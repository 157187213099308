<template>
  <b-alert variant="danger" show>{{ message }}</b-alert>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['message'],
}
</script>
