var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "8" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c("h3", [_vm._v(_vm._s(_vm.$t("sar.part1")))]),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "schoolName",
                          "label-for": "schoolName",
                          label: _vm.$t("sar.schoolName"),
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.schoolName,
                            callback: function ($$v) {
                              _vm.schoolName = $$v
                            },
                            expression: "schoolName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "intSchoolNameChi",
                          "label-for": "intSchoolNameChi",
                          label: _vm.$t("sar.intSchoolNameChi"),
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.intSchoolNameChi,
                            callback: function ($$v) {
                              _vm.intSchoolNameChi = $$v
                            },
                            expression: "intSchoolNameChi",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "district",
                          "label-for": "district",
                          label: _vm.$t("sar.district"),
                        },
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.districtList,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "disabled",
                          },
                          model: {
                            value: _vm.district,
                            callback: function ($$v) {
                              _vm.district = $$v
                            },
                            expression: "district",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "category",
                          "label-for": "category",
                          label: _vm.$t("sar.category"),
                        },
                      },
                      [
                        _c("b-form-radio-group", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.catList,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "notEnabled",
                          },
                          model: {
                            value: _vm.category,
                            callback: function ($$v) {
                              _vm.category = $$v
                            },
                            expression: "category",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-3",
                        attrs: {
                          id: "edbCat",
                          "label-for": "edbCat",
                          label: _vm.$t("sar.edbCat"),
                        },
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.edbCatList,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "disabled",
                          },
                          model: {
                            value: _vm.edbCat,
                            callback: function ($$v) {
                              _vm.edbCat = $$v
                            },
                            expression: "edbCat",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "renameRemark",
                          "label-for": "renameRemark",
                          label: _vm.$t("sar.renameRemark"),
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.renameRemark,
                            callback: function ($$v) {
                              _vm.renameRemark = $$v
                            },
                            expression: "renameRemark",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "schoolStatus",
                          "label-for": "schoolStatus",
                          label: _vm.$t("sar.schoolStatus"),
                        },
                      },
                      [
                        _c("b-form-radio-group", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.statusList,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "notEnabled",
                          },
                          model: {
                            value: _vm.schoolStatus,
                            callback: function ($$v) {
                              _vm.schoolStatus = $$v
                            },
                            expression: "schoolStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "schoolStatusRemark",
                          "label-for": "schoolStatusRemark",
                          label: _vm.$t("sar.schoolStatusRemark"),
                        },
                      },
                      [
                        _c("b-form-checkbox-group", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.statusList2,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "notEnabled",
                          },
                          model: {
                            value: _vm.schoolStatusRemark,
                            callback: function ($$v) {
                              _vm.schoolStatusRemark = $$v
                            },
                            expression: "schoolStatusRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c("h3", [_vm._v(_vm._s(_vm.$t("sar.part2")))]),
                    _vm._v(" "),
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "scds" }, [
                                      _vm._v(_vm._s(_vm.$t("sar.scds"))),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticStyle: { "margin-top": "1em" },
                                attrs: { id: "scds", "label-for": "scds" },
                              },
                              [
                                _c("JsonInput", {
                                  attrs: {
                                    id: "scds",
                                    value: _vm.scds,
                                    empty: _vm.scdsEmpty,
                                  },
                                  on: { out: _vm.getContent },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "spaps" }, [
                                      _vm._v(_vm._s(_vm.$t("sar.spaps"))),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticStyle: { "margin-top": "1em" },
                                attrs: { id: "spaps", "label-for": "spaps" },
                              },
                              [
                                _c("JsonInput", {
                                  attrs: {
                                    id: "spaps",
                                    value: _vm.spaps,
                                    empty: _vm.spapsEmpty,
                                  },
                                  on: { out: _vm.getContent },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "aes" }, [
                                      _vm._v(_vm._s(_vm.$t("sar.aes"))),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                staticStyle: { "margin-top": "1em" },
                                attrs: { id: "aes", "label-for": "aes" },
                              },
                              [
                                _c("JsonInput", {
                                  attrs: {
                                    id: "aes",
                                    value: _vm.aes,
                                    empty: _vm.aesEmpty,
                                  },
                                  on: { out: _vm.getContent },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }