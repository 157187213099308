<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="8">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          {{ $t('smsTemplate.target') }}
          <b-form-group id="to" label-for="to">
            <b-form-select
              v-model="target"
              :options="targetOption"
              :state="!!target"
              size="sm"
              class="mt-1"
              required
            ></b-form-select>
          </b-form-group>

          {{ $t('smsTemplate.advancedTarget') }}
          <b-form-group id="to" label-for="to">
            <b-form-select
              v-model="advTarget"
              :options="advTargetOption"
              :state="!!advTarget"
              size="sm"
              class="mt-1"
              required
            ></b-form-select>
          </b-form-group>

          <div v-if="advTarget !== 'none'">
            {{ $t('smsTemplate.advancedTarget') }}
            <b-row>
              <b-col sm="3">
                {{ $t('smsTemplate.filter') }}
                <b-form-input
                  v-model="advTarget2Filter"
                  size="sm"
                  class="mt-1"
                  @change="getAdvTargetOption2"
                ></b-form-input>
              </b-col>
              <b-col sm="9">
                {{ $t('smsTemplate.select') }}
                <b-form-select
                  v-model="advTarget2"
                  :options="advTarget2FilterOption"
                  :state="!!advTarget2"
                  size="sm"
                  class="mt-1"
                  required
                ></b-form-select>
              </b-col>
            </b-row>
          </div>

          <div v-if="advTarget === 'eventSlotId' && advTarget2 !== null">
            {{ $t('smsTemplate.advancedTarget') }}
            <b-form-group id="to" label-for="to">
              <b-form-select
                v-model="advTarget3"
                :options="advTargetOption3"
                :state="!!advTarget3"
                size="sm"
                class="mt-1"
                required
              ></b-form-select>
            </b-form-group>
          </div>
          <!-- <div v-if="advTargetResult !== null">
            <p>Count: {{ advTargetResult.length }}</p>
            <div v-for="i in advTargetResult" :key="i">
              <p>
                Name: {{ i.name }} | Email: {{ i.email }} | Tel: {{ i.tel }}
              </p>
            </div>
          </div> -->
          <div v-if="advTarget !== 'none'">
            <div v-if="advTargetResult !== null" style="padding: 10px 0px">
              <b-button v-b-toggle.collapse-1 variant="primary" size="sm"
                >對象名單
                <b-badge variant="light"> {{ advTargetResult.length }} </b-badge
                >：</b-button
              >
              <b-collapse id="collapse-1" class="mt-2">
                <b-card>
                  <div v-for="i in advTargetResult" :key="i">
                    <p>
                      Name: {{ i.name }} | Email: {{ i.email }} | Tel:
                      {{ i.tel }}
                    </p>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </div>
          <!-- <b-form-radio-group
              v-model="target"
              :options="targetOption"
              :state="!!target"
              class="mb-3"
              required
            ></b-form-radio-group> -->
          <b-form-group id="to" label-for="to">
            {{ $t('smsTemplate.to') }}
            <b-form-input v-model="receiver" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="content" label-for="content">
            {{ $t('smsTemplate.content') }}
            <b-textarea v-model="content" maxlength="603"></b-textarea>
            <p>
              {{ content.length + '/' + '603' }}.
              <span>{{ calSMS() }}</span> SMS required.
            </p>
          </b-form-group>

          <b-form-group id="testto" label-for="testto">
            {{ $t('smsTemplate.testto') }}
            <b-row>
              <b-col cols="8">
                <b-form-input v-model="testReceiver" type="text"></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button variant="outline-info" @click="sendTestMail">{{
                  $t('smsTemplate.testmail')
                }}</b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submitSMS')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <!-- <tools :tc="getTc" @writeContent="setSc"></tools> -->
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
/* import tools from '~/components/common/tools' */

export default {
  components: {
    Notification,
    /* tools, */
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    action: { type: String, default: 'add' },
    trnId: { type: String, default: '' },
    tempCode: { type: String, default: '' },
    custom: { type: Object, default: null },
    // isShow: { type: Boolean, default: false },
  },
  data() {
    return {
      id: null,
      code: '',
      receiver: '{{users|tel}}',
      cc: '',
      bcc: '',
      subject: '',
      content: '',
      schoolId: null,
      userId: null,
      testReceiver: '',
      orders: null,
      error: null,
      isShow: false,
      target: 'all',
      targetOption: [
        { value: 'all', text: '全部 All Teacher' },
        { value: 'k', text: '幼稚園 Kindergarten' },
        { value: 'p', text: '小學 Primary' },
        { value: 's', text: '中學 Secondary' },
        { value: 'sp', text: '特殊 Special' },
        { value: 'o', text: '其他 Other' },
      ],
      targetResult: [],
      advTarget: 'none',
      advTargetOption: [
        { value: 'none', text: '沒有 None' },
        { value: 'eventId', text: '活動 Event' },
        { value: 'eventSlotId', text: '活動場次 Event Slot' },
        { value: 'district', text: '區域 District' },
      ],
      advTarget2: null,
      advTargetOption2: [],
      advTarget3: null,
      advTargetOption3: [], // event slot only
      tmp_advTargetResult: [],
      advTargetResult: [],
      advTarget2Filter: null,
      advTarget2FilterOption: [],
    }
  },
  async fetch() {
    try {
      const targets = await this.$axios.post(
        `${process.env.localApiUrl}filters/smsTarget`,
        {
          target: 'all',
        }
      )
      this.targetResult = targets.data.data
      console.log(this.targetResult)
      console.log(targets)
      if (this.action === 'edit' || this.action === 'send') {
        if (this.action === 'edit') {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}smsTemplate/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.id = data.id
          this.code = data.code
          this.receiver = data.receiver
          this.content = data.content
        } else if (this.action === 'send') {
          const template = await this.$axios.get(
            `${process.env.cloudApiUrl}smsTemplate/template/${this.tempCode}`
          )
          const result = await this.$axios.post(
            `${process.env.localApiUrl}smsTemplate/generate`,
            {
              trnId: this.trnId,
              code: this.tempCode,
              template: template.data.data[0],
              custom: this.custom,
            }
          )
          this.userId = result.data.data.userId
          this.schoolId = result.data.data.schoolId
          const data = result.data.data.template
          this.receiver = data.receiver
          this.content = data.content
        }
      } else {
        this.isShow = true
      }
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  computed: {},
  watch: {
    target: {
      async handler(newVal, oldVal) {
        console.log(`trigger target`)
        if (this.advTarget !== 'none') {
          console.log(`trigger target not none`)
          this.updateAdvTargetResult(newVal)
        } else {
          // special handle basic target to get tel number in local db
          console.log(`trigger target none`)
          await this.getTarget()
        }
      },
      deep: true,
    },
    tmp_advTargetResult: {
      handler(newVal, oldVal) {
        this.updateAdvTargetResult(this.target)
      },
      deep: true,
    },
    advTarget: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          let tmp = newVal
          if (newVal === 'eventSlotId') {
            tmp = 'eventId'
          }
          this.tmp_advancedTarget = []
          this.advancedTarget = []
          this.getAdvTarget1(tmp)
        }
      },
      deep: true,
    },
    advTarget2: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          if (this.advTarget === 'eventSlotId') {
            this.getAdvTarget2('eventSlotId', newVal)
          } else if (this.advTarget === 'district') {
            this.getAdvTargetResult('district', newVal)
          } else if (this.advTarget === 'eventId') {
            this.getAdvTargetResult('eventId', newVal)
          }
        }
      },
      deep: true,
    },
    advTarget3: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          if (this.advTarget === 'eventSlotId') {
            this.getAdvTargetResult('eventSlotId', newVal)
          }
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    async getTarget() {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters/smsTarget`,
        {
          target: this.target,
        }
      )
      this.targetResult = result.data.data
    },
    getAdvTargetOption2() {
      this.advTarget2FilterOption = this.advTarget2Filter
        ? this.advTargetOption2.filter((item) => {
            return item.text
              .toLowerCase()
              .includes(this.advTarget2Filter.toLowerCase())
          })
        : this.advTargetOption2
    },
    async getAdvTarget1(target) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters`,
        {
          type: target,
        }
      )
      this.advTargetOption2 = result.data.data
      this.getAdvTargetOption2()
      this.advTarget2 = null
      this.advTarget3 = null
    },
    async getAdvTarget2(target, eventId) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters`,
        {
          type: target,
          eventId,
        }
      )
      this.advTargetOption3 = result.data.data
      this.advTarget3 = null
    },
    async getAdvTargetResult(target, Id) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters/target`,
        {
          type: target,
          id: Id,
        }
      )
      this.tmp_advTargetResult = result.data.data
    },
    updateAdvTargetResult(target) {
      if (target === 'k') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 1
        )
      } else if (target === 'p') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 2
        )
      } else if (target === 's') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 3
        )
      } else if (target === 'sp') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 4
        )
      } else if (target === 'o') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 5
        )
      } else {
        this.advTargetResult = this.tmp_advTargetResult
      }
    },
    calSMS() {
      const limit = [0, 70, 134, 201, 268, 335, 402, 469, 536, 603, 99999999999]
      const len = this.content.length
      if (len === 0) return 0
      for (let i = 0; i < limit.length; i++) {
        if (len >= limit[i] && len <= limit[i + 1]) {
          return i + 1
        }
      }
      return 'cannot delivery'
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async submit() {
      try {
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.receiver,
          cc: this.cc,
          bcc: this.bcc,
          subject: this.subject,
          content: this.content,
        }
        // TODO: change api to generate customized email notification and add to queue system.
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}smsTemplate/compose/send`,
          {
            template: obj,
            target: this.target,
            advancedTarget:
              this.advTarget === 'none'
                ? this.targetResult
                : this.advTargetResult,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/smsTemplate'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async sendTestMail() {
      try {
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.testReceiver,
          cc: '',
          bcc: '',
          subject: this.subject,
          content: this.content,
        }
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}smsTemplate/send`,
          {
            template: obj,
            userId: 0,
            schoolId: 0,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `test mail has been sent`,
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'OK',
        })
        if (back.value) {
          // this.$router.push(this.localePath('/admin/smsTemplate'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
