<template>
  <div
    class="header"
    :style="slotsMap.get(eventSlot.id) ? 'background-color: powderblue' : ''"
  >
    <span class="d-flex header" style="align-items: center">
      場次{{ eventSlot.id }} ｜ {{ eventSlot.dateStr }} ｜
      {{ eventSlot.startTimeStr }} - {{ eventSlot.endTimeStr }} ｜
      {{ eventSlot.venueName }} ｜
      <div v-if="$route.query.successful">Success: {{ getSuccess }}</div>
      <div v-else :class="applications.length > 0 ? 'count' : ''">
        Count: {{ applications.length }}
      </div>
      <b-button
        v-if="$route.query.successful && getSuccess > 0"
        v-b-toggle="`collapse-${eventSlot.id}`"
        :variant="
          applications.length > 0 && !slotsMap.get(eventSlot.id)
            ? 'primary'
            : 'link'
        "
        size="sm"
      >
        View Successful Applications
        <b-badge v-if="eventSlot.noOfPendingOfSlot > 0" variant="light">
          {{ eventSlot.noOfPendingOfSlot }}
        </b-badge>
        <b-icon
          :icon="slotsMap.get(eventSlot.id) ? 'caret-up' : 'caret-down-fill'"
        />
      </b-button>
      <b-button
        v-else-if="!$route.query.successful && applications.length > 0"
        v-b-toggle="`collapse-${eventSlot.id}`"
        :variant="
          applications.length > 0 && !slotsMap.get(eventSlot.id)
            ? 'primary'
            : 'link'
        "
        size="sm"
      >
        View Applications
        <b-badge v-if="eventSlot.noOfPendingOfSlot > 0" variant="light">
          {{ eventSlot.noOfPendingOfSlot }}
        </b-badge>
        <b-icon
          :icon="slotsMap.get(eventSlot.id) ? 'caret-up' : 'caret-down-fill'"
        />
      </b-button>
    </span>

    <b-collapse
      :id="`collapse-${eventSlot.id}`"
      :style="'background-color: #f8f8f8'"
      @show="
        () => {
          slotsMap.set(eventSlot.id, true)
          $forceUpdate()
        }
      "
      @hidden="
        () => {
          slotsMap.set(eventSlot.id, false)
          $forceUpdate()
        }
      "
    >
      <slot></slot>
    </b-collapse>
  </div>
</template>
<script>
export default {
  props: {
    eventSlot: {
      type: Object,
      default: () => {
        return {
          id: 0,
          dateStr: '',
          startTimeStr: '',
          endTimeStr: '',
          venueName: '',
          applications: [],
          noOfPendingOfSlot: null,
        }
      },
    },
    applications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slotsMap: new Map(),
    }
  },
  computed: {
    getSuccess() {
      let count = 0
      for (let i = 0; i < this.applications.length; i++) {
        if (
          this.applications[i].status === 1 &&
          this.applications[i].result_event_slot === this.eventSlot.id
        ) {
          console.log(this.applications[i])
          count++
        }
      }
      return count
    },
  },
}
</script>
<style land="scss" scoped>
.header {
  padding: 10px 20px;
  line-height: 2;
}
</style>
