import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _35ef99fd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6b48de06 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _af718904 = () => interopDefault(import('../pages/application/index.vue' /* webpackChunkName: "pages/application/index" */))
const _0d946f4a = () => interopDefault(import('../pages/bookmark.vue' /* webpackChunkName: "pages/bookmark" */))
const _431d9e88 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _1e0a4f2a = () => interopDefault(import('../pages/feedback.vue' /* webpackChunkName: "pages/feedback" */))
const _00d185d8 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7fcd00e6 = () => interopDefault(import('../pages/scheme/index.vue' /* webpackChunkName: "pages/scheme/index" */))
const _55b67060 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1649d110 = () => interopDefault(import('../pages/shoppingCart/index.vue' /* webpackChunkName: "pages/shoppingCart/index" */))
const _d28a472a = () => interopDefault(import('../pages/admin/approval/index.vue' /* webpackChunkName: "pages/admin/approval/index" */))
const _8ee5dd24 = () => interopDefault(import('../pages/admin/cancelEvent/index.vue' /* webpackChunkName: "pages/admin/cancelEvent/index" */))
const _4c197534 = () => interopDefault(import('../pages/admin/category/index.vue' /* webpackChunkName: "pages/admin/category/index" */))
const _658c45b0 = () => interopDefault(import('../pages/admin/change_request/index.vue' /* webpackChunkName: "pages/admin/change_request/index" */))
const _117d55ea = () => interopDefault(import('../pages/admin/config/index.vue' /* webpackChunkName: "pages/admin/config/index" */))
const _f2f4e264 = () => interopDefault(import('../pages/admin/contact/index.vue' /* webpackChunkName: "pages/admin/contact/index" */))
const _e7d82e18 = () => interopDefault(import('../pages/admin/emailPreview/index.vue' /* webpackChunkName: "pages/admin/emailPreview/index" */))
const _c4baa5b8 = () => interopDefault(import('../pages/admin/emailTemplate/index.vue' /* webpackChunkName: "pages/admin/emailTemplate/index" */))
const _831754f0 = () => interopDefault(import('../pages/admin/event/index.vue' /* webpackChunkName: "pages/admin/event/index" */))
const _670191a6 = () => interopDefault(import('../pages/admin/feedback/index.vue' /* webpackChunkName: "pages/admin/feedback/index" */))
const _64b5fb7b = () => interopDefault(import('../pages/admin/news/index.vue' /* webpackChunkName: "pages/admin/news/index" */))
const _4c687304 = () => interopDefault(import('../pages/admin/other/index.vue' /* webpackChunkName: "pages/admin/other/index" */))
const _626d4f92 = () => interopDefault(import('../pages/admin/page/index.vue' /* webpackChunkName: "pages/admin/page/index" */))
const _77b0fd44 = () => interopDefault(import('../pages/admin/role/index.vue' /* webpackChunkName: "pages/admin/role/index" */))
const _a0ccaee6 = () => interopDefault(import('../pages/admin/scheme/index.vue' /* webpackChunkName: "pages/admin/scheme/index" */))
const _225c179c = () => interopDefault(import('../pages/admin/school/index.vue' /* webpackChunkName: "pages/admin/school/index" */))
const _7fbc351b = () => interopDefault(import('../pages/admin/schoolApplicationRecord/index.vue' /* webpackChunkName: "pages/admin/schoolApplicationRecord/index" */))
const _82177b3e = () => interopDefault(import('../pages/admin/smsTemplate/index.vue' /* webpackChunkName: "pages/admin/smsTemplate/index" */))
const _7d8e8673 = () => interopDefault(import('../pages/admin/user/index.vue' /* webpackChunkName: "pages/admin/user/index" */))
const _d54771c6 = () => interopDefault(import('../pages/admin/venue/index.vue' /* webpackChunkName: "pages/admin/venue/index" */))
const _af5ebffc = () => interopDefault(import('../pages/password/forgot.vue' /* webpackChunkName: "pages/password/forgot" */))
const _63de78e6 = () => interopDefault(import('../pages/settings/account.vue' /* webpackChunkName: "pages/settings/account" */))
const _09603d9c = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _573177cd = () => interopDefault(import('../pages/admin/application/add.vue' /* webpackChunkName: "pages/admin/application/add" */))
const _7a1a3362 = () => interopDefault(import('../pages/admin/application/aoa/index.vue' /* webpackChunkName: "pages/admin/application/aoa/index" */))
const _5ffba678 = () => interopDefault(import('../pages/admin/application/school.vue' /* webpackChunkName: "pages/admin/application/school" */))
const _1d83a4b5 = () => interopDefault(import('../pages/admin/category/add.vue' /* webpackChunkName: "pages/admin/category/add" */))
const _08572382 = () => interopDefault(import('../pages/admin/change_request/view/index.vue' /* webpackChunkName: "pages/admin/change_request/view/index" */))
const _5d831af0 = () => interopDefault(import('../pages/admin/composeNotification/custom.vue' /* webpackChunkName: "pages/admin/composeNotification/custom" */))
const _2d69281d = () => interopDefault(import('../pages/admin/contact/add.vue' /* webpackChunkName: "pages/admin/contact/add" */))
const _ae74381a = () => interopDefault(import('../pages/admin/emailTemplate/add.vue' /* webpackChunkName: "pages/admin/emailTemplate/add" */))
const _71ee5557 = () => interopDefault(import('../pages/admin/event/add.vue' /* webpackChunkName: "pages/admin/event/add" */))
const _ef9247ec = () => interopDefault(import('../pages/admin/news/add.vue' /* webpackChunkName: "pages/admin/news/add" */))
const _998ce866 = () => interopDefault(import('../pages/admin/other/add.vue' /* webpackChunkName: "pages/admin/other/add" */))
const _4acb51c9 = () => interopDefault(import('../pages/admin/page/add/index.vue' /* webpackChunkName: "pages/admin/page/add/index" */))
const _45f302a6 = () => interopDefault(import('../pages/admin/role/add.vue' /* webpackChunkName: "pages/admin/role/add" */))
const _a0a0f1c2 = () => interopDefault(import('../pages/admin/scheme/add/index.vue' /* webpackChunkName: "pages/admin/scheme/add/index" */))
const _7170e72a = () => interopDefault(import('../pages/admin/school/add.vue' /* webpackChunkName: "pages/admin/school/add" */))
const _b68204ac = () => interopDefault(import('../pages/admin/schoolApplicationRecord/add.vue' /* webpackChunkName: "pages/admin/schoolApplicationRecord/add" */))
const _5b05a470 = () => interopDefault(import('../pages/admin/smsTemplate/add.vue' /* webpackChunkName: "pages/admin/smsTemplate/add" */))
const _2125c502 = () => interopDefault(import('../pages/admin/user/add.vue' /* webpackChunkName: "pages/admin/user/add" */))
const _0040afa8 = () => interopDefault(import('../pages/admin/venue/add.vue' /* webpackChunkName: "pages/admin/venue/add" */))
const _99453a9e = () => interopDefault(import('../pages/admin/application/aoa/all.vue' /* webpackChunkName: "pages/admin/application/aoa/all" */))
const _7dc2a74c = () => interopDefault(import('../pages/admin/application/spaps/ballot_mark.vue' /* webpackChunkName: "pages/admin/application/spaps/ballot_mark" */))
const _740f14ac = () => interopDefault(import('../pages/admin/emailTemplate/compose/custom.vue' /* webpackChunkName: "pages/admin/emailTemplate/compose/custom" */))
const _3235140f = () => interopDefault(import('../pages/admin/smsTemplate/compose/custom.vue' /* webpackChunkName: "pages/admin/smsTemplate/compose/custom" */))
const _61cc19aa = () => interopDefault(import('../pages/event/apply/aoa/tempest.vue' /* webpackChunkName: "pages/event/apply/aoa/tempest" */))
const _bdbd060a = () => interopDefault(import('../pages/admin/config/customizeHomepageLastModify/edit/_id.vue' /* webpackChunkName: "pages/admin/config/customizeHomepageLastModify/edit/_id" */))
const _3db619cb = () => interopDefault(import('../pages/admin/config/lcsdRelatedWeb/edit/_id.vue' /* webpackChunkName: "pages/admin/config/lcsdRelatedWeb/edit/_id" */))
const _77fbe55d = () => interopDefault(import('../pages/admin/config/schemeType/edit/_id.vue' /* webpackChunkName: "pages/admin/config/schemeType/edit/_id" */))
const _7039fc40 = () => interopDefault(import('../pages/admin/config/suggestedEvents/edit/_id.vue' /* webpackChunkName: "pages/admin/config/suggestedEvents/edit/_id" */))
const _215b63f9 = () => interopDefault(import('../pages/admin/application/aes/_slug/index.vue' /* webpackChunkName: "pages/admin/application/aes/_slug/index" */))
const _71bb5f70 = () => interopDefault(import('../pages/admin/application/all/_slug.vue' /* webpackChunkName: "pages/admin/application/all/_slug" */))
const _d051333e = () => interopDefault(import('../pages/admin/application/scds/_slug/index.vue' /* webpackChunkName: "pages/admin/application/scds/_slug/index" */))
const _58428e5e = () => interopDefault(import('../pages/admin/application/spaps/_slug/index.vue' /* webpackChunkName: "pages/admin/application/spaps/_slug/index" */))
const _1438eb88 = () => interopDefault(import('../pages/admin/category/copy/_id.vue' /* webpackChunkName: "pages/admin/category/copy/_id" */))
const _0c1ae3d1 = () => interopDefault(import('../pages/admin/category/edit/_id.vue' /* webpackChunkName: "pages/admin/category/edit/_id" */))
const _37d2d4cd = () => interopDefault(import('../pages/admin/config/edit/_id.vue' /* webpackChunkName: "pages/admin/config/edit/_id" */))
const _5fa74169 = () => interopDefault(import('../pages/admin/contact/edit/_id.vue' /* webpackChunkName: "pages/admin/contact/edit/_id" */))
const _90f269e2 = () => interopDefault(import('../pages/admin/emailTemplate/compose/_id.vue' /* webpackChunkName: "pages/admin/emailTemplate/compose/_id" */))
const _0efd21be = () => interopDefault(import('../pages/admin/emailTemplate/copy/_id.vue' /* webpackChunkName: "pages/admin/emailTemplate/copy/_id" */))
const _25347b53 = () => interopDefault(import('../pages/admin/emailTemplate/edit/_id.vue' /* webpackChunkName: "pages/admin/emailTemplate/edit/_id" */))
const _3d17eeda = () => interopDefault(import('../pages/admin/event/copy/_id.vue' /* webpackChunkName: "pages/admin/event/copy/_id" */))
const _534f486f = () => interopDefault(import('../pages/admin/event/edit/_id.vue' /* webpackChunkName: "pages/admin/event/edit/_id" */))
const _62fa6b76 = () => interopDefault(import('../pages/admin/feedback/view/_id.vue' /* webpackChunkName: "pages/admin/feedback/view/_id" */))
const _a2b2bff2 = () => interopDefault(import('../pages/admin/news/copy/_id.vue' /* webpackChunkName: "pages/admin/news/copy/_id" */))
const _76440cc8 = () => interopDefault(import('../pages/admin/news/edit/_id.vue' /* webpackChunkName: "pages/admin/news/edit/_id" */))
const _01ba5224 = () => interopDefault(import('../pages/admin/other/copy/_id.vue' /* webpackChunkName: "pages/admin/other/copy/_id" */))
const _17f1abb9 = () => interopDefault(import('../pages/admin/other/edit/_id.vue' /* webpackChunkName: "pages/admin/other/edit/_id" */))
const _2fdad940 = () => interopDefault(import('../pages/admin/page/edit/_id.vue' /* webpackChunkName: "pages/admin/page/edit/_id" */))
const _beacf64e = () => interopDefault(import('../pages/admin/role/edit/_id.vue' /* webpackChunkName: "pages/admin/role/edit/_id" */))
const _4d62c547 = () => interopDefault(import('../pages/admin/scheme/add/_id.vue' /* webpackChunkName: "pages/admin/scheme/add/_id" */))
const _02cc5b1a = () => interopDefault(import('../pages/admin/scheme/applyNotes/_id.vue' /* webpackChunkName: "pages/admin/scheme/applyNotes/_id" */))
const _8c71496c = () => interopDefault(import('../pages/admin/scheme/edit/_id.vue' /* webpackChunkName: "pages/admin/scheme/edit/_id" */))
const _6a415ddb = () => interopDefault(import('../pages/admin/school/edit/_id.vue' /* webpackChunkName: "pages/admin/school/edit/_id" */))
const _c36c4808 = () => interopDefault(import('../pages/admin/schoolApplicationRecord/edit/_id.vue' /* webpackChunkName: "pages/admin/schoolApplicationRecord/edit/_id" */))
const _7370be8c = () => interopDefault(import('../pages/admin/smsTemplate/compose/_id.vue' /* webpackChunkName: "pages/admin/smsTemplate/compose/_id" */))
const _7a1a80f6 = () => interopDefault(import('../pages/admin/smsTemplate/edit/_id.vue' /* webpackChunkName: "pages/admin/smsTemplate/edit/_id" */))
const _1c31eba4 = () => interopDefault(import('../pages/admin/user/edit/_id.vue' /* webpackChunkName: "pages/admin/user/edit/_id" */))
const _16a21925 = () => interopDefault(import('../pages/admin/venue/copy/_id.vue' /* webpackChunkName: "pages/admin/venue/copy/_id" */))
const _2cd972ba = () => interopDefault(import('../pages/admin/venue/edit/_id.vue' /* webpackChunkName: "pages/admin/venue/edit/_id" */))
const _1bc320c7 = () => interopDefault(import('../pages/application/aes/change_request/_trnId.vue' /* webpackChunkName: "pages/application/aes/change_request/_trnId" */))
const _0d9502f2 = () => interopDefault(import('../pages/application/scds/change_request/_id.vue' /* webpackChunkName: "pages/application/scds/change_request/_id" */))
const _3373f0c2 = () => interopDefault(import('../pages/application/spaps/change_request/_trnId.vue' /* webpackChunkName: "pages/application/spaps/change_request/_trnId" */))
const _7b4dd70f = () => interopDefault(import('../pages/event/apply/aes/_id.vue' /* webpackChunkName: "pages/event/apply/aes/_id" */))
const _3daa3a76 = () => interopDefault(import('../pages/event/apply/scds/_id.vue' /* webpackChunkName: "pages/event/apply/scds/_id" */))
const _21f8a4b2 = () => interopDefault(import('../pages/event/apply/spaps/_id.vue' /* webpackChunkName: "pages/event/apply/spaps/_id" */))
const _0f784c08 = () => interopDefault(import('../pages/admin/application/aes/_slug/add.vue' /* webpackChunkName: "pages/admin/application/aes/_slug/add" */))
const _1d8fcd08 = () => interopDefault(import('../pages/admin/application/aes/_slug/all.vue' /* webpackChunkName: "pages/admin/application/aes/_slug/all" */))
const _cb3c6f20 = () => interopDefault(import('../pages/admin/application/scds/_slug/add.vue' /* webpackChunkName: "pages/admin/application/scds/_slug/add" */))
const _af0d6d20 = () => interopDefault(import('../pages/admin/application/scds/_slug/all.vue' /* webpackChunkName: "pages/admin/application/scds/_slug/all" */))
const _500a1240 = () => interopDefault(import('../pages/admin/application/spaps/_slug/add.vue' /* webpackChunkName: "pages/admin/application/spaps/_slug/add" */))
const _33db1040 = () => interopDefault(import('../pages/admin/application/spaps/_slug/all.vue' /* webpackChunkName: "pages/admin/application/spaps/_slug/all" */))
const _09af985c = () => interopDefault(import('../pages/admin/emailPreview/_id.vue' /* webpackChunkName: "pages/admin/emailPreview/_id" */))
const _039839a0 = () => interopDefault(import('../pages/application/aes/_trnId.vue' /* webpackChunkName: "pages/application/aes/_trnId" */))
const _25e4ca8e = () => interopDefault(import('../pages/application/scds/_id.vue' /* webpackChunkName: "pages/application/scds/_id" */))
const _588b2758 = () => interopDefault(import('../pages/application/spaps/_trnId.vue' /* webpackChunkName: "pages/application/spaps/_trnId" */))
const _dcb53262 = () => interopDefault(import('../pages/event/apply/_id.vue' /* webpackChunkName: "pages/event/apply/_id" */))
const _a190fc4e = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _6cf6cb38 = () => interopDefault(import('../pages/shoppingCart/aes/_id.vue' /* webpackChunkName: "pages/shoppingCart/aes/_id" */))
const _24a073bc = () => interopDefault(import('../pages/shoppingCart/scds/_id.vue' /* webpackChunkName: "pages/shoppingCart/scds/_id" */))
const _1a403b90 = () => interopDefault(import('../pages/shoppingCart/spaps/_id.vue' /* webpackChunkName: "pages/shoppingCart/spaps/_id" */))
const _6fba89c4 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _44e84f82 = () => interopDefault(import('../pages/event/_code.vue' /* webpackChunkName: "pages/event/_code" */))
const _2bc245f0 = () => interopDefault(import('../pages/scheme/_slug/index.vue' /* webpackChunkName: "pages/scheme/_slug/index" */))
const _fabcf3c0 = () => interopDefault(import('../pages/scheme/_slug/_subscheme.vue' /* webpackChunkName: "pages/scheme/_slug/_subscheme" */))
const _3438f8b5 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _35ef99fd,
    name: "index___en"
  }, {
    path: "/sc",
    component: _35ef99fd,
    name: "index___sc"
  }, {
    path: "/tc",
    component: _35ef99fd,
    name: "index___tc"
  }, {
    path: "/en/admin",
    component: _6b48de06,
    name: "admin___en"
  }, {
    path: "/en/application",
    component: _af718904,
    name: "application___en"
  }, {
    path: "/en/bookmark",
    component: _0d946f4a,
    name: "bookmark___en"
  }, {
    path: "/en/event",
    component: _431d9e88,
    name: "event___en"
  }, {
    path: "/en/feedback",
    component: _1e0a4f2a,
    name: "feedback___en"
  }, {
    path: "/en/login",
    component: _00d185d8,
    name: "login___en"
  }, {
    path: "/en/scheme",
    component: _7fcd00e6,
    name: "scheme___en"
  }, {
    path: "/en/search",
    component: _55b67060,
    name: "search___en"
  }, {
    path: "/en/shoppingCart",
    component: _1649d110,
    name: "shoppingCart___en"
  }, {
    path: "/sc/admin",
    component: _6b48de06,
    name: "admin___sc"
  }, {
    path: "/sc/application",
    component: _af718904,
    name: "application___sc"
  }, {
    path: "/sc/bookmark",
    component: _0d946f4a,
    name: "bookmark___sc"
  }, {
    path: "/sc/event",
    component: _431d9e88,
    name: "event___sc"
  }, {
    path: "/sc/feedback",
    component: _1e0a4f2a,
    name: "feedback___sc"
  }, {
    path: "/sc/login",
    component: _00d185d8,
    name: "login___sc"
  }, {
    path: "/sc/scheme",
    component: _7fcd00e6,
    name: "scheme___sc"
  }, {
    path: "/sc/search",
    component: _55b67060,
    name: "search___sc"
  }, {
    path: "/sc/shoppingCart",
    component: _1649d110,
    name: "shoppingCart___sc"
  }, {
    path: "/tc/admin",
    component: _6b48de06,
    name: "admin___tc"
  }, {
    path: "/tc/application",
    component: _af718904,
    name: "application___tc"
  }, {
    path: "/tc/bookmark",
    component: _0d946f4a,
    name: "bookmark___tc"
  }, {
    path: "/tc/event",
    component: _431d9e88,
    name: "event___tc"
  }, {
    path: "/tc/feedback",
    component: _1e0a4f2a,
    name: "feedback___tc"
  }, {
    path: "/tc/login",
    component: _00d185d8,
    name: "login___tc"
  }, {
    path: "/tc/scheme",
    component: _7fcd00e6,
    name: "scheme___tc"
  }, {
    path: "/tc/search",
    component: _55b67060,
    name: "search___tc"
  }, {
    path: "/tc/shoppingCart",
    component: _1649d110,
    name: "shoppingCart___tc"
  }, {
    path: "/en/admin/approval",
    component: _d28a472a,
    name: "admin-approval___en"
  }, {
    path: "/en/admin/cancelEvent",
    component: _8ee5dd24,
    name: "admin-cancelEvent___en"
  }, {
    path: "/en/admin/category",
    component: _4c197534,
    name: "admin-category___en"
  }, {
    path: "/en/admin/change_request",
    component: _658c45b0,
    name: "admin-change_request___en"
  }, {
    path: "/en/admin/config",
    component: _117d55ea,
    name: "admin-config___en"
  }, {
    path: "/en/admin/contact",
    component: _f2f4e264,
    name: "admin-contact___en"
  }, {
    path: "/en/admin/emailPreview",
    component: _e7d82e18,
    name: "admin-emailPreview___en"
  }, {
    path: "/en/admin/emailTemplate",
    component: _c4baa5b8,
    name: "admin-emailTemplate___en"
  }, {
    path: "/en/admin/event",
    component: _831754f0,
    name: "admin-event___en"
  }, {
    path: "/en/admin/feedback",
    component: _670191a6,
    name: "admin-feedback___en"
  }, {
    path: "/en/admin/news",
    component: _64b5fb7b,
    name: "admin-news___en"
  }, {
    path: "/en/admin/other",
    component: _4c687304,
    name: "admin-other___en"
  }, {
    path: "/en/admin/page",
    component: _626d4f92,
    name: "admin-page___en"
  }, {
    path: "/en/admin/role",
    component: _77b0fd44,
    name: "admin-role___en"
  }, {
    path: "/en/admin/scheme",
    component: _a0ccaee6,
    name: "admin-scheme___en"
  }, {
    path: "/en/admin/school",
    component: _225c179c,
    name: "admin-school___en"
  }, {
    path: "/en/admin/schoolApplicationRecord",
    component: _7fbc351b,
    name: "admin-schoolApplicationRecord___en"
  }, {
    path: "/en/admin/smsTemplate",
    component: _82177b3e,
    name: "admin-smsTemplate___en"
  }, {
    path: "/en/admin/user",
    component: _7d8e8673,
    name: "admin-user___en"
  }, {
    path: "/en/admin/venue",
    component: _d54771c6,
    name: "admin-venue___en"
  }, {
    path: "/en/password/forgot",
    component: _af5ebffc,
    name: "password-forgot___en"
  }, {
    path: "/en/settings/account",
    component: _63de78e6,
    name: "settings-account___en"
  }, {
    path: "/en/settings/password",
    component: _09603d9c,
    name: "settings-password___en"
  }, {
    path: "/sc/admin/approval",
    component: _d28a472a,
    name: "admin-approval___sc"
  }, {
    path: "/sc/admin/cancelEvent",
    component: _8ee5dd24,
    name: "admin-cancelEvent___sc"
  }, {
    path: "/sc/admin/category",
    component: _4c197534,
    name: "admin-category___sc"
  }, {
    path: "/sc/admin/change_request",
    component: _658c45b0,
    name: "admin-change_request___sc"
  }, {
    path: "/sc/admin/config",
    component: _117d55ea,
    name: "admin-config___sc"
  }, {
    path: "/sc/admin/contact",
    component: _f2f4e264,
    name: "admin-contact___sc"
  }, {
    path: "/sc/admin/emailPreview",
    component: _e7d82e18,
    name: "admin-emailPreview___sc"
  }, {
    path: "/sc/admin/emailTemplate",
    component: _c4baa5b8,
    name: "admin-emailTemplate___sc"
  }, {
    path: "/sc/admin/event",
    component: _831754f0,
    name: "admin-event___sc"
  }, {
    path: "/sc/admin/feedback",
    component: _670191a6,
    name: "admin-feedback___sc"
  }, {
    path: "/sc/admin/news",
    component: _64b5fb7b,
    name: "admin-news___sc"
  }, {
    path: "/sc/admin/other",
    component: _4c687304,
    name: "admin-other___sc"
  }, {
    path: "/sc/admin/page",
    component: _626d4f92,
    name: "admin-page___sc"
  }, {
    path: "/sc/admin/role",
    component: _77b0fd44,
    name: "admin-role___sc"
  }, {
    path: "/sc/admin/scheme",
    component: _a0ccaee6,
    name: "admin-scheme___sc"
  }, {
    path: "/sc/admin/school",
    component: _225c179c,
    name: "admin-school___sc"
  }, {
    path: "/sc/admin/schoolApplicationRecord",
    component: _7fbc351b,
    name: "admin-schoolApplicationRecord___sc"
  }, {
    path: "/sc/admin/smsTemplate",
    component: _82177b3e,
    name: "admin-smsTemplate___sc"
  }, {
    path: "/sc/admin/user",
    component: _7d8e8673,
    name: "admin-user___sc"
  }, {
    path: "/sc/admin/venue",
    component: _d54771c6,
    name: "admin-venue___sc"
  }, {
    path: "/sc/password/forgot",
    component: _af5ebffc,
    name: "password-forgot___sc"
  }, {
    path: "/sc/settings/account",
    component: _63de78e6,
    name: "settings-account___sc"
  }, {
    path: "/sc/settings/password",
    component: _09603d9c,
    name: "settings-password___sc"
  }, {
    path: "/tc/admin/approval",
    component: _d28a472a,
    name: "admin-approval___tc"
  }, {
    path: "/tc/admin/cancelEvent",
    component: _8ee5dd24,
    name: "admin-cancelEvent___tc"
  }, {
    path: "/tc/admin/category",
    component: _4c197534,
    name: "admin-category___tc"
  }, {
    path: "/tc/admin/change_request",
    component: _658c45b0,
    name: "admin-change_request___tc"
  }, {
    path: "/tc/admin/config",
    component: _117d55ea,
    name: "admin-config___tc"
  }, {
    path: "/tc/admin/contact",
    component: _f2f4e264,
    name: "admin-contact___tc"
  }, {
    path: "/tc/admin/emailPreview",
    component: _e7d82e18,
    name: "admin-emailPreview___tc"
  }, {
    path: "/tc/admin/emailTemplate",
    component: _c4baa5b8,
    name: "admin-emailTemplate___tc"
  }, {
    path: "/tc/admin/event",
    component: _831754f0,
    name: "admin-event___tc"
  }, {
    path: "/tc/admin/feedback",
    component: _670191a6,
    name: "admin-feedback___tc"
  }, {
    path: "/tc/admin/news",
    component: _64b5fb7b,
    name: "admin-news___tc"
  }, {
    path: "/tc/admin/other",
    component: _4c687304,
    name: "admin-other___tc"
  }, {
    path: "/tc/admin/page",
    component: _626d4f92,
    name: "admin-page___tc"
  }, {
    path: "/tc/admin/role",
    component: _77b0fd44,
    name: "admin-role___tc"
  }, {
    path: "/tc/admin/scheme",
    component: _a0ccaee6,
    name: "admin-scheme___tc"
  }, {
    path: "/tc/admin/school",
    component: _225c179c,
    name: "admin-school___tc"
  }, {
    path: "/tc/admin/schoolApplicationRecord",
    component: _7fbc351b,
    name: "admin-schoolApplicationRecord___tc"
  }, {
    path: "/tc/admin/smsTemplate",
    component: _82177b3e,
    name: "admin-smsTemplate___tc"
  }, {
    path: "/tc/admin/user",
    component: _7d8e8673,
    name: "admin-user___tc"
  }, {
    path: "/tc/admin/venue",
    component: _d54771c6,
    name: "admin-venue___tc"
  }, {
    path: "/tc/password/forgot",
    component: _af5ebffc,
    name: "password-forgot___tc"
  }, {
    path: "/tc/settings/account",
    component: _63de78e6,
    name: "settings-account___tc"
  }, {
    path: "/tc/settings/password",
    component: _09603d9c,
    name: "settings-password___tc"
  }, {
    path: "/en/admin/application/add",
    component: _573177cd,
    name: "admin-application-add___en"
  }, {
    path: "/en/admin/application/aoa",
    component: _7a1a3362,
    name: "admin-application-aoa___en"
  }, {
    path: "/en/admin/application/school",
    component: _5ffba678,
    name: "admin-application-school___en"
  }, {
    path: "/en/admin/category/add",
    component: _1d83a4b5,
    name: "admin-category-add___en"
  }, {
    path: "/en/admin/change_request/view",
    component: _08572382,
    name: "admin-change_request-view___en"
  }, {
    path: "/en/admin/composeNotification/custom",
    component: _5d831af0,
    name: "admin-composeNotification-custom___en"
  }, {
    path: "/en/admin/contact/add",
    component: _2d69281d,
    name: "admin-contact-add___en"
  }, {
    path: "/en/admin/emailTemplate/add",
    component: _ae74381a,
    name: "admin-emailTemplate-add___en"
  }, {
    path: "/en/admin/event/add",
    component: _71ee5557,
    name: "admin-event-add___en"
  }, {
    path: "/en/admin/news/add",
    component: _ef9247ec,
    name: "admin-news-add___en"
  }, {
    path: "/en/admin/other/add",
    component: _998ce866,
    name: "admin-other-add___en"
  }, {
    path: "/en/admin/page/add",
    component: _4acb51c9,
    name: "admin-page-add___en"
  }, {
    path: "/en/admin/role/add",
    component: _45f302a6,
    name: "admin-role-add___en"
  }, {
    path: "/en/admin/scheme/add",
    component: _a0a0f1c2,
    name: "admin-scheme-add___en"
  }, {
    path: "/en/admin/school/add",
    component: _7170e72a,
    name: "admin-school-add___en"
  }, {
    path: "/en/admin/schoolApplicationRecord/add",
    component: _b68204ac,
    name: "admin-schoolApplicationRecord-add___en"
  }, {
    path: "/en/admin/smsTemplate/add",
    component: _5b05a470,
    name: "admin-smsTemplate-add___en"
  }, {
    path: "/en/admin/user/add",
    component: _2125c502,
    name: "admin-user-add___en"
  }, {
    path: "/en/admin/venue/add",
    component: _0040afa8,
    name: "admin-venue-add___en"
  }, {
    path: "/sc/admin/application/add",
    component: _573177cd,
    name: "admin-application-add___sc"
  }, {
    path: "/sc/admin/application/aoa",
    component: _7a1a3362,
    name: "admin-application-aoa___sc"
  }, {
    path: "/sc/admin/application/school",
    component: _5ffba678,
    name: "admin-application-school___sc"
  }, {
    path: "/sc/admin/category/add",
    component: _1d83a4b5,
    name: "admin-category-add___sc"
  }, {
    path: "/sc/admin/change_request/view",
    component: _08572382,
    name: "admin-change_request-view___sc"
  }, {
    path: "/sc/admin/composeNotification/custom",
    component: _5d831af0,
    name: "admin-composeNotification-custom___sc"
  }, {
    path: "/sc/admin/contact/add",
    component: _2d69281d,
    name: "admin-contact-add___sc"
  }, {
    path: "/sc/admin/emailTemplate/add",
    component: _ae74381a,
    name: "admin-emailTemplate-add___sc"
  }, {
    path: "/sc/admin/event/add",
    component: _71ee5557,
    name: "admin-event-add___sc"
  }, {
    path: "/sc/admin/news/add",
    component: _ef9247ec,
    name: "admin-news-add___sc"
  }, {
    path: "/sc/admin/other/add",
    component: _998ce866,
    name: "admin-other-add___sc"
  }, {
    path: "/sc/admin/page/add",
    component: _4acb51c9,
    name: "admin-page-add___sc"
  }, {
    path: "/sc/admin/role/add",
    component: _45f302a6,
    name: "admin-role-add___sc"
  }, {
    path: "/sc/admin/scheme/add",
    component: _a0a0f1c2,
    name: "admin-scheme-add___sc"
  }, {
    path: "/sc/admin/school/add",
    component: _7170e72a,
    name: "admin-school-add___sc"
  }, {
    path: "/sc/admin/schoolApplicationRecord/add",
    component: _b68204ac,
    name: "admin-schoolApplicationRecord-add___sc"
  }, {
    path: "/sc/admin/smsTemplate/add",
    component: _5b05a470,
    name: "admin-smsTemplate-add___sc"
  }, {
    path: "/sc/admin/user/add",
    component: _2125c502,
    name: "admin-user-add___sc"
  }, {
    path: "/sc/admin/venue/add",
    component: _0040afa8,
    name: "admin-venue-add___sc"
  }, {
    path: "/tc/admin/application/add",
    component: _573177cd,
    name: "admin-application-add___tc"
  }, {
    path: "/tc/admin/application/aoa",
    component: _7a1a3362,
    name: "admin-application-aoa___tc"
  }, {
    path: "/tc/admin/application/school",
    component: _5ffba678,
    name: "admin-application-school___tc"
  }, {
    path: "/tc/admin/category/add",
    component: _1d83a4b5,
    name: "admin-category-add___tc"
  }, {
    path: "/tc/admin/change_request/view",
    component: _08572382,
    name: "admin-change_request-view___tc"
  }, {
    path: "/tc/admin/composeNotification/custom",
    component: _5d831af0,
    name: "admin-composeNotification-custom___tc"
  }, {
    path: "/tc/admin/contact/add",
    component: _2d69281d,
    name: "admin-contact-add___tc"
  }, {
    path: "/tc/admin/emailTemplate/add",
    component: _ae74381a,
    name: "admin-emailTemplate-add___tc"
  }, {
    path: "/tc/admin/event/add",
    component: _71ee5557,
    name: "admin-event-add___tc"
  }, {
    path: "/tc/admin/news/add",
    component: _ef9247ec,
    name: "admin-news-add___tc"
  }, {
    path: "/tc/admin/other/add",
    component: _998ce866,
    name: "admin-other-add___tc"
  }, {
    path: "/tc/admin/page/add",
    component: _4acb51c9,
    name: "admin-page-add___tc"
  }, {
    path: "/tc/admin/role/add",
    component: _45f302a6,
    name: "admin-role-add___tc"
  }, {
    path: "/tc/admin/scheme/add",
    component: _a0a0f1c2,
    name: "admin-scheme-add___tc"
  }, {
    path: "/tc/admin/school/add",
    component: _7170e72a,
    name: "admin-school-add___tc"
  }, {
    path: "/tc/admin/schoolApplicationRecord/add",
    component: _b68204ac,
    name: "admin-schoolApplicationRecord-add___tc"
  }, {
    path: "/tc/admin/smsTemplate/add",
    component: _5b05a470,
    name: "admin-smsTemplate-add___tc"
  }, {
    path: "/tc/admin/user/add",
    component: _2125c502,
    name: "admin-user-add___tc"
  }, {
    path: "/tc/admin/venue/add",
    component: _0040afa8,
    name: "admin-venue-add___tc"
  }, {
    path: "/en/admin/application/aoa/all",
    component: _99453a9e,
    name: "admin-application-aoa-all___en"
  }, {
    path: "/en/admin/application/spaps/ballot_mark",
    component: _7dc2a74c,
    name: "admin-application-spaps-ballot_mark___en"
  }, {
    path: "/en/admin/emailTemplate/compose/custom",
    component: _740f14ac,
    name: "admin-emailTemplate-compose-custom___en"
  }, {
    path: "/en/admin/smsTemplate/compose/custom",
    component: _3235140f,
    name: "admin-smsTemplate-compose-custom___en"
  }, {
    path: "/en/event/apply/aoa/tempest",
    component: _61cc19aa,
    name: "event-apply-aoa-tempest___en"
  }, {
    path: "/sc/admin/application/aoa/all",
    component: _99453a9e,
    name: "admin-application-aoa-all___sc"
  }, {
    path: "/sc/admin/application/spaps/ballot_mark",
    component: _7dc2a74c,
    name: "admin-application-spaps-ballot_mark___sc"
  }, {
    path: "/sc/admin/emailTemplate/compose/custom",
    component: _740f14ac,
    name: "admin-emailTemplate-compose-custom___sc"
  }, {
    path: "/sc/admin/smsTemplate/compose/custom",
    component: _3235140f,
    name: "admin-smsTemplate-compose-custom___sc"
  }, {
    path: "/sc/event/apply/aoa/tempest",
    component: _61cc19aa,
    name: "event-apply-aoa-tempest___sc"
  }, {
    path: "/tc/admin/application/aoa/all",
    component: _99453a9e,
    name: "admin-application-aoa-all___tc"
  }, {
    path: "/tc/admin/application/spaps/ballot_mark",
    component: _7dc2a74c,
    name: "admin-application-spaps-ballot_mark___tc"
  }, {
    path: "/tc/admin/emailTemplate/compose/custom",
    component: _740f14ac,
    name: "admin-emailTemplate-compose-custom___tc"
  }, {
    path: "/tc/admin/smsTemplate/compose/custom",
    component: _3235140f,
    name: "admin-smsTemplate-compose-custom___tc"
  }, {
    path: "/tc/event/apply/aoa/tempest",
    component: _61cc19aa,
    name: "event-apply-aoa-tempest___tc"
  }, {
    path: "/en/admin/config/customizeHomepageLastModify/edit/:id?",
    component: _bdbd060a,
    name: "admin-config-customizeHomepageLastModify-edit-id___en"
  }, {
    path: "/en/admin/config/lcsdRelatedWeb/edit/:id?",
    component: _3db619cb,
    name: "admin-config-lcsdRelatedWeb-edit-id___en"
  }, {
    path: "/en/admin/config/schemeType/edit/:id?",
    component: _77fbe55d,
    name: "admin-config-schemeType-edit-id___en"
  }, {
    path: "/en/admin/config/suggestedEvents/edit/:id?",
    component: _7039fc40,
    name: "admin-config-suggestedEvents-edit-id___en"
  }, {
    path: "/sc/admin/config/customizeHomepageLastModify/edit/:id?",
    component: _bdbd060a,
    name: "admin-config-customizeHomepageLastModify-edit-id___sc"
  }, {
    path: "/sc/admin/config/lcsdRelatedWeb/edit/:id?",
    component: _3db619cb,
    name: "admin-config-lcsdRelatedWeb-edit-id___sc"
  }, {
    path: "/sc/admin/config/schemeType/edit/:id?",
    component: _77fbe55d,
    name: "admin-config-schemeType-edit-id___sc"
  }, {
    path: "/sc/admin/config/suggestedEvents/edit/:id?",
    component: _7039fc40,
    name: "admin-config-suggestedEvents-edit-id___sc"
  }, {
    path: "/tc/admin/config/customizeHomepageLastModify/edit/:id?",
    component: _bdbd060a,
    name: "admin-config-customizeHomepageLastModify-edit-id___tc"
  }, {
    path: "/tc/admin/config/lcsdRelatedWeb/edit/:id?",
    component: _3db619cb,
    name: "admin-config-lcsdRelatedWeb-edit-id___tc"
  }, {
    path: "/tc/admin/config/schemeType/edit/:id?",
    component: _77fbe55d,
    name: "admin-config-schemeType-edit-id___tc"
  }, {
    path: "/tc/admin/config/suggestedEvents/edit/:id?",
    component: _7039fc40,
    name: "admin-config-suggestedEvents-edit-id___tc"
  }, {
    path: "/en/admin/application/aes/:slug",
    component: _215b63f9,
    name: "admin-application-aes-slug___en"
  }, {
    path: "/en/admin/application/all/:slug?",
    component: _71bb5f70,
    name: "admin-application-all-slug___en"
  }, {
    path: "/en/admin/application/scds/:slug",
    component: _d051333e,
    name: "admin-application-scds-slug___en"
  }, {
    path: "/en/admin/application/spaps/:slug",
    component: _58428e5e,
    name: "admin-application-spaps-slug___en"
  }, {
    path: "/en/admin/category/copy/:id?",
    component: _1438eb88,
    name: "admin-category-copy-id___en"
  }, {
    path: "/en/admin/category/edit/:id?",
    component: _0c1ae3d1,
    name: "admin-category-edit-id___en"
  }, {
    path: "/en/admin/config/edit/:id?",
    component: _37d2d4cd,
    name: "admin-config-edit-id___en"
  }, {
    path: "/en/admin/contact/edit/:id?",
    component: _5fa74169,
    name: "admin-contact-edit-id___en"
  }, {
    path: "/en/admin/emailTemplate/compose/:id?",
    component: _90f269e2,
    name: "admin-emailTemplate-compose-id___en"
  }, {
    path: "/en/admin/emailTemplate/copy/:id?",
    component: _0efd21be,
    name: "admin-emailTemplate-copy-id___en"
  }, {
    path: "/en/admin/emailTemplate/edit/:id?",
    component: _25347b53,
    name: "admin-emailTemplate-edit-id___en"
  }, {
    path: "/en/admin/event/copy/:id?",
    component: _3d17eeda,
    name: "admin-event-copy-id___en"
  }, {
    path: "/en/admin/event/edit/:id?",
    component: _534f486f,
    name: "admin-event-edit-id___en"
  }, {
    path: "/en/admin/feedback/view/:id?",
    component: _62fa6b76,
    name: "admin-feedback-view-id___en"
  }, {
    path: "/en/admin/news/copy/:id?",
    component: _a2b2bff2,
    name: "admin-news-copy-id___en"
  }, {
    path: "/en/admin/news/edit/:id?",
    component: _76440cc8,
    name: "admin-news-edit-id___en"
  }, {
    path: "/en/admin/other/copy/:id?",
    component: _01ba5224,
    name: "admin-other-copy-id___en"
  }, {
    path: "/en/admin/other/edit/:id?",
    component: _17f1abb9,
    name: "admin-other-edit-id___en"
  }, {
    path: "/en/admin/page/edit/:id?",
    component: _2fdad940,
    name: "admin-page-edit-id___en"
  }, {
    path: "/en/admin/role/edit/:id?",
    component: _beacf64e,
    name: "admin-role-edit-id___en"
  }, {
    path: "/en/admin/scheme/add/:id",
    component: _4d62c547,
    name: "admin-scheme-add-id___en"
  }, {
    path: "/en/admin/scheme/applyNotes/:id?",
    component: _02cc5b1a,
    name: "admin-scheme-applyNotes-id___en"
  }, {
    path: "/en/admin/scheme/edit/:id?",
    component: _8c71496c,
    name: "admin-scheme-edit-id___en"
  }, {
    path: "/en/admin/school/edit/:id?",
    component: _6a415ddb,
    name: "admin-school-edit-id___en"
  }, {
    path: "/en/admin/schoolApplicationRecord/edit/:id?",
    component: _c36c4808,
    name: "admin-schoolApplicationRecord-edit-id___en"
  }, {
    path: "/en/admin/smsTemplate/compose/:id?",
    component: _7370be8c,
    name: "admin-smsTemplate-compose-id___en"
  }, {
    path: "/en/admin/smsTemplate/edit/:id?",
    component: _7a1a80f6,
    name: "admin-smsTemplate-edit-id___en"
  }, {
    path: "/en/admin/user/edit/:id?",
    component: _1c31eba4,
    name: "admin-user-edit-id___en"
  }, {
    path: "/en/admin/venue/copy/:id?",
    component: _16a21925,
    name: "admin-venue-copy-id___en"
  }, {
    path: "/en/admin/venue/edit/:id?",
    component: _2cd972ba,
    name: "admin-venue-edit-id___en"
  }, {
    path: "/en/application/aes/change_request/:trnId?",
    component: _1bc320c7,
    name: "application-aes-change_request-trnId___en"
  }, {
    path: "/en/application/scds/change_request/:id?",
    component: _0d9502f2,
    name: "application-scds-change_request-id___en"
  }, {
    path: "/en/application/spaps/change_request/:trnId?",
    component: _3373f0c2,
    name: "application-spaps-change_request-trnId___en"
  }, {
    path: "/en/event/apply/aes/:id?",
    component: _7b4dd70f,
    name: "event-apply-aes-id___en"
  }, {
    path: "/en/event/apply/scds/:id?",
    component: _3daa3a76,
    name: "event-apply-scds-id___en"
  }, {
    path: "/en/event/apply/spaps/:id?",
    component: _21f8a4b2,
    name: "event-apply-spaps-id___en"
  }, {
    path: "/sc/admin/application/aes/:slug",
    component: _215b63f9,
    name: "admin-application-aes-slug___sc"
  }, {
    path: "/sc/admin/application/all/:slug?",
    component: _71bb5f70,
    name: "admin-application-all-slug___sc"
  }, {
    path: "/sc/admin/application/scds/:slug",
    component: _d051333e,
    name: "admin-application-scds-slug___sc"
  }, {
    path: "/sc/admin/application/spaps/:slug",
    component: _58428e5e,
    name: "admin-application-spaps-slug___sc"
  }, {
    path: "/sc/admin/category/copy/:id?",
    component: _1438eb88,
    name: "admin-category-copy-id___sc"
  }, {
    path: "/sc/admin/category/edit/:id?",
    component: _0c1ae3d1,
    name: "admin-category-edit-id___sc"
  }, {
    path: "/sc/admin/config/edit/:id?",
    component: _37d2d4cd,
    name: "admin-config-edit-id___sc"
  }, {
    path: "/sc/admin/contact/edit/:id?",
    component: _5fa74169,
    name: "admin-contact-edit-id___sc"
  }, {
    path: "/sc/admin/emailTemplate/compose/:id?",
    component: _90f269e2,
    name: "admin-emailTemplate-compose-id___sc"
  }, {
    path: "/sc/admin/emailTemplate/copy/:id?",
    component: _0efd21be,
    name: "admin-emailTemplate-copy-id___sc"
  }, {
    path: "/sc/admin/emailTemplate/edit/:id?",
    component: _25347b53,
    name: "admin-emailTemplate-edit-id___sc"
  }, {
    path: "/sc/admin/event/copy/:id?",
    component: _3d17eeda,
    name: "admin-event-copy-id___sc"
  }, {
    path: "/sc/admin/event/edit/:id?",
    component: _534f486f,
    name: "admin-event-edit-id___sc"
  }, {
    path: "/sc/admin/feedback/view/:id?",
    component: _62fa6b76,
    name: "admin-feedback-view-id___sc"
  }, {
    path: "/sc/admin/news/copy/:id?",
    component: _a2b2bff2,
    name: "admin-news-copy-id___sc"
  }, {
    path: "/sc/admin/news/edit/:id?",
    component: _76440cc8,
    name: "admin-news-edit-id___sc"
  }, {
    path: "/sc/admin/other/copy/:id?",
    component: _01ba5224,
    name: "admin-other-copy-id___sc"
  }, {
    path: "/sc/admin/other/edit/:id?",
    component: _17f1abb9,
    name: "admin-other-edit-id___sc"
  }, {
    path: "/sc/admin/page/edit/:id?",
    component: _2fdad940,
    name: "admin-page-edit-id___sc"
  }, {
    path: "/sc/admin/role/edit/:id?",
    component: _beacf64e,
    name: "admin-role-edit-id___sc"
  }, {
    path: "/sc/admin/scheme/add/:id",
    component: _4d62c547,
    name: "admin-scheme-add-id___sc"
  }, {
    path: "/sc/admin/scheme/applyNotes/:id?",
    component: _02cc5b1a,
    name: "admin-scheme-applyNotes-id___sc"
  }, {
    path: "/sc/admin/scheme/edit/:id?",
    component: _8c71496c,
    name: "admin-scheme-edit-id___sc"
  }, {
    path: "/sc/admin/school/edit/:id?",
    component: _6a415ddb,
    name: "admin-school-edit-id___sc"
  }, {
    path: "/sc/admin/schoolApplicationRecord/edit/:id?",
    component: _c36c4808,
    name: "admin-schoolApplicationRecord-edit-id___sc"
  }, {
    path: "/sc/admin/smsTemplate/compose/:id?",
    component: _7370be8c,
    name: "admin-smsTemplate-compose-id___sc"
  }, {
    path: "/sc/admin/smsTemplate/edit/:id?",
    component: _7a1a80f6,
    name: "admin-smsTemplate-edit-id___sc"
  }, {
    path: "/sc/admin/user/edit/:id?",
    component: _1c31eba4,
    name: "admin-user-edit-id___sc"
  }, {
    path: "/sc/admin/venue/copy/:id?",
    component: _16a21925,
    name: "admin-venue-copy-id___sc"
  }, {
    path: "/sc/admin/venue/edit/:id?",
    component: _2cd972ba,
    name: "admin-venue-edit-id___sc"
  }, {
    path: "/sc/application/aes/change_request/:trnId?",
    component: _1bc320c7,
    name: "application-aes-change_request-trnId___sc"
  }, {
    path: "/sc/application/scds/change_request/:id?",
    component: _0d9502f2,
    name: "application-scds-change_request-id___sc"
  }, {
    path: "/sc/application/spaps/change_request/:trnId?",
    component: _3373f0c2,
    name: "application-spaps-change_request-trnId___sc"
  }, {
    path: "/sc/event/apply/aes/:id?",
    component: _7b4dd70f,
    name: "event-apply-aes-id___sc"
  }, {
    path: "/sc/event/apply/scds/:id?",
    component: _3daa3a76,
    name: "event-apply-scds-id___sc"
  }, {
    path: "/sc/event/apply/spaps/:id?",
    component: _21f8a4b2,
    name: "event-apply-spaps-id___sc"
  }, {
    path: "/tc/admin/application/aes/:slug",
    component: _215b63f9,
    name: "admin-application-aes-slug___tc"
  }, {
    path: "/tc/admin/application/all/:slug?",
    component: _71bb5f70,
    name: "admin-application-all-slug___tc"
  }, {
    path: "/tc/admin/application/scds/:slug",
    component: _d051333e,
    name: "admin-application-scds-slug___tc"
  }, {
    path: "/tc/admin/application/spaps/:slug",
    component: _58428e5e,
    name: "admin-application-spaps-slug___tc"
  }, {
    path: "/tc/admin/category/copy/:id?",
    component: _1438eb88,
    name: "admin-category-copy-id___tc"
  }, {
    path: "/tc/admin/category/edit/:id?",
    component: _0c1ae3d1,
    name: "admin-category-edit-id___tc"
  }, {
    path: "/tc/admin/config/edit/:id?",
    component: _37d2d4cd,
    name: "admin-config-edit-id___tc"
  }, {
    path: "/tc/admin/contact/edit/:id?",
    component: _5fa74169,
    name: "admin-contact-edit-id___tc"
  }, {
    path: "/tc/admin/emailTemplate/compose/:id?",
    component: _90f269e2,
    name: "admin-emailTemplate-compose-id___tc"
  }, {
    path: "/tc/admin/emailTemplate/copy/:id?",
    component: _0efd21be,
    name: "admin-emailTemplate-copy-id___tc"
  }, {
    path: "/tc/admin/emailTemplate/edit/:id?",
    component: _25347b53,
    name: "admin-emailTemplate-edit-id___tc"
  }, {
    path: "/tc/admin/event/copy/:id?",
    component: _3d17eeda,
    name: "admin-event-copy-id___tc"
  }, {
    path: "/tc/admin/event/edit/:id?",
    component: _534f486f,
    name: "admin-event-edit-id___tc"
  }, {
    path: "/tc/admin/feedback/view/:id?",
    component: _62fa6b76,
    name: "admin-feedback-view-id___tc"
  }, {
    path: "/tc/admin/news/copy/:id?",
    component: _a2b2bff2,
    name: "admin-news-copy-id___tc"
  }, {
    path: "/tc/admin/news/edit/:id?",
    component: _76440cc8,
    name: "admin-news-edit-id___tc"
  }, {
    path: "/tc/admin/other/copy/:id?",
    component: _01ba5224,
    name: "admin-other-copy-id___tc"
  }, {
    path: "/tc/admin/other/edit/:id?",
    component: _17f1abb9,
    name: "admin-other-edit-id___tc"
  }, {
    path: "/tc/admin/page/edit/:id?",
    component: _2fdad940,
    name: "admin-page-edit-id___tc"
  }, {
    path: "/tc/admin/role/edit/:id?",
    component: _beacf64e,
    name: "admin-role-edit-id___tc"
  }, {
    path: "/tc/admin/scheme/add/:id",
    component: _4d62c547,
    name: "admin-scheme-add-id___tc"
  }, {
    path: "/tc/admin/scheme/applyNotes/:id?",
    component: _02cc5b1a,
    name: "admin-scheme-applyNotes-id___tc"
  }, {
    path: "/tc/admin/scheme/edit/:id?",
    component: _8c71496c,
    name: "admin-scheme-edit-id___tc"
  }, {
    path: "/tc/admin/school/edit/:id?",
    component: _6a415ddb,
    name: "admin-school-edit-id___tc"
  }, {
    path: "/tc/admin/schoolApplicationRecord/edit/:id?",
    component: _c36c4808,
    name: "admin-schoolApplicationRecord-edit-id___tc"
  }, {
    path: "/tc/admin/smsTemplate/compose/:id?",
    component: _7370be8c,
    name: "admin-smsTemplate-compose-id___tc"
  }, {
    path: "/tc/admin/smsTemplate/edit/:id?",
    component: _7a1a80f6,
    name: "admin-smsTemplate-edit-id___tc"
  }, {
    path: "/tc/admin/user/edit/:id?",
    component: _1c31eba4,
    name: "admin-user-edit-id___tc"
  }, {
    path: "/tc/admin/venue/copy/:id?",
    component: _16a21925,
    name: "admin-venue-copy-id___tc"
  }, {
    path: "/tc/admin/venue/edit/:id?",
    component: _2cd972ba,
    name: "admin-venue-edit-id___tc"
  }, {
    path: "/tc/application/aes/change_request/:trnId?",
    component: _1bc320c7,
    name: "application-aes-change_request-trnId___tc"
  }, {
    path: "/tc/application/scds/change_request/:id?",
    component: _0d9502f2,
    name: "application-scds-change_request-id___tc"
  }, {
    path: "/tc/application/spaps/change_request/:trnId?",
    component: _3373f0c2,
    name: "application-spaps-change_request-trnId___tc"
  }, {
    path: "/tc/event/apply/aes/:id?",
    component: _7b4dd70f,
    name: "event-apply-aes-id___tc"
  }, {
    path: "/tc/event/apply/scds/:id?",
    component: _3daa3a76,
    name: "event-apply-scds-id___tc"
  }, {
    path: "/tc/event/apply/spaps/:id?",
    component: _21f8a4b2,
    name: "event-apply-spaps-id___tc"
  }, {
    path: "/en/admin/application/aes/:slug?/add",
    component: _0f784c08,
    name: "admin-application-aes-slug-add___en"
  }, {
    path: "/en/admin/application/aes/:slug?/all",
    component: _1d8fcd08,
    name: "admin-application-aes-slug-all___en"
  }, {
    path: "/en/admin/application/scds/:slug?/add",
    component: _cb3c6f20,
    name: "admin-application-scds-slug-add___en"
  }, {
    path: "/en/admin/application/scds/:slug?/all",
    component: _af0d6d20,
    name: "admin-application-scds-slug-all___en"
  }, {
    path: "/en/admin/application/spaps/:slug?/add",
    component: _500a1240,
    name: "admin-application-spaps-slug-add___en"
  }, {
    path: "/en/admin/application/spaps/:slug?/all",
    component: _33db1040,
    name: "admin-application-spaps-slug-all___en"
  }, {
    path: "/sc/admin/application/aes/:slug?/add",
    component: _0f784c08,
    name: "admin-application-aes-slug-add___sc"
  }, {
    path: "/sc/admin/application/aes/:slug?/all",
    component: _1d8fcd08,
    name: "admin-application-aes-slug-all___sc"
  }, {
    path: "/sc/admin/application/scds/:slug?/add",
    component: _cb3c6f20,
    name: "admin-application-scds-slug-add___sc"
  }, {
    path: "/sc/admin/application/scds/:slug?/all",
    component: _af0d6d20,
    name: "admin-application-scds-slug-all___sc"
  }, {
    path: "/sc/admin/application/spaps/:slug?/add",
    component: _500a1240,
    name: "admin-application-spaps-slug-add___sc"
  }, {
    path: "/sc/admin/application/spaps/:slug?/all",
    component: _33db1040,
    name: "admin-application-spaps-slug-all___sc"
  }, {
    path: "/tc/admin/application/aes/:slug?/add",
    component: _0f784c08,
    name: "admin-application-aes-slug-add___tc"
  }, {
    path: "/tc/admin/application/aes/:slug?/all",
    component: _1d8fcd08,
    name: "admin-application-aes-slug-all___tc"
  }, {
    path: "/tc/admin/application/scds/:slug?/add",
    component: _cb3c6f20,
    name: "admin-application-scds-slug-add___tc"
  }, {
    path: "/tc/admin/application/scds/:slug?/all",
    component: _af0d6d20,
    name: "admin-application-scds-slug-all___tc"
  }, {
    path: "/tc/admin/application/spaps/:slug?/add",
    component: _500a1240,
    name: "admin-application-spaps-slug-add___tc"
  }, {
    path: "/tc/admin/application/spaps/:slug?/all",
    component: _33db1040,
    name: "admin-application-spaps-slug-all___tc"
  }, {
    path: "/en/admin/emailPreview/:id",
    component: _09af985c,
    name: "admin-emailPreview-id___en"
  }, {
    path: "/en/application/aes/:trnId?",
    component: _039839a0,
    name: "application-aes-trnId___en"
  }, {
    path: "/en/application/scds/:id?",
    component: _25e4ca8e,
    name: "application-scds-id___en"
  }, {
    path: "/en/application/spaps/:trnId?",
    component: _588b2758,
    name: "application-spaps-trnId___en"
  }, {
    path: "/en/event/apply/:id?",
    component: _dcb53262,
    name: "event-apply-id___en"
  }, {
    path: "/en/password/reset/:token?",
    component: _a190fc4e,
    name: "password-reset-token___en"
  }, {
    path: "/en/shoppingCart/aes/:id?",
    component: _6cf6cb38,
    name: "shoppingCart-aes-id___en"
  }, {
    path: "/en/shoppingCart/scds/:id?",
    component: _24a073bc,
    name: "shoppingCart-scds-id___en"
  }, {
    path: "/en/shoppingCart/spaps/:id?",
    component: _1a403b90,
    name: "shoppingCart-spaps-id___en"
  }, {
    path: "/sc/admin/emailPreview/:id",
    component: _09af985c,
    name: "admin-emailPreview-id___sc"
  }, {
    path: "/sc/application/aes/:trnId?",
    component: _039839a0,
    name: "application-aes-trnId___sc"
  }, {
    path: "/sc/application/scds/:id?",
    component: _25e4ca8e,
    name: "application-scds-id___sc"
  }, {
    path: "/sc/application/spaps/:trnId?",
    component: _588b2758,
    name: "application-spaps-trnId___sc"
  }, {
    path: "/sc/event/apply/:id?",
    component: _dcb53262,
    name: "event-apply-id___sc"
  }, {
    path: "/sc/password/reset/:token?",
    component: _a190fc4e,
    name: "password-reset-token___sc"
  }, {
    path: "/sc/shoppingCart/aes/:id?",
    component: _6cf6cb38,
    name: "shoppingCart-aes-id___sc"
  }, {
    path: "/sc/shoppingCart/scds/:id?",
    component: _24a073bc,
    name: "shoppingCart-scds-id___sc"
  }, {
    path: "/sc/shoppingCart/spaps/:id?",
    component: _1a403b90,
    name: "shoppingCart-spaps-id___sc"
  }, {
    path: "/tc/admin/emailPreview/:id",
    component: _09af985c,
    name: "admin-emailPreview-id___tc"
  }, {
    path: "/tc/application/aes/:trnId?",
    component: _039839a0,
    name: "application-aes-trnId___tc"
  }, {
    path: "/tc/application/scds/:id?",
    component: _25e4ca8e,
    name: "application-scds-id___tc"
  }, {
    path: "/tc/application/spaps/:trnId?",
    component: _588b2758,
    name: "application-spaps-trnId___tc"
  }, {
    path: "/tc/event/apply/:id?",
    component: _dcb53262,
    name: "event-apply-id___tc"
  }, {
    path: "/tc/password/reset/:token?",
    component: _a190fc4e,
    name: "password-reset-token___tc"
  }, {
    path: "/tc/shoppingCart/aes/:id?",
    component: _6cf6cb38,
    name: "shoppingCart-aes-id___tc"
  }, {
    path: "/tc/shoppingCart/scds/:id?",
    component: _24a073bc,
    name: "shoppingCart-scds-id___tc"
  }, {
    path: "/tc/shoppingCart/spaps/:id?",
    component: _1a403b90,
    name: "shoppingCart-spaps-id___tc"
  }, {
    path: "/en/category/:slug?",
    component: _6fba89c4,
    name: "category-slug___en"
  }, {
    path: "/en/event/:code",
    component: _44e84f82,
    name: "event-code___en"
  }, {
    path: "/en/scheme/:slug",
    component: _2bc245f0,
    name: "scheme-slug___en"
  }, {
    path: "/sc/category/:slug?",
    component: _6fba89c4,
    name: "category-slug___sc"
  }, {
    path: "/sc/event/:code",
    component: _44e84f82,
    name: "event-code___sc"
  }, {
    path: "/sc/scheme/:slug",
    component: _2bc245f0,
    name: "scheme-slug___sc"
  }, {
    path: "/tc/category/:slug?",
    component: _6fba89c4,
    name: "category-slug___tc"
  }, {
    path: "/tc/event/:code",
    component: _44e84f82,
    name: "event-code___tc"
  }, {
    path: "/tc/scheme/:slug",
    component: _2bc245f0,
    name: "scheme-slug___tc"
  }, {
    path: "/en/scheme/:slug/:subscheme",
    component: _fabcf3c0,
    name: "scheme-slug-subscheme___en"
  }, {
    path: "/sc/scheme/:slug/:subscheme",
    component: _fabcf3c0,
    name: "scheme-slug-subscheme___sc"
  }, {
    path: "/tc/scheme/:slug/:subscheme",
    component: _fabcf3c0,
    name: "scheme-slug-subscheme___tc"
  }, {
    path: "/en/:slug",
    component: _3438f8b5,
    name: "slug___en"
  }, {
    path: "/sc/:slug",
    component: _3438f8b5,
    name: "slug___sc"
  }, {
    path: "/tc/:slug",
    component: _3438f8b5,
    name: "slug___tc"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
