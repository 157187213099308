/* Deprecated */
<template>
  <div></div>
  <!-- <vue-editor
    :id="id"
    v-model="content"
    use-custom-image-handler
    @image-added="handleImageAdded"
    @change="changeContent"
  ></vue-editor> -->
</template>
<script>
// import { VueEditor } from 'vue2-editor'

export default {
  /* components: { VueEditor },
  props: {
    id: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newContent: this.content,
      newId: this.id,
    }
  },
  watch: {},
  methods: {
    changeContent() {
      this.$nuxt.$emit('changeContent', this.content)
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)
      try {
        const formData = new FormData()
        formData.append('image', file)
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}upload`,
          formData
        )
        if (result.error !== undefined) throw new Error(result.error)
        const url = result.data.url // Get url from response
        Editor.insertEmbed(cursorLocation, 'image', url)
        resetUploader()
      } catch (err) {
        // console.log(err)
      }
    },
  }, */
}
</script>
