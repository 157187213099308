var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "newsForm" },
    [
      _c(
        "b-form-group",
        { attrs: { id: "to", "label-for": "to" } },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("mailTemplate.to")) + "\n    "),
          _c("b-form-input", {
            attrs: { type: "text" },
            model: {
              value: _vm.receiver,
              callback: function ($$v) {
                _vm.receiver = $$v
              },
              expression: "receiver",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { id: "cc", "label-for": "cc" } },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("mailTemplate.cc")) + "\n    "),
          _c("b-form-input", {
            attrs: { type: "text" },
            model: {
              value: _vm.cc,
              callback: function ($$v) {
                _vm.cc = $$v
              },
              expression: "cc",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { id: "bcc", "label-for": "bcc" } },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("mailTemplate.bcc")) + "\n    "),
          _c("b-form-input", {
            attrs: { type: "text" },
            model: {
              value: _vm.bcc,
              callback: function ($$v) {
                _vm.bcc = $$v
              },
              expression: "bcc",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        { attrs: { id: "subject", "label-for": "subject" } },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("mailTemplate.subject")) + "\n    "),
          _c("b-form-input", {
            attrs: { type: "text" },
            model: {
              value: _vm.subject,
              callback: function ($$v) {
                _vm.subject = $$v
              },
              expression: "subject",
            },
          }),
        ],
        1
      ),
      _vm._v("\n\n  " + _vm._s(_vm.$t("mailTemplate.content")) + "\n  "),
      _c("b-form-group", { attrs: { id: "content", "label-for": "content" } }, [
        _c(
          "div",
          [
            _c("tinymceEditorFlexible", {
              ref: "tinymce",
              attrs: { id: "content", value: _vm.content },
              on: {
                writeContent: _vm.getContent,
                editorInit: (e) => e.setContent(_vm.content),
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }