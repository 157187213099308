var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navmenu",
      class: _vm.mNavDisplay === false ? "m-hide" : "m-show",
    },
    [
      _c(
        "b-nav",
        { staticClass: "navbar-nav", attrs: { vertical: "" } },
        [
          _c(
            "b-nav-item",
            {
              staticClass: "nav-first-child",
              class: _vm.togglenav,
              on: { click: _vm.toggleNav },
            },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                _c("div", { staticClass: "nav-item-obj" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 98.67 94.42",
                      },
                    },
                    [
                      _c("defs", [
                        _c("style", [
                          _vm._v(
                            "\n                  .cls-1 {\n                    fill: #000;\n                  }\n                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "g",
                        { attrs: { id: "Layer_2", "data-name": "Layer 2" } },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Layer_1-2-1",
                                "data-name": "Layer 1",
                              },
                            },
                            [
                              _c("path", {
                                staticClass: "cls-1",
                                attrs: {
                                  d: "M94,47.53H90.37V87.16a7.26,7.26,0,0,1-7.26,7.26H69.24a5.4,5.4,0,0,1-5.4-5.4V58.38a5.4,5.4,0,0,0-5.4-5.4H40.24a5.4,5.4,0,0,0-5.4,5.4V89a5.4,5.4,0,0,1-5.4,5.4H15.57a7.26,7.26,0,0,1-7.26-7.26V47.53H4.69a4.68,4.68,0,0,1-3-8.24L46.3,1.13a4.65,4.65,0,0,1,6.08,0L97,39.29A4.68,4.68,0,0,1,94,47.53Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("navbar.home")))]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/about") } }, [
                _c("div", { staticClass: "nav-item-obj" }, [
                  _c(
                    "svg",
                    {
                      staticStyle: {
                        "enable-background": "new 0 0 174.4 174.4",
                      },
                      attrs: {
                        id: "Capa_1",
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 174.4 174.4",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("style", { attrs: { type: "text/css" } }, [
                        _vm._v(
                          "\n                .st0 {\n                  fill: none;\n                  stroke: #fff;\n                  stroke-width: 10;\n                  stroke-miterlimit: 10;\n                }\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("circle", {
                        staticClass: "st0",
                        attrs: { cx: "86.2", cy: "88.2", r: "78" },
                      }),
                      _vm._v(" "),
                      _c("g", [
                        _c("path", {
                          staticClass: "cls-1",
                          attrs: {
                            d: "M102.6,124.7l-1.3,5.3c-3.9,1.5-7,2.7-9.3,3.5c-2.3,0.8-5,1.2-8.1,1.2c-4.7,0-8.4-1.2-11-3.5c-2.6-2.3-3.9-5.2-3.9-8.8\n\t\tc0-1.4,0.1-2.8,0.3-4.2c0.2-1.4,0.5-3.1,1-4.9L75,96.2c0.4-1.7,0.8-3.2,1.1-4.7c0.3-1.5,0.4-2.8,0.4-4c0-2.2-0.5-3.7-1.4-4.6\n\t\tc-0.9-0.9-2.6-1.3-5.2-1.3c-1.3,0-2.6,0.2-3.9,0.6c-1.3,0.4-2.5,0.8-3.4,1.1l1.3-5.3c3.2-1.3,6.3-2.4,9.2-3.3\n\t\tc2.9-0.9,5.7-1.4,8.3-1.4c4.7,0,8.3,1.1,10.9,3.4c2.5,2.3,3.8,5.2,3.8,8.8c0,0.8-0.1,2.1-0.3,4c-0.2,1.9-0.5,3.6-1,5.2L90,111.8\n\t\tc-0.4,1.4-0.8,3-1.1,4.7c-0.3,1.8-0.5,3.1-0.5,4c0,2.3,0.5,3.8,1.5,4.7c1,0.8,2.8,1.2,5.3,1.2c1.2,0,2.5-0.2,4-0.6\n\t\tC100.8,125.4,101.9,125,102.6,124.7z M103.8,52.5c0,3-1.1,5.6-3.4,7.7c-2.3,2.1-5,3.2-8.2,3.2c-3.2,0-5.9-1.1-8.2-3.2\n\t\tc-2.3-2.1-3.4-4.7-3.4-7.7c0-3,1.1-5.6,3.4-7.7c2.3-2.1,5-3.2,8.2-3.2c3.2,0,5.9,1.1,8.2,3.2C102.7,47,103.8,49.5,103.8,52.5z",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("navbar.about")))]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/search") } }, [
                _c("div", { staticClass: "nav-item-obj" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 498.75 498.74",
                      },
                    },
                    [
                      _c("defs", [
                        _c("style", [
                          _vm._v(
                            "\n                  .cls-1 {\n                    fill: #fff;\n                  }\n                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "g",
                        { attrs: { id: "Layer_2", "data-name": "Layer 2" } },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Layer_1-2-2",
                                "data-name": "Layer 1",
                              },
                            },
                            [
                              _c("path", {
                                staticClass: "cls-1",
                                attrs: {
                                  d: "M375.73,330.48A206.64,206.64,0,0,0,416,211.13C417.68,97.87,327.6,3.5,214.38.1,94.68-3.5-3.47,94.62.09,214.31,3.46,327.55,97.82,417.66,211.1,416a206.7,206.7,0,0,0,119.39-40.25L444.13,489.37a32,32,0,0,0,45.25,0h0a32,32,0,0,0,0-45.24L375.73,330.48ZM208,352c-79.41,0-144-64.59-144-144S128.59,64,208,64s144,64.59,144,144S287.4,352,208,352Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("navbar.search")))]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            {
              staticClass: "m-hide-when-wide",
              class: _vm.togglenav,
              on: { click: _vm.accordionNav },
            },
            [
              _c(
                "div",
                {
                  staticClass: "nav-item-obj",
                  class: _vm.schemeAccordionDisplay
                    ? "nuxt-link-exact-active"
                    : "",
                },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { "enable-background": "new 0 0 512 512" },
                      attrs: {
                        id: "Layer_1",
                        version: "1.1",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        x: "0px",
                        y: "0px",
                        viewBox: "0 0 512 512",
                        "xml:space": "preserve",
                      },
                    },
                    [
                      _c("g", [
                        _c("path", {
                          staticClass: "st0 fill",
                          attrs: {
                            d: "M502.4,177.3l-240-104c-4.1-1.8-8.7-1.8-12.7,0l-240,104c-12.8,5.6-12.8,23.8,0,29.4L96,244.1V344\n\t\tc0,53.8,70.3,96,160,96s160-42.2,160-96v-99.9l16-6.9V408c0,8.8,7.2,16,16,16c8.8,0,16-7.2,16-16V223.3l38.4-16.6\n\t\tC515.2,201.1,515.2,182.9,502.4,177.3z M256,105.4L455.8,192l-15.8,6.9L258,176.1c-8.8-1.1-16.8,5.1-17.9,13.9\n\t\tc-1.1,8.8,5.1,16.8,13.9,17.9l128.2,16L256,278.6c-112.9-48.9-88.8-38.5-199.8-86.6L256,105.4z M384,344\n\t\tc0,15.1-12.4,30.6-33.9,42.6c-51.4,28.5-136.6,28.6-188.1,0c-21.6-12-33.9-27.5-33.9-42.6v-86l121.6,52.7c4.1,1.8,8.7,1.8,12.7,0\n\t\tL384,258V344z",
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("home.schemes")))]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "m-nav-accordion",
              class: _vm.schemeAccordionDisplay
                ? "m-nav-accordion-show"
                : "m-nav-accordion-hide",
            },
            _vm._l(_vm.getSchemes, function (n) {
              return _c(
                "b-nav-item",
                {
                  key: n.id,
                  class: _vm.togglenav,
                  attrs: {
                    to: _vm.localePath(`/scheme/${n.slug}`),
                    "no-body": "",
                  },
                  on: { click: _vm.toggleNav },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.i18nTitle(n)) + "\n        "
                  ),
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleLoginNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("application") } },
                [
                  _c("div", { staticClass: "nav-item-obj" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 78.33 64.92",
                        },
                      },
                      [
                        _c("defs", [
                          _c("style", [
                            _vm._v(
                              "\n                  .cls-1 {\n                    fill: #fff;\n                    stroke: #fff;\n                    stroke-linecap: round;\n                    stroke-linejoin: round;\n                    stroke-width: 6px;\n                  }\n                "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "g",
                          { attrs: { id: "Layer_2", "data-name": "Layer 2" } },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Layer_1-2-3",
                                  "data-name": "Layer 1",
                                },
                              },
                              [
                                _c("rect", {
                                  staticClass: "cls-1",
                                  attrs: {
                                    x: "3",
                                    y: "3",
                                    width: "21.33",
                                    height: "21.33",
                                  },
                                }),
                                _vm._v(" "),
                                _c("line", {
                                  staticClass: "cls-1",
                                  attrs: {
                                    x1: "41",
                                    y1: "5.83",
                                    x2: "75.33",
                                    y2: "5.83",
                                  },
                                }),
                                _vm._v(" "),
                                _c("line", {
                                  staticClass: "cls-1",
                                  attrs: {
                                    x1: "41",
                                    y1: "21.51",
                                    x2: "75.33",
                                    y2: "21.51",
                                  },
                                }),
                                _vm._v(" "),
                                _c("rect", {
                                  staticClass: "cls-1",
                                  attrs: {
                                    x: "3",
                                    y: "40.59",
                                    width: "21.33",
                                    height: "21.33",
                                  },
                                }),
                                _vm._v(" "),
                                _c("line", {
                                  staticClass: "cls-1",
                                  attrs: {
                                    x1: "41",
                                    y1: "43.41",
                                    x2: "75.33",
                                    y2: "43.41",
                                  },
                                }),
                                _vm._v(" "),
                                _c("line", {
                                  staticClass: "cls-1",
                                  attrs: {
                                    x1: "41",
                                    y1: "59.09",
                                    x2: "75.33",
                                    y2: "59.09",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("navbar.application")))]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleLoginNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("shoppingCart") } },
                [
                  _c(
                    "div",
                    { staticClass: "nav-item-obj" },
                    [
                      _c("b-icon", { attrs: { icon: "cart-fill" } }),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.$t("navbar.shoppingCart")))]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleLoginNav } },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("bookmark") } }, [
                _c("div", { staticClass: "nav-item-obj" }, [
                  _c(
                    "svg",
                    {
                      staticStyle: { height: "28px", width: "18px" },
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 58 73.37",
                      },
                    },
                    [
                      _c("defs", [
                        _c("style", [
                          _vm._v(
                            "\n                  .cls-1 {\n                    fill: #fff;\n                  }\n                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "g",
                        { attrs: { id: "Layer_2", "data-name": "Layer 2" } },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Layer_1-2-4",
                                "data-name": "Layer 1",
                              },
                            },
                            [
                              _c("path", {
                                staticClass: "cls-1",
                                attrs: {
                                  d: "M52.29,72.53l-21-18.13a3.46,3.46,0,0,0-4.52,0l-21,18.13A3.45,3.45,0,0,1,0,69.91v-63A6.88,6.88,0,0,1,6.88,0H51.12A6.88,6.88,0,0,1,58,6.88v63A3.45,3.45,0,0,1,52.29,72.53Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("navbar.bookmark")))]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c("a", { on: { click: _vm.showNewsModal } }, [
                _c("div", { staticClass: "nav-item-obj" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        id: "Icon_News",
                        "data-name": "Icon News",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        viewBox: "0 0 20 16.87",
                      },
                    },
                    [
                      _c("defs", [
                        _c("style", [
                          _vm._v(
                            "\n                  .cls-2 {\n                    fill: #fff;\n                  }\n                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "cls-2",
                        attrs: {
                          id: "Combined_Shape",
                          "data-name": "Combined Shape",
                          d: "M2.92,17.45,1.49,13.56H1.26A1.26,1.26,0,0,1,0,12.29V7.17A1.27,1.27,0,0,1,1.26,5.91H4.8a.4.4,0,0,1,.4.4h0v6.85a.4.4,0,0,1-.4.4h0l1,2.84a1.48,1.48,0,0,1,0,1.14,1.51,1.51,0,0,1-.84.78l-.08,0a1.52,1.52,0,0,1-.52.09A1.5,1.5,0,0,1,2.92,17.45Zm13.65.27h0a10.23,10.23,0,0,0-8.72-4.84H6V6.58H7.84a10.24,10.24,0,0,0,8.73-4.83.4.4,0,0,1,.74.21V17.5a.4.4,0,0,1-.28.38l-.12,0a.4.4,0,0,1-.34-.18ZM18.11,6.88a3.09,3.09,0,0,1,0,5.7Z",
                          transform: "translate(0 -1.56)",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.$t("navbar.news")))]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/feedback") } }, [
                _c(
                  "div",
                  { staticClass: "nav-item-obj" },
                  [
                    _c("b-icon", { attrs: { icon: "chat-dots-fill" } }),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("navbar.feedback")))]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAdmin && _vm.api && !_vm.api.includes("preview")
            ? _c(
                "b-nav-item",
                { class: _vm.togglenav },
                [
                  _c("nuxt-link", { attrs: { to: `/tc/admin` } }, [
                    _c("div", { staticClass: "nav-item-obj" }, [
                      _c("p", [_vm._v(_vm._s(_vm.$t("navbar.admin_panel")))]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loggedIn
            ? _c(
                "b-nav-item",
                {
                  staticClass: "m-hide-when-wide",
                  class: _vm.togglenav,
                  on: { click: _vm.logout },
                },
                [
                  _c("div", { staticClass: "nav-item-obj" }, [
                    _c(
                      "svg",
                      {
                        staticStyle: {
                          "enable-background": "new 0 0 320.002 320.002",
                        },
                        attrs: {
                          id: "Layer_1",
                          version: "1.1",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 320.002 320.002",
                          "xml:space": "preserve",
                        },
                      },
                      [
                        _c("g", { attrs: { id: "XMLID_6_" } }, [
                          _c("path", {
                            staticClass: "fill",
                            attrs: {
                              id: "XMLID_7_",
                              d: "M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394\n\t\tc5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103\n\t\tc-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64\n\t\tc-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659\n\t\tc-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672\n\t\tc-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669\n\t\tc-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022\n\t\tc0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79\n\t\tc0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71\n\t\tc0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685\n\t\tc0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635\n\t\tc0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001\n\t\tc2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001\n\t\tz",
                            },
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticClass: "fill",
                            attrs: {
                              id: "XMLID_8_",
                              d: "M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160\n\t\tv-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40\n\t\tC320.002,31.716,313.286,25,305.002,25z",
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("navbar.logout")))]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "navicon-container align-center",
              on: { click: _vm.toggleNav },
            },
            [
              _c("div", { staticClass: "navicon" }),
              _vm._v(" "),
              _c("div", { staticClass: "navicon" }),
              _vm._v(" "),
              _c("div", { staticClass: "navicon" }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("news-modal", { ref: "newsmodal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }