<template>
  <b-row>
    <!-- <div style="float: right; margin-right: 75px"> -->
    <b-col v-if="edit" cols="1">
      <b-button
        variant="outline-success"
        style="margin: 40px 0 4px 0"
        @click="addRow"
        >添加列</b-button
      >
      <b-button variant="outline-danger" @click="delRow">刪除列</b-button>
    </b-col>
    <b-col :cols="edit ? 11 : 12">
      <div v-if="edit">
        <b-button variant="outline-success" @click="addCol">添加欄</b-button>
        <b-button variant="outline-danger" @click="delCol">刪除欄</b-button>
      </div>
      <!-- {{ data }} -->
      <div style="overflow-x: auto">
        <table class="table_impl">
          <tbody>
            <tr v-for="(row, index) in data" :key="index">
              <td v-for="(item, i) in row" :key="i" :colspan="item.colspan">
                <span
                  v-if="!edit & (lang === 0)"
                  :style="i === 0 ? 'font-weight: 500' : ''"
                  >{{ item.content_en }}</span
                >
                <span
                  v-if="!edit & (lang === 1)"
                  :style="i === 0 ? 'font-weight: 500' : ''"
                  >{{ item.content_tc }}</span
                >
                <span
                  v-if="!edit & (lang === 2)"
                  :style="i === 0 ? 'font-weight: 500' : ''"
                  >{{ item.content_sc }}</span
                >

                <b-form-input
                  v-if="edit & (lang === 0)"
                  v-model="item.content_en"
                  type="text"
                  trim
                  :class="i === 0 ? 'first_td' : 'other_td'"
                  @input="inputChanged"
                ></b-form-input>
                <b-form-input
                  v-if="edit & (lang === 1)"
                  v-model="item.content_tc"
                  type="text"
                  trim
                  :class="i === 0 ? 'first_td' : 'other_td'"
                  @input="inputChanged"
                ></b-form-input>
                <b-form-input
                  v-if="edit & (lang === 2)"
                  v-model="item.content_sc"
                  type="text"
                  trim
                  :class="i === 0 ? 'first_td' : 'other_td'"
                  @input="inputChanged"
                ></b-form-input>
              </td>

              <td :style="edit === false ? 'display: none' : ''">
                <b-form-checkbox
                  value="1"
                  unchecked-value="0"
                  @change="(checked) => span(checked, index)"
                >
                  Span
                </b-form-checkbox>
              </td>
            </tr>
            <tr></tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    implTableData: {
      type: Array,
      default: () => [],
    },
    lang: { type: Number, default: 0 },
    edit: { type: Boolean, default: false },
  },
  data() {
    return {
      data: this.implTableData.map((a) => [...a]),
      noOfCol: this.implTableData.length ? this.implTableData[0].length - 1 : 1, // number of column minus header(1st col)
    }
  },
  computed: {},
  /* watch: {
    data: {
      handler(newVal, oldVal) {
        this.$emit('change', newVal)
      },
      deep: true,
    },
    lang: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  }, */
  watch: {
    implTableData: {
      handler(newVal, oldVal) {
        this.data = newVal.map((a) => [...a])
        this.noOfCol = newVal.length ? newVal[0].length - 1 : 1
      },
      deep: true,
    },
  },
  methods: {
    inputChanged() {
      this.$emit('input', this.data)
    },
    getContent(item) {
      switch (this.lang) {
        case 0:
          return item.content_en
        case 1:
          return item.content_tc
        case 2:
          return item.content_sc
        default:
          return ''
      }
    },
    span(value, index) {
      this.data[index][1].colspan = this.noOfCol
      // only keep the 1st & 2nd cell
      if ((value === '1') & (this.data[index].length > 2)) {
        // value = 1 : span checked
        this.data[index] = this.data[index].slice(0, 2)
      } else if ((value === '0') & (this.data[index].length === 2)) {
        // value = 0 : span unchecked
        this.data[index][1].colspan = 1
        for (let i = 0; i < this.noOfCol - 1; i++) {
          this.data[index].push({
            content_en: '',
            content_tc: '',
            content_sc: '',
            colspan: 1,
          })
        }
      }
      this.inputChanged()
    },
    addCol() {
      this.data.forEach((d) => {
        if (d.length > 1) {
          if (d[1].colspan === 1)
            // normal row: add new cell
            d.push({
              content_en: '',
              content_tc: '',
              content_sc: '',
              colspan: 1,
            })
          else d[1].colspan += 1
          // if original cell has span checked (colspan > 1), colspan + 1
        }
      })
      this.noOfCol += 1
      /* // console.log(typeof this.data, this.data)
      // console.log(typeof this.data[0], this.data[0]) */
      this.inputChanged()
    },
    delCol() {
      this.data.forEach((d) => {
        if (d.length > 2) d.pop()
        else d[1].colspan -= 1
      })
      // console.log(this.data)
      this.noOfCol -= 1
      this.inputChanged()
    },
    addRow() {
      const col = { content_en: '', content_tc: '', content_sc: '', colspan: 1 }
      const row = []
      for (let i = 0; i <= this.noOfCol; i++) {
        // need to deep copy col
        row.push(JSON.parse(JSON.stringify(col)))
      }
      this.data.push(row)
      this.inputChanged()
    },
    delRow() {
      this.data.pop()
      this.inputChanged()
    },
    i18nText(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.en
        case 'tc':
          return item.tc
        case 'sc':
          return item.sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.first_td {
  background-color: #ffe086;
  border-radius: 0;
  border: none;
  color: black;
  font-weight: 500;
}
.other_td {
  background-color: #ffffde;
  border-radius: 0;
  border: none;
  color: black;
}
.table_impl {
  // border: 1px solid lightgrey;
  width: 100%;
  margin: 4px auto 25px auto;
  // font-size: 0.8em;
}
.table_impl > tbody > tr > td:nth-child(1) {
  background-color: #ffe086; // #f5ddd4;
  text-align: center;
  width: 30%;
}
.table_impl > tbody > tr > td:last-child {
  background-color: whitesmoke;
  text-align: center;
  border: none;
  width: 75px;
}
.table_impl > tbody > tr > td {
  background-color: #ffffde; // #f5ddd4;
  text-align: center;
  height: 2.3em;
  border: 1px solid lightgrey;
  padding: 7px;
  min-width: 130px;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .table_impl > tbody > tr > td:nth-child(1),
  .table_impl > tbody > tr > td:last-child {
    width: auto;
    word-break: normal;
    word-wrap: break-word;
  }

  .table_impl > tbody > tr > td {
    min-width: unset;
    word-break: normal;
    word-wrap: break-word;
  }

  .table_impl {
    width: 100%;
    margin: 4px -15px 25px 0px;
  }
}
</style>
