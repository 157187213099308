export const state = () => ({
  schemes: [],
  schemesPortal: [],
  searchPageAvailableSchemes: [],
  events: [],
  eventsPortal: [],
  categories: [],
  searchTagStored: [],
  eventSelected: {},
  notShowNewsPopup: false,
})

export const mutations = {
  importSchemes(state, data) {
    state.schemes = data
  },
  importPortalSchemes(state, data) {
    state.schemesPortal = data
  },
  importAvailableSchemes(state, data) {
    state.searchPageAvailableSchemes = data
  },
  importEvents(state, data) {
    state.events = data
  },
  importPortalEvents(state, data) {
    state.eventsPortal = data
  },
  importCategories(state, data) {
    state.categories = data
  },
  importSearchTag(state, data) {
    state.searchTagStored = data
  },
  storeEventSelected(state, data) {
    state.eventSelected = data
  },
  storeShowNewsPopup(state, data) {
    state.notShowNewsPopup = data
  },
}

export const actions = {}
