<template>
  <b-modal
    id="modal-calendar"
    ref="modalCalendar"
    content-class="modal-cal-content"
    centered
    hide-header
    hide-footer
    scrollable
    size="lg"
  >
    <b-row>
      <b-col cols="5" style="padding-right: 0">
        <div class="calendar_title border-bottom">
          {{ $t('home.calendar') }}
          <!-- {{ getRightColHeight }} -->
        </div>
        <v-calendar
          mode="date"
          title-position="left"
          :attributes="attrs"
          :locale="{
            id: i18nCalendar(),
            firstDayOfWeek: 0,
            // masks: { title: $moment(datePicked.date).format('yyyy年M月D日') },
          }"
          :first-day-of-week="0"
          :min-date="new Date()"
          is-expanded
          class="calendar"
          :is-inline="true"
          @dayclick="(day) => test(day)"
        />
        <!-- :select-attribute="selectAttribute" -->
        <!-- @dayclick="(day) => (datePicked = day.id)" -->
        <div v-if="events" :style="eventListStyles">
          <div v-for="(event, index) in filteredEvents" :key="index">
            <!-- v-if="isOnSelectedDate(event.EventSlot)" -->
            <div
              role="button"
              @click="
                () => {
                  // eventSelected = event
                  $store.commit('tempStorage/storeEventSelected', event)
                }
              "
            >
              <EventCardForCalendar
                :event="event"
                :index="index"
                :bookmarks="bookmarks"
                :schemes="schemes"
                :result-slot="getSelectedDateEventSlotId(event)"
                :is-applied="isAppliedEvent(event.code)"
              />
            </div>
            <div
              v-if="index !== filteredEvents.length - 1"
              style="width=100%; border: 1px solid whitesmoke"
            ></div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="7"
        style="
          border-left: 1px solid whitesmoke;
          padding-left: 30px;
          padding-right: 30px;
        "
      >
        <b-button
          size="sm"
          class="calendar-event-close"
          @click="$bvModal.hide('modal-calendar')"
          >×</b-button
        >
        <!-- {{ eventSelectedStored.code }} -->
        <div class="icons-bar">
          <div v-if="getScheme">
            <img
              v-if="eventSelected.isFamily === 1"
              v-b-popover.hover="
                getScheme.parentIcon
                  ? i18nContent(JSON.parse(getScheme.parentIcon).popover)
                  : ''
              "
              :src="
                getScheme.parentIcon ? JSON.parse(getScheme.parentIcon).img : ''
              "
              class="event-icon"
              alt="icon event"
            />
            <img
              v-if="eventSelected.isStar === 1"
              v-b-popover.hover="
                getScheme.starIcon
                  ? i18nContent(JSON.parse(getScheme.starIcon).popover)
                  : ''
              "
              :src="
                getScheme.starIcon ? JSON.parse(getScheme.starIcon).img : ''
              "
              class="event-icon"
              alt="icon event"
            />
          </div>

          <div v-if="subevents.length" class="target">
            <div
              v-for="(target, index) in getSubEventTargetSchool()"
              :key="index"
              class="card-target"
            >
              {{ target }}
            </div>
          </div>
          <div
            v-else-if="eventSelected && eventSelected.EventSlot"
            class="target"
          >
            <div
              v-for="(target, index) in getTargetSchool(eventSelected)"
              :key="index"
              class="card-target"
            >
              {{ target }}
            </div>
          </div>
        </div>

        <h6
          v-if="eventSelected"
          class="card-scheme-title"
          :style="{ color: getColor(getSchemesSlug(eventSelected.scheme)) }"
        >
          {{ getScheme ? i18nTitle(getScheme) : '' }}
        </h6>
        <div class="mb-3">
          <div v-if="parentEvent.code">
            {{ parentEvent.code }}
            {{ parentEvent ? i18nTitle(parentEvent) : '' }}
          </div>

          <b-icon v-if="parentEvent.code" icon="arrow-return-right" />
          <span v-if="eventSelected" class="mb-3">
            {{ eventSelected.code }}
            <span v-html="i18nTitle(eventSelected)"></span>
            <span
              v-if="
                eventSelected.statusTxt &&
                i18nContent(JSON.parse(eventSelected.statusTxt)) !== ''
              "
              style="white-space: nowrap"
              v-html="i18nContent(JSON.parse(eventSelected.statusTxt))"
            >
            </span>
            <span v-else-if="getStatus === 1" class="status_completed">
              ({{ $t('event.statusList.completed') }})
            </span>
            <span v-else-if="getStatus === 2" class="status_cancelled">
              ({{ $t('event.statusList.cancelled') }})
            </span>
          </span>
        </div>
        <img
          v-if="eventSelected && eventSelected.img"
          class="card-img"
          :src="getImg(eventSelected)"
          alt="card image"
        />
        <div style="font-size: 85%">
          <event-table
            v-if="eventSelected && eventSelected.EventSlot"
            :event="eventSelected"
            :show-apply-btn="false"
          ></event-table>
        </div>
        <!-- <event-table-for-calendar
          v-if="eventSelected && eventSelected.EventSlot"
          :event="eventSelected"
        ></event-table-for-calendar> -->
        <div
          v-if="eventSelected && eventSelected.EventSlot"
          class="calendar_event"
        >
          <div class="event-btn">
            <!-- <a v-b-modal="'modal-1'" class="event_apply_links"> 查看更多 </a> -->
            <nuxt-link
              v-if="!eventSelected.parent"
              :to="localePath(`/event/${eventSelected.id}`)"
              class="event_apply_links"
              >{{ $t('home.more') }}</nuxt-link
            >
            <nuxt-link
              v-else
              :to="localePath(`/event/${parentEvent.id}`)"
              class="event_apply_links"
              >{{ $t('home.more') }}</nuxt-link
            >
          </div>
          <div
            v-if="eventSelected && eventSelected.EventSlot.length"
            class="event-btn"
          >
            <a
              v-if="!isBookmarked"
              class="event_apply_links"
              @click="
                () => {
                  if (loggedIn) {
                    bookmark(
                      eventSelected.parent ? parentEvent.id : eventSelected.id
                    )
                  } else $router.push(localePath('/login'))
                }
              "
            >
              <!-- <b-icon icon="bookmark" /> -->{{ $t('home.addbkmark') }}
            </a>
            <a
              v-else
              class="event_apply_links"
              style="
                background: whitesmoke;
                color: #999999;
                border: 1px solid #999999;
              "
              @click="
                bookmark(
                  eventSelected.parent ? parentEvent.id : eventSelected.id
                )
              "
            >
              {{ $t('home.addedbkmark') }}
            </a>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
// import DatePicker from 'v-calendar-ie11/src/components/DatePicker.vue'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
// import Calendar from 'v-calendar-ie11/lib/v-calendar.umd'
// import Calendar from 'v-calendar/lib/components/calendar.umd'
import EventCardForCalendar from '~/components/common/event_card_for_calendar.vue'

export default {
  components: { EventCardForCalendar },
  props: {
    passDate: Date,
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/recentEvent`
      )
      const recentEvent = result.data.data
      this.$store.commit('tempStorage/storeEventSelected', recentEvent)
    } catch (err) {}
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/venue`
      )
      this.venue = result.data.data
    } catch (err) {}
    try {
      if (this.eventsPortalStored.length) {
        this.events = this.eventsPortalStored.map((a) => ({ ...a }))
      } else {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/public/calevent`
        )
        this.events = result.data.data
      }
      this.filteredEvents = this.events.filter(
        (e) =>
          !!e.EventSlot.find((s) => {
            return (
              this.$moment(s.date).format('yyyy.MM.DD') ===
              this.$moment(new Date()).format('yyyy.MM.DD')
            )
          })
      )
      /* this.firstUpcomingEvent = this.filteredEvents.length
        ? this.filteredEvents[0]
        : {} */
    } catch (err) {}
    try {
      if (this.loggedIn) {
        const result = await this.$axios.get(`applications`)
        this.allApplications = result.data.data
        this.applications = this.getApplications
        // console.log(this.applications)
        this.resultText = this.applications.length ? '' : this.errorText

        // console.log('this.eventSelectedtmp' + this.eventSelectedStored)
        this.eventSelectedtmp = this.eventSelectedStored
          ? this.eventSelectedStored
          : this.applications
          ? this.applications[0].event
          : this.firstUpcomingEvent
        // console.log('this.eventSelectedtmp' + this.eventSelectedtmp)
        this.$store.commit(
          'tempStorage/storeEventSelected',
          this.eventSelectedtmp
        )
      }
    } catch (err) {}
    try {
      if (this.loggedIn) {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}bookmarks`
        )
        this.bookmarks = result.data.data
      }
    } catch (err) {}

    try {
      if (this.schemeStored.length) {
        this.schemes = this.schemeStored.map((a) => ({ ...a }))
        // console.log('this.schemeAll ' + this.schemeAll)
      } else {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}schemes/public/scheme/name`
        )
        this.schemes = result.data.data
        this.$store.commit(
          'tempStorage/importPortalSchemes',
          result.data.data.map((a) => ({ ...a }))
        )
      }
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    const todos = [
      {
        /* description: '古典合唱劇場—彼高利斯《聖母悼歌》', */
        isComplete: false,
        dates: new Date(2020, 11, 30),
        color: 'pink',
      },
      {
        /* description: '音樂事務處「起舞人間」管樂音樂會', */
        isComplete: true,
        dates: new Date(2020, 9, 27),
        color: 'pink',
      },
    ]
    return {
      parentEvent: {},
      eventListStyles: {},
      incId: todos.length,
      todos,
      applications: [],
      schemes: [],
      events: [],
      // eventSelected: {},
      filteredEvents: [],
      firstUpcomingEvent: {},
      subevents: [],
      bookmarks: [],
      isBookmarked: false,
      datePicked: new Date().toISOString(),
      test2: 'test2',
      selectAttribute: {
        dot: true, // not working
      },
      objects: {
        types: [
          {
            name: this.$t('school.kindergarten'),
            forms: [
              { name: '幼兒班', tag: 'k1', type: 'target' },
              { name: '低班', tag: 'k2', type: 'target' },
              { name: '高班', tag: 'k3', type: 'target' },
            ],
          },
          {
            name: this.$t('school.primaryskl'),
            forms: [
              { name: '小一', tag: 'p1', type: 'target' },
              { name: '小二', tag: 'p2', type: 'target' },
              { name: '小三', tag: 'p3', type: 'target' },
              { name: '小四', tag: 'p4', type: 'target' },
              { name: '小五', tag: 'p5', type: 'target' },
              { name: '小六', tag: 'p6', type: 'target' },
            ],
          },
          {
            name: this.$t('school.secondaryskl'),
            forms: [
              { name: '中一', tag: 'f1', type: 'target' },
              { name: '中二', tag: 'f2', type: 'target' },
              { name: '中三', tag: 'f3', type: 'target' },
              { name: '中四', tag: 'f4', type: 'target' },
              { name: '中五', tag: 'f5', type: 'target' },
              { name: '中六', tag: 'f6', type: 'target' },
            ],
          },
          {
            name: '其他',
            forms: [
              {
                name: this.$t('school.specialskl'),
                tag: 'n',
                type: 'target',
              },
            ],
          },
        ],
      },
    }
  },

  computed: {
    eventsPortalStored() {
      return this.$store.state.tempStorage.eventsPortal
    },
    getStatus() {
      // 0: normal; 1: completed; 2: cancelled
      let status = 0

      /* const tomorrow = new Date()
      tomorrow.setDate(new Date().getDate() + 1) */

      // if event status is 2, directly show "cancelled"
      if (this.eventSelected.status === 2) status = 2
      // if event has enddate, show "completed" from the next date
      else if (
        this.eventSelected.enddate &&
        this.$moment(this.eventSelected.enddate).isBefore(this.$moment(), 'day')
      )
        status = 1
      // --- calendar wont show events with slots all completed ---
      return status
    },
    /* eventsApplied() {
      return this.events.filter((e) =>
        this.applications.find((a) => a.activityCode === e.code)
      )
    }, */
    getScheme() {
      if (this.eventSelected) {
        const find = this.schemes.find(
          (s) => s.id === this.eventSelected.scheme
        )
        return find || null
      }
      return null
    },
    getRightColHeight() {
      return this.$refs.rightCol ? this.$refs.rightCol.clientHeight : ''
    },
    attrs() {
      const arr = []
      /* const arr = [
        ...this.todos.map((todo) => ({
          dates: todo.dates,
          dot: {
            color: todo.color,
            class: todo.isComplete ? 'opacity-75' : '',
          },
          customData: todo, */
      /* highlight: {
            color: todo.color,
            fillMode: 'light',
          }, */
      /* })),
      ] */
      const arrEventsGrpbyDate = this.events.reduce((array = [], event) => {
        if (event.EventSlot.length) {
          /* event.EventSlot.map((a) => {
            arr[a.date] = arr[a.date] || []
            if (!arr[a.date].includes(event.title_tc))
              arr[a.date].push(event.title_tc)
          }) */
          /* event.EventSlot.map((a) => {
            const item = {
              date: a.date,
              title: [event.title_tc],
            }
            const find = arr.findIndex((f) => a.date === f.date)
            // console.log('find ' + find)
            if (find < 0) arr.push(item)
            else if (!arr[find].title.includes(event.title_tc))
              arr[find].title.push(event.title_tc)
          }) */
          event.EventSlot.map((a) => {
            const item = {
              date: a.date,
              title: event.title_tc, // event.parent ?
            }
            const find = array.findIndex((f) => {
              return a.date === f.date && event.title_tc === f.title
            })
            // if no event with the same name and date, push (eventslots of the same event may have same name and date)
            if (find < 0) array.push(item)
          })
        }
        return array
      }, [])
      // console.log(arrEventsGrpbyDate)
      // console.log(arrEventsGrpbyDate[0])

      arrEventsGrpbyDate.map((obj) => {
        /* const titles = obj.title.reduce((string, t) => {
          return string.concat(`<br/>${t}`)
        }, '') */
        if (new Date() <= new Date(obj.date)) {
          arr.push({
            dates: new Date(obj.date),
            highlight: {
              // dot: {
              color: 'teal',
              fillMode: 'light',
              contentStyle: { backgroundColor: '#e9f9f8', color: '#1fbcb4' },
            },
            // popover will cause 500 ...
            /* popover: {
              label: obj.title,
            }, */
          })
        }
      })

      arr.push({
        dates: new Date(),
        highlight: {
          color: 'teal',
          contentStyle: { backgroundColor: '#1fbcb4', color: '#fff' },
        },
      })

      /* // console.log(this.applications)
      this.applications.map((a) => {
        // console.log(a.firstChoiceDate)
      }) */
      // const arr = []
      return arr
    },
    eventSelected() {
      return this.$store.state.tempStorage.eventSelected
    },
    eventsStored() {
      return this.$store.state.tempStorage.events
    },
    schemeStored() {
      return this.$store.state.tempStorage.schemesPortal
    },
    getApplications() {
      let applications = this.allApplications
      applications.forEach((a) => {
        a.event = this.events.find((e) => e.code === a.activityCode)
      })
      if (this.status >= 0)
        applications = applications.filter((a) => a.status === this.status)
      if (this.plan >= 0)
        applications = applications.filter((a) => a.event.scheme === this.plan)

      if (this.sortKey === 'code') {
        applications.sort((a, b) => {
          // console.log(a.code + ' ' + b.code)
          if (a.activityCode < b.activityCode) return -1
          if (a.activityCode > b.activityCode) return 1
          return 0
        })
      }

      if (this.sortKey === 'date') {
        applications.sort((a, b) => {
          // console.log(a.code + ' ' + b.code)
          if (a.submitTime < b.submitTime) return -1
          if (a.submitTime > b.submitTime) return 1
          return 0
        })
      }
      return applications
    },
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
  },
  watch: {
    eventSelected: {
      handler(newVal, oldVal) {
        this.onEventSelected(newVal)
      },
      deep: true,
    },
  },
  mounted() {
    this.matchHeight()
  },
  methods: {
    getImg(event) {
      if (event) {
        if (event.cal_img) return event.cal_img
        // this.getParent(event.parent)
        if (this.parentEvent) return this.parentEvent.cal_img
        if (!event.cal_img) return 'https://www.abo.gov.hk/image/blank.png'
        if (JSON.parse(event.img).length) return JSON.parse(event.img)[0]
      }
      return this.event
        ? this.event.cal_img
          ? this.event.cal_img
          : JSON.parse(this.event.img).length
          ? JSON.parse(this.event.img)[0]
          : this.subevents && this.subevents.length
          ? this.subevents[0].cal_img
            ? this.subevents[0].cal_img
            : JSON.parse(this.subevents[0].img)[0]
          : this.parentEvent && this.parentEvent.cal_img
          ? this.parentEvent.cal_img
          : ''
        : ''
    },
    getSchemesSlug(id) {
      if (this.schemes) {
        for (let i = 0; i < this.schemes.length; i++) {
          if (this.schemes[i].id === id) {
            return this.schemes[i].slug
          }
        }
      }
      return ''
    },
    getColor(slug) {
      switch (true) {
        case slug.includes('scds'):
          return '#FF739D'
        case slug.includes('aoa'):
          return '#4FB1E8'
        case slug.includes('spaps'):
          return '#9772EA'
        case slug.includes('aes'):
          return '#739e00'
        default:
          return ''
      }
    },
    // i18n
    i18nCalendar() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'en'
        case 'tc':
          return 'zh'
        case 'sc':
          return 'zh'
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    async onEventSelected(newVal) {
      await this.getParentEvent(newVal)
      await this.getIsBookmarked(newVal)
    },
    async getParentEvent(event) {
      try {
        // console.log(event.id, event.parent)
        if (event.parent !== null) {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}events/public/parentCode/${event.parent}`
          )
          // console.log(event.id, event.parent, result.data.data[0])
          this.parentEvent = result.data.data[0]
        } else {
          this.parentEvent = {}
        }
      } catch (err) {}
    },
    async getIsBookmarked(event) {
      // console.log('get', event.code)
      let result = false
      try {
        if (this.loggedIn) {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}bookmarks`
          )
          this.bookmarks = result.data.data
        }
      } catch (err) {}
      if (this.bookmarks && event) {
        const found = this.bookmarks.find((bk) => {
          // console.log('isBookmarked', this.isBookmarked, bk.event_id)
          return parseInt(bk.event_id) === event.id
        })
        if (found) result = true
        else if (this.parentEvent.code) {
          const found = this.bookmarks.find((bk) => {
            // console.log('p', bk.event_id === this.parentEvent.code)
            return parseInt(bk.event_id) === this.parentEvent.id
          })
          if (found) result = true
        }
      }
      this.isBookmarked = result
    },
    getSelectedDateEventSlotId(event) {
      let id = null
      const date = this.$moment(this.datePicked).format('yyyy.MM.DD')

      // console.log('event.EventSlot' + JSON.stringify(event.EventSlot))
      if (event.EventSlot) {
        const eventslot = event.EventSlot.find(
          (s) => this.$moment(s.date).format('yyyy.MM.DD') === date
        )
        id = eventslot ? eventslot.id : null
        // console.log(id, date, eventslot)
      }
      return id
    },
    test(day) {
      // this.datePicked = (' ' + day.id).slice(1)
      this.datePicked = day.id

      // this.datePicked = day.date

      // console.log(day.id)
      // console.log('this.datePicked' + this.datePicked)
      // this.datePicked = '2021-03-02'

      this.filteredEvents = this.events.filter(
        (e) =>
          !!e.EventSlot.find((s) => {
            return this.$moment(s.date).format('yyyy.MM.DD') === day.id
          })
      )
    },
    matchHeight() {
      // console.log('this.$refs.rightCol' + this.$refs.rightCol.clientHeight)
      /* const height = this.$refs.modalCalendar.clientHeight
        ? this.$refs.modalCalendar.clientHeight.toString().concat('px')
        : ''
      // console.log('height' + height) */
      // this.$set(this.imgStyles, 'height', height)

      // console.log('this.$refs.rightCol' + this.$refs.rightCol.clientHeight)
      /* const height = this.$refs.rightCol
        ? `calc(${this.$refs.rightCol.clientHeight.toString()}px - 450px)`
        : '' */
      // console.log('height' + height)
      // this.$set(this.eventListStyles, 'height', height)
      this.$set(this.eventListStyles, 'overflow', scroll)
      this.$set(this.eventListStyles, 'width', '100%')
    },
    isAppliedEvent(code) {
      return !!this.applications.find((a) => a.activityCode === code)
    },
    isOnSelectedDate(eventslot) {
      // not used
      return !!eventslot.filter((s) => {
        // return this.$moment(s.date).format('yyyy-MM-DD') === '2020-03-02'
        // console.log('this.datePicked.date' + this.datePicked.date)
        return this.$moment(s.date).format('yyyy.MM.DD') === '2021.03.02'
      }).length
      // return true
    },
    getTargets(event) {
      const targets = []
      if (event.EventSlot) {
        for (let i = 0; i < event.EventSlot.length; i++) {
          const allTargets = event.EventSlot[i].target.split(',')
          // console.log(allTargets)
          allTargets.map((t) => targets.push(t.toString()))
        }
        const uniqueTargets = targets.filter((c, index) => {
          return targets.indexOf(c) === index
        })
        // console.log('targets: ' + uniqueTargets)
        return uniqueTargets
      }
    },
    getTargetSchool(event) {
      const uniqueTargets = this.getTargets(event)
      const resultSklArray = []
      let uniqueSkl = []
      uniqueTargets.map((u) => {
        if (u.toString().includes('k')) {
          resultSklArray.push(this.objects.types[0].name)
        }
        if (u.toString().includes('p')) {
          resultSklArray.push(this.objects.types[1].name)
        }
        if (u.toString().includes('f')) {
          resultSklArray.push(this.objects.types[2].name)
        }
        /* if (u.toString().includes('n')) {
          resultSklArray.push(this.objects.types[3].forms[0].name)
        } */
        uniqueSkl = resultSklArray.filter((c, index) => {
          return resultSklArray.indexOf(c) === index
        })
      })
      // console.log('uniqueSkl ' + uniqueSkl)
      return uniqueSkl
    },
    getSubEventTargetSchool() {
      const k = this.objects.types[0].name
      const p = this.objects.types[1].name
      const f = this.objects.types[2].name

      const target = this.subevents.length
        ? this.subevents.reduce((arr = [], s) => {
            if (this.getTargets(s).toString().includes('k')) {
              if (!arr.includes(k)) arr.push(k)
            }
            if (this.getTargets(s).toString().includes('p')) {
              if (!arr.includes(p)) arr.push(p)
            }
            if (this.getTargets(s).toString().includes('f')) {
              if (!arr.includes(f)) arr.push(f)
            }
            return arr
          }, [])
        : []
      // console.log(target)
      return target
    },

    // bookmarks
    bookmark(code) {
      // this.event.isBookmarked = !this.event.isBookmarked
      // console.log(this.isBookmarked)
      // console.log('Before', this.isBookmarked)
      // console.log(this.isBookmarked)

      if (!this.isBookmarked) {
        this.addbookmark(code)
        this.isBookmarked = true
        // console.log('After Add', this.isBookmarked)
      } else {
        this.delbookmark(code)
        this.isBookmarked = false
        // console.log('After Del', this.isBookmarked)
      }
      // console.log(this.isBookmarked)
    },
    async addbookmark(code) {
      try {
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}bookmarks/add`,
          {
            id: code,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async delbookmark(code) {
      try {
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}bookmarks/delete`,
          {
            id: code,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },

    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss">
.calendar {
  border: none !important;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.calendar_event {
  display: flex;
  justify-content: center;
}
.modal-cal-content {
  padding: 0px;
}
.modal-lg {
  max-width: 930px;
}
</style>
<style lang="scss" scoped>
.icons-bar {
  display: flex;
  align-items: center;
}
.event_apply_links {
  border: 1px solid #1fbcb4;
  border-radius: 20px;
  padding: 7px;
  text-decoration: none;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  width: 180px;
  cursor: pointer;
  background: #fff;
  color: #1fbcb4;
}
.event_apply_links:hover {
  background: #1fbcb4;
  color: white;
}
.event-btn {
  margin: 15px;
  margin-right: 15px;
  width: 100%;
}
.target {
  display: inline;
}
.card-target {
  border-radius: 5px;
  background-color: #e9f9f8;
  font-size: 90%;
  color: #1fbcb4;
  display: inline-block;
  padding: 0.1em 0.5em;
  margin: 0 0.5em 0.5em 0.5em;
  position: relative;
  left: -0.5em;
}
.event-icon {
  padding: 0 5px 8px 5px;
  height: 35px;
  cursor: pointer;
}
.green-border {
  // border: 1px solid #1cbb59;
}
.yellow-border {
  border: 1px solid #fd9326;
}
.card-scheme-title {
  font-size: 90%;
  color: #ff739d;
}
/* .card-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;
} */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-img {
    height: 250px;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    margin-bottom: 16px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .card-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 16px;
  }
}
.more {
  border-color: #1fbcb4;
  border-radius: 500px;
  color: #1fbcb4;
  width: fit-content;
  position: absolute;
  right: 5px;
  font-size: 60%;
}

.col-5 {
  display: block;
}

.calendar-event-close {
  position: absolute;
  right: 15px;
  border-radius: 50%;
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .calendar_event {
    padding-bottom: 25px;
  }
  .col-5 {
    display: none;
  }
  .col-7 {
    flex: none;
    max-width: unset;
  }

  .event_apply_links {
    width: auto;
  }

  .calendar-event-close {
    right: 30px;
  }

  .icons-bar {
    margin-bottom: 10px;
  }
}
</style>
