var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
        _c(
          "div",
          { staticClass: "pageForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-form-group",
                  { attrs: { id: "title", "label-for": "title" } },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.$t("role.name")))]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      attrs: { id: "name", type: "text", trim: "" },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow my-3 mse-c1" },
                  [
                    _c("h3", [_vm._v("Permission List")]),
                    _vm._v(" "),
                    _vm._l(_vm.permissions, function (perm, counter) {
                      return _c(
                        "div",
                        { key: counter },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { value: "1", "unchecked-value": "0" },
                                  model: {
                                    value: perm.checked,
                                    callback: function ($$v) {
                                      _vm.$set(perm, "checked", $$v)
                                    },
                                    expression: "perm.checked",
                                  },
                                },
                                [_vm._v(_vm._s(perm.name))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          variant: "outline-success",
                          type: "submit",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }