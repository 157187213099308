<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="9" style="font-size: 0.9em">
      <div>
        <div>
          <div>報名階段</div>
          <b-form-radio-group
            v-model="scdsRoundOfToday"
            :options="scdsRoundOption"
            :state="!!scdsRoundOfToday"
            class="mb-3"
            required
          ></b-form-radio-group>
        </div>
        <div>報名方法</div>
        <div style="display: flex" class="mb-3">
          <b-form-radio-group
            v-model="applyMethod"
            :options="applyMethodOption"
            :state="!!applyMethod"
            required
          ></b-form-radio-group>
          <b-form-input
            v-model="applyMethodOther"
            type="text"
            maxlength="1000"
            style="width: 200px"
          />
        </div>
        <div class="mb-1">報名遞交時間（YYYY-MM-DD HH:mm:ss）</div>
        <b-form-input
          v-model="submitTime"
          :state="submitTimeState(submitTime)"
          type="text"
          style="width: 300px"
          class="mb-3"
        ></b-form-input>

        <div class="mb-1">申請編號（自動產生）</div>
        <b-form-input
          v-model="trnId"
          class="mb-3"
          type="text"
          readonly
        ></b-form-input>

        <b-form-group label-for="form" label="匯入申請（OGCIO .zip）">
          <b-form-file
            v-if="action === 'add'"
            id="form"
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
            @change="uploadForm"
          />
        </b-form-group>
        <!-- {{ data }} -->
        <div>
          <div class="mb-1">附件</div>
          <p v-for="(a, index) in attr" :key="index">
            <a :href="`${attachmentUrl}${a}`" target="_blank">
              <b-icon icon="paperclip"></b-icon>{{ a }}
            </a>
          </p>
        </div>
      </div>

      <b-form @submit="onSubmit">
        <div class="shadow">
          <matching-school
            :data="data"
            :sch-id="sarId"
            :name="schoolData ? schoolData.name_tc : ''"
            @input="onChangeSkl"
          />
        </div>
        <div class="shadow">
          <applySchoolInfo
            :promo="disagreePromo"
            :teacher-data="teachers"
            :school-data="schoolData"
            @change="onSchoolInfoChange"
          />
        </div>

        <event-slot-modal
          :event-slots="getEventSlot"
          :parent-scheme-id="1"
          :is-admin="true"
          :pass-first-choice="passFirstChoice"
          :pass-second-choice="passSecondChoice"
          @input="getSelectedSlots"
        ></event-slot-modal>

        <div class="shadow">
          <div class="form-section">
            <h3>3. 擬參加活動<br /></h3>
            <div v-for="(activity, index) in activityList" :key="index">
              <fieldset class="topLeftInsideLegend">
                <legend>
                  <span class="sr-only">行</span># {{ index + 1 }}
                </legend>

                <div>
                  <div class="layout-default layout-group layout-inline-group">
                    <div
                      id="activityList.0.activityCode_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.activityCode"
                        ><div
                          id="activityList.0.activityCode_label"
                          class="required"
                        >
                          <div class="control-label required-label">
                            活動編號
                          </div>
                          <span class="sr-only">必須提供</span>
                        </div>
                        <b-form-input
                          v-model="activity.activityCode"
                          type="text"
                          name="activityList.0.activityCode"
                          class="form-control"
                          maxlength="1000"
                          value=""
                          style="width: 194px"
                          readonly
                      /></label>
                    </div>
                    <div
                      id="activityList.0.activityName_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.activityName"
                        ><div
                          id="activityList.0.activityName_label"
                          class="required"
                        >
                          <div class="control-label required-label">
                            活動名稱
                          </div>
                          <span class="sr-only">必須提供</span>
                        </div>
                        <b-form-input
                          v-model="activity.activityName"
                          type="text"
                          name="activityList.0.activityName"
                          class="form-control"
                          maxlength="1000"
                          value=""
                          style="width: 364px"
                          readonly
                      /></label>
                    </div>
                  </div>

                  <div>
                    <div>配對活動</div>
                    <b-form-select
                      v-model="activity.event_id"
                      :options="suggestEventList"
                      :state="!!activity.event_id"
                      size="sm"
                      class="mt-1"
                      required
                      @change="
                        () => {
                          activity.event_slot_1 = null
                          activity.event_slot_2 = null
                          $forceUpdate()
                        }
                      "
                    ></b-form-select>
                    <div class="layout-inline mt-3 mb-3">
                      已選擇：
                      <span
                        :class="
                          !activity.event_id ? 'not_selected' : 'resultskl'
                        "
                      >
                        {{
                          activity.event_id
                            ? suggestEventList.find(
                                (d) => d.id === activity.event_id
                              ).label
                            : '未選擇'
                        }}
                      </span>
                    </div>
                    <!-- <b-form-input
                    v-model="activity.event_id"
                    type="text"
                    readonly
                    :state="!!activity.event_id"
                  ></b-form-input> -->
                  </div>

                  <hr style="width: 95%" />

                  <div>
                    <div>配對活動場次</div>
                    <b-button
                      v-b-modal.modal-eventslot
                      size="sm"
                      variant="primary"
                      style="display: block"
                      class="mt-1"
                      @click="
                        () => {
                          selectedEventIndex = index
                          selectedEventId = activity.event_id
                            ? activity.event_id
                            : null
                          passFirstChoice = activity.event_slot_1
                          passSecondChoice = activity.event_slot_2
                        }
                      "
                    >
                      選擇場次 <b-icon icon="hand-index-thumb"></b-icon>
                    </b-button>
                    <!-- <div style="display: none">
                      {{ tmpEventSlot1 }}{{ tmpEventSlot2 }}
                    </div> -->
                    <div class="layout-inline mt-3 mb-5">
                      已選擇首選場次：
                      <span
                        :class="
                          !activity.event_slot_1 ? 'not_selected' : 'resultskl'
                        "
                      >
                        {{
                          activity.event_slot_1
                            ? activity.event_slot_1
                            : '未選擇'
                        }}
                      </span>
                    </div>
                    <div class="layout-inline my-3">
                      已選擇次選場次：
                      <span
                        :class="
                          !activity.event_slot_2 ? 'not_selected' : 'resultskl'
                        "
                      >
                        {{
                          activity.event_slot_2
                            ? activity.event_slot_2
                            : '未選擇'
                        }}
                      </span>
                    </div>
                  </div>

                  <h5>首選場次</h5>
                  <div class="layout-default layout-group layout-inline-group">
                    <div
                      id="activityList.0.firstChoiceDate_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.firstChoiceDate"
                        ><div
                          id="activityList.0.firstChoiceDate_label"
                          class=""
                        >
                          <div class="control-label">日期</div>
                        </div>
                        <div class="input-date-container">
                          <div class="input-group">
                            <b-form-input
                              v-model="activity.firstChoiceDate"
                              name="activityList.0.firstChoiceDate"
                              type="text"
                              class="form-control"
                              placeholder="YYYY-MM-DD"
                              maxlength="10"
                              value=""
                              style="
                                display: inline-block;
                                width: 125px;
                                margin-right: 0px;
                              "
                              readonly
                            />
                          </div></div
                      ></label>
                    </div>
                    <div
                      id="activityList.0.firstChoiceTime_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.firstChoiceTime"
                        ><div
                          id="activityList.0.firstChoiceTime_label"
                          class="required"
                        >
                          <div class="control-label required-label">
                            時間（例如：2pm-3:30pm）
                          </div>
                          <span class="sr-only">必須提供</span>
                        </div>
                        <b-form-input
                          v-model="activity.firstChoiceTime"
                          type="text"
                          name="activityList.0.firstChoiceTime"
                          class="form-control"
                          maxlength="1000"
                          value=""
                          style="width: 279px"
                          readonly
                      /></label>
                    </div>
                  </div>
                  <div
                    id="activityList.0.firstChoiceClass_panel"
                    class="field form-group layout-default"
                  >
                    <label for="activityList.0.firstChoiceClass"
                      ><div
                        id="activityList.0.firstChoiceClass_label"
                        class="required"
                      >
                        <div class="control-label required-label">
                          學生班級（例如：P3, P5-P6）
                        </div>
                        <span class="sr-only">必須提供</span>
                      </div>
                      <b-form-input
                        v-model="activity.firstChoiceClass"
                        type="text"
                        name="activityList.0.firstChoiceClass"
                        class="form-control"
                        maxlength="1000"
                        value=""
                        style="width: 279px"
                        readonly
                      />
                    </label>
                    <div class="control-label required-label">配對學生班級</div>
                    <b-form-checkbox-group
                      v-model="activity.firstChoiceClassVerify"
                      :options="targetOption"
                      :state="
                        !!activity.firstChoiceClassVerify &&
                        activity.firstChoiceClassVerify.length > 0
                      "
                    ></b-form-checkbox-group>
                  </div>
                  <div class="layout-default layout-group layout-inline-group">
                    <div
                      id="activityList.0.firstChoiceStudentNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.firstChoiceStudentNo"
                        ><div
                          id="activityList.0.firstChoiceStudentNo_label"
                          class="required"
                        >
                          <div class="control-label required-label">
                            學生人數
                          </div>
                          <span class="sr-only">必須提供</span>
                        </div>
                        <b-form-input
                          v-model="activity.firstChoiceStudentNo"
                          type="tel"
                          name="activityList.0.firstChoiceStudentNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                    <div
                      id="activityList.0.firstChoiceTeacherNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.firstChoiceTeacherNo"
                        ><div
                          id="activityList.0.firstChoiceTeacherNo_label"
                          class="required"
                        >
                          <div class="control-label required-label">
                            老師人數
                          </div>
                          <span class="sr-only">必須提供</span>
                        </div>
                        <b-form-input
                          v-model="activity.firstChoiceTeacherNo"
                          type="tel"
                          name="activityList.0.firstChoiceTeacherNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                    <div
                      id="activityList.0.firstChoiceParentNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.firstChoiceParentNo"
                        ><div
                          id="activityList.0.firstChoiceParentNo_label"
                          class=""
                        >
                          <div class="control-label">
                            家長人數（適用於附用歡迎家長參與標示之活動）
                          </div>
                        </div>
                        <b-form-input
                          v-model="activity.firstChoiceParentNo"
                          type="tel"
                          name="activityList.0.firstChoiceParentNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                  </div>
                  <h5>次選場次</h5>
                  <div class="layout-default layout-group layout-inline-group">
                    <div
                      id="activityList.0.secondChoiceDate_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.secondChoiceDate"
                        ><div
                          id="activityList.0.secondChoiceDate_label"
                          class=""
                        >
                          <div class="control-label">日期</div>
                        </div>
                        <div class="input-date-container">
                          <div class="input-group">
                            <b-form-input
                              v-model="activity.secondChoiceDate"
                              name="activityList.0.secondChoiceDate"
                              type="text"
                              class="form-control"
                              placeholder="YYYY.MM.DD"
                              maxlength="10"
                              value=""
                              style="
                                display: inline-block;
                                width: 125px;
                                margin-right: 0px;
                              "
                              readonly
                            />
                          </div></div
                      ></label>
                    </div>
                    <div
                      id="activityList.0.secondChoiceTime_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.secondChoiceTime"
                        ><div
                          id="activityList.0.secondChoiceTime_label"
                          class=""
                        >
                          <div class="control-label">
                            時間（例如：2pm-3:30pm）
                          </div>
                        </div>
                        <b-form-input
                          v-model="activity.secondChoiceTime"
                          type="text"
                          name="activityList.0.secondChoiceTime"
                          class="form-control"
                          maxlength="1000"
                          value=""
                          style="width: 279px"
                          readonly
                      /></label>
                    </div>
                  </div>

                  <div
                    id="activityList.0.secondChoiceClass_panel"
                    class="field form-group layout-default"
                  >
                    <label for="activityList.0.secondChoiceClass"
                      ><div
                        id="activityList.0.secondChoiceClass_label"
                        class=""
                      >
                        <div class="control-label">
                          學生班級（例如：P3, P5-P6）
                        </div>
                      </div>
                      <b-form-input
                        v-model="activity.secondChoiceClass"
                        type="text"
                        name="activityList.0.secondChoiceClass"
                        class="form-control"
                        maxlength="1000"
                        value=""
                        style="width: 279px"
                        readonly
                      />
                    </label>
                    <div class="control-label required-label">配對學生班級</div>
                    <b-form-checkbox-group
                      v-model="activity.secondChoiceClassVerify"
                      :options="targetOption"
                      :state="
                        !!activity.secondChoiceClassVerify &&
                        activity.secondChoiceClassVerify.length > 0
                      "
                    ></b-form-checkbox-group>
                  </div>

                  <div class="layout-default layout-group layout-inline-group">
                    <div
                      id="activityList.0.secondChoiceStudentNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.secondChoiceStudentNo"
                        ><div
                          id="activityList.0.secondChoiceStudentNo_label"
                          class=""
                        >
                          <div class="control-label">學生人數</div>
                        </div>
                        <b-form-input
                          v-model="activity.secondChoiceStudentNo"
                          type="tel"
                          name="activityList.0.secondChoiceStudentNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                    <div
                      id="activityList.0.secondChoiceTeacherNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.secondChoiceTeacherNo"
                        ><div
                          id="activityList.0.secondChoiceTeacherNo_label"
                          class=""
                        >
                          <div class="control-label">老師人數</div>
                        </div>
                        <b-form-input
                          v-model="activity.secondChoiceTeacherNo"
                          type="tel"
                          name="activityList.0.secondChoiceTeacherNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                    <div
                      id="activityList.0.secondChoiceParentNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.secondChoiceParentNo"
                        ><div
                          id="activityList.0.secondChoiceParentNo_label"
                          class=""
                        >
                          <div class="control-label">
                            家長人數（適用於附用歡迎家長參與標示之活動）
                          </div>
                        </div>
                        <b-form-input
                          v-model="activity.secondChoiceParentNo"
                          type="tel"
                          name="activityList.0.secondChoiceParentNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                  </div>
                  <div
                    id="activityList.0.remarks_panel"
                    class="field form-group layout-default"
                  >
                    <label for="activityList.0.remarks"
                      ><div id="activityList.0.remarks_label" class="">
                        <div class="control-label">
                          備註（如有輪椅位或其他需要，請在此列明）
                        </div>
                      </div>
                      <b-form-input
                        v-model="activity.remarks"
                        type="text"
                        name="activityList.0.remarks"
                        class="form-control"
                        maxlength="1000"
                        value=""
                        style="width: 704px"
                    /></label>
                  </div>
                </div>
              </fieldset>
              <div class="fieldListButtons">
                <button
                  v-if="activityList.length > 1"
                  aria-label="刪除活動"
                  type="button"
                  class="btn btn-outline-danger mb-3"
                  @click="delActivity(index)"
                >
                  <span class="icon-plus-circled"></span>
                  刪除活動 {{ index + 1 }}
                </button>
              </div>
            </div>
            <div class="fieldListButtons my-3">
              <button
                aria-label="新增活動"
                type="button"
                class="btn btn-outline-success"
                @click="addActivity()"
              >
                <span class="icon-plus-circled"></span> 新增活動
              </button>
            </div>
            <br />
            <div class="form-section">
              <div>聲明<br /></div>
              <div id="declare_panel" class="field form-group layout-default">
                <label for="declare"
                  ><div id="declare_label" class="required sr-only">
                    <div class="control-label required-label">
                      本人為上述學校之授權代表；本校校長亦已得悉並同意報名參與上述活動。
                    </div>
                    <span class="sr-only">必須提供</span>
                  </div>
                  <div class="checkbox checkbox-single">
                    <label for="declare"
                      ><b-form-checkbox
                        v-model="declare"
                        type="checkbox"
                        name="declare"
                        disabled
                      >
                        本人為上述學校之授權代表；本校校長亦已得悉並同意報名參與上述活動。
                      </b-form-checkbox>
                      <div id="declare_label" class="required">
                        <span class="sr-only">必須提供</span>
                      </div></label
                    >
                  </div></label
                >
              </div>
            </div>
          </div>
        </div>
        <b-button type="submit" variant="success"> 提交 </b-button>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import applySchoolInfo from '~/components/admin/application/applySchoolInfo'
export default {
  layout: 'admin',
  middleware: ['Admin', 'jwtCheck'],
  components: {
    applySchoolInfo,
  },
  props: ['action'],
  async fetch() {
    // Create an array of promises for each API call
    const promises = [
      this.$axios.get(
        `${process.env.cloudApiUrl}schemes/public/getId/${this.$route.params.slug}`
      ),
      this.$axios.get(`${process.env.localApiUrl}schools/sar/school`),
      this.$axios.get(
        `${process.env.cloudApiUrl}events/subScheme/slug/${this.$route.params.slug}`
      ),
      this.$axios.get(`${process.env.localApiUrl}applications/getTrnId/SCDS`),
    ]

    // Wait for all promises to be resolved or rejected
    const results = await Promise.allSettled(promises)

    // Handle the results of each API call
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        // If the promise was fulfilled, update the appropriate data property
        switch (index) {
          case 0:
            this.schemeId = result.value.data.data
              ? result.value.data.data[0].id
              : null // Update schemeId
            break
          case 1:
            this.allSkls = result.value.data.data // Update allSkls
            break
          case 2:
            this.suggestEventList = result.value.data.data // Update suggestEventList
            this.suggestEventList.map((d) => {
              d.text = `${d.id}.【${d.code}】${d.title_tc}`
              d.value = d.id
              d.label = `${d.id}.【${d.code}】${d.title_tc}`
              d.eventCode = d.code
              d.code = d.id
              return d
            })
            this.suggestEventList = this.suggestEventList.sort((a, b) => {
              return a.eventCode.localeCompare(b.eventCode)
            })
            break
          case 3:
            this.trnId = result.value.data.data[0] // Update trnId
            break
          default:
            break
        }
      } else {
        // If the promise was rejected, handle the error for the appropriate API call
        switch (index) {
          case 0:
            this.$nuxt.error({
              message: 'Scheme ID not found',
              statusCode: 404,
            }) // Handle error for scheme ID API call
            break
          case 1:
            this.$nuxt.error({
              message: 'Schools not found',
              statusCode: 404,
            }) // Handle error for schools API call
            break
          case 2:
            this.$nuxt.error({
              message: 'Events not found',
              statusCode: 404,
            }) // Handle error for events API call
            break
          case 3:
            this.$nuxt.error({
              message: 'TRN ID not found',
              statusCode: 404,
            }) // Handle error for TRN ID API call
            break
          default:
            break
        }
      }
    })
  },
  // async fetch() {
  //   try {
  //     const result = await this.$axios.get(
  //       `${process.env.cloudApiUrl}schemes/public/getId/${this.$route.params.slug}`
  //     )
  //     this.schemeId = result.data.data ? result.data.data[0].id : null
  //   } catch (err) {
  //     // console.log(err)
  //     this.$nuxt.error({
  //       message: 'Not Found',
  //       statusCode: 404,
  //     })
  //   }
  //   try {
  //     const result = await this.$axios.get(
  //       `${process.env.localApiUrl}schools/sar/school`
  //     )
  //     this.allSkls = result.data.data
  //   } catch (err) {
  //     // console.log(err)
  //     this.$nuxt.error({
  //       message: 'Not Found',
  //       statusCode: 404,
  //     })
  //   }
  //   try {
  //     const result = await this.$axios.get(
  //       `${process.env.cloudApiUrl}events/subScheme/slug/${this.$route.params.slug}`
  //     )
  //     this.suggestEventList = result.data.data
  //     this.suggestEventList.map((d) => {
  //       d.text = `${d.id}.【${d.code}】${d.title_tc}`
  //       d.value = d.id
  //       d.label = `${d.id}.【${d.code}】${d.title_tc}`
  //       d.eventCode = d.code
  //       d.code = d.id
  //       return d
  //     })
  //     this.suggestEventList = this.suggestEventList.sort((a, b) => {
  //       return a.eventCode.localeCompare(b.eventCode)
  //     })
  //   } catch (err) {
  //     // console.log(err)
  //     this.$nuxt.error({
  //       message: 'Not Found',
  //       statusCode: 404,
  //     })
  //   }
  //   try {
  //     const result = await this.$axios.get(
  //       `${process.env.localApiUrl}applications/getTrnId/SCDS`
  //     )
  //     this.trnId = result.data.data[0]
  //   } catch (err) {
  //     // console.log(err)
  //     this.$nuxt.error({
  //       message: 'Not Found',
  //       statusCode: 404,
  //     })
  //   }
  // },
  data() {
    return {
      disagreePromo: false,
      finishSubmit: 0,
      passFirstChoice: null,
      passSecondChoice: null,
      tmpEventSlot1: null,
      tmpEventSlot2: null,
      data: null,
      trnId: '',
      submitTime: '',
      teachers: [{}],
      activityList: [{}],
      declare: true,
      schoolData: {
        name_tc: null,
        name_en: null,
        category: null,
        region: null,
        district: 0,
        address_area: null,
        address_street: null,
        address_building: null,
        tel: null,
        fax: null,
        email: null,
        principal_name: null,
      },
      attr: [],
      sarId: null,
      selected: null,
      keyword: '',
      perPage: 15,
      currentPage: 1,
      allSkls: [],
      suggestEventList: [],
      selectedEvent: [],
      suggestEventSlotList: [],
      selectedEventIndex: 0,
      selectedEventId: null,
      targetOption: [
        { value: 'k1', text: this.intToGrade(-2) },
        { value: 'k2', text: this.intToGrade(-1) },
        { value: 'k3', text: this.intToGrade(0) },
        { value: 'p1', text: this.intToGrade(1) },
        { value: 'p2', text: this.intToGrade(2) },
        { value: 'p3', text: this.intToGrade(3) },
        { value: 'p4', text: this.intToGrade(4) },
        { value: 'p5', text: this.intToGrade(5) },
        { value: 'p6', text: this.intToGrade(6) },
        { value: 'f1', text: this.intToGrade(7) },
        { value: 'f2', text: this.intToGrade(8) },
        { value: 'f3', text: this.intToGrade(9) },
        { value: 'f4', text: this.intToGrade(10) },
        { value: 'f5', text: this.intToGrade(11) },
        { value: 'f6', text: this.intToGrade(12) },
      ],
      applyMethodOption: [
        { value: 'E', text: 'OGCIO E-Form' },
        { value: 'F', text: 'Fax' },
        { value: 'M', text: 'Mail' },
        { value: 'O', text: '其他報名方法（請註明）：' },
      ],
      scdsRoundOption: [
        { value: 1, text: 'B' },
        { value: 2, text: 'O1' },
        { value: 3, text: 'O2' },
        { value: 4, text: 'O3' },
      ],
      applyMethod: null,
      applyMethodOther: '',
      schemeId: null,
      scdsRoundOfToday: null,
    }
  },
  computed: {
    attachmentUrl() {
      return process.env.attachmentUrl
    },
    getEventSlot() {
      if (this.suggestEventList.length && this.selectedEventId) {
        const found = this.suggestEventList.find(
          (d) => d.id === this.selectedEventId
        )
        return [...found.EventSlot].sort((a, b) =>
          a.date > b.date
            ? 1
            : b.date > a.date
            ? -1
            : a.startTime > b.startTime
            ? 1
            : b.startTime > a.startTime
            ? -1
            : 0
        )
      }
      return []
    },
  },
  watch: {
    applyMethod: {
      handler(newVal, oldVal) {
        if (newVal !== 'E') {
          this.getTrnId()
        }
      },
      deep: true,
    },
    finishSubmit: {
      async handler(newVal, oldVal) {
        if (
          this.activityList.length > 0 &&
          this.activityList.length === newVal
        ) {
          const result2 = await this.$swal({
            title: this.$t('apply.alert_success'),
            html: '成功新增申請',
            icon: 'success',
            customClass: 'swal-wide',
            showCancelButton: false,
            confirmButtonText: this.$t('alert.confirm'),
            confirmButtonColor: '#1cbbb4',
          })
          if (result2.value) {
            this.$router.push(this.localePath(`/application`))
          }
        }
      },
      deep: true,
    },
    tmpEventSlot1: {
      handler(newVal, oldVal) {
        this.activityList[this.selectedEventIndex].event_slot_1 = newVal
        this.$forceUpdate()
      },
      deep: true,
    },
    tmpEventSlot2: {
      handler(newVal, oldVal) {
        this.activityList[this.selectedEventIndex].event_slot_2 = newVal
        this.$forceUpdate()
      },
      deep: true,
    },
  },
  methods: {
    async onChangeSkl(sarId) {
      this.sarId = sarId
      await this.$axios
        .get(`${process.env.localApiUrl}schools/sarid/getSchoolList/${sarId}`)
        .then((result) => {
          if (result.data.data.length > 0) {
            const tmp = result.data.data[0]
            this.schoolData = {
              name_tc: tmp.name_tc,
              name_en: tmp.name_en,
              category: tmp.type,
              region: tmp.region,
              district: tmp.district,
              address_area: tmp.address_area,
              address_street: tmp.address_street,
              address_building: tmp.address_building,
              tel: tmp.tel,
              fax: tmp.fax,
              email: tmp.email,
              principal_name: tmp.principal_name,
            }
          }
        })
    },
    onSchoolInfoChange(schoolData, teachers, promo) {
      this.schoolData = schoolData
      this.teachers = teachers
      this.disagreePromo = promo
    },
    async getTrnId() {
      const result = await this.$axios.get(
        `${process.env.localApiUrl}applications/getTrnId/scds`
      )
      if (result) {
        this.trnId = result.data.data.trnId
      }
    },
    async onSubmit(event) {
      event.preventDefault()
      // console.log(this.activityList)
      const submit = await this.$swal({
        title: this.$t('alert.submit'),
        text: `確定新增 ${this.schoolData.name_tc} 申請紀錄?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `${this.$t('alert.yes')}`,
        confirmButtonColor: '#28a745', // '#d9534f',
        cancelButtonText: `${this.$t('alert.no')}`,
      })

      if (submit.value) {
        try {
          let result
          this.activityList.forEach(async (d, i) => {
            if (this.data) this.data.applyMethod = this.applyMethod
            const tmp = {
              data: this.schoolData,
              ogcio: this.data ? JSON.stringify(this.data) : '',
              trnId: `${this.trnId}-${i + 1}`,
              formId: 'ABOSCDSFORM',
              submitTime: this.$moment(this.submitTime)
                .add(8, 'hours')
                .format('YYYY.MM.DD HH:mm:ss'),
              activityCode: d.activityCode,
              event_id: d.event_id,
              activityName: d.activityName,
              firstChoiceDate: null,
              firstChoiceTime: null,
              firstChoiceStudentNo: d.firstChoiceStudentNo,
              firstChoiceTeacherNo: d.firstChoiceTeacherNo,
              firstChoiceParentNo: d.firstChoiceParentNo,
              // firstChoiceClass: d.firstChoiceClassVerify,
              firstChoiceClass: d.firstChoiceClassVerify
                ? JSON.stringify(d.firstChoiceClassVerify)
                : null,
              event_slot_1: d.event_slot_1,
              secondChoiceDate: null,
              secondChoiceTime: null,
              secondChoiceStudentNo: d.secondChoiceStudentNo || null,
              secondChoiceTeacherNo: d.secondChoiceTeacherNo || null,
              secondChoiceParentNo: d.secondChoiceParentNo || null,
              // secondChoiceClass: d.secondChoiceClassVerify,
              secondChoiceClass: d.secondChoiceClassVerify
                ? JSON.stringify(d.secondChoiceClassVerify)
                : null,
              event_slot_2: d.event_slot_2 || null,
              remarks: d.remarks
                ? JSON.stringify({ byTeacher: d.remarks, byABO: '' })
                : {},
              additionTeachers: this.teachers
                ? JSON.stringify(this.teachers)
                : [],
              schemeId: this.schemeId,
              userId: null,
              schoolId: this.sarId,
              round: this.scdsRoundOfToday,
            }
            // console.log('tmp', tmp)
            result = await this.$axios.$post(
              `${process.env.localApiUrl}applications/import/scds`,
              tmp
            )
            this.finishSubmit++
          })
          if (result.data) {
            const result2 = await this.$swal({
              title: this.$t('apply.alert_success'),
              html: '成功新增申請',
              icon: 'success',
              customClass: 'swal-wide',
              showCancelButton: false,
              confirmButtonText: this.$t('alert.confirm'),
              confirmButtonColor: '#1cbbb4',
            })
            if (result2.value) {
              const path = this.$router.currentRoute.path
                .replace('add', 'all')
                .replace('/tc', '')
              this.$router.push(this.localePath(path))
            }
          }
          if (result.error !== undefined) throw new Error(result.error)
        } catch (err) {
          // console.log(err)
        }
      }
    },
    async uploadForm(e) {
      try {
        const params = new FormData()
        params.append('form', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.localApiUrl}files/form`,
          params
        )
        if (await result) {
          const data = result.data
          if (data.status === 'ok') {
            this.applyMethod = 'E'
            this.data = data.data
            this.attr = data.data.attr ? data.data.attr : []
            this.trnId = this.trimData(data.data.submission.submit.trnId)
            this.submitTime = this.trimData(
              data.data.submission.submit.submitTime
            )
            const subData = data.data.submission.data
            // console.log(subData)
            this.schoolData.name_tc = this.trimData(subData.schoolNameChi)
            this.schoolData.name_en = this.trimData(subData.schoolNameEng)
            this.schoolData.address_area = this.trimData(
              subData.schoolAddressL1
            )
            this.schoolData.address_street = this.trimData(
              subData.schoolAddressL2
            )
            this.schoolData.address_building = this.trimData(
              subData.schoolAddressL3
            )
            this.schoolData.region = this.trimData(subData.schoolRegion)
            this.schoolData.district = this.trimData(subData.schoolDistrict)
            this.schoolData.category = this.trimData(subData.schoolLevel)
            this.schoolData.tel = this.trimData(subData.schoolTel)
            this.schoolData.fax = this.trimData(subData.schoolFax)
            this.schoolData.email = this.trimData(subData.schoolEmail)
            this.schoolData.principal_name = this.trimData(
              subData.schoolPrincipal
            )
            if (Array.isArray(subData.teacher)) {
              this.teachers = [...subData.teacher]
            } else {
              this.teachers = []
              this.teachers.push(subData.teacher)
            }
            this.teachers = this.teachers.map((l) => {
              l.name = l.ticName
              l.email = l.ticEmail
              l.tel = l.ticPhone
              l.telExt = l.ticPhoneExt
              delete l.ticName
              delete l.ticEmail
              delete l.ticPhone
              delete l.ticPhoneExt
              return l
            })
            if (Array.isArray(subData.activityList)) {
              this.activityList = [...subData.activityList]
            } else {
              this.activityList = []
              this.activityList.push(subData.activityList)
            }
            this.activityList = this.activityList.map((a) => {
              const event = this._.find(this.suggestEventList, {
                eventCode: a.activityCode,
              })
              a.event_id = event ? event.code : null
              return a
            })
            if (this.trimData(subData.declare) === 'true') this.declare = true
            if (this.trimData(subData.disagreePromo) === 'true')
              this.disagreePromo = true
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    submitTimeState(input) {
      const regex = new RegExp(
        '^([0-9]{4})-[0-2][0-9]-[0-3][0-9] [0-2][0-9]:[0-5][0-9]:[0-5][0-9]$'
      )
      return regex.test(input)
    },
    addActivity() {
      this.activityList.push({})
    },
    delActivity(index) {
      this.activityList.splice(index, 1)
    },
    updateSarId(obj) {
      this.sarId = obj ? obj.id : null
    },
    trimData(field) {
      return field ? field.trim() : ''
    },
    updateEvent(obj, index) {
      this.activityList[index].event_id = obj ? obj.id : null
    },
    /* updateEventSlot1(obj, index) {
      this.activityList[index].event_slot_1 = obj ? obj.id : null
    },
    updateEventSlot2(obj, index) {
      this.activityList[index].event_slot_2 = obj ? obj.id : null
    }, */
    getSelectedSlots(firstChoice, secondChoice) {
      // this.activityList[this.selectedEventIndex].event_slot_1 = firstChoice
      // this.activityList[this.selectedEventIndex].event_slot_2 = secondChoice
      this.tmpEventSlot1 = firstChoice
      this.tmpEventSlot2 = secondChoice
    },
    intToGrade(i) {
      let result = ''
      switch (this.$i18n.locale) {
        case 'en':
          result =
            i < 1
              ? 'K.'.concat((i + 3).toString())
              : i < 7
              ? 'P.'.concat(i.toString())
              : 'S.'.concat((i - 6).toString())
          break
        case 'tc':
          result =
            i === -2
              ? '幼兒班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        case 'sc':
          result =
            i === -2
              ? '幼儿班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.match-db {
  color: grey;
}
.resultskl {
  color: grey;
  background-color: white;
  padding: 5px 20px;
  line-height: 2.5;
}
.not_selected {
  color: #f76576;
  background-color: white;
  padding: 5px 20px;
}
fieldset.topLeftInsideLegend legend {
  float: left;
  width: auto;
  display: block;
  margin: 0 100% 10px 0;
  padding: 0 10px;
  background: #f5f5f5;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 5px 0;
  font-weight: 700;
  font-size: 12px;
  color: #1cbcb4;
  white-space: normal;
}
fieldset.topLeftInsideLegend {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 0;
  margin-bottom: 10px;
}
fieldset.topLeftInsideLegend > div {
  padding: 0 20px;
}
.layout-inline {
  display: inline-block;
  margin-right: 50px;
}
.shadow-important {
  padding: 10px 30px;
  margin: 30px 0;
  border: 2px solid #fc7137;
}
.shadow {
  padding: 10px 30px;
  margin: 30px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.component {
  text-align: left;
}
</style>
