const middleware = {}

middleware['Admin'] = require('../middleware/Admin.js')
middleware['Admin'] = middleware['Admin'].default || middleware['Admin']

middleware['cacheControl'] = require('../middleware/cacheControl.js')
middleware['cacheControl'] = middleware['cacheControl'].default || middleware['cacheControl']

middleware['jwtCheck'] = require('../middleware/jwtCheck.js')
middleware['jwtCheck'] = middleware['jwtCheck'].default || middleware['jwtCheck']

middleware['loginCheck'] = require('../middleware/loginCheck.js')
middleware['loginCheck'] = middleware['loginCheck'].default || middleware['loginCheck']

middleware['RequireLogin'] = require('../middleware/RequireLogin.js')
middleware['RequireLogin'] = middleware['RequireLogin'].default || middleware['RequireLogin']

middleware['SchoolAdmin'] = require('../middleware/SchoolAdmin.js')
middleware['SchoolAdmin'] = middleware['SchoolAdmin'].default || middleware['SchoolAdmin']

export default middleware
