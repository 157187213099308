var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "10" } }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "slug", "label-for": "slug" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("scheme.slug")) +
                                "（e.g. 'scds1920', 'spaps2021'）\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              disabled: _vm.action === "edit" ? true : false,
                              "lazy-formatter": "",
                              formatter: _vm.slugformatter,
                              state: _vm.slugState,
                              required: "",
                              trim: "",
                            },
                            model: {
                              value: _vm.slug,
                              callback: function ($$v) {
                                _vm.slug = $$v
                              },
                              expression: "slug",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-form-invalid-feedback",
                            { attrs: { id: "input-live-feedback" } },
                            [
                              _vm._v(
                                "\n                Cannot contain special characters\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "banner",
                            label: _vm.$t("scheme.banner"),
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("scheme.banner_size")) +
                              ": 1200px(W) x 313px(H)\n              "
                          ),
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.banner,
                              callback: function ($$v) {
                                _vm.banner = $$v
                              },
                              expression: "banner",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-file", {
                            attrs: { id: "banner", accept: "image/*" },
                            on: { change: _vm.uploadImage },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { "max-height": "100px" },
                            attrs: { src: _vm.banner, alt: "banner" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "img",
                            label: _vm.$t("scheme.img"),
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("scheme.banner_size")) +
                              ": Square - 350px(W) x 350px(H);\n              Rectangle - 535px(W) x 350px(H);\n              "
                          ),
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.img,
                              callback: function ($$v) {
                                _vm.img = $$v
                              },
                              expression: "img",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-file", {
                            attrs: { id: "img", accept: "image/*" },
                            on: { change: _vm.uploadImage },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { "max-height": "100px" },
                            attrs: { src: _vm.img, alt: "image" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.parent && _vm.parent === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-for": "img",
                                    label: "圖像：'家長參與'（文化日適用）",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("scheme.banner_size")) +
                                      ": 50px(W) x 50px(H)\n                "
                                  ),
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.parentIcon.img,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.parentIcon, "img", $$v)
                                      },
                                      expression: "parentIcon.img",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-form-file", {
                                    attrs: {
                                      id: "parentIcon",
                                      accept: "image/*",
                                    },
                                    on: {
                                      change: (e) =>
                                        _vm.uploadImage(e, "parentIcon"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "b-tabs",
                                    { attrs: { "content-class": "mt-3" } },
                                    [
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            title: _vm.$t("scheme.popover.en"),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "popover_en",
                                                "label-for": "popover_en",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.popover.en"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.parentIcon.popover
                                                      .content_en,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.parentIcon.popover,
                                                      "content_en",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "parentIcon.popover.content_en",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            title: _vm.$t("scheme.popover.tc"),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "popover_tc",
                                                "label-for": "popover_tc",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.popover.tc"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.parentIcon.popover
                                                      .content_tc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.parentIcon.popover,
                                                      "content_tc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "parentIcon.popover.content_tc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            title: _vm.$t("scheme.popover.sc"),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "popover_sc",
                                                "label-for": "popover_sc",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.popover.sc"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.parentIcon.popover
                                                      .content_sc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.parentIcon.popover,
                                                      "content_sc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "parentIcon.popover.content_sc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticStyle: { "max-height": "30px" },
                                    attrs: {
                                      src: _vm.parentIcon.img,
                                      alt: "parent icon",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-for": "img",
                                    label:
                                      "圖像：'適合較少經驗參加者'（文化日適用）",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("scheme.banner_size")) +
                                      ": 50px(W) x 50px(H)\n                "
                                  ),
                                  _c("b-form-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: _vm.starIcon.img,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.starIcon, "img", $$v)
                                      },
                                      expression: "starIcon.img",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-form-file", {
                                    attrs: {
                                      id: "starIcon",
                                      accept: "image/*",
                                    },
                                    on: {
                                      change: (e) =>
                                        _vm.uploadImage(e, "starIcon"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "b-tabs",
                                    { attrs: { "content-class": "mt-3" } },
                                    [
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            title: _vm.$t("scheme.popover.en"),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "popover_en",
                                                "label-for": "popover_en",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.popover.en"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.starIcon.popover
                                                      .content_en,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.starIcon.popover,
                                                      "content_en",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "starIcon.popover.content_en",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            title: _vm.$t("scheme.popover.tc"),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "popover_tc",
                                                "label-for": "popover_tc",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.popover.tc"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.starIcon.popover
                                                      .content_tc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.starIcon.popover,
                                                      "content_tc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "starIcon.popover.content_tc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            title: _vm.$t("scheme.popover.sc"),
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "popover_sc",
                                                "label-for": "popover_sc",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "placeholder.popover.sc"
                                                  ),
                                                  type: "text",
                                                  trim: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.starIcon.popover
                                                      .content_sc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.starIcon.popover,
                                                      "content_sc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "starIcon.popover.content_sc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticStyle: { "max-height": "30px" },
                                    attrs: {
                                      src: _vm.starIcon.img,
                                      alt: "star icon",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-7" },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "my-3" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.en") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_en",
                                    "label-for": "title_en",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.en"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_en,
                                      callback: function ($$v) {
                                        _vm.title_en = $$v
                                      },
                                      expression: "title_en",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.tc") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_tc",
                                    "label-for": "title_tc",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.tc"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_tc,
                                      callback: function ($$v) {
                                        _vm.title_tc = $$v
                                      },
                                      expression: "title_tc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.sc") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_sc",
                                    "label-for": "title_sc",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.sc"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_sc,
                                      callback: function ($$v) {
                                        _vm.title_sc = $$v
                                      },
                                      expression: "title_sc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "label" }, [
                        _vm._v(
                          "\n              計劃年份 (請輸入開始年份 e.g.計劃年份為2021-2022, 輸入'2021')\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-form-input", {
                        attrs: { type: "number", trim: "" },
                        model: {
                          value: _vm.year,
                          callback: function ($$v) {
                            _vm.year = $$v
                          },
                          expression: "year",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mt-3",
                          attrs: { id: "parent", "label-for": "parent" },
                        },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.$t("scheme.schemeType"))),
                          ]),
                          _vm._v(" "),
                          _c("b-form-select", {
                            attrs: { options: _vm.schemeTypeList },
                            model: {
                              value: _vm.schemeType,
                              callback: function ($$v) {
                                _vm.schemeType = $$v
                              },
                              expression: "schemeType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { id: "parent", "label-for": "parent" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.$t("scheme.parent"))),
                          ]),
                          _vm._v(" "),
                          _c("b-form-select", {
                            attrs: { options: _vm.schemeList },
                            model: {
                              value: _vm.parent,
                              callback: function ($$v) {
                                _vm.parent = $$v
                              },
                              expression: "parent",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "needCategory",
                            "label-for": "needCategory",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "cat_checkbox",
                                name: "cat_checkbox",
                                value: "1",
                                "unchecked-value": "0",
                                disabled: _vm.parent,
                              },
                              model: {
                                value: _vm.needCategory,
                                callback: function ($$v) {
                                  _vm.needCategory = $$v
                                },
                                expression: "needCategory",
                              },
                            },
                            [
                              _vm._v(
                                "\n                需要活動分類\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "yt_link",
                            label: _vm.$t("scheme.yt_link"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", trim: "" },
                            model: {
                              value: _vm.yt_link,
                              callback: function ($$v) {
                                _vm.yt_link = $$v
                              },
                              expression: "yt_link",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "m-period-table-roller" },
                        [
                          _vm.parent &&
                          (_vm.parent === 1 ||
                            _vm.parent === 4 ||
                            _vm.parent === 5)
                            ? _c("b-table", {
                                staticStyle: {
                                  border: "2px #ff739d solid",
                                  "margin-bottom": "5px",
                                },
                                style: { borderColor: _vm.getColor(_vm.slug) },
                                attrs: {
                                  fields: _vm.getRegRound,
                                  items: _vm.filterRegFields(_vm.reg_fields),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(round)",
                                      fn: function (row) {
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row.item.round) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(startRegDate)",
                                      fn: function (row) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "date",
                                              placeholder: "yyyy-mm-dd",
                                            },
                                            model: {
                                              value: row.item.startDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "startDate",
                                                  $$v
                                                )
                                              },
                                              expression: "row.item.startDate",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "ie-hint" },
                                            [_vm._v("yyyy-mm-dd")]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell(endRegDate)",
                                      fn: function (row) {
                                        return [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "date",
                                              placeholder: "yyyy-mm-dd",
                                            },
                                            model: {
                                              value: row.item.endDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row.item,
                                                  "endDate",
                                                  $$v
                                                )
                                              },
                                              expression: "row.item.endDate",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "ie-hint" },
                                            [_vm._v("yyyy-mm-dd")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3991194542
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.parent && _vm.parent === 4
                            ? _c("small", { staticClass: "red" }, [
                                _vm._v(
                                  '\n                "Late Application" 截止報名日期後，將不能再報名參加此計劃\n              '
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.parent
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "shadow my-3 mse-c1" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { id: "content", "label-for": "content" },
                            },
                            [
                              _c(
                                "b-tabs",
                                { attrs: { "content-class": "mt-3" } },
                                [
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.$t("event.content.en"),
                                      },
                                    },
                                    [
                                      _c("tinymceEditor", {
                                        attrs: {
                                          id: "content_en",
                                          value: _vm.content_en,
                                        },
                                        on: { writeContent: _vm.getContent },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.$t("event.content.tc"),
                                      },
                                    },
                                    [
                                      _c("tinymceEditor", {
                                        attrs: {
                                          id: "content_tc",
                                          value: _vm.content_tc,
                                        },
                                        on: { writeContent: _vm.getContent },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        title: _vm.$t("event.content.sc"),
                                      },
                                    },
                                    [
                                      _c("tinymceEditor", {
                                        attrs: {
                                          id: "content_sc",
                                          value: _vm.content_sc,
                                        },
                                        on: { writeContent: _vm.getContent },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "shadow my-3 mse-c1" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex mb-3" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-success" },
                              on: {
                                click: function ($event) {
                                  return _vm.addTab(_vm.addTabIndex)
                                },
                              },
                            },
                            [_vm._v("添加分段內容")]
                          ),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "ml-2",
                            staticStyle: { width: "150px" },
                            attrs: {
                              type: "number",
                              min: 1,
                              max: _vm.tabs.length + 1,
                              placeholder: "位置(選填)",
                            },
                            model: {
                              value: _vm.addTabIndex,
                              callback: function ($$v) {
                                _vm.addTabIndex = $$v
                              },
                              expression: "addTabIndex",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "mt-3" } },
                        _vm._l(_vm.tabs, function (tab, counter) {
                          return _c(
                            "b-tab",
                            {
                              key: counter,
                              staticClass: "tab",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(counter + 1) +
                                            ") " +
                                            _vm._s(_vm.i18nTitle(tab)) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticStyle: {
                                              "border-radius": "500%",
                                            },
                                            attrs: {
                                              size: "sm",
                                              variant: "danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteTab(counter)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    ×\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { id: "tabs", "label-for": "tabs" } },
                                [
                                  _c(
                                    "b-tabs",
                                    { attrs: { "content-class": "mt-3" } },
                                    [
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.en") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.en"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholder.title.en"
                                              ),
                                              type: "text",
                                              trim: "",
                                            },
                                            model: {
                                              value: tab.title_en,
                                              callback: function ($$v) {
                                                _vm.$set(tab, "title_en", $$v)
                                              },
                                              expression: "tab.title_en",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.en"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_en_" + counter,
                                              value: tab.content_en,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.tc") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.tc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholder.title.tc"
                                              ),
                                              type: "text",
                                              trim: "",
                                            },
                                            model: {
                                              value: tab.title_tc,
                                              callback: function ($$v) {
                                                _vm.$set(tab, "title_tc", $$v)
                                              },
                                              expression: "tab.title_tc",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.tc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_tc_" + counter,
                                              value: tab.content_tc,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.sc") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.sc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholder.title.sc"
                                              ),
                                              type: "text",
                                              trim: "",
                                            },
                                            model: {
                                              value: tab.title_sc,
                                              callback: function ($$v) {
                                                _vm.$set(tab, "title_sc", $$v)
                                              },
                                              expression: "tab.title_sc",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.sc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_sc_" + counter,
                                              value: tab.content_sc,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.parent
                  ? _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { id: "isFinish" } },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      value: "1",
                                      "unchecked-value": "0",
                                    },
                                    model: {
                                      value: _vm.isFinish,
                                      callback: function ($$v) {
                                        _vm.isFinish = $$v
                                      },
                                      expression: "isFinish",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("scheme.isFinish")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "customizeLastModify",
                              "label-for": "customizeLastModify",
                            },
                          },
                          [
                            _c("p", { staticClass: "label" }, [
                              _vm._v("最後修改日期"),
                            ]),
                            _vm._v(" "),
                            _c("b-form-input", {
                              attrs: {
                                id: "customizeLastModify",
                                type: "date",
                              },
                              model: {
                                value: _vm.customizeLastModify,
                                callback: function ($$v) {
                                  _vm.customizeLastModify = $$v
                                },
                                expression: "customizeLastModify",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { id: "web_portal" } },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  value: "1",
                                  "unchecked-value": "0",
                                  disabled: _vm.disabled === "1",
                                },
                                model: {
                                  value: _vm.web_portal,
                                  callback: function ($$v) {
                                    _vm.web_portal = $$v
                                  },
                                  expression: "web_portal",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("scheme.web_portal")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "disabled" } },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { value: "3", "unchecked-value": "0" },
                                model: {
                                  value: _vm.disabled,
                                  callback: function ($$v) {
                                    _vm.disabled = $$v
                                  },
                                  expression: "disabled",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("scheme.disabled")) +
                                    " (Website only)\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { value: "1", "unchecked-value": "0" },
                                model: {
                                  value: _vm.disabled,
                                  callback: function ($$v) {
                                    _vm.disabled = $$v
                                  },
                                  expression: "disabled",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("scheme.disabled")) +
                                    " (Website & Eplatform)\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: { value: "2", "unchecked-value": "0" },
                                model: {
                                  value: _vm.disabled,
                                  callback: function ($$v) {
                                    _vm.disabled = $$v
                                  },
                                  expression: "disabled",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("scheme.web_disabled")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                          disabled: _vm.btn_disabled,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")) + "\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: _vm.isEPlatform()
                            ? "outline-primary"
                            : "outline-secondary",
                          disabled: !_vm.isEPlatform(),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.previewEplatform()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("action.preview")) +
                            " (E-Platform)\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-warning" },
                        on: {
                          click: function ($event) {
                            return _vm.previewWebpage()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("action.preview")) +
                            " (Webpage)\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }