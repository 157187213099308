<template>
  <div>
    <div v-for="(item, index) in content" :key="index">
      <b-row style="padding-bottom: 20px">
        <b-button size="sm" variant="danger" @click="remove(index)">X</b-button>
        <div style="display: flex; flex-wrap: wrap; width: 95%">
          <div v-for="(val, name) in item" :key="name">
            <b-col>
              {{ name }}
              <b-input
                :id="id + name + index"
                v-model="item[name]"
                type="text"
                :name="id + name + index"
                :placeholder="name"
              ></b-input>
            </b-col>
          </div>
        </div>
      </b-row>
    </div>
    <b-button @click="add">Add</b-button>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    id: { type: String, default: '' },
    height: { type: Number, default: 400 },
    empty: { type: Object, default: () => {} },
  },
  data() {
    return {
      content: this.value || [],
      ele: this.id,
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.content = newVal
      },
      deep: true,
    },
    el(newValue) {
      this.ele = newValue
    },
    content(newValue) {
      this.$emit('out', this.$event, this.ele, newValue)
    },
  },
  methods: {
    add() {
      this.content.push(JSON.parse(JSON.stringify(this.empty))) // clone the empty object
    },
    remove(index) {
      this.content.splice(index, 1)
    },
  },
}
</script>
