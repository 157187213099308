<template>
  <!-- <div class="cont"> -->
  <div>
    <!-- {{ passSortKey }} passSortKey {{ showSortBtn }} showSortBtn
    {{ sortKey }} sortKey -->
    <b-col cols="12">
      <div class="cont-search-results">
        <div v-if="currSelected.length > 0" class="search-results">
          <div class="search-filter-cont">
            <div class="search-filter-list">
              <div class="filter-title">{{ $t('event.search_result') }}</div>
              <div class="filter-tags">
                <div
                  v-for="(tag, index) in currSelected"
                  :key="index"
                  class="filter-tag"
                >
                  {{ tag.name ? tag.name : tag.text }}
                  <a
                    href="javascript:void(0)"
                    class="filter-tag-x"
                    @click="rmTag(tag)"
                  >
                    ×</a
                  >
                </div>
              </div>
              <a
                v-if="currSelected.length > 0"
                class="btn-refresh btn-link"
                @click="refresh"
              >
                {{ $t('event.clear_all') }}
              </a>
            </div>
          </div>
        </div>
        <!-- Search Result -->
        <div class="cards-box">
          <div v-if="showSortBtn" class="card-box-header">
            <div
              class="card-box-sort"
              @click="isShowSortMenu = !isShowSortMenu"
            >
              <div v-if="showSortBtn">
                {{ $t('event.sort_by') }}{{ getSortItem }}
              </div>
            </div>
            <div v-if="isShowSortMenu" class="card-box-sort-menu">
              <ul class="card-box-sort-menu-list">
                <li class="card-box-sort-menu-item" @click="sortEvent('date')">
                  {{ $t('event.startdate') }}
                </li>
                <li class="card-box-sort-menu-item" @click="sortEvent('code')">
                  {{ $t('event.code') }}
                </li>
              </ul>
            </div>
            <!-- <span style="float: right">
              <a
                class="tag-sort"
                :class="isGrid ? 'active' : ''"
                @click="isGrid = true"
              >
                <b-icon icon="grid-fill" /> {{ $t('event.grid_view') }}
              </a>
              <a
                class="tag-sort"
                :class="!isGrid ? 'active' : ''"
                @click="isGrid = false"
              >
                <b-icon
                  icon="list-task"
                  style="width: 1.3em; height: 1.3em; vertical-align: bottom"
                />
                {{ $t('event.list_view') }}
              </a>
            </span> -->
          </div>
          <div v-if="loading" class="mt-5">
            <b-spinner variant="secondary"></b-spinner>
          </div>
          <div v-else-if="events.length > 0" class="cards-box-body">
            <!-- Search Result - Cards -->
            <div v-if="isGrid" class="cards-box-content-grid row p-0">
              <EventCard
                v-for="(event, index) in events"
                :key="index"
                :event="event"
                :index="index"
                :bookmarks="bookmarks"
                :schemes="schemes"
                :categories="categories"
                :aoa-pass="aoaPass"
                :aoa-join="aoaJoin"
              />
            </div>
            <!-- Search Result - Cards -->
            <!-- <div v-if="isGrid" class="cards-box-content-grid row"> -->
            <div v-if="false" class="cards-box-content-grid row">
              <EventCard
                v-for="(event, index) in events"
                :key="index"
                :event="event"
                :index="index"
                :bookmarks="bookmarks"
                :schemes="schemes"
                :categories="categories"
                :aoa-pass="aoaPass"
                :aoa-join="aoaJoin"
              />
            </div>
            <!-- Search Result - Table -->
            <div v-if="!isGrid" class="cards-box-content-list">
              <table v-if="events" style="margin-top: 30px">
                <tbody>
                  <tr>
                    <th>{{ $t('event.category_id') }}</th>
                    <th>{{ $t('event.id') }}</th>
                    <th>{{ $t('event.name') }}</th>
                    <th>{{ $t('event.date2') }}</th>
                    <th>{{ $t('navbar.bookmark') }}</th>
                  </tr>
                  <EventCard
                    v-for="(event, index) in events"
                    :key="index"
                    :event="event"
                    :index="index"
                    :bookmarks="bookmarks"
                    :is-list="!isGrid"
                    :schemes="schemes"
                    :categories="categories"
                    :aoa-pass="aoaPass"
                    :aoa-join="aoaJoin"
                  />
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="cards-box-body">
            <h1 class="mt-5"><b-icon icon="exclamation-circle" /></h1>
            <h6 class="mt-3">{{ $t('event.not_found') }}</h6>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
import EventCard from '~/components/common/eventCard.vue'

export default {
  name: 'SearchContainer',
  components: { EventCard },
  props: [
    'filterByCat',
    'passEvents',
    'schemes',
    'onlyBookmarkedEvents',
    'categories',
    'bookmarks',
    'passSortKey',
    'showSortBtn',
    'loading',
  ],
  layout: 'default',
  async fetch() {
    let tmp = await this.$axios.get(
      `${process.env.cloudApiUrl}applications/aoa/pass`
    )
    this.aoaPass = tmp.data.data.result
    tmp = await this.$axios.get(
      `${process.env.cloudApiUrl}applications/aoa/join`
    )
    this.aoaJoin = tmp.data.data.result
  },
  data() {
    return {
      // events: [],
      aoaPass: 'N',
      aoaJoin: 'N',
      testing: false,
      sortKey: this.passSortKey,
      isShowSortMenu: false,
      // bookmarks: [],
      schemes_tab: {
        types: [],
      },
      schemesAll: [],
      tags_tab: {
        types: [],
      },
      venue: [],
      opt: {
        opt_keywords: '',
        opt_date_range: {},
        opt_time: [],
        opt_cat: '',
        opt_skl: '',
        opt_target: [],
        opt_quota: [0, 100],
        opt_parents: [],
        opt_district: '',
        opt_language: '',
        opt_price: [0, 100],
      },
      searchWord: '',
      isGrid: true,
      value: [0, 100],
      cardCarouselSetting: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        // autoplay: true,
        // autoplaySpeed: 5000,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      objects: {
        types: [
          {
            name: this.$t('school.kindergarten'),
            forms: [
              { name: '幼兒班', tag: 'k1', type: 'target' },
              { name: '低班', tag: 'k2', type: 'target' },
              { name: '高班', tag: 'k3', type: 'target' },
            ],
          },
          {
            name: this.$t('school.primaryskl'),
            forms: [
              { name: '小一', tag: 'p1', type: 'target' },
              { name: '小二', tag: 'p2', type: 'target' },
              { name: '小三', tag: 'p3', type: 'target' },
              { name: '小四', tag: 'p4', type: 'target' },
              { name: '小五', tag: 'p5', type: 'target' },
              { name: '小六', tag: 'p6', type: 'target' },
            ],
          },
          {
            name: this.$t('school.secondaryskl'),
            forms: [
              { name: '中一', tag: 'f1', type: 'target' },
              { name: '中二', tag: 'f2', type: 'target' },
              { name: '中三', tag: 'f3', type: 'target' },
              { name: '中四', tag: 'f4', type: 'target' },
              { name: '中五', tag: 'f5', type: 'target' },
              { name: '中六', tag: 'f6', type: 'target' },
            ],
          },
          {
            name: '其他',
            forms: [
              {
                name: this.$t('school.specialskl'),
                tag: 'n',
                type: 'target',
              },
            ],
          },
        ],
      },
      events_original: [],
      tags: [],
      advanced_options: {
        time: ['上午', '下午', '晚上'],
        parents: ['參與', '不參與'],
        target: ['小一', '小二', '小三', '小四', '小五', '小六'],
        district: [
          { text: '請選擇地區', value: null },
          '高山劇場',
          '屯門大會堂',
        ],
      },
      currSelected: [],
      selectedBar: 2,
    }
  },
  computed: {
    getSortItem() {
      if (this.sortKey === 'date') return this.$t('event.startdate')
      if (this.sortKey === 'code') return this.$t('event.code')
      return ''
    },
    events() {
      if (this.$route.query.all) {
        return this.passEvents.filter((event) => {
          /* // console.log(
            event.scheme,
            this.$route.params.slug,
            event.scheme === parseInt(this.$route.query.subscheme)
          ) */
          return event.scheme === parseInt(this.$route.query.subscheme)
        })
      } else {
        return this.onlyBookmarkedEvents
          ? this.passEvents
          : this.filterByCat
          ? this.passEvents.filter((event) => {
              return (
                event.category_id === this.filterByCat &&
                event.scheme === parseInt(this.$route.query.subscheme)
              )
            })
          : []
      }
    },
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
    eventsStored() {
      return this.$store.state.tempStorage.events
    },
    dates() {
      const today = new Date()
      const returnDates = []
      let year = today.getFullYear()
      let months = []
      let monthText = ''
      for (let i = today.getMonth(); i < 12 + today.getMonth(); ++i) {
        if (i % 12 === 0) {
          returnDates.push({ name: year, months })
          months = []
          year++
        }
        switch ((i % 12) + 1) {
          case 1:
            monthText = '一月'
            break
          case 2:
            monthText = '二月'
            break
          case 3:
            monthText = '三月'
            break
          case 4:
            monthText = '四月'
            break
          case 5:
            monthText = '五月'
            break
          case 6:
            monthText = '六月'
            break
          case 7:
            monthText = '七月'
            break
          case 8:
            monthText = '八月'
            break
          case 9:
            monthText = '九月'
            break
          case 10:
            monthText = '十月'
            break
          case 11:
            monthText = '十一月'
            break
          case 12:
            monthText = '十二月'
            break
        }
        months.push({
          name: monthText + '/' + year,
          tag:
            (i % 12) + 1 < 10
              ? year + '-0' + ((i % 12) + 1)
              : year + '-' + ((i % 12) + 1),
          display: (i % 12) + 1 + '月',
          type: 'month',
        })
      }
      returnDates.push({ name: year, months })
      return { types: returnDates }
    },
  },
  watch: {
    passSortKey: {
      handler(newVal, oldVal) {
        this.sortKey = newVal
      },
      deep: true,
    },
    sortKey: {
      handler(newVal, oldVal) {
        this.sortEvent(newVal)
      },
      deep: true,
    },
  },
  created() {
    // added: filter events by category
    /* if (this.filterByCat) {
      this.events = this.passEvents.filter((event) => {
        return event.category_id === this.filterByCat
      })
    } else {
      this.events = this.passEvents
    } */
    // console.log(this.onlyBookmarkedEvents)
    // this.events = this.passEvents
    // for advanced search range picker
    this.min = 0
    this.max = 250
    this.step = 10
    this.enableCross = false
    this.tooltip = false
    this.tooltipMerge = false
    this.formatter = (value) => `${value}`
    // for advanced search calendar
    this.min_quota = 0
    this.max_quota = 100
    this.min_price = 0
    this.max_price = 100
    this.singleDatePicker = 'range'
    this.showDropdowns = true
    this.showRanges = false
    const ytd = new Date()
    ytd.setDate(ytd.getDate() - 1)
    this.advancedMinDate = ytd
  },
  methods: {
    // bookmark
    syncBookmark() {
      // console.log('SyncBookmark' + this.events.length)
      this.events.forEach((event) => {
        if (this.bookmarks.find((bk) => parseInt(bk.event_id) === event.id)) {
          event.isBookmarked = true
          // console.log(event.code)
        }
      })
    },
    bookmark(index) {
      this.events[index].isBookmarked = !this.events[index].isBookmarked
      /* // console.log(
        this.events[index].isBookmarked + ' ' + this.events[index].code
      ) */
      if (this.events[index].isBookmarked) {
        this.addbookmark(this.events[index].id)
      } else {
        this.delbookmark(this.events[index].id)
      }
    },
    async addbookmark(code) {
      try {
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}bookmarks/add`,
          {
            id: code,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async delbookmark(code) {
      try {
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}bookmarks/delete`,
          {
            id: code,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    // i18n
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    // others
    sortEvent(key) {
      this.isShowSortMenu = false
      if (key === 'code') {
        this.sortKey = 'code'
        this.events.sort((a, b) => {
          // console.log(a.code + ' ' + b.code)
          if (a.code < b.code) return -1
          if (a.code > b.cdoe) return 1
          return 0
        })
      } else if (key === 'date') {
        this.sortKey = 'date'
        this.events.sort((a, b) => {
          // console.log(a.title_tc, b.title_tc)
          // console.log(this.getDates(a)[0] + ' ' + this.getDates(b)[0])
          const dateC = new Date(2100, 1, 1)
          const dateA = this.getDates(a)[0] ? this.getDates(a)[0] : dateC
          const dateB = this.getDates(b)[0] ? this.getDates(b)[0] : dateC
          if (dateA < dateB) return -1
          if (dateA > dateB) return 1
          return 0
        })
      }
    },
    // determine if the img is too wide
    isLandscape(img) {
      const img2 = new Image()
      img2.src = img
      // console.log(img2.offsetWidth + ' ' + img2.offsetHeight)
      return img2.offsetHeight / img2.offsetWidth < 192 / 256
    },
    getTargets(event) {
      const targets = []
      if (event.EventSlot) {
        for (let i = 0; i < event.EventSlot.length; i++) {
          const allTargets = event.EventSlot[i].target.split(',')
          // console.log(allTargets)
          allTargets.map((t) => targets.push(t.toString()))
        }
        const uniqueTargets = targets.filter((c, index) => {
          return targets.indexOf(c) === index
        })
        // console.log('targets: ' + uniqueTargets)
        return uniqueTargets
      }
    },
    getTargetSchool(event) {
      const uniqueTargets = this.getTargets(event)
      const resultSklArray = []
      let uniqueSkl = []
      uniqueTargets.map((u) => {
        if (u.toString().includes('k')) {
          resultSklArray.push(this.objects.types[0].name)
        }
        if (u.toString().includes('p')) {
          resultSklArray.push(this.objects.types[1].name)
        }
        if (u.toString().includes('f')) {
          resultSklArray.push(this.objects.types[2].name)
        }
        if (u.toString().includes('n')) {
          resultSklArray.push(this.objects.types[3].forms[0].name)
        }
        uniqueSkl = resultSklArray.filter((c, index) => {
          return resultSklArray.indexOf(c) === index
        })
      })
      // console.log('uniqueSkl ' + uniqueSkl)
      return uniqueSkl
    },
    getTagId(tabName) {
      for (let i = 0; i < this.tags.length; i++) {
        if (this.i18nName(this.tags[i]) === tabName) {
          return this.tags[i].id
        }
      }
      return ''
    },
    format(date) {
      return date.slice(0, 10)
    },
    getDates(event) {
      const dates = []
      // console.log('dates: ' + event.EventSlot.length)
      if (event.EventSlot) {
        // console.log('event ' + JSON.stringify(event.EventSlot[0]))
        for (let i = 0; i < event.EventSlot.length; i++) {
          dates.push(new Date(event.EventSlot[i].date))
        }
        const uniqueDates = []
        dates.forEach((c) => {
          if (!uniqueDates.includes(c)) {
            uniqueDates.push(c)
          }
        })
        // console.log('dates: ' + uniqueDates)
        return uniqueDates
      }
    },
    getSchemes(id) {
      if (this.schemes) {
        for (let i = 0; i < this.schemes.length; i++) {
          if (this.schemes[i].id === id) {
            return this.schemes[i].title_tc
          }
        }
      }
      return ''
    },
    refresh() {
      this.currSelected = []
      this.filterEvents()
    },
    advancedSearch() {
      /* console.log(
        JSON.stringify(this.opt.opt_date_range) +
          this.opt.opt_keywords +
          ' ' +
          this.opt.opt_date_start +
          ' ' +
          this.opt.opt_date_end +
          ' ' +
          this.opt.opt_time +
          ' ' +
          this.opt.opt_cat +
          ' ' +
          this.opt.opt_skl +
          ' ' +
          this.opt.opt_target +
          ' ' +
          this.opt.opt_quota +
          ' ' +
          this.opt.opt_parents +
          ' ' +
          this.opt.opt_district +
          ' ' +
          this.opt.opt_language +
          ' ' +
          this.opt.opt_price
      ) */
    },
    showSelectedBar(index) {
      this.selectedBar = index
    },
    filterEvents() {
      if (this.events_original.length && this.currSelected.length) {
        this.events = this.events_original
        const currSelecteScheme = this.currSelected.filter(
          (curr) => curr.type === 'scheme'
        )
        const currSelectedText = this.currSelected.filter(
          (curr) => curr.type === 'text'
        )
        const currSelectedMonth = this.currSelected.filter(
          (curr) => curr.type === 'month'
        )
        const currSelectedCat = this.currSelected.filter(
          (curr) => curr.type === 'cat'
        )
        const currSelectedTarget = this.currSelected.filter(
          (curr) => curr.type === 'target'
        )
        this.events = currSelecteScheme.length
          ? this.events.filter((event) =>
              currSelecteScheme.find(
                (t) => this.getSchemes(event.scheme) === t.name
              )
            )
          : this.events
        this.events = currSelectedText.length
          ? this.events.filter((event) =>
              currSelectedText.find((t) =>
                this.i18nTitle(event).toString().includes(t.name)
              )
            )
          : this.events
        this.events = currSelectedMonth.length
          ? this.events.filter((event) =>
              currSelectedMonth.find((t) => {
                let check = false
                if (this.getDates(event).length !== 0) {
                  // for each date in EventSlot
                  this.getDates(event).map((date) => {
                    date = date.getMonth() + 1
                    if (date < 10) {
                      date = '0'.concat(date.toString())
                    }
                    date = this.getDates(event)[0]
                      .toString()
                      .slice(11, 15)
                      .concat('-')
                      .concat(date.toString())
                    // console.log('tag: ' + t.tag)
                    check = date.includes(t.tag)
                  })
                }
                return check
              })
            )
          : this.events
        this.events = currSelectedCat.length
          ? this.events.filter((event) =>
              currSelectedCat.find((t) =>
                event.tag.includes(this.getTagId(t.name))
              )
            )
          : this.events
        this.events = currSelectedTarget.length
          ? this.events.filter((event) =>
              currSelectedTarget.find((t) =>
                this.getTargets(event).toString().includes(t.tag)
              )
            )
          : this.events
      } else if (
        this.events_original.length &&
        this.currSelected.length === 0
      ) {
        this.events = this.events_original
      }
    },
    filItems(value, type) {
      let item = {}
      if (type === 'text') {
        item = {
          name: value,
          type,
        }
        this.searchWord = ''
      } else if (type === 'school') {
        // console.log('value ' + JSON.stringify(value.forms))
        for (let i = 0; i < value.forms.length; i++) {
          const item = {
            name: value.forms[i].name,
            tag: value.forms[i].tag,
            type: value.forms[i].type,
          }
          this.filItems(item, 'age')
          // console.log('item ' + item)
        }
        return
      } else {
        item = value
      }
      if (!this.currSelected.includes(value)) {
        this.currSelected.push(item)
        this.filterEvents()
      } else {
        this.rmTag(item)
      }
      // console.log(this.currSelected)
      // this.selectedBar = 0
    },
    rmTag(tag) {
      // console.log('rmTag' + tag)
      this.currSelected.map((t, index) => {
        if (t === tag) {
          this.currSelected.splice(index, 1)
          this.filterEvents()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.cards-box-body {
  text-align: center;
}
.schemeLink {
  color: #3f3f3f;
}
.btn-refresh {
  width: auto; // 46px;
  padding: 10px;
  text-decoration: underline;
  color: #3f3f3f;
  cursor: pointer;
}
.tag-sort {
  cursor: pointer;
}
.vue-range-slider.slider-component .slider .slider-process {
  background-color: rgb(255, 93, 18);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.search {
  padding-top: 10px;
}
.cont {
  // width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 50px;
}
.cont-search {
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
}
.search-filter {
  color: rgb(255, 93, 18);
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.cont-search-results {
  // margin-top: 10px;
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.search-results {
  background: rgb(228, 228, 228);
  padding: 10px 30px;
}
.search-filter-cont {
  display: flex;
}
.search-filter-list {
  display: flex;
}
.filter-title {
  color: black;
  font-weight: bold;
  padding: 10px;
  min-width: 80px;
  display: flex;
  align-content: center;
}
.filter-tags {
  display: flex;
  flex-wrap: wrap;
}
.filter-tag {
  color: white;
  background-color: rgb(255, 93, 18);
  padding: 5px 10px 5px 20px;
  margin: 5px;
  font-weight: bold;
}
.filter-tag-x {
  padding-left: 10px;
}
.filter-tag > * {
  color: white;
  text-decoration: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.cards-box {
  width: 100%;
  // min-height: calc(100vh - 350px);
  padding-bottom: 30px;
  box-shadow: none;
}
.card-box-show-past {
  display: inline-block;
  margin-left: 150px;
}
.card-box-sort {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}
.card-box-sort-menu {
  position: absolute;
  top: 0;
  left: 105px;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.card-box-sort-menu-list {
  list-style-type: none;
}
.card-box-sort-menu-item {
  padding: 10px;
}
.card-box-header {
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-bottom-style: solid;
  border-bottom-width: 1.3px;
  padding: 10px 30px;
  text-align: left;
  position: relative;
}
.card-box-header a {
  font-size: 110%;
  color: rgb(138, 138, 138);
  font-weight: bold;
  margin-left: 20px;
}
.card-box-header a:hover,
.card-box-header a.active {
  color: rgb(255, 93, 18);
}
.cards-box-content-grid {
  padding: 10px 0 20px 25px;
}
table {
  border: 1px solid black;
  width: 95%;
  text-align: left;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
table > tbody > tr > th {
  background-color: #f5ddd4;
  height: 40px;
  font-size: 130%;
  padding: 10px;
  border: 1px solid rgb(138, 138, 138);
  vertical-align: middle;
}
table > tbody > tr {
  vertical-align: middle; // top;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .cont {
    width: 100%;
  }

  .search-filter.col-1 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .search-bar .col-8 {
    flex: none;
    max-width: unset;
  }

  .search-results {
    padding: 8px;
  }
}
</style>
