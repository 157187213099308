<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="8">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <p class="label">{{ $t('news.date') }}</p>
          <b-form-datepicker
            id="date"
            v-model="date"
            today-button
            reset-button
            trim
          ></b-form-datepicker>

          <b-form-group id="scheme" label-for="scheme">
            <p class="label">{{ $t('event.scheme') }}</p>
            <b-form-select
              v-model="scheme"
              :options="schemeList"
            ></b-form-select>
          </b-form-group>

          <b-form-group id="title" label-for="title">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('news.title.en')">
                <!-- <b-form-input
                  id="title_en"
                  v-model="title_en"
                  type="text"
                  trim
                ></b-form-input> -->
                <b-form-textarea
                  id="title_en"
                  v-model="title_en"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-tab>
              <b-tab :title="$t('news.title.tc')">
                <!-- <b-form-input
                  id="title_tc"
                  v-model="title_tc"
                  type="text"
                  trim
                ></b-form-input> -->
                <b-form-textarea
                  id="title_tc"
                  v-model="title_tc"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-tab>
              <b-tab :title="$t('news.title.sc')">
                <!-- <b-form-input
                  id="title_sc"
                  v-model="title_sc"
                  type="text"
                  trim
                ></b-form-input> -->
                <b-form-textarea
                  id="title_sc"
                  v-model="title_sc"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-tab>
            </b-tabs>
          </b-form-group>

          <b-form-group id="content" label-for="content">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('news.content.en')">
                <tinymceEditor
                  id="content_en"
                  :value="content_en"
                  @writeContent="getContent"
                />
              </b-tab>
              <b-tab :title="$t('news.content.tc')">
                <tinymceEditor
                  id="content_tc"
                  :value="content_tc"
                  @writeContent="getContent"
                />
              </b-tab>
              <b-tab :title="$t('news.content.sc')">
                <tinymceEditor
                  id="content_sc"
                  :value="content_sc"
                  @writeContent="getContent"
                />
              </b-tab>
            </b-tabs>
          </b-form-group>

          <p class="label">{{ $t('news.startDate') }}</p>
          <b-form-datepicker
            id="startDate"
            v-model="startDate"
            today-button
            reset-button
            required
            trim
          ></b-form-datepicker>
          <p class="label">{{ $t('news.endDate') }}</p>
          <b-form-datepicker
            id="endDate"
            v-model="endDate"
            today-button
            reset-button
            required
            trim
          ></b-form-datepicker>

          <p class="label">{{ $t('news.order') }}</p>
          <b-form-input
            id="orders"
            v-model="orders"
            type="number"
            trim
          ></b-form-input>

          <b-form-group id="disabled">
            <b-form-checkbox v-model="disabled" value="1" unchecked-value="0">{{
              $t('news.disabled')
            }}</b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-radio v-model="deployTarget" :value="0">
              {{ $t('news.deployTarget0') }}
            </b-form-radio>
            <b-form-radio v-model="deployTarget" :value="1">
              {{ $t('news.deployTarget1') }}
            </b-form-radio>
            <b-form-radio v-model="deployTarget" :value="2">
              {{ $t('news.deployTarget2') }}
            </b-form-radio>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submit')
            }}</b-button>
            <b-button
              v-if="deployTarget === 0 || deployTarget === 2"
              variant="outline-primary"
              @click="previewEplatform()"
            >
              {{ $t('action.preview') }} (E-Platform)
            </b-button>
            <b-button
              v-if="deployTarget === 1 || deployTarget === 2"
              variant="outline-warning"
              @click="previewWebpage()"
            >
              {{ $t('action.preview') }} (Webpage)
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import TinymceEditor from '~/components/admin/tinymceEditor'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    TinymceEditor,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      // console.log(this.action)
      if (this.action === 'edit' || this.action === 'copy') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}news/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.scheme = data.scheme
        this.date = data.date
          ? this.$moment(data.date).format('yyyy.MM.DD')
          : null
        this.startDate = data.startDate
          ? this.$moment(data.startDate).format('yyyy-MM-DD')
          : null
        this.endDate = data.endDate
          ? this.$moment(data.endDate).format('yyyy-MM-DD')
          : null
        this.title_en = data.title_en
        this.title_tc = data.title_tc
        this.title_sc = data.title_sc
        this.content_en = data.content_en
        this.content_tc = data.content_tc
        this.content_sc = data.content_sc
        this.deployTarget = data.deployTarget
        this.orders = data.orders
        this.disabled = data.disabled
      }
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }

    try {
      const schemel = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/public/web/scheme/name`
      )
      this.schemeAll = schemel.data.data

      let schemeListData = schemel.data.data
      schemeListData = schemeListData.map((d) => {
        d.value = d.id
        d.text = d.title_tc
        delete d.id
        delete d.title_tc
        return d
      })

      // hardcode
      schemeListData = schemeListData.filter(
        (s) => s.value === 1 || s.parent === 1
      )

      this.schemeList = this.schemeList.concat(schemeListData)
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      schemeList: [{ value: null, text: 'General' }],
      id: null,
      scheme: null,
      date: '',
      startDate: '',
      endDate: '',
      title_en: '',
      title_tc: '',
      title_sc: '',
      content_en: '',
      content_tc: '',
      content_sc: '',
      deployTarget: 0,
      disabled: 0,
      orders: null,
      error: null,
    }
  },
  computed: {
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      return tc
    },
  },
  methods: {
    setSc(data) {
      this.title_sc = data[0]
      this.content_sc = data[1]
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async previewEplatform() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }

      window.open(`${process.env.previewUrl}${this.$i18n.locale}`)

      this.revertPreviewDbEntry(previewId)
    },
    async previewWebpage() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
        await this.$axios.$post(
          `${process.env.previewApiUrl}preview/toWebPreview`
        )
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
      if (!this.scheme) {
        window.open(`${process.env.previewWebUrl}${this.$i18n.locale}`)
      } else {
        window.open(
          `${process.env.previewWebUrl}${this.$i18n.locale}/scheme/scds`
        )
      }

      this.revertPreviewDbEntry(previewId)
    },
    async revertPreviewDbEntry(previewId) {
      if (previewId) {
        // delete preview db entry after preview
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#d9534f',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.previewApiUrl}news/delete`,
            {
              id: previewId,
            }
          )
          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)
        }
      } else if (this.action === 'edit') {
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#1cbcb4',
        })
        if (back.value) {
          // post original data to preview db
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}news/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)

          const result2 = await this.$axios.$post(
            `${process.env.previewApiUrl}news/update`,
            {
              // data,
              id: data.id,
              scheme: data.scheme,
              date: data.date !== '' ? data.date : null,
              startDate: data.startDate
                ? data.$moment(data.startDate).format('yyyy.MM.DD')
                : null,
              endDate: data.endDate
                ? data.$moment(data.endDate).format('yyyy.MM.DD')
                : null,
              title_en: data.title_en,
              title_tc: data.title_tc,
              title_sc: data.title_sc,
              content_en: data.content_en,
              content_tc: data.content_tc,
              content_sc: data.content_sc,
              deployTarget: data.deployTarget,
              orders: parseInt(data.orders),
              disabled: data.disabled,
            }
          )

          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result2.error !== undefined) throw new Error(result2.error)
        }
      }
    },
    async callapi(url, previewId) {
      let result
      if (this.action === 'edit') {
        result = await this.$axios.$post(`${url}news/update`, {
          id: this.id,
          scheme: this.scheme,
          date: this.date !== '' ? this.date : null,
          startDate: this.startDate
            ? this.$moment(this.startDate).format('yyyy.MM.DD')
            : null,
          endDate: this.endDate
            ? this.$moment(this.endDate).format('yyyy.MM.DD')
            : null,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          deployTarget: this.deployTarget,
          orders: parseInt(this.orders),
          disabled: this.disabled,
          previewId,
        })
      } else {
        result = await this.$axios.$post(`${url}news/add`, {
          scheme: this.scheme,
          date: this.date,
          startDate: this.startDate
            ? this.$moment(this.startDate).format('yyyy.MM.DD')
            : null,
          endDate: this.endDate
            ? this.$moment(this.endDate).format('yyyy.MM.DD')
            : null,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          deployTarget: this.deployTarget,
          orders: parseInt(this.orders),
          disabled: this.disabled,
        })
      }
      if (result.error !== undefined) throw new Error(result.error)
    },
    async submit() {
      try {
        this.callapi(process.env.previewApiUrl)
        this.callapi(process.env.cloudApiUrl)

        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/news'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
