var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cont-tab" }, [
    _vm.schemeAll.length
      ? _c(
          "div",
          { staticClass: "scheme-title" },
          _vm._l(_vm.getPaths(), function (n, index) {
            return _c(
              "span",
              { key: n.id, staticClass: "path" },
              [
                _c("nuxt-link", {
                  class:
                    index === _vm.getPaths().length - 1
                      ? "scheme-link-last"
                      : "scheme-link",
                  attrs: { to: _vm.localePath(`/${n.slug}`) },
                  domProps: { innerHTML: _vm._s(n.title) },
                }),
                index != _vm.getPaths().length - 1
                  ? _c("span", [_vm._v(" > ")])
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "b-nav",
          {
            ref: "schemeTabNav",
            staticClass: "nav-ie",
            attrs: { tabs: "", justified: "" },
          },
          _vm._l(_vm.mainScheme, function (n) {
            return _c(
              "b-nav-item",
              {
                key: n.id,
                class: `scheme-tab-item scheme-tab-item-${n.slug}`,
                attrs: {
                  to: _vm.localePath(`/scheme/${n.slug}`),
                  active:
                    n.id === _vm.currSchemeId ||
                    n.id === _vm.getParentSchemeId(_vm.currSchemeId)
                      ? true
                      : false,
                },
              },
              [
                _c(
                  "div",
                  {
                    class:
                      _vm.$i18n.locale === "en" ? "tab-txt-en" : "tab-txt-chi",
                  },
                  [
                    _c("img", {
                      staticClass: "scheme-tab-img",
                      attrs: { src: _vm.getTmpImg(n.slug), alt: "scheme tag" },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.i18nTitle(n)))]),
                  ]
                ),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }