var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "10" } }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "slug", "label-for": "slug" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.$t("scheme.slug"))),
                          ]),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: { type: "text", required: "", trim: "" },
                            model: {
                              value: _vm.slug,
                              callback: function ($$v) {
                                _vm.slug = $$v
                              },
                              expression: "slug",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "img",
                            label: _vm.$t("scheme.img"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.img,
                              callback: function ($$v) {
                                _vm.img = $$v
                              },
                              expression: "img",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-file", {
                            attrs: { id: "img", accept: "image/*" },
                            on: { change: _vm.uploadImage },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { "max-height": "100px" },
                            attrs: { src: _vm.img, alt: "other page" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-7" },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "mt-3" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.en") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_en",
                                    "label-for": "title_en",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.en"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_en,
                                      callback: function ($$v) {
                                        _vm.title_en = $$v
                                      },
                                      expression: "title_en",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.tc") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_tc",
                                    "label-for": "title_tc",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.tc"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_tc,
                                      callback: function ($$v) {
                                        _vm.title_tc = $$v
                                      },
                                      expression: "title_tc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.sc") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_sc",
                                    "label-for": "title_sc",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.sc"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_sc,
                                      callback: function ($$v) {
                                        _vm.title_sc = $$v
                                      },
                                      expression: "title_sc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { id: "parent", "label-for": "parent" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.$t("event.scheme"))),
                          ]),
                          _vm._v(" "),
                          _c("b-form-select", {
                            attrs: { options: _vm.schemeList },
                            model: {
                              value: _vm.scheme,
                              callback: function ($$v) {
                                _vm.scheme = $$v
                              },
                              expression: "scheme",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "shadow my-3 mse-c1" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "content", "label-for": "content" } },
                        [
                          _c(
                            "b-tabs",
                            { attrs: { "content-class": "mt-3" } },
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.en") },
                                },
                                [
                                  _c("tinymceEditor", {
                                    attrs: {
                                      id: "content_en",
                                      value: _vm.content_en,
                                    },
                                    on: { writeContent: _vm.getContent },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.tc") },
                                },
                                [
                                  _c("tinymceEditor", {
                                    attrs: {
                                      id: "content_tc",
                                      value: _vm.content_tc,
                                    },
                                    on: { writeContent: _vm.getContent },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.sc") },
                                },
                                [
                                  _c("tinymceEditor", {
                                    attrs: {
                                      id: "content_sc",
                                      value: _vm.content_sc,
                                    },
                                    on: { writeContent: _vm.getContent },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "shadow my-3 mse-c1" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-success" },
                          on: { click: _vm.addTab },
                        },
                        [_vm._v(_vm._s(_vm.$t("scheme.add_tab")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "mt-3" } },
                        _vm._l(_vm.tabs, function (tab, counter) {
                          return _c(
                            "b-tab",
                            {
                              key: counter,
                              staticClass: "tab",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.i18nTitle(tab)) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticStyle: {
                                              "border-radius": "500%",
                                            },
                                            attrs: {
                                              size: "sm",
                                              variant: "danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteTab(counter)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    ×\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { id: "tabs", "label-for": "tabs" } },
                                [
                                  _c(
                                    "b-tabs",
                                    { attrs: { "content-class": "mt-3" } },
                                    [
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.en") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.en"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("medium-editor", {
                                            staticClass: "medium-editor",
                                            attrs: {
                                              text: tab.title_en,
                                              options: _vm.options_title_en,
                                            },
                                            on: {
                                              edit: (operation) => {
                                                tab.title_en =
                                                  operation.api.origElements.innerHTML
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.en"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_en_" + counter,
                                              value: tab.content_en,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                          _vm._v(" "),
                                          tab.commentlist_en
                                            ? _c("CommentForm", {
                                                attrs: {
                                                  "comment-data":
                                                    tab.commentlist_en,
                                                  lang: 0,
                                                  edit: true,
                                                },
                                                model: {
                                                  value: tab.commentlist_en,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      tab,
                                                      "commentlist_en",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tab.commentlist_en",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.tc") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.tc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("medium-editor", {
                                            staticClass: "medium-editor",
                                            attrs: {
                                              text: tab.title_tc,
                                              options: _vm.options_title_tc,
                                            },
                                            on: {
                                              edit: (operation) => {
                                                tab.title_tc =
                                                  operation.api.origElements.innerHTML
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.tc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_tc_" + counter,
                                              value: tab.content_tc,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                          _vm._v(" "),
                                          tab.commentlist_tc
                                            ? _c("CommentForm", {
                                                attrs: {
                                                  "comment-data":
                                                    tab.commentlist_tc,
                                                  lang: 1,
                                                  edit: true,
                                                },
                                                model: {
                                                  value: tab.commentlist_tc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      tab,
                                                      "commentlist_tc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tab.commentlist_tc",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.sc") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.sc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("medium-editor", {
                                            staticClass: "medium-editor",
                                            attrs: {
                                              text: tab.title_sc,
                                              options: _vm.options_title_sc,
                                            },
                                            on: {
                                              edit: (operation) => {
                                                tab.title_sc =
                                                  operation.api.origElements.innerHTML
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.sc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_sc_" + counter,
                                              value: tab.content_sc,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                          _vm._v(" "),
                                          tab.commentlist_sc
                                            ? _c("CommentForm", {
                                                attrs: {
                                                  "comment-data":
                                                    tab.commentlist_sc,
                                                  lang: 2,
                                                  edit: true,
                                                },
                                                model: {
                                                  value: tab.commentlist_sc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      tab,
                                                      "commentlist_sc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tab.commentlist_sc",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("news.order"))),
                ]),
                _vm._v(" "),
                _c("b-form-input", {
                  attrs: { id: "orders", type: "number", trim: "" },
                  model: {
                    value: _vm.orders,
                    callback: function ($$v) {
                      _vm.orders = $$v
                    },
                    expression: "orders",
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "disabled" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { value: "1", "unchecked-value": "0" },
                        model: {
                          value: _vm.disabled,
                          callback: function ($$v) {
                            _vm.disabled = $$v
                          },
                          expression: "disabled",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("news.disabled")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                          disabled: _vm.btn_disabled,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")) + "\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-warning" },
                        on: {
                          click: function ($event) {
                            return _vm.previewWebpage()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("action.preview")) +
                            " (Webpage)\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }