var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { attrs: { cols: "5" } },
        [
          _vm.error
            ? _c("Notification", { attrs: { message: _vm.error } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "back" },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                _c("img", {
                  staticStyle: { height: "25px" },
                  attrs: {
                    src: `${_vm.staticPrefix}img/login_home.png`,
                    alt: "home",
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-card", [
            _c(
              "div",
              { staticClass: "login" },
              [
                _c("b-col", { attrs: { cols: "8" } }, [
                  _c("h3", { staticClass: "title-login" }, [
                    _vm._v(_vm._s(_vm.$t("g.title"))),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "title-login" }, [
                    _vm._v(_vm._s(_vm.$t("login.forgotpw"))),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    attrs: { method: "post" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.loginSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { id: "email", "label-for": "email" } },
                      [
                        _c("p", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("login.email"))),
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "email",
                            type: "email",
                            required: "",
                            trim: "",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              id: "login",
                              type: "submit",
                              disabled: _vm.api && _vm.api.includes("preview"),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("action.submit")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "b-row",
                          { attrs: { "align-h": "between" } },
                          [
                            _c(
                              "b-col",
                              { attrs: { align: "left" } },
                              [
                                _c(
                                  "nuxt-link",
                                  { attrs: { to: _vm.localePath("/login") } },
                                  [_vm._v(_vm._s(_vm.$t("login.login")))]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("b-col", { attrs: { align: "right" } }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.registrationLink,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("login.donthaveAcc")))]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }