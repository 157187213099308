var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-cont" }, [
    _c(
      "div",
      { staticClass: "spaps-div" },
      [
        _c("h3", [
          _vm._v("❸ " + _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.title"))),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("apply.spaps.tab_txt.tab2.content")))]),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title mt-3" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.venue")) +
                      "\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-checkbox-group", {
                      attrs: {
                        id: "checkbox-group-venue",
                        options: _vm.venues,
                        state:
                          _vm.validate || _vm.validateOnclick[0]
                            ? !!_vm.equipments.venue &&
                              _vm.equipments.venue.length > 0
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(0)
                        },
                      },
                      model: {
                        value: _vm.equipments.venue,
                        callback: function ($$v) {
                          _vm.$set(_vm.equipments, "venue", $$v)
                        },
                        expression: "equipments.venue",
                      },
                    })
                  : _c(
                      "div",
                      { staticClass: "choice" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.equipments.venue &&
                                _vm.equipments.venue.length
                                ? ""
                                : "/"
                            ) +
                            "\n          "
                        ),
                        _vm._l(_vm.equipments.venue, function (d, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(`apply.spaps.tab_txt.tab2.venue_${d}`)
                                ) +
                                "\n          "
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-col", { attrs: { md: "6" } }, [
              _vm.equipments.venue && _vm.equipments.venue.find((d) => d === 5)
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "subtitle mt-3", attrs: { id: "5" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("apply.spaps.tab_txt.tab2.otherVenue")
                              ) +
                              "\n            "
                          ),
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.edit
                        ? _c("b-form-input", {
                            attrs: {
                              type: "text",
                              state:
                                _vm.equipments.venue &&
                                _vm.equipments.venue.find((d) => d === 5) &&
                                (_vm.validate || _vm.validateOnclick[3])
                                  ? !!_vm.equipments.otherVenue
                                  : null,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.inputChanged(3)
                              },
                            },
                            model: {
                              value: _vm.equipments.otherVenue,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipments, "otherVenue", $$v)
                              },
                              expression: "equipments.otherVenue",
                            },
                          })
                        : _c("div", { staticClass: "choice" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.equipments.otherVenue !== null
                                    ? _vm.equipments.otherVenue
                                    : "/"
                                ) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title mt-3" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.plan")) +
                      "\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-radio-group", {
                      attrs: {
                        id: "radio-group-plan",
                        options: _vm.plans,
                        state:
                          _vm.validate || _vm.validateOnclick[1]
                            ? !!_vm.equipments.plan
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(1)
                        },
                      },
                      model: {
                        value: _vm.equipments.plan,
                        callback: function ($$v) {
                          _vm.$set(_vm.equipments, "plan", $$v)
                        },
                        expression: "equipments.plan",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.equipments.plan
                              ? _vm.$t(
                                  `apply.spaps.tab_txt.tab2.plan_${_vm.equipments.plan}`
                                )
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              [
                _c(
                  "div",
                  { staticClass: "event-title mt-3", attrs: { id: "5" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.size")) +
                        "\n          "
                    ),
                    _c("span", { staticClass: "red" }, [_vm._v("*")]),
                  ]
                ),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        type: "number",
                        state:
                          _vm.validate || _vm.validateOnclick[2]
                            ? _vm.checkNumber(_vm.equipments.size)
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(2)
                        },
                      },
                      model: {
                        value: _vm.equipments.size,
                        callback: function ($$v) {
                          _vm.$set(_vm.equipments, "size", $$v)
                        },
                        expression: "equipments.size",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.equipments.size !== null
                              ? _vm.equipments.size
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title mt-3" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.floor")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-checkbox-group", {
                      attrs: {
                        id: "checkbox-group-floor",
                        options: _vm.floors,
                      },
                      on: { input: _vm.inputChanged },
                      model: {
                        value: _vm.equipments.floor,
                        callback: function ($$v) {
                          _vm.$set(_vm.equipments, "floor", $$v)
                        },
                        expression: "equipments.floor",
                      },
                    })
                  : _c(
                      "div",
                      { staticClass: "choice" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.equipments.floor &&
                                _vm.equipments.floor.length
                                ? ""
                                : "/"
                            ) +
                            "\n          "
                        ),
                        _vm._l(_vm.equipments.floor, function (d, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(`apply.spaps.tab_txt.tab2.floor_${d}`)
                                ) +
                                "\n          "
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c("b-col", { attrs: { md: "6" } }, [
              _vm.equipments.floor && _vm.equipments.floor.find((d) => d === 3)
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "subtitle mt-3", attrs: { id: "5" } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("apply.spaps.tab_txt.tab2.floorSpecify")
                              ) +
                              "\n            "
                          ),
                          _c("span", { staticClass: "red" }, [_vm._v("*")]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.edit
                        ? _c("b-form-input", {
                            attrs: {
                              type: "text",
                              state:
                                _vm.equipments.floor &&
                                _vm.equipments.floor.find((d) => d === 3) &&
                                (_vm.validate || _vm.validateOnclick[4])
                                  ? !!_vm.equipments.floorSpecify
                                  : null,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.inputChanged(4)
                              },
                            },
                            model: {
                              value: _vm.equipments.floorSpecify,
                              callback: function ($$v) {
                                _vm.$set(_vm.equipments, "floorSpecify", $$v)
                              },
                              expression: "equipments.floorSpecify",
                            },
                          })
                        : _c("div", { staticClass: "choice" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.equipments.floorSpecify !== null
                                    ? _vm.equipments.floorSpecify
                                    : "/"
                                ) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "event-title mt-3" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.equipment")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _vm.edit
          ? _c("b-form-checkbox-group", {
              attrs: { id: "checkbox-group-equipment", options: _vm.equip },
              on: { input: _vm.inputChanged },
              model: {
                value: _vm.equipments.equipment,
                callback: function ($$v) {
                  _vm.$set(_vm.equipments, "equipment", $$v)
                },
                expression: "equipments.equipment",
              },
            })
          : _c(
              "div",
              { staticClass: "choice" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.equipments.equipment &&
                        _vm.equipments.equipment.length
                        ? ""
                        : "/"
                    ) +
                    "\n      "
                ),
                _vm._l(_vm.equipments.equipment, function (d, index) {
                  return _c("span", { key: index }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(`apply.spaps.tab_txt.tab2.equipment_${d}`)
                        ) +
                        "\n      "
                    ),
                  ])
                }),
              ],
              2
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "spaps-div" }, [
      _c(
        "div",
        { class: _vm.exceptionalScheme ? "spaps-div2" : "" },
        [
          _c("h3", [
            _vm._v(
              "\n        ❹ " +
                _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.title2")) +
                "\n        "
            ),
            _vm.exceptionalScheme
              ? _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.not_applicable")) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("p", {
            domProps: {
              innerHTML: _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.content2")),
            },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("div", { staticClass: "event-title mt-3" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.venue")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.exceptionalScheme
                    ? _c("b-form-radio-group", {
                        attrs: {
                          id: "checkbox-radio-2",
                          options: _vm.venue2,
                          disabled: "",
                        },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.equipments.venue2,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipments, "venue2", $$v)
                          },
                          expression: "equipments.venue2",
                        },
                      })
                    : _vm.edit
                    ? _c("b-form-radio-group", {
                        attrs: { id: "checkbox-radio-2", options: _vm.venue2 },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.equipments.venue2,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipments, "venue2", $$v)
                          },
                          expression: "equipments.venue2",
                        },
                      })
                    : _c("div", { staticClass: "choice" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.equipments.venue2
                                ? _vm.$t(
                                    `apply.spaps.tab_txt.tab2.venue2_${_vm.equipments.venue2}`
                                  )
                                : "/"
                            ) +
                            "\n          "
                        ),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { md: "6" } }, [
                _vm.equipments.venue2 === 2
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "subtitle mt-3", attrs: { id: "5" } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("apply.spaps.tab_txt.tab2.otherVenue")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.edit
                          ? _c("b-form-input", {
                              attrs: { type: "text" },
                              on: { input: _vm.inputChanged },
                              model: {
                                value: _vm.equipments.otherVenue2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.equipments, "otherVenue2", $$v)
                                },
                                expression: "equipments.otherVenue2",
                              },
                            })
                          : _c("div", { staticClass: "choice" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.equipments.otherVenue2 !== null
                                      ? _vm.equipments.otherVenue2
                                      : "/"
                                  ) +
                                  "\n            "
                              ),
                            ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("div", { staticClass: "event-title mt-3" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.speaker")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.exceptionalScheme
                    ? _c("b-form-radio-group", {
                        attrs: {
                          id: "checkbox-radio-3",
                          options: _vm.speaker,
                          disabled: "",
                        },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.equipments.speaker,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipments, "speaker", $$v)
                          },
                          expression: "equipments.speaker",
                        },
                      })
                    : _vm.edit
                    ? _c("b-form-radio-group", {
                        attrs: { id: "checkbox-radio-3", options: _vm.speaker },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.equipments.speaker,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipments, "speaker", $$v)
                          },
                          expression: "equipments.speaker",
                        },
                      })
                    : _c("div", { staticClass: "choice" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.equipments.speaker
                                ? _vm.$t(
                                    `apply.spaps.tab_txt.tab2.speaker_${_vm.equipments.speaker}`
                                  )
                                : "/"
                            ) +
                            "\n          "
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("div", { staticClass: "event-title mt-3" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("apply.spaps.tab_txt.tab2.mic")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.exceptionalScheme
                    ? _c("b-form-radio-group", {
                        attrs: {
                          id: "checkbox-radio-4",
                          options: _vm.mic,
                          disabled: "",
                        },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.equipments.mic,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipments, "mic", $$v)
                          },
                          expression: "equipments.mic",
                        },
                      })
                    : _vm.edit
                    ? _c("b-form-radio-group", {
                        attrs: { id: "checkbox-radio-4", options: _vm.mic },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.equipments.mic,
                          callback: function ($$v) {
                            _vm.$set(_vm.equipments, "mic", $$v)
                          },
                          expression: "equipments.mic",
                        },
                      })
                    : _c("div", { staticClass: "choice" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.equipments.mic
                                ? _vm.$t(
                                    `apply.spaps.tab_txt.tab2.mic_${_vm.equipments.mic}`
                                  )
                                : "/"
                            ) +
                            "\n          "
                        ),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { md: "6" } }, [
                _vm.equipments.mic === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "subtitle mt-3", attrs: { id: "5" } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("apply.spaps.tab_txt.tab2.micAmount")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.edit
                          ? _c("b-form-input", {
                              attrs: { type: "number" },
                              on: { input: _vm.inputChanged },
                              model: {
                                value: _vm.equipments.micAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.equipments, "micAmount", $$v)
                                },
                                expression: "equipments.micAmount",
                              },
                            })
                          : _c("div", { staticClass: "choice" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.equipments.micAmount !== null
                                      ? _vm.equipments.micAmount
                                      : "/"
                                  ) +
                                  "\n            "
                              ),
                            ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }