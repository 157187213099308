<template>
  <div class="tab-cont">
    <div class="event-desc-cont">
      <div class="row">
        <div class="col">
          <div class="event-field">
            <div class="event-title">{{ $t('apply.event_code') }}</div>
            <div>{{ getEvent.code }}</div>
          </div>
          <div class="event-field">
            <div class="event-title">{{ $t('apply.event_title') }}</div>
            <div v-if="$route.name.includes('application')">
              <nuxt-link
                :to="localePath(`/event/${event.id}`)"
                class="scheme-link"
                ><div v-html="i18nTitle(event)"></div>
              </nuxt-link>
            </div>
            <div v-else v-html="i18nTitle(event)"></div>
          </div>
          <div
            v-if="
              getEvent.contact_id &&
              haveData &&
              propsChoice.result_choice &&
              status !== 0
            "
            class="event-field"
          >
            <div class="event-title">{{ $t('apply.contact') }}</div>
            <div>
              {{ getContactPerson(event.contact_id) }} –
              {{ getContactTel(event.contact_id) }}
            </div>
          </div>
        </div>
        <!-- Cancel application remark and details -->
        <div
          v-if="$route.name.includes('application') && status === 3"
          class="col-md-4 event-cancel"
        >
          <div v-if="!isAdmin" class="event-field">
            <div class="event-title">
              {{ $t('apply.scds.cancel_reason') }}
            </div>
            <div style="word-break: break-word; padding: 5px 0px">
              {{ getCancelReason(propsChoice.data) }}
            </div>
          </div>
          <div v-else-if="isAdmin" class="event-field">
            <div class="event-title">
              {{ $t('apply.scds.cancel_reason') }}
            </div>
            <div style="word-break: break-word; padding: 5px 0px">
              {{ getCancelReason_admin(propsChoice) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        !haveData &&
        event &&
        event.applyRemark &&
        i18nContent(JSON.parse(event.applyRemark)).length
      "
      class="applyRemark"
      v-html="i18nContent(JSON.parse(event.applyRemark))"
    ></div>
    <div v-if="propsChoice.result_choice != 2 || status === 0">
      <div v-if="firstSlot" class="slot-title">
        <span v-if="propsChoice && status !== 0">
          <b-icon
            v-if="propsChoice.result_choice === 1"
            icon="check2-square"
            style="color: #1cbb59; vertical-align: -0.35em"
            font-scale="2.3"
          />
          <b-icon
            v-else-if="propsChoice.result_choice === 2"
            icon="x-square"
            style="color: lightgrey; vertical-align: -0.32em"
            font-scale="1.85"
          />
        </span>
        <div class="slot-ordering">1</div>
        <div
          v-if="propsChoice.result_choice == 1 && status !== 0"
          class="event-title"
        >
          {{ $t('apply.scds.result_choice') }}
        </div>
        <div v-else class="event-title">
          {{ $t('apply.scds.first_choice') }}
        </div>
        <div v-if="cancelledChoice === '1'" style="color: red">
          ({{ $t('apply.scds.cancelled') }})
        </div>
      </div>
      <div class="slot-form">
        <div v-if="!firstSlot && !haveData" class="row">
          <!-- for scds events with no event slot，外借服務需要輸入開始日期&結束日期 -->
          <div class="col-4">
            <div class="event-title">
              {{ $t('apply.scds.tab0.startdate') }}
            </div>
            <b-form-input
              v-if="choice"
              v-model="choice.firstChoiceDate"
              type="date"
              required
              @input="inputChanged"
            />
          </div>
          <div class="col-4">
            <div class="event-title">
              {{ $t('apply.scds.tab0.enddate') }}
            </div>
            <b-form-input
              v-if="choice"
              v-model="choice.secondChoiceDate"
              type="date"
              required
              @input="inputChanged"
            />
          </div>
        </div>
        <div class="row">
          <div
            v-if="getFirstSlot"
            :class="passChoice ? 'col-sm-4' : 'col-sm-6'"
          >
            <div class="event-field">
              <div class="event-title">{{ $t('apply.scds.tab0.date') }}</div>
              <div class="event-input">
                {{ $moment(getFirstSlot.date).format('D.M.Y') }}
              </div>
            </div>
            <div class="event-field">
              <div class="event-title">{{ $t('apply.scds.tab0.time') }}</div>
              <div class="event-input">
                {{ getTime(getFirstSlot) }}
              </div>
            </div>
            <div class="event-field">
              <div class="event-title">{{ $t('apply.scds.tab0.venue') }}</div>
              <div class="event-input">
                {{ getVenue(getFirstSlot.venue) }}
              </div>
            </div>
          </div>

          <div v-else-if="passChoice" class="col-sm-4">
            <!-- for scds events with no event slot，外借服務需要輸入開始日期&結束日期 -->
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.startdate') }}
              </div>
              <div class="event-input">
                {{
                  passChoice.firstChoiceDate
                    ? $moment(passChoice.firstChoiceDate).format('D.M.Y')
                    : ''
                }}
              </div>
            </div>
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.enddate') }}
              </div>
              <div class="event-input">
                {{
                  passChoice.secondChoiceDate
                    ? $moment(passChoice.secondChoiceDate).format('D.M.Y')
                    : ''
                }}
              </div>
            </div>
          </div>

          <div v-if="!haveData" class="col-sm-5">
            <div class="event-title">
              {{ $t('apply.scds.tab0.class') }} <span>*</span> :
            </div>
            <!-- <input type="text" placeholder="（例如：P3, P5-P6）" required /> -->
            <!-- <input
            :value="value"
            @input="$emit('input', $event.target.value)"
          /> -->
            <b-form-checkbox-group
              id="checkbox-group-firstChoiceClass"
              v-model="choice.firstChoiceClass"
              :options="createOption1"
              :state="checkClass1"
            ></b-form-checkbox-group>
            <!-- <b-form-input
            v-if="choice"
            v-model="choice.firstChoiceClass"
            :state="checkClass1"
            :placeholder="'（例如：P3, P5-P6）'"
            type="text"
            required
            @input="inputChanged"
          /> -->
          </div>
          <div v-if="passChoice" class="col-sm-4">
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.class') }}
                <span
                  v-if="
                    !$route.name.includes('shoppingCart') &&
                    !$route.name.includes('application')
                  "
                  >*</span
                >
                :
              </div>
              <div v-if="passChoice.firstChoiceClass" class="event-input">
                {{ formatTargetInArray(passChoice.firstChoiceClass) }}
              </div>
            </div>
          </div>
          <div v-if="passChoice" class="col-sm-4">
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.studentno') }}
                <span
                  v-if="
                    !$route.name.includes('shoppingCart') &&
                    !$route.name.includes('application')
                  "
                  >*</span
                >
                :
              </div>
              <div class="event-input">
                {{ passChoice.firstChoiceStudentNo }}
              </div>
            </div>
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.teacherno') }}
                <span
                  v-if="
                    !$route.name.includes('shoppingCart') &&
                    !$route.name.includes('application')
                  "
                  >*</span
                >
                :
              </div>
              <div class="event-input">
                {{ passChoice.firstChoiceTeacherNo }}
              </div>
            </div>
            <div v-if="event.isFamily" class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.parentno') }} :
              </div>
              <div class="event-input">
                {{ passChoice.firstChoiceParentNo || 0 }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!haveData" class="row">
          <div class="col-sm-4">
            <div class="event-title">
              {{ $t('apply.scds.tab0.studentno') }} <span>*</span> :
            </div>
            <b-form-input
              v-if="choice"
              v-model="choice.firstChoiceStudentNo"
              :state="checkStudent1"
              :placeholder="$t('apply.scds.tab0.studno_placeholder')"
              type="number"
              required
              @input="inputChanged"
            />
          </div>
          <div class="col-sm-4">
            <div class="event-title">
              {{ $t('apply.scds.tab0.teacherno') }} <span>*</span> :
            </div>
            <b-form-input
              v-if="choice"
              v-model="choice.firstChoiceTeacherNo"
              :state="checkTeacher1"
              :placeholder="$t('apply.scds.tab0.teacherno_placeholder')"
              type="number"
              required
              @input="inputChanged"
            />
          </div>
          <div v-if="event.isFamily" class="col-sm-4">
            <div class="event-title">{{ $t('apply.scds.tab0.parentno') }}</div>
            <b-form-input
              v-if="choice"
              v-model="choice.firstChoiceParentNo"
              :state="checkParent1"
              :placeholder="$t('apply.scds.tab0.parentno_placeholder')"
              type="number"
              required
              @input="inputChanged"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <hr style="width: 95%" /> -->
    <div v-if="secondSlot && (propsChoice.result_choice != 1 || status === 0)">
      <div class="slot-title">
        <span v-if="propsChoice && status !== 0">
          <b-icon
            v-if="propsChoice.result_choice === 2"
            icon="check2-square"
            style="color: #1cbb59; vertical-align: -0.35em"
            font-scale="2.3"
          />
          <b-icon
            v-else-if="propsChoice.result_choice === 1"
            icon="x-square"
            style="color: lightgrey; vertical-align: -0.32em"
            font-scale="1.85"
          />
        </span>
        <div class="slot-ordering">2</div>
        <div
          v-if="propsChoice.result_choice == 2 && status !== 0"
          class="event-title"
        >
          {{ $t('apply.scds.result_choice') }}
        </div>
        <div v-else class="event-title">
          {{ $t('apply.scds.sec_choice') }}
        </div>
        <div v-if="cancelledChoice === '2'" style="color: red">
          ({{ $t('apply.scds.cancelled') }})
        </div>
      </div>
      <div class="slot-form">
        <div class="row">
          <div :class="getPassChoice ? 'col-sm-4' : 'col-sm-6'">
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.date') }}
              </div>
              <div v-if="getSecondSlot" class="event-input">
                {{ $moment(getSecondSlot.date).format('D.M.Y') }}
              </div>
            </div>
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.time') }}
              </div>
              <div v-if="getSecondSlot" class="event-input">
                {{ getTime(getSecondSlot) }}
              </div>
            </div>
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.venue') }}
              </div>
              <div v-if="getSecondSlot" class="event-input">
                {{ getVenue(getSecondSlot.venue) }}
              </div>
            </div>
          </div>
          <!-- X :v-model -->
          <div v-if="!haveData" class="col-sm-5">
            <div class="event-title">
              {{ $t('apply.scds.tab0.class') }} <span>*</span> :
            </div>
            <b-form-checkbox-group
              id="checkbox-group-secondChoiceClass"
              v-model="choice.secondChoiceClass"
              :state="checkClass2"
              :options="createOption2"
              name="flavour-2"
            ></b-form-checkbox-group>
          </div>

          <div v-if="passChoice" class="col-sm-4">
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.class') }}
                <span
                  v-if="
                    !$route.name.includes('shoppingCart') &&
                    !$route.name.includes('application')
                  "
                  >*</span
                >
                :
              </div>
              <div v-if="passChoice.secondChoiceClass" class="event-input">
                {{ formatTargetInArray(passChoice.secondChoiceClass) }}
              </div>
            </div>
          </div>
          <div v-if="passChoice" class="col-sm-4">
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.studentno') }}
                <span
                  v-if="
                    !$route.name.includes('shoppingCart') &&
                    !$route.name.includes('application')
                  "
                  >*</span
                >
                :
              </div>
              <div class="event-input">
                {{ passChoice.secondChoiceStudentNo }}
              </div>
            </div>
            <div class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.teacherno') }}
                <span
                  v-if="
                    !$route.name.includes('shoppingCart') &&
                    !$route.name.includes('application')
                  "
                  >*</span
                >
                :
              </div>
              <div class="event-input">
                {{ passChoice.secondChoiceTeacherNo }}
              </div>
            </div>
            <div v-if="event.isFamily" class="event-field">
              <div class="event-title">
                {{ $t('apply.scds.tab0.parentno') }}
              </div>
              <div class="event-input">
                {{ passChoice.secondChoiceParentNo || 0 }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!haveData" class="row">
          <div class="col-sm-4">
            <div class="event-title">
              {{ $t('apply.scds.tab0.studentno') }} <span>*</span> :
            </div>
            <b-form-input
              v-if="choice"
              v-model="choice.secondChoiceStudentNo"
              :state="checkStudent2"
              :placeholder="$t('apply.scds.tab0.teacherno_placeholder')"
              type="number"
              required
              @input="inputChanged"
            />
          </div>
          <div class="col-sm-4">
            <div class="event-title">
              {{ $t('apply.scds.tab0.teacherno') }} <span>*</span> :
            </div>
            <b-form-input
              v-if="choice"
              v-model="choice.secondChoiceTeacherNo"
              :state="checkTeacher2"
              :placeholder="$t('apply.scds.tab0.teacherno_placeholder')"
              type="number"
              required
              @input="inputChanged"
            />
          </div>
          <div v-if="event.isFamily" class="col-sm-4">
            <div class="event-title">
              {{ $t('apply.scds.tab0.parentno') }}
            </div>
            <b-form-input
              v-if="choice"
              v-model="choice.secondChoiceParentNo"
              :state="checkParent2"
              :placeholder="$t('apply.scds.tab0.parentno_placeholder')"
              type="number"
              required
              @input="inputChanged"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="parentSchemeId === 4"></div>
  </div>
</template>
<script>
export default {
  props: {
    event: { type: Object, required: true },
    venues: { type: Array, required: true },
    firstSlot: { type: Object },
    secondSlot: { type: Object },
    passChoice: Object, // non-editable
    editChoice: Object, // editable
    status: Number,
    parentSchemeId: Number,
    cancelledChoice: String,
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}contacts/public/contact`
      )
      this.contacts = result.data.data
    } catch (err) {}
  },
  data() {
    return {
      // haveData: false,
      // contacts: [],
      loading1: true,
      something: 'something',
      choice: {
        firstChoiceStudentNo: '',
        firstChoiceTeacherNo: '',
        firstChoiceParentNo: '',
        firstChoiceClass: [],
        secondChoiceStudentNo: '',
        secondChoiceTeacherNo: '',
        secondChoiceParentNo: '',
        secondChoiceClass: [],
      },
    }
  },
  computed: {
    getEvent() {
      return this.event
    },
    getVenues() {
      return this.venues
    },
    getFirstSlot() {
      return this.firstSlot
    },
    getSecondSlot() {
      return this.secondSlot
    },
    getPassChoice() {
      return this.passChoice
    },
    getEditChoice() {
      return this.editChoice
    },
    isAdmin() {
      return this.$store.state.localStorage.auth.admin
    },
    propsChoice() {
      return this.getPassChoice ? this.getPassChoice : this.getEditChoice
    },
    getFirstChoiceClass() {
      return JSON.parse(this.choice.firstChoiceClass)
    },
    getSecondChoiceClass() {
      return JSON.parse(this.choice.secondChoiceClass)
    },
    checkClass1() {
      if (!this.choice.firstChoiceClass) return false
      return this.choice.firstChoiceClass.length > 0
    },
    checkStudent1() {
      const d = this.choice.firstChoiceStudentNo || -1
      return d > -1 && !d.toString().includes('.')
    },
    checkTeacher1() {
      const d = this.choice.firstChoiceTeacherNo || -1
      return d > -1 && !d.toString().includes('.')
    },
    checkParent1() {
      const d = this.choice.firstChoiceParentNo || 0
      return !d.toString().includes('.') && d > -1
    },
    checkClass2() {
      if (!this.choice.secondChoiceClass) return false
      return this.choice.secondChoiceClass.length > 0
    },
    checkStudent2() {
      const d = this.choice.secondChoiceStudentNo || -1
      return d > -1 && !d.toString().includes('.')
    },
    checkTeacher2() {
      const d = this.choice.secondChoiceTeacherNo || -1
      return d > -1 && !d.toString().includes('.')
    },
    checkParent2() {
      const d = this.choice.secondChoiceParentNo || 0
      return !d.toString().includes('.') && d > -1
    },
    haveData() {
      if (this.loading1) return true
      return this.passChoice ? !!this.passChoice.firstChoiceClass : false
    },
    createOption1() {
      let targetArray = this.getFirstSlot
        ? this.getFirstSlot.target.split(',')
        : 'k1,k2,k3,p1,p2,p3,p4,p5,p6,f1,f2,f3,f4,f5,f6,t1,t2,t3'.split(',')
      const arrK = targetArray
        .filter((d) => d.includes('k'))
        .sort((a, b) => a.localeCompare(b))
      const arrP = targetArray
        .filter((d) => d.includes('p'))
        .sort((a, b) => a.localeCompare(b))
      const arrF = targetArray
        .filter((d) => d.includes('f'))
        .sort((a, b) => a.localeCompare(b))
      const arrT = targetArray
        .filter((d) => d.includes('t'))
        .sort((a, b) => a.localeCompare(b))
      targetArray = arrK.concat(arrP).concat(arrF).concat(arrT)

      const options = []
      targetArray.map((t) => {
        if (t[0] === 'k') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) - 3),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 'p') {
          const item = {
            text: this.intToGrade(parseInt(t[1])),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 'f') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) + 6),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 't') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) + 12),
            value: t,
          }
          options.push(item)
        }
      })
      return options
    },
    createOption2() {
      let targetArray2 = this.secondSlot.target.split(',')
      const arrK = targetArray2
        .filter((d) => d.includes('k'))
        .sort((a, b) => a.localeCompare(b))
      const arrP = targetArray2
        .filter((d) => d.includes('p'))
        .sort((a, b) => a.localeCompare(b))
      const arrF = targetArray2
        .filter((d) => d.includes('f'))
        .sort((a, b) => a.localeCompare(b))
      const arrT = targetArray2
        .filter((d) => d.includes('t'))
        .sort((a, b) => a.localeCompare(b))
      targetArray2 = arrK.concat(arrP).concat(arrF).concat(arrT)

      const options = []
      targetArray2.map((t) => {
        if (t[0] === 'k') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) - 3),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 'p') {
          const item = {
            text: this.intToGrade(parseInt(t[1])),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 'f') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) + 6),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 't') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) + 12),
            value: t,
          }
          options.push(item)
        }
      })
      return options
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => (this.loading1 = false), 500)
    })
    this.choice = this.getEditChoice ? this.getEditChoice : this.choice
  },
  methods: {
    inputChanged() {
      this.$emit('input', this.choice)
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    getTime(slot) {
      const startTime = slot.startTime.split(':')
      const endTime = slot.endTime.split(':')
      return (
        startTime[0] +
        ':' +
        startTime[1] +
        ' - ' +
        endTime[0] +
        ':' +
        endTime[1]
      )
    },
    getDate(timeString) {
      return timeString.split('T')[0]
    },
    getDateTime(timeString) {
      return timeString.replace('T', ' ').split('.')[0]
    },
    getVenue(venue) {
      if (this.getVenues) {
        return this.venues.find((v) => v.code === venue)
          ? this.i18nName(this.getVenues.find((v) => v.code === venue))
          : ''
      }
    },
    getContactPerson(contactid) {
      const id = parseInt(contactid)
      if (this.contacts) {
        return this.contacts.find((c) => c.id === id)
          ? this.i18nName(this.contacts.find((c) => c.id === id))
          : ''
      }
    },
    getContactTel(contactid) {
      const id = parseInt(contactid)
      if (this.contacts) {
        return this.contacts.find((c) => c.id === id)
          ? this.contacts.find((c) => c.id === id).tel
          : ''
      }
    },
    getCancelReason(data) {
      try {
        // console.log(data)
        const temp = this.$_.attempt(JSON.parse.bind(null, data))
        // const temp = JSON.parse(data)
        const index = temp.cancel.state
        console.log('index: ', index)
        // console.log(index)
        if (index === 1) {
          switch (this.$i18n.locale) {
            case 'en':
              return 'Cancelled by School'
            case 'tc':
              return '學校取消'
            case 'sc':
              return '学校取消'
            default:
              return '學校取消'
          }
        } else if (index === 2) {
          switch (this.$i18n.locale) {
            case 'en':
              return 'Cancelled by School'
            case 'tc':
              return '學校取消'
            case 'sc':
              return '学校取消'
            default:
              return '學校取消'
          }
        } else if (index === 3) {
          switch (this.$i18n.locale) {
            case 'en':
              return 'Cancelled by Organizer'
            case 'tc':
              return '主辦單位取消'
            case 'sc':
              return '主办单位取消'
            default:
              return '主辦單位取消'
          }
        } else if (index === 4) {
          switch (this.$i18n.locale) {
            case 'en':
              return 'Other Reasons'
            case 'tc':
              return '其他突發原因'
            case 'sc':
              return '其他突发原因'
            default:
              return '其他突發原因'
          }
        } else if (index === 5) {
          switch (this.$i18n.locale) {
            case 'en':
              return 'Cancelled within the application period'
            case 'tc':
              return '於申請期內取消'
            case 'sc':
              return '于申请期内取消'
            default:
              return '於申請期內取消'
          }
        } else {
          switch (this.$i18n.locale) {
            case 'en':
              return 'Cancelled within the application period'
            case 'tc':
              return '於申請期內取消'
            case 'sc':
              return '于申请期内取消'
            default:
              return '於申請期內取消'
          }
        }
      } catch (error) {
        switch (this.$i18n.locale) {
          case 'en':
            return 'Cancelled within the application period'
          case 'tc':
            return '於申請期內取消'
          case 'sc':
            return '于申请期内取消'
          default:
            return '於申請期內取消'
        }
      }
    },
    getCancelReason_admin(app) {
      try {
        const index = app.data.cancel.state
        if (index === 1) {
          return '學校取消 (及早通知)'
        } else if (index === 2) {
          return '學校取消 (遲通知)'
        } else if (index === 3) {
          return '主辦單位取消'
        } else if (index === 4) {
          return '其他突發原因'
        } else if (index === 5) {
          return '於申請期內取消'
        } else {
          return '於申請期內取消'
        }
      } catch (error) {
        return '於申請期內取消'
      }
    },
    getDeductMark(data) {
      try {
        const temp = this.$_.attempt(JSON.parse.bind(null, data))
        const score = temp.cancel.score
        return score
      } catch (error) {
        console.log(error)
      }
    },
    getCancelRemark(data) {
      try {
        const temp = this.$_.attempt(JSON.parse.bind(null, data))
        const reason = temp.cancel.reason
        return reason
      } catch (error) {
        console.log(error)
      }
    },
    changeRequest() {
      window.open(
        'https://www.abo.gov.hk/img/upload/24862dc1-2558-40c9-be21-b2b13eb21bdd_SCDS_Change_Form.pdf'
      )
    },
    formatTarget(t) {
      // k1: -2, k2: -1, k3: 0,
      // p1: 1, p2: 2, p3: 3, p4: 4, p5: 5, p6: 6
      // f1: 7, f2: 8, f3: 9, f4: 10, f5: 11, f6: 12
      let result = ''
      const targetArray = t.split(',')
      result = this.formatTargetInArray(targetArray)
      return result
    },
    formatTargetInArray(targetArray) {
      if (!targetArray.length) {
        return '/'
      }
      let result = ''
      let targetArrayInInt = []
      targetArray =
        typeof targetArray === 'string' ? JSON.parse(targetArray) : targetArray

      targetArray.map((t) => {
        if (t[0] === 'k') targetArrayInInt.push(parseInt(t[1]) - 3)
        if (t[0] === 'p') targetArrayInInt.push(parseInt(t[1]))
        if (t[0] === 'f') targetArrayInInt.push(parseInt(t[1]) + 6)
      })
      targetArrayInInt = targetArrayInInt.sort((a, b) => a - b)

      if (targetArrayInInt.length === 1)
        return this.intToGrade(targetArrayInInt[0])

      const check =
        targetArrayInInt[0] + targetArrayInInt.length - 1 ===
        targetArrayInInt[targetArrayInInt.length - 1]
      if (check) {
        result = this.intToGrade(targetArrayInInt[0])
          .concat(this.$t('event.to')) // .concat(' - ')
          .concat(
            this.intToGrade(targetArrayInInt[targetArrayInInt.length - 1])
          )
      } else {
        targetArrayInInt.forEach((t, index) => {
          result = result.concat(this.intToGrade(t))
          result =
            index === targetArrayInInt.length - 1 ? result : result.concat(', ')
        })
      }
      return result
    },
    intToGrade(i) {
      let result = ''
      switch (this.$i18n.locale) {
        case 'en':
          if (i < 1) {
            result = 'K.'.concat((i + 3).toString())
          } else if (i < 7) {
            result = 'P.'.concat(i.toString())
          } else if (i < 13) {
            result = 'S.'.concat((i - 6).toString())
          } else if (i === 13) {
            result = 'Kindergarten Teachers'
          } else if (i === 14) {
            result = 'Primary Teachers'
          } else if (i === 15) {
            result = 'Secondary Teachers'
          }
          break
        case 'tc':
          if (i === -2) {
            result = '幼兒班'
          } else if (i === -1) {
            result = '低班'
          } else if (i === 0) {
            result = '高班'
          } else if (i < 7) {
            result = '小'.concat(this.intToChi(i))
          } else if (i < 13) {
            result = '中'.concat(this.intToChi(i - 6))
          } else if (i === 13) {
            result = '幼稚園老師'
          } else if (i === 14) {
            result = '小學老師'
          } else if (i === 15) {
            result = '中學老師'
          }
          break
        case 'sc':
          if (i === -2) {
            result = '幼儿班'
          } else if (i === -1) {
            result = '低班'
          } else if (i === 0) {
            result = '高班'
          } else if (i < 7) {
            result = '小'.concat(this.intToChi(i))
          } else if (i < 13) {
            result = '中'.concat(this.intToChi(i - 6))
          } else if (i === 13) {
            result = '幼稚园老师'
          } else if (i === 14) {
            result = '小学老师'
          } else if (i === 15) {
            result = '中学老师'
          }
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.event-input {
  background-color: whitesmoke;
  padding: 5px;
  margin: 5px;
  width: 100%;
}
.applyRemark {
  margin-top: 30px;
  text-align: left;
  padding: 15px;
  background-color: whitesmoke;
}
.is-valid {
  border-color: #28a745;
}
.is-invalid {
  border-color: #dc3545;
}
.tab-cont {
  // padding: 0 30px;
}
table {
  width: 100%;
  text-align: left;
}
.event-desc-cont {
  display: block;
  text-align: left;
}
.event-cancel {
  border: 2px solid #1fbcb4;
  height: fit-content;
  margin-right: 15px;
}
.event-field {
  display: flex;
  align-items: baseline;
}
.event-field > * {
  display: inline-block;
}
.event-title {
  color: #1fbcb4;
  margin-right: 20px;
  min-width: 85px;
  margin-bottom: 0.5em;
}
.event-title-wide {
  color: #1fbcb4;
  margin-right: 20px;
  min-width: 120px;
  margin-bottom: 0.5em;
}
span {
  color: #d14814;
}
.slot-title {
  text-align: left;
  margin-top: 30px;
}
.slot-title > * {
  display: inline-block;
  font-size: 120%;
}
.slot-title > .event-title {
}
.slot-ordering {
  text-align: center;
  height: 32px;
  width: 32px;
  background: #1fbcb4;
  color: #fff;
  border-radius: 4px;
  line-height: 30px;
}
.slot-form {
  text-align: left;
  page-break-inside: avoid;
}
.slot-form > .row {
  margin-top: 15px;
  margin-bottom: 15px;
}
.slot-form > .row input {
  width: 90%;
  height: 50px;
  border-radius: 4px;
  // border-color: #d5d5d5;
  border-width: 1px;
  border-style: solid;
  // color: #d5d5d5;
}
.slot-form > .row input::placeholder {
  color: #d5d5d5;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .slot-form > .row input {
    width: 100%;
  }

  .event-field {
    flex-flow: column;
  }

  .event-field .event-title {
    margin-bottom: 0;
    margin-top: 5px;
  }
}
</style>
