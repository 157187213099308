<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="6">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <p>地點代號</p>
          <b-form-group id="code" label-for="code">
            <b-form-input
              id="code"
              v-model="code"
              type="text"
              required
              :readonly="action === 'edit' ? true : false"
              :state="validation()"
              trim
            ></b-form-input>
          </b-form-group>
          <small>請使用統一格式</small><br />
          <small>e.g. 香港大會堂 地點代號：HKCH</small><br />
          <small>e.g. 香港大會堂劇院 地點代號：TH/HKCH</small>
          <hr />

          <p>場館代號</p>
          <b-form-group id="code" label-for="code">
            <b-form-input
              id="code"
              v-model="displayCode"
              type="text"
              required
              trim
            ></b-form-input>
          </b-form-group>
          <small>
            場館代號只顯示於節目表
            <a
              target="_blank"
              href="https://www.abo.gov.hk/tc/eventCategory/2/2020/09"
            >
              Example
            </a>
          </small>
          <br />
          <small>e.g. 香港大會堂 場館代號：HKCH</small><br />
          <small>e.g. 香港大會堂劇院 場館代號：HKCH</small>
          <hr />

          <p class="mt-3">地點名稱（英）</p>
          <b-form-group id="name_en" label-for="name_en">
            <b-form-input
              id="name_en"
              v-model="name_en"
              type="text"
              trim
            ></b-form-input>
          </b-form-group>
          <p>地點名稱（繁）</p>
          <b-form-group id="name_tc" label-for="name_tc">
            <b-form-input
              id="name_tc"
              v-model="name_tc"
              type="text"
              trim
            ></b-form-input>
          </b-form-group>
          <p>地點名稱（簡）</p>
          <b-form-group id="name_sc" label-for="name_sc">
            <b-form-input
              id="name_sc"
              v-model="name_sc"
              type="text"
              trim
            ></b-form-input>
          </b-form-group>
          <br />

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      if (this.action === 'edit' || this.action === 'copy') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/venue/id/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.code = data.code
        this.name_en = data.name_en
        this.name_tc = data.name_tc
        this.name_sc = data.name_sc
        this.displayCode = data.displayCode
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      code: null,
      displayCode: null,
      name_en: null,
      name_tc: null,
      name_sc: null,
      error: null,
    }
  },
  computed: {
    getTc() {
      const tc = []
      tc.push(this.name_tc)
      return tc
    },
  },
  methods: {
    validation() {
      return (
        /^([A-Z0-9]+)+$/.test(this.code) ||
        /^([A-Z0-9]+)\/([A-Z0-9]+)+$/.test(this.code)
      )
    },
    setSc(data) {
      this.name_sc = data[0]
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async callapi(url, action) {
      const result = await this.$axios.$post(`${url}events/venue/${action}`, {
        id: this.id,
        code: this.code,
        displayCode: this.displayCode,
        name_en: this.name_en,
        name_tc: this.name_tc,
        name_sc: this.name_sc,
      })
      if (result.error !== undefined) throw new Error(result.error)
    },
    async submit() {
      try {
        if (this.action === 'edit') {
          this.callapi(process.env.previewApiUrl, 'update')
          this.callapi(process.env.cloudApiUrl, 'update')
        } else {
          this.callapi(process.env.previewApiUrl, 'add')
          this.callapi(process.env.cloudApiUrl, 'add')
        }
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/venue'))
        }
      } catch (err) {
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
