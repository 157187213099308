var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "10" } }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "shadow my-3 mse-c1" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-success" },
                          on: { click: _vm.addTab },
                        },
                        [_vm._v(_vm._s(_vm.$t("scheme.add_tab")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "mt-3" } },
                        _vm._l(_vm.applyNotes, function (tab, counter) {
                          return _c(
                            "b-tab",
                            {
                              key: counter,
                              staticClass: "tab",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.i18nTitle(tab)) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticStyle: {
                                              "border-radius": "500%",
                                            },
                                            attrs: {
                                              size: "sm",
                                              variant: "danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.deleteTab(counter)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    ×\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { id: "tabs", "label-for": "tabs" } },
                                [
                                  _c(
                                    "b-tabs",
                                    { attrs: { "content-class": "mt-3" } },
                                    [
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.en") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.en"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholder.title.en"
                                              ),
                                              type: "text",
                                              trim: "",
                                            },
                                            model: {
                                              value: tab.title_en,
                                              callback: function ($$v) {
                                                _vm.$set(tab, "title_en", $$v)
                                              },
                                              expression: "tab.title_en",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.en"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_en_" + counter,
                                              value: tab.content_en,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.tc") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.tc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholder.title.tc"
                                              ),
                                              type: "text",
                                              trim: "",
                                            },
                                            model: {
                                              value: tab.title_tc,
                                              callback: function ($$v) {
                                                _vm.$set(tab, "title_tc", $$v)
                                              },
                                              expression: "tab.title_tc",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.tc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_tc_" + counter,
                                              value: tab.content_tc,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-tab",
                                        { attrs: { title: _vm.$t("g.sc") } },
                                        [
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.title.sc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "placeholder.title.sc"
                                              ),
                                              type: "text",
                                              trim: "",
                                            },
                                            model: {
                                              value: tab.title_sc,
                                              callback: function ($$v) {
                                                _vm.$set(tab, "title_sc", $$v)
                                              },
                                              expression: "tab.title_sc",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("p", { staticClass: "label" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("tab.content.sc"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tinymceEditor", {
                                            attrs: {
                                              id: "tab_editor_sc_" + counter,
                                              value: tab.content_sc,
                                            },
                                            on: {
                                              writeContent: _vm.getTabContent,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                          disabled: _vm.btn_disabled,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")) + "\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }