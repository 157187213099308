var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalCalendar",
      attrs: {
        id: "modal-calendar",
        "content-class": "modal-cal-content",
        centered: "",
        "hide-header": "",
        "hide-footer": "",
        scrollable: "",
        size: "lg",
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticStyle: { "padding-right": "0" }, attrs: { cols: "5" } },
            [
              _c("div", { staticClass: "calendar_title border-bottom" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("home.calendar")) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("v-calendar", {
                staticClass: "calendar",
                attrs: {
                  mode: "date",
                  "title-position": "left",
                  attributes: _vm.attrs,
                  locale: {
                    id: _vm.i18nCalendar(),
                    firstDayOfWeek: 0,
                    // masks: { title: $moment(datePicked.date).format('yyyy年M月D日') },
                  },
                  "first-day-of-week": 0,
                  "min-date": new Date(),
                  "is-expanded": "",
                  "is-inline": true,
                },
                on: { dayclick: (day) => _vm.test(day) },
              }),
              _vm._v(" "),
              _vm.events
                ? _c(
                    "div",
                    { style: _vm.eventListStyles },
                    _vm._l(_vm.filteredEvents, function (event, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            attrs: { role: "button" },
                            on: {
                              click: () => {
                                // eventSelected = event
                                _vm.$store.commit(
                                  "tempStorage/storeEventSelected",
                                  event
                                )
                              },
                            },
                          },
                          [
                            _c("EventCardForCalendar", {
                              attrs: {
                                event: event,
                                index: index,
                                bookmarks: _vm.bookmarks,
                                schemes: _vm.schemes,
                                "result-slot":
                                  _vm.getSelectedDateEventSlotId(event),
                                "is-applied": _vm.isAppliedEvent(event.code),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        index !== _vm.filteredEvents.length - 1
                          ? _c("div", {
                              staticStyle: { border: "1px solid whitesmoke" },
                            })
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticStyle: {
                "border-left": "1px solid whitesmoke",
                "padding-left": "30px",
                "padding-right": "30px",
              },
              attrs: { cols: "7" },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "calendar-event-close",
                  attrs: { size: "sm" },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.hide("modal-calendar")
                    },
                  },
                },
                [_vm._v("×")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "icons-bar" }, [
                _vm.getScheme
                  ? _c("div", [
                      _vm.eventSelected.isFamily === 1
                        ? _c("img", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: _vm.getScheme.parentIcon
                                  ? _vm.i18nContent(
                                      JSON.parse(_vm.getScheme.parentIcon)
                                        .popover
                                    )
                                  : "",
                                expression:
                                  "\n              getScheme.parentIcon\n                ? i18nContent(JSON.parse(getScheme.parentIcon).popover)\n                : ''\n            ",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "event-icon",
                            attrs: {
                              src: _vm.getScheme.parentIcon
                                ? JSON.parse(_vm.getScheme.parentIcon).img
                                : "",
                              alt: "icon event",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.eventSelected.isStar === 1
                        ? _c("img", {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: _vm.getScheme.starIcon
                                  ? _vm.i18nContent(
                                      JSON.parse(_vm.getScheme.starIcon).popover
                                    )
                                  : "",
                                expression:
                                  "\n              getScheme.starIcon\n                ? i18nContent(JSON.parse(getScheme.starIcon).popover)\n                : ''\n            ",
                                modifiers: { hover: true },
                              },
                            ],
                            staticClass: "event-icon",
                            attrs: {
                              src: _vm.getScheme.starIcon
                                ? JSON.parse(_vm.getScheme.starIcon).img
                                : "",
                              alt: "icon event",
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.subevents.length
                  ? _c(
                      "div",
                      { staticClass: "target" },
                      _vm._l(
                        _vm.getSubEventTargetSchool(),
                        function (target, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "card-target" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(target) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm.eventSelected && _vm.eventSelected.EventSlot
                  ? _c(
                      "div",
                      { staticClass: "target" },
                      _vm._l(
                        _vm.getTargetSchool(_vm.eventSelected),
                        function (target, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "card-target" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(target) +
                                  "\n          "
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.eventSelected
                ? _c(
                    "h6",
                    {
                      staticClass: "card-scheme-title",
                      style: {
                        color: _vm.getColor(
                          _vm.getSchemesSlug(_vm.eventSelected.scheme)
                        ),
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.getScheme ? _vm.i18nTitle(_vm.getScheme) : ""
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _vm.parentEvent.code
                    ? _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.parentEvent.code) +
                            "\n          " +
                            _vm._s(
                              _vm.parentEvent
                                ? _vm.i18nTitle(_vm.parentEvent)
                                : ""
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.parentEvent.code
                    ? _c("b-icon", { attrs: { icon: "arrow-return-right" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.eventSelected
                    ? _c("span", { staticClass: "mb-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.eventSelected.code) +
                            "\n          "
                        ),
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.i18nTitle(_vm.eventSelected)),
                          },
                        }),
                        _vm._v(" "),
                        _vm.eventSelected.statusTxt &&
                        _vm.i18nContent(
                          JSON.parse(_vm.eventSelected.statusTxt)
                        ) !== ""
                          ? _c("span", {
                              staticStyle: { "white-space": "nowrap" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.i18nContent(
                                    JSON.parse(_vm.eventSelected.statusTxt)
                                  )
                                ),
                              },
                            })
                          : _vm.getStatus === 1
                          ? _c("span", { staticClass: "status_completed" }, [
                              _vm._v(
                                "\n            (" +
                                  _vm._s(_vm.$t("event.statusList.completed")) +
                                  ")\n          "
                              ),
                            ])
                          : _vm.getStatus === 2
                          ? _c("span", { staticClass: "status_cancelled" }, [
                              _vm._v(
                                "\n            (" +
                                  _vm._s(_vm.$t("event.statusList.cancelled")) +
                                  ")\n          "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.eventSelected && _vm.eventSelected.img
                ? _c("img", {
                    staticClass: "card-img",
                    attrs: {
                      src: _vm.getImg(_vm.eventSelected),
                      alt: "card image",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "85%" } },
                [
                  _vm.eventSelected && _vm.eventSelected.EventSlot
                    ? _c("event-table", {
                        attrs: {
                          event: _vm.eventSelected,
                          "show-apply-btn": false,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.eventSelected && _vm.eventSelected.EventSlot
                ? _c("div", { staticClass: "calendar_event" }, [
                    _c(
                      "div",
                      { staticClass: "event-btn" },
                      [
                        !_vm.eventSelected.parent
                          ? _c(
                              "nuxt-link",
                              {
                                staticClass: "event_apply_links",
                                attrs: {
                                  to: _vm.localePath(
                                    `/event/${_vm.eventSelected.id}`
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("home.more")))]
                            )
                          : _c(
                              "nuxt-link",
                              {
                                staticClass: "event_apply_links",
                                attrs: {
                                  to: _vm.localePath(
                                    `/event/${_vm.parentEvent.id}`
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("home.more")))]
                            ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.eventSelected && _vm.eventSelected.EventSlot.length
                      ? _c("div", { staticClass: "event-btn" }, [
                          !_vm.isBookmarked
                            ? _c(
                                "a",
                                {
                                  staticClass: "event_apply_links",
                                  on: {
                                    click: () => {
                                      if (_vm.loggedIn) {
                                        _vm.bookmark(
                                          _vm.eventSelected.parent
                                            ? _vm.parentEvent.id
                                            : _vm.eventSelected.id
                                        )
                                      } else
                                        _vm.$router.push(
                                          _vm.localePath("/login")
                                        )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("home.addbkmark")) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "event_apply_links",
                                  staticStyle: {
                                    background: "whitesmoke",
                                    color: "#999999",
                                    border: "1px solid #999999",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.bookmark(
                                        _vm.eventSelected.parent
                                          ? _vm.parentEvent.id
                                          : _vm.eventSelected.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("home.addedbkmark")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }