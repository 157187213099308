<template>
  <div class="noti-card" :class="handled === 0 ? 'not-read' : ''">
    <div role="button" @click="readNotification(id)">
      <b-row>
        <b-col cols="2" class="noti-icon-container">
          <div class="noti-heading">
            <b-icon :icon="icon" class="noti-icon rounded-circle"></b-icon>
          </div>
        </b-col>
        <b-col cols="9" class="main-content">
          <div class="noti-card-title">{{ cardTitle }}</div>
          <div class="noti-content">
            <slot>Notification Card Content</slot>
            <div class="noti-datetime">{{ datetime }}</div>
          </div>
        </b-col>
        <b-col v-if="handled === 0" cols="1" class="noti-not-read-container">
          <b-icon
            icon="circle-fill"
            variant="primary"
            font-scale="0.5"
            class="noti-not-read"
          ></b-icon>
        </b-col>
      </b-row>
      <hr />
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotificationCard',
  props: {
    id: {
      type: Number,
      required: true,
    },
    cardTitle: {
      type: String,
      default: 'Notification Card title',
    },
    handled: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#000000',
    },
    datetime: {
      type: String,
      default: 'D.M.YYYY HH:mm',
    },
  },
  methods: {
    getLink(eventId, schemeId, type, text) {
      return `<a href="/event/${eventId}/scheme/${schemeId}/${type}">${text}</a>`
    },
    async readNotification(id) {
      try {
        await this.$axios.$post(`${process.env.cloudApiUrl}notification/`, {
          id,
        })
        this.handled = 1
      } catch (err) {
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style scoped>
.noti-heading {
  text-align: left;
  width: 93%;
}
.noti-card {
  margin-bottom: 0px;
  padding: 10px 0px 0px 0px;
  background-color: white;
}

.noti-card hr {
  margin: 0px 0px;
}
.noti-card.not-read {
  background-color: aliceblue;
}
.noti-card-title {
  color: v-bind(color);
  text-align: left;
}
.noti-content {
  padding: 10px 0px;
  text-align: left;
}
.noti-icon-container {
  padding-right: 0px;
}
.noti-icon {
  background-color: v-bind(color);
  padding: 5px;
  font-size: 200%;
}
.main-content {
  padding: 0;
}
.noti-not-read-container {
  padding-left: 0px;
}
.noti-not-read {
  vertical-align: middle;
  text-align: right;
}
.noti-datetime {
  padding-top: 5px;
  font-size: 85%;
  color: #7e7e7e;
}
</style>
