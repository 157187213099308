var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.event.EventSlot
      ? _c("div", { staticClass: "event_detail" }, [
          _vm.isEdit
            ? _c("ul", { staticClass: "nav nav-tabs" }, [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: _vm.lang === 0 ? "active" : "",
                      staticStyle: { cursor: "pointer" },
                      attrs: { "aria-setsize": "3" },
                      on: {
                        click: function ($event) {
                          return _vm.changeLang(0)
                        },
                      },
                    },
                    [_vm._v("內容(英)")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: _vm.lang === 1 ? "active" : "",
                      staticStyle: { cursor: "pointer" },
                      attrs: { "aria-setsize": "3" },
                      on: {
                        click: function ($event) {
                          return _vm.changeLang(1)
                        },
                      },
                    },
                    [_vm._v("內容(繁)")]
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: _vm.lang === 2 ? "active" : "",
                      staticStyle: { cursor: "pointer" },
                      attrs: { "aria-setsize": "3" },
                      on: {
                        click: function ($event) {
                          return _vm.changeLang(2)
                        },
                      },
                    },
                    [_vm._v("內容(簡)")]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.event.EventSlot.length
            ? _c("table", { staticStyle: { "font-size": "0.9em" } }, [
                _c("tbody", [
                  _vm.singleVenue
                    ? _c("tr", [
                        _c("th", [_vm._v("日期：")]),
                        _vm._v(" "),
                        _vm.date
                          ? _c(
                              "td",
                              { staticStyle: { position: "relative" } },
                              [
                                _c("table", [
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.date, function (d, key, index) {
                                      return _c(
                                        "tr",
                                        { key: key, staticClass: "td-time" },
                                        [
                                          _c(
                                            "td",
                                            [
                                              _vm._l(d, function (a, i) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: i,
                                                    staticClass: "td-time",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm
                                                            .$moment(a.date)
                                                            .format("D.M")
                                                        )
                                                    ),
                                                    i !== d.length - 1
                                                      ? _c("span", [
                                                          _vm
                                                            .$moment(a.date)
                                                            .format("Y") !==
                                                          _vm
                                                            .$moment(
                                                              d[i + 1].date
                                                            )
                                                            .format("Y")
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "." +
                                                                    _vm._s(
                                                                      _vm
                                                                        .$moment(
                                                                          a.date
                                                                        )
                                                                        .format(
                                                                          "Y"
                                                                        )
                                                                    )
                                                                ),
                                                                _c("br"),
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(" | "),
                                                              ]),
                                                        ])
                                                      : _vm._e(),
                                                    !d[i + 1]
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "." +
                                                              _vm._s(
                                                                _vm
                                                                  .$moment(
                                                                    a.date
                                                                  )
                                                                  .format("Y")
                                                              )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }),
                                              _vm._v(
                                                "\n                    (" +
                                                  _vm._s(
                                                    _vm.getWeekly(d[0].weekday)
                                                  ) +
                                                  ") \n\n                    "
                                              ),
                                              JSON.parse(_vm.event.remark)
                                                ? _c("span", [
                                                    d.length > 6 &&
                                                    JSON.parse(_vm.event.remark)
                                                      .date[index]
                                                      ? // Object.values(date)[index + 1].length > 6)
                                                        _c("span", [_c("br")])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    JSON.parse(_vm.event.remark)
                                                      .date[index] &&
                                                    JSON.parse(_vm.event.remark)
                                                      .date[index].text.tc !==
                                                      ""
                                                      ? _c("span", {
                                                          class:
                                                            JSON.parse(
                                                              _vm.event.remark
                                                            ).date[index]
                                                              .color == "red"
                                                              ? "red"
                                                              : "",
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              JSON.parse(
                                                                _vm.event.remark
                                                              ).date[index].text
                                                                .tc
                                                            ),
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              index + 1 !==
                                                Object.keys(_vm.date).length &&
                                              (d.length > 6 ||
                                                Object.keys(_vm.date).map(
                                                  (e) => _vm.date[e]
                                                )[index + 1].length > 6)
                                                ? // Object.values(date)[index + 1].length > 6)
                                                  _c("span", [_c("br")])
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.singleVenue
                    ? _c("tr", [
                        _c("th", [_vm._v("地點：")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("table", [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getVenue(_vm.event.EventSlot[0].venue)
                                    ) + " "
                                  ),
                                ]),
                                _vm._v(" "),
                                JSON.parse(_vm.event.remark)
                                  ? _c("td", [
                                      JSON.parse(_vm.event.remark).venue[0]
                                        ? _c("span", {
                                            class:
                                              JSON.parse(_vm.event.remark)
                                                .venue[0].color == "red"
                                                ? "red"
                                                : "",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                JSON.parse(_vm.event.remark)
                                                  .venue[0].text.tc
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                          ]),
                        ]),
                      ])
                    : _c("tr", [
                        _c("th", [_vm._v("日期及地點：")]),
                        _vm._v(" "),
                        _c("td", [
                          _c("table", [
                            _c(
                              "tbody",
                              _vm._l(_vm.venueDates, function (vd, i) {
                                return _c("tr", { key: i }, [
                                  _c("td", [_vm._v(_vm._s(vd.date) + " ")]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(vd.day) + " ")]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticStyle: { width: "110px" } },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getVenue(vd.venue)) + " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  JSON.parse(_vm.event.remark)
                                    ? _c("td", [
                                        JSON.parse(_vm.event.remark).dnv[i]
                                          ? _c("span", {
                                              class:
                                                JSON.parse(_vm.event.remark)
                                                  .dnv[i].color == "red"
                                                  ? "red"
                                                  : "",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  JSON.parse(_vm.event.remark)
                                                    .dnv[i].text.tc
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("時間：")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("table", [
                        _c(
                          "tbody",
                          _vm._l(_vm.time, function (t, i) {
                            return _c(
                              "tr",
                              { key: i, staticClass: "td-time" },
                              [
                                _c("td", [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(t.startTime.slice(0, 5)) +
                                      " -\n                    " +
                                      _vm._s(t.endTime.slice(0, 5)) +
                                      " \n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                JSON.parse(_vm.event.remark)
                                  ? _c("td", [
                                      JSON.parse(_vm.event.remark).time[i]
                                        ? _c("span", {
                                            class:
                                              JSON.parse(_vm.event.remark).time[
                                                i
                                              ].color == "red"
                                                ? "red"
                                                : "",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                JSON.parse(_vm.event.remark)
                                                  .time[i].text.tc
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("需時：")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("table", [
                        _c(
                          "tbody",
                          _vm._l(_vm.getDuration(), function (d, i) {
                            return _c(
                              "tr",
                              { key: i, staticClass: "td-time" },
                              [
                                _c("td", [
                                  _vm._v(
                                    "\n                    " + _vm._s(d) + " "
                                  ),
                                  JSON.parse(_vm.event.remark) &&
                                  JSON.parse(_vm.event.remark).dura[i]
                                    ? _c("span", {
                                        class:
                                          JSON.parse(_vm.event.remark).dura[i]
                                            .color == "red"
                                            ? "red"
                                            : "",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            JSON.parse(_vm.event.remark).dura[i]
                                              .text.tc
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("對象：")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("table", [
                        _c(
                          "tbody",
                          _vm._l(_vm.target, function (t, i) {
                            return _c("tr", { key: i }, [
                              _c("td", [_vm._v(_vm._s(t) + " ")]),
                              _vm._v(" "),
                              JSON.parse(_vm.event.remark)
                                ? _c("td", [
                                    JSON.parse(_vm.event.remark).target[i]
                                      ? _c("span", {
                                          class:
                                            JSON.parse(_vm.event.remark).target[
                                              i
                                            ].color == "red"
                                              ? "red"
                                              : "",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              JSON.parse(_vm.event.remark)
                                                .target[i].text.tc
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("名額：")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("table", [
                        _c(
                          "tbody",
                          _vm._l(_vm.quota, function (t, i) {
                            return _c("tr", { key: i }, [
                              _c("td", [_vm._v(_vm._s(t) + " ")]),
                              _vm._v(" "),
                              JSON.parse(_vm.event.remark)
                                ? _c("td", [
                                    JSON.parse(_vm.event.remark).quota[i]
                                      ? _c("span", {
                                          class:
                                            JSON.parse(_vm.event.remark).quota[
                                              i
                                            ].color == "red"
                                              ? "red"
                                              : "",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              JSON.parse(_vm.event.remark)
                                                .quota[i].text.tc
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }