<template>
  <b-card
    :title="name"
    :img-src="img"
    :img-alt="name"
    img-top
    tag="article"
    style="max-width: 20rem"
    class="mb-2"
  >
  </b-card>
</template>

<script>
export default {
  props: ['img', 'name'],
}
</script>
