<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="6">
      <div class="pageForm">
        <b-form method="post" @submit.prevent="submit">
          <b-form-group id="title" label-for="title">
            <p>{{ $t('role.name') }}</p>
            <b-form-input
              id="name"
              v-model="name"
              type="text"
              trim
            ></b-form-input>
          </b-form-group>

          <div class="shadow my-3 mse-c1">
            <h3>Permission List</h3>
            <div v-for="(perm, counter) in permissions" :key="counter">
              <b-form-group>
                <b-form-checkbox
                  v-model="perm.checked"
                  value="1"
                  unchecked-value="0"
                  >{{ perm.name }}</b-form-checkbox
                >
              </b-form-group>
            </div>
          </div>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" variant="outline-success" type="submit">{{
              $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'

export default {
  components: {
    Notification,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      const permission = await this.$axios.get(`roles/permissions`)
      this.permissions = permission.data.data
      if (this.action === 'edit') {
        const result = await this.$axios.get(`roles/${this.$route.params.id}`)
        const data = result.data.data[0]
        this.id = data.id
        this.name = data.name
        this.permissionList = data.permissionList
        this.permissions = this.permissions.map((d) => {
          if (
            this.permissionList.filter(function (e) {
              return e.code === d.code
            }).length > 0
          ) {
            d.checked = 1
          }
          return d
        })
      }
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      name: '',
      permissions: [],
      permissionList: [],
      error: null,
    }
  },
  computed: {},
  methods: {
    async submit() {
      try {
        let result
        // const permList = this.permissions.filter((d) => d.checked)
        const permList = this.permissions.reduce(function (accumulator, item) {
          if (item.checked === 1 || item.checked === '1') {
            accumulator.push({ permission_id: item.id })
          }
          return accumulator
        }, [])
        if (this.action === 'edit') {
          result = await this.$axios.$post(`roles/update`, {
            id: this.id,
            name: this.name,
            permissionList: permList,
          })
        } else {
          result = await this.$axios.$post(`roles/add`, {
            name: this.name,
            permissionList: permList,
          })
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/role'))
        }
      } catch (err) {
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
.mse-c1 {
  padding: 30px;
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .mse-c1 {
    padding: 10px;
  }

  .shadow {
    padding: 12px;
    margin: 30px -15px;
  }
}
</style>
