var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _vm.action != "send"
                  ? _c(
                      "b-form-group",
                      { attrs: { id: "code", "label-for": "code" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("mailTemplate.code")) +
                            "\n          "
                        ),
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            readonly:
                              _vm.action !== "add" && _vm.action !== "copy",
                          },
                          model: {
                            value: _vm.code,
                            callback: function ($$v) {
                              _vm.code = $$v
                            },
                            expression: "code",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "to", "label-for": "to" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.to")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.receiver,
                        callback: function ($$v) {
                          _vm.receiver = $$v
                        },
                        expression: "receiver",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "cc", "label-for": "cc" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.cc")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.cc,
                        callback: function ($$v) {
                          _vm.cc = $$v
                        },
                        expression: "cc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "bcc", "label-for": "bcc" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.bcc")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.bcc,
                        callback: function ($$v) {
                          _vm.bcc = $$v
                        },
                        expression: "bcc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "subject", "label-for": "subject" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.subject")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.subject,
                        callback: function ($$v) {
                          _vm.subject = $$v
                        },
                        expression: "subject",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("mailTemplate.content")) +
                    "\n        "
                ),
                _c(
                  "b-form-group",
                  { attrs: { id: "content", "label-for": "content" } },
                  [
                    _c(
                      "div",
                      [
                        _vm.isShow
                          ? _c("tinymceEditorFlexible", {
                              ref: "tinymce",
                              attrs: { id: "content", value: _vm.content },
                              on: {
                                writeContent: _vm.getContent,
                                editorInit: (e) => e.setContent(_vm.content),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "testto", "label-for": "testto" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.testto")) +
                        "\n          "
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.testReceiver,
                                callback: function ($$v) {
                                  _vm.testReceiver = $$v
                                },
                                expression: "testReceiver",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { cols: "2" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-info" },
                                on: { click: _vm.sendTestMail },
                              },
                              [_vm._v(_vm._s(_vm.$t("mailTemplate.testmail")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$route.name.includes("emailTemplate")
                              ? _vm.$t("action.save")
                              : _vm.$t("action.submit")
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }