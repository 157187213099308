<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="8">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <div class="shadow">
            <h3>{{ $t('sar.part1') }}</h3>
            <b-form-group
              id="schoolName"
              label-for="schoolName"
              :label="$t('sar.schoolName')"
            >
              <b-form-input v-model="schoolName" type="text"></b-form-input>
            </b-form-group>

            <b-form-group
              id="intSchoolNameChi"
              label-for="intSchoolNameChi"
              :label="$t('sar.intSchoolNameChi')"
            >
              <b-form-input
                v-model="intSchoolNameChi"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="district"
              label-for="district"
              :label="$t('sar.district')"
            >
              <!-- <b-form-input v-model="district" type="text"></b-form-input> -->
              <b-form-select
                v-model="district"
                :options="districtList"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="disabled"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="category"
              label-for="category"
              :label="$t('sar.category')"
            >
              <!-- <b-form-input v-model="category" type="text"></b-form-input> -->
              <b-form-radio-group
                v-model="category"
                :options="catList"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="notEnabled"
              >
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              id="edbCat"
              label-for="edbCat"
              :label="$t('sar.edbCat')"
              class="mb-3"
            >
              <!-- <b-form-input v-model="edbCat" type="text"></b-form-input> -->
              <b-form-select
                v-model="edbCat"
                :options="edbCatList"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="disabled"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="renameRemark"
              label-for="renameRemark"
              :label="$t('sar.renameRemark')"
            >
              <b-form-input v-model="renameRemark" type="text"></b-form-input>
            </b-form-group>

            <hr />
            <b-form-group
              id="schoolStatus"
              label-for="schoolStatus"
              :label="$t('sar.schoolStatus')"
            >
              <!-- <b-form-input v-model="schoolStatus" type="text"></b-form-input> -->
              <b-form-radio-group
                v-model="schoolStatus"
                :options="statusList"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="notEnabled"
              >
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              id="schoolStatusRemark"
              label-for="schoolStatusRemark"
              :label="$t('sar.schoolStatusRemark')"
            >
              <!-- <b-form-input
                v-model="schoolStatusRemark"
                type="text"
              ></b-form-input> -->
              <b-form-checkbox-group
                v-model="schoolStatusRemark"
                :options="statusList2"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="notEnabled"
              >
              </b-form-checkbox-group>
            </b-form-group>
          </div>

          <div class="shadow">
            <h3>{{ $t('sar.part2') }}</h3>
            <b-tabs content-class="mt-3">
              <b-tab>
                <template #title>
                  <span class="scds">{{ $t('sar.scds') }}</span>
                </template>

                <b-form-group
                  id="scds"
                  label-for="scds"
                  style="margin-top: 1em"
                >
                  <JsonInput
                    id="scds"
                    :value="scds"
                    :empty="scdsEmpty"
                    @out="getContent"
                  ></JsonInput>
                </b-form-group>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="spaps">{{ $t('sar.spaps') }}</span>
                </template>
                <b-form-group
                  id="spaps"
                  label-for="spaps"
                  style="margin-top: 1em"
                >
                  <JsonInput
                    id="spaps"
                    :value="spaps"
                    :empty="spapsEmpty"
                    @out="getContent"
                  ></JsonInput>
                </b-form-group>
              </b-tab>
              <b-tab>
                <template #title>
                  <span class="aes">{{ $t('sar.aes') }}</span>
                </template>
                <b-form-group id="aes" label-for="aes" style="margin-top: 1em">
                  <JsonInput
                    id="aes"
                    :value="aes"
                    :empty="aesEmpty"
                    @out="getContent"
                  ></JsonInput>
                </b-form-group>
              </b-tab>
            </b-tabs>
          </div>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
import JsonInput from '~/components/admin/schoolApplicationRecord/JsonInput'

/* import tools from '~/components/common/tools' */

export default {
  components: {
    Notification,
    JsonInput,
    /* tools, */
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    action: { type: String, default: 'add' },
    trnId: { type: String, default: '' },
    tempCode: { type: String, default: '' },
    custom: { type: Object, default: null },
    // isShow: { type: Boolean, default: false },
  },
  async fetch() {
    try {
      if (this.action === 'edit') {
        if (this.action === 'edit') {
          const result = await this.$axios.get(
            `${process.env.localApiUrl}schoolApplicationRecord/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.id = data.id
          this.district = data.district
          this.category = data.category
          this.schoolName = data.schoolName
          this.intSchoolNameChi = data.intSchoolNameChi
          this.renameRemark = data.renameRemark
          this.scds = JSON.parse(data.scds) || []
          this.spaps = JSON.parse(data.spaps) || []
          this.aes = JSON.parse(data.aes) || []
          this.schoolStatus = data.schoolStatus
          this.schoolStatusRemark = data.schoolStatusRemark
          this.edbCat = data.edbCat
        }
      } else {
        this.isShow = true
      }
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      district: 0,
      category: '',
      schoolName: '',
      intSchoolNameChi: null,
      renameRemark: null,
      scds: null,
      spaps: null,
      aes: null,
      schoolStatus: null,
      schoolStatusRemark: null,
      edbCat: null,
      error: null,
      isShow: false,
      scdsEmpty: {
        year: '',
        result: '',
        offered: '',
        attendance: '',
        demeritPoint: '',
      },
      spapsEmpty: { year: '', result: '', activity: '' },
      aesEmpty: { year: '', result: '' },
      catList: [
        { value: '幼稚園', name: '幼稚園' },
        { value: '小學', name: '小學' },
        { value: '中學', name: '中學' },
        { value: '特殊學校', name: '特殊學校' },
        { value: '其他', name: '其他' },
      ],
      edbCatList: [
        {
          value: null,
          name: '學校類別註明',
          disabled: true,
        },
        {
          value: '#N/A',
          name: '#N/A',
          // disabled: true,
        },
        {
          label: '小學',
          options: [
            { value: 'Aided_Primary', name: '資助小學' },
            { value: 'Government_Primary', name: '官立小學' },
            { value: 'DSS_Primary', name: '直資小學' },
            { value: 'Private_Primary', name: '私立小學' },
          ],
        },
        {
          label: '中學',
          options: [
            { value: 'Aided_Secondary', name: '資助中學' },
            { value: 'Government_Secondary', name: '官立中學' },
            { value: 'DSS_Secondary', name: '直資中學' },
            { value: 'Private_Secondary', name: '私立中學' },
          ],
        },
        {
          label: '特殊學校',
          options: [
            { value: 'Visual_Children', name: '視障兒童學校' },
            { value: 'Hearing_Children', name: '聽障兒童學校' },
            { value: 'PD_Children', name: '肢體傷殘兒童學校' },
            { value: 'MiID_Children', name: '輕度智障兒童學校' },
            { value: 'MiMoID_Children', name: '輕中度智障兒童學校' },
            { value: 'MoID_Children', name: '中度智障兒童學校' },
            { value: 'SID_Children', name: '嚴重智障兒童學校' },
            { value: 'SSD', name: '群育學校' },
            { value: 'Hos', name: '醫院學校' },
            { value: 'Early_Centre', name: '早期教育及訓練中心' },
            { value: 'SpecialChild_Centre', name: '特殊幼兒中心' },
          ],
        },
      ],
      districtList: [
        {
          value: 0,
          name: this.$t('school.districtList.select'),
          disabled: true,
        },
        { value: 1, name: '中西區 Central & Western District' },
        { value: 2, name: '東區 Eastern District' },
        { value: 3, name: '南區 Southern District' },
        { value: 4, name: '灣仔區 Wanchai District' },
        { value: 5, name: '九龍城區 Kowloon City District' },
        { value: 6, name: '觀塘區 Kuwn Tong District' },
        { value: 7, name: '深水埗區 Sham Shui Po District' },
        { value: 8, name: '黃大仙區 Wong Tai Sin District' },
        { value: 9, name: '油尖旺區 Yau Tsim Mong District' },
        { value: 10, name: '離島區 Islands District' },
        { value: 11, name: '葵青區 Kwai Tsing District' },
        { value: 12, name: '北區 North District' },
        { value: 13, name: '西貢區 Sai Kung District' },
        { value: 14, name: '沙田區 Shatin District' },
        { value: 15, name: '大埔區 Tai Po District' },
        { value: 16, name: '荃灣區 Tsuen Wan District' },
        { value: 17, name: '屯門區 Tuen Mun District' },
        { value: 18, name: '元朗區 Yuen Long District' },
      ],
      statusList: [
        { value: null, name: '正常' },
        { value: 1, name: '已殺校' },
        { value: 2, name: '已合併學校' },
        { value: 3, name: '新分拆學校' },
      ],
      statusList2: [
        // { value: null, name: '正常' },
        // { value: 1, name: '已殺校' },
        { value: 2, name: '已合併學校' },
        { value: 3, name: '新分拆學校' },
      ],
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getContent(evt, id, content) {
      this[id] = content
    },
    async submit() {
      try {
        let result
        const obj = {
          id: this.id,
          district: this.district,
          category: this.category,
          schoolName: this.schoolName,
          intSchoolNameChi: this.intSchoolNameChi,
          renameRemark: this.renameRemark,
          scds: JSON.stringify(this.scds),
          spaps: JSON.stringify(this.spaps),
          aes: JSON.stringify(this.aes),
          schoolStatus: this.schoolStatus,
          schoolStatusRemark: this.schoolStatusRemark,
          edbCat: this.edbCat,
        }
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.localApiUrl}schoolApplicationRecord/update`,
            obj
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.localApiUrl}schoolApplicationRecord/add`,
            obj
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/schoolApplicationRecord'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
.shadow {
  padding: 30px;
  margin: 30px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.scds {
  color: rgb(255, 115, 157);
}
.spaps {
  color: rgb(151, 114, 234);
}
.aes {
  color: rgb(115, 158, 0);
}
</style>
