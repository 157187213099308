var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-hover",
          rawName: "v-b-hover",
          value: _vm.handleHover,
          expression: "handleHover",
        },
      ],
      class: _vm.isHovered ? "row calendar-card-selected" : "row calendar-card",
      staticStyle: { "font-size": "0.9em" },
    },
    [
      _c("b-col", { staticClass: "leftcol", attrs: { cols: "4" } }, [
        _c("img", {
          staticClass: "card-img",
          style: _vm.imgStyles,
          attrs: { src: _vm.getImg(), alt: "card image" },
        }),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        {
          ref: "rightcol",
          staticStyle: { padding: "0", height: "fit-content" },
          attrs: { id: "right", cols: "8" },
        },
        [
          _c(
            "h6",
            {
              staticClass: "card-scheme-title mb-2",
              style: {
                color: _vm.getColor(_vm.getSchemesSlug(_vm.event.scheme)),
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.getScheme(_vm.event.scheme)
                      ? _vm.i18nTitle(_vm.getScheme(_vm.event.scheme))
                      : ""
                  ) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c("span", {
              staticClass: "schemeLink",
              domProps: { innerHTML: _vm._s(_vm.i18nTitle(_vm.event)) },
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.event.statusTxt &&
            _vm.i18nContent(JSON.parse(_vm.event.statusTxt)) !== ""
              ? _c("span", {
                  staticStyle: { "white-space": "normal" },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.i18nContent(JSON.parse(_vm.event.statusTxt))
                    ),
                  },
                })
              : _vm.getStatus === 1
              ? _c("span", { staticClass: "status_completed" }, [
                  _vm._v(
                    "\n        (" +
                      _vm._s(_vm.$t("event.statusList.completed")) +
                      ")\n      "
                  ),
                ])
              : _vm.getStatus === 2
              ? _c("span", { staticClass: "status_cancelled" }, [
                  _vm._v(
                    "\n        (" +
                      _vm._s(_vm.$t("event.statusList.cancelled")) +
                      ")\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.event.EventSlot && _vm.resultSlot
            ? _c(
                "p",
                { staticClass: "card-time" },
                [
                  _c("b-icon", {
                    staticStyle: { "margin-right": "5px" },
                    attrs: { icon: "calendar2-check" },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm
                          .$moment(
                            _vm.event.EventSlot.find(
                              (s) => s.id === _vm.resultSlot
                            ).date
                          )
                          .format("D.M.Y")
                      )
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }