var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "8" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _vm.action != "send"
                  ? _c(
                      "b-form-group",
                      { attrs: { id: "code", "label-for": "code" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("smsTemplate.code")) +
                            "\n          "
                        ),
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            readonly: _vm.action !== "add",
                          },
                          model: {
                            value: _vm.code,
                            callback: function ($$v) {
                              _vm.code = $$v
                            },
                            expression: "code",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "to", "label-for": "to" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("smsTemplate.to")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.receiver,
                        callback: function ($$v) {
                          _vm.receiver = $$v
                        },
                        expression: "receiver",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "content", "label-for": "content" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("smsTemplate.content")) +
                        "\n          "
                    ),
                    _c("b-textarea", {
                      attrs: { maxlength: "603" },
                      model: {
                        value: _vm.content,
                        callback: function ($$v) {
                          _vm.content = $$v
                        },
                        expression: "content",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.content.length + "/" + "603") +
                          ".\n            "
                      ),
                      _c("span", [_vm._v(_vm._s(_vm.calSMS()))]),
                      _vm._v(" SMS required.\n          "),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "testto", "label-for": "testto" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("smsTemplate.testto")) +
                        "\n          "
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.testReceiver,
                                callback: function ($$v) {
                                  _vm.testReceiver = $$v
                                },
                                expression: "testReceiver",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { cols: "2" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-info" },
                                on: { click: _vm.sendTestMail },
                              },
                              [_vm._v(_vm._s(_vm.$t("smsTemplate.testmail")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }