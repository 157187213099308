var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-cont" }, [
    _c("div", { staticClass: "event-desc-cont" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "event-field" }, [
            _c("div", { staticClass: "event-title" }, [
              _vm._v(_vm._s(_vm.$t("apply.event_code"))),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.getEvent.code))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "event-field" }, [
            _c("div", { staticClass: "event-title" }, [
              _vm._v(_vm._s(_vm.$t("apply.event_title"))),
            ]),
            _vm._v(" "),
            _vm.$route.name.includes("application")
              ? _c(
                  "div",
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass: "scheme-link",
                        attrs: { to: _vm.localePath(`/event/${_vm.event.id}`) },
                      },
                      [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.i18nTitle(_vm.event)),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              : _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.i18nTitle(_vm.event)) },
                }),
          ]),
          _vm._v(" "),
          _vm.getEvent.contact_id &&
          _vm.haveData &&
          _vm.propsChoice.result_choice &&
          _vm.status !== 0
            ? _c("div", { staticClass: "event-field" }, [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(_vm._s(_vm.$t("apply.contact"))),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getContactPerson(_vm.event.contact_id)) +
                      " –\n            " +
                      _vm._s(_vm.getContactTel(_vm.event.contact_id)) +
                      "\n          "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.$route.name.includes("application") && _vm.status === 3
          ? _c("div", { staticClass: "col-md-4 event-cancel" }, [
              !_vm.isAdmin
                ? _c("div", { staticClass: "event-field" }, [
                    _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("apply.scds.cancel_reason")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "word-break": "break-word",
                          padding: "5px 0px",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getCancelReason(_vm.propsChoice.data)) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm.isAdmin
                ? _c("div", { staticClass: "event-field" }, [
                    _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("apply.scds.cancel_reason")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "word-break": "break-word",
                          padding: "5px 0px",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getCancelReason_admin(_vm.propsChoice)) +
                            "\n          "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    !_vm.haveData &&
    _vm.event &&
    _vm.event.applyRemark &&
    _vm.i18nContent(JSON.parse(_vm.event.applyRemark)).length
      ? _c("div", {
          staticClass: "applyRemark",
          domProps: {
            innerHTML: _vm._s(
              _vm.i18nContent(JSON.parse(_vm.event.applyRemark))
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.propsChoice.result_choice != 2 || _vm.status === 0
      ? _c("div", [
          _vm.firstSlot
            ? _c("div", { staticClass: "slot-title" }, [
                _vm.propsChoice && _vm.status !== 0
                  ? _c(
                      "span",
                      [
                        _vm.propsChoice.result_choice === 1
                          ? _c("b-icon", {
                              staticStyle: {
                                color: "#1cbb59",
                                "vertical-align": "-0.35em",
                              },
                              attrs: {
                                icon: "check2-square",
                                "font-scale": "2.3",
                              },
                            })
                          : _vm.propsChoice.result_choice === 2
                          ? _c("b-icon", {
                              staticStyle: {
                                color: "lightgrey",
                                "vertical-align": "-0.32em",
                              },
                              attrs: { icon: "x-square", "font-scale": "1.85" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "slot-ordering" }, [_vm._v("1")]),
                _vm._v(" "),
                _vm.propsChoice.result_choice == 1 && _vm.status !== 0
                  ? _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("apply.scds.result_choice")) +
                          "\n      "
                      ),
                    ])
                  : _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("apply.scds.first_choice")) +
                          "\n      "
                      ),
                    ]),
                _vm._v(" "),
                _vm.cancelledChoice === "1"
                  ? _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        "\n        (" +
                          _vm._s(_vm.$t("apply.scds.cancelled")) +
                          ")\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "slot-form" }, [
            !_vm.firstSlot && !_vm.haveData
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.startdate")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.choice
                        ? _c("b-form-input", {
                            attrs: { type: "date", required: "" },
                            on: { input: _vm.inputChanged },
                            model: {
                              value: _vm.choice.firstChoiceDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.choice, "firstChoiceDate", $$v)
                              },
                              expression: "choice.firstChoiceDate",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-4" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.enddate")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.choice
                        ? _c("b-form-input", {
                            attrs: { type: "date", required: "" },
                            on: { input: _vm.inputChanged },
                            model: {
                              value: _vm.choice.secondChoiceDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.choice, "secondChoiceDate", $$v)
                              },
                              expression: "choice.secondChoiceDate",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.getFirstSlot
                ? _c(
                    "div",
                    { class: _vm.passChoice ? "col-sm-4" : "col-sm-6" },
                    [
                      _c("div", { staticClass: "event-field" }, [
                        _c("div", { staticClass: "event-title" }, [
                          _vm._v(_vm._s(_vm.$t("apply.scds.tab0.date"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "event-input" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .$moment(_vm.getFirstSlot.date)
                                  .format("D.M.Y")
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-field" }, [
                        _c("div", { staticClass: "event-title" }, [
                          _vm._v(_vm._s(_vm.$t("apply.scds.tab0.time"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "event-input" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getTime(_vm.getFirstSlot)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-field" }, [
                        _c("div", { staticClass: "event-title" }, [
                          _vm._v(_vm._s(_vm.$t("apply.scds.tab0.venue"))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "event-input" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getVenue(_vm.getFirstSlot.venue)) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm.passChoice
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.startdate")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-input" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.passChoice.firstChoiceDate
                                ? _vm
                                    .$moment(_vm.passChoice.firstChoiceDate)
                                    .format("D.M.Y")
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.enddate")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-input" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.passChoice.secondChoiceDate
                                ? _vm
                                    .$moment(_vm.passChoice.secondChoiceDate)
                                    .format("D.M.Y")
                                : ""
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.haveData
                ? _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.class")) +
                            " "
                        ),
                        _c("span", [_vm._v("*")]),
                        _vm._v(" :\n          "),
                      ]),
                      _vm._v(" "),
                      _c("b-form-checkbox-group", {
                        attrs: {
                          id: "checkbox-group-firstChoiceClass",
                          options: _vm.createOption1,
                          state: _vm.checkClass1,
                        },
                        model: {
                          value: _vm.choice.firstChoiceClass,
                          callback: function ($$v) {
                            _vm.$set(_vm.choice, "firstChoiceClass", $$v)
                          },
                          expression: "choice.firstChoiceClass",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.passChoice
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.class")) +
                            "\n              "
                        ),
                        !_vm.$route.name.includes("shoppingCart") &&
                        !_vm.$route.name.includes("application")
                          ? _c("span", [_vm._v("*")])
                          : _vm._e(),
                        _vm._v("\n              :\n            "),
                      ]),
                      _vm._v(" "),
                      _vm.passChoice.firstChoiceClass
                        ? _c("div", { staticClass: "event-input" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.formatTargetInArray(
                                    _vm.passChoice.firstChoiceClass
                                  )
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.passChoice
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.studentno")) +
                            "\n              "
                        ),
                        !_vm.$route.name.includes("shoppingCart") &&
                        !_vm.$route.name.includes("application")
                          ? _c("span", [_vm._v("*")])
                          : _vm._e(),
                        _vm._v("\n              :\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-input" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.passChoice.firstChoiceStudentNo) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.teacherno")) +
                            "\n              "
                        ),
                        !_vm.$route.name.includes("shoppingCart") &&
                        !_vm.$route.name.includes("application")
                          ? _c("span", [_vm._v("*")])
                          : _vm._e(),
                        _vm._v("\n              :\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-input" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.passChoice.firstChoiceTeacherNo) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.event.isFamily
                      ? _c("div", { staticClass: "event-field" }, [
                          _c("div", { staticClass: "event-title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("apply.scds.tab0.parentno")) +
                                " :\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "event-input" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.passChoice.firstChoiceParentNo || 0
                                ) +
                                "\n            "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.haveData
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.studentno")) +
                            " "
                        ),
                        _c("span", [_vm._v("*")]),
                        _vm._v(" :\n          "),
                      ]),
                      _vm._v(" "),
                      _vm.choice
                        ? _c("b-form-input", {
                            attrs: {
                              state: _vm.checkStudent1,
                              placeholder: _vm.$t(
                                "apply.scds.tab0.studno_placeholder"
                              ),
                              type: "number",
                              required: "",
                            },
                            on: { input: _vm.inputChanged },
                            model: {
                              value: _vm.choice.firstChoiceStudentNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.choice,
                                  "firstChoiceStudentNo",
                                  $$v
                                )
                              },
                              expression: "choice.firstChoiceStudentNo",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.teacherno")) +
                            " "
                        ),
                        _c("span", [_vm._v("*")]),
                        _vm._v(" :\n          "),
                      ]),
                      _vm._v(" "),
                      _vm.choice
                        ? _c("b-form-input", {
                            attrs: {
                              state: _vm.checkTeacher1,
                              placeholder: _vm.$t(
                                "apply.scds.tab0.teacherno_placeholder"
                              ),
                              type: "number",
                              required: "",
                            },
                            on: { input: _vm.inputChanged },
                            model: {
                              value: _vm.choice.firstChoiceTeacherNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.choice,
                                  "firstChoiceTeacherNo",
                                  $$v
                                )
                              },
                              expression: "choice.firstChoiceTeacherNo",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.event.isFamily
                    ? _c(
                        "div",
                        { staticClass: "col-sm-4" },
                        [
                          _c("div", { staticClass: "event-title" }, [
                            _vm._v(_vm._s(_vm.$t("apply.scds.tab0.parentno"))),
                          ]),
                          _vm._v(" "),
                          _vm.choice
                            ? _c("b-form-input", {
                                attrs: {
                                  state: _vm.checkParent1,
                                  placeholder: _vm.$t(
                                    "apply.scds.tab0.parentno_placeholder"
                                  ),
                                  type: "number",
                                  required: "",
                                },
                                on: { input: _vm.inputChanged },
                                model: {
                                  value: _vm.choice.firstChoiceParentNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.choice,
                                      "firstChoiceParentNo",
                                      $$v
                                    )
                                  },
                                  expression: "choice.firstChoiceParentNo",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.secondSlot && (_vm.propsChoice.result_choice != 1 || _vm.status === 0)
      ? _c("div", [
          _c("div", { staticClass: "slot-title" }, [
            _vm.propsChoice && _vm.status !== 0
              ? _c(
                  "span",
                  [
                    _vm.propsChoice.result_choice === 2
                      ? _c("b-icon", {
                          staticStyle: {
                            color: "#1cbb59",
                            "vertical-align": "-0.35em",
                          },
                          attrs: { icon: "check2-square", "font-scale": "2.3" },
                        })
                      : _vm.propsChoice.result_choice === 1
                      ? _c("b-icon", {
                          staticStyle: {
                            color: "lightgrey",
                            "vertical-align": "-0.32em",
                          },
                          attrs: { icon: "x-square", "font-scale": "1.85" },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "slot-ordering" }, [_vm._v("2")]),
            _vm._v(" "),
            _vm.propsChoice.result_choice == 2 && _vm.status !== 0
              ? _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("apply.scds.result_choice")) +
                      "\n      "
                  ),
                ])
              : _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("apply.scds.sec_choice")) +
                      "\n      "
                  ),
                ]),
            _vm._v(" "),
            _vm.cancelledChoice === "2"
              ? _c("div", { staticStyle: { color: "red" } }, [
                  _vm._v(
                    "\n        (" +
                      _vm._s(_vm.$t("apply.scds.cancelled")) +
                      ")\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "slot-form" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { class: _vm.getPassChoice ? "col-sm-4" : "col-sm-6" },
                [
                  _c("div", { staticClass: "event-field" }, [
                    _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("apply.scds.tab0.date")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.getSecondSlot
                      ? _c("div", { staticClass: "event-input" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .$moment(_vm.getSecondSlot.date)
                                  .format("D.M.Y")
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "event-field" }, [
                    _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("apply.scds.tab0.time")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.getSecondSlot
                      ? _c("div", { staticClass: "event-input" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getTime(_vm.getSecondSlot)) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "event-field" }, [
                    _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("apply.scds.tab0.venue")) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.getSecondSlot
                      ? _c("div", { staticClass: "event-input" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getVenue(_vm.getSecondSlot.venue)) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              !_vm.haveData
                ? _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.class")) +
                            " "
                        ),
                        _c("span", [_vm._v("*")]),
                        _vm._v(" :\n          "),
                      ]),
                      _vm._v(" "),
                      _c("b-form-checkbox-group", {
                        attrs: {
                          id: "checkbox-group-secondChoiceClass",
                          state: _vm.checkClass2,
                          options: _vm.createOption2,
                          name: "flavour-2",
                        },
                        model: {
                          value: _vm.choice.secondChoiceClass,
                          callback: function ($$v) {
                            _vm.$set(_vm.choice, "secondChoiceClass", $$v)
                          },
                          expression: "choice.secondChoiceClass",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.passChoice
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.class")) +
                            "\n              "
                        ),
                        !_vm.$route.name.includes("shoppingCart") &&
                        !_vm.$route.name.includes("application")
                          ? _c("span", [_vm._v("*")])
                          : _vm._e(),
                        _vm._v("\n              :\n            "),
                      ]),
                      _vm._v(" "),
                      _vm.passChoice.secondChoiceClass
                        ? _c("div", { staticClass: "event-input" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.formatTargetInArray(
                                    _vm.passChoice.secondChoiceClass
                                  )
                                ) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.passChoice
                ? _c("div", { staticClass: "col-sm-4" }, [
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.studentno")) +
                            "\n              "
                        ),
                        !_vm.$route.name.includes("shoppingCart") &&
                        !_vm.$route.name.includes("application")
                          ? _c("span", [_vm._v("*")])
                          : _vm._e(),
                        _vm._v("\n              :\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-input" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.passChoice.secondChoiceStudentNo) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "event-field" }, [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("apply.scds.tab0.teacherno")) +
                            "\n              "
                        ),
                        !_vm.$route.name.includes("shoppingCart") &&
                        !_vm.$route.name.includes("application")
                          ? _c("span", [_vm._v("*")])
                          : _vm._e(),
                        _vm._v("\n              :\n            "),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-input" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.passChoice.secondChoiceTeacherNo) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.event.isFamily
                      ? _c("div", { staticClass: "event-field" }, [
                          _c("div", { staticClass: "event-title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("apply.scds.tab0.parentno")) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "event-input" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.passChoice.secondChoiceParentNo || 0
                                ) +
                                "\n            "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.haveData
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.studentno")) +
                            " "
                        ),
                        _c("span", [_vm._v("*")]),
                        _vm._v(" :\n          "),
                      ]),
                      _vm._v(" "),
                      _vm.choice
                        ? _c("b-form-input", {
                            attrs: {
                              state: _vm.checkStudent2,
                              placeholder: _vm.$t(
                                "apply.scds.tab0.teacherno_placeholder"
                              ),
                              type: "number",
                              required: "",
                            },
                            on: { input: _vm.inputChanged },
                            model: {
                              value: _vm.choice.secondChoiceStudentNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.choice,
                                  "secondChoiceStudentNo",
                                  $$v
                                )
                              },
                              expression: "choice.secondChoiceStudentNo",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-4" },
                    [
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab0.teacherno")) +
                            " "
                        ),
                        _c("span", [_vm._v("*")]),
                        _vm._v(" :\n          "),
                      ]),
                      _vm._v(" "),
                      _vm.choice
                        ? _c("b-form-input", {
                            attrs: {
                              state: _vm.checkTeacher2,
                              placeholder: _vm.$t(
                                "apply.scds.tab0.teacherno_placeholder"
                              ),
                              type: "number",
                              required: "",
                            },
                            on: { input: _vm.inputChanged },
                            model: {
                              value: _vm.choice.secondChoiceTeacherNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.choice,
                                  "secondChoiceTeacherNo",
                                  $$v
                                )
                              },
                              expression: "choice.secondChoiceTeacherNo",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.event.isFamily
                    ? _c(
                        "div",
                        { staticClass: "col-sm-4" },
                        [
                          _c("div", { staticClass: "event-title" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("apply.scds.tab0.parentno")) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.choice
                            ? _c("b-form-input", {
                                attrs: {
                                  state: _vm.checkParent2,
                                  placeholder: _vm.$t(
                                    "apply.scds.tab0.parentno_placeholder"
                                  ),
                                  type: "number",
                                  required: "",
                                },
                                on: { input: _vm.inputChanged },
                                model: {
                                  value: _vm.choice.secondChoiceParentNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.choice,
                                      "secondChoiceParentNo",
                                      $$v
                                    )
                                  },
                                  expression: "choice.secondChoiceParentNo",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.parentSchemeId === 4 ? _c("div") : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }