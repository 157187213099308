var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-addskl",
            title: "新增學校至千年表",
            size: "lg",
            "hide-footer": "",
          },
        },
        [
          _c(
            "b-form-group",
            { attrs: { "label-for": "form", label: "學校名稱" } },
            [
              _c("b-form-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.newskl_schoolName,
                  callback: function ($$v) {
                    _vm.newskl_schoolName = $$v
                  },
                  expression: "newskl_schoolName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { "label-for": "form", label: "學校類別" } },
            [
              _c("b-form-radio-group", {
                staticClass: "mb-3",
                attrs: {
                  options: _vm.typeList,
                  "value-field": "value",
                  "text-field": "name",
                  "disabled-field": "notEnabled",
                },
                model: {
                  value: _vm.newskl_category,
                  callback: function ($$v) {
                    _vm.newskl_category = $$v
                  },
                  expression: "newskl_category",
                },
              }),
              _vm._v(" "),
              _vm.newskl_category === 5
                ? _c("b-form-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.newskl_categoryTxt,
                      callback: function ($$v) {
                        _vm.newskl_categoryTxt = $$v
                      },
                      expression: "newskl_categoryTxt",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { "label-for": "form", label: "學校區域" } },
            [
              _c("b-form-select", {
                staticClass: "mb-3",
                attrs: {
                  options: _vm.districtList,
                  "value-field": "value",
                  "text-field": "name",
                  "disabled-field": "disabled",
                },
                model: {
                  value: _vm.newskl_district,
                  callback: function ($$v) {
                    _vm.newskl_district = $$v
                  },
                  expression: "newskl_district",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              attrs: { variant: "outline-success" },
              on: {
                click: function ($event) {
                  return _vm.addSkl()
                },
              },
            },
            [_vm._v("\n      新增學校\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", [_vm._v("1. 配對千年表紀錄")]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-1" }, [_vm._v("請從千年表選擇相應學校：")]),
      _vm._v(" "),
      !!_vm.localData
        ? _c("b-form-select", {
            staticClass: "mt-1",
            attrs: {
              options: _vm.suggestSklList,
              state: !!_vm.sarId,
              size: "sm",
              required: "",
            },
            model: {
              value: _vm.sarId,
              callback: function ($$v) {
                _vm.sarId = $$v
              },
              expression: "sarId",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "mt-2 mb-5" }, [
        _c("table", [
          _c("tbody", [
            _c("tr", [
              !!_vm.localData ? _c("td", [_vm._v("找不到學校？")]) : _vm._e(),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("school-modal", {
                    attrs: {
                      "sch-id": _vm.sarId,
                      school: _vm.selected,
                      "skl-list": _vm.suggestSklList,
                    },
                    on: { change: _vm.schoolModalChange },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            !!_vm.localData ? _c("td") : _vm._e(),
            _vm._v(" "),
            _c("td", [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-addskl",
                      modifiers: { "modal-addskl": true },
                    },
                  ],
                  attrs: { href: "javascript:void(0)" },
                },
                [_vm._v("\n            新增學校至千年表\n          ")]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "my-3" }, [
        _vm._v("\n    已選擇：\n    "),
        _c(
          "span",
          {
            class:
              _vm.getSklName(_vm.sarId) === "未選擇"
                ? "not_selected"
                : "resultskl",
          },
          [_vm._v("\n      " + _vm._s(_vm.getSklName(_vm.sarId)) + "\n    ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }