<template>
  <div class="app" :class="fontclass">
    <header class="row header mobile-row">
      <div
        v-if="api && api.includes('preview')"
        pill
        variant="secondary"
        class="preview"
      >
        預覽模式 <b-icon icon="eye" />
      </div>
      <div
        v-if="api && api.includes('abo.lcsd.hksarg')"
        pill
        variant="secondary"
        class="preview"
      >
        內聯網 {{ version }}
      </div>
      <div
        v-if="api && api.includes('abotest.lcsd.hksarg')"
        pill
        variant="secondary"
        class="preview"
      >
        內聯網 (Testing)
      </div>
      <b-col sm="5" md="3" class="logo mobile-col-12">
        <nuxt-link :to="localePath('/')">
          <img
            v-show="$i18n.locale === 'en'"
            class="logoimg"
            alt="logo image"
            :src="`${staticPrefix}img/logo_abo_en.png`"
          />
          <img
            v-show="$i18n.locale === 'tc'"
            class="logoimg"
            alt="封面圖片"
            :src="`${staticPrefix}img/logo_abo_ch.png`"
          />
          <img
            v-show="$i18n.locale === 'sc'"
            class="logoimg"
            alt="封面图片"
            :src="`${staticPrefix}img/logo_abo_sc.png`"
          />
        </nuxt-link>
      </b-col>
      <b-col sm="7" md="9" xl="9" class="fontstyle mobile-col-12">
        <div>
          <nuxt-link
            class="lang-text"
            :class="$i18n.locale == 'tc' ? 'selected' : ''"
            :to="switchLocalePath('tc')"
          >
            <!-- <nuxt-link v-if="$i18n.locale !== 'tc'" :to="switchLocalePath('tc')"> -->
            {{ $t('g.tc') }}
          </nuxt-link>
        </div>
        <div>/</div>
        <div>
          <nuxt-link
            class="lang-text"
            :class="$i18n.locale == 'sc' ? 'selected' : ''"
            :to="switchLocalePath('sc')"
          >
            <!-- <nuxt-link v-if="$i18n.locale !== 'sc'" :to="switchLocalePath('sc')"> -->
            {{ $t('g.sc') }}
          </nuxt-link>
        </div>
        <div>/</div>
        <div>
          <nuxt-link
            class="lang-text"
            :class="$i18n.locale == 'en' ? 'selected' : ''"
            :to="switchLocalePath('en')"
          >
            <!-- <nuxt-link v-if="$i18n.locale !== 'en'" :to="switchLocalePath('en')"> -->
            {{ $t('g.en') }}</nuxt-link
          >
        </div>
        <div>|</div>
        <div class="fontsize">
          <a
            onclick="return false"
            :class="selectedFontSize === 1 ? 'a1-selected' : 'a1'"
            @click="changeFont(1)"
          >
            A
          </a>
          <a
            onclick="return false"
            :class="selectedFontSize === 2 ? 'a2-selected' : 'a2'"
            @click="changeFont(2)"
          >
            A
          </a>
          <a
            onclick="return false"
            :class="selectedFontSize === 3 ? 'a3-selected' : 'a3'"
            @click="changeFont(3)"
          >
            A
          </a>
        </div>
      </b-col>
    </header>
    <Nuxt />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      api: process.env.mainApiUrl,
      selectedFontSize: 1,
    }
  },
  computed: {
    version() {
      return process.env.version
    },
    fontclass() {
      const v = this.$store.state.localStorage.setting.fontsize
      return v === 3 ? 'fontsize3' : v === 2 ? 'fontsize2' : 'fontsize1'
    },
    staticPrefix() {
      return process.env.base
    },
  },
  methods: {
    changeFont(data) {
      this.$store.commit('localStorage/changeFont', data)
      this.selectedFontSize = data
    },
  },
}
</script>
<style>
body {
  font-family: 'Noto Sans TC', 'Noto Sans SC', sans-serif;
}
</style>
<style lang="scss" scoped>
.preview {
  position: fixed;
  border-radius: 50rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 4;
  /* -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3)); */
  /* filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3)); */
  width: 150px;
  padding: 0.5em;
  color: #fc7137;
  border: 1px solid #fc7137;
}
.fontstyle {
  position: relative;
  float: right;
  display: flex;
  font-size: 18px;
  vertical-align: center;
  justify-content: flex-end;
}
.fontstyle > * {
  align-self: center;
}
.fontstyle a {
  color: rgba(63, 63, 63, 0.3);
}
.fontstyle > div {
  margin: 8px;
}
.logined {
  display: flex;
}
.logined > * {
  align-self: center;
  margin: 0 8px;
}
.usericon {
  user-select: none;
  background: #fc7137;
  color: #fff !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  font-size: 21px;
  margin: auto;
}
.fontsize {
  display: flex;
}
.fontsize a {
  color: #3f3f3f;
}
.fontsize > * {
  margin: 0 8px;
  align-self: baseline;
}
.selected {
  color: rgba(63, 63, 63, 1) !important;
}

html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

header {
  background: #fff;
  margin: 0px;
  z-index: 10;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  width: 100%;
  top: 0;
  height: 75px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-wrapper {
  padding-left: 0px;
}

.d-block {
  text-align: left;
}
.header {
  margin: 0px;
}
.app {
  margin: 0 auto;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1920px;
  }
}
.container {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.nuxt-link-exact-active p {
  color: #000;
}

.pointer {
  cursor: pointer;
  color: #fc7137;
  font-weight: bold;
  margin-left: 15px;
}

.nav-container {
  padding: 0px;
  width: 100%;
}
.logo {
  height: 60px;
}
.logo.col-sm-12.col-md-3 {
  text-align: left;
}
.logoimg {
  position: absolute;
  left: 20px;
}
@media (min-width: 768px) {
  .d-12 {
    flex: 0 0 calc(100% - 100px);
    max-width: calc(100% - 100px);
  }

  .nav-container {
    padding: 0px;
    width: 100px;
  }
}
/* .fontsize1 {
  font-size: 12px !important;
}
.fontsize2 {
  font-size: 15px !important;
}
.fontsize3 {
  font-size: 18px !important;
} */

.fontsize1 {
  font-size: 95% !important;
}
.fontsize2 {
  font-size: 107% !important;
}
.fontsize3 {
  font-size: 120% !important;
}

.a1 {
  font-size: 14px !important;
}
.a2 {
  font-size: 18px !important;
}
.a3 {
  font-size: 22px !important;
}
.a1-selected {
  font-size: 14px !important;
  text-decoration: underline;
}
.a2-selected {
  font-size: 18px !important;
  text-decoration: underline;
}
.a3-selected {
  font-size: 22px !important;
  text-decoration: underline;
}
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

.d-block {
  text-align: left;
}

.header {
  margin: 0px;
}
.app {
  margin: 0 auto;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
  background-image: url('/img/bg_loginPage.png');
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

header {
  margin: 0px;
  z-index: 10;
  position: relative;
  position: fixed;
  width: 100%;
  top: 0;
  height: 75px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.logo.col-sm-12.col-md-3 {
  text-align: left;
  padding-left: 50px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
a {
  cursor: pointer;
}

/* RESPONSIVE EDIT START */
.m-header-right {
  display: none;
}

.m-hide,
.m-show {
  display: block;
}

.m-hide-flex {
  display: flex;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .m-hide,
  .m-hide-flex {
    display: none;
  }

  .m-show {
    display: block;
  }

  .m-show-flex {
    display: flex;
  }

  .fontstyle.col {
    position: fixed;
    top: 80px;
    padding-left: 0;
    padding-right: 5%;
  }

  .fontstyle a {
    color: rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px #b5b5b5;
  }

  .fontstyle > div {
    margin: 8px 4px;
  }

  .fontsize > * {
    margin: 0 6px;
  }

  .selected,
  .a1-selected,
  .a2-selected,
  .a3-selected {
    color: black !important;
  }

  header {
    height: 60px;
    padding-top: 5px;
  }

  .m-header-right {
    display: flex;
    float: right;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 0;

    position: absolute;
  }

  .m-header-right .m-header-button {
    margin: 8px;
    padding-top: 5px;
    padding-left: 5px;
    font-size: 15px !important; // not to shift when font-size change
  }

  .nav-container {
    position: fixed;
    left: auto;
    right: auto;
    bottom: auto;

    width: 0;
    top: 75px;
    z-index: 4;
  }

  .d-12 {
    max-width: unset;
    flex: none;
  }

  .logoimg {
    left: 2px;
    top: 9px;
    height: 38px;
  }
}

/* RESPONSIVE EDIT END */
</style>
