<template>
  <!---------- table ---------->
  <tr v-if="isList">
    <td v-if="event.category_id">{{ getCategory(event.category_id) }}</td>
    <td v-else>-</td>
    <td>{{ event.code }}</td>
    <td>
      <nuxt-link :to="getUrl(event)" class="schemeLink">
        <span v-html="i18nTitle(event)"></span>
        <span
          v-if="
            event.statusTxt && i18nContent(JSON.parse(event.statusTxt)) !== ''
          "
          style="font-size: 80%; white-space: nowrap"
          v-html="i18nContent(JSON.parse(event.statusTxt))"
        >
        </span>
        <span v-else-if="getStatus === 1" class="status_completed">
          ({{ $t('event.statusList.completed') }})
        </span>
        <span v-else-if="getStatus === 2" class="status_cancelled">
          ({{ $t('event.statusList.cancelled') }})
        </span>
      </nuxt-link>
      <img
        v-if="event.isFamily"
        class="icon-family"
        src="https://www.abo.gov.hk/archive/img/SCDS2021/parent.jpg"
        alt="icon-family"
      />
    </td>
    <td>
      <div v-if="!getSchemesSlug(event.scheme).includes('spaps')">
        {{ getDate() }}
      </div>
      <!-- <span v-if="event.startdate">
        {{ $moment(event.startdate).format('MMM DD') }}
      </span>
      <span v-if="event.enddate">
        - {{ $moment(event.enddate).format('MMM DD') }}
      </span>
      <span v-else-if="getDates(event).length === 1">
        {{ $moment(getDates(event)[0]).format('MMM DD') }} ({{
          getDates(event)[0].toString().slice(0, 3)
        }})
      </span>
      <span v-else-if="getDates(event).length > 1" :key="index">
        {{ $moment(getDates(event)[0]).format('MMM DD') }} ({{
          getDates(event)[0].toString().slice(0, 3)
        }}) -
        {{
          getDates(event)[getDates(event).length - 1].toString().slice(4, 10)
        }}
        ({{
          getDates(event)[getDates(event).length - 1].toString().slice(0, 3)
        }})
      </span> -->
    </td>
    <td>
      <a href="javascript:void(0)" @click="bookmark(event.id)">
        <img
          :src="
            isBookmarked
              ? '/img/icon_bookmark_green.svg'
              : '/img/icon_bookmark_grey.svg'
          "
          alt="unbookmark"
          class="icon_bookmark"
        />
      </a>
    </td>
  </tr>

  <!---------- card ---------->
  <b-card v-else class="card-size" no-body>
    <nuxt-link :to="getUrl(event)" class="schemeLink">
      <b-card-img-lazy :src="getImg()" alt="card image"></b-card-img-lazy>
      <div class="card-tags">
        <div
          v-if="event.category_id"
          class="card-tag card-category"
          :style="{ background: getColor(getSchemesSlug(event.scheme)) }"
        >
          {{ getCategory(event.category_id) }}
        </div>
        <div v-if="event.isFamily" class="card-tag card-family">
          <img
            :src="
              getScheme(event.scheme) && getScheme(event.scheme).parentIcon
                ? JSON.parse(getScheme(event.scheme).parentIcon).img
                : ''
            "
            alt="icon-family"
          />
        </div>
        <div
          v-if="getSchemesSlug(event.scheme).includes('scds')"
          class="card-tag card-code"
        >
          {{ event.code }}
        </div>
      </div>
    </nuxt-link>

    <div class="card-body">
      <div class="card-body-header">
        <div class="card-body-target mb-1">
          <div
            v-for="(target, index) in getEventTarget(event)"
            :key="index"
            class="card-target"
          >
            {{ target }}
          </div>
        </div>
        <!-- <div v-if="subevents && subevents.length" class="card-body-target mb-1">
          <div
            v-for="(target, index) in getSubEventTargetSchool()"
            :key="index"
            class="card-target"
          >
            {{ target }}
          </div>
        </div>
        <div v-else class="card-body-target mb-1">
          <div
            v-for="(target, index) in getTargetSchool(event)"
            :key="index"
            class="card-target"
          >
            {{ target }}
          </div>
        </div> -->
        <!-- {{ testbkmark }} {{ test }} -->
        <h6
          v-if="getScheme(event.scheme)"
          :class="
            getSchemesSlug(event.scheme).includes('aoa')
              ? 'card-scheme-title2 mb-2'
              : 'card-scheme-title mb-2'
          "
          :style="{ color: getColor(getSchemesSlug(event.scheme)) }"
        >
          {{ i18nTitle(getScheme(event.scheme)) }}
        </h6>
        <a href="javascript:void(0)" @click="bookmark(event.id)">
          <img
            :src="
              isBookmarked
                ? '/img/icon_bookmark_green.svg'
                : '/img/icon_bookmark_grey.svg'
            "
            class="icon_bookmark"
            alt="icon-bookmark"
          />
        </a>
      </div>

      <div class="mb-5" style="margin-bottom: 2rem !important">
        <nuxt-link :to="getUrl(event)" class="schemeLink">
          <span v-html="i18nTitle(event)"></span>
          <span
            v-if="
              event.statusTxt && i18nContent(JSON.parse(event.statusTxt)) !== ''
            "
            style="font-size: 80%; white-space: nowrap"
            v-html="i18nContent(JSON.parse(event.statusTxt))"
          >
          </span>
          <span v-else-if="getStatus === 1" class="status_completed">
            ({{ $t('event.statusList.completed') }})
          </span>
          <span v-else-if="getStatus === 2" class="status_cancelled">
            ({{ $t('event.statusList.cancelled') }})
          </span>
        </nuxt-link>
      </div>
      <h6
        v-if="!getSchemesSlug(event.scheme).includes('spaps')"
        class="card-date"
      >
        {{ getDate() }}
      </h6>
    </div>
  </b-card>
</template>
<script>
export default {
  props: [
    'event',
    'index',
    'bookmarks',
    'isList',
    'schemes',
    'categories',
    'locked',
    'aoaPass',
  ],
  data() {
    return {
      subEventSlots: [],
      subevents: [],
      subeventsTarget: [],
      subeventsDates: [],
      objects: {
        types: [
          {
            name: this.$t('school.kindergarten'),
            forms: [
              { name: '幼兒班', tag: 'k1', type: 'target' },
              { name: '低班', tag: 'k2', type: 'target' },
              { name: '高班', tag: 'k3', type: 'target' },
            ],
          },
          {
            name: this.$t('school.primaryskl'),
            forms: [
              { name: '小一', tag: 'p1', type: 'target' },
              { name: '小二', tag: 'p2', type: 'target' },
              { name: '小三', tag: 'p3', type: 'target' },
              { name: '小四', tag: 'p4', type: 'target' },
              { name: '小五', tag: 'p5', type: 'target' },
              { name: '小六', tag: 'p6', type: 'target' },
            ],
          },
          {
            name: this.$t('school.secondaryskl'),
            forms: [
              { name: '中一', tag: 'f1', type: 'target' },
              { name: '中二', tag: 'f2', type: 'target' },
              { name: '中三', tag: 'f3', type: 'target' },
              { name: '中四', tag: 'f4', type: 'target' },
              { name: '中五', tag: 'f5', type: 'target' },
              { name: '中六', tag: 'f6', type: 'target' },
            ],
          },
          {
            name: '其他',
            forms: [
              {
                name: this.$t('school.specialskl'),
                tag: 'n',
                type: 'target',
              },
            ],
          },
        ],
      },
      schemes_tab: {
        types: [],
      },
      // schemes: [],
      // isBookmarked: this.event.isBookmarked,
      isBookmarked: this.getIsBookmarked(),
      // test: this.testget(),
    }
  },
  computed: {
    getStatus() {
      // 0: normal; 1: completed; 2: cancelled
      let status = 0

      // if event status is 2, directly show "cancelled"
      if (this.event.status === 2) return 2

      // if all subevents have status = 2 (cancelled), show "cancelled"
      if (this.subevents.length) {
        let countCompletedSubevents = 0
        this.subevents.forEach((d) => {
          if (d.status === 2) countCompletedSubevents++
        })
        if (countCompletedSubevents === this.subevents.length) return 2
      }

      let slots = []
      if (this.event.EventSlot && this.event.EventSlot.length)
        slots = this.event.EventSlot
      if (this.subEventSlots.length) slots = slots.concat(this.subEventSlots)

      // get all normal slots
      slots = slots.filter((d) => d.status !== 2)

      if (slots.length) {
        let countCancelled = 0
        let countCompleted = 0
        slots.forEach((d) => {
          if (d.status === 2) countCancelled++
          if (d.date && this.$moment(d.date).isBefore(this.$moment(), 'day'))
            countCompleted++
        })
        if (countCancelled === slots.length) return 2
        if (countCompleted === slots.length) status = 1
      }

      // check if scheme is finished
      if (
        this.getScheme(this.event.scheme) &&
        this.getScheme(this.event.scheme).isFinish === 1
      )
        return 1

      // if event has enddate, overwrite eventslot's "completed"
      if (this.event.enddate) {
        if (this.$moment(this.event.enddate).isBefore(this.$moment(), 'day'))
          return 1
        else return 0
      }

      // if subevents have enddate, overwrite eventslot's "completed"
      if (this.subevents.length) {
        let countNotCompletedSubevents = 0
        this.subevents.forEach((d) => {
          if (
            d.enddate &&
            !this.$moment(d.enddate).isBefore(this.$moment(), 'day')
          )
            countNotCompletedSubevents++
        })
        if (countNotCompletedSubevents > 0) return 0
      }
      return status
    },
    /* getStatus() {
      // 0: normal; 1: completed; 2: cancelled
      let status = 0

      // if event status is 2, directly show "cancelled"
      if (this.event.status === 2) status = 2
      // if event has enddate, show "completed" from the next date
      else if (
        this.event.enddate &&
        this.$moment(this.event.enddate).isBefore(this.$moment(), 'day')
      )
        status = 1
      else {
        let slots = []
        if (this.event.EventSlot && this.event.EventSlot.length)
          slots = this.event.EventSlot
        if (this.subEventSlots.length) slots = slots.concat(this.subEventSlots)
        if (slots.length) {
          let countCompleted = 0
          slots.forEach((d) => {
            if (d.date && this.$moment(d.date).isBefore(this.$moment(), 'day'))
              countCompleted += 1
          })
          if (countCompleted === slots.length) status = 1
        }
      }
      return status
    }, */
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
    /* testbkmark() {
      if (this.bookmarks.find((bk) => bk.event_id === this.event.code)) {
        return true
      }
      return false
    }, */
  },
  watch: {
    event: {
      handler(newVal, oldVal) {
        this.getSubEvent()
        this.isBookmarked = this.getIsBookmarked()
      },
      deep: true,
    },
    /* isBookmarked: {
      handler(newVal, oldVal) {
        this.bookmarks = this.getBookmarks()
      },
      deep: true,
    }, */
  },
  mounted() {
    this.getSubEvent()
  },
  methods: {
    getUrl(event) {
      if (this.getSchemesSlug(event.scheme).includes('aoa')) {
        if (this.loggedIn) {
          if (this.aoaPass !== 'Y') {
            return this.localePath(`/event/apply/aoa/tempest`)
          }
        } else {
          return this.localePath('/login')
        }
      }
      return this.localePath(`/event/${event.id}`)
    },
    getDate() {
      let result = ''

      if (!this.subevents.length) {
        // spaps: start month to end month
        if (this.event.startdate)
          result = this.$moment(this.event.startdate).format('D.M.Y')
        if (this.event.enddate)
          result = `${result} - ${this.$moment(this.event.enddate).format(
            'D.M.Y'
          )}`

        // scds: get date from eventslot - only 1 eventslot
        if (this.getDates(this.event).length === 1) {
          result = this.getDates(this.event)[0].toString()
          result = `${this.$moment(result).format('D.M.Y')}`
        }

        // scds: get date from eventslot- more than 1 eventslots
        if (this.getDates(this.event).length > 1) {
          const start = this.getDates(this.event)[0].toString()
          const end = this.getDates(this.event)[
            this.getDates(this.event).length - 1
          ].toString()
          result = `${this.$moment(start).format('D.M.Y')} - ${this.$moment(
            end
          ).format('D.M.Y')}`
        }
      } else if (this.getSubEventDates().length) {
        // scds: no eventslot - get from subevent's eventslot
        result = this.getSubEventDates()
      }

      return result
    },
    getImg() {
      let tmp = this.event
        ? this.event.thumbnail
          ? this.event.thumbnail
          : JSON.parse(this.event.img).length
          ? JSON.parse(this.event.img)[0]
          : this.subevents.length
          ? this.subevents[0].thumbnail
            ? this.subevents[0].thumbnail
            : JSON.parse(this.subevents[0].img)[0]
          : ''
        : ''
      if (typeof tmp === 'object') {
        tmp = tmp.img
      }
      return tmp
    },
    getIsBookmarked() {
      return (
        this.bookmarks &&
        this.bookmarks.find((bk) => parseInt(bk.event_id) === this.event.id)
      )
    },
    getCategory(id) {
      if (this.categories !== undefined) {
        const cat = this.categories.find((c) => c.id === id)
        return cat ? this.i18nTitle(cat) : ''
      }
      return ''
    },
    // i18n
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    getColor(slug) {
      switch (true) {
        case slug.includes('scds'):
          return '#FF739D'
        case slug.includes('aoa'):
          return '#4FB1E8'
        case slug.includes('spaps'):
          return '#9772EA'
        case slug.includes('aes'):
          return '#739e00'
        default:
          return ''
      }
    },
    async getSubEvent() {
      try {
        if (!this.event.EventSlot.length) {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}events/public/subEventListByEventId/` +
              this.event.id
          )
          this.subevents = result.data.data
          if (this.subevents) {
            this.subevents.forEach((d) => {
              d.EventSlot.forEach((d) => this.subEventSlots.push(d))
            })
          }
        }
      } catch (err) {}
    },
    bookmark(code) {
      if (this.loggedIn) {
        this.isBookmarked = !this.isBookmarked
        if (this.isBookmarked) {
          this.addbookmark(code)
        } else {
          this.delbookmark(code)
        }
        // console.log(this.bookmarks)
      } else {
        this.$router.push(this.localePath('/login'))
      }
    },
    async addbookmark(code) {
      try {
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}bookmarks/add`,
          {
            id: code,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
      this.bookmarks.push({ event_id: code })
    },
    async delbookmark(code) {
      try {
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}bookmarks/delete`,
          {
            id: code,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    getDates(event) {
      const dates = []
      if (event.EventSlot) {
        event.EventSlot.forEach((s) => {
          if (!dates.includes(s.date)) dates.push(s.date)
        })
        dates.sort()
        // console.log(event.code, dates)
        return dates.map((c) => new Date(c))
      }
    },
    getScheme(id) {
      /* if (this.schemes) {
        for (let i = 0; i < this.schemes.length; i++) {
          if (this.schemes[i].id === id) {
            return this.i18nTitle(this.schemes[i])
          }
        }
      }
      return '' */
      const find = this.schemes.find((s) => s.id === id)
      return find || null
    },
    getSchemesSlug(id) {
      if (this.schemes) {
        for (let i = 0; i < this.schemes.length; i++) {
          if (this.schemes[i].id === id) {
            return this.schemes[i].slug
          }
        }
      }
      return ''
    },
    getEventTarget(event) {
      const result = []
      const tar = event.target.split(',')
      if (tar.includes('k')) {
        result.push(this.$t('event.target.k'))
      }
      if (tar.includes('p')) {
        result.push(this.$t('event.target.p'))
      }
      if (tar.includes('f')) {
        result.push(this.$t('event.target.f'))
      }
      if (tar.includes('tk')) {
        result.push(this.$t('event.target.tk'))
      }
      if (tar.includes('tp')) {
        result.push(this.$t('event.target.tp'))
      }
      if (tar.includes('ts')) {
        result.push(this.$t('event.target.ts'))
      }
      return result
    },
    getTargets(event) {
      const targets = []
      if (event.EventSlot) {
        for (let i = 0; i < event.EventSlot.length; i++) {
          const allTargets = event.EventSlot[i].target.split(',')
          allTargets.map((t) => targets.push(t.toString()))
        }
        const uniqueTargets = targets.reduce((arr = [], t) => {
          if (!arr.includes(t)) arr.push(t)
          return arr
        }, [])
        // console.log('targets: ' + uniqueTargets)
        const arrK = uniqueTargets
          .filter((d) => d.includes('k'))
          .sort((a, b) => a.localeCompare(b))
        const arrP = uniqueTargets
          .filter((d) => d.includes('p'))
          .sort((a, b) => a.localeCompare(b))
        const arrF = uniqueTargets
          .filter((d) => d.includes('f'))
          .sort((a, b) => a.localeCompare(b))
        // console.log('targets2: ' + arrK.concat(arrP).concat(arrF))
        return arrK.concat(arrP).concat(arrF)
      }
    },
    getTargetSchool(event) {
      let uniqueTargets = []
      if (event.EventSlot.length) {
        uniqueTargets = this.getTargets(event)
      } else if (event.target) uniqueTargets = event.target.split(',')

      const resultSklArray = []

      let uniqueSkl = []
      uniqueTargets.map((u) => {
        if (u.toString().includes('k')) {
          resultSklArray.push('k')
        }
        if (u.toString().includes('p')) {
          resultSklArray.push('p')
        }
        if (u.toString().includes('f')) {
          resultSklArray.push('f')
        }
        uniqueSkl = resultSklArray.filter((c, index) => {
          return resultSklArray.indexOf(c) === index
        })
      })
      const result = []
      if (uniqueSkl.includes('k')) result.push(this.objects.types[0].name)
      if (uniqueSkl.includes('p')) result.push(this.objects.types[1].name)
      if (uniqueSkl.includes('f')) result.push(this.objects.types[2].name)
      return result
    },
    getSubEventTargetSchool() {
      const k = this.objects.types[0].name
      const p = this.objects.types[1].name
      const f = this.objects.types[2].name

      const target = this.subevents.length
        ? this.subevents.reduce((arr = [], s) => {
            if (this.getTargets(s).toString().includes('k')) {
              if (!arr.includes(k)) arr.push('k')
            }
            if (this.getTargets(s).toString().includes('p')) {
              if (!arr.includes(p)) arr.push('p')
            }
            if (this.getTargets(s).toString().includes('f')) {
              if (!arr.includes(f)) arr.push('f')
            }
            return arr
          }, [])
        : []
      const targetString = target.join()
      const result = []
      if (targetString.includes('k')) result.push(k)
      if (targetString.includes('p')) result.push(p)
      if (targetString.includes('f')) result.push(f)
      return result
    },
    getSubEventDates() {
      const dates = this.subevents.length
        ? this.subevents.reduce((arr = [], s) => {
            this.getDates(s).map((d) => {
              // arr.push(this.$moment(d).format('Y-M-D'))
              arr.push(this.$moment(d).format('Y-M-D').slice(0, 10)) // fix wrong date issue
            })
            return arr
          }, [])
        : []
      // console.log(dates)
      dates.sort()
      // console.log(dates)
      const dateString = `${this.$moment(dates[0]).format('D.M.Y')}
        - ${this.$moment(dates[dates.length - 1]).format('D.M.Y')}`
      return dateString
    },
  },
}
</script>
<style lang="scss" scoped>
.status_completed {
  color: green;
  font-size: 80%;
  white-space: nowrap;
}
.red {
  color: #fe3855;
}
.status_cancelled {
  font-size: 80%;
  color: #fe3855;
  white-space: nowrap;
}
.card-img-icon {
  overflow: hidden;
  width: 100%;
  // height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  position: relative;
}

.card-img-icon:after {
  content: '';
  display: block;
  padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-img-icon img {
    width: auto !important;
  }
}
.card-img-icon img {
  /* position: relative;
  width: 100%;
  // max-height: 245px;
  margin: auto; */
  position: absolute; /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; /* Make the picture taking the size of it's parent */
  width: 100%; /* This if for the object-fit */
  height: 100%; /* This if for the object-fit */
  object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.titletxt {
}
.schemeLink {
  color: #3f3f3f;
  font-size: 115%;
  height: 180px;
}
.icon-family {
  width: 30px;
  height: 30px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-size {
    width: 245px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* @media only screen and (min-width: 1441px) { */
  .card-size {
    width: 250px;
    // width: 18%;
  }
  /* } */
}
/* @media only screen and (min-width: 1226px) and (max-width: 1440px) {
  .card-size {
    width: 23%; // 250px;
  }
}
@media only screen and (min-width: 783px) and (max-width: 1225px) {
  .card-size {
    width: 30%;
  }
} */
/* @media only screen and (max-width: 782px) {
  .card-size {
    width: 45%;
  }
} */
.card {
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-style: none;
  margin: 20px 10px;
  // height: 350px;
  overflow: hidden;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-img {
    height: 55%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -95%);
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .card-img {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
}
.card-tags {
  position: absolute;
  height: 170px;
  z-index: 2;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 10px;
  font-weight: 500;
  text-align: right;
}
.card-tag {
  padding: 2px 15px;
  border-radius: 100px;
}
.card-tags > .card-category {
  // background: #fd6da9;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
}
.card-tags > .card-family {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0;
  bottom: 20px;
  margin: 30px;
}
.card-tags > .card-family > img {
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 5px;
}
.card-tags > .card-code {
  width: max-content;
  bottom: 0;
  background: white;
  position: absolute;
  right: 0px;
  margin: 10px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
}
.card-body-target {
  // height: 28px;
  overflow: hidden;
}
.card-target {
  border-radius: 5px;
  background-color: #e9f9f8;
  font-size: 90%;
  color: #1fbcb4;
  display: inline-block;
  padding: 0.1em 0.5em;
  margin: 0 0.5em 0.5em 0.5em;
  position: relative;
  left: -0.5em;
}
.card-body {
  // position: absolute;
  width: 100%;
  // height: 180px;
  bottom: 0;
  padding: 15px;
  text-align: left;
  z-index: 2;
  background: white;
  font-size: 90%;
}
.card-body-header {
  position: relative;
}
.card-date {
  font-size: 100%;
  color: #999999;
  position: absolute;
  bottom: 5px;
  // height: 20px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-scheme-title {
  font-size: 90%;
  // color: #ff739d;
}
.card-scheme-title2 {
  font-size: 90%;
  width: 88%;
}
.icon_bookmark {
  height: 25px;
}
.icon_bookmark:hover {
  // content: url(/img/icon_bookmark_grey.svg);
}
.cards-box-content-grid .icon_bookmark {
  position: absolute;
  // z-index: 10;
  right: 0px;
  top: 0;
}
/* .card-text {
  color: #3f3f3f !important;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  height: 2.4em;
  font-size: 105%;
  line-height: 1.2;
}
.card-text:after {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  line-height: 1.2;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
} */
table {
  border: 1px solid black;
  width: 95%;
  text-align: left;
  margin-bottom: 25px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
table > tbody > tr > th {
  background-color: #f5ddd4;
  height: 40px;
  font-size: 130%;
  padding: 10px;
  border: 1px solid rgb(138, 138, 138);
}
table > tbody > tr > td {
  font-weight: 500;
  padding: 10px;
  border: 1px solid rgb(138, 138, 138);
}
table > tbody > tr > td:first-child {
  background-color: #fff2ed;
  height: 40px;
  width: 17%;
}
table > tbody > tr > td:nth-child(2n) {
  background-color: #fffaf5;
  height: 40px;
  font-size: 100%;
}
table > tbody > tr > td:last-child {
  text-align: center;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .card {
    width: 95%;
    margin: 15px 15px;
  }
  .schemeLink {
    color: #3f3f3f;
    font-size: 115%;
    height: 245px;
  }
  .card-img {
    width: 100%;
    height: 245px;
    object-fit: cover;
  }
}

@media only screen and (min-width: 540px) and (max-width: 768px),
  (min-width: 540px) and (orientation: portrait) {
  .card {
    width: 45%;
  }
}

@media only screen and (min-width: 1024px) and (orientation: portrait) {
  .card {
    width: 31%;
  }
}
</style>
