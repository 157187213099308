import { render, staticRenderFns } from "./eventSlotModal.vue?vue&type=template&id=22eaf6b6&scoped=true&"
import script from "./eventSlotModal.vue?vue&type=script&lang=js&"
export * from "./eventSlotModal.vue?vue&type=script&lang=js&"
import style0 from "./eventSlotModal.vue?vue&type=style&index=0&id=22eaf6b6&lang=scss&"
import style1 from "./eventSlotModal.vue?vue&type=style&index=1&id=22eaf6b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22eaf6b6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/sengital/abo-preview-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22eaf6b6')) {
      api.createRecord('22eaf6b6', component.options)
    } else {
      api.reload('22eaf6b6', component.options)
    }
    module.hot.accept("./eventSlotModal.vue?vue&type=template&id=22eaf6b6&scoped=true&", function () {
      api.rerender('22eaf6b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/event/eventSlotModal.vue"
export default component.exports