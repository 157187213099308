var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.content, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "b-row",
              { staticStyle: { "padding-bottom": "20px" } },
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.remove(index)
                      },
                    },
                  },
                  [_vm._v("X")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "wrap",
                      width: "95%",
                    },
                  },
                  _vm._l(item, function (val, name) {
                    return _c(
                      "div",
                      { key: name },
                      [
                        _c(
                          "b-col",
                          [
                            _vm._v(
                              "\n            " + _vm._s(name) + "\n            "
                            ),
                            _c("b-input", {
                              attrs: {
                                id: _vm.id + name + index,
                                type: "text",
                                name: _vm.id + name + index,
                                placeholder: name,
                              },
                              model: {
                                value: item[name],
                                callback: function ($$v) {
                                  _vm.$set(item, name, $$v)
                                },
                                expression: "item[name]",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("b-button", { on: { click: _vm.add } }, [_vm._v("Add")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }