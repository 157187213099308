<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="6">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <p>{{ $t('config.type') }}</p>
          <b-form-group id="type" label-for="type">
            <b-form-input
              id="type"
              v-model="type"
              type="text"
              required
              readonly
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group id="content" label-for="content">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('config.content.en')">
                <tinymceEditor
                  id="content_en"
                  :value="content_en"
                  @writeContent="getContent"
                />
              </b-tab>
              <b-tab :title="$t('config.content.tc')">
                <tinymceEditor
                  id="content_tc"
                  :value="content_tc"
                  @writeContent="getContent"
                />
              </b-tab>
              <b-tab :title="$t('config.content.sc')">
                <tinymceEditor
                  id="content_sc"
                  :value="content_sc"
                  @writeContent="getContent"
                />
              </b-tab>
            </b-tabs>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import TinymceEditor from '~/components/admin/tinymceEditor'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    TinymceEditor,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      // console.log(this.action)
      if (this.action === 'edit') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}config/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.type = data.type
        this.content_en = data.content_en
        this.content_tc = data.content_tc
        this.content_sc = data.content_sc
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      type: '',
      content_en: '',
      content_tc: '',
      content_sc: '',
      error: null,
    }
  },
  computed: {
    getTc() {
      const tc = []
      tc.push(this.content_tc)
      return tc
    },
  },
  methods: {
    setSc(data) {
      this.content_sc = data[0]
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async submit() {
      try {
        let result
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}config/update`,
            {
              id: this.id,
              type: this.type,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
            }
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}config/add`,
            {
              type: this.type,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
            }
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/config'))
        }
      } catch (err) {
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
