var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "margin-bottom": "25px" } }, [
    _c("table", { staticClass: "additional_row" }, [
      _c(
        "tbody",
        [
          _vm._l(_vm.data, function (row, index) {
            return _c(
              "tr",
              { key: index },
              _vm._l(row, function (item, i) {
                return _c(
                  "td",
                  {
                    key: i,
                    style: _vm.edit
                      ? "padding: 5px 0 0 8px"
                      : i === 0
                      ? _vm.tdStyles
                      : "",
                    attrs: { colspan: item.colspan },
                  },
                  [
                    !_vm.edit & (_vm.lang === 0)
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(item.content_en) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.edit & (_vm.lang === 1)
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(item.content_tc) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.edit & (_vm.lang === 2)
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(item.content_sc) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.edit & (_vm.lang === 0)
                      ? _c("medium-editor", {
                          attrs: {
                            text: item.content_en,
                            options: _vm.options,
                          },
                          on: {
                            edit: (operation) => {
                              item.content_en =
                                operation.api.origElements.innerHTML
                              _vm.inputChanged()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.edit & (_vm.lang === 1)
                      ? _c("medium-editor", {
                          attrs: {
                            text: item.content_tc,
                            options: _vm.options,
                          },
                          on: {
                            edit: (operation) => {
                              item.content_tc =
                                operation.api.origElements.innerHTML
                              _vm.inputChanged()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.edit & (_vm.lang === 2)
                      ? _c("medium-editor", {
                          attrs: {
                            text: item.content_sc,
                            options: _vm.options,
                          },
                          on: {
                            edit: (operation) => {
                              item.content_sc =
                                operation.api.origElements.innerHTML
                              _vm.inputChanged()
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          }),
          _vm._v(" "),
          _c("tr"),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.edit
      ? _c(
          "div",
          [
            _c(
              "b-button",
              {
                attrs: { variant: "outline-success" },
                on: { click: _vm.addAdditionalEvent },
              },
              [_vm._v("\n      添加演前/演後活動\n    ")]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { variant: "outline-success" },
                on: { click: _vm.addRow },
              },
              [_vm._v("添加列")]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { variant: "outline-danger" },
                on: { click: _vm.delRow },
              },
              [_vm._v("\n      刪除列（只限新增列）\n    ")]
            ),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticStyle: { color: "red", "font-size": "80%" } }, [
      _c("br"),
      _vm._v(
        "*系統根據活動場次資料自動產生：「日期」、「地點」、「時間」、「節目需時」、「對象」、「名額」\n      "
      ),
      _c("br"),
      _vm._v("*若沒有場次包含票價，則不會顯示「票價」列\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }