<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="10">
      <Notification v-if="error" :message="error" />
      <div class="form">
        <b-form method="post" @submit.prevent="submit">
          <b-form-group id="title" label-for="title">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('contact.name.en')">
                <b-form-input
                  id="name_en"
                  v-model="name_en"
                  type="text"
                  required
                  trim
                ></b-form-input>
              </b-tab>
              <b-tab :title="$t('contact.name.tc')">
                <b-form-input
                  id="name_tc"
                  v-model="name_tc"
                  type="text"
                  required
                  trim
                ></b-form-input>
              </b-tab>
              <b-tab :title="$t('contact.name.sc')">
                <b-form-input
                  id="name_sc"
                  v-model="name_sc"
                  type="text"
                  required
                  trim
                ></b-form-input>
              </b-tab>
            </b-tabs>
          </b-form-group>

          <p class="label">{{ $t('contact.tel') }}</p>
          <b-form-group id="tel" label-for="tel">
            <b-form-input v-model="tel" type="number"></b-form-input>
          </b-form-group>

          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      if (this.action === 'edit') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}contacts/public/contact/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.name_en = data.name_en
        this.name_tc = data.name_tc
        this.name_sc = data.name_sc
        this.tel = data.tel
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      schemeList: [{ value: 'null', text: 'No Parent' }],
      parent: null,
      id: null,
      name_en: '',
      name_tc: '',
      name_sc: '',
      tel: '',
      error: null,
    }
  },
  computed: {
    getTc() {
      const tc = []
      tc.push(this.name_tc)
      return tc
    },
  },
  methods: {
    setSc(data) {
      this.name_sc = data[0]
    },
    async submit() {
      try {
        // console.log('submit')
        let result
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}contacts/update`,
            {
              id: this.id,
              name_en: this.name_en,
              name_tc: this.name_tc,
              name_sc: this.name_sc,
              tel: this.tel,
            }
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}contacts/add`,
            {
              id: this.id,
              name_en: this.name_en,
              name_tc: this.name_tc,
              name_sc: this.name_sc,
              tel: this.tel,
            }
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/contact'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.component {
  text-align: left;
}
.btn {
  margin-bottom: 5px;
}
</style>
