var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        {
          attrs: {
            sm: "12",
            md:
              _vm.action == "schoolAdd" || _vm.action == "schoolEdit" ? 12 : 6,
          },
        },
        [
          _c(
            "div",
            { staticClass: "pageForm" },
            [
              _c(
                "b-form",
                {
                  attrs: { method: "post" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: _vm.info.id
                                  ? "name-" + _vm.info.id
                                  : "name",
                                "label-for": "name",
                              },
                            },
                            [
                              _c("p", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.$t("user.name"))),
                              ]),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: _vm.info.id
                                    ? "name-" + _vm.info.id
                                    : "name",
                                  type: "text",
                                  required:
                                    _vm.action === "edit" ? false : true,
                                  readonly:
                                    _vm.action == "schoolEdit" ? true : false,
                                  trim: "",
                                },
                                model: {
                                  value: _vm.info.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "name", $$v)
                                  },
                                  expression: "info.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: _vm.info.id
                                  ? "password-" + _vm.info.id
                                  : "password",
                                "label-for": "password",
                              },
                            },
                            [
                              _c("p", { staticClass: "label" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.action === "edit"
                                        ? _vm.$t("user.newPassword")
                                        : _vm.$t("user.password")
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: _vm.info.id
                                    ? "password-" + _vm.info.id
                                    : "password",
                                  type:
                                    _vm.action == "schoolAdd"
                                      ? "text"
                                      : "password",
                                  required:
                                    _vm.action === "edit" ? false : true,
                                  readonly:
                                    _vm.action == "schoolEdit" ? true : false,
                                  trim: "",
                                },
                                model: {
                                  value: _vm.info.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "password", $$v)
                                  },
                                  expression: "info.password",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: _vm.info.id ? "tel-" + _vm.info.id : "tel",
                                "label-for": "tel",
                              },
                            },
                            [
                              _c("p", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.$t("user.tel"))),
                              ]),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: _vm.info.id
                                    ? "tel-" + _vm.info.id
                                    : "tel",
                                  type: "text",
                                  required:
                                    _vm.action === "edit" ? false : true,
                                  readonly:
                                    _vm.action == "schoolEdit" ? true : false,
                                  trim: "",
                                },
                                model: {
                                  value: _vm.info.tel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "tel", $$v)
                                  },
                                  expression: "info.tel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: _vm.info.id
                                  ? "email-" + _vm.info.id
                                  : "email",
                                "label-for": "email",
                              },
                            },
                            [
                              _c("p", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.$t("user.email"))),
                              ]),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  id: _vm.info.id
                                    ? "email-" + _vm.info.id
                                    : "email",
                                  type: "email",
                                  required:
                                    _vm.action === "edit" ? false : true,
                                  readonly:
                                    _vm.action == "schoolEdit" ? true : false,
                                  trim: "",
                                },
                                model: {
                                  value: _vm.info.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.info, "email", $$v)
                                  },
                                  expression: "info.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _vm.action != "schoolAdd" && _vm.action != "schoolEdit"
                        ? _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: _vm.info.id
                                      ? "school-" + _vm.info.id
                                      : "school",
                                    "label-for": "school",
                                  },
                                },
                                [
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v(_vm._s(_vm.$t("user.school"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-form-input", {
                                    attrs: {
                                      id: _vm.info.id
                                        ? "school-" + _vm.info.id
                                        : "school",
                                      type: "text",
                                      required:
                                        _vm.info.isEditorAdmin ||
                                        _vm.info.isSuperAdmin
                                          ? false
                                          : true,
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.info.school,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.info, "school", $$v)
                                      },
                                      expression: "info.school",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _vm.action != "schoolAdd" && _vm.action != "schoolEdit"
                        ? _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: _vm.info.id
                                      ? "isSchoolAdmin-" + _vm.info.id
                                      : "isSchoolAdmin",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      model: {
                                        value: _vm.info.isSchoolAdmin,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.info,
                                            "isSchoolAdmin",
                                            $$v
                                          )
                                        },
                                        expression: "info.isSchoolAdmin",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("user.isSchoolAdmin"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action != "schoolAdd" &&
                      _vm.action != "schoolEdit" &&
                      !!_vm.info.isSuperAdmin
                        ? _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: _vm.info.id
                                      ? "isSuperAdmin-" + _vm.info.id
                                      : "isSuperAdmin",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        disabled: _vm.info.isSuperAdmin
                                          ? true
                                          : false,
                                      },
                                      model: {
                                        value: _vm.info.isSuperAdmin,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.info,
                                            "isSuperAdmin",
                                            $$v
                                          )
                                        },
                                        expression: "info.isSuperAdmin",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("user.isSuperAdmin")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action != "schoolAdd" && _vm.action != "schoolEdit"
                        ? _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: _vm.info.id
                                      ? "isEditorAdmin-" + _vm.info.id
                                      : "isEditorAdmin",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      model: {
                                        value: _vm.info.isEditorAdmin,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.info,
                                            "isEditorAdmin",
                                            $$v
                                          )
                                        },
                                        expression: "info.isEditorAdmin",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("user.isEditorAdmin"))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action != "schoolAdd" && _vm.action != "schoolEdit"
                        ? _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: _vm.info.id
                                      ? "disabled-" + _vm.info.id
                                      : "disabled",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      model: {
                                        value: _vm.info.disabled,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.info, "disabled", $$v)
                                        },
                                        expression: "info.disabled",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("user.disabled")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.info.isEditorAdmin || _vm.info.isSuperAdmin
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: _vm.info.id
                                      ? "role-" + _vm.info.id
                                      : "role",
                                    "label-for": "role",
                                  },
                                },
                                [
                                  _c("p", { staticClass: "label" }, [
                                    _vm._v(_vm._s(_vm.$t("navbar_admin.role"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.roleList,
                                      disabled: _vm.info.isSuperAdmin
                                        ? true
                                        : false,
                                    },
                                    model: {
                                      value: _vm.info.role,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.info, "role", $$v)
                                      },
                                      expression: "info.role",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("p", [_vm._v(_vm._s(_vm.$t("school.remarks")))]),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: _vm.info.id
                                      ? "remarks-" + _vm.info.id
                                      : "remarks",
                                    "label-for": "remarks",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "text", trim: "" },
                                    model: {
                                      value: _vm.info.remarks,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.info, "remarks", $$v)
                                      },
                                      expression: "info.remarks",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.action != "schoolAdd" &&
                  _vm.action != "schoolEdit" &&
                  _vm.error
                    ? _c("Notification", { attrs: { message: _vm.error } })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.action != "schoolAdd" && _vm.action != "schoolEdit"
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                id:
                                  _vm.submit +
                                  (_vm.info.id ? "-" + _vm.info.id : ""),
                                type: "submit",
                                variant: "outline-success",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("action.submit")) + "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action != "schoolAdd" && _vm.action != "schoolEdit"
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.sendAcctRegMail()
                                },
                              },
                            },
                            [_vm._v("重新發送「建立帳戶」確認電郵\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.action != "schoolAdd" &&
                      _vm.action != "schoolEdit" &&
                      _vm.info.attempt === 5
                        ? _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.unlock()
                                },
                              },
                            },
                            [_vm._v("解鎖帳戶\n          ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }