<template>
  <div>
    <b-modal id="modal-addskl" title="新增學校至千年表" size="lg" hide-footer>
      <b-form-group label-for="form" label="學校名稱">
        <b-form-input v-model="newskl_schoolName" type="text"></b-form-input>
      </b-form-group>
      <b-form-group label-for="form" label="學校類別">
        <b-form-radio-group
          v-model="newskl_category"
          :options="typeList"
          class="mb-3"
          value-field="value"
          text-field="name"
          disabled-field="notEnabled"
        ></b-form-radio-group>
        <b-form-input
          v-if="newskl_category === 5"
          v-model="newskl_categoryTxt"
          type="text"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-for="form" label="學校區域">
        <b-form-select
          v-model="newskl_district"
          :options="districtList"
          class="mb-3"
          value-field="value"
          text-field="name"
          disabled-field="disabled"
        ></b-form-select>
      </b-form-group>
      <b-button variant="outline-success" @click="addSkl()">
        新增學校
      </b-button>
    </b-modal>
    <h3>1. 配對千年表紀錄</h3>
    <div class="mb-1">請從千年表選擇相應學校：</div>
    <b-form-select
      v-if="!!localData"
      v-model="sarId"
      :options="suggestSklList"
      :state="!!sarId"
      size="sm"
      class="mt-1"
      required
    ></b-form-select>
    <div class="mt-2 mb-5">
      <table>
        <tbody>
          <tr>
            <td v-if="!!localData">找不到學校？</td>
            <td>
              <school-modal
                :sch-id="sarId"
                :school="selected"
                :skl-list="suggestSklList"
                @change="schoolModalChange"
              />
            </td>
          </tr>
          <td v-if="!!localData"></td>
          <td>
            <a v-b-modal.modal-addskl href="javascript:void(0)">
              新增學校至千年表
            </a>
          </td>
        </tbody>
      </table>
    </div>
    <div class="my-3">
      已選擇：
      <span
        :class="getSklName(sarId) === '未選擇' ? 'not_selected' : 'resultskl'"
      >
        {{ getSklName(sarId) }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    schId: { type: Number, default: null },
    data: { type: Object, default: () => null },
    name: { type: String, default: null },
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.localApiUrl}schools/sar/school`
      )
      this.allSkls = result.data.data
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      newskl_schoolName: '',
      localData: '',
      suggestSklList: [{ value: null, text: '匯入申請後將顯示建議學校選項' }],
      sarId: null,
      selected: null,
      allSkls: [],
      newskl_category: '',
      newskl_categoryTxt: '',
      newskl_district: '',
      typeList: [
        { value: 1, name: '幼稚園' },
        { value: 2, name: '小學' },
        { value: 3, name: '中學' },
        { value: 4, name: '特殊學校' },
        { value: 5, name: '其他（請註明）' },
      ],
      districtList: [
        {
          value: 0,
          name: this.$t('school.districtList.select'),
          disabled: true,
        },
        { value: 1, name: '中西區 Central & Western District' },
        { value: 2, name: '東區 Eastern District' },
        { value: 3, name: '南區 Southern District' },
        { value: 4, name: '灣仔區 Wanchai District' },
        { value: 5, name: '九龍城區 Kowloon City District' },
        { value: 6, name: '觀塘區 Kuwn Tong District' },
        { value: 7, name: '深水埗區 Sham Shui Po District' },
        { value: 8, name: '黃大仙區 Wong Tai Sin District' },
        { value: 9, name: '油尖旺區 Yau Tsim Mong District' },
        { value: 10, name: '離島區 Islands District' },
        { value: 11, name: '葵青區 Kwai Tsing District' },
        { value: 12, name: '北區 North District' },
        { value: 13, name: '西貢區 Sai Kung District' },
        { value: 14, name: '沙田區 Shatin District' },
        { value: 15, name: '大埔區 Tai Po District' },
        { value: 16, name: '荃灣區 Tsuen Wan District' },
        { value: 17, name: '屯門區 Tuen Mun District' },
        { value: 18, name: '元朗區 Yuen Long District' },
      ],
    }
  },
  watch: {
    sarId: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true,
    },
    schId: {
      handler(newVal, oldVal) {
        this.sarId = newVal
      },
      deep: true,
    },
    data: {
      handler(newVal, oldVal) {
        this.localData = newVal
      },
      deep: true,
    },
    name: {
      async handler(newVal, oldVal) {
        const result = await this.$axios.get(
          `${process.env.localApiUrl}schools/sar/school/${newVal}`
        )
        if (result.data && result.data.data) {
          this.suggestSklList = result.data.data
          this.suggestSklList = this.suggestSklList.map((d) => {
            d.label = d.schoolName
            d.code = d.id
            d.text = d.schoolName
            d.value = d.id
            return d
          })
        }
      },
      deep: true,
    },
  },
  methods: {
    async addSkl() {
      try {
        const back = await this.$swal({
          text: `確定新增學校至「千年表」？`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: '確定',
          confirmButtonColor: '#d9534f',
          cancelButtonText: '取消',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.localApiUrl}schools/sar/newSchool`,
            {
              schoolName: this.newskl_schoolName,
              category: this.getCategory(),
              district: this.newskl_district,
            }
          )
          if (result.error !== undefined) throw new Error(result.error)
          const back = await this.$swal({
            text: `成功新增學校： ${this.newskl_schoolName}`,
            showCancelButton: false,
            confirmButtonText: 'OK',
            confirmButtonColor: '#1cbcb4',
          })
          if (back.value) {
            this.$fetch()
          }
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    getCategory() {
      switch (this.newskl_category) {
        case 1:
          return '幼稚園'
        case 2:
          return '小學'
        case 3:
          return '中學'
        case 4:
          return '特殊學校'
        case 5:
          return this.newskl_categoryTxt
        default:
          return ''
      }
    },
    schoolModalChange(sarId, selected) {
      this.sarId = sarId
      this.selected = selected

      if (selected) {
        this.suggestSklList.push({
          label: selected[0].schoolName,
          code: selected[0].id,
          text: selected[0].schoolName,
          value: selected[0].id,
        })
      }
    },
    getDistrict(id) {
      const found = this.districtList.find((d) => d.value === id)
      return found ? found.name : ''
    },
    getSklName(sarId) {
      if (this.sarId && this.allSkls.length) {
        const found = this.allSkls.find((d) => {
          return d.id === this.sarId
        })
        return found
          ? `${sarId}. ${this.getDistrict(found.district)}: ${found.schoolName}`
          : '未選擇'
      }
      if (this.sarId && this.suggestSklList.length) {
        const found = this.suggestSklList.find((d) => {
          return d.id === this.sarId
        })
        return found
          ? `${sarId}. ${this.getDistrict(found.district)}: ${found.schoolName}`
          : '未選擇'
      }
      return '未選擇'
    },
  },
}
</script>
