<template>
  <div class="cont-tab">
    <!--Title-->
    <div class="scheme-title">
      <div v-for="(n, index) in getPaths()" :key="n.id" class="path">
        <nuxt-link
          :to="localePath(`/${n.slug}`)"
          :class="
            index === getPaths().length - 1 ? 'scheme-link-last' : 'scheme-link'
          "
          >{{ n.title }}</nuxt-link
        ><span v-if="index != getPaths().length - 1">&nbsp;&gt;&nbsp;</span>
      </div>
      <!-- <div class="pagetitle path">{{ currSchemeId }}</div> -->
    </div>
    <!--Tabs-->
  </div>
</template>

<script>
export default {
  name: 'SchemeTab',
  props: {
    currTitle: String,
    currSlug: String,
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    getTxt(field) {
      const lang =
        this.$i18n.locale === 'en' ? 0 : this.$i18n.locale === 'tc' ? 1 : 2
      switch (field) {
        case 'home':
          return lang === 0 ? 'Home' : lang === 1 ? '主頁' : '主页'
        default:
          return ''
      }
    },
    getPaths() {
      const path = []
      // home
      const home = { title: this.getTxt('home'), slug: `` }
      path.push(home)
      path.push({ title: this.currTitle, slug: this.currSlug })
      return path
    },
  },
}
</script>

<style lang="scss" scoped>
.cont-tab {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.scheme-tab-img-bg {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  background-color: #e1e1e1;
  margin-right: 10px;
  fill: white;
  display: flex;
  flex-wrap: wrap;
}
.scheme-tab-img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}
.scheme-tab-item {
  // width: 100%;
}
.scheme-title {
  color: #000;
  width: 100%;
  z-index: 0;
  display: inline-flex;
  margin-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
}
.path {
  font-size: 100%;
}
a.scheme-link,
a.nav-link {
  color: #3f3f3f;
}
a.scheme-link-last {
  color: #3f3f3f;
  font-weight: bold;
}
a.scheme-link:hover,
a.scheme-link-last:hover,
a.nav-link:hover {
  color: #007bff;
}
.pagetitle {
  font-weight: bold;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .cont-tab {
    width: 100%;
  }
}
</style>
