var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app", class: _vm.fontclass },
    [
      _c(
        "header",
        { staticClass: "row header mobile-row" },
        [
          _vm.api && _vm.api.includes("preview")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [
                  _vm._v("\n      預覽模式 "),
                  _c("b-icon", { attrs: { icon: "eye" } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.api && _vm.api.includes("abo.lcsd.hksarg")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [_vm._v("\n      內聯網 " + _vm._s(_vm.version) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.api && _vm.api.includes("abotest.lcsd.hksarg")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [_vm._v("\n      內聯網 (Testing)\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "logo mobile-col-12", attrs: { sm: "5", md: "3" } },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$i18n.locale === "en",
                      expression: "$i18n.locale === 'en'",
                    },
                  ],
                  staticClass: "logoimg",
                  attrs: {
                    alt: "logo image",
                    src: `${_vm.staticPrefix}img/logo_abo_en.png`,
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$i18n.locale === "tc",
                      expression: "$i18n.locale === 'tc'",
                    },
                  ],
                  staticClass: "logoimg",
                  attrs: {
                    alt: "封面圖片",
                    src: `${_vm.staticPrefix}img/logo_abo_ch.png`,
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$i18n.locale === "sc",
                      expression: "$i18n.locale === 'sc'",
                    },
                  ],
                  staticClass: "logoimg",
                  attrs: {
                    alt: "封面图片",
                    src: `${_vm.staticPrefix}img/logo_abo_sc.png`,
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "fontstyle mobile-col-12",
              attrs: { sm: "7", md: "9", xl: "9" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "lang-text",
                      class: _vm.$i18n.locale == "tc" ? "selected" : "",
                      attrs: { to: _vm.switchLocalePath("tc") },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("g.tc")) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("/")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "lang-text",
                      class: _vm.$i18n.locale == "sc" ? "selected" : "",
                      attrs: { to: _vm.switchLocalePath("sc") },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("g.sc")) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("/")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "lang-text",
                      class: _vm.$i18n.locale == "en" ? "selected" : "",
                      attrs: { to: _vm.switchLocalePath("en") },
                    },
                    [_vm._v("\n          " + _vm._s(_vm.$t("g.en")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("|")]),
              _vm._v(" "),
              _c("div", { staticClass: "fontsize" }, [
                _c(
                  "a",
                  {
                    class: _vm.selectedFontSize === 1 ? "a1-selected" : "a1",
                    attrs: { onclick: "return false" },
                    on: {
                      click: function ($event) {
                        return _vm.changeFont(1)
                      },
                    },
                  },
                  [_vm._v("\n          A\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: _vm.selectedFontSize === 2 ? "a2-selected" : "a2",
                    attrs: { onclick: "return false" },
                    on: {
                      click: function ($event) {
                        return _vm.changeFont(2)
                      },
                    },
                  },
                  [_vm._v("\n          A\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: _vm.selectedFontSize === 3 ? "a3-selected" : "a3",
                    attrs: { onclick: "return false" },
                    on: {
                      click: function ($event) {
                        return _vm.changeFont(3)
                      },
                    },
                  },
                  [_vm._v("\n          A\n        ")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("Nuxt"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }