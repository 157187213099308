<template>
  <div
    v-b-hover="handleHover"
    :class="isHovered ? 'row calendar-card-selected' : 'row calendar-card'"
    style="font-size: 0.9em"
  >
    <b-col cols="4" class="leftcol">
      <img
        class="card-img"
        :src="getImg()"
        :style="imgStyles"
        alt="card image"
      />
    </b-col>
    <b-col
      id="right"
      ref="rightcol"
      cols="8"
      style="padding: 0; height: fit-content"
    >
      <h6
        class="card-scheme-title mb-2"
        :style="{ color: getColor(getSchemesSlug(event.scheme)) }"
      >
        {{ getScheme(event.scheme) ? i18nTitle(getScheme(event.scheme)) : '' }}
      </h6>
      <div>
        <span class="schemeLink" v-html="i18nTitle(event)"></span>
        <br />
        <span
          v-if="
            event.statusTxt && i18nContent(JSON.parse(event.statusTxt)) !== ''
          "
          style="white-space: normal"
          v-html="i18nContent(JSON.parse(event.statusTxt))"
        >
        </span>
        <span v-else-if="getStatus === 1" class="status_completed">
          ({{ $t('event.statusList.completed') }})
        </span>
        <span v-else-if="getStatus === 2" class="status_cancelled">
          ({{ $t('event.statusList.cancelled') }})
        </span>
      </div>
      <p v-if="event.EventSlot && resultSlot" class="card-time">
        <b-icon icon="calendar2-check" style="margin-right: 5px" />
        {{
          $moment(event.EventSlot.find((s) => s.id === resultSlot).date).format(
            'D.M.Y'
          )
        }}<!-- 'MMM DD' &ensp; <b-icon icon="clock" style="margin-right: 5px" />{{
          event.EventSlot.find((s) => s.id === resultSlot).startTime.slice(0, 5)
        }} -->
        <!-- -
        {{
          event.EventSlot.find((s) => s.id === resultSlot).endTime.slice(0, 5)
        }} -->
        <!-- <br />
        <b-icon icon="geo" style="margin-right: 5px" />
        {{ event.EventSlot.find((s) => s.id === resultSlot).venue }} -->
      </p>
    </b-col>
  </div>
</template>
<script>
export default {
  props: {
    event: Object,
    index: Number,
    bookmarks: Array,
    isList: Boolean,
    schemes: Array,
    resultSlot: Number,
    isApplied: { type: Boolean, default: false },
  },
  async fetch() {},
  data() {
    return {
      imgStyles: {},
      isHovered: false,
      schemes_tab: {
        types: [],
      },
      parentEvent: {},
      // schemes: [],
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
  },
  watch: {
    event: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.matchHeight()
          this.getParent()
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.matchHeight()
    this.getParent()
  },
  methods: {
    getStatus() {
      // 0: normal; 1: completed; 2: cancelled
      let status = 0

      // if event status is 2, directly show "cancelled"
      if (this.event.status === 2) status = 2
      // if event has enddate, show "completed" from the next date
      else if (
        this.event.enddate &&
        this.$moment(this.event.enddate).isBefore(this.$moment(), 'day')
      )
        status = 1
      // if event has eventslot, check if all slots are completed
      else if (this.event.EventSlot && this.event.EventSlot.length) {
        let countCompleted = 0
        this.event.EventSlot.forEach((d) => {
          if (d.date && this.$moment(d.date).isBefore(this.$moment(), 'day'))
            countCompleted += 1
        })
        if (countCompleted === this.event.EventSlot.length) status = 1
      }
      return status
    },
    getImg() {
      if (this.event) {
        if (this.event.thumbnail) return this.event.thumbnail
        // this.getParent()
        if (this.parentEvent) return this.parentEvent.thumbnail
        if (!this.event.thumbnail)
          return 'https://www.abo.gov.hk/image/blank.png'
        if (JSON.parse(this.event.img).length)
          return JSON.parse(this.event.img)[0]
      }
      return this.event
        ? this.event.thumbnail
          ? this.event.thumbnail
          : JSON.parse(this.event.img).length
          ? JSON.parse(this.event.img)[0]
          : this.subevents && this.subevents.length
          ? this.subevents[0].thumbnail
            ? this.subevents[0].thumbnail
            : JSON.parse(this.subevents[0].img)[0]
          : this.parentEvent && this.parentEvent.thumbnail
          ? this.parentEvent.thumbnail
          : ''
        : ''
    },
    async getParent() {
      try {
        if (this.event.parent && !this.event.thumbnail) {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}events/public/parentCode/${this.event.parent}`
          )
          this.parentEvent = result.data.data[0]
        }
      } catch (err) {}
    },
    getScheme(id) {
      const find = this.schemes.find((s) => s.id === id)
      return find || null
    },
    getSchemesSlug(id) {
      if (this.schemes) {
        for (let i = 0; i < this.schemes.length; i++) {
          if (this.schemes[i].id === id) {
            return this.schemes[i].slug
          }
        }
      }
      return ''
    },
    getColor(slug) {
      switch (true) {
        case slug.includes('scds'):
          return '#FF739D'
        case slug.includes('aoa'):
          return '#4FB1E8'
        case slug.includes('spaps'):
          return '#9772EA'
        case slug.includes('aes'):
          return '#739e00'
        default:
          return ''
      }
    },
    // i18n
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    matchHeight() {
      /* cancel - set img height as same as the right col(scheme name, event name, date) */
      /* const isIE = false || !!document.documentMode
      if (!isIE) {
        if (this.$refs.rightcol.clientWidth < 300) {
          const height = this.$refs.rightcol.clientHeight
            .toString()
            .concat('px')
          if (this.$refs.rightcol.clientHeight > 0)
            this.$set(this.imgStyles, 'height', height)
          else this.$set(this.imgStyles, '94px', height)
        } else {
          this.$set(this.imgStyles, 'height', '100px')
        }
      } */
      this.$set(this.imgStyles, 'height', '66px')
    },
    handleHover(hovered) {
      this.isHovered = hovered
    },
    getDates(event) {
      const dates = []
      // console.log('dates: ' + event.EventSlot.length)
      if (event.EventSlot) {
        // console.log('event ' + JSON.stringify(event.EventSlot[0]))
        for (let i = 0; i < event.EventSlot.length; i++) {
          dates.push(new Date(event.EventSlot[i].date))
        }
        const uniqueDates = []
        dates.forEach((c) => {
          if (!uniqueDates.includes(c)) {
            uniqueDates.push(c)
          }
        })
        // console.log('dates: ' + uniqueDates)
        return uniqueDates
      }
    },
    getSchemes(id) {
      if (this.schemes) {
        for (let i = 0; i < this.schemes.length; i++) {
          if (this.schemes[i].id === id) {
            return this.schemes[i].title_tc
          }
        }
      }
      return ''
    },
    getTargets(event) {
      const targets = []
      if (event.EventSlot) {
        for (let i = 0; i < event.EventSlot.length; i++) {
          const allTargets = event.EventSlot[i].target.split(',')
          // console.log(allTargets)
          allTargets.map((t) => targets.push(t.toString()))
        }
        const uniqueTargets = targets.filter((c, index) => {
          return targets.indexOf(c) === index
        })
        // console.log('targets: ' + uniqueTargets)
        return uniqueTargets
      }
    },
    getTargetSchool(event) {
      const uniqueTargets = this.getTargets(event)
      const resultSklArray = []
      let uniqueSkl = []
      uniqueTargets.map((u) => {
        if (u.toString().includes('k')) {
          resultSklArray.push(this.objects.types[0].name)
        }
        if (u.toString().includes('p')) {
          resultSklArray.push(this.objects.types[1].name)
        }
        if (u.toString().includes('f')) {
          resultSklArray.push(this.objects.types[2].name)
        }
        /* if (u.toString().includes('n')) {
          resultSklArray.push(this.objects.types[3].forms[0].name)
        } */
        uniqueSkl = resultSklArray.filter((c, index) => {
          return resultSklArray.indexOf(c) === index
        })
      })
      // console.log('uniqueSkl ' + uniqueSkl)
      return uniqueSkl
    },
  },
}
</script>
<style lang="scss" scoped>
.leftcol {
  padding-left: 0;
  display: flex;
  align-items: center;
}
.calendar-card {
  padding: 10px 5px;
  width: 100%;
  margin: 0px;
}
.calendar-card-selected {
  padding: 10px 5px;
  width: 100%;
  margin: 0px;
  background-color: whitesmoke;
}
.schemeLink {
  color: #3f3f3f;
}
.icon-family {
  width: 30px;
  height: 30px;
}
.card-size {
  width: 100%;
}
.card {
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-style: none;
  margin: 20px 10px;
  height: 350px;
  overflow: hidden;
}
/* .card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
} */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-img {
    height: auto;
    width: 100%;
    border-radius: 8px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
.card-tags {
  position: absolute;
  height: 170px;
  z-index: 2;
  right: 0;
  top: 0;
  font-size: 80%;
  padding: 10px;
  font-weight: 500;
}
.card-tag {
  padding: 2px 15px;
  border-radius: 100px;
}
.card-tags > .card-category {
  background: #fd6da9;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
}
.card-tags > .card-family {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 0;
  bottom: 20px;
  margin: 30px;
}
.card-tags > .card-family > img {
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 50%;
}
.card-tags > .card-code {
  bottom: 0;
  background: white;
  position: absolute;
  right: 0px;
  margin: 10px;
}
.card-body-target {
  height: 36px;
  overflow: hidden;
}
.card-target {
  border-radius: 5px;
  background-color: #e9f9f8;
  font-size: 90%;
  color: #1fbcb4;
  display: inline-block;
  padding: 0.1em 0.5em;
  margin: 0 0.5em 0.5em 0.5em;
  position: relative;
  left: -0.5em;
}
.card-body {
  position: absolute;
  width: 100%;
  height: 180px;
  bottom: 0;
  padding: 15px;
  text-align: left;
  z-index: 2;
  background: white;
}
.card-body-header {
  position: relative;
}
.card-date {
  font-size: 100%;
  color: #999999;
  position: absolute;
  bottom: 5px;
  // height: 20px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-scheme-title {
  font-size: 90%;
  color: #ff739d;
}
.card-time {
  font-size: 90%;
  color: #999999;
  margin-bottom: 0;
}
.cards-box-content-grid .icon_bookmark {
  position: absolute;
  z-index: 10;
  right: 0px;
  top: 0;
}
.card-text {
  color: #3f3f3f !important;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  height: 2.4em;
  font-size: 120%;
  line-height: 1.2;
}
.card-text:after {
  content: '';
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  height: 1.2em;
  line-height: 1.2;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .calendar-card,
  .calendar-card-selected {
    width: 480px;
    margin: 0px auto;
  }
}
</style>
