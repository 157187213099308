<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="10">
      <div class="form">
        <b-form method="post" @submit.prevent="submit">
          <div>
            <div class="shadow my-3 mse-c1">
              <b-button variant="outline-success" @click="addTab">{{
                $t('scheme.add_tab')
              }}</b-button>

              <b-tabs content-class="mt-3">
                <b-tab
                  v-for="(tab, counter) in applyNotes"
                  :key="counter"
                  class="tab"
                >
                  <template #title>
                    {{ i18nTitle(tab) }}
                    <b-button
                      size="sm"
                      variant="danger"
                      style="border-radius: 500%"
                      @click.prevent="deleteTab(counter)"
                    >
                      ×
                    </b-button>
                  </template>
                  <b-form-group id="tabs" label-for="tabs">
                    <b-tabs content-class="mt-3">
                      <b-tab :title="$t('g.en')">
                        <p class="label">{{ $t('tab.title.en') }}</p>
                        <b-form-input
                          v-model="tab.title_en"
                          :placeholder="$t('placeholder.title.en')"
                          type="text"
                          trim
                        ></b-form-input>
                        <p class="label">{{ $t('tab.content.en') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_en_' + counter"
                          :value="tab.content_en"
                          @writeContent="getTabContent"
                        />
                      </b-tab>
                      <b-tab :title="$t('g.tc')">
                        <p class="label">{{ $t('tab.title.tc') }}</p>
                        <b-form-input
                          v-model="tab.title_tc"
                          :placeholder="$t('placeholder.title.tc')"
                          type="text"
                          trim
                        ></b-form-input>
                        <p class="label">{{ $t('tab.content.tc') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_tc_' + counter"
                          :value="tab.content_tc"
                          @writeContent="getTabContent"
                        />
                      </b-tab>
                      <b-tab :title="$t('g.sc')">
                        <p class="label">{{ $t('tab.title.sc') }}</p>
                        <b-form-input
                          v-model="tab.title_sc"
                          :placeholder="$t('placeholder.title.sc')"
                          type="text"
                          trim
                        ></b-form-input>
                        <p class="label">{{ $t('tab.content.sc') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_sc_' + counter"
                          :value="tab.content_sc"
                          @writeContent="getTabContent"
                      /></b-tab>
                    </b-tabs>
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </div>
          </div>

          <Notification v-if="error" :message="error" />
          <div>
            <b-button
              id="submit"
              type="submit"
              variant="outline-success"
              :disabled="btn_disabled"
              >{{ $t('action.submit') }}
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import TinymceEditor from '~/components/admin/tinymceEditor'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    TinymceEditor,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      if (this.action === 'edit') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}schemes/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.slug = data.slug
        this.schemeType = data.schemeType
        this.parent = data.parent
        this.title_en = data.title_en
        this.title_tc = data.title_tc
        this.title_sc = data.title_sc
        this.content_en = data.content_en
        this.content_tc = data.content_tc
        this.content_sc = data.content_sc
        this.img = data.img
        this.yt_link = data.yt_link
        this.reg_fields[0].startDate = this.$moment(
          data.reg_round1_start
        ).format('yyyy-MM-DD')
        this.reg_fields[0].endDate = this.$moment(data.reg_round1_end).format(
          'yyyy-MM-DD'
        )
        this.reg_fields[1].startDate = this.$moment(
          data.reg_round2_start
        ).format('yyyy-MM-DD')
        this.reg_fields[1].endDate = this.$moment(data.reg_round2_end).format(
          'yyyy-MM-DD'
        )
        this.reg_fields[2].startDate = this.$moment(
          data.reg_round3_start
        ).format('yyyy-MM-DD')
        this.reg_fields[2].endDate = this.$moment(data.reg_round3_end).format(
          'yyyy-MM-DD'
        )
        this.reg_fields[3].startDate = this.$moment(
          data.reg_round4_start
        ).format('yyyy-MM-DD')
        this.reg_fields[3].endDate = this.$moment(data.reg_round4_end).format(
          'yyyy-MM-DD'
        )
        this.banner = data.banner
        this.parentIcon = data.parentIcon
          ? JSON.parse(data.parentIcon)
          : this.parentIcon
        this.starIcon = data.starIcon
          ? JSON.parse(data.starIcon)
          : this.starIcon
        // this.web_portal = data.web_portal
        this.disabled = data.disabled
        this.isFinish = data.isFinish
        if (data.tabs !== null || data.tabs !== undefined)
          this.tabs = JSON.parse(data.tabs)
        if (!this.tabs) this.tabs = []
        this.year = data.year
        if (data.applyNotes !== null || data.applyNotes !== undefined)
          this.applyNotes = JSON.parse(data.applyNotes)
        if (!this.applyNotes) this.applyNotes = []
        this.needCategory = data.parent
          ? this.haveCat(data.parent)
          : data.needCategory
        this.customizeLastModify = data.customizeLastModify
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      btn_disabled: false,
      schemeType: '',
      schemeTypeList: [],
      schemeList: [{ value: null, text: 'No Parent' }],
      // schemeList: [{ value: '1', text: '學校文化日計劃' }],
      reg_round: [
        { key: 'round', label: this.$t('scheme.round.title') },
        { key: 'startRegDate', label: this.$t('scheme.regStartDate') },
        { key: 'endRegDate', label: this.$t('scheme.regDeadline') },
      ],
      reg_fields: [
        {
          round: this.$t('scheme.round.round1'),
          startDate: null,
          endDate: null,
        },
        {
          round: this.$t('scheme.round.round2'),
          startDate: null,
          endDate: null,
        },
        {
          round: this.$t('scheme.round.round3'),
          startDate: null,
          endDate: null,
        },
        {
          round: this.$t('scheme.round.round4'),
          startDate: null,
          endDate: null,
        },
      ],
      parentIcon: {
        img: '',
        popover: {
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
      },
      starIcon: {
        img: '',
        popover: {
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
      },
      parent: null,
      id: null,
      slug: '',
      title_en: '',
      title_tc: '',
      title_sc: '',
      content_en: '',
      content_tc: '',
      content_sc: '',
      img: '',
      yt_link: '',
      banner: '',
      tabs: [],
      // web_portal: 0,
      disabled: 0,
      isFinish: 0,
      year: null,
      applyNotes: [],
      needCategory: 0,
      customizeLastModify: null,
      error: null,
    }
  },
  computed: {
    slugState() {
      const format = /^[a-z0-9]+$/
      // console.log(format.test(this.slug), this.slug)
      return format.test(this.slug)
    },
    // hard code: scds/spaps/aes/aoa
    web_portal() {
      if (
        this.id === 1 ||
        this.id === 3 ||
        this.id === 4 ||
        this.id === 5 ||
        this.parent === 1 ||
        this.parent === 3 ||
        this.parent === 4 ||
        this.parent === 5
      )
        return 1
      else return 0
    },
    isSubScheme() {
      return this.parent
    },
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      tc.push(this.parentIcon.popover.content_tc)
      tc.push(this.starIcon.popover.content_tc)
      if (this.applyNotes.length > 0) {
        for (let i = 0; i < this.applyNotes.length; i++) {
          tc.push({
            title: this.applyNotes[i].title_tc,
            content: this.applyNotes[i].content_tc,
          })
        }
      }
      return tc
    },
  },
  methods: {
    slugformatter(value) {
      return value.toLowerCase()
    },
    isPortalScheme() {
      return (
        this.slug.includes('scds') ||
        this.slug.includes('aoa') ||
        this.slug.includes('spaps') ||
        this.slug.includes('aes')
      )
    },
    haveCat(id) {
      const result = this.schemeList.find((s) => s.value === parseInt(id))
      // console.log('result:', result)
      return result ? result.needCategory : false
    },
    setSc(data) {
      // console.log(data)
      this.title_sc = data[0]
      this.content_sc = data[1]
      this.parentIcon.popover.content_sc = data[2]
      this.starIcon.popover.content_sc = data[3]
      if (this.applyNotes.length > 0) {
        for (let i = 0; i <= this.applyNotes.length; i++) {
          if (data[i + 4]) {
            this.applyNotes[i].title_sc = data[i + 4].title
            this.applyNotes[i].content_sc = data[i + 4].content
          }
        }
      }
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    getTabContent(evt, id, content) {
      if (id.startsWith('tab_editor')) {
        const tmp = id.split('_')
        this.applyNotes[tmp[3]]['content_' + tmp[2]] = content
      } else {
        this[id] = content
      }
    },
    async uploadImage(e, target) {
      try {
        const params = new FormData()
        await // console.log(e.target)
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            if (target && target === 'parentIcon')
              this.parentIcon.img = result.data.location
            else if (target && target === 'starIcon')
              this.starIcon.img = result.data.location
            else this[e.target.id] = result.data.location
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    async submit(e) {
      try {
        const regDate = this.reg_fields
        const today = this.$moment(new Date()).format('yyyy-MM-DD')
        if (!this.title_en || !this.title_tc || !this.title_sc) {
          this.error = 'error.title cannot be empty'
          return
        }
        if (
          !regDate[0] &&
          (regDate[0].startDate <= today ||
            regDate[0].endDate <= regDate[0].startDate ||
            regDate[1].startDate <= regDate[0].endDate ||
            regDate[1].endDate <= regDate[1].startDate ||
            regDate[2].startDate <= regDate[1].endDate ||
            regDate[2].endDate <= regDate[2].startDate ||
            regDate[3].startDate <= regDate[2].endDate ||
            regDate[3].endDate <= regDate[3].startDate)
        ) {
          this.error = 'error.Registration Date Error'
          return
        }
        let result
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}/schemes/update`,
            {
              id: this.id,
              slug: this.slug.toLowerCase(),
              parent: this.parent,
              schemeType: this.schemeType,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              banner: this.banner,
              yt_link: this.yt_link,
              reg_round1_start:
                this.reg_fields[0].startDate !== 'Invalid date'
                  ? this.reg_fields[0].startDate
                  : null,
              reg_round1_end:
                this.reg_fields[0].endDate !== 'Invalid date'
                  ? this.reg_fields[0].endDate
                  : null,
              reg_round2_start:
                this.reg_fields[1].startDate !== 'Invalid date'
                  ? this.reg_fields[1].startDate
                  : null,
              reg_round2_end:
                this.reg_fields[1].endDate !== 'Invalid date'
                  ? this.reg_fields[1].endDate
                  : null,
              reg_round3_start:
                this.reg_fields[2].startDate !== 'Invalid date'
                  ? this.reg_fields[2].startDate
                  : null,
              reg_round3_end:
                this.reg_fields[2].endDate !== 'Invalid date'
                  ? this.reg_fields[2].endDate
                  : null,
              reg_round4_start:
                this.reg_fields[3].startDate !== 'Invalid date'
                  ? this.reg_fields[3].startDate
                  : null,
              reg_round4_end:
                this.reg_fields[3].endDate !== 'Invalid date'
                  ? this.reg_fields[3].endDate
                  : null,
              tabs: this.tabs.length > 0 ? this.tabs : null,
              parentIcon: JSON.stringify(this.parentIcon),
              starIcon: JSON.stringify(this.starIcon),
              web_portal: this.web_portal,
              disabled: this.disabled,
              isFinish: this.isFinish,
              year: this.year,
              applyNotes: this.applyNotes.length > 0 ? this.applyNotes : null,
              needCategory: this.parent
                ? this.haveCat(this.parent)
                : this.needCategory,
              customizeLastModify: this.customizeLastModify,
            }
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}/schemes/add`,
            {
              slug: this.slug.toLowerCase(),
              parent: this.parent,
              schemeType: this.schemeType,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              banner: this.banner,
              yt_link: this.yt_link,
              reg_round1_start:
                this.reg_fields[0].startDate !== 'Invalid date'
                  ? this.reg_fields[0].startDate
                  : null,
              reg_round1_end:
                this.reg_fields[0].endDate !== 'Invalid date'
                  ? this.reg_fields[0].endDate
                  : null,
              reg_round2_start:
                this.reg_fields[1].startDate !== 'Invalid date'
                  ? this.reg_fields[1].startDate
                  : null,
              reg_round2_end:
                this.reg_fields[1].endDate !== 'Invalid date'
                  ? this.reg_fields[1].endDate
                  : null,
              reg_round3_start:
                this.reg_fields[2].startDate !== 'Invalid date'
                  ? this.reg_fields[2].startDate
                  : null,
              reg_round3_end:
                this.reg_fields[2].endDate !== 'Invalid date'
                  ? this.reg_fields[2].endDate
                  : null,
              reg_round4_start:
                this.reg_fields[3].startDate !== 'Invalid date'
                  ? this.reg_fields[3].startDate
                  : null,
              reg_round4_end:
                this.reg_fields[3].endDate !== 'Invalid date'
                  ? this.reg_fields[3].endDate
                  : null,
              tabs: this.tabs.length > 0 ? this.tabs : null,
              parentIcon: JSON.stringify(this.parentIcon),
              starIcon: JSON.stringify(this.starIcon),
              web_portal: this.web_portal,
              disabled: this.disabled,
              isFinish: this.isFinish,
              year: this.year,
              applyNotes:
                this.applyNotes.length > 0
                  ? JSON.stringify(this.applyNotes)
                  : null,
            }
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.go(-1)
          // this.$router.push(this.localePath('/admin/scheme'))
        } else if (this.action === 'add') {
          this.btn_disabled = true
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    addTab() {
      this.applyNotes.push({
        title_en: '',
        title_tc: '',
        title_sc: '',
        content_en: '',
        content_tc: '',
        content_sc: '',
      })
    },
    deleteTab(counter) {
      this.applyNotes.splice(counter, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
.tab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.component {
  text-align: left;
}
.btn {
  margin-bottom: 5px;
}
.ie-hint {
  color: red;
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-hint {
    display: block;
    padding-bottom: 10px;
  }
}

.mse-c1 {
  padding: 30px;
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .mse-c1 {
    padding: 10px;
  }
  .shadow {
    padding: 12px;
    margin: 30px -15px;
  }
}
</style>
<!--[if IE]>
  <style scoped>
    .ie-hint {
      display: block;
    }
  </style>
<![endif]-->
