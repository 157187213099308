<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="8">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <b-form-group v-if="action != 'send'" id="code" label-for="code">
            {{ $t('smsTemplate.code') }}
            <b-form-input
              v-model="code"
              type="text"
              :readonly="action !== 'add'"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="to" label-for="to">
            {{ $t('smsTemplate.to') }}
            <b-form-input v-model="receiver" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="content" label-for="content">
            {{ $t('smsTemplate.content') }}
            <b-textarea v-model="content" maxlength="603"></b-textarea>
            <p>
              {{ content.length + '/' + '603' }}.
              <span>{{ calSMS() }}</span> SMS required.
            </p>
          </b-form-group>

          <b-form-group id="testto" label-for="testto">
            {{ $t('smsTemplate.testto') }}
            <b-row>
              <b-col cols="8">
                <b-form-input v-model="testReceiver" type="text"></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button variant="outline-info" @click="sendTestMail">{{
                  $t('smsTemplate.testmail')
                }}</b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
/* import tools from '~/components/common/tools' */

export default {
  components: {
    Notification,
    /* tools, */
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    action: { type: String, default: 'add' },
    trnId: { type: String, default: '' },
    tempCode: { type: String, default: '' },
    custom: { type: Object, default: null },
    // isShow: { type: Boolean, default: false },
  },
  async fetch() {
    try {
      if (this.action === 'edit' || this.action === 'send') {
        if (this.action === 'edit') {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}smsTemplate/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.id = data.id
          this.code = data.code
          this.receiver = data.receiver
          this.content = data.content
        } else if (this.action === 'send') {
          const template = await this.$axios.get(
            `${process.env.cloudApiUrl}smsTemplate/template/${this.tempCode}`
          )
          const result = await this.$axios.post(
            `${process.env.localApiUrl}smsTemplate/generate`,
            {
              trnId: this.trnId,
              code: this.tempCode,
              template: template.data.data[0],
              custom: this.custom,
            }
          )
          this.userId = result.data.data.userId
          this.schoolId = result.data.data.schoolId
          const data = result.data.data.template
          this.receiver = data.receiver
          this.cc = data.cc
          this.bcc = data.bcc
          this.subject = data.subject
          this.content = data.content
          this.focusTextEditor()
        }
      } else {
        this.isShow = true
      }
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      code: '',
      receiver: '',
      content: '',
      schoolId: null,
      userId: null,
      testReceiver: '',
      orders: null,
      error: null,
      isShow: false,
    }
  },
  computed: {},
  mounted() {},
  methods: {
    calSMS() {
      const limit = [0, 70, 134, 201, 268, 335, 402, 469, 536, 603, 99999999999]
      const len = this.content.length
      if (len === 0) return 0
      for (let i = 0; i < limit.length; i++) {
        if (len >= limit[i] && len <= limit[i + 1]) {
          return i + 1
        }
      }
      return 'cannot delivery'
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async submit() {
      try {
        let result
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.receiver,
          content: this.content,
        }
        if (this.action === 'send') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}smsTemplate/send`,
            {
              template: obj,
              schoolId: this.schoolId,
              userId: this.schoolId,
            }
          )
        } else if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}smsTemplate/update`,
            obj
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}smsTemplate/add`,
            obj
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/smsTemplate'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async sendTestMail() {
      try {
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.testReceiver,
          content: this.content,
        }
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}smsTemplate/send`,
          {
            template: obj,
            userId: 0,
            schoolId: 0,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `test mail has been sent`,
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'OK',
        })
        if (back.value) {
          // this.$router.push(this.localePath('/admin/smsTemplate'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}

h1::first-letter {
  text-transform: capitalize;
}

.content_textarea {
  height: 200px;
}

.component {
  text-align: left;
}

.submit {
  margin-bottom: 20px;
}

.form-group {
  margin-top: 5px;
}
</style>
