<template>
  <fab
    :position="position"
    :bg-color="bgColor"
    :actions="fabActions"
    @tc2sc="tc2sc"
  ></fab>
</template>
<script>
import fab from 'vue-fab'

export default {
  name: 'Tools',
  components: {
    fab,
  },
  props: {
    tc: { type: Array },
  },
  data() {
    return {
      bgColor: '#1cbcb4',
      position: 'top-right',
      positionType: 'absolute',
      mainIcon: 'add',
      fabActions: [
        {
          name: 'tc2sc',
          icon: 'translate',
        },
      ],
      resultData: [],
    }
  },
  watch: {
    tc: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    async tc2sc() {
      // https://api.dev.abo.hkrnd.com/api/v1/translate
      try {
        const target = JSON.stringify(this.tc)
        const result = await this.$axios.post(`translate`, {
          msg: target,
        })
        const data = JSON.parse(result.data.data)
        this.error = ''
        if (result.error !== undefined) throw new Error(result.error)
        this.$emit('writeContent', data)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style scoped>
/* fallback */
@import url(https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
  user-select: none;
}
</style>
<style>
.fab-main .material-icons.main,
.fab-main .material-icons.close {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.fab-wrapper {
  top: 20vh !important;
  z-index: 9 !important;
}
</style>
