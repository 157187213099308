<template>
  <div>
    <h3>{{ title }}</h3>
    <br />
    <div>
      <label for="schoolNameChi">
        <div class="required">
          <div>學校名稱（中文）</div>
          <span class="sr-only">必須提供</span>
        </div>
        <b-form-input
          v-model="schData.name_tc"
          type="text"
          name="schoolNameChi"
          class="form-control"
          maxlength="1000"
          style="width: 704px"
        />
      </label>
    </div>
    <div id="schoolNameEng_panel">
      <label for="schoolNameEng">
        <div id="schoolNameEng_label" class="required">
          <div>學校名稱（英文）</div>
          <span class="sr-only">必須提供</span>
        </div>
        <b-form-input
          v-model="schData.name_en"
          type="text"
          name="schoolNameEng"
          class="form-control"
          maxlength="1000"
          style="width: 704px"
        />
      </label>
    </div>
    <div id="fieldGroup_6_panel">
      <div class="">
        <div id="fieldGroup_6_label" class="required">
          <div>學校地址</div>
          <span class="sr-only">必須提供</span>
        </div>
      </div>
      <div>
        <div id="schoolAddressL1_panel">
          <label for="schoolAddressL1">
            <div id="schoolAddressL1_label" class="sr-only">
              <div class="control-label">(1) 地區</div>
            </div>
            <b-form-input
              v-model="schData.address_area"
              type="text"
              name="schoolAddressL1"
              class="form-control"
              placeholder="(1) 地區"
              maxlength="120"
              style="width: 704px"
            />
          </label>
        </div>
        <div id="schoolAddressL2_panel">
          <label for="schoolAddressL2">
            <div id="schoolAddressL2_label" class="sr-only">
              <div class="control-label">(2) 街道及號碼</div>
            </div>
            <b-form-input
              v-model="schData.address_street"
              type="text"
              name="schoolAddressL2"
              class="form-control"
              placeholder="(2) 街道及號碼"
              maxlength="120"
              style="width: 704px"
          /></label>
        </div>
        <div id="schoolAddressL3_panel">
          <label for="schoolAddressL3">
            <div id="schoolAddressL3_label" class="sr-only">
              <div class="control-label">(3) 建築物名稱、樓層、單位(如有)</div>
            </div>
            <b-form-input
              v-model="schData.address_building"
              type="text"
              name="schoolAddressL3"
              class="form-control"
              placeholder="(3) 建築物名稱、樓層、單位(如有)"
              maxlength="120"
              style="width: 704px"
            />
          </label>
        </div>
      </div>
    </div>
    <div id="fieldGroup_12_panel">
      <div class="">
        <div id="fieldGroup_12_label" class="required">
          <div>學校所屬區域</div>
          <span class="sr-only">必須提供</span>
        </div>
      </div>
      <div style="display: flex">
        <div id="schoolRegion_panel">
          <label for="schoolRegion">
            <div id="schoolRegion_label" class="required sr-only">
              <div>學校所屬區域</div>
              <span class="sr-only">必須提供</span>
            </div>
            <b-form-radio-group
              v-model="schData.region"
              :options="regionList"
              class="mb-3"
              value-field="value"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-radio-group>
          </label>
        </div>
        <div id="schoolDistrict_panel">
          <label for="schoolDistrict">
            <div id="schoolDistrict_label" class="required sr-only">
              <div>區域</div>
              <span class="sr-only">必須提供</span>
            </div>
            <b-form-select
              v-model="schData.district"
              :options="districtList"
              class="mb-3"
              value-field="value"
              text-field="name"
              disabled-field="disabled"
            >
            </b-form-select>
          </label>
        </div>
      </div>
    </div>
    <div id="schoolLevel_panel">
      <label for="schoolLevel">
        <div id="schoolLevel_label" class="required">
          <div>學校類別</div>
          <span class="sr-only">必須提供</span>
        </div>
        <b-form-radio-group
          v-model="schData.category"
          :options="typeList"
          class="mb-3"
          value-field="value"
          text-field="name"
          disabled-field="notEnabled"
        >
        </b-form-radio-group>
      </label>
    </div>
    <div class="layout-default layout-group layout-inline-group">
      <div id="schoolTel_panel" class="field form-group layout-inline">
        <label for="schoolTel">
          <div id="schoolTel_label" class="required">
            <div>學校電話</div>
            <span class="sr-only">必須提供</span>
          </div>
          <b-form-input
            v-model="schData.tel"
            type="tel"
            name="schoolTel"
            class="form-control"
            maxlength="1000"
            pattern="[0-9]*"
            inputmode="numeric"
            style="width: 194px"
          />
        </label>
      </div>
      <div id="schoolFax_panel" class="field form-group layout-inline">
        <label for="schoolFax">
          <div id="schoolFax_label" class="required">
            <div>學校傳真 (如無法提供傳真號碼，請輸入00000000)</div>
            <span class="sr-only">必須提供</span>
          </div>
          <b-form-input
            v-model="schData.fax"
            type="tel"
            name="schoolFax"
            class="form-control"
            maxlength="1000"
            pattern="[0-9]*"
            inputmode="numeric"
            style="width: 194px"
          />
        </label>
      </div>
    </div>
    <div id="schoolEmail_panel">
      <label for="schoolEmail">
        <div id="schoolEmail_label" class="required">
          <div>學校電郵</div>
          <span class="sr-only">必須提供</span>
        </div>
        <b-form-input
          v-model="schData.email"
          type="email"
          name="schoolEmail"
          class="form-control"
          maxlength="512"
          style="width: 449px"
        />
      </label>
    </div>
    <div id="schoolPrincipal_panel">
      <label for="schoolPrincipal">
        <div id="schoolPrincipal_label" class="required">
          <div>校長姓名及稱謂</div>
          <span class="sr-only">必須提供</span>
        </div>
        <b-form-input
          v-model="schData.principal_name"
          type="text"
          name="schoolPrincipal"
          class="form-control"
          maxlength="1000"
          style="width: 449px"
        />
      </label>
    </div>
    <div v-for="(teacher, index) in teachers" :key="index">
      <fieldset class="topLeftInsideLegend">
        <legend><span class="sr-only">行</span># {{ index + 1 }}</legend>
        <div>
          <div>
            <label for="teacher.0.ticName">
              <div id="teacher.0.ticName_label" class="required">
                <div>負責老師姓名及稱謂</div>
                <span class="sr-only">必須提供</span>
              </div>
              <b-form-input
                v-model="teacher.name"
                type="text"
                name="teacher.0.ticName"
                class="form-control"
                maxlength="1000"
                style="width: 449px"
              />
            </label>
          </div>
          <div class="layout-default layout-group layout-inline-group">
            <div
              id="teacher.0.ticPhone_panel"
              class="field form-group layout-inline"
            >
              <label for="teacher.0.ticPhone">
                <div id="teacher.0.ticPhone_label" class="required">
                  <div>聯絡/手提電話</div>
                  <span class="sr-only">必須提供</span>
                </div>
                <b-form-input
                  v-model="teacher.tel"
                  type="tel"
                  name="teacher.0.ticPhone"
                  class="form-control"
                  maxlength="1000"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  style="width: 194px"
                />
              </label>
            </div>
            <div
              id="teacher.0.ticPhoneExt_panel"
              class="field form-group layout-inline"
            >
              <label for="teacher.0.ticPhoneExt">
                <div id="teacher.0.ticPhoneExt_label" class="">
                  <div class="control-label">學校內線(如適用)</div>
                </div>
                <input
                  v-model="teacher.telExt"
                  type="tel"
                  name="teacher.0.ticPhoneExt"
                  class="form-control"
                  maxlength="5"
                  pattern="[0-9]*"
                  inputmode="numeric"
                />
              </label>
            </div>
          </div>
          <div id="teacher.0.ticEmail_panel">
            <label for="teacher.0.ticEmail">
              <div id="teacher.0.ticEmail_label" class="required">
                <div>聯絡電郵</div>
                <span class="sr-only">必須提供</span>
              </div>
              <b-form-input
                v-model="teacher.email"
                type="email"
                name="teacher.0.ticEmail"
                class="form-control"
                maxlength="512"
                style="width: 449px"
              />
            </label>
          </div>
        </div>
      </fieldset>
      <div class="fieldListButtons">
        <b-button
          v-if="teachers.length > 1"
          type="button"
          variant="outline-danger"
          size="sm"
          class="mb-3"
          @click="delTeacher(index)"
        >
          刪除聯絡人 {{ index + 1 }}
        </b-button>
      </div>
    </div>
    <div class="fieldListButtons">
      <b-button
        v-if="teachers.length < 3"
        type="button"
        variant="outline-success"
        size="sm"
        @click="addTeacher()"
      >
        新增聯絡人
      </b-button>
    </div>
    <br />
    <div id="disagreePromo_panel">
      <label for="disagreePromo">
        <div class="checkbox checkbox-single">
          <label for="disagreePromo">
            <b-form-checkbox
              v-model="disagreePromo"
              type="checkbox"
              name="disagreePromo"
              size="sm"
            >
              本校
              <strong><u>不同意</u></strong>
              康樂及文化事務署日後透過所提供的郵寄地址、傳真號碼及電郵地址發放節目宣傳資料予本校參閱。<br />
              <strong>
                (如選擇此項，學校將<u>不能</u>接收新增活動的資料。)
              </strong>
            </b-form-checkbox>
          </label>
        </div>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '2. 申請學校資料',
    },
    promo: {
      type: Boolean,
      default: false,
    },
    teacherData: {
      type: Array,
      default: () => [{}],
    },
    schoolData: {
      type: Object,
      default: () => {
        return {
          name_tc: null,
          name_en: null,
          category: null,
          region: null,
          district: 0,
          address_area: null,
          address_street: null,
          address_building: null,
          tel: null,
          fax: null,
          email: null,
          principal_name: null,
        }
      },
    },
  },
  data() {
    return {
      schData: this.schoolData,
      teachers: this.teacherData,
      disagreePromo: this.promo,
      typeList: [
        { value: 1, name: '幼稚園' },
        { value: 2, name: '小學' },
        { value: 3, name: '中學' },
        { value: 4, name: '特殊學校' },
        { value: 5, name: '其他（請註明）' },
      ],
      regionList: [
        { value: 'hk', name: '香港島' },
        { value: 'kln', name: '九龍區' },
        { value: 'nt', name: '新界區' },
      ],
      districtList: [
        {
          value: 0,
          name: this.$t('school.districtList.select'),
          disabled: true,
        },
        { value: 1, name: '中西區 Central & Western District' },
        { value: 2, name: '東區 Eastern District' },
        { value: 3, name: '南區 Southern District' },
        { value: 4, name: '灣仔區 Wanchai District' },
        { value: 5, name: '九龍城區 Kowloon City District' },
        { value: 6, name: '觀塘區 Kuwn Tong District' },
        { value: 7, name: '深水埗區 Sham Shui Po District' },
        { value: 8, name: '黃大仙區 Wong Tai Sin District' },
        { value: 9, name: '油尖旺區 Yau Tsim Mong District' },
        { value: 10, name: '離島區 Islands District' },
        { value: 11, name: '葵青區 Kwai Tsing District' },
        { value: 12, name: '北區 North District' },
        { value: 13, name: '西貢區 Sai Kung District' },
        { value: 14, name: '沙田區 Shatin District' },
        { value: 15, name: '大埔區 Tai Po District' },
        { value: 16, name: '荃灣區 Tsuen Wan District' },
        { value: 17, name: '屯門區 Tuen Mun District' },
        { value: 18, name: '元朗區 Yuen Long District' },
      ],
    }
  },
  computed: {},
  watch: {
    promo: {
      handler(newVal, oldVal) {
        this.disagreePromo = newVal
      },
      deep: true,
    },
    schoolData: {
      handler(newVal, oldVal) {
        this.schData = newVal
      },
      deep: true,
    },
    teacherData: {
      handler(newVal, oldVal) {
        this.teachers = newVal
      },
      deep: true,
    },
    schData: {
      handler(newVal, oldVal) {
        this.emitData()
      },
      deep: true,
    },
    teachers: {
      handler(newVal, oldVal) {
        this.emitData()
      },
      deep: true,
    },
    disagreePromo: {
      handler(newVal, oldVal) {
        this.emitData()
      },
      deep: true,
    },
  },
  methods: {
    emitData() {
      this.$emit('change', this.schData, this.teachers, this.disagreePromo)
    },
    addTeacher() {
      this.teachers.push({})
    },
    delTeacher(index) {
      this.teachers.splice(index, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
fieldset.topLeftInsideLegend legend {
  float: left;
  width: auto;
  display: block;
  margin: 0 100% 10px 0;
  padding: 0 10px;
  background: #f5f5f5;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 5px 0;
  font-weight: 700;
  font-size: 12px;
  color: #1cbcb4;
  white-space: normal;
}
fieldset.topLeftInsideLegend {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 0;
  margin-bottom: 10px;
}
fieldset.topLeftInsideLegend > div {
  padding: 0 20px;
}
</style>
