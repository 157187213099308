var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.edit
    ? _c(
        "b-row",
        {
          staticStyle: { width: "50%", display: "inline-flex", float: "right" },
        },
        [
          _c(
            "b-col",
            [
              _vm.lang == 0
                ? _c("medium-editor", {
                    staticClass: "medium-editor",
                    attrs: { text: _vm.value.text.en, options: _vm.options_en },
                    on: {
                      edit: (operation) => {
                        _vm.value.text.en = operation.api.origElements.innerHTML
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.lang == 1
                ? _c("medium-editor", {
                    staticClass: "medium-editor",
                    attrs: { text: _vm.value.text.tc, options: _vm.options_tc },
                    on: {
                      edit: (operation) => {
                        _vm.value.text.tc = operation.api.origElements.innerHTML
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.lang == 2
                ? _c("medium-editor", {
                    staticClass: "medium-editor",
                    attrs: { text: _vm.value.text.sc, options: _vm.options_sc },
                    on: {
                      edit: (operation) => {
                        _vm.value.text.sc = operation.api.origElements.innerHTML
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c("span", {
        domProps: { innerHTML: _vm._s(_vm.i18nText(_vm.value.text)) },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }