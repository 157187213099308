var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("p", [_vm._v(_vm._s(_vm.$t("config.type")))]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "type", "label-for": "type" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "type",
                        type: "text",
                        required: "",
                        readonly: "",
                        trim: "",
                      },
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.value, function (item, index) {
                  return _c(
                    "b-form-group",
                    { key: index },
                    [
                      _c("h3", [_vm._v("網站 " + _vm._s(index + 1))]),
                      _vm._v(" "),
                      _c(
                        "b-tabs",
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("config.content.en") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "標題(英)",
                                  trim: "",
                                },
                                model: {
                                  value: item.title_en,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title_en", $$v)
                                  },
                                  expression: "item.title_en",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "網址(英)",
                                  trim: "",
                                },
                                model: {
                                  value: item.url_en,
                                  callback: function ($$v) {
                                    _vm.$set(item, "url_en", $$v)
                                  },
                                  expression: "item.url_en",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("config.content.tc") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "標題(繁)",
                                  trim: "",
                                },
                                model: {
                                  value: item.title_tc,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title_tc", $$v)
                                  },
                                  expression: "item.title_tc",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "網址(繁)",
                                  trim: "",
                                },
                                model: {
                                  value: item.url_tc,
                                  callback: function ($$v) {
                                    _vm.$set(item, "url_tc", $$v)
                                  },
                                  expression: "item.url_tc",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("config.content.sc") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "標題(簡)",
                                  trim: "",
                                },
                                model: {
                                  value: item.title_sc,
                                  callback: function ($$v) {
                                    _vm.$set(item, "title_sc", $$v)
                                  },
                                  expression: "item.title_sc",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "網址(簡)",
                                  trim: "",
                                },
                                model: {
                                  value: item.url_sc,
                                  callback: function ($$v) {
                                    _vm.$set(item, "url_sc", $$v)
                                  },
                                  expression: "item.url_sc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-3" }, [_vm._v("順序")]),
                      _vm._v(" "),
                      _c("b-form-input", {
                        attrs: { type: "text", trim: "" },
                        model: {
                          value: item.order,
                          callback: function ($$v) {
                            _vm.$set(item, "order", $$v)
                          },
                          expression: "item.order",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.delWeb(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            刪除網站 " +
                              _vm._s(index + 1) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("hr"),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass: "mt-3 mb-3",
                    attrs: { variant: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.addWeb()
                      },
                    },
                  },
                  [_vm._v("\n          新增網站\n        ")]
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }