<template>
  <b-row align-h="center" class="component">
    <b-col sm="12">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <b-form-group v-if="action != 'send'" id="code" label-for="code">
            {{ $t('mailTemplate.code') }}
            <b-form-input
              v-model="code"
              type="text"
              :readonly="action !== 'add' && action !== 'copy'"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="to" label-for="to">
            {{ $t('mailTemplate.to') }}
            <b-form-input v-model="receiver" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="cc" label-for="cc">
            {{ $t('mailTemplate.cc') }}
            <b-form-input v-model="cc" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="bcc" label-for="bcc">
            {{ $t('mailTemplate.bcc') }}
            <b-form-input v-model="bcc" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="subject" label-for="subject">
            {{ $t('mailTemplate.subject') }}
            <b-form-input v-model="subject" type="text"></b-form-input>
          </b-form-group>
          {{ $t('mailTemplate.content') }}
          <b-form-group id="content" label-for="content">
            <div>
              <tinymceEditorFlexible
                v-if="isShow"
                id="content"
                ref="tinymce"
                :value="content"
                @writeContent="getContent"
                @editorInit="(e) => e.setContent(content)"
              />
            </div>
          </b-form-group>

          <b-form-group id="testto" label-for="testto">
            {{ $t('mailTemplate.testto') }}
            <b-row>
              <b-col cols="8">
                <b-form-input v-model="testReceiver" type="text"></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button variant="outline-info" @click="sendTestMail">{{
                  $t('mailTemplate.testmail')
                }}</b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $route.name.includes('emailTemplate')
                ? $t('action.save')
                : $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <!-- <tools :tc="getTc" @writeContent="setSc"></tools> -->
  </b-row>
</template>

<script>
import $ from 'jquery'
import TinymceEditorFlexible from '~/components/admin/tinymceEditorFlexible'
import Notification from '~/components/common/notification'
/* import tools from '~/components/common/tools' */

export default {
  components: {
    Notification,
    TinymceEditorFlexible,
    /* tools, */
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    action: { type: String, default: 'add' },
    trnId: { type: String, default: '' },
    tempCode: { type: String, default: '' },
    custom: { type: Object, default: null },
    // isShow: { type: Boolean, default: false },
  },
  async fetch() {
    try {
      if (
        this.action === 'edit' ||
        this.action === 'send' ||
        this.action === 'copy'
      ) {
        if (this.action === 'edit') {
          await this.$axios
            .get(
              `${process.env.cloudApiUrl}mailTemplate/${this.$route.params.id}`
            )
            .then((result) => {
              const data = result.data.data[0]
              this.id = data.id
              this.code = data.code
              this.receiver = data.receiver
              this.cc = data.cc
              this.bcc = data.bcc
              this.subject = data.subject
              this.content = data.content
              this.focusTextEditor()
            })
        } else if (this.action === 'copy') {
          await this.$axios
            .get(
              `${process.env.cloudApiUrl}mailTemplate/${this.$route.params.id}`
            )
            .then((result) => {
              const data = result.data.data[0]
              this.id = data.id
              this.code = null
              this.receiver = data.receiver
              this.cc = data.cc
              this.bcc = data.bcc
              this.subject = data.subject
              this.content = data.content
              this.focusTextEditor()
            })
        } else if (this.action === 'send') {
          const template = await this.$axios.get(
            `${process.env.cloudApiUrl}mailTemplate/template/${this.tempCode}`
          )
          const result = await this.$axios.post(
            `${process.env.localApiUrl}mailTemplate/generate`,
            {
              trnId: this.trnId,
              code: this.tempCode,
              template: template.data.data[0],
              custom: this.custom,
            }
          )
          this.userId = result.data.data.userId
          this.schoolId = result.data.data.schoolId
          const data = result.data.data.template
          this.receiver = data.receiver
          this.cc = data.cc
          this.bcc = data.bcc
          this.subject = data.subject
          this.content = data.content
          this.focusTextEditor()
        }
      } else {
        this.isShow = true
      }
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      code: '',
      receiver: '',
      cc: '',
      bcc: '',
      subject: '',
      content: '',
      schoolId: null,
      userId: null,
      testReceiver: '',
      orders: null,
      error: null,
      isShow: false,
    }
  },
  computed: {},
  mounted() {
    if (this.action === 'edit' || this.action === 'send') {
      this.isShow = false
      $(document).on('focusin', function (e) {
        if (
          $(e.target).closest(
            '.tox-tinymce-aux, .tox-dialog, .moxman-window, .tam-assetmanager-root'
          ).length
        ) {
          e.stopImmediatePropagation()
        }
      })
    }
  },
  methods: {
    focusTextEditor() {
      this.$nextTick(() => {
        this.isShow = true
        /* console.log('emailContent', this.$refs.tinymce.$el)
        this.$refs.tinymce.$el.focus() */
      })
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async submit() {
      try {
        let result
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.receiver,
          cc: this.cc,
          bcc: this.bcc,
          subject: this.subject,
          content: this.content,
        }
        if (!this.code && !this.$route.name.includes('application')) {
          // if no code
          this.$swal({
            title: this.$t('alert.notice'),
            html: this.$t('alert.missing_code'),
            type: 'warning',
          })
        } else if (this.action === 'send') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}mailTemplate/send`,
            {
              template: obj,
              schoolId: this.schoolId,
              userId: this.userId,
            }
          )
        } else if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}mailTemplate/update`,
            obj
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}mailTemplate/add`,
            obj
          )
        }
        if (result) {
          if (result.error !== undefined) throw new Error(result.error)
          const back = await this.$swal({
            title: this.$t('alert.title'),
            text: `${this.$t('alert.content')}： ${
              this.action === 'edit'
                ? this.$t('alert.edit')
                : this.action === 'send'
                ? this.$t('alert.send')
                : this.$t('alert.add')
            }${this.$t('alert.success')}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.$t('alert.back'),
            confirmButtonColor: '#d9534f',
            cancelButtonText: 'OK',
          })
          if (back.value) {
            this.$router.push(this.localePath('/admin/emailTemplate'))
          }
        }
      } catch (err) {
        console.log('err: ', err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async sendTestMail() {
      try {
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.testReceiver,
          cc: '',
          bcc: '',
          subject: this.subject,
          content: this.content,
        }
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}mailTemplate/send`,
          {
            template: obj,
            userId: 0,
            schoolId: 0,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `test mail has been sent`,
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'OK',
        })
        if (back.value) {
          // this.$router.push(this.localePath('/admin/mailTemplate'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
