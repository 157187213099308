var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "newsmodal2",
      attrs: { id: "modal-2", scrollable: "", size: "lg" },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c("h5", { attrs: { center: "" } }, [
                _vm._v(_vm._s(_vm.$t("home.news"))),
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "modal-close-button",
                  attrs: { size: "sm" },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                },
                [_vm._v("×")]
              ),
            ]
          },
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-col",
                {
                  staticStyle: { margin: "0", padding: "0", height: "100%" },
                  attrs: { cols: "8" },
                },
                [
                  _c("div", {
                    staticStyle: { margin: "0", padding: "0" },
                    domProps: {
                      innerHTML: _vm._s(_vm.i18nContent(_vm.config)),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "showNewsPopup",
                        value: true,
                        "unchecked-value": false,
                      },
                      on: {
                        change: (checked) => {
                          _vm.$store.commit(
                            "tempStorage/storeShowNewsPopup",
                            checked
                          )
                        },
                      },
                      model: {
                        value: _vm.notShowNewsPopup,
                        callback: function ($$v) {
                          _vm.notShowNewsPopup = $$v
                        },
                        expression: "notShowNewsPopup",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("home.showNews")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticStyle: { margin: "0", padding: "0", height: "100%" } },
                [
                  _c("img", {
                    staticStyle: {
                      width: "160px",
                      height: "76px",
                      position: "absolute",
                      right: "0",
                      bottom: "0",
                    },
                    attrs: {
                      src: `${_vm.base}/img/logo_lcsd.svg`,
                      alt: "logo_lcsd",
                    },
                  }),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.getNews.length
        ? _c(
            "div",
            _vm._l(_vm.getNews, function (n) {
              return _c(
                "div",
                { key: n.id, staticClass: "b-col-12 news-item" },
                [
                  _c("div", { staticClass: "news-header" }, [
                    _c("div", [
                      _c("pre", { staticClass: "news-title" }, [
                        _vm._v(_vm._s(_vm.i18nTitle(n))),
                      ]),
                    ]),
                    _vm._v(" "),
                    n.date
                      ? _c(
                          "div",
                          { staticClass: "news-date" },
                          [
                            _c("b-icon", {
                              staticStyle: { "margin-right": "5px" },
                              attrs: { icon: "calendar2-check" },
                            }),
                            _vm._v(
                              _vm._s(_vm.$moment(n.date).format("D.M.Y")) +
                                "\n        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "news-content" }, [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.i18nContent(n)) },
                    }),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("h3", [_vm._v(_vm._s(_vm.$t("news.error")))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }