<template>
  <div>
    <div v-if="edit">
      <b-button variant="outline-success" @click="addRow">添加列</b-button>
      <b-button variant="outline-danger" @click="delRow">刪除列</b-button>
    </div>
    <!-- <div style="color: #7e7e7e; text-align: right">
      {{ $t('event.scroll_for_more') }}<b-icon icon="arrow-right" />
    </div> -->
    <div ref="tableImpl" style="overflow-x: auto">
      <table :class="!!edit ? 'table_impl' : 'table_impl fontsize-85'">
        <tbody>
          <tr v-for="(row, index) in data" :key="index">
            <td v-for="(item, i) in row" :key="i">
              <div
                v-if="!edit && i18nContent(item)"
                class="td-cont"
                v-html="i18nContent(item).replace(/(?:\r\n|\r|\n)/g, '<br />')"
              ></div>
              <!-- {{ item.content_tc }} -->

              <b-form-input
                v-if="edit && index === 0 && lang === 0"
                v-model="item.content_en"
                type="text"
                trim
                :class="'first_td'"
                @input="inputChanged"
              ></b-form-input>
              <medium-editor
                v-if="edit && index !== 0 && lang === 0"
                :text="item.content_en"
                :options="options"
                @edit="
                  (operation) => {
                    item.content_en = operation.api.origElements.innerHTML
                    inputChanged()
                  }
                "
              >
              </medium-editor>

              <b-form-input
                v-if="edit && index === 0 && lang === 1"
                v-model="item.content_tc"
                type="text"
                trim
                :class="'first_td'"
                @input="inputChanged"
              ></b-form-input>
              <medium-editor
                v-if="edit && index !== 0 && lang === 1"
                :text="item.content_tc"
                :options="options"
                @edit="
                  (operation) => {
                    item.content_tc = operation.api.origElements.innerHTML
                    inputChanged()
                  }
                "
              >
              </medium-editor>

              <b-form-input
                v-if="edit && index === 0 && lang === 2"
                v-model="item.content_sc"
                type="text"
                trim
                :class="'first_td'"
                @input="inputChanged"
              ></b-form-input>
              <medium-editor
                v-if="edit && index !== 0 && lang === 2"
                :text="item.content_sc"
                :options="options"
                @edit="
                  (operation) => {
                    item.content_sc = operation.api.origElements.innerHTML
                    inputChanged()
                  }
                "
              >
              </medium-editor>
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    implTableData: {
      type: Array,
      default: () => [],
    },
    lang: { type: Number, default: 0 },
    edit: { type: Boolean, default: false },
  },
  data() {
    return {
      myText: 'default',
      options: {
        toolbar: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'unorderedlist',
            'orderedlist',
          ],
        },
      },
      data: this.implTableData.map((a) => [...a]),
      // noOfCol: this.implTableData.length ? this.implTableData[0].length - 1 : 1, // number of column minus header(1st col)
    }
  },
  computed: {
    /* data() {
      return this.implTableData.map((a) => [...a])
    }, */
  },

  watch: {
    implTableData: {
      handler(newVal, oldVal) {
        this.data = newVal.map((a) => [...a])
      },
      deep: true,
    },
  },
  methods: {
    /* processEditOperation(operation) {
      this.myText = operation.api.origElements.innerHTML
      // console.log(this.myText)
    }, */
    inputChanged() {
      this.$emit('input', this.data)
    },
    addRow() {
      this.data.push([
        { content_en: '', content_tc: '', content_sc: '' },
        { content_en: '', content_tc: '', content_sc: '' },
        { content_en: '', content_tc: '', content_sc: '' },
      ])
      this.inputChanged()
    },
    delRow() {
      this.data.pop()
      this.inputChanged()
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nText(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.en
        case 'tc':
          return item.tc
        case 'sc':
          return item.sc
        default:
          return ''
      }
    },
  },
}
</script>
<style scoped lang="scss">
.first_td {
  background-color: #ffe086;
  border-radius: 0;
  border: none;
  color: black;
  font-weight: 500;
}
.other_td {
  background-color: #ffffde;
  border-radius: 0;
  border: none;
  color: black;
}
.table_impl {
  // border: 1px solid lightgrey;
  width: 100%;
  margin: 4px auto 25px auto;
}
.fontsize-85 {
  // font-size: 0.85em;
}
.td-cont > p {
  // margin-bottom: 0.1em;
}
.table_impl > tbody > tr > td:nth-child(1) {
  background-color: #ffe086; // #f5ddd4;
  width: 28%;
}
.table_impl > tbody > tr > td:nth-child(2) {
  width: 22%;
}
.table_impl > tbody > tr > td:nth-child(3) {
  width: 50%;
  // text-align: justify;
}
.table_impl > tbody > tr:nth-child(1) > td {
  background-color: #ffe086; // #f5ddd4;
}
.table_impl > tbody > tr > td {
  background-color: #ffffde; // #f5ddd4;
  // text-align: center;
  height: 2.3em;
  border: 1px solid lightgrey;
  padding: 10px;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .table_impl {
    width: 100%;
    margin: 4px -15px 25px 0px;
  }

  .table_impl > tbody > tr > td:nth-child(1),
  .table_impl > tbody > tr > td:nth-child(2),
  .table_impl > tbody > tr > td:nth-child(3) {
    word-break: normal;
    word-wrap: break-word;
  }
}
</style>
