var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { attrs: { cols: "5" } },
        [
          _vm.error
            ? _c("Notification", { attrs: { message: _vm.error } })
            : _vm._e(),
          _vm._v(" "),
          _c("b-card", [
            _c(
              "div",
              { staticClass: "login" },
              [
                _c("b-col", { attrs: { cols: "8" } }, [
                  _c("h3", { staticClass: "title-login" }, [
                    _vm._v(_vm._s(_vm.$t("g.title"))),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "title-login" }, [
                    _vm._v(_vm._s(_vm.$t("login.resetpw"))),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "b-form",
                  {
                    attrs: { method: "post" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.loginSubmit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { id: "email", "label-for": "email" } },
                      [
                        _c("p", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("login.email"))),
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "email",
                            type: "email",
                            required: "",
                            trim: "",
                            disabled: "",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "newPassword",
                          "label-for": "newPassword",
                        },
                      },
                      [
                        _c("p", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("login.newpwd"))),
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "newPassword",
                            type: "password",
                            required: "",
                            trim: "",
                          },
                          model: {
                            value: _vm.newPassword,
                            callback: function ($$v) {
                              _vm.newPassword = $$v
                            },
                            expression: "newPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "repeatPassword",
                          "label-for": "repeatPassword",
                        },
                      },
                      [
                        _c("p", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("login.repeatpwd"))),
                        ]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: {
                            id: "repeatPassword",
                            type: "password",
                            required: "",
                            trim: "",
                          },
                          model: {
                            value: _vm.repeatPassword,
                            callback: function ($$v) {
                              _vm.repeatPassword = $$v
                            },
                            expression: "repeatPassword",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "rules",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("account.password_rules")),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              id: "login",
                              type: "submit",
                              disabled: _vm.api && _vm.api.includes("preview"),
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("login.resetpw")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }