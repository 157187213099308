var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "profile" }, [
        _c("div", { staticClass: "usericon my-3" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.user.name.substring(0, 1)))]),
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "userName my-2" }, [
          _vm._v(_vm._s(_vm.user.name)),
        ]),
        _vm._v(" "),
        _vm.user.school
          ? _c("div", { staticClass: "schoolName my-2" }, [
              _c(
                "svg",
                {
                  staticStyle: { width: "1.3em", "vertical-align": "-0.15em" },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 640 512",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#4f4f4f",
                      d: "M0 224v272c0 8.84 7.16 16 16 16h80V192H32c-17.67 0-32 14.33-32 32zm360-48h-24v-40c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v64c0 4.42 3.58 8 8 8h48c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm137.75-63.96l-160-106.67a32.02 32.02 0 0 0-35.5 0l-160 106.67A32.002 32.002 0 0 0 128 138.66V512h128V368c0-8.84 7.16-16 16-16h96c8.84 0 16 7.16 16 16v144h128V138.67c0-10.7-5.35-20.7-14.25-26.63zM320 256c-44.18 0-80-35.82-80-80s35.82-80 80-80 80 35.82 80 80-35.82 80-80 80zm288-64h-64v320h80c8.84 0 16-7.16 16-16V224c0-17.67-14.33-32-32-32z",
                    },
                  }),
                ]
              ),
              _vm._v(" \n    " + _vm._s(_vm.user.school) + "\n  "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.user.schoolId
          ? _c("div", { staticClass: "schoolId my-2" }, [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("account.schoolId")) +
                  _vm._s(_vm.user.schoolId) +
                  "\n  "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-group" },
          [
            _c(
              "b-button",
              {
                staticClass: "btn-profile btn-myAc",
                attrs: {
                  variant: "light",
                  pill: "",
                  disabled: _vm.user.isAdmin,
                },
                on: {
                  click: function ($event) {
                    return _vm.account()
                  },
                },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("navbar.myAccount")) + "\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "btn-profile btn-logout",
                attrs: { variant: "outline-light", pill: "" },
                on: {
                  click: function ($event) {
                    return _vm.logout()
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("navbar.logout")) + "\n    ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }