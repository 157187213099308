import Vue from 'vue'

import moment from 'moment'

Vue.filter('str_limit', function (value, size) {
  if (!value) return ''
  value = value.toString()

  if (value.length <= size) {
    return value
  }
  return value.substr(0, size) + '...'
})

Vue.filter('timestamp', function (timestamp, format) {
  let result
  timestamp = parseInt(timestamp)

  if (!timestamp) {
    result = '-'
  } else if (format) {
    result = moment(timestamp).format(format)
  } else {
    result = moment(timestamp).format('YYYY.MM.DD HH:mm:ss')
  }

  return result
})

Vue.filter('date', function (date, format) {
  let result
  if (!date) {
    result = '-'
  } else if (format) {
    result = moment(date).format(format)
  } else {
    result = moment(date).format('YYYY.MM.DD')
  }

  return result
})

Vue.filter('time', function (date, format) {
  let result
  if (!date) {
    result = '-'
  } else if (format) {
    result = moment(date).format(format)
  } else {
    result = moment(date).format('HH:mm')
  }

  return result
})
