var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-cont" }, [
    _c(
      "div",
      { staticClass: "spaps-div" },
      [
        _c(
          "b-row",
          { staticClass: "mb-3" },
          [
            _c(
              "b-col",
              { attrs: { md: "11" } },
              [
                _c("h3", [
                  _vm._v(
                    "❶ " + _vm._s(_vm.$t("apply.spaps.tab_txt.choice.title"))
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.localChoiceHint, function (ch) {
                  return _c("div", { key: ch.id }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(ch.id) +
                        ": " +
                        _vm._s(ch.value) +
                        "\n        "
                    ),
                  ])
                }),
                _vm._v(" "),
                _vm._l(_vm.localChoiceArray, function (c, index) {
                  return _c("div", { key: index, staticClass: "mb-3" }, [
                    _vm.edit
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "subtitle" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("apply.spaps.tab_txt.choice.title_a")
                                  ) +
                                  _vm._s(index + 1) +
                                  _vm._s(
                                    _vm.$t("apply.spaps.tab_txt.choice.title_b")
                                  ) +
                                  "\n              "
                              ),
                              index === 0
                                ? _c("span", { staticClass: "red" }, [
                                    _vm._v("*"),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.filterChoices(index),
                                state:
                                  _vm.validate || _vm.validateOnclick[0]
                                    ? _vm.localChoiceArray.length === 1
                                      ? _vm.localChoiceArray[index] !== -1
                                      : index ===
                                        _vm.localChoiceArray.length - 1 // the new selection box
                                      ? null
                                      : _vm.localChoiceArray.length > 1
                                    : null,
                              },
                              model: {
                                value: _vm.localChoiceArray[index],
                                callback: function ($$v) {
                                  _vm.$set(_vm.localChoiceArray, index, $$v)
                                },
                                expression: "localChoiceArray[index]",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.localChoiceArray[index] !== -1
                      ? _c("div", [
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("apply.spaps.tab_txt.choice.title_a")
                                ) +
                                _vm._s(index + 1) +
                                _vm._s(
                                  _vm.$t("apply.spaps.tab_txt.choice.title_b")
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          c.event
                            ? _c("div", { staticClass: "choice" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.getEventTitle(c)) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { md: "5" } },
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.classes")) +
                      "\n          " +
                      _vm._s(_vm.localClassHints) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-checkbox-group", {
                      attrs: {
                        id: "checkbox-group-class",
                        options: _vm.createOption1,
                        name: "flavour-1",
                      },
                      on: { input: _vm.inputChanged },
                      model: {
                        value: _vm.localClasses,
                        callback: function ($$v) {
                          _vm.localClasses = $$v
                        },
                        expression: "localClasses",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localClasses
                              ? _vm.formatTargetInArray(_vm.localClasses)
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.eventGroup")) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        placeholder: _vm.$t("apply.spaps.tab_txt.placeholder"),
                        type: "text",
                      },
                      on: { input: _vm.inputChanged },
                      model: {
                        value: _vm.localEventGroup,
                        callback: function ($$v) {
                          _vm.localEventGroup = $$v
                        },
                        expression: "localEventGroup",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localEventGroup !== null
                              ? _vm.localEventGroup
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "spaps-div" },
      [
        _c("h3", [
          _vm._v("❷ " + _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-title", attrs: { id: "event-title-1" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title1")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title1a")) +
                      "\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-select", {
                      attrs: {
                        options: _vm.weekdays,
                        state:
                          _vm.validate || _vm.validateOnclick[1]
                            ? !!_vm.localWorkshop.workshopFirstweekday
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(1)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.workshopFirstweekday,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localWorkshop,
                            "workshopFirstweekday",
                            $$v
                          )
                        },
                        expression: "localWorkshop.workshopFirstweekday",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.workshopFirstweekday !== null
                              ? _vm.localWorkshop.workshopFirstweekday
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title1b")) +
                      " (hh:mm - hh:mm)\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        type: "text",
                        state:
                          _vm.validate || _vm.validateOnclick[2]
                            ? _vm.checkTime(_vm.localWorkshop.workshopFirsttime)
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(2)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.workshopFirsttime,
                        callback: function ($$v) {
                          _vm.$set(_vm.localWorkshop, "workshopFirsttime", $$v)
                        },
                        expression: "localWorkshop.workshopFirsttime",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.workshopFirsttime !== null
                              ? _vm.localWorkshop.workshopFirsttime
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-title mt-3", attrs: { id: "event-title-2" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title2")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title1a")) +
                      "\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-select", {
                      attrs: {
                        options: _vm.weekdays,
                        state:
                          _vm.validate || _vm.validateOnclick[3]
                            ? !!_vm.localWorkshop.workshopSecondweekday
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(3)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.workshopSecondweekday,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localWorkshop,
                            "workshopSecondweekday",
                            $$v
                          )
                        },
                        expression: "localWorkshop.workshopSecondweekday",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.workshopSecondweekday !== null
                              ? _vm.localWorkshop.workshopSecondweekday
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title1b")) +
                      " (hh:mm - hh:mm)\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        type: "text",
                        state:
                          _vm.validate || _vm.validateOnclick[4]
                            ? _vm.checkTime(
                                _vm.localWorkshop.workshopSecondtime
                              )
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(4)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.workshopSecondtime,
                        callback: function ($$v) {
                          _vm.$set(_vm.localWorkshop, "workshopSecondtime", $$v)
                        },
                        expression: "localWorkshop.workshopSecondtime",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.workshopSecondtime !== null
                              ? _vm.localWorkshop.workshopSecondtime
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-title mt-3", attrs: { id: "event-title-3" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title3")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title3a")) +
                      "\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-datepicker", {
                      attrs: {
                        min: _vm.today,
                        locale: _vm.$i18n.locale,
                        state:
                          _vm.validate || _vm.validateOnclick[5]
                            ? !!_vm.localWorkshop.performFirstdate
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(5)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.performFirstdate,
                        callback: function ($$v) {
                          _vm.$set(_vm.localWorkshop, "performFirstdate", $$v)
                        },
                        expression: "localWorkshop.performFirstdate",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.performFirstdate !== null
                              ? _vm.localWorkshop.performFirstdate
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title1b")) +
                      " (hh:mm)\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        type: "text",
                        state:
                          _vm.validate || _vm.validateOnclick[6]
                            ? _vm.checkTime(_vm.localWorkshop.performFirsttime)
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(6)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.performFirsttime,
                        callback: function ($$v) {
                          _vm.$set(_vm.localWorkshop, "performFirsttime", $$v)
                        },
                        expression: "localWorkshop.performFirsttime",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.performFirsttime !== null
                              ? _vm.localWorkshop.performFirsttime
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-title mt-3", attrs: { id: "event-title-4" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title4")) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title3a")) +
                      "\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-datepicker", {
                      attrs: {
                        min: _vm.today,
                        locale: _vm.$i18n.locale,
                        state:
                          _vm.validate || _vm.validateOnclick[7]
                            ? !!_vm.localWorkshop.performSeconddate
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(7)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.performSeconddate,
                        callback: function ($$v) {
                          _vm.$set(_vm.localWorkshop, "performSeconddate", $$v)
                        },
                        expression: "localWorkshop.performSeconddate",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.performSeconddate !== null
                              ? _vm.localWorkshop.performSeconddate
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-col",
              [
                _c("div", { staticClass: "event-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title1b")) +
                      " (hh:mm)\n          "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        type: "text",
                        state:
                          _vm.validate || _vm.validateOnclick[8]
                            ? _vm.checkTime(_vm.localWorkshop.performSecondtime)
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(8)
                        },
                      },
                      model: {
                        value: _vm.localWorkshop.performSecondtime,
                        callback: function ($$v) {
                          _vm.$set(_vm.localWorkshop, "performSecondtime", $$v)
                        },
                        expression: "localWorkshop.performSecondtime",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.performSecondtime !== null
                              ? _vm.localWorkshop.performSecondtime
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-title mt-3", attrs: { id: "event-title-5" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title5")) +
                "\n      "
            ),
            _c("span", { staticClass: "red" }, [_vm._v("*")]),
          ]
        ),
        _vm._v(" "),
        _vm.edit
          ? _c("b-form-input", {
              attrs: {
                type: "number",
                state:
                  _vm.validate || _vm.validateOnclick[9]
                    ? _vm.checkNumber(_vm.localWorkshop.anticipatedNo)
                    : null,
              },
              on: {
                input: function ($event) {
                  return _vm.inputChanged(9)
                },
              },
              model: {
                value: _vm.localWorkshop.anticipatedNo,
                callback: function ($$v) {
                  _vm.$set(_vm.localWorkshop, "anticipatedNo", $$v)
                },
                expression: "localWorkshop.anticipatedNo",
              },
            })
          : _c("div", { staticClass: "choice" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.localWorkshop.anticipatedNo !== null
                      ? _vm.localWorkshop.anticipatedNo
                      : "/"
                  ) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-title mt-3", attrs: { id: "event-title-6" } },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("apply.spaps.tab_txt.div2.title6")) +
                "\n      "
            ),
            _c("span", { staticClass: "red" }, [
              _vm._v("*" + _vm._s(_vm.localAnticipatedClassHints)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { md: "5" } },
              [
                _vm.edit
                  ? _c("b-form-checkbox-group", {
                      attrs: {
                        id: "checkbox-group-anticipatedClass",
                        options: _vm.createOption1,
                        state:
                          _vm.validate || _vm.validateOnclick[10]
                            ? _vm.checkAnticipatedClass()
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(10)
                        },
                      },
                      model: {
                        value: _vm.selectAnticipatedClass,
                        callback: function ($$v) {
                          _vm.selectAnticipatedClass = $$v
                        },
                        expression: "selectAnticipatedClass",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.localWorkshop.anticipatedClass
                              ? _vm.formatTargetInArray(
                                  _vm.localWorkshop.anticipatedClass
                                )
                              : "/"
                          ) +
                          "\n        "
                      ),
                    ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }