export default function ({ $axios, store }) {
  $axios.onRequest((config) => {
    config.headers.get['Cache-Control'] =
      'no-cache, no-store, must-revalidate, public, max-age=86400, immutable'
    if (store.state.localStorage.auth.token) {
      config.headers.common.Authorization = `Bearer ${store.state.localStorage.auth.token}`
    }
  })

  $axios.interceptors.response.use(
    (response) => {
      return response
    },
    function (error) {
      const originalRequest = error.config

      // console.log(error.response)

      if (
        error.response.data.error === 'jwt expired' &&
        originalRequest.url === '/users/refresh_token'
      ) {
        store.dispatch('localStorage/logout')
        return Promise.reject(error)
      }

      if (
        error.response.data.error === 'jwt expired' &&
        !originalRequest._retry
      ) {
        // console.log('originalRequest ', originalRequest)
        originalRequest._retry = true
        return store.dispatch('localStorage/refreshToken').then((res) => {
          $axios.defaults.headers.common.Authorization = `Bearer ${store.state.localStorage.auth.token}`
          return $axios(originalRequest)
        })
      }
      return Promise.reject(error)
    }
  )
}
