var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { staticStyle: { "font-size": "0.9em" }, attrs: { sm: "12", md: "9" } },
        [
          _c(
            "div",
            [
              _c("div", [_vm._v("報名方法")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3", staticStyle: { display: "flex" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      options: _vm.applyMethodOption,
                      state: !!_vm.applyMethod,
                      required: "",
                    },
                    model: {
                      value: _vm.applyMethod,
                      callback: function ($$v) {
                        _vm.applyMethod = $$v
                      },
                      expression: "applyMethod",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "text", maxlength: "1000" },
                    model: {
                      value: _vm.applyMethodOther,
                      callback: function ($$v) {
                        _vm.applyMethodOther = $$v
                      },
                      expression: "applyMethodOther",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _vm._v("報名遞交時間（YYYY-MM-DD HH:mm:ss）"),
              ]),
              _vm._v(" "),
              _c("b-form-input", {
                staticClass: "mb-3",
                staticStyle: { width: "300px" },
                attrs: {
                  state: _vm.submitTimeState(_vm.submitTime),
                  type: "text",
                },
                model: {
                  value: _vm.submitTime,
                  callback: function ($$v) {
                    _vm.submitTime = $$v
                  },
                  expression: "submitTime",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _vm._v("申請編號（自動產生）"),
              ]),
              _vm._v(" "),
              _c("b-form-input", {
                staticClass: "mb-4",
                attrs: { type: "text", readonly: "" },
                model: {
                  value: _vm.trnId,
                  callback: function ($$v) {
                    _vm.trnId = $$v
                  },
                  expression: "trnId",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "form",
                    label: "匯入申請（OGCIO .zip）",
                  },
                },
                [
                  _vm.action === "add"
                    ? _c("b-form-file", {
                        attrs: {
                          id: "form",
                          accept:
                            "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
                        },
                        on: { change: _vm.uploadForm },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", { staticClass: "mt-4 mb-1" }, [_vm._v("附件")]),
                  _vm._v(" "),
                  _vm._l(_vm.attr, function (a, index) {
                    return _c("p", { key: index }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `${_vm.attachmentUrl}${a}`,
                            target: "_blank",
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "paperclip" } }),
                          _vm._v(_vm._s(a) + "\n          "),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("matching-school", {
                    attrs: {
                      data: _vm.data,
                      "sch-id": _vm.sarId,
                      name: _vm.schoolData ? _vm.schoolData.name_tc : "",
                    },
                    on: { input: _vm.onChangeSkl },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("applySchoolInfo", {
                    attrs: {
                      promo: _vm.disagreePromo,
                      "teacher-data": _vm.teachers,
                      "school-data": _vm.schoolData,
                    },
                    on: { change: _vm.onSchoolInfoChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("event-slot-modal", {
                attrs: {
                  "event-slots": _vm.getEventSlot,
                  "parent-scheme-id": 5,
                  "is-admin": true,
                },
                on: { input: _vm.getSelectedSlots },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "shadow" }, [
                _c(
                  "div",
                  { staticClass: "form-section" },
                  [
                    _c("h3", [_vm._v("3. 擬參加活動"), _c("br")]),
                    _vm._v(" "),
                    _vm._l(_vm.activityList, function (activity, index) {
                      return _c("div", { key: index }, [
                        _c("fieldset", { staticClass: "topLeftInsideLegend" }, [
                          _c("legend", [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("行"),
                            ]),
                            _vm._v(
                              "# " + _vm._s(index + 1) + "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "mb-3" },
                                [
                                  _c("div", { staticClass: "subtitle" }, [
                                    _vm._v("節目名稱及票價"),
                                  ]),
                                  _vm._v(" "),
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      maxlength: "1000",
                                      readonly: "",
                                    },
                                    model: {
                                      value: activity.eventNamePrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          activity,
                                          "eventNamePrice",
                                          $$v
                                        )
                                      },
                                      expression: "activity.eventNamePrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "py-3" },
                                [
                                  _c("div", [_vm._v("配對活動")]),
                                  _vm._v(" "),
                                  _c("b-form-select", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      options: _vm.suggestEventList,
                                      state: !!activity.event_id,
                                      size: "sm",
                                      required: "",
                                    },
                                    model: {
                                      value: activity.event_id,
                                      callback: function ($$v) {
                                        _vm.$set(activity, "event_id", $$v)
                                      },
                                      expression: "activity.event_id",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "layout-inline mt-3 mb-3" },
                                    [
                                      _vm._v(
                                        "\n                    已選擇：\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          class: !activity.event_id
                                            ? "not_selected"
                                            : "resultskl",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                activity.event_id
                                                  ? _vm.suggestEventList.find(
                                                      (d) =>
                                                        d.id ===
                                                        activity.event_id
                                                    ).label
                                                  : "未選擇"
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("hr", { staticStyle: { width: "95%" } }),
                              _vm._v(" "),
                              _c("div", { staticClass: "subtitle" }, [
                                _vm._v("日期、時間及地點"),
                              ]),
                              _vm._v(" "),
                              _c("b-form-input", {
                                staticClass: "mb-3",
                                attrs: {
                                  type: "text",
                                  maxlength: "1000",
                                  readonly: "",
                                },
                                model: {
                                  value: activity.dateTime,
                                  callback: function ($$v) {
                                    _vm.$set(activity, "dateTime", $$v)
                                  },
                                  expression: "activity.dateTime",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "py-3" },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "配對活動場次 #" + _vm._s(index + 1)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.modal-eventslot",
                                          modifiers: {
                                            "modal-eventslot": true,
                                          },
                                        },
                                      ],
                                      staticClass: "mt-1",
                                      staticStyle: { display: "block" },
                                      attrs: { size: "sm", variant: "primary" },
                                      on: {
                                        click: () => {
                                          _vm.selectedEventIndex = index
                                          _vm.selectedEventId =
                                            activity.event_id
                                              ? activity.event_id
                                              : null
                                        },
                                      },
                                    },
                                    [
                                      _vm._v("\n                    選擇場次 "),
                                      _c("b-icon", {
                                        attrs: { icon: "hand-index-thumb" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "none" } },
                                    [_vm._v(_vm._s(_vm.tmpEventSlot1))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "layout-inline mt-3 mb-4" },
                                    [
                                      _vm._v(
                                        "\n                    已選擇場次：\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          class: !activity.event_slot_1
                                            ? "not_selected"
                                            : "resultskl",
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                activity.event_slot_1
                                                  ? activity.event_slot_1
                                                  : "未選擇"
                                              ) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "layout-default layout-group layout-inline-group",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "field form-group layout-inline",
                                      attrs: {
                                        id: "activityList.0.firstChoiceStudentNo_panel",
                                      },
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "activityList.0.firstChoiceStudentNo",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "required",
                                              attrs: {
                                                id: "activityList.0.firstChoiceStudentNo_label",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "subtitle" },
                                                [_vm._v("學生人數")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "sr-only" },
                                                [_vm._v("必須提供")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            staticClass: "form-control",
                                            staticStyle: { width: "104px" },
                                            attrs: {
                                              type: "tel",
                                              name: "activityList.0.firstChoiceStudentNo",
                                              maxlength: "15",
                                              pattern: "[0-9]*",
                                              inputmode: "numeric",
                                              value: "",
                                            },
                                            model: {
                                              value: activity.studentNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  activity,
                                                  "studentNo",
                                                  $$v
                                                )
                                              },
                                              expression: "activity.studentNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "field form-group layout-inline",
                                      attrs: {
                                        id: "activityList.0.firstChoiceTeacherNo_panel",
                                      },
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "activityList.0.firstChoiceTeacherNo",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "required",
                                              attrs: {
                                                id: "activityList.0.firstChoiceTeacherNo_label",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "subtitle" },
                                                [_vm._v("老師人數")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "sr-only" },
                                                [_vm._v("必須提供")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            staticClass: "form-control",
                                            staticStyle: { width: "104px" },
                                            attrs: {
                                              type: "tel",
                                              name: "activityList.0.firstChoiceTeacherNo",
                                              maxlength: "15",
                                              pattern: "[0-9]*",
                                              inputmode: "numeric",
                                              value: "",
                                            },
                                            model: {
                                              value: activity.teacherNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  activity,
                                                  "teacherNo",
                                                  $$v
                                                )
                                              },
                                              expression: "activity.teacherNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "field form-group layout-inline",
                                    },
                                    [
                                      _c("div", { staticClass: "subtitle" }, [
                                        _vm._v(
                                          "\n                      學生人數 (綜合社會保障援助受惠人士) (如適用)^\n                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("b-form-input", {
                                        staticStyle: { width: "104px" },
                                        attrs: {
                                          type: "number",
                                          maxlength: "15",
                                        },
                                        model: {
                                          value: activity.cssaStudentNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              activity,
                                              "cssaStudentNo",
                                              $$v
                                            )
                                          },
                                          expression: "activity.cssaStudentNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldListButtons" }, [
                          _vm.activityList.length > 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-danger mb-3",
                                  attrs: {
                                    "aria-label": "刪除活動",
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delActivity(index)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "icon-plus-circled",
                                  }),
                                  _vm._v(
                                    "\n                刪除活動 " +
                                      _vm._s(index + 1) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldListButtons my-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-success",
                          attrs: { "aria-label": "新增活動", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addActivity()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "icon-plus-circled" }),
                          _vm._v(" 新增活動\n            "),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "success" } },
                [_vm._v(" 提交 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }