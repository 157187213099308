var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-eventslot",
            centered: "",
            scrollable: "",
            size: "xl",
          },
          on: { ok: _vm.handleOk, show: _vm.resetModal },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ close }) {
                return [
                  _c("h5", { attrs: { center: "" } }, [
                    _vm._v(_vm._s(_vm.$t("event.select_event_slot"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "modal-close",
                      attrs: { size: "sm" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function () {
                return undefined
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "news-item" }, [
            _vm.eventSlots
              ? _c("div", [
                  _c("div", { staticClass: "wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "event_slot" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _vm.parentSchemeId !== 5
                            ? _c(
                                "div",
                                { staticClass: "col-11 ordering" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("event.filter_by_target")) +
                                      "\n                "
                                  ),
                                  _c(
                                    "b-form-radio-group",
                                    {
                                      attrs: {
                                        id: "radio-group-2",
                                        name: "radio-target",
                                      },
                                      model: {
                                        value: _vm.filterItem,
                                        callback: function ($$v) {
                                          _vm.filterItem = $$v
                                        },
                                        expression: "filterItem",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-radio",
                                        { attrs: { value: "all" } },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("event.all")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-radio",
                                        { attrs: { value: "k" } },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("event.target.k")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-radio",
                                        { attrs: { value: "p" } },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("event.target.p")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-radio",
                                        { attrs: { value: "f" } },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("event.target.f")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c("div", { staticClass: "col-11" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-1" }, [
                            _c("span", { staticClass: "note-right" }, [
                              _vm._v("*" + _vm._s(_vm.$t("event.required"))),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("b-table", {
                          staticClass: "event_table text-center",
                          attrs: {
                            id: "table",
                            striped: "",
                            "thead-class": "eventslot-table-td",
                            items: _vm.filteredEventSlot,
                            fields: _vm.eventslotFields,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "head(firstSlot)",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("event.first_choice"))
                                    ),
                                    _c("span", { staticClass: "note" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "cell(date)",
                                fn: function (row) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm
                                            .$moment(row.item.date)
                                            .format("DD.MM.YYYY")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(time)",
                                fn: function (row) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm
                                            .$moment(
                                              `2021-01-01 ${row.item.startTime}`
                                            )
                                            .format("h:mm a")
                                        ) +
                                        "\n                -\n                " +
                                        _vm._s(
                                          _vm
                                            .$moment(
                                              `2021-01-01 ${row.item.endTime}`
                                            )
                                            .format("h:mm a")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(venue)",
                                fn: function (row) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.getVenue(row.item.venue)) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(target)",
                                fn: function (row) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          row.item.target
                                            ? _vm.formatTarget(row.item.target)
                                            : ""
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                              _vm.scdsAdditionalRound && !_vm.isAdmin
                                ? {
                                    key: "cell(remain_quota)",
                                    fn: function (row) {
                                      return [
                                        _vm.getRemainQuota(row.item.id) > 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.getRemainQuota(
                                                      row.item.id
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ])
                                          : _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "indianred",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "event.statusList.fulled"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  }
                                : null,
                              {
                                key: "cell(slot)",
                                fn: function (row) {
                                  return [
                                    row.item.status === 2
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { color: "indianred" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.cancelled"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      : _vm
                                          .$moment(row.item.date)
                                          .isBefore(_vm.$moment(), "day")
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "forestgreen",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.completed"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      : _vm.getRemainQuota(row.item.id) <= 0 &&
                                        (_vm.scdsAdditionalRound || _vm.isAdmin)
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { color: "indianred" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.fulled"
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("b-form-radio", {
                                      attrs: {
                                        value: row.item.id,
                                        name: "first",
                                        state: _vm.state,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.checkRadio(
                                            "1",
                                            row.item.id
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.firstChoice,
                                        callback: function ($$v) {
                                          _vm.firstChoice = $$v
                                        },
                                        expression: "firstChoice",
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "cell(firstSlot)",
                                fn: function (row) {
                                  return [
                                    row.item.status === 2
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { color: "indianred" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.cancelled"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _vm.isAdmin
                                              ? _c("b-form-radio", {
                                                  attrs: {
                                                    value: row.item.id,
                                                    name: "first",
                                                    state: _vm.state,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRadio(
                                                        "1",
                                                        row.item.id
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.firstChoice,
                                                    callback: function ($$v) {
                                                      _vm.firstChoice = $$v
                                                    },
                                                    expression: "firstChoice",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm
                                          .$moment(row.item.date)
                                          .isBefore(_vm.$moment(), "day")
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "forestgreen",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.completed"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _vm.isAdmin
                                              ? _c("b-form-radio", {
                                                  attrs: {
                                                    value: row.item.id,
                                                    name: "first",
                                                    state: _vm.state,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRadio(
                                                        "1",
                                                        row.item.id
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.firstChoice,
                                                    callback: function ($$v) {
                                                      _vm.firstChoice = $$v
                                                    },
                                                    expression: "firstChoice",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm.getRemainQuota(row.item.id) <= 0 &&
                                        _vm.scdsAdditionalRound
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { color: "indianred" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.fulled"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _vm.isAdmin
                                              ? _c("b-form-radio", {
                                                  attrs: {
                                                    value: row.item.id,
                                                    name: "first",
                                                    state: _vm.state,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRadio(
                                                        "1",
                                                        row.item.id
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.firstChoice,
                                                    callback: function ($$v) {
                                                      _vm.firstChoice = $$v
                                                    },
                                                    expression: "firstChoice",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _c("b-form-radio", {
                                          attrs: {
                                            value: row.item.id,
                                            name: "first",
                                            state: _vm.state,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.checkRadio(
                                                "1",
                                                row.item.id
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.firstChoice,
                                            callback: function ($$v) {
                                              _vm.firstChoice = $$v
                                            },
                                            expression: "firstChoice",
                                          },
                                        }),
                                  ]
                                },
                              },
                              {
                                key: "cell(secondSlot)",
                                fn: function (row) {
                                  return [
                                    row.item.status === 2
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { color: "indianred" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.cancelled"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _vm.isAdmin
                                              ? _c("b-form-radio", {
                                                  attrs: {
                                                    value: row.item.id,
                                                    name: "second",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRadio(
                                                        "2",
                                                        row.item.id
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.secondChoice,
                                                    callback: function ($$v) {
                                                      _vm.secondChoice = $$v
                                                    },
                                                    expression: "secondChoice",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm
                                          .$moment(row.item.date)
                                          .isBefore(_vm.$moment(), "day")
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "forestgreen",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.completed"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _vm.isAdmin
                                              ? _c("b-form-radio", {
                                                  attrs: {
                                                    value: row.item.id,
                                                    name: "second",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRadio(
                                                        "2",
                                                        row.item.id
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.secondChoice,
                                                    callback: function ($$v) {
                                                      _vm.secondChoice = $$v
                                                    },
                                                    expression: "secondChoice",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm.getRemainQuota(row.item.id) <= 0 &&
                                        _vm.scdsAdditionalRound
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { color: "indianred" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "event.statusList.fulled"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _vm.isAdmin
                                              ? _c("b-form-radio", {
                                                  attrs: {
                                                    value: row.item.id,
                                                    name: "first",
                                                    state: _vm.state,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.checkRadio(
                                                        "1",
                                                        row.item.id
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.firstChoice,
                                                    callback: function ($$v) {
                                                      _vm.firstChoice = $$v
                                                    },
                                                    expression: "firstChoice",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _c("b-form-radio", {
                                          attrs: {
                                            value: row.item.id,
                                            name: "second",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.checkRadio(
                                                "2",
                                                row.item.id
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.secondChoice,
                                            callback: function ($$v) {
                                              _vm.secondChoice = $$v
                                            },
                                            expression: "secondChoice",
                                          },
                                        }),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        !_vm.eventSlots.length
                          ? _c(
                              "p",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("event.no_slot_found")) +
                                    "\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }