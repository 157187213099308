<template>
  <div>
    <tab-1
      v-if="
        ($route.name.includes('apply') ||
          $route.name.includes('application') ||
          $route.name.includes('shoppingCart')) &&
        schoolId
      "
      :edit="edit"
      :eventlist="eventlist"
      :choice-array="choiceArray"
      :classes="classes"
      :event-group="eventGroup"
      :workshop="workshop"
    ></tab-1>
    <tab-2
      v-if="
        ($route.name.includes('apply') ||
          $route.name.includes('application') ||
          $route.name.includes('shoppingCart')) &&
        schoolId
      "
      :equipments="equipments"
      :edit="edit"
    ></tab-2>
    <tab-3
      v-if="
        ($route.name.includes('apply') ||
          $route.name.includes('application') ||
          $route.name.includes('shoppingCart')) &&
        schoolId
      "
      :sklinfo="sklinfo"
      :edit="edit"
    ></tab-3>
    <tab-4
      v-if="
        ($route.name.includes('apply') ||
          $route.name.includes('application') ||
          $route.name.includes('shoppingCart')) &&
        schoolId
      "
      :pass-teachers="teachers"
      :remarks="remarks"
      :edit="edit"
      :sklinfo="sklinfo"
      :sch-tc="schTc"
      :sch-en="schEn"
      :user-name-tc="userNameTc"
      :user-email="userEmail"
      :user-tel="userTel"
      :has-tel="hasTel"
      :school-id="schoolId"
      :user-id="userId"
    ></tab-4>
    <!-- <tab-6
      v-if="
        !$route.name.includes('change_request') &&
        !$route.name.includes('shoppingCart') &&
        !$route.name.includes('apply')
      "
      :edit="edit"
      scheme="spaps"
    ></tab-6> -->
    <tab-6
      v-if="
        ($route.name.includes('application') &&
          !$route.name.includes('change_request')) ||
        ($route.name.includes('apply') && !schoolId)
      "
      :edit="edit"
      scheme="spaps"
    ></tab-6>
  </div>
</template>
<script>
import tab1 from '~/components/apply/spaps/tab1.vue'
import tab2 from '~/components/apply/spaps/tab2.vue'
import tab3 from '~/components/apply/spaps/tab3.vue'
import tab4 from '~/components/apply/tab1.vue'
import tab6 from '~/components/apply/tab2.vue'
export default {
  name: 'Tab5',
  components: {
    tab1,
    tab2,
    tab3,
    tab4,
    tab6,
  },
  props: [
    'edit',
    'choice',
    'remarks',
    'eventlist',
    'choiceArray',
    'classes',
    'eventGroup',
    'workshop',
    'equipments',
    'sklinfo',
    'teachers',
    'schTc',
    'schEn',
    'userNameTc',
    'userEmail',
    'userTel',
    'hasTel',
    'schoolId',
    'userId',
  ],
}
</script>
<style lang="scss" scoped></style>
