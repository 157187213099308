<template>
  <div class="cont-tab">
    <!--Title-->
    <div v-if="schemeAll.length" class="scheme-title">
      <span v-for="(n, index) in getPaths()" :key="n.id" class="path">
        <nuxt-link
          :to="localePath(`/${n.slug}`)"
          :class="
            index === getPaths().length - 1 ? 'scheme-link-last' : 'scheme-link'
          "
          v-html="n.title"
        ></nuxt-link
        ><span v-if="index != getPaths().length - 1">&nbsp;&gt;&nbsp;</span>
      </span>
      <!-- <div class="pagetitle path">{{ currSchemeId }}</div> -->
    </div>
    <!--Tabs-->
    <div>
      <b-nav ref="schemeTabNav" tabs justified class="nav-ie">
        <b-nav-item
          v-for="n in mainScheme"
          :key="n.id"
          :to="localePath(`/scheme/${n.slug}`)"
          :active="
            n.id === currSchemeId || n.id === getParentSchemeId(currSchemeId)
              ? true
              : false
          "
          :class="`scheme-tab-item scheme-tab-item-${n.slug}`"
        >
          <div :class="$i18n.locale === 'en' ? 'tab-txt-en' : 'tab-txt-chi'">
            <img
              :src="getTmpImg(n.slug)"
              class="scheme-tab-img"
              alt="scheme tag"
            />
            <div>{{ i18nTitle(n) }}</div>
          </div>
        </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchemeTab',
  props: {
    currSchemeId: Number,
    currSubSchemeId: Number,
    currCatTitle: String,
    currCatSlug: String,
    currCatId: Number,
    currEvent: String,
    currEventTitle: String,
  },
  async fetch() {
    try {
      if (this.schemeStored.length) {
        this.schemeAll = this.schemeStored.map((a) => ({ ...a }))
        // console.log('this.schemeAll ' + this.schemeAll)
      } else {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}schemes/public/scheme/name`
        )
        this.schemeAll = result.data.data
        this.$store.commit(
          'tempStorage/importPortalSchemes',
          result.data.data.map((a) => ({ ...a }))
        )
      }
      this.schemeOnly = this.schemeAll.filter((s) => s.parent === null)
      this.mainScheme = this.schemeOnly.filter(
        (s) => s.id === 1 || s.id === 4 || s.id === 5 || s.id === 9
      )
      // onsole.log('schemeAll ' + JSON.stringify(this.schemeAll))
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/category`
      )
      this.categories = result.data.data
    } catch (err) {}
  },
  data() {
    return {
      active: false,
      schemeAll: [],
      schemeOnly: [],
      mainScheme: [],
      categories: [],
    }
  },
  computed: {
    schemeStored() {
      return this.$store.state.tempStorage.schemesPortal
    },
  },
  methods: {
    getTmpImg(slug) {
      switch (slug) {
        case 'scds':
          return 'https://www.abo.gov.hk/img/upload/e410f98e-45bf-4cf2-9f16-0f7dc527ae4d_SCDS.jpg'
        case 'aoa':
          return 'https://www.abo.gov.hk/img/upload/1b531a2c-af94-42b1-9b46-d66c27541518_AOA.jpg'
        case 'spaps':
          return 'https://www.abo.gov.hk/img/upload/58c8963b-c29e-4358-9b83-98e9eb372a15_SPAPS.jpg'
        case 'aes':
          return 'https://www.abo.gov.hk/img/upload/e9d6ba96-4c5c-4014-a862-16206ad71a60_AES.jpg'
        default:
          return ''
      }
    },
    getParentSchemeId(id) {
      const result = this.schemeAll.find((s) => s.id === parseInt(id))
      // console.log(this.schemeAll, result)
      return result ? result.parent : null
    },
    getParentSchemeSlug(id) {
      let result = -1
      this.schemeAll.forEach((s) => {
        if (s.id === parseInt(id)) {
          this.schemeAll.forEach((d) => {
            if (d.id === s.parent) result = d.slug
          })
          if (result === -1) {
            result = s.slug
          }
        }
      })
      return result
    },
    // i18n
    getTxt(field) {
      const lang =
        this.$i18n.locale === 'en' ? 0 : this.$i18n.locale === 'tc' ? 1 : 2
      switch (field) {
        case 'home':
          return lang === 0 ? 'Home' : lang === 1 ? '主頁' : '主页'
        case 'eventdetail':
          return lang === 0
            ? 'Event Details'
            : lang === 1
            ? '活動詳情'
            : '活动详情'
        case 'eventlist':
          return lang === 0
            ? 'Programme List'
            : lang === 1
            ? '活動一覽'
            : '活动一览'
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    getCategory(id) {
      if (this.categories !== undefined) {
        const cat = this.categories.find((c) => c.id === id)
        return cat ? this.i18nTitle(cat) : ''
      }
      return ''
    },
    getSchemeIndexById(id) {
      return this.schemeAll.findIndex((s) => s.id === parseInt(id))
    },
    getPaths() {
      const path = []
      // home
      const home = { title: this.getTxt('home'), slug: `` }
      path.push(home)
      // scheme
      if (
        this.schemeAll.length &&
        this.currSchemeId &&
        this.schemeAll[this.getSchemeIndexById(this.currSchemeId)]
      ) {
        const scheme = {
          title: `${this.i18nTitle(
            this.schemeAll[this.getSchemeIndexById(this.currSchemeId)]
          )}`,
          slug: `scheme/${this.getParentSchemeSlug(this.currSchemeId)}`,
        }
        path.push(scheme)
      }
      /* // sub-schem
      if (this.schemeAll.length && this.currSchemeId && this.currSubSchemeId) {
        // console.log(this.getSchemeIndexById(this.currSchemeId))
        const subScheme = {
          title: `${
            this.schemeAll[this.getSchemeIndexById(this.currSubSchemeId)]
              .title_tc
          }`,
          slug: `scheme/${
            this.schemeAll[this.getSchemeIndexById(this.currSchemeId)].slug
          }/${
            this.schemeAll[this.getSchemeIndexById(this.currSubSchemeId)].slug
          }`,
        }
        path.push(subScheme)
      } */

      // category
      if (this.schemeAll.length && this.currSchemeId && this.currCatSlug) {
        // console.log(this.getSchemeIndexById(this.currSchemeId))
        let category = {}
        if (this.currCatSlug === 'all') {
          category = {
            title: this.getTxt('eventlist'),
            slug: `category/all?all=true&subscheme=${this.currSchemeId}`,
          }
        } else {
          category = {
            // title: this.getCategory(this.currCatId),
            title: this.getTxt('eventlist'),
            slug: `category/${this.currCatSlug}?subscheme=${this.currSchemeId}#${this.currCatId}`,
          }
        }
        path.push(category)
      }
      // event
      if (this.schemeAll.length && this.currSchemeId && this.currEvent) {
        // console.log(this.getSchemeIndexById(this.currSchemeId))
        const event = {
          // title: `活動詳情`,
          title: `${
            this.currEventTitle
              ? this.currEventTitle
              : this.getTxt('eventdetail')
          }`,
          slug: `event/${this.currEvent}`,
        }
        path.push(event)
      }
      return path
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-txt-chi {
  font-size: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.tab-txt-en {
  font-size: 11.5px;
  display: flex;
  text-align: left;
  align-items: center;
  height: 100%;
}
.cont-tab {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}
.scheme-tab-img-bg {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  background-color: #e1e1e1;
  margin-right: 10px;
  fill: white;
  display: flex;
  flex-wrap: wrap;
}
.scheme-tab-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.scheme-tab-item-scds > .active {
  border-top: 3px solid #ff739d !important;
}
.scheme-tab-item-spaps > .active {
  border-top: 3px solid #9772ea !important;
}
.scheme-tab-item-aes > .active {
  border-top: 3px solid #739e00 !important;
}
.scheme-tab-item-aoa > .active {
  border-top: 3px solid #4fb1e8 !important;
}
.scheme-tab-item {
  // max-width: 25%;
}
.scheme-tab-item > .nav-link {
  height: 100%;
}
/* .scheme-tab-item-scds > .nav-link:hover {
  border-top: 3px solid #ff739d;
}
.scheme-tab-item-spaps > .nav-link:hover {
  border-top: 3px solid #9772ea;
}
.scheme-tab-item-aes > .nav-link:hover {
  border-top: 3px solid #739e00;
}
.scheme-tab-item-aoa > .nav-link:hover {
  border-top: 3px solid #4fb1e8;
}
.scheme-tab-item > .nav-link {
  border-top: 3px solid #f8f8f8;
} */
.scheme-title {
  color: #000;
  width: 100%;
  z-index: 0;
  margin-bottom: 10px;
  text-align: left;
}
.path {
  font-size: 100%;
}
a.scheme-link {
  color: #3f3f3f;
}

a.nav-link {
  color: darkgrey;
}

a.nav-link:hover {
  color: #007bff;
}
a.scheme-link-last {
  color: #3f3f3f;
  font-weight: bold;
}
a.scheme-link:hover,
a.scheme-link-last:hover {
  color: #007bff;
}
.pagetitle {
  font-weight: bold;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .scheme-title {
    color: #000;
    width: 100%;
    z-index: 0;
    margin-bottom: 10px;
    text-align: left;

    padding-right: 16px;
    padding-left: 16px;
  }

  .nav.nav-ie {
    display: none;
    padding: 0;
  }

  .nav-tabs .nav-link {
    border-radius: 0;
  }

  .cont-tab {
    width: 100%;
    margin-left: 0px;
    display: none;
  }

  .scheme-tab-item-scds > .active {
    border-bottom: 3px solid #ff739d;
  }
  .scheme-tab-item-spaps > .active {
    border-bottom: 3px solid #9772ea;
  }
  .scheme-tab-item-aes > .active {
    border-bottom: 3px solid #739e00;
  }
  .scheme-tab-item-aoa > .active {
    border-bottom: 3px solid #4fb1e8;
  }
}
</style>
