<template>
  <div class="navmenu" :class="mNavDisplay === false ? 'm-hide' : 'm-show'">
    <b-nav vertical class="navbar-nav">
      <!--nav-home-->
      <b-nav-item :class="togglenav" class="nav-first-child" @click="toggleNav">
        <nuxt-link :to="localePath('/')">
          <div class="nav-item-obj">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.67 94.42">
              <defs>
                <style>
                  .cls-1 {
                    fill: #000;
                  }
                </style>
              </defs>
              <!--title>Home</title-->
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2-1" data-name="Layer 1">
                  <path
                    class="cls-1"
                    d="M94,47.53H90.37V87.16a7.26,7.26,0,0,1-7.26,7.26H69.24a5.4,5.4,0,0,1-5.4-5.4V58.38a5.4,5.4,0,0,0-5.4-5.4H40.24a5.4,5.4,0,0,0-5.4,5.4V89a5.4,5.4,0,0,1-5.4,5.4H15.57a7.26,7.26,0,0,1-7.26-7.26V47.53H4.69a4.68,4.68,0,0,1-3-8.24L46.3,1.13a4.65,4.65,0,0,1,6.08,0L97,39.29A4.68,4.68,0,0,1,94,47.53Z"
                  />
                </g>
              </g>
            </svg>

            <p>{{ $t('navbar.home') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>
      <!--nav-about-->
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/about')">
          <div class="nav-item-obj">
            <svg
              id="Capa_1"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 174.4 174.4"
              style="enable-background: new 0 0 174.4 174.4"
              xml:space="preserve"
            >
              <style type="text/css">
                .st0 {
                  fill: none;
                  stroke: #fff;
                  stroke-width: 10;
                  stroke-miterlimit: 10;
                }
              </style>
              <circle class="st0" cx="86.2" cy="88.2" r="78" />
              <g>
                <path
                  class="cls-1"
                  d="M102.6,124.7l-1.3,5.3c-3.9,1.5-7,2.7-9.3,3.5c-2.3,0.8-5,1.2-8.1,1.2c-4.7,0-8.4-1.2-11-3.5c-2.6-2.3-3.9-5.2-3.9-8.8
		c0-1.4,0.1-2.8,0.3-4.2c0.2-1.4,0.5-3.1,1-4.9L75,96.2c0.4-1.7,0.8-3.2,1.1-4.7c0.3-1.5,0.4-2.8,0.4-4c0-2.2-0.5-3.7-1.4-4.6
		c-0.9-0.9-2.6-1.3-5.2-1.3c-1.3,0-2.6,0.2-3.9,0.6c-1.3,0.4-2.5,0.8-3.4,1.1l1.3-5.3c3.2-1.3,6.3-2.4,9.2-3.3
		c2.9-0.9,5.7-1.4,8.3-1.4c4.7,0,8.3,1.1,10.9,3.4c2.5,2.3,3.8,5.2,3.8,8.8c0,0.8-0.1,2.1-0.3,4c-0.2,1.9-0.5,3.6-1,5.2L90,111.8
		c-0.4,1.4-0.8,3-1.1,4.7c-0.3,1.8-0.5,3.1-0.5,4c0,2.3,0.5,3.8,1.5,4.7c1,0.8,2.8,1.2,5.3,1.2c1.2,0,2.5-0.2,4-0.6
		C100.8,125.4,101.9,125,102.6,124.7z M103.8,52.5c0,3-1.1,5.6-3.4,7.7c-2.3,2.1-5,3.2-8.2,3.2c-3.2,0-5.9-1.1-8.2-3.2
		c-2.3-2.1-3.4-4.7-3.4-7.7c0-3,1.1-5.6,3.4-7.7c2.3-2.1,5-3.2,8.2-3.2c3.2,0,5.9,1.1,8.2,3.2C102.7,47,103.8,49.5,103.8,52.5z"
                />
              </g>
            </svg>
            <p>{{ $t('navbar.about') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>
      <!--nav-search-->
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/search')">
          <div class="nav-item-obj">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 498.75 498.74">
              <defs>
                <style>
                  .cls-1 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <!--title>Asset 8</title-->
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2-2" data-name="Layer 1">
                  <path
                    class="cls-1"
                    d="M375.73,330.48A206.64,206.64,0,0,0,416,211.13C417.68,97.87,327.6,3.5,214.38.1,94.68-3.5-3.47,94.62.09,214.31,3.46,327.55,97.82,417.66,211.1,416a206.7,206.7,0,0,0,119.39-40.25L444.13,489.37a32,32,0,0,0,45.25,0h0a32,32,0,0,0,0-45.24L375.73,330.48ZM208,352c-79.41,0-144-64.59-144-144S128.59,64,208,64s144,64.59,144,144S287.4,352,208,352Z"
                  />
                </g>
              </g>
            </svg>

            <p>{{ $t('navbar.search') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>

      <!-- m-nav-scheme -->
      <b-nav-item
        class="m-hide-when-wide"
        :class="togglenav"
        @click="accordionNav"
      >
        <div
          class="nav-item-obj"
          :class="schemeAccordionDisplay ? 'nuxt-link-exact-active' : ''"
        >
          <!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
          <svg
            id="Layer_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background: new 0 0 512 512"
            xml:space="preserve"
          >
            <g>
              <path
                class="st0 fill"
                d="M502.4,177.3l-240-104c-4.1-1.8-8.7-1.8-12.7,0l-240,104c-12.8,5.6-12.8,23.8,0,29.4L96,244.1V344
		c0,53.8,70.3,96,160,96s160-42.2,160-96v-99.9l16-6.9V408c0,8.8,7.2,16,16,16c8.8,0,16-7.2,16-16V223.3l38.4-16.6
		C515.2,201.1,515.2,182.9,502.4,177.3z M256,105.4L455.8,192l-15.8,6.9L258,176.1c-8.8-1.1-16.8,5.1-17.9,13.9
		c-1.1,8.8,5.1,16.8,13.9,17.9l128.2,16L256,278.6c-112.9-48.9-88.8-38.5-199.8-86.6L256,105.4z M384,344
		c0,15.1-12.4,30.6-33.9,42.6c-51.4,28.5-136.6,28.6-188.1,0c-21.6-12-33.9-27.5-33.9-42.6v-86l121.6,52.7c4.1,1.8,8.7,1.8,12.7,0
		L384,258V344z"
              />
            </g>
          </svg>
          <p>{{ $t('home.schemes') }}</p>
        </div>
      </b-nav-item>
      <div
        class="m-nav-accordion"
        :class="
          schemeAccordionDisplay
            ? 'm-nav-accordion-show'
            : 'm-nav-accordion-hide'
        "
      >
        <b-nav-item
          v-for="n in getSchemes"
          :key="n.id"
          :to="localePath(`/scheme/${n.slug}`)"
          :class="togglenav"
          no-body
          @click="toggleNav"
        >
          {{ i18nTitle(n) }}
        </b-nav-item>
      </div>

      <!-- Application -->
      <b-nav-item :class="togglenav" @click="toggleLoginNav">
        <nuxt-link :to="localePath('application')">
          <div class="nav-item-obj">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.33 64.92">
              <defs>
                <style>
                  .cls-1 {
                    fill: #fff;
                    stroke: #fff;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 6px;
                  }
                </style>
              </defs>
              <!--title>Asset 11</title-->
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2-3" data-name="Layer 1">
                  <rect
                    class="cls-1"
                    x="3"
                    y="3"
                    width="21.33"
                    height="21.33"
                  />
                  <line class="cls-1" x1="41" y1="5.83" x2="75.33" y2="5.83" />
                  <line
                    class="cls-1"
                    x1="41"
                    y1="21.51"
                    x2="75.33"
                    y2="21.51"
                  />
                  <rect
                    class="cls-1"
                    x="3"
                    y="40.59"
                    width="21.33"
                    height="21.33"
                  />
                  <line
                    class="cls-1"
                    x1="41"
                    y1="43.41"
                    x2="75.33"
                    y2="43.41"
                  />
                  <line
                    class="cls-1"
                    x1="41"
                    y1="59.09"
                    x2="75.33"
                    y2="59.09"
                  />
                </g>
              </g>
            </svg>

            <p>{{ $t('navbar.application') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>

      <!-- Shopping Cart -->
      <b-nav-item :class="togglenav" @click="toggleLoginNav">
        <nuxt-link :to="localePath('shoppingCart')">
          <div class="nav-item-obj">
            <b-icon icon="cart-fill" />
            <p>{{ $t('navbar.shoppingCart') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>

      <!-- Bookmark -->
      <b-nav-item :class="togglenav" @click="toggleLoginNav">
        <nuxt-link :to="localePath('bookmark')">
          <div class="nav-item-obj">
            <svg
              style="height: 28px; width: 18px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 58 73.37"
            >
              <defs>
                <style>
                  .cls-1 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <!--title>Asset 9</title-->
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2-4" data-name="Layer 1">
                  <path
                    class="cls-1"
                    d="M52.29,72.53l-21-18.13a3.46,3.46,0,0,0-4.52,0l-21,18.13A3.45,3.45,0,0,1,0,69.91v-63A6.88,6.88,0,0,1,6.88,0H51.12A6.88,6.88,0,0,1,58,6.88v63A3.45,3.45,0,0,1,52.29,72.53Z"
                  />
                </g>
              </g>
            </svg>

            <p>{{ $t('navbar.bookmark') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>

      <b-nav-item :class="togglenav" @click="toggleNav">
        <a @click="showNewsModal">
          <div class="nav-item-obj">
            <svg
              id="Icon_News"
              data-name="Icon News"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 20 16.87"
            >
              <defs>
                <style>
                  .cls-2 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <!-- <title>Icon-News</title> -->
              <path
                id="Combined_Shape"
                data-name="Combined Shape"
                class="cls-2"
                d="M2.92,17.45,1.49,13.56H1.26A1.26,1.26,0,0,1,0,12.29V7.17A1.27,1.27,0,0,1,1.26,5.91H4.8a.4.4,0,0,1,.4.4h0v6.85a.4.4,0,0,1-.4.4h0l1,2.84a1.48,1.48,0,0,1,0,1.14,1.51,1.51,0,0,1-.84.78l-.08,0a1.52,1.52,0,0,1-.52.09A1.5,1.5,0,0,1,2.92,17.45Zm13.65.27h0a10.23,10.23,0,0,0-8.72-4.84H6V6.58H7.84a10.24,10.24,0,0,0,8.73-4.83.4.4,0,0,1,.74.21V17.5a.4.4,0,0,1-.28.38l-.12,0a.4.4,0,0,1-.34-.18ZM18.11,6.88a3.09,3.09,0,0,1,0,5.7Z"
                transform="translate(0 -1.56)"
              />
            </svg>

            <p>{{ $t('navbar.news') }}</p>
          </div>
        </a>
      </b-nav-item>

      <!-- Recommend Events -->
      <!-- <b-nav-item :class="togglenav" @click="toggleNav">
        <div class="nav-item-obj">
          <b-icon icon="star-fill" />

          <p>{{ $t('navbar.recommend') }}</p>
        </div>
      </b-nav-item> -->

      <!-- Feedback -->
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/feedback')">
          <div class="nav-item-obj">
            <b-icon icon="chat-dots-fill" />

            <p>{{ $t('navbar.feedback') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>

      <b-nav-item
        v-if="isAdmin && api && !api.includes('preview')"
        :class="togglenav"
      >
        <nuxt-link :to="`/tc/admin`">
          <div class="nav-item-obj">
            <p>{{ $t('navbar.admin_panel') }}</p>
          </div>
        </nuxt-link>
      </b-nav-item>

      <b-nav-item
        v-if="loggedIn"
        class="m-hide-when-wide"
        :class="togglenav"
        @click="logout"
      >
        <div class="nav-item-obj">
          <svg
            id="Layer_1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 320.002 320.002"
            style="enable-background: new 0 0 320.002 320.002"
            xml:space="preserve"
          >
            <g id="XMLID_6_">
              <path
                id="XMLID_7_"
                class="fill"
                d="M51.213,175.001h173.785c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15H51.213l19.394-19.394
		c5.858-5.858,5.858-15.355,0-21.213c-5.857-5.858-15.355-5.858-21.213,0L4.396,149.393c-0.351,0.351-0.683,0.719-0.997,1.103
		c-0.137,0.167-0.256,0.344-0.385,0.515c-0.165,0.22-0.335,0.435-0.488,0.664c-0.14,0.209-0.261,0.426-0.389,0.64
		c-0.123,0.206-0.252,0.407-0.365,0.619c-0.118,0.22-0.217,0.446-0.323,0.67c-0.104,0.219-0.213,0.435-0.306,0.659
		c-0.09,0.219-0.164,0.442-0.243,0.664c-0.087,0.24-0.179,0.477-0.253,0.722c-0.067,0.222-0.116,0.447-0.172,0.672
		c-0.063,0.249-0.133,0.497-0.183,0.751c-0.051,0.259-0.082,0.521-0.119,0.782c-0.032,0.223-0.075,0.443-0.097,0.669
		c-0.048,0.484-0.073,0.971-0.074,1.457c0,0.007-0.001,0.015-0.001,0.022c0,0.007,0.001,0.015,0.001,0.022
		c0.001,0.487,0.026,0.973,0.074,1.458c0.022,0.223,0.064,0.44,0.095,0.661c0.038,0.264,0.069,0.528,0.121,0.79
		c0.05,0.252,0.119,0.496,0.182,0.743c0.057,0.227,0.107,0.456,0.175,0.681c0.073,0.241,0.164,0.474,0.248,0.71
		c0.081,0.226,0.155,0.453,0.247,0.675c0.091,0.22,0.198,0.431,0.3,0.646c0.108,0.229,0.21,0.46,0.33,0.685
		c0.11,0.205,0.235,0.4,0.354,0.599c0.131,0.221,0.256,0.444,0.4,0.659c0.146,0.219,0.309,0.424,0.466,0.635
		c0.136,0.181,0.262,0.368,0.407,0.544c0.299,0.364,0.616,0.713,0.947,1.048c0.016,0.016,0.029,0.034,0.045,0.05l45,45.001
		c2.93,2.929,6.768,4.394,10.607,4.394c3.838-0.001,7.678-1.465,10.606-4.393c5.858-5.858,5.858-15.355,0.001-21.213L51.213,175.001
		z"
              />
              <path
                id="XMLID_8_"
                class="fill"
                d="M305.002,25h-190c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15s15-6.716,15-15V55h160v210.001h-160
		v-45.001c0-8.284-6.716-15-15-15s-15,6.716-15,15v60.001c0,8.284,6.716,15,15,15h190c8.284,0,15-6.716,15-15V40
		C320.002,31.716,313.286,25,305.002,25z"
              />
            </g>
          </svg>
          <p>{{ $t('navbar.logout') }}</p>
        </div>
      </b-nav-item>
      <div class="navicon-container align-center" @click="toggleNav">
        <div class="navicon"></div>
        <div class="navicon"></div>
        <div class="navicon"></div>
      </div>
    </b-nav>
    <news-modal ref="newsmodal"></news-modal>
  </div>
</template>

<script>
import NewsModal from '~/components/home/newsModal.vue'
export default {
  name: 'NavBar',
  components: { NewsModal },
  async fetch() {
    try {
      if (this.schemeStored.length) {
        this.schemeAll = this.schemeStored.map((a) => ({ ...a }))
        // console.log('this.schemeAll ' + this.schemeAll)
      } else {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}schemes/public/scheme/name`
        )
        this.schemeAll = result.data.data
        this.$store.commit(
          'tempStorage/importPortalSchemes',
          result.data.data.map((a) => ({ ...a }))
        )
      }
      this.schemeOnly = this.schemeAll.filter((s) => s.parent === null)
      // console.log('schemeAll ' + JSON.stringify(this.schemeAll))
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      api: process.env.mainApiUrl,
      config: {},
      togglenav: null,
      isActive: false,
      news: [],
      mNavDisplay: false,
      schemeAccordionDisplay: false,
      schemeAll: [],
      schemeOnly: [],
      schemes: [],
    }
  },
  computed: {
    username() {
      return this.$store.state.localStorage.auth.user
    },
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
    isAdmin() {
      return this.$store.state.localStorage.auth.admin
    },
    getSchemes() {
      return this.schemeOnly
    },
    schemeStored() {
      return this.$store.state.tempStorage.schemesPortal
    },
  },
  methods: {
    showNewsModal() {
      this.$refs.newsmodal.showModal()
    },
    // i18n
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    toggleNav() {
      /* if (window.innerWidth <= 768) {
        this.togglenav = this.togglenav != null ? null : 'mobile-nav'
        // console.log(this.togglenav)
      } */
      this.mNavDisplay = !this.mNavDisplay
      this.schemeAccordionDisplay = false
      this.$parent.set_nav_displaying()
    },
    toggleLoginNav() {
      if (this.loggedIn) {
        this.toggleNav()
      } else {
        // do nothing
      }
    },
    accordionNav() {
      this.schemeAccordionDisplay = !this.schemeAccordionDisplay
    },
    logout() {
      this.toggleNav()
      this.$parent.$refs.mProfile.logout()
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-first-child {
  padding-top: 30px;
}

.modal-footer {
  min-height: 100px;
}

.icon-news {
  padding-right: 4px;
  vertical-align: sub;
}
.icon {
  padding-right: 10px;
  vertical-align: sub;
}

.user-wrapper {
  background-color: #fff;
  padding-top: 60px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding: 10px;
  align-self: center;
  margin: auto;
}

.nav-item {
  align-self: center;
  margin: 0;
}
.navbar-nav {
  padding-top: 45px;
}

.nav {
  display: block;
}
.navicon {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
}

.navmenu {
  width: 100%;
  display: inline-grid;
}

a {
  color: #fff;
}

img {
  height: 25px;
}
.nav-item-obj {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 5px;
}
svg {
  height: 30px;
  width: 20px;
}

.fill {
  fill: white;
}

.nuxt-link-exact-active {
  // .nav-item-obj {
  //   background: #fff;
  // }
  p {
    color: #085b57;
  }
  svg {
    fill: #085b57;
  }
  .cls-1 {
    fill: #085b57;
    stroke: #085b57;
  }
  .cls-2 {
    fill: #085b57;
    stroke: #085b57;
  }
  .st0 {
    stroke: #085b57;
  }
  .fill {
    fill: #085b57;
  }
}
.navbar-nav .nav-link {
  padding: 0;
  &:hover {
    a,
    p {
      color: #085b57;
    }
    svg {
      fill: #085b57;
    }
    .cls-1 {
      fill: #085b57;
      stroke: #085b57;
    }
    .cls-2 {
      fill: #085b57;
      stroke: #085b57;
    }
    .st0 {
      stroke: #085b57;
    }
    a {
      text-decoration: none;
    }
    .fill {
      fill: #085b57;
    }
  }
}
li {
  padding-left: 2px;
  padding-right: 2px;
}

p {
  font-size: 15px;
}
/* @media (min-width: 768px) { */
.navmenu {
  background-color: #1cbcb4;
  min-height: 100vh;
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  top: 0px;
  overflow-x: hidden;
  overflow-y: visible;
  padding-top: 20px;
  width: 100px;
  margin-top: 0px;
}

.navicon-container {
  display: none;
}

/* Responsive Edit */
.navbar-nav {
  display: block;
}

.nav-item-obj svg {
  float: none;
}

.m-hide,
.m-show {
  display: block;
}

.m-hide-when-wide {
  display: none;
}

.m-nav-accordion {
  display: none;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  p {
    font-size: 100%;
  }
  .m-nav-accordion {
    display: block;

    width: 80%;
    margin: 0 auto;
    transition: all 0.2s ease;
    background: rgba(255, 255, 255, 0.15);
    color: white;
    text-align: left;
    overflow: hidden;
    border-radius: 5px;
    font-size: 1rem;
  }

  .m-nav-accordion-hide {
    height: 0px;
    padding: 0px 10px;
  }

  .m-nav-accordion-show {
    height: fit-content;
    padding: 10px;
    margin-bottom: 0px;
  }

  .m-nav-accordion .nav-item {
    padding: 10px 2px;
    padding-left: 10px;
  }

  .m-nav-accordion .nav-item:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  a.nuxt-link-exact-active {
    color: #085b57;
  }

  .m-hide {
    right: -100%;
  }

  .m-show {
    right: 0;
  }

  .m-hide-when-wide {
    display: block;
  }

  .navmenu {
    min-height: unset;
    height: 100%;
    width: 100%;
    z-index: 4;
    transition: all 0.5s ease;
    left: auto;

    border-left: 12px #085b57 solid; // eye focus
    border-right: 12px #085b57 solid;
  }

  .navmenu::-webkit-scrollbar {
    width: 8px;
  }

  .navmenu::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
  }

  .navmenu::-webkit-scrollbar-thumb {
    background: #75d6d1;
  }

  .navmenu::-webkit-scrollbar-thumb:hover {
    background: #0b9790;
  }

  .nav-first-child {
    padding-top: 80px;
  }

  .m-navbar-nav {
    display: block;
    padding-top: 45px;
  }

  .nav-item-obj {
    width: 80%;
    margin: 0 auto;
  }

  .nav-item-obj svg {
    float: left;
  }

  .nav-item-obj p {
    text-align: left;
    padding-top: 5px;
    padding-left: 48px;
  }
}

/* } */
/*
@media (max-width: 767px) {
  .navmenu {
    background-color: #1cbcb4;
    height: auto;
    width: 100%;
    margin-top: 70px;
  }
  .nav-item {
    display: none;
  }

  .mobile-nav {
    display: block;
  }

  .navicon-container {
    display: block;
  }
} */
</style>
