var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-cont" }, [
    _c("div", { staticClass: "event-desc-cont" }, [
      _c("div", { staticClass: "event-field" }, [
        _c("div", { staticClass: "event-title" }, [
          _vm._v(_vm._s(_vm.$t("apply.scds.tab1.schTc"))),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.school.name_tc))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "event-field" }, [
        _c("div", { staticClass: "event-title" }, [
          _vm._v(_vm._s(_vm.$t("apply.scds.tab1.schEn"))),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v(_vm._s(_vm.school.name_en))]),
      ]),
      _vm._v(" "),
      _vm.admin && !_vm.$route.name.includes("change_request")
        ? _c("div", { staticClass: "event-field" }, [
            _c(
              "div",
              {
                staticClass: "event-title",
                staticStyle: { "min-width": "115px" },
              },
              [_vm._v("\n        " + _vm._s("學校類別註明") + "\n      ")]
            ),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.schoolCatListOut(_vm.edbCat)))]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "slot-title" }, [
      _c("div", { staticClass: "event-title" }, [
        _vm._v(_vm._s(_vm.$t("apply.scds.tab1.title1"))),
      ]),
    ]),
    _vm._v(" "),
    !_vm.edit
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _vm.user
              ? _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "slot-form" }, [
                    _c("div", { staticClass: "form-title" }, [
                      _c("div", { staticClass: "ordering" }, [_vm._v("1")]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(_vm._s(_vm.$t("apply.scds.tab1.subtitle1"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-cont" }, [
                      _c("div", { staticClass: "event-title mt-3" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("apply.scds.tab1.name")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.user.name))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-title" }, [
                        _vm._v(_vm._s(_vm.$t("apply.scds.tab1.email"))),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.user.email))]),
                      _vm._v(" "),
                      _vm.hasTel
                        ? _c("div", [
                            _c("div", { staticClass: "event-title" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("apply.scds.tab1.tel")) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.user.tel ? _vm.user.tel : "/")),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.passTeachers, function (t, counter) {
              return _c("div", { key: counter, staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "slot-form" }, [
                  _c("div", { staticClass: "form-title" }, [
                    _c("div", { staticClass: "ordering" }, [
                      _vm._v(_vm._s(counter + 2)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.subtitle1"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-cont" }, [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.name"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(t.name))]),
                    _vm._v(" "),
                    _c("div", { staticClass: "event-title" }, [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.email"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(t.email ? t.email : "/"))]),
                    _vm._v(" "),
                    _c("div", { staticClass: "event-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("apply.scds.tab1.tel")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(t.tel ? t.tel : "/"))]),
                  ]),
                ]),
              ])
            }),
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "row" },
          [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "slot-form" }, [
                _c("div", { staticClass: "form-title" }, [
                  _c("div", { staticClass: "ordering" }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("apply.scds.tab1.subtitle1"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-cont" }, [
                  _c("div", { staticClass: "event-title mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("apply.scds.tab1.name"))),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.user.name))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "event-title" }, [
                    _vm._v(_vm._s(_vm.$t("apply.scds.tab1.email"))),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.user.email))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "event-title" }, [
                    _vm._v(_vm._s(_vm.$t("apply.scds.tab1.tel"))),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.user.tel ? _vm.user.tel : "/"))]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.teachers, function (t, counter) {
              return _c("div", { key: counter, staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "slot-form" }, [
                  _c("div", { staticClass: "form-title" }, [
                    _c("div", { staticClass: "ordering" }, [
                      _vm._v(_vm._s(counter + 2)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.subtitle1"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cross",
                        on: {
                          click: function ($event) {
                            return _vm.delForm(counter)
                          },
                        },
                      },
                      [_vm._v("×")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-cont" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "event-title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("apply.scds.tab1.name"))
                          ),
                          _c("span", [_vm._v("*")]),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: t.name,
                              expression: "t.name",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "apply.scds.tab1.placeholderName"
                            ),
                            required: "",
                          },
                          domProps: { value: t.name },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(t, "name", $event.target.value)
                              },
                              _vm.inputChanged,
                            ],
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "event-title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("apply.scds.tab1.email"))
                          ),
                          _c("span", [_vm._v("*")]),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: t.email,
                              expression: "t.email",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "email",
                            placeholder: _vm.$t(
                              "apply.scds.tab1.placeholderEmail"
                            ),
                            required: "",
                          },
                          domProps: { value: t.email },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(t, "email", $event.target.value)
                              },
                              _vm.inputChanged,
                            ],
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "event-title" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("apply.scds.tab1.tel"))
                          ),
                          _c("span", [_vm._v("*")]),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: t.tel,
                              expression: "t.tel",
                            },
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "apply.scds.tab1.placeholderTel"
                            ),
                          },
                          domProps: { value: t.tel },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(t, "tel", $event.target.value)
                              },
                              _vm.inputChanged,
                            ],
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            }),
          ],
          2
        ),
    _vm._v(" "),
    _vm.edit && _vm.teachers.length < _vm.noOfAdditionalTeacher
      ? _c(
          "div",
          {
            staticClass: "btn btn-outline-abo",
            on: {
              click: function ($event) {
                return _vm.addTeacher()
              },
            },
          },
          [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("apply.scds.tab1.addTeacher")) + "\n  "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "slot-title" }, [
        _c("div", { staticClass: "event-title" }, [
          _vm._v(_vm._s(_vm.$t("apply.scds.tab1.title2"))),
        ]),
      ]),
      _vm._v(" "),
      _vm.isScdsEventWithNoSlot && _vm.checkSpecificID()
        ? _c("div", { staticStyle: { "text-align": "left" } }, [
            _vm.$i18n.locale === "en"
              ? _c("div", [
                  _vm._v(
                    "\n        Please specify the exhibition panel number (E1-E9) and/or multimedia\n        video code (1-43).\n        "
                  ),
                  _vm._m(0),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$i18n.locale === "tc"
              ? _c("div", [
                  _vm._v(
                    "\n        請填寫擬借用的展覽圖板（E1-E9）及/或多媒體視像節目編號（1-43）以及借用日期（不多於三星期）。\n        "
                  ),
                  _vm._m(1),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$i18n.locale === "sc"
              ? _c("div", [
                  _vm._v(
                    "\n        请填写拟借用的展览图板（E1-E9）及/或多媒体视像节目编号（1-43）以及借用日期（不多于三星期）。\n        "
                  ),
                  _vm._m(2),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            {
              staticClass: "slot-form",
              style: _vm.admin ? "min-height: 172.5px" : "min-height: 110px",
            },
            [
              _vm.admin && !_vm.$route.name.includes("change_request")
                ? _c("div", { staticClass: "form-title" }, [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("apply.scds.tab1.subtitle2a"))
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "form-title" }, [
                    _c("div", { staticClass: "ordering" }, [_vm._v("1")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.subtitle2e"))),
                    ]),
                  ]),
              _vm._v(" "),
              _vm.edit && !_vm.admin
                ? _c(
                    "div",
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "4", "max-rows": "20" },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.remarks.byTeacher,
                          callback: function ($$v) {
                            _vm.$set(_vm.remarks, "byTeacher", $$v)
                          },
                          expression: "remarks.byTeacher",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.edit && _vm.$route.name.includes("change_request")
                ? _c(
                    "div",
                    { staticStyle: { padding: "15px" } },
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "4", "max-rows": "20" },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.remarks.byTeacher,
                          callback: function ($$v) {
                            _vm.$set(_vm.remarks, "byTeacher", $$v)
                          },
                          expression: "remarks.byTeacher",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticStyle: { padding: "15px" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.remarks.byTeacher ? _vm.remarks.byTeacher : "/"
                        ) +
                        "\n          "
                    ),
                  ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.admin && !_vm.$route.name.includes("change_request")
          ? _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  staticClass: "slot-form",
                  staticStyle: { "min-height": "172.5px" },
                },
                [
                  _c("div", { staticClass: "form-title" }, [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("apply.scds.tab1.subtitle2b"))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.edit
                    ? _c(
                        "div",
                        { staticStyle: { padding: "15px" } },
                        [
                          _vm.edit
                            ? _c("b-form-textarea", {
                                attrs: { rows: "4", "max-rows": "20" },
                                on: { input: _vm.inputChanged },
                                model: {
                                  value: _vm.remarks.byABO2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.remarks, "byABO2", $$v)
                                  },
                                  expression: "remarks.byABO2",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", { staticStyle: { padding: "15px" } }, [
                        _vm._v(_vm._s(_vm.remarks.byABO2)),
                      ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.admin && !_vm.$route.name.includes("change_request")
          ? _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                {
                  staticClass: "slot-form",
                  staticStyle: { "min-height": "80px" },
                },
                [
                  _c("div", { staticClass: "form-title" }, [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("apply.scds.tab1.subtitle2c"))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.edit
                    ? _c(
                        "div",
                        { staticStyle: { padding: "15px" } },
                        [
                          _vm.edit
                            ? _c("b-form-textarea", {
                                attrs: { rows: "4", "max-rows": "20" },
                                on: { input: _vm.inputChanged },
                                model: {
                                  value: _vm.remarks.byABO,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.remarks, "byABO", $$v)
                                  },
                                  expression: "remarks.byABO",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("div", { staticStyle: { padding: "15px" } }, [
                        _vm._v(_vm._s(_vm.remarks.byABO)),
                      ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            {
              staticClass: "slot-form",
              style: _vm.admin ? "min-height: 172.5px" : "min-height: 110px",
            },
            [
              _vm.admin
                ? _c("div", { staticClass: "form-title" }, [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("apply.scds.tab1.subtitle2d"))
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "form-title" }, [
                    _c("div", { staticClass: "ordering" }, [_vm._v("2")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.subtitle2d"))),
                    ]),
                  ]),
              _vm._v(" "),
              _vm.edit &&
              _vm.admin &&
              !_vm.$route.name.includes("change_request")
                ? _c(
                    "div",
                    { staticStyle: { padding: "15px" } },
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "4", "max-rows": "20" },
                        on: { input: _vm.inputChanged },
                        model: {
                          value: _vm.remarks.event,
                          callback: function ($$v) {
                            _vm.$set(_vm.remarks, "event", $$v)
                          },
                          expression: "remarks.event",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticStyle: { padding: "15px" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.remarks.event ? _vm.remarks.event : "/") +
                        "\n          "
                    ),
                  ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "scdsEventWithNoSlot" }, [
      _vm._v("\n          Example:"),
      _c("br"),
      _vm._v("\n          Exhibition panel number: E1"),
      _c("br"),
      _vm._v("\n          Multimedia video code: 1"),
      _c("br"),
      _vm._v("\n          Date: 1/9/2021 - 22/9/2021\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "scdsEventWithNoSlot" }, [
      _vm._v("\n          例如："),
      _c("br"),
      _vm._v("\n          展覽圖板：E1"),
      _c("br"),
      _vm._v("\n          多媒體視像節目編號：1"),
      _c("br"),
      _vm._v("\n          借用日期： 1/9/2021 - 22/9/2021\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "scdsEventWithNoSlot" }, [
      _vm._v("\n          例如："),
      _c("br"),
      _vm._v("\n          展览图板：E1"),
      _c("br"),
      _vm._v("\n          多媒体视像节目编号：1"),
      _c("br"),
      _vm._v("\n          借用日期： 1/9/2021 - 22/9/2021\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }