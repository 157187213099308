<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="9" style="font-size: 0.9em">
      <div>
        <div>報名方法</div>
        <div style="display: flex" class="mb-3">
          <b-form-radio-group
            v-model="applyMethod"
            :options="applyMethodOption"
            :state="!!applyMethod"
            required
          ></b-form-radio-group>
          <b-form-input
            v-model="applyMethodOther"
            type="text"
            maxlength="1000"
            style="width: 200px"
          />
        </div>
        <div class="mb-1">報名遞交時間（YYYY-MM-DD HH:mm:ss）</div>
        <b-form-input
          v-model="submitTime"
          :state="submitTimeState(submitTime)"
          type="text"
          style="width: 300px"
          class="mb-3"
        ></b-form-input>

        <div class="mb-1">申請編號（自動產生）</div>
        <b-form-input
          v-model="trnId"
          class="mb-4"
          type="text"
          readonly
        ></b-form-input>

        <b-form-group label-for="form" label="匯入申請（OGCIO .zip）">
          <b-form-file
            v-if="action === 'add'"
            id="form"
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
            @change="uploadForm"
          />
        </b-form-group>
        <!-- {{ data }} -->
        <div>
          <div class="mt-4 mb-1">附件</div>
          <p v-for="(a, index) in attr" :key="index">
            <a :href="`${attachmentUrl}${a}`" target="_blank">
              <b-icon icon="paperclip"></b-icon>{{ a }}
            </a>
          </p>
        </div>
      </div>

      <b-form @submit="onSubmit">
        <div class="shadow">
          <matching-school
            :data="data"
            :sch-id="sarId"
            :name="schoolData ? schoolData.name_tc : ''"
            @input="onChangeSkl"
          />
        </div>
        <div class="shadow">
          <applySchoolInfo
            :promo="disagreePromo"
            :teacher-data="teachers"
            :school-data="schoolData"
            @change="onSchoolInfoChange"
          />
        </div>

        <event-slot-modal
          :event-slots="getEventSlot"
          :parent-scheme-id="5"
          :is-admin="true"
          @input="getSelectedSlots"
        ></event-slot-modal>

        <div class="shadow">
          <div class="form-section">
            <h3>3. 擬參加活動<br /></h3>
            <div v-for="(activity, index) in activityList" :key="index">
              <fieldset class="topLeftInsideLegend">
                <legend>
                  <span class="sr-only">行</span># {{ index + 1 }}
                </legend>

                <div>
                  <!-- <h3>3.2 檢查/輸入資料</h3> -->
                  <div class="mb-3">
                    <div class="subtitle">節目名稱及票價</div>
                    <b-form-input
                      v-model="activity.eventNamePrice"
                      type="text"
                      maxlength="1000"
                      readonly
                    />
                  </div>

                  <div class="py-3">
                    <!-- <h3>3.1 配對資料庫活動</h3> -->
                    <div>配對活動</div>
                    <b-form-select
                      v-model="activity.event_id"
                      :options="suggestEventList"
                      :state="!!activity.event_id"
                      size="sm"
                      class="mt-1"
                      required
                    ></b-form-select>
                    <!-- <v-select
                    :options="suggestEventList"
                    :value="activity.event_id"
                    placeholder="Select Event"
                    required
                    @input="(c) => updateEvent(c, index)"
                  ></v-select> -->
                    <div class="layout-inline mt-3 mb-3">
                      已選擇：
                      <span
                        :class="
                          !activity.event_id ? 'not_selected' : 'resultskl'
                        "
                      >
                        {{
                          activity.event_id
                            ? suggestEventList.find(
                                (d) => d.id === activity.event_id
                              ).label
                            : '未選擇'
                        }}
                      </span>
                    </div>
                    <!-- <b-form-input
                    v-model="activity.event_id"
                    type="text"
                    readonly
                    :state="!!activity.event_id"
                  ></b-form-input> -->
                  </div>

                  <hr style="width: 95%" />

                  <div class="subtitle">日期、時間及地點</div>
                  <b-form-input
                    v-model="activity.dateTime"
                    type="text"
                    maxlength="1000"
                    class="mb-3"
                    readonly
                  />
                  <div class="py-3">
                    <div>配對活動場次 #{{ index + 1 }}</div>
                    <b-button
                      v-b-modal.modal-eventslot
                      size="sm"
                      variant="primary"
                      style="display: block"
                      class="mt-1"
                      @click="
                        () => {
                          selectedEventIndex = index
                          selectedEventId = activity.event_id
                            ? activity.event_id
                            : null
                        }
                      "
                    >
                      選擇場次 <b-icon icon="hand-index-thumb"></b-icon>
                    </b-button>
                    <!-- <v-select
                      :options="suggestEventSlotList1"
                      :value="activity.event_slot_1"
                      placeholder="Select Event"
                      required
                      @input="(c) => updateEventSlot1(c, index)"
                    ></v-select> -->
                    <div style="display: none">{{ tmpEventSlot1 }}</div>
                    <div class="layout-inline mt-3 mb-4">
                      已選擇場次：
                      <span
                        :class="
                          !activity.event_slot_1 ? 'not_selected' : 'resultskl'
                        "
                      >
                        {{
                          activity.event_slot_1
                            ? activity.event_slot_1
                            : '未選擇'
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="layout-default layout-group layout-inline-group">
                    <div
                      id="activityList.0.firstChoiceStudentNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.firstChoiceStudentNo"
                        ><div
                          id="activityList.0.firstChoiceStudentNo_label"
                          class="required"
                        >
                          <div class="subtitle">學生人數</div>
                          <span class="sr-only">必須提供</span>
                        </div>
                        <b-form-input
                          v-model="activity.studentNo"
                          type="tel"
                          name="activityList.0.firstChoiceStudentNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                    <div
                      id="activityList.0.firstChoiceTeacherNo_panel"
                      class="field form-group layout-inline"
                    >
                      <label for="activityList.0.firstChoiceTeacherNo"
                        ><div
                          id="activityList.0.firstChoiceTeacherNo_label"
                          class="required"
                        >
                          <div class="subtitle">老師人數</div>
                          <span class="sr-only">必須提供</span>
                        </div>
                        <b-form-input
                          v-model="activity.teacherNo"
                          type="tel"
                          name="activityList.0.firstChoiceTeacherNo"
                          class="form-control"
                          maxlength="15"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          value=""
                          style="width: 104px"
                      /></label>
                    </div>
                    <div class="field form-group layout-inline">
                      <div class="subtitle">
                        學生人數 (綜合社會保障援助受惠人士) (如適用)^
                      </div>

                      <b-form-input
                        v-model="activity.cssaStudentNo"
                        type="number"
                        maxlength="15"
                        style="width: 104px"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="fieldListButtons">
                <button
                  v-if="activityList.length > 1"
                  aria-label="刪除活動"
                  type="button"
                  class="btn btn-outline-danger mb-3"
                  @click="delActivity(index)"
                >
                  <span class="icon-plus-circled"></span>
                  刪除活動 {{ index + 1 }}
                </button>
              </div>
            </div>
            <div class="fieldListButtons my-3">
              <button
                aria-label="新增活動"
                type="button"
                class="btn btn-outline-success"
                @click="addActivity()"
              >
                <span class="icon-plus-circled"></span> 新增活動
              </button>
            </div>
            <br />
          </div>
        </div>
        <b-button type="submit" variant="success"> 提交 </b-button>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import applySchoolInfo from '~/components/admin/application/applySchoolInfo'
import 'vue-select/dist/vue-select.css'
export default {
  layout: 'admin',
  middleware: ['Admin', 'jwtCheck'],
  components: {
    // vSelect,
    applySchoolInfo,
  },
  props: ['action'],
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/public/getId/${this.$route.params.slug}`
      )
      this.schemeId = result.data.data ? result.data.data[0].id : null
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const result = await this.$axios.get(
        `${process.env.localApiUrl}schools/sar/school`
      )
      this.allSkls = result.data.data
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/subScheme/slug/${this.$route.params.slug}`
      )
      this.suggestEventList = result.data.data
      this.suggestEventList.map((d) => {
        d.text = `${d.id}.【${d.code}】${d.title_tc}`
        d.value = d.id
        d.label = `${d.id}.【${d.code}】${d.title_tc}`
        d.eventCode = d.code
        d.code = d.id
        return d
      })
      this.suggestEventList = this.suggestEventList.sort((a, b) => {
        return a.eventCode.localeCompare(b.eventCode)
      })
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      disagreePromo: null,
      schoolData: {
        name_tc: null,
        name_en: null,
        category: null,
        region: null,
        district: 0,
        address_area: null,
        address_street: null,
        address_building: null,
        tel: null,
        fax: null,
        email: null,
        principal_name: null,
      },
      finishSubmit: 0,
      tmpEventSlot1: null,
      tmpEventSlot2: null,
      data: null,
      trnId: '',
      submitTime: '',
      teachers: [{}],
      activityList: [{}],
      target: [],
      declare: true,
      notReceive: false,
      attr: [],
      sarId: null,
      keyword: '',
      perPage: 15,
      currentPage: 1,
      allSkls: [],
      suggestEventList: [],
      selectedEvent: [],
      suggestEventSlotList: [],
      selectedEventIndex: 0,
      selectedEventId: null,
      targetOption: [
        { value: 'k1', text: this.intToGrade(-2) },
        { value: 'k2', text: this.intToGrade(-1) },
        { value: 'k3', text: this.intToGrade(0) },
        { value: 'p1', text: this.intToGrade(1) },
        { value: 'p2', text: this.intToGrade(2) },
        { value: 'p3', text: this.intToGrade(3) },
        { value: 'p4', text: this.intToGrade(4) },
        { value: 'p5', text: this.intToGrade(5) },
        { value: 'p6', text: this.intToGrade(6) },
        { value: 'f1', text: this.intToGrade(7) },
        { value: 'f2', text: this.intToGrade(8) },
        { value: 'f3', text: this.intToGrade(9) },
        { value: 'f4', text: this.intToGrade(10) },
        { value: 'f5', text: this.intToGrade(11) },
        { value: 'f6', text: this.intToGrade(12) },
      ],
      applyMethodOption: [
        { value: 'E', text: 'OGCIO E-Form' },
        { value: 'F', text: 'Fax' },
        { value: 'M', text: 'Mail' },
        { value: 'O', text: '其他報名方法（請註明）：' },
      ],
      applyMethod: null,
      applyMethodOther: '',
      schemeId: null,
    }
  },
  computed: {
    attachmentUrl() {
      return process.env.attachmentUrl
    },
    getEventSlot() {
      if (this.suggestEventList.length && this.selectedEventId) {
        const found = this.suggestEventList.find(
          (d) => d.id === this.selectedEventId
        )
        return [...found.EventSlot].sort((a, b) =>
          a.date > b.date
            ? 1
            : b.date > a.date
            ? -1
            : a.startTime > b.startTime
            ? 1
            : b.startTime > a.startTime
            ? -1
            : 0
        )
      }
      return []
    },
  },
  watch: {
    applyMethod: {
      handler(newVal, oldVal) {
        if (newVal !== 'E') {
          this.getTrnId()
        }
      },
      deep: true,
    },
    finishSubmit: {
      async handler(newVal, oldVal) {
        if (
          this.activityList.length > 0 &&
          this.activityList.length === newVal
        ) {
          const result2 = await this.$swal({
            title: this.$t('apply.alert_success'),
            html: '成功新增申請',
            icon: 'success',
            customClass: 'swal-wide',
            showCancelButton: false,
            confirmButtonText: this.$t('alert.confirm'),
            confirmButtonColor: '#1cbbb4',
          })
          if (result2.value) {
            this.$router.push(this.localePath(`/application`))
          }
        }
      },
      deep: true,
    },
    tmpEventSlot1: {
      handler(newVal, oldVal) {
        this.activityList[this.selectedEventIndex].event_slot_1 = newVal
      },
      deep: true,
    },
    tmpEventSlot2: {
      handler(newVal, oldVal) {
        this.activityList[this.selectedEventIndex].event_slot_2 = newVal
      },
      deep: true,
    },
  },
  methods: {
    async onChangeSkl(sarId) {
      this.sarId = sarId
      await this.$axios
        .get(`${process.env.localApiUrl}schools/sarid/getSchoolList/${sarId}`)
        .then((result) => {
          if (result.data.data.length > 0) {
            const tmp = result.data.data[0]
            this.schoolData = {
              name_tc: tmp.name_tc,
              name_en: tmp.name_en,
              category: tmp.type,
              region: tmp.region,
              district: tmp.district,
              address_area: tmp.address_area,
              address_street: tmp.address_street,
              address_building: tmp.address_building,
              tel: tmp.tel,
              fax: tmp.fax,
              email: tmp.email,
              principal_name: tmp.principal_name,
            }
          }
        })
    },
    onSchoolInfoChange(schoolData, teachers, promo) {
      this.schoolData = schoolData
      this.teachers = teachers
      this.disagreePromo = promo
    },
    async getTrnId() {
      const result = await this.$axios.get(
        `${process.env.localApiUrl}applications/getTrnId/aes`
      )
      if (result) {
        console.log('Result Not Null: ', result.data)
        this.trnId = result.data.data.trnId
      }
    },
    async onSubmit(event) {
      event.preventDefault()
      let check = true
      this.activityList.forEach((d, i) => {
        if (!d.event_slot_1) {
          alert(`請配對活動場次 #${i + 1}。`)
          check = false
        }
      })
      if (check) {
        const submit = await this.$swal({
          title: this.$t('alert.submit'),
          text: `確定新增 ${this.schoolData.name_tc} 申請紀錄?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: `${this.$t('alert.yes')}`,
          confirmButtonColor: '#28a745', // '#d9534f',
          cancelButtonText: `${this.$t('alert.no')}`,
        })

        if (submit.value) {
          try {
            let result
            await this.activityList.forEach(async (d, i) => {
              if (this.applyMethod === 'E') {
                this.data.applyMethod = this.applyMethod
              }
              const tmp = {
                data: this.schoolData,
                ogcio: this.data ? JSON.stringify(this.data) : '',
                trnId: `${this.trnId}-${i + 1}`,
                formId: 'ABOAESFORM',
                submitTime: this.$moment(this.submitTime)
                  .add(8, 'hours')
                  .format('YYYY-MM-DD HH:mm:ss'),
                activityCode: null,
                event_id: d.event_id,
                activityName: null,
                firstChoiceDate: null,
                firstChoiceTime: null,
                firstChoiceStudentNo: d.studentNo,
                firstChoiceTeacherNo: d.teacherNo,
                firstChoiceParentNo: d.cssaStudentNo,
                // firstChoiceClass: d.firstChoiceClassVerify,
                firstChoiceClass: this.target
                  ? JSON.stringify(this.target)
                  : null,
                event_slot_1: d.event_slot_1,
                secondChoiceDate: null,
                secondChoiceTime: null,
                secondChoiceStudentNo: null,
                secondChoiceTeacherNo: null,
                secondChoiceParentNo: null,
                secondChoiceClass: null,
                event_slot_2: null,
                remarks: {},
                additionTeachers: this.teachers
                  ? JSON.stringify(this.teachers)
                  : [],
                schemeId: this.schemeId,
                userId: null,
                schoolId: this.sarId,
                round: 1,
              }
              result = await this.$axios.$post(
                `${process.env.localApiUrl}applications/import/scds`,
                tmp
              )
              if (result.error !== undefined) {
                throw new Error(result.error)
              }
              this.finishSubmit += 1
            })
            if (result.data) {
              const result2 = await this.$swal({
                title: this.$t('apply.alert_success'),
                html: '成功新增申請',
                icon: 'success',
                customClass: 'swal-wide',
                showCancelButton: false,
                confirmButtonText: this.$t('alert.confirm'),
                confirmButtonColor: '#1cbbb4',
              })
              if (result2.value) {
                const path = this.$router.currentRoute.path
                  .replace('add', 'all')
                  .replace('/tc', '')
                this.$router.push(this.localePath(path))
              }
            }
          } catch (err) {
            console.log(err)
            this.error = this.$t('error.' + err.response.data.error)
          }
        }
      }
    },
    async uploadForm(e) {
      try {
        const params = new FormData()
        params.append('form', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.localApiUrl}files/form`,
          params
        )
        if (await result) {
          const data = result.data
          if (data.status === 'ok') {
            this.applyMethod = 'E'
            this.data = data.data
            this.attr = data.data.attr ? data.data.attr : []
            this.trnId = this.trimData(data.data.submission.submit.trnId)
            this.submitTime = this.trimData(
              data.data.submission.submit.submitTime
            )
            const subData = data.data.submission.data

            this.schoolData.name_tc = this.trimData(subData.schoolNameChi)
            this.schoolData.name_en = this.trimData(subData.schoolNameEng)
            this.schoolData.address_area = this.trimData(subData.address)
            this.schoolData.region = this.trimData(subData.region)
            this.schoolData.district = this.trimData(subData.district)
            this.schoolData.tel = this.trimData(subData.schoolTel)
            this.schoolData.fax = this.trimData(subData.schoolFax)
            this.schoolData.email = this.trimData(subData.schoolEmail)
            this.principalName = `${this.trimData(
              subData.principalName.surname
            )}${this.trimData(subData.principalName.givenName)}`

            this.teachers = []
            this.teachers.push({
              name: `${this.trimData(
                subData.teacherName.surname
              )}${this.trimData(subData.teacherName.givenName)}`,
              email: this.trimData(subData.email),
              tel: this.trimData(subData.schoolMobile),
            })
            if (Array.isArray(subData.eventList)) {
              this.activityList = [...subData.eventList]
            } else {
              this.activityList = []
              this.activityList.push(subData.eventList)
            }

            let tmpTarget = []
            if (Array.isArray(subData.target)) {
              tmpTarget = [...subData.target]
            } else {
              tmpTarget.push(subData.target)
            }
            tmpTarget.forEach((d) => {
              switch (d) {
                case '1':
                  this.target.push('f4')
                  return
                case '2':
                  this.target.push('f5')
                  return
                case '3':
                  this.target.push('f6')
              }
            })
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    submitTimeState(input) {
      const regex = new RegExp(
        '^([0-9]{4})-[0-2][0-9]-[0-3][0-9] [0-2][0-9]:[0-5][0-9]:[0-5][0-9]$'
      )
      return regex.test(input)
    },
    addTeacher() {
      this.teachers.push({})
    },
    delTeacher(index) {
      this.teachers.splice(index, 1)
    },
    addActivity() {
      this.activityList.push({})
    },
    delActivity(index) {
      this.activityList.splice(index, 1)
    },
    trimData(field) {
      return field ? field.trim() : ''
    },
    updateEvent(obj, index) {
      this.activityList[index].event_id = obj ? obj.id : null
    },
    /* updateEventSlot1(obj, index) {
      this.activityList[index].event_slot_1 = obj ? obj.id : null
    },
    updateEventSlot2(obj, index) {
      this.activityList[index].event_slot_2 = obj ? obj.id : null
    }, */
    getSelectedSlots(firstChoice, secondChoice) {
      // this.activityList[this.selectedEventIndex].event_slot_1 = firstChoice
      // this.activityList[this.selectedEventIndex].event_slot_2 = secondChoice
      this.tmpEventSlot1 = firstChoice
      this.tmpEventSlot2 = secondChoice
    },
    intToGrade(i) {
      let result = ''
      switch (this.$i18n.locale) {
        case 'en':
          result =
            i < 1
              ? 'K.'.concat((i + 3).toString())
              : i < 7
              ? 'P.'.concat(i.toString())
              : 'S.'.concat((i - 6).toString())
          break
        case 'tc':
          result =
            i === -2
              ? '幼兒班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        case 'sc':
          result =
            i === -2
              ? '幼儿班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.subtitle {
  font-weight: 500;
}
.field.layout-sibling {
  display: inline-block;
  margin: 0 5px 5px 0;
}
.match-db {
  color: grey;
}
.resultskl {
  color: grey;
  background-color: white;
  padding: 5px 20px;
  line-height: 2.5;
}
.not_selected {
  color: #f76576;
  background-color: white;
  padding: 5px 20px;
}
fieldset.topLeftInsideLegend legend {
  float: left;
  width: auto;
  display: block;
  margin: 0 100% 10px 0;
  padding: 0 10px;
  background: #f5f5f5;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 5px 0;
  font-weight: 700;
  font-size: 12px;
  color: #1cbcb4;
  white-space: normal;
}
fieldset.topLeftInsideLegend {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 0;
  margin-bottom: 10px;
}
fieldset.topLeftInsideLegend > div {
  padding: 0 20px;
}
.layout-inline {
  display: inline-block;
  margin-right: 50px;
}
.shadow-important {
  padding: 10px 30px;
  margin: 30px 0;
  border: 2px solid #fc7137;
}
.shadow {
  padding: 10px 30px;
  margin: 30px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.component {
  text-align: left;
}
</style>
