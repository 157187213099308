<template>
  <div class="app">
    <header class="row header mobile-row">
      <div
        v-if="api && api.includes('preview')"
        pill
        variant="secondary"
        class="preview"
      >
        預覽模式 <b-icon icon="eye" />
      </div>
      <div
        v-if="api && api.includes('abo.lcsd.hksarg')"
        pill
        variant="secondary"
        class="preview"
      >
        內聯網 {{ version }}
      </div>
      <div
        v-if="api && api.includes('abotest.lcsd.hksarg')"
        pill
        variant="secondary"
        class="preview"
      >
        內聯網 (Testing)
      </div>
      <b-col>
        <img
          v-show="$i18n.locale === 'en'"
          class="logoimg"
          :src="`${staticPrefix}img/logo_abo_en.png`"
          alt="logo image"
        />
        <img
          v-show="$i18n.locale === 'tc'"
          class="logoimg"
          :src="`${staticPrefix}img/logo_abo_ch.png`"
          alt="封面圖片"
        />
        <img
          v-show="$i18n.locale === 'sc'"
          class="logoimg"
          :src="`${staticPrefix}img/logo_abo_sc.png`"
          alt="封面图片"
        />
      </b-col>
      <b-col sm="7" md="9" xl="9" class="fontstyle mobile-col-12">
        <div
          v-if="loggedIn"
          class="logined"
          style="color: #fc7137; font-size: 1.3em"
        >
          <!-- <div>
            <a
              v-b-popover.hover.bottom="'構建中...'"
              title="提示"
              class="navBtn"
              ><b-icon icon="bell"
            /></a>
          </div>
          <div>
            <a
              v-b-popover.hover.bottom="'構建中...'"
              title="帳戶設定"
              class="navBtn"
              ><b-icon icon="gear"
            /></a>
          </div> -->
          <div class="usericon" @click="toggleProfile">
            <span> {{ user.name.substring(0, 1) }}</span>
          </div>
          <div class="noti" @click="toggleNoti">
            <b-icon icon="bell-fill"></b-icon>
            <span v-if="count_noti() > 10" class="noti-count">{{ '10+' }}</span>
            <span v-else-if="count_noti() > 0" class="noti-count">{{
              count_noti()
            }}</span>
          </div>
          <div class="m-nav-button" @click="toggleAdminNav">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 49.79">
              <title>Asset 12</title>
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <line class="cls-1" x1="4" y1="4" x2="61" y2="4" />
                  <line class="cls-1" x1="4" y1="24.9" x2="61" y2="24.9" />
                  <line class="cls-1" x1="4" y1="45.79" x2="61" y2="45.79" />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </b-col>
      <UserProfile :show="isShowProfile" :user="user" @close="closeProfile" />
      <NotiBox ref="noti" :show="isShowNoti" :user="user" @close="closeNoti" />
    </header>
    <b-row style="background: #f8f8f8; min-height: calc(100vh - 54px)">
      <div md="1" class="nav-container">
        <NavBar ref="adminNav" />
      </div>
      <b-col
        sm="12"
        class="content d-12"
        style="padding: 0px; padding-top: 75px"
      >
        <b-container
          style="min-height: calc(100vh - 100px - 95px); margin-bottom: 100px"
        >
          <b-col md="12">
            <Nuxt />
          </b-col>
        </b-container>
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import NavBar from '~/components/admin/adminNavbar'
import UserProfile from '~/components/common/user_profile.vue'
import NotiBox from '~/components/common/notification_box.vue'
export default {
  components: { NavBar, UserProfile, NotiBox },
  async fetch() {
    try {
      if (this.loggedIn) {
        const result = await this.$axios.get(`users/profile`)
        // console.log(result.data.data)
        this.user.name = `${result.data.data[0].name}`
        this.user.role = result.data.data[0].role
        /* this.user.schoolId = result.data.data[0].school

        const result2 = await this.$axios.get(
          `${process.env.cloudApiUrl}schools/getSchoolNameTemp/${this.user.schoolId}`
        )
        this.user.school = result2.data.data[0].schoolName */
      }
    } catch (err) {}
  },
  data() {
    return {
      api: process.env.mainApiUrl,
      user: {
        name: '李先生',
        isAdmin: true,
        schoolId: '',
        school: null,
      },
      isShowProfile: false,
      isShowNoti: false,
      selectedFontSize: this.$store.state.localStorage.setting.fontsize,
      notiArray: [],
      timer: '',
    }
  },
  computed: {
    version() {
      return process.env.version
    },
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
    staticPrefix() {
      return process.env.base
    },
  },
  created() {
    this.fetchNotiList()
    this.timer = setInterval(this.fetchNotiList, 60000)
  },
  methods: {
    closeProfile(data) {
      this.isShowProfile = data
    },
    closeNoti(data) {
      this.isShowNoti = data
    },
    toggleAdminNav() {
      this.$refs.adminNav.toggleNav()
    },
    toggleProfile() {
      this.isShowProfile = !this.isShowProfile
      this.isShowNoti = false
    },
    toggleNoti() {
      this.isShowNoti = !this.isShowNoti
      this.isShowProfile = false
    },
    async fetchNotiList() {
      try {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}notification`
        )
        this.notiArray = result.data.data
      } catch (err) {}
    },
    count_noti() {
      let total = 0
      if (this.checkRole() === 't3') {
        return (total = this.getSCDSNoti().length)
      } else if (this.checkRole() === 't2') {
        return (total =
          this.getSPAPSNoti().length +
          this.getAESNoti().length +
          this.getAOANoti().length)
      } else if (this.checkRole() === 'admin') {
        return (total =
          this.getSCDSNoti().length +
          this.getSPAPSNoti().length +
          this.getAESNoti().length +
          this.getAOANoti().length)
      } else {
        return total
      }
    },
    checkRole() {
      const admin = [1, 2, 11, 12]
      const t2 = [4, 7, 13]
      const t3 = [5, 8, 9, 10]
      if (admin.includes(this.user.role)) {
        return 'admin'
      } else if (t2.includes(this.user.role)) {
        return 't2'
      } else if (t3.includes(this.user.role)) {
        return 't3'
      } else {
        return '0'
      }
    },
    getSCDSNoti() {
      // console.log('this.SCDSnotiArray')
      // console.log(this.notiArray)
      let scdsArr = this.notiArray
      scdsArr = scdsArr.filter((a) => {
        return a.admin_team === 'scds' && a.staff_handled === 0
      })
      // console.log(scdsArr)
      return scdsArr
    },
    getSPAPSNoti() {
      // console.log('this.SPAPSnotiArray')
      let spapsArr = this.notiArray
      spapsArr = spapsArr.filter((a) => {
        return a.admin_team === 'spaps' && a.staff_handled === 0
      })
      // console.log(spapsArr)
      return spapsArr
    },
    getAESNoti() {
      // console.log('this.AESnotiArray')
      let aesArr = this.notiArray
      aesArr = aesArr.filter((a) => {
        return a.admin_team === 'aes' && a.staff_handled === 0
      })
      // console.log(aesArr)
      return aesArr
    },
    getAOANoti() {
      // console.log('this.AOAnotiArray')
      let aoaArr = this.notiArray
      aoaArr = aoaArr.filter((a) => {
        return a.admin_team === 'aoa' && a.staff_handled === 0
      })
      // console.log(aoaArr)
      return aoaArr
    },
  },
}
</script>
<style lang="scss">
body {
  font-family: 'Noto Sans TC', 'Noto Sans SC', sans-serif;
}

h1 {
  font-size: 155%;
  color: #1cbcb4;
  margin-top: 10px;
}

h2 {
  font-size: 140%;
  color: #1cbcb4;
  margin-top: 10px;
}

h3 {
  font-size: 125%;
  color: #1cbcb4;
  margin-top: 10px;
}

.wrapper {
  text-align: left;
  align-content: left;
}
</style>
<style lang="scss" scoped>
.preview {
  position: fixed;
  border-radius: 50rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 4;
  /* -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3)); */
  /* filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3)); */
  width: 150px;
  padding: 0.5em;
  color: #fc7137;
  border: 1px solid #fc7137;
}
.btn-outline-light {
  color: #3f3f3f;
  border-color: #dddddd;
  padding: 6px 36px;
}
.btn-outline-light:hover {
  border-color: whitesmoke;
}
.btn-abo {
  background: #fc7137;
  color: white;
}
.navBtn {
  color: #3f3f3f !important;
  cursor: pointer;
}
.navBtn:hover {
  color: #fc7137 !important;
}
.fontstyle {
  position: relative;
  float: right;
  display: flex;
  font-size: 18px;
  vertical-align: center;
  justify-content: flex-end;
}
.logined {
  display: flex;
}
.logined > * {
  align-self: center;
  margin: 0 8px;
}
.usericon {
  user-select: none;
  background: #fc7137;
  color: #fff !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  font-size: 21px;
  cursor: pointer;
}
.noti {
  color: #3f3f3f;
  cursor: pointer;
}
.noti:hover {
  /* color: rgba(63, 63, 63, 0.3); */
  opacity: 0.75;
}
.noti-count {
  position: absolute;
  padding: 2px 4px;
  background: #ff4f38;
  color: #fff;
  border-radius: 30px;
  top: 6px;
  right: 11px;
  min-width: 20px;
  font-size: 10px;
  z-index: 200;
  text-align: center;
}
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

header {
  background: #fff;
  margin: 0px;
  z-index: 10;
  position: relative;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  width: 100%;
  top: 0;
  height: 75px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-wrapper {
  padding-left: 0px;
}

.d-block {
  text-align: left;
}

.header {
  margin: 0px;
}
.app {
  margin: 0 auto;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1920px;
  }
}
.container {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
@media (max-width: 1199px) {
  .container {
    max-width: none;
  }
}
.container {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}
a {
  color: #000;
  font-size: 20px;
  // font-size: 1.2rem;
  margin: 5px;
}

.nuxt-link-exact-active p {
  color: #000;
}

.pointer {
  cursor: pointer;
  color: #fc7137;
  font-weight: bold;
  margin-left: 15px;
}

.nav-container {
  padding: 0px;
  width: 100%;
}
.logo {
  height: 60px;
}
.logo.col-sm-12.col-md-3 {
  text-align: left;
  // padding-left: 50px;
}
.logoimg {
  position: absolute;
  left: 20px;
}
/* @media (min-width: 768px) { */
.d-12 {
  flex: 0 0 calc(100% - 110px);
  max-width: calc(100% - 110px);
}

.nav-container {
  padding: 0px;
  width: 110px;
}
/* } */

// Responsive Edit

.usericon {
  display: block;
}

.m-nav-button {
  display: none;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  header {
    height: 60px;
    padding-top: 5px;
  }

  .m-nav-button {
    display: block;

    margin: 8px;
    font-size: 15px !important; // not to shift when font-size change
  }

  .m-nav-button svg {
    height: 36px;
    width: 36px;
  }
  .m-nav-button svg .cls-1 {
    fill: none;
    stroke: #085b57;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 8px;
  }

  .m-nav-button:hover svg .cls-1 {
    stroke: #1cbcb4;
  }

  .d-12 {
    max-width: unset;
    flex: none;
  }

  .logoimg {
    left: 2px;
    top: 9px;
    height: 38px;
  }

  .usericon {
    display: none;
  }

  .fontstyle {
    padding-right: 0px;
  }

  :deep .m-admin-table-container {
    overflow: auto;
    scrollbar-width: thin;
  }

  :deep .m-admin-table-container::-webkit-scrollbar {
    height: 5px;
  }
  :deep .m-admin-table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  :deep .m-admin-table-container::-webkit-scrollbar-thumb {
    background: #bbb;
  }
  :deep .m-admin-table-container::-webkit-scrollbar-thumb:hover {
    background: #777;
  }

  :deep .m-admin-table-container > table {
    display: flex;
    width: max-content;
  }

  :deep .m-admin-table-container > table > thead > tr,
  :deep .m-admin-table-container > table > tbody > tr {
    display: block;
    float: left;
  }

  :deep .m-admin-table-container > table > thead > tr > th,
  :deep .m-admin-table-container > table > tbody > tr > td {
    display: block;
    border-top: 1px solid #dee2e6;
    border-bottom: none;
    width: max-content;
    height: 49px;
    width: 100%;
  }

  :deep .m-admin-table-container > table > tbody,
  :deep .m-admin-table-container > table > thead {
    border-top: none;
    border-bottom: 1px solid #dee2e6;
  }

  :deep table > thead {
    position: sticky;
    left: 0;
    background: white;
    z-index: 2;
  }

  :deep .shadow .col {
    flex-basis: unset;
  }
}
</style>
