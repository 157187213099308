

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"繁體","code":"tc","iso":"zh-HK","file":"zh-HK.js"},{"name":"簡體","code":"sc","iso":"zh-CN","file":"zh-CN.js"},{"name":"English","code":"en","iso":"en-US","file":"en.js"}],
  defaultLocale: "tc",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/home/sengital/abo-preview-frontend/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"lang","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"繁體","code":"tc","iso":"zh-HK","file":"zh-HK.js"},{"name":"簡體","code":"sc","iso":"zh-CN","file":"zh-CN.js"},{"name":"English","code":"en","iso":"en-US","file":"en.js"}],
  localeCodes: ["tc","sc","en"],
}

export const localeMessages = {
  'zh-HK.js': () => import('../../lang/zh-HK.js' /* webpackChunkName: "lang-zh-HK.js" */),
  'zh-CN.js': () => import('../../lang/zh-CN.js' /* webpackChunkName: "lang-zh-CN.js" */),
  'en.js': () => import('../../lang/en.js' /* webpackChunkName: "lang-en.js" */),
}
