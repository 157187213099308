var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { staticStyle: { "font-size": "0.9em" }, attrs: { sm: "12", md: "9" } },
        [
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _c("div", [_vm._v("報名階段")]),
                  _vm._v(" "),
                  _c("b-form-radio-group", {
                    staticClass: "mb-3",
                    attrs: {
                      options: _vm.spapsRoundOption,
                      state: !!_vm.spapsRoundOfToday,
                      required: "",
                    },
                    model: {
                      value: _vm.spapsRoundOfToday,
                      callback: function ($$v) {
                        _vm.spapsRoundOfToday = $$v
                      },
                      expression: "spapsRoundOfToday",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("報名方法")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3", staticStyle: { display: "flex" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      options: _vm.applyMethodOption,
                      state: !!_vm.applyMethod,
                      required: "",
                    },
                    model: {
                      value: _vm.applyMethod,
                      callback: function ($$v) {
                        _vm.applyMethod = $$v
                      },
                      expression: "applyMethod",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "text", maxlength: "1000" },
                    model: {
                      value: _vm.applyMethodOther,
                      callback: function ($$v) {
                        _vm.applyMethodOther = $$v
                      },
                      expression: "applyMethodOther",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _vm._v("報名遞交時間（YYYY-MM-DD HH:mm:ss）"),
              ]),
              _vm._v(" "),
              _c("b-form-input", {
                staticClass: "mb-3",
                staticStyle: { width: "300px" },
                attrs: {
                  state: _vm.submitTimeState(_vm.submitTime),
                  type: "text",
                },
                model: {
                  value: _vm.submitTime,
                  callback: function ($$v) {
                    _vm.submitTime = $$v
                  },
                  expression: "submitTime",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _vm._v("申請編號（自動產生）"),
              ]),
              _vm._v(" "),
              _c("b-form-input", {
                staticClass: "mb-3",
                attrs: { type: "text", readonly: "" },
                model: {
                  value: _vm.trnId,
                  callback: function ($$v) {
                    _vm.trnId = $$v
                  },
                  expression: "trnId",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "form",
                    label: "匯入申請（OGCIO .zip）",
                  },
                },
                [
                  _vm.action === "add"
                    ? _c("b-form-file", {
                        attrs: {
                          id: "form",
                          accept:
                            "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
                        },
                        on: { change: _vm.uploadForm },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", { staticClass: "mb-1" }, [_vm._v("附件")]),
                  _vm._v(" "),
                  _vm._l(_vm.attr, function (a, index) {
                    return _c("p", { key: index }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `${_vm.attachmentUrl}${a}`,
                            target: "_blank",
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "paperclip" } }),
                          _vm._v(_vm._s(a) + "\n          "),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("matching-school", {
                    attrs: {
                      data: _vm.data,
                      "sch-id": _vm.sarId,
                      name: _vm.schoolData ? _vm.schoolData.name_tc : "",
                    },
                    on: { input: _vm.onChangeSkl },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("applySchoolInfo", {
                    attrs: {
                      promo: _vm.disagreePromo,
                      "teacher-data": _vm.teachers,
                      "school-data": _vm.schoolData,
                    },
                    on: { change: _vm.onSchoolInfoChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _vm.eventlist
                    ? _c("tab-1", {
                        attrs: {
                          "choice-hint": _vm.choiceHint,
                          "class-hints": _vm.classHints,
                          "anticipated-class-hints": _vm.anticipatedClassHints,
                          eventlist: _vm.eventlist,
                          "choice-array": _vm.choiceArray,
                          classes: _vm.classes,
                          "event-group": _vm.eventGroup,
                          workshop: _vm.workshop,
                          edit: true,
                        },
                        on: { input: _vm.getTab1Input },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("tab-2", {
                    attrs: { edit: true, equipments: _vm.equipments },
                    on: { input: _vm.getTab2Input },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("tab-3", {
                    attrs: { edit: true, sklinfo: _vm.sklinfo },
                    on: { input: _vm.getTab3Input },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "success" } },
                [_vm._v(" 提交 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }