var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", [
        _c(
          "label",
          { attrs: { for: "schoolNameChi" } },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("b-form-input", {
              staticClass: "form-control",
              staticStyle: { width: "704px" },
              attrs: { type: "text", name: "schoolNameChi", maxlength: "1000" },
              model: {
                value: _vm.schData.name_tc,
                callback: function ($$v) {
                  _vm.$set(_vm.schData, "name_tc", $$v)
                },
                expression: "schData.name_tc",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "schoolNameEng_panel" } }, [
        _c(
          "label",
          { attrs: { for: "schoolNameEng" } },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("b-form-input", {
              staticClass: "form-control",
              staticStyle: { width: "704px" },
              attrs: { type: "text", name: "schoolNameEng", maxlength: "1000" },
              model: {
                value: _vm.schData.name_en,
                callback: function ($$v) {
                  _vm.$set(_vm.schData, "name_en", $$v)
                },
                expression: "schData.name_en",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "fieldGroup_6_panel" } }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", [
          _c("div", { attrs: { id: "schoolAddressL1_panel" } }, [
            _c(
              "label",
              { attrs: { for: "schoolAddressL1" } },
              [
                _vm._m(3),
                _vm._v(" "),
                _c("b-form-input", {
                  staticClass: "form-control",
                  staticStyle: { width: "704px" },
                  attrs: {
                    type: "text",
                    name: "schoolAddressL1",
                    placeholder: "(1) 地區",
                    maxlength: "120",
                  },
                  model: {
                    value: _vm.schData.address_area,
                    callback: function ($$v) {
                      _vm.$set(_vm.schData, "address_area", $$v)
                    },
                    expression: "schData.address_area",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "schoolAddressL2_panel" } }, [
            _c(
              "label",
              { attrs: { for: "schoolAddressL2" } },
              [
                _vm._m(4),
                _vm._v(" "),
                _c("b-form-input", {
                  staticClass: "form-control",
                  staticStyle: { width: "704px" },
                  attrs: {
                    type: "text",
                    name: "schoolAddressL2",
                    placeholder: "(2) 街道及號碼",
                    maxlength: "120",
                  },
                  model: {
                    value: _vm.schData.address_street,
                    callback: function ($$v) {
                      _vm.$set(_vm.schData, "address_street", $$v)
                    },
                    expression: "schData.address_street",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "schoolAddressL3_panel" } }, [
            _c(
              "label",
              { attrs: { for: "schoolAddressL3" } },
              [
                _vm._m(5),
                _vm._v(" "),
                _c("b-form-input", {
                  staticClass: "form-control",
                  staticStyle: { width: "704px" },
                  attrs: {
                    type: "text",
                    name: "schoolAddressL3",
                    placeholder: "(3) 建築物名稱、樓層、單位(如有)",
                    maxlength: "120",
                  },
                  model: {
                    value: _vm.schData.address_building,
                    callback: function ($$v) {
                      _vm.$set(_vm.schData, "address_building", $$v)
                    },
                    expression: "schData.address_building",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "fieldGroup_12_panel" } }, [
        _vm._m(6),
        _vm._v(" "),
        _c("div", { staticStyle: { display: "flex" } }, [
          _c("div", { attrs: { id: "schoolRegion_panel" } }, [
            _c(
              "label",
              { attrs: { for: "schoolRegion" } },
              [
                _vm._m(7),
                _vm._v(" "),
                _c("b-form-radio-group", {
                  staticClass: "mb-3",
                  attrs: {
                    options: _vm.regionList,
                    "value-field": "value",
                    "text-field": "name",
                    "disabled-field": "notEnabled",
                  },
                  model: {
                    value: _vm.schData.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.schData, "region", $$v)
                    },
                    expression: "schData.region",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "schoolDistrict_panel" } }, [
            _c(
              "label",
              { attrs: { for: "schoolDistrict" } },
              [
                _vm._m(8),
                _vm._v(" "),
                _c("b-form-select", {
                  staticClass: "mb-3",
                  attrs: {
                    options: _vm.districtList,
                    "value-field": "value",
                    "text-field": "name",
                    "disabled-field": "disabled",
                  },
                  model: {
                    value: _vm.schData.district,
                    callback: function ($$v) {
                      _vm.$set(_vm.schData, "district", $$v)
                    },
                    expression: "schData.district",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "schoolLevel_panel" } }, [
        _c(
          "label",
          { attrs: { for: "schoolLevel" } },
          [
            _vm._m(9),
            _vm._v(" "),
            _c("b-form-radio-group", {
              staticClass: "mb-3",
              attrs: {
                options: _vm.typeList,
                "value-field": "value",
                "text-field": "name",
                "disabled-field": "notEnabled",
              },
              model: {
                value: _vm.schData.category,
                callback: function ($$v) {
                  _vm.$set(_vm.schData, "category", $$v)
                },
                expression: "schData.category",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "layout-default layout-group layout-inline-group" },
        [
          _c(
            "div",
            {
              staticClass: "field form-group layout-inline",
              attrs: { id: "schoolTel_panel" },
            },
            [
              _c(
                "label",
                { attrs: { for: "schoolTel" } },
                [
                  _vm._m(10),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "form-control",
                    staticStyle: { width: "194px" },
                    attrs: {
                      type: "tel",
                      name: "schoolTel",
                      maxlength: "1000",
                      pattern: "[0-9]*",
                      inputmode: "numeric",
                    },
                    model: {
                      value: _vm.schData.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.schData, "tel", $$v)
                      },
                      expression: "schData.tel",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "field form-group layout-inline",
              attrs: { id: "schoolFax_panel" },
            },
            [
              _c(
                "label",
                { attrs: { for: "schoolFax" } },
                [
                  _vm._m(11),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticClass: "form-control",
                    staticStyle: { width: "194px" },
                    attrs: {
                      type: "tel",
                      name: "schoolFax",
                      maxlength: "1000",
                      pattern: "[0-9]*",
                      inputmode: "numeric",
                    },
                    model: {
                      value: _vm.schData.fax,
                      callback: function ($$v) {
                        _vm.$set(_vm.schData, "fax", $$v)
                      },
                      expression: "schData.fax",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "schoolEmail_panel" } }, [
        _c(
          "label",
          { attrs: { for: "schoolEmail" } },
          [
            _vm._m(12),
            _vm._v(" "),
            _c("b-form-input", {
              staticClass: "form-control",
              staticStyle: { width: "449px" },
              attrs: { type: "email", name: "schoolEmail", maxlength: "512" },
              model: {
                value: _vm.schData.email,
                callback: function ($$v) {
                  _vm.$set(_vm.schData, "email", $$v)
                },
                expression: "schData.email",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "schoolPrincipal_panel" } }, [
        _c(
          "label",
          { attrs: { for: "schoolPrincipal" } },
          [
            _vm._m(13),
            _vm._v(" "),
            _c("b-form-input", {
              staticClass: "form-control",
              staticStyle: { width: "449px" },
              attrs: {
                type: "text",
                name: "schoolPrincipal",
                maxlength: "1000",
              },
              model: {
                value: _vm.schData.principal_name,
                callback: function ($$v) {
                  _vm.$set(_vm.schData, "principal_name", $$v)
                },
                expression: "schData.principal_name",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.teachers, function (teacher, index) {
        return _c("div", { key: index }, [
          _c("fieldset", { staticClass: "topLeftInsideLegend" }, [
            _c("legend", [
              _c("span", { staticClass: "sr-only" }, [_vm._v("行")]),
              _vm._v("# " + _vm._s(index + 1)),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", [
                _c(
                  "label",
                  { attrs: { for: "teacher.0.ticName" } },
                  [
                    _vm._m(14, true),
                    _vm._v(" "),
                    _c("b-form-input", {
                      staticClass: "form-control",
                      staticStyle: { width: "449px" },
                      attrs: {
                        type: "text",
                        name: "teacher.0.ticName",
                        maxlength: "1000",
                      },
                      model: {
                        value: teacher.name,
                        callback: function ($$v) {
                          _vm.$set(teacher, "name", $$v)
                        },
                        expression: "teacher.name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "layout-default layout-group layout-inline-group",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "field form-group layout-inline",
                      attrs: { id: "teacher.0.ticPhone_panel" },
                    },
                    [
                      _c(
                        "label",
                        { attrs: { for: "teacher.0.ticPhone" } },
                        [
                          _vm._m(15, true),
                          _vm._v(" "),
                          _c("b-form-input", {
                            staticClass: "form-control",
                            staticStyle: { width: "194px" },
                            attrs: {
                              type: "tel",
                              name: "teacher.0.ticPhone",
                              maxlength: "1000",
                              pattern: "[0-9]*",
                              inputmode: "numeric",
                            },
                            model: {
                              value: teacher.tel,
                              callback: function ($$v) {
                                _vm.$set(teacher, "tel", $$v)
                              },
                              expression: "teacher.tel",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "field form-group layout-inline",
                      attrs: { id: "teacher.0.ticPhoneExt_panel" },
                    },
                    [
                      _c("label", { attrs: { for: "teacher.0.ticPhoneExt" } }, [
                        _vm._m(16, true),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: teacher.telExt,
                              expression: "teacher.telExt",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "tel",
                            name: "teacher.0.ticPhoneExt",
                            maxlength: "5",
                            pattern: "[0-9]*",
                            inputmode: "numeric",
                          },
                          domProps: { value: teacher.telExt },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(teacher, "telExt", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { attrs: { id: "teacher.0.ticEmail_panel" } }, [
                _c(
                  "label",
                  { attrs: { for: "teacher.0.ticEmail" } },
                  [
                    _vm._m(17, true),
                    _vm._v(" "),
                    _c("b-form-input", {
                      staticClass: "form-control",
                      staticStyle: { width: "449px" },
                      attrs: {
                        type: "email",
                        name: "teacher.0.ticEmail",
                        maxlength: "512",
                      },
                      model: {
                        value: teacher.email,
                        callback: function ($$v) {
                          _vm.$set(teacher, "email", $$v)
                        },
                        expression: "teacher.email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "fieldListButtons" },
            [
              _vm.teachers.length > 1
                ? _c(
                    "b-button",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        type: "button",
                        variant: "outline-danger",
                        size: "sm",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.delTeacher(index)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        刪除聯絡人 " + _vm._s(index + 1) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fieldListButtons" },
        [
          _vm.teachers.length < 3
            ? _c(
                "b-button",
                {
                  attrs: {
                    type: "button",
                    variant: "outline-success",
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addTeacher()
                    },
                  },
                },
                [_vm._v("\n      新增聯絡人\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { attrs: { id: "disagreePromo_panel" } }, [
        _c("label", { attrs: { for: "disagreePromo" } }, [
          _c("div", { staticClass: "checkbox checkbox-single" }, [
            _c(
              "label",
              { attrs: { for: "disagreePromo" } },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: {
                      type: "checkbox",
                      name: "disagreePromo",
                      size: "sm",
                    },
                    model: {
                      value: _vm.disagreePromo,
                      callback: function ($$v) {
                        _vm.disagreePromo = $$v
                      },
                      expression: "disagreePromo",
                    },
                  },
                  [
                    _vm._v("\n            本校\n            "),
                    _c("strong", [_c("u", [_vm._v("不同意")])]),
                    _vm._v(
                      "\n            康樂及文化事務署日後透過所提供的郵寄地址、傳真號碼及電郵地址發放節目宣傳資料予本校參閱。"
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v("\n              (如選擇此項，學校將"),
                      _c("u", [_vm._v("不能")]),
                      _vm._v("接收新增活動的資料。)\n            "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "required" }, [
      _c("div", [_vm._v("學校名稱（中文）")]),
      _vm._v(" "),
      _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "schoolNameEng_label" } },
      [
        _c("div", [_vm._v("學校名稱（英文）")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c(
        "div",
        { staticClass: "required", attrs: { id: "fieldGroup_6_label" } },
        [
          _c("div", [_vm._v("學校地址")]),
          _vm._v(" "),
          _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "sr-only", attrs: { id: "schoolAddressL1_label" } },
      [_c("div", { staticClass: "control-label" }, [_vm._v("(1) 地區")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "sr-only", attrs: { id: "schoolAddressL2_label" } },
      [_c("div", { staticClass: "control-label" }, [_vm._v("(2) 街道及號碼")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "sr-only", attrs: { id: "schoolAddressL3_label" } },
      [
        _c("div", { staticClass: "control-label" }, [
          _vm._v("(3) 建築物名稱、樓層、單位(如有)"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c(
        "div",
        { staticClass: "required", attrs: { id: "fieldGroup_12_label" } },
        [
          _c("div", [_vm._v("學校所屬區域")]),
          _vm._v(" "),
          _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required sr-only", attrs: { id: "schoolRegion_label" } },
      [
        _c("div", [_vm._v("學校所屬區域")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "required sr-only",
        attrs: { id: "schoolDistrict_label" },
      },
      [
        _c("div", [_vm._v("區域")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "schoolLevel_label" } },
      [
        _c("div", [_vm._v("學校類別")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "schoolTel_label" } },
      [
        _c("div", [_vm._v("學校電話")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "schoolFax_label" } },
      [
        _c("div", [_vm._v("學校傳真 (如無法提供傳真號碼，請輸入00000000)")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "schoolEmail_label" } },
      [
        _c("div", [_vm._v("學校電郵")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "schoolPrincipal_label" } },
      [
        _c("div", [_vm._v("校長姓名及稱謂")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "teacher.0.ticName_label" } },
      [
        _c("div", [_vm._v("負責老師姓名及稱謂")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "teacher.0.ticPhone_label" } },
      [
        _c("div", [_vm._v("聯絡/手提電話")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "teacher.0.ticPhoneExt_label" } }, [
      _c("div", { staticClass: "control-label" }, [_vm._v("學校內線(如適用)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "required", attrs: { id: "teacher.0.ticEmail_label" } },
      [
        _c("div", [_vm._v("聯絡電郵")]),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("必須提供")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }