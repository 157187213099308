<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="8">
      <div class="pageForm">
        <b-form method="post" @submit.prevent="submit">
          <b-form-group id="slug" label-for="slug">
            <p class="label">{{ $t('page.slug') }}</p>
            <b-form-input
              id="slug"
              v-model="slug"
              type="text"
              required
              trim
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group id="title" label-for="title">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('page.title.en')">
                <b-form-input
                  id="title_en"
                  v-model="title_en"
                  type="text"
                  trim
                ></b-form-input>
              </b-tab>
              <b-tab :title="$t('page.title.tc')">
                <b-form-input
                  id="title_tc"
                  v-model="title_tc"
                  type="text"
                  trim
                ></b-form-input>
              </b-tab>
              <b-tab :title="$t('page.title.sc')">
                <b-form-input
                  id="title_sc"
                  v-model="title_sc"
                  type="text"
                  trim
                ></b-form-input>
              </b-tab>
            </b-tabs>
          </b-form-group>

          <b-form-group id="content" label-for="content">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('page.content.en')">
                <tinymceEditor
                  id="content_en"
                  :value="content_en"
                  @writeContent="getContent"
                />
              </b-tab>
              <b-tab :title="$t('page.content.tc')">
                <tinymceEditor
                  id="content_tc"
                  :value="content_tc"
                  @writeContent="getContent"
                />
              </b-tab>
              <b-tab :title="$t('page.content.sc')">
                <tinymceEditor
                  id="content_sc"
                  :value="content_sc"
                  @writeContent="getContent"
                />
              </b-tab>
            </b-tabs>
          </b-form-group>

          <div class="shadow my-3 mse-c1">
            <b-button variant="outline-success" @click="addTab">{{
              $t('scheme.add_tab')
            }}</b-button>

            <b-tabs content-class="mt-3">
              <b-tab v-for="(tab, counter) in tabs" :key="counter" class="tab">
                <template #title>
                  {{ i18nTitle(tab) }}
                  <b-button
                    size="sm"
                    variant="danger"
                    style="border-radius: 500%"
                    @click.prevent="deleteTab(counter)"
                  >
                    ×
                  </b-button>
                </template>
                <b-form-group id="tabs" label-for="tabs">
                  <b-tabs content-class="mt-3">
                    <b-tab :title="$t('g.en')">
                      <p class="label">{{ $t('tab.title.en') }}</p>
                      <b-form-input
                        v-model="tab.title_en"
                        :placeholder="$t('placeholder.title.en')"
                        type="text"
                        trim
                      ></b-form-input>
                      <p class="label">{{ $t('tab.content.en') }}</p>
                      <tinymceEditor
                        :id="'tab_editor_en_' + counter"
                        v-model="tab.content_en"
                        :value="tab.content_sc"
                        @writeContent="getTabContent"
                      />
                    </b-tab>
                    <b-tab :title="$t('g.tc')">
                      <p class="label">{{ $t('tab.title.tc') }}</p>
                      <b-form-input
                        v-model="tab.title_tc"
                        :placeholder="$t('placeholder.title.tc')"
                        type="text"
                        trim
                      ></b-form-input>
                      <p class="label">{{ $t('tab.content.tc') }}</p>
                      <tinymceEditor
                        :id="'tab_editor_tc_' + counter"
                        :value="tab.content_tc"
                        @writeContent="getTabContent"
                      />
                      <!--  {{ JSON.stringify(tabs).length }} -->
                    </b-tab>
                    <b-tab :title="$t('g.sc')">
                      <p class="label">{{ $t('tab.title.sc') }}</p>
                      <b-form-input
                        v-model="tab.title_sc"
                        :placeholder="$t('placeholder.title.sc')"
                        type="text"
                        trim
                      ></b-form-input>
                      <p class="label">{{ $t('tab.content.sc') }}</p>
                      <tinymceEditor
                        :id="'tab_editor_sc_' + counter"
                        :value="tab.content_sc"
                        @writeContent="getTabContent"
                    /></b-tab>
                  </b-tabs>
                </b-form-group>
              </b-tab>
            </b-tabs>
          </div>

          <b-form-group
            id="customizeLastModify"
            label-for="customizeLastModify"
          >
            <p class="label">最後修改日期</p>
            <b-form-input
              id="customizeLastModify"
              v-model="customizeLastModify"
              type="date"
            />
          </b-form-group>

          <b-form-group>
            <b-form-radio v-model="deployTarget" :value="0">
              {{ $t('news.deployTarget0') }}
            </b-form-radio>
            <b-form-radio v-model="deployTarget" :value="1">
              {{ $t('news.deployTarget1') }}
            </b-form-radio>
            <b-form-radio v-model="deployTarget" :value="2">
              {{ $t('news.deployTarget2') }}
            </b-form-radio>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" variant="outline-success" type="submit">{{
              $t('action.submit')
            }}</b-button>
            <b-button
              v-if="deployTarget === 0 || deployTarget === 2"
              variant="outline-primary"
              @click="previewEplatform()"
            >
              {{ $t('action.preview') }} (E-Platform)
            </b-button>
            <b-button
              v-if="deployTarget === 1 || deployTarget === 2"
              variant="outline-warning"
              @click="previewWebpage()"
            >
              {{ $t('action.preview') }} (Webpage)
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import TinymceEditor from '~/components/admin/tinymceEditor'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    TinymceEditor,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      // console.log(this.action)
      if (this.action === 'edit') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}pages/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.slug = data.slug
        this.title_en = data.name_en
        this.title_tc = data.name_tc
        this.title_sc = data.name_sc
        this.content_en = data.content_en
        this.content_tc = data.content_tc
        this.content_sc = data.content_sc
        this.customizeLastModify =
          data.customizeLastModify != null
            ? new Date(data.customizeLastModify).toLocaleDateString('en-CA', {
                timeZone: 'Asia/Hong_Kong',
              })
            : null

        this.deployTarget = data.deployTarget
        if (data.tabs !== null || data.tabs !== undefined)
          this.tabs = JSON.parse(data.tabs)
        if (!this.tabs) this.tabs = []
      }
    } catch (err) {
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      slug: '',
      title_en: '',
      title_tc: '',
      title_sc: '',
      content_en: '',
      content_tc: '',
      content_sc: '',
      tabs: [],
      deployTarget: -1, // 0: eplatform, 1: web, 2: both
      customizeLastModify: '',
      error: null,
    }
  },
  computed: {
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      if (this.tabs.length > 0) {
        for (let i = 0; i < this.tabs.length; i++) {
          tc.push({
            title: this.tabs[i].title_tc,
            content: this.tabs[i].content_tc,
          })
        }
      }
      return tc
    },
  },
  methods: {
    setSc(data) {
      this.title_sc = data[0]
      this.content_sc = data[1]
      if (this.tabs.length > 0) {
        for (let i = 1; i <= this.tabs.length; i++) {
          this.tabs[i - 1].title_sc = data[i + 1].title
          this.tabs[i - 1].content_sc = data[i + 1].content
        }
      }
    },
    getContent(evt, id, content) {
      // watch TinyMCE content
      this[id] = content
      // console.log(content)
    },
    getTabContent(evt, id, content) {
      if (id.startsWith('tab_editor')) {
        const tmp = id.split('_')
        this.tabs[tmp[3]]['content_' + tmp[2]] = content
      } else {
        this[id] = content
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    addTab() {
      this.tabs.push({
        title_en: '',
        title_tc: '',
        title_sc: '',
        content_en: '',
        content_tc: '',
        content_sc: '',
        link_en: '',
        link_tc: '',
        link_sc: '',
      })
    },
    deleteTab(counter) {
      this.tabs.splice(counter, 1)
    },
    async previewEplatform() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }

      window.open(`${process.env.previewUrl}${this.$i18n.locale}/${this.slug}`)

      this.revertPreviewDbEntry(previewId)
    },
    async previewWebpage() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
        await this.$axios.$post(
          `${process.env.previewApiUrl}preview/toWebPreview`
        )
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }

      if (this.slug.includes('about')) {
        window.open(`${process.env.previewWebUrl}${this.$i18n.locale}/about`)
      } else if (this.slug.includes('others')) {
        window.open(
          `${process.env.previewWebUrl}${this.$i18n.locale}/otherActivities`
        )
      } else if (this.slug.includes('archive')) {
        window.open(`${process.env.previewWebUrl}${this.$i18n.locale}/archive`)
      } else if (this.slug.includes('notice')) {
        window.open(
          `${process.env.previewWebUrl}${this.$i18n.locale}/importantNotices`
        )
      }

      this.revertPreviewDbEntry(previewId)
    },
    async revertPreviewDbEntry(previewId) {
      if (previewId) {
        // delete preview db entry after preview
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#d9534f',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.previewApiUrl}pages/delete`,
            {
              id: previewId,
            }
          )
          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)
        }
      } else if (this.action === 'edit') {
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#1cbcb4',
        })
        if (back.value) {
          // post original data to preview db
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}pages/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)

          const result2 = await this.$axios.$post(
            `${process.env.previewApiUrl}pages/update`,
            {
              // data,
              id: data.id,
              slug: data.slug,
              name_en: data.title_en,
              name_tc: data.title_tc,
              name_sc: data.title_sc,
              content_en: data.content_en,
              content_tc: data.content_tc,
              content_sc: data.content_sc,
              tabs: data.tabs.length > 0 ? data.tabs : null,
              deployTarget: data.deployTarget,
              customizeLastModify: data.customizeLastModify,
            }
          )

          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result2.error !== undefined) throw new Error(result2.error)
        }
      }
    },
    async callapi(url, previewId) {
      let result
      if (this.customizeLastModify === '') {
        this.customizeLastModify = null
      }
      if (this.action === 'edit') {
        result = await this.$axios.$post(`${url}pages/update`, {
          id: this.id,
          slug: this.slug,
          name_en: this.title_en,
          name_tc: this.title_tc,
          name_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          tabs: this.tabs.length > 0 ? this.tabs : null,
          deployTarget: this.deployTarget,
          previewId,
          customizeLastModify: this.customizeLastModify,
        })
      } else {
        result = await this.$axios.$post(`${url}pages/add`, {
          slug: this.slug,
          name_en: this.title_en,
          name_tc: this.title_tc,
          name_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          tabs: this.tabs.length > 0 ? this.tabs : null,
          deployTarget: this.deployTarget,
          customizeLastModify: this.customizeLastModify,
        })
      }
      if (result.error !== undefined) throw new Error(result.error)
    },
    async submit() {
      try {
        this.callapi(process.env.previewApiUrl)
        this.callapi(process.env.cloudApiUrl)

        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/page'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
.mse-c1 {
  padding: 30px;
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .mse-c1 {
    padding: 10px;
  }

  .shadow {
    padding: 12px;
    margin: 30px -15px;
  }
}
</style>
