<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="7">
      <div class="schoolForm">
        <b-form method="post" @submit.prevent="submit">
          <div class="shadow">
            <b-form-group label-for="form" :label="$t('school.form')">
              <b-form-input
                v-model="form_id"
                type="text"
                readonly
              ></b-form-input>
              <b-form-file
                v-if="action === 'add'"
                id="form"
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                @change="uploadForm"
              />
            </b-form-group>
            <hr />
            <div class="mb-5">
              <h3>1. 配對千年表紀錄</h3>
              <div class="mb-1">請從千年表選擇相應學校：</div>
              <v-select
                :options="suggestSklList"
                :value="sarId"
                placeholder="Suggested Schools"
                @input="(c) => updateSarId(c)"
              ></v-select>
              <div class="mt-2 mb-5">
                <table>
                  <tbody>
                    <tr>
                      <td>找不到學校？</td>
                      <td>
                        <a v-b-modal.modal-selectskl href="javascript:void(0)">
                          尋找其他學校
                        </a>
                      </td>
                    </tr>
                    <td></td>
                    <td>
                      <a v-b-modal.modal-addskl href="javascript:void(0)">
                        新增學校至千年表
                      </a>
                    </td>
                  </tbody>
                </table>
              </div>
              <div class="my-3">
                已選擇：
                <span
                  :class="
                    getSklName(sarId) === '未選擇'
                      ? 'not_selected'
                      : 'resultskl'
                  "
                >
                  {{ getSklName(sarId) }}
                </span>
                <span v-html="matchedStr"></span>
              </div>
              <!-- <b-form-checkbox
                v-model="newskl"
                value="accepted"
                unchecked-value="not_accepted"
              >
                此為新學校
              </b-form-checkbox> -->
            </div>
            <hr />

            <b-modal
              id="modal-addskl"
              title="新增學校至千年表"
              size="lg"
              hide-footer
            >
              <b-form-group label-for="form" label="學校名稱">
                <b-form-input
                  v-model="newskl_schoolName"
                  type="text"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-for="form" label="學校類別">
                <b-form-radio-group
                  v-model="newskl_category"
                  :options="typeList"
                  class="mb-3"
                  value-field="value"
                  text-field="name"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
                <b-form-input
                  v-if="newskl_category === 5"
                  v-model="newskl_categoryTxt"
                  type="text"
                ></b-form-input>
              </b-form-group>
              <b-form-group label-for="form" label="學校區域">
                <b-form-select
                  v-model="newskl_district"
                  :options="districtList"
                  class="mb-3"
                  value-field="value"
                  text-field="name"
                  disabled-field="disabled"
                ></b-form-select>
              </b-form-group>
              <b-button variant="outline-success" @click="addSkl()">
                新增學校
              </b-button>
            </b-modal>
            <school-modal
              :sch-id="sarId"
              :school="selected"
              :skl-list="suggestSklList"
              @change="schoolModalChange"
            ></school-modal>
            <!--<b-modal
              id="modal-selectskl"
              title="選擇學校"
              size="xl"
              hide-footer
            >
              <div class="my-3">
                已選擇：
                <span
                  :class="
                    getSklName(sarId) === '未選擇'
                      ? 'not_selected'
                      : 'resultskl'
                  "
                >
                  {{ getSklName(sarId) }}
                </span>
                <span v-html="matchedStr"></span>
              </div>
              選擇學校：
              {{
                `${selected && selected.length ? selected[0].schoolName : ''}`
              }}
              <b-button
                class="mx-2"
                variant="outline-success"
                size="sm"
                :disabled="!(selected && selected.length)"
                @click="() => (sarId = selected[0].id)"
              >
                確定
              </b-button>
              <b-button size="sm" @click="clearSelected">
                Clear selected
              </b-button>

              <b-input-group class="my-3">
                <b-input v-model="keyword" type="text" placeholder="Search" />
                <b-input-group-append>
                  <b-button @click="removeKeyword">×</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-table
                id="table"
                ref="selectableTable"
                striped
                hover
                small
                selectable
                selected-variant="info"
                select-mode="single"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                @row-selected="onRowSelected"
              >
                <template #cell(district)="row">
                  {{ getDistrict(row.item.district) }}
                </template>
              </b-table>
              <p class="mt-3">Total: {{ total }} | Search : {{ rows }}</p>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="table"
                align="center"
              ></b-pagination>
            </b-modal> -->

            <h3>2. 檢查學校資料</h3>
            <b-form-group id="title" label-for="title">
              <b-tabs content-class="mt-3">
                <b-tab :title="$t('school.name.en')"
                  ><b-form-input
                    v-model="name_en"
                    :placeholder="$t('school.placeholder.name.en')"
                    type="text"
                    required
                    trim
                  ></b-form-input
                ></b-tab>
                <b-tab :title="$t('school.name.tc')"
                  ><b-form-input
                    v-model="name_tc"
                    :placeholder="$t('school.placeholder.name.tc')"
                    type="text"
                    required
                    trim
                  ></b-form-input
                ></b-tab>
              </b-tabs>
            </b-form-group>

            <p>{{ $t('school.address') }}</p>
            <b-row>
              <b-col>
                <b-form-group id="address_area" label-for="address_area">
                  <b-form-input
                    v-model="address_area"
                    :placeholder="$t('school.placeholder.address.area')"
                    type="text"
                    required
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="address_street" label-for="address_street">
                  <b-form-input
                    v-model="address_street"
                    :placeholder="$t('school.placeholder.address.street')"
                    type="text"
                    required
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="address_building"
                  label-for="address_building"
                >
                  <b-form-input
                    v-model="address_building"
                    :placeholder="$t('school.placeholder.address.building')"
                    type="text"
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <p>{{ $t('school.district') }}</p>
            <b-form-group id="school_region" label-for="school_region">
              <b-form-radio-group
                v-model="region"
                :options="regionList"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="notEnabled"
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group id="district" label-for="district">
              <b-form-select
                v-model="district"
                :options="districtList"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="disabled"
              ></b-form-select>
            </b-form-group>

            <p>{{ $t('school.level') }}</p>
            <b-form-group id="type" label-for="type">
              <b-form-radio-group
                v-model="type"
                :options="typeList"
                class="mb-3"
                value-field="value"
                text-field="name"
                disabled-field="notEnabled"
              ></b-form-radio-group>
              <b-form-input
                v-if="type === 5"
                v-model="typeOther"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-row>
              <b-col>
                <p>{{ $t('school.tel') }}</p>
                <b-form-group id="tel" label-for="tel">
                  <b-form-input
                    v-model="tel"
                    :placeholder="$t('school.placeholder.tel')"
                    type="number"
                    required
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <p>{{ $t('school.fax') }}</p>
                <b-form-group id="fax" label-for="fax">
                  <b-form-input
                    v-model="fax"
                    :placeholder="$t('school.placeholder.fax')"
                    type="number"
                    required
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <p>{{ $t('school.email') }}</p>
                <b-form-group id="email" label-for="email">
                  <b-form-input
                    v-model="email"
                    :placeholder="$t('school.placeholder.email')"
                    type="email"
                    required
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <p>{{ $t('school.principal_name') }}</p>
                <b-form-group id="principal_name" label-for="principal_name">
                  <b-form-input
                    v-model="principal_name"
                    :placeholder="$t('school.placeholder.principal_name')"
                    type="text"
                    required
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <div>
              <p>附件</p>
              <p v-for="(a, index) in attr" :key="index">
                <a :href="`${attachmentUrl}${a}`" target="_blank">
                  <b-icon icon="paperclip"></b-icon>{{ a }}
                </a>
              </p>
            </div>
          </div>

          <div class="shadow">
            <p>{{ $t('user.isSchoolAdmin') }}</p>
            <user-Form
              :action="action == 'edit' ? 'schoolEdit' : 'schoolAdd'"
              :detail="admin"
              @change="getUser"
            ></user-Form>
          </div>

          <div class="shadow">
            <p>{{ $t('user.schoolTeacher') }}</p>
            <b-button
              v-if="action === 'add'"
              variant="outline-success"
              @click="addTab"
              >{{ $t('school.add_teacherSlot') }}</b-button
            >
            <b-tabs>
              <b-tab v-for="(teacher, index) in teacherList" :key="index">
                <template #title>
                  {{ teacher.name }}
                  <b-button
                    v-if="action === 'add'"
                    size="sm"
                    variant="danger"
                    style="border-radius: 500%"
                    @click.prevent="deleteTab(index)"
                  >
                    ×
                  </b-button>
                </template>
                <user-Form
                  :action="action == 'edit' ? 'schoolEdit' : 'schoolAdd'"
                  :detail="teacher"
                  @change="getUser"
                ></user-Form>
              </b-tab>
            </b-tabs>
          </div>

          <div class="shadow">
            <p>{{ $t('school.remarks') }}</p>
            <b-row>
              <b-col>
                <b-form-group id="remarks" label-for="remarks">
                  <b-form-input
                    v-model="remarks"
                    type="text"
                    trim
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button
              id="submit"
              type="submit"
              variant="outline-success"
              :disabled="systemMode === 'cloud'"
              >{{ $t('action.submit') }}</b-button
            >
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'
import userForm from '~/components/admin/user/userForm'
import SchoolModal from '~/components/admin/application/schoolModal'

export default {
  components: {
    Notification,
    tools,
    userForm,
    vSelect,
    SchoolModal,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    if (this.action === 'add') {
      try {
        const result = await this.$axios.get(
          `${process.env.localApiUrl}schools/getNewFormId`
        )
        this.form_id = result.data.data.fId
      } catch (err) {
        console.log(err)
      }
    }
    try {
      const result = await this.$axios.get(
        `${process.env.localApiUrl}schools/sar/school`
      )
      this.allSkls = result.data.data
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      if (this.action === 'edit') {
        const result = await this.$axios.get(
          `${process.env.localApiUrl}schools/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.form_id = data.form_id
        this.name_en = data.name_en
        this.name_tc = data.name_tc
        this.address_area = data.address_area
        this.address_street = data.address_street
        this.address_building = data.address_building
        this.type = data.type
        this.typeOther = data.typeOther
        this.region = data.region
        this.district = data.district
        this.tel = data.tel
        this.fax = data.fax
        this.email = data.email
        this.principal_name = data.principal_name
        this.teacherList = data.teacherList
        this.admin = data.schAdmin[0]
        this.sarId = data.sarId
        this.attr = data.attr ? JSON.parse(data.attr) : []
        this.remarks = data.remarks

        const result2 = await this.$axios.get(
          `${process.env.localApiUrl}schools/sar/school/${this.name_tc}`
        )
        if (result2.data && result2.data.data) {
          this.suggestSklList = result2.data.data
          this.suggestSklList = this.suggestSklList.map((d) => {
            d.label = d.schoolName
            d.code = d.id
            return d
          })
        }
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      matchedStr: '',
      newskl_schoolName: '',
      newskl_category: '',
      newskl_categoryTxt: '',
      newskl_district: '',
      selected: null,
      keyword: '',
      perPage: 15,
      currentPage: 1,
      allSkls: [],
      suggestSklList: [{ code: 1, label: '' }],
      typeList: [
        { value: 1, name: '幼稚園' },
        { value: 2, name: '小學' },
        { value: 3, name: '中學' },
        { value: 4, name: '特殊學校' },
        { value: 5, name: '其他（請註明）' },
      ],
      regionList: [
        { value: 'hk', name: '香港島' },
        { value: 'kln', name: '九龍區' },
        { value: 'nt', name: '新界區' },
      ],
      districtList: [
        {
          value: 0,
          name: this.$t('school.districtList.select'),
          disabled: true,
        },
        { value: 1, name: '中西區 Central & Western District' },
        { value: 2, name: '東區 Eastern District' },
        { value: 3, name: '南區 Southern District' },
        { value: 4, name: '灣仔區 Wanchai District' },
        { value: 5, name: '九龍城區 Kowloon City District' },
        { value: 6, name: '觀塘區 Kuwn Tong District' },
        { value: 7, name: '深水埗區 Sham Shui Po District' },
        { value: 8, name: '黃大仙區 Wong Tai Sin District' },
        { value: 9, name: '油尖旺區 Yau Tsim Mong District' },
        { value: 10, name: '離島區 Islands District' },
        { value: 11, name: '葵青區 Kwai Tsing District' },
        { value: 12, name: '北區 North District' },
        { value: 13, name: '西貢區 Sai Kung District' },
        { value: 14, name: '沙田區 Shatin District' },
        { value: 15, name: '大埔區 Tai Po District' },
        { value: 16, name: '荃灣區 Tsuen Wan District' },
        { value: 17, name: '屯門區 Tuen Mun District' },
        { value: 18, name: '元朗區 Yuen Long District' },
      ],
      id: null,
      form_id: '',
      name_en: '',
      name_tc: '',
      address_area: '',
      address_street: '',
      address_building: '',
      type: null,
      typeOther: '',
      region: null,
      district: 0,
      tel: null,
      fax: null,
      email: '',
      principal_name: '',
      error: null,
      teacherList: [],
      admin: {
        name: '',
        password: '',
        tel: null,
        email: '',
      },
      attr: [],
      sarId: null,
      remarks: '',
    }
  },
  computed: {
    systemMode() {
      return process.env.SYSTEM_MODE
    },
    rows() {
      return this.items.length
    },
    total() {
      return this.allSkls.length
    },
    items() {
      const result = this.keyword
        ? this.allSkls.filter((item) => {
            const id = item.id || ''
            const district = item.district || ''
            const schoolName = item.schoolName || ''
            const intSchoolNameChi = item.intSchoolNameChi || ''
            const edbCat = item.edbCat || ''
            return (
              id.toString().includes(this.getKeyword()) ||
              district.toString().includes(this.getKeyword()) ||
              schoolName.toLowerCase().includes(this.getKeyword()) ||
              intSchoolNameChi.toLowerCase().includes(this.getKeyword()) ||
              edbCat.toLowerCase().includes(this.getKeyword())
            )
          })
        : this.allSkls
      return result
    },
    attachmentUrl() {
      // return process.env.localApiUrl
      return process.env.attachmentUrl
    },
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      return tc
    },
  },
  methods: {
    schoolModalChange(sarId, selected) {
      this.sarId = sarId
      this.selected = selected
    },
    getCategory() {
      switch (this.newskl_category) {
        case 1:
          return '幼稚園'
        case 2:
          return '小學'
        case 3:
          return '中學'
        case 4:
          return '特殊學校'
        case 5:
          return this.newskl_categoryTxt
        default:
          return ''
      }
    },
    async addSkl() {
      /* alert(
        this.newskl_schoolName,
        this.newskl_category,
        this.newskl_categoryTxt,
        this.newskl_district
      ) */
      try {
        const back = await this.$swal({
          text: `確定新增學校至「千年表」？`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: '確定',
          confirmButtonColor: '#d9534f',
          cancelButtonText: '取消',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.localApiUrl}schools/sar/newSchool`,
            {
              schoolName: this.newskl_schoolName,
              category: this.getCategory(),
              district: this.newskl_district,
            }
          )
          if (result.error !== undefined) throw new Error(result.error)
          const back = await this.$swal({
            text: `成功新增學校： ${this.newskl_schoolName}`,
            showCancelButton: false,
            confirmButtonText: 'OK',
            confirmButtonColor: '#1cbcb4',
          })
          if (back.value) {
            this.$fetch()
          }
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    onRowSelected(items) {
      this.selected = items
    },
    getSklName(sarId) {
      if (this.sarId && this.allSkls.length) {
        const found = this.allSkls.find((d) => {
          return d.id === this.sarId
        })
        this.checkMatched()
        return found
          ? `${sarId}. ${this.getDistrict(found.district)}: ${
              found.schoolName
            } `
          : '未選擇'
      }
      return '未選擇'
    },
    async checkMatched() {
      const result = await this.$axios.get(`schools/sar/matched/${this.sarId}`)
      let tmp = ''

      this._.forEach(result.data.data, function (value) {
        tmp += `<br> Matched SCHOOL ID:${value.id}, NAME: ${value.name_tc} `
      })
      this.matchedStr = tmp
    },
    getDistrict(id) {
      const found = this.districtList.find((d) => d.value === id)
      return found ? found.name : ''
    },
    removeKeyword() {
      this.keyword = ''
    },
    getKeyword() {
      return this.keyword.toLowerCase().trim()
    },
    updateSarId(obj) {
      this.sarId = obj ? obj.id : null
    },
    trimData(field) {
      return field ? field.trim() : ''
    },
    toInt(string) {
      return string !== '' ? parseInt(string) : null
    },
    setSc(data) {
      this.title_sc = data[0]
      this.content_sc = data[1]
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    addTab() {
      this.teacherList.push({
        id: 0,
        name: '',
        email: '',
        tel: '',
        password: this.randPassword(1, 1, 1, 1, 4),
      })
    },
    async uploadForm(e) {
      try {
        const params = new FormData()
        params.append('form', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.localApiUrl}files/form`,
          params
        )
        if (await result) {
          const data = result.data
          if (data.status === 'ok') {
            const subData = data.data.submission.data
            this.form_id = this.trimData(data.data.submission.submit.trnId)
            this.name_en = this.trimData(subData.schNameEng)
            this.name_tc = this.trimData(subData.schNameChi)
            this.address_area = this.trimData(subData.schAddr.address1)
            this.address_street = this.trimData(subData.schAddr.address2)
            this.address_building = this.trimData(subData.schAddr.address3)
            this.type = this.toInt(this.trimData(subData.schLevel))
            this.typeOther = this.trimData(subData.schLevelOth)
            this.region = this.trimData(subData.schRegion)
            this.district = this.toInt(this.trimData(subData.schDistrict))
            this.tel = this.toInt(this.trimData(subData.schTel))
            this.fax = this.toInt(this.trimData(subData.schFax))
            this.email = this.trimData(subData.schEmail)
            this.principal_name = this.trimData(subData.principal)
            this.attr = data.data.attr
            this.admin = {
              name: this.trimData(subData.schAdminAcc),
              email: this.trimData(subData.schAdminEmail),
              tel: this.trimData(subData.schAdminTel),
              password: this.randPassword(1, 1, 1, 1, 4),
              isSchoolAdmin: true,
            }

            if (Array.isArray(subData.teacherList)) {
              this.teacherList = [...subData.teacherList]
            } else {
              this.teacherList = []
              this.teacherList.push(subData.teacherList)
            }
            this.teacherList = this.teacherList.map((l) => {
              l.name = l.tchName
              l.email = l.tchEmail
              l.tel = l.tchTel
              l.password = this.randPassword(1, 1, 1, 1, 4)
              delete l.tchName
              delete l.tchEmail
              delete l.tchTel
              return l
            })

            const result2 = await this.$axios.get(
              `${process.env.localApiUrl}schools/sar/school/${this.name_tc}`
            )
            if (result2.data && result2.data.data) {
              this.suggestSklList = result2.data.data
              this.suggestSklList = this.suggestSklList.map((d) => {
                d.label = d.schoolName
                d.code = d.id
                return d
              })
            }
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    async submit() {
      try {
        let result
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.localApiUrl}schools/update`,
            {
              id: this.id,
              form_id: this.form_id,
              name_en: this.name_en,
              name_tc: this.name_tc,
              address_area: this.address_area,
              address_street: this.address_street,
              address_building: this.address_building,
              type: this.type,
              typeOther: this.type === 5 ? this.typeOther : '',
              region: this.region,
              district: this.district,
              tel: this.tel,
              fax: this.fax,
              email: this.email,
              principal_name: this.principal_name,
              teacherList: this.teacherList,
              schAdmin: this.admin,
              sarId: this.sarId,
              attr: this.attr,
              remarks: this.remarks,
            }
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.localApiUrl}schools/add`,
            {
              form_id: this.form_id,
              name_en: this.name_en,
              name_tc: this.name_tc,
              address_area: this.address_area,
              address_street: this.address_street,
              address_building: this.address_building,
              type: this.type,
              typeOther: this.type === 5 ? this.typeOther : '',
              region: this.region,
              district: this.district,
              tel: this.tel,
              fax: this.fax,
              email: this.email,
              principal_name: this.principal_name,
              teacherList: this.teacherList,
              schAdmin: this.admin,
              sarId: this.sarId,
              attr: this.attr,
              remarks: this.remarks,
            }
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/school'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    randPassword(capital, letters, numbers, symbol, either) {
      const chars = [
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ', // letters
        'abcdefghijklmnopqrstuvwxyz', // letters
        '0123456789', // numbers,
        '!@#$%^&*()<>?{}|:[]',
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()<>?{}|:[]', // either
      ]

      return [capital, letters, numbers, symbol, either]
        .map(function (len, i) {
          return Array(len)
            .fill(chars[i])
            .map(function (x) {
              return x[Math.floor(Math.random() * x.length)]
            })
            .join('')
        })
        .concat()
        .join('')
        .split('')
        .sort(function () {
          return 0.5 - Math.random()
        })
        .join('')
    },
    getUser(evt, id, content) {
      this[id] = content
    },
    deleteTab(counter) {
      this.teacherList.splice(counter, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
.resultskl {
  color: grey;
  background-color: white;
  padding: 5px 20px;
  line-height: 2.5;
}
.not_selected {
  color: #f76576;
  background-color: white;
  padding: 5px 20px;
}
.shadow {
  padding: 30px;
  margin: 30px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
