var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("editor", {
    attrs: {
      "initial-value": _vm.content,
      options: _vm.editorOptions,
      height: "500px",
      "initial-edit-type": "wysiwyg",
      "preview-style": "vertical",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }