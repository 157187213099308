var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fab", {
    attrs: {
      position: _vm.position,
      "bg-color": _vm.bgColor,
      actions: _vm.fabActions,
    },
    on: { tc2sc: _vm.tc2sc },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }