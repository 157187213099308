<template>
  <div class="tab-cont">
    <div class="spaps-div">
      <h3>❺ {{ $t('apply.spaps.tab_txt.tab3.title') }}</h3>
      <!-- <p class="questions">{{ $t('apply.spaps.tab_txt.tab3.subtitle1') }}</p>

      <div class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.tab3.name') }}
      </div>
      <b-row>
        <b-col>
          <div class="event-title mt-1">
            {{ $t('apply.spaps.tab_txt.tab3.surname') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.ticName1.surname"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{
              sklinfo.ticName1.surname !== null ? sklinfo.ticName1.surname : '/'
            }}
          </div>
        </b-col>

        <b-col>
          <div id="5" class="event-title mt-1">
            {{ $t('apply.spaps.tab_txt.tab3.givenName') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.ticName1.givenName"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{
              sklinfo.ticName1.givenName !== null
                ? sklinfo.ticName1.givenName
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.schoolMobile') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.schoolMobile1"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{ sklinfo.schoolMobile1 !== null ? sklinfo.schoolMobile1 : '/' }}
          </div>
        </b-col>

        <b-col>
          <div id="5" class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.email') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.email1"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{ sklinfo.email1 !== null ? sklinfo.email1 : '/' }}
          </div>
        </b-col>
      </b-row>

      <p class="questions mt-4">
        {{ $t('apply.spaps.tab_txt.tab3.subtitle2') }}
      </p>
      <div class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.tab3.name') }}
      </div>
      <b-row>
        <b-col>
          <div class="event-title mt-1">
            {{ $t('apply.spaps.tab_txt.tab3.surname') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.ticName2.surname"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{
              sklinfo.ticName2.surname !== null ? sklinfo.ticName2.surname : '/'
            }}
          </div>
        </b-col>

        <b-col>
          <div id="5" class="event-title mt-1">
            {{ $t('apply.spaps.tab_txt.tab3.givenName') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.ticName2.givenName"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{
              sklinfo.ticName2.givenName !== null
                ? sklinfo.ticName2.givenName
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.schoolMobile') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.schoolMobile2"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{ sklinfo.schoolMobile2 !== null ? sklinfo.schoolMobile2 : '/' }}
          </div>
        </b-col>

        <b-col>
          <div id="5" class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.email') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.email2"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{ sklinfo.email2 !== null ? sklinfo.email2 : '/' }}
          </div>
        </b-col>
      </b-row> -->

      <div class="questions mt-4">
        {{ $t('apply.spaps.tab_txt.tab3.fund') }}
        <span class="red">*</span>
      </div>
      <b-form-radio-group
        v-if="edit"
        id="radio-group-fund"
        v-model="sklinfo.fund"
        :options="fund"
        :state="validate || validateOnclick[0] ? !!sklinfo.fund : null"
        @input="inputChanged(0)"
      ></b-form-radio-group>
      <div v-else class="choice">
        {{
          sklinfo.fund
            ? $t(`apply.spaps.tab_txt.tab3.fund_${sklinfo.fund}`)
            : '/'
        }}
      </div>
    </div>

    <hr />

    <div class="spaps-div">
      <h3>❻ {{ $t('apply.spaps.tab_txt.tab3.title2') }}</h3>

      <div class="questions mt-3">
        {{ $t('apply.spaps.tab_txt.tab3.otherGroup') }}
        <span class="red">*</span>
      </div>
      <b-form-radio-group
        v-if="edit"
        id="radio-group-otherGroup"
        v-model="sklinfo.otherGroup"
        :options="otherGroup"
        :state="validate || validateOnclick[1] ? !!sklinfo.otherGroup : null"
        @input="inputChanged(1)"
      ></b-form-radio-group>
      <div v-else class="choice">
        {{
          sklinfo.otherGroup
            ? $t(`apply.spaps.tab_txt.tab3.otherGroup_${sklinfo.otherGroup}`)
            : '/'
        }}
      </div>
      <b-row v-if="sklinfo.otherGroup === 1">
        <b-col md="6">
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.otherGroupSpecify') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.otherGroupSpecify"
            type="text"
            :state="
              validate || validateOnclick[5]
                ? checkExist(sklinfo.otherGroupSpecify)
                : null
            "
            @input="inputChanged(5)"
          />
          <div v-else class="choice">
            {{
              checkExist(sklinfo.otherGroupSpecify)
                ? sklinfo.otherGroupSpecify
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <div class="questions mt-5">
        {{ $t('apply.spaps.tab_txt.tab3.otherClass') }}
        <span class="red">*</span>
      </div>
      <b-form-radio-group
        v-if="edit"
        id="radio-group-otherClass"
        v-model="sklinfo.otherClass"
        :options="otherClass"
        :state="validate || validateOnclick[2] ? !!sklinfo.otherClass : null"
        @input="inputChanged(2)"
      ></b-form-radio-group>
      <div v-else class="choice">
        {{
          sklinfo.otherClass
            ? $t(`apply.spaps.tab_txt.tab3.otherClass_${sklinfo.otherClass}`)
            : '/'
        }}
      </div>
      <b-row v-if="sklinfo.otherClass === 1">
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.otherClassContent') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.otherClassContent"
            type="text"
            :state="
              validate || validateOnclick[6]
                ? checkExist(sklinfo.otherClassContent)
                : null
            "
            @input="inputChanged(6)"
          />
          <div v-else class="choice">
            {{
              checkExist(sklinfo.otherClassContent)
                ? sklinfo.otherClassContent
                : '/'
            }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.otherClassYear') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.otherClassYear"
            type="text"
            :state="
              validate || validateOnclick[7]
                ? checkYear(sklinfo.otherClassYear)
                : null
            "
            @input="inputChanged(7)"
          />
          <div v-else class="choice">
            {{
              checkExist(sklinfo.otherClassYear) ? sklinfo.otherClassYear : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <div class="questions mt-5">
        {{ $t('apply.spaps.tab_txt.tab3.otherTraining') }}
        <span class="red">*</span>
      </div>
      <b-form-radio-group
        v-if="edit"
        id="radio-group-otherTraining"
        v-model="sklinfo.otherTraining"
        :options="otherTraining"
        :state="validate || validateOnclick[3] ? !!sklinfo.otherTraining : null"
        @input="inputChanged(3)"
      ></b-form-radio-group>
      <div v-else class="choice">
        {{
          sklinfo.otherTraining
            ? $t(
                `apply.spaps.tab_txt.tab3.otherTraining_${sklinfo.otherTraining}`
              )
            : '/'
        }}
      </div>
      <div v-if="sklinfo.otherTraining === 1">
        <div class="event-title mt-3">
          {{ $t('apply.spaps.tab_txt.tab3.otherTrainingType') }}
          <span class="red">*</span>
        </div>
        <b-form-input
          v-if="edit"
          v-model="sklinfo.otherTrainingType"
          type="text"
          :state="
            validate || validateOnclick[8]
              ? checkExist(sklinfo.otherTrainingType)
              : null
          "
          @input="inputChanged(8)"
        />
        <div v-else class="choice">
          {{
            checkExist(sklinfo.otherTrainingType)
              ? sklinfo.otherTrainingType
              : '/'
          }}
        </div>
      </div>
      <b-row v-if="sklinfo.otherTraining === 1">
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.otherTrainingName') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.otherTrainingName"
            type="text"
            :state="
              validate || validateOnclick[9]
                ? checkExist(sklinfo.otherTrainingName)
                : null
            "
            @input="inputChanged(9)"
          />
          <div v-else class="choice">
            {{
              checkExist(sklinfo.otherTrainingName)
                ? sklinfo.otherTrainingName
                : '/'
            }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.otherTrainingYear') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.otherTrainingYear"
            type="text"
            :state="
              validate || validateOnclick[10]
                ? checkYear(sklinfo.otherTrainingYear)
                : null
            "
            @input="inputChanged(10)"
          />
          <div v-else class="choice">
            {{
              checkExist(sklinfo.otherTrainingYear)
                ? sklinfo.otherTrainingYear
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <div class="questions mt-5">
        {{ $t('apply.spaps.tab_txt.tab3.havePerformance') }}
        <span class="red">*</span>
      </div>
      <b-form-radio-group
        v-if="edit"
        id="radio-group-havePerformance"
        v-model="sklinfo.havePerformance"
        :options="havePerformance"
        :state="
          validate || validateOnclick[4] ? !!sklinfo.havePerformance : null
        "
        @input="inputChanged(4)"
      ></b-form-radio-group>
      <div v-else class="choice">
        {{
          sklinfo.havePerformance
            ? $t(
                `apply.spaps.tab_txt.tab3.havePerformance_${sklinfo.havePerformance}`
              )
            : '/'
        }}
      </div>
      <div v-if="sklinfo.havePerformance === 1">
        <div class="event-title mt-3">
          {{ $t('apply.spaps.tab_txt.tab3.havePerformanceType') }}
          <span class="red">*</span>
        </div>
        <b-form-input
          v-if="edit"
          v-model="sklinfo.havePerformanceType"
          type="text"
          :state="
            validate || validateOnclick[11]
              ? checkExist(sklinfo.havePerformanceType)
              : null
          "
          @input="inputChanged(11)"
        />
        <div v-else class="choice">
          {{
            checkExist(sklinfo.havePerformanceType)
              ? sklinfo.havePerformanceType
              : '/'
          }}
        </div>
      </div>
      <b-row v-if="sklinfo.havePerformance === 1">
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.havePerformanceName') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.havePerformanceName"
            type="text"
            :state="
              validate || validateOnclick[12]
                ? checkExist(sklinfo.havePerformanceName)
                : null
            "
            @input="inputChanged(12)"
          />
          <div v-else class="choice">
            {{
              checkExist(sklinfo.havePerformanceName)
                ? sklinfo.havePerformanceName
                : '/'
            }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab3.havePerformanceYear') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="sklinfo.havePerformanceYear"
            type="text"
            :state="
              validate || validateOnclick[13]
                ? checkYear(sklinfo.havePerformanceYear)
                : null
            "
            @input="inputChanged(13)"
          />
          <div v-else class="choice">
            {{
              checkExist(sklinfo.havePerformanceYear)
                ? sklinfo.havePerformanceYear
                : '/'
            }}
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />
  </div>
</template>
<script>
export default {
  props: {
    validate: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    sklinfo: {
      type: Object,
      default() {
        return {
          ticName1: {
            surname: null,
            givenName: null,
          },
          ticName2: {
            surname: null,
            givenName: null,
          },
          schoolMobile1: null,
          schoolMobile2: null,
          email1: null,
          email2: null,

          fund: null,
          otherGroup: null,
          otherGroupSpecify: null,

          otherClass: null,
          otherClassContent: null,
          otherClassYear: null,

          otherTraining: null,
          otherTrainingType: null,
          otherTrainingName: null,
          otherTrainingYear: null,

          havePerformance: null,
          havePerformanceType: null,
          havePerformanceName: null,
          havePerformanceYear: null,
        }
      },
    },
  },
  data() {
    return {
      noOfFieldsToValidate: 14,
      validateOnclick: [],
      fund: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab3.fund_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab3.fund_2') },
      ],
      otherGroup: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab3.otherGroup_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab3.otherGroup_2') },
      ],
      otherClass: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab3.otherClass_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab3.otherClass_2') },
      ],
      otherTraining: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab3.otherTraining_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab3.otherTraining_2') },
      ],
      havePerformance: [
        {
          value: 1,
          text: this.$t('apply.spaps.tab_txt.tab3.havePerformance_1'),
        },
        {
          value: 2,
          text: this.$t('apply.spaps.tab_txt.tab3.havePerformance_2'),
        },
      ],
    }
  },
  mounted() {
    for (let i = 0; i < this.noOfFieldsToValidate; i++) {
      this.validateOnclick.push(false)
    }
  },
  methods: {
    inputChanged(fieldIndex) {
      this.$emit('input', this.sklinfo, this.checkPassValidationTab3())
      this.validateOnclick[fieldIndex] = true
    },
    checkExist(input) {
      return !!input && input !== ''
    },
    checkYear(input) {
      const regex = new RegExp('[1-2][0-9][0-9][0-9]')
      return regex.test(input)
    },
    checkOtherGroupDetail() {
      if (this.sklinfo.otherGroup === 1) {
        return this.checkExist(this.sklinfo.otherGroupSpecify)
      }
      return true
    },
    checkOtheClassDetail() {
      if (this.sklinfo.otherClass === 1) {
        return (
          this.checkExist(this.sklinfo.otherClassContent) &&
          this.checkYear(this.sklinfo.otherClassYear)
        )
      }
      return true
    },
    checkOtheTrainingDetail() {
      if (this.sklinfo.otherTraining === 1) {
        return (
          this.checkExist(this.sklinfo.otherTrainingType) &&
          this.checkExist(this.sklinfo.otherTrainingName) &&
          this.checkYear(this.sklinfo.otherTrainingYear)
        )
      }
      return true
    },
    checkHavePerformanceDetail() {
      if (this.sklinfo.havePerformance === 1) {
        return (
          this.checkExist(this.sklinfo.havePerformanceType) &&
          this.checkExist(this.sklinfo.havePerformanceName) &&
          this.checkYear(this.sklinfo.havePerformanceYear)
        )
      }
      return true
    },
    checkPassValidationTab3() {
      return (
        !!this.sklinfo.fund &&
        !!this.sklinfo.otherGroup &&
        this.checkOtherGroupDetail() &&
        !!this.sklinfo.otherClass &&
        this.checkOtheClassDetail() &&
        !!this.sklinfo.otherTraining &&
        this.checkOtheTrainingDetail() &&
        !!this.sklinfo.havePerformance &&
        this.checkHavePerformanceDetail()
      )
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
h3 {
  color: #3182ce;
}
.event-title {
  // color: #1fbcb4;
}
.choice {
  background-color: whitesmoke;
  padding: 5px;
  overflow-wrap: break-word;
}
.subtitle {
  color: #ffa500;
}
.questions {
  // color: #3182ce;
}
.spaps-div {
  // border: 2px solid #1fbcb4;
  padding: 10px 0;
  text-align: left;
}
</style>
