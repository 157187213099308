<template>
  <b-row v-if="edit" style="width: 50%; display: inline-flex; float: right">
    <!-- <b-col sm="8">
      <b-form-input
        v-if="lang == 0"
        v-model="value.text.en"
        :value="value.text.en"
        :placeholder="$t('event.remark.en')"
      ></b-form-input>
      <b-form-input
        v-else-if="lang == 1"
        v-model="value.text.tc"
        :value="value.text.tc"
        :placeholder="$t('event.remark.tc')"
      ></b-form-input>
      <b-form-input
        v-else-if="lang == 2"
        v-model="value.text.sc"
        :value="value.text.sc"
        :placeholder="$t('event.remark.sc')"
      ></b-form-input>
    </b-col>
    <b-col sm="4">
      <b-form-checkbox
        v-model="value.color"
        value="red"
        unchecked-value="default"
      >
        Red
      </b-form-checkbox>
    </b-col> -->
    <b-col>
      <medium-editor
        v-if="lang == 0"
        class="medium-editor"
        :text="value.text.en"
        :options="options_en"
        @edit="
          (operation) => {
            value.text.en = operation.api.origElements.innerHTML
          }
        "
      ></medium-editor>
      <medium-editor
        v-if="lang == 1"
        class="medium-editor"
        :text="value.text.tc"
        :options="options_tc"
        @edit="
          (operation) => {
            value.text.tc = operation.api.origElements.innerHTML
          }
        "
      ></medium-editor>
      <medium-editor
        v-if="lang == 2"
        class="medium-editor"
        :text="value.text.sc"
        :options="options_sc"
        @edit="
          (operation) => {
            value.text.sc = operation.api.origElements.innerHTML
          }
        "
      ></medium-editor>
    </b-col>
  </b-row>
  <!-- <span v-else :class="value.color == 'red' ? 'red' : ''">{{
    i18nText(value.text)
  }}</span> -->
  <span v-else v-html="i18nText(value.text)"></span>
</template>
<script>
import RedBtn from '~/components/event/redBtn'
import ResetBtn from '~/components/event/resetBtn'
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ text: { en: '', tc: '', sc: '' }, color: 'default' }),
    },
    id: { type: Number, default: 0 },
    lang: { type: Number, default: 0 },
    edit: { type: Boolean, default: false },
  },
  data() {
    return {
      options_en: {
        toolbar: {
          buttons: ['red', 'bold'],
          // buttons: ['red', 'reset', 'bold'],
        },
        extensions: {
          /* red: new RedBtn({
            buttonText: '<hr>',
            htmlToInsert: "<hr class='someclass'>",
          }), */
          red: new RedBtn.P1(),
          reset: new ResetBtn.P1(),
        },
        placeholder: {
          text: this.$t('event.remark.en'),
        },
        disableReturn: true,
      },
      options_tc: {
        toolbar: {
          buttons: ['red', 'bold'],
        },
        extensions: {
          red: new RedBtn.P1(),
          reset: new ResetBtn.P1(),
        },
        placeholder: {
          text: this.$t('event.remark.tc'),
        },
        disableReturn: true,
      },
      options_sc: {
        toolbar: {
          buttons: ['red', 'bold'],
        },
        extensions: {
          red: new RedBtn.P1(),
          reset: new ResetBtn.P1(),
        },
        placeholder: {
          text: this.$t('event.remark.sc'),
        },
        disableReturn: true,
      },
    }
  },
  computed: {},
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.$emit('change', this.id, newVal)
      },
      deep: true,
    },
    lang: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    i18nText(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.en
        case 'tc':
          return item.tc
        case 'sc':
          return item.sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.red {
  color: red;
}
.medium-editor {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
</style>
