<template>
  <div class="pw-cont">
    <h2 class="title">
      <img :src="`${staticPrefix}img/icon_password.svg`" alt="icon password" />
      {{ $t('account.password') }}
    </h2>
    <div class="rules" v-html="$t('account.password_rules')"></div>
    <div class="pw-cont-inner">
      <div v-html="$t('account.oldPassword')"></div>
      <div class="d-flex">
        <b-form-input
          v-model="oldPassword"
          :type="viewOldPassword ? 'text' : 'password'"
          size="lg"
        />
        <b-button
          class="ml-2"
          variant="outline-dark"
          @click="viewOldPassword = !viewOldPassword"
        >
          <b-icon :icon="viewOldPassword ? 'eye' : 'eye-slash'"></b-icon>
        </b-button>
      </div>

      <div v-html="$t('account.newPassword')"></div>
      <div class="d-flex">
        <b-form-input
          v-model="newPassword"
          :type="viewNewPassword ? 'text' : 'password'"
          :state="validatePw(newPassword) && newPassword !== ''"
          size="lg"
        />
        <b-button
          class="ml-2"
          variant="outline-dark"
          @click="viewNewPassword = !viewNewPassword"
        >
          <b-icon :icon="viewNewPassword ? 'eye' : 'eye-slash'"></b-icon>
        </b-button>
      </div>

      <div v-html="$t('account.repeatPassword')"></div>
      <div class="d-flex">
        <b-form-input
          v-model="repeatPassword"
          :type="viewRepeatPassword ? 'text' : 'password'"
          :state="validateRepeatPw"
          size="lg"
        />
        <b-button
          class="ml-2"
          variant="outline-dark"
          @click="viewRepeatPassword = !viewRepeatPassword"
        >
          <b-icon :icon="viewRepeatPassword ? 'eye' : 'eye-slash'"></b-icon>
        </b-button>
      </div>

      <div class="submit-pw">
        <div class="btn btn-apply" @click="submitPw()">
          {{ $t('action.submit') }}
        </div>
      </div>
    </div>

    <Notification v-if="error" :message="error" />
    <b-alert v-if="success" variant="success" show>
      <b-icon icon="check-circle"></b-icon>
      {{ $t('account.pwSuccess') }}
    </b-alert>
  </div>
</template>
<script>
export default {
  layout: 'default',
  middleware: ['RequireLogin', 'jwtCheck'],
  fetch() {
    this.loading = false
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      repeatPassword: null,
      viewOldPassword: false,
      viewNewPassword: false,
      viewRepeatPassword: false,
      success: false,
      error: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.localStorage.profile
    },
    staticPrefix() {
      return process.env.base
    },
    validateRepeatPw() {
      if (!this.repeatPassword || this.repeatPassword === '') return null
      return this.newPassword === this.repeatPassword
    },
  },
  methods: {
    validatePw(pwd) {
      if (!pwd) return null
      let matched = 0
      const uppercase = new RegExp('[A-Z]')
      const lowercase = new RegExp('[a-z]')
      const numeric = new RegExp('[0-9]')
      const symbol = new RegExp('[$-/:-?{-~!"^_`[]]')
      const name = new RegExp(this.user.email)

      if (pwd.length < 10) return false
      if (uppercase.test(pwd)) matched += 1
      if (lowercase.test(pwd)) matched += 1
      if (numeric.test(pwd)) matched += 1
      if (symbol.test(pwd)) matched += 1
      if (!name.test(pwd)) matched += 1
      if (matched >= 4) return true
      return false
    },
    async submitPw() {
      try {
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}users/newPassword`,
          {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
            repeatPassword: this.repeatPassword,
          }
        )
        if (result) this.success = true
        // console.log(result, this.success)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.rules {
  background-color: whitesmoke;
  padding: 15px;
  color: #3f3f3f;
  text-align: left;
}
.contcontaier {
  background: white;
  padding-bottom: 100px;
}
.cont-wrap {
  display: block;
  flex-wrap: wrap;
}
.cont {
  width: calc(85% + 20px);
  margin-left: auto;
  margin-right: auto;
}
.scheme-cont {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.pw-cont {
  max-width: 640px;
  padding: 10px 50px;
  margin-left: auto;
  margin-right: auto;
}
.pw-cont-inner {
  padding: 0 30px;
  border: 1px solid #ddd;
  border-radius: 0.25em;
  text-align: left;
}
.pw-cont > *,
.pw-cont-inner > * {
  margin: 16px 0;
}
.btn-apply {
  width: 180px;
  border-radius: 20px;
  padding: 7px 50px;
  margin-right: auto;
  margin-left: auto;
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: white;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-apply:hover {
  background-color: #17a79f;
}
.submit-pw {
  display: flex;
  justify-content: end;
}
.title {
  text-align: left;
  color: #393939;
  margin-bottom: 5px;
  font-weight: 100;
  font-size: 24px;
}
</style>
