var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "8" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("news.date"))),
                ]),
                _vm._v(" "),
                _c("b-form-datepicker", {
                  attrs: {
                    id: "date",
                    "today-button": "",
                    "reset-button": "",
                    trim: "",
                  },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "scheme", "label-for": "scheme" } },
                  [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("event.scheme"))),
                    ]),
                    _vm._v(" "),
                    _c("b-form-select", {
                      attrs: { options: _vm.schemeList },
                      model: {
                        value: _vm.scheme,
                        callback: function ($$v) {
                          _vm.scheme = $$v
                        },
                        expression: "scheme",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "title", "label-for": "title" } },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("news.title.en") } },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "title_en",
                                rows: "3",
                                "max-rows": "6",
                              },
                              model: {
                                value: _vm.title_en,
                                callback: function ($$v) {
                                  _vm.title_en = $$v
                                },
                                expression: "title_en",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("news.title.tc") } },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "title_tc",
                                rows: "3",
                                "max-rows": "6",
                              },
                              model: {
                                value: _vm.title_tc,
                                callback: function ($$v) {
                                  _vm.title_tc = $$v
                                },
                                expression: "title_tc",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("news.title.sc") } },
                          [
                            _c("b-form-textarea", {
                              attrs: {
                                id: "title_sc",
                                rows: "3",
                                "max-rows": "6",
                              },
                              model: {
                                value: _vm.title_sc,
                                callback: function ($$v) {
                                  _vm.title_sc = $$v
                                },
                                expression: "title_sc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "content", "label-for": "content" } },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("news.content.en") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_en",
                                value: _vm.content_en,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("news.content.tc") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_tc",
                                value: _vm.content_tc,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("news.content.sc") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_sc",
                                value: _vm.content_sc,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("news.startDate"))),
                ]),
                _vm._v(" "),
                _c("b-form-datepicker", {
                  attrs: {
                    id: "startDate",
                    "today-button": "",
                    "reset-button": "",
                    required: "",
                    trim: "",
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function ($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("news.endDate"))),
                ]),
                _vm._v(" "),
                _c("b-form-datepicker", {
                  attrs: {
                    id: "endDate",
                    "today-button": "",
                    "reset-button": "",
                    required: "",
                    trim: "",
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function ($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("news.order"))),
                ]),
                _vm._v(" "),
                _c("b-form-input", {
                  attrs: { id: "orders", type: "number", trim: "" },
                  model: {
                    value: _vm.orders,
                    callback: function ($$v) {
                      _vm.orders = $$v
                    },
                    expression: "orders",
                  },
                }),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "disabled" } },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { value: "1", "unchecked-value": "0" },
                        model: {
                          value: _vm.disabled,
                          callback: function ($$v) {
                            _vm.disabled = $$v
                          },
                          expression: "disabled",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("news.disabled")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { value: 0 },
                        model: {
                          value: _vm.deployTarget,
                          callback: function ($$v) {
                            _vm.deployTarget = $$v
                          },
                          expression: "deployTarget",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("news.deployTarget0")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-radio",
                      {
                        attrs: { value: 1 },
                        model: {
                          value: _vm.deployTarget,
                          callback: function ($$v) {
                            _vm.deployTarget = $$v
                          },
                          expression: "deployTarget",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("news.deployTarget1")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-radio",
                      {
                        attrs: { value: 2 },
                        model: {
                          value: _vm.deployTarget,
                          callback: function ($$v) {
                            _vm.deployTarget = $$v
                          },
                          expression: "deployTarget",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("news.deployTarget2")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                    _vm._v(" "),
                    _vm.deployTarget === 0 || _vm.deployTarget === 2
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.previewEplatform()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("action.preview")) +
                                " (E-Platform)\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.deployTarget === 1 || _vm.deployTarget === 2
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-warning" },
                            on: {
                              click: function ($event) {
                                return _vm.previewWebpage()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("action.preview")) +
                                " (Webpage)\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }