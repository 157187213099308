<template>
  <div v-if="show" class="profile">
    <div class="usericon my-3">
      <span> {{ user.name.substring(0, 1) }}</span>
    </div>
    <h6 class="userName my-2">{{ user.name }}</h6>
    <div v-if="user.school" class="schoolName my-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
        style="width: 1.3em; vertical-align: -0.15em"
      >
        <path
          fill="#4f4f4f"
          d="M0 224v272c0 8.84 7.16 16 16 16h80V192H32c-17.67 0-32 14.33-32 32zm360-48h-24v-40c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v64c0 4.42 3.58 8 8 8h48c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm137.75-63.96l-160-106.67a32.02 32.02 0 0 0-35.5 0l-160 106.67A32.002 32.002 0 0 0 128 138.66V512h128V368c0-8.84 7.16-16 16-16h96c8.84 0 16 7.16 16 16v144h128V138.67c0-10.7-5.35-20.7-14.25-26.63zM320 256c-44.18 0-80-35.82-80-80s35.82-80 80-80 80 35.82 80 80-35.82 80-80 80zm288-64h-64v320h80c8.84 0 16-7.16 16-16V224c0-17.67-14.33-32-32-32z"
        ></path></svg
      >&nbsp;
      {{ user.school }}
    </div>
    <div v-if="user.schoolId" class="schoolId my-2">
      {{ $t('account.schoolId') }}{{ user.schoolId }}
    </div>
    <div class="btn-group">
      <b-button
        class="btn-profile btn-myAc"
        variant="light"
        pill
        :disabled="user.isAdmin"
        @click="account()"
      >
        {{ $t('navbar.myAccount') }}
      </b-button>
      <b-button
        class="btn-profile btn-logout"
        variant="outline-light"
        pill
        @click="logout()"
      >
        {{ $t('navbar.logout') }}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserProfile',
  props: {
    user: {
      type: Object,
      default() {
        return { name: '', school: '', schoolId: 0, isAdmin: false }
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  watch: {
    show: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  },
  mounted() {},
  methods: {
    account() {
      this.$router.push(this.localePath('/settings/account'))
      this.$emit('close', false)
    },
    logout() {
      try {
        this.$store.dispatch('localStorage/logout')
      } catch (e) {}
      this.$router.push(this.localePath('/login'))
      this.$emit('close', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.userName {
  font-size: 1.15em;
}
.profile {
  position: absolute;
  width: 245px;
  height: 335px;
  right: 2px;
  top: 75px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  overflow-y: scroll;
}
.profile > * {
  margin: 4px 0;
}
.profile .usericon {
  margin-right: auto;
  margin-left: auto;
  width: 56px;
  height: 56px;
}
.usericon {
  user-select: none;
  background: #fc7137;
  color: #fff !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  font-size: 30px;
  cursor: pointer;
}
.btn-profile {
  min-width: 146px;
  margin: 2px 0;
  padding: 8px 40px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-myAc:hover {
  background-color: #17a79f;
}
.btn-myAc {
  background-color: #1fbcb4;
  color: #fff;
}
.btn-logout {
  color: #000;
}
.schoolId {
  font-size: 0.85em;
  color: rgb(141, 141, 141);
}
.school-img {
  height: 14px;
}
.btn-group {
  bottom: 20px;
  display: inline-block;
  position: absolute;
  left: 0;
}

.profile {
  display: block;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .profile {
    display: none;
  }
}
</style>
