<template>
  <b-row align-h="center" class="component">
    <b-col sm="12">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <b-form-group
            v-if="action != 'send' && code"
            id="code"
            label-for="code"
          >
            {{ $t('mailTemplate.code') }}
            <b-form-input
              v-model="code"
              type="text"
              :readonly="action !== 'add'"
            ></b-form-input>
          </b-form-group>
          {{ $t('mailTemplate.target') }}
          <b-form-group id="to" label-for="to">
            <b-form-select
              v-model="target"
              :options="targetOption"
              :state="!!target"
              size="sm"
              class="mt-1"
              required
            ></b-form-select>
          </b-form-group>
          {{ $t('smsTemplate.advancedTarget') }}
          <b-form-group id="to" label-for="to">
            <b-form-select
              v-model="advTarget"
              :options="advTargetOption"
              :state="!!advTarget"
              size="sm"
              class="mt-1"
              required
            ></b-form-select>
          </b-form-group>

          <div v-if="advTarget !== 'none'">
            {{ $t('smsTemplate.advancedTarget') }}
            <b-row>
              <b-col sm="3">
                {{ $t('smsTemplate.filter') }}
                <b-form-input
                  v-model="advTarget2Filter"
                  size="sm"
                  class="mt-1"
                  @change="getAdvTargetOption2"
                ></b-form-input>
              </b-col>
              <b-col sm="9">
                {{ $t('smsTemplate.select') }}
                <b-form-select
                  v-model="advTarget2"
                  :options="advTarget2FilterOption"
                  :state="!!advTarget2"
                  size="sm"
                  class="mt-1"
                  required
                ></b-form-select>
              </b-col>
            </b-row>
          </div>

          <div v-if="advTarget === 'eventSlotId' && advTarget2 !== null">
            {{ $t('smsTemplate.advancedTarget') }}
            <b-form-group id="to" label-for="to">
              <b-form-select
                v-model="advTarget3"
                :options="advTargetOption3"
                :state="!!advTarget3"
                size="sm"
                class="mt-1"
                required
              ></b-form-select>
            </b-form-group>
          </div>
          <!-- <div v-if="advTargetResult !== null">
            <p>Count: {{ advTargetResult.length }}</p>
            <div v-for="i in advTargetResult" :key="i">
              <p>
                Name: {{ i.name }} | Email: {{ i.email }} | Tel: {{ i.tel }}
              </p>
            </div>
          </div> -->
          <div v-if="advTarget !== 'none'">
            <div v-if="advTargetResult !== null" style="padding: 10px 0px">
              <b-button v-b-toggle.collapse-1 variant="primary" size="sm"
                >對象名單
                <b-badge variant="light"> {{ advTargetResult.length }} </b-badge
                >：</b-button
              >
              <b-collapse id="collapse-1" class="mt-2">
                <b-card>
                  <div v-for="i in advTargetResult" :key="i">
                    <p>
                      Name: {{ i.name }} | Email: {{ i.email }} | Tel:
                      {{ i.tel }}
                    </p>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </div>
          <!-- <b-form-radio-group
              v-model="target"
              :options="targetOption"
              :state="!!target"
              class="mb-3"
              required
            ></b-form-radio-group> -->
          <b-form-group id="to" label-for="to">
            {{ $t('mailTemplate.to') }}
            <b-form-input v-model="receiver" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="cc" label-for="cc">
            {{ $t('mailTemplate.cc') }}
            <b-form-input v-model="cc" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="bcc" label-for="bcc">
            {{ $t('mailTemplate.bcc') }}
            <b-form-input v-model="bcc" type="text"></b-form-input>
          </b-form-group>

          <b-form-group id="subject" label-for="subject">
            {{ $t('mailTemplate.subject') }}
            <b-form-input v-model="subject" type="text"></b-form-input>
          </b-form-group>
          {{ $t('mailTemplate.content') }}
          <b-form-group id="content" label-for="content">
            <div>
              <tinymceEditorFlexible
                v-if="isShow"
                id="content"
                ref="tinymce"
                :value="content"
                @writeContent="getContent"
                @editorInit="(e) => e.setContent(content)"
              />
            </div>
          </b-form-group>

          <b-form-group id="testto" label-for="testto">
            {{ $t('mailTemplate.testto') }}
            <b-row>
              <b-col cols="8">
                <b-form-input v-model="testReceiver" type="text"></b-form-input>
              </b-col>
              <b-col cols="2">
                <b-button variant="outline-info" @click="sendTestMail">{{
                  $t('mailTemplate.testmail')
                }}</b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button
              v-if="code"
              variant="outline-success"
              @click="submitEdit"
              >{{ $t('action.saveTemplate') }}</b-button
            >
            <b-button id="submit" type="submit" variant="outline-danger">{{
              $t('action.submitToMailQueue')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <!-- <tools :tc="getTc" @writeContent="setSc"></tools> -->
  </b-row>
</template>

<script>
import $ from 'jquery'
import TinymceEditorFlexible from '~/components/admin/tinymceEditorFlexible'
import Notification from '~/components/common/notification'
/* import tools from '~/components/common/tools' */

export default {
  components: {
    Notification,
    TinymceEditorFlexible,
    /* tools, */
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    action: { type: String, default: 'add' },
    trnId: { type: String, default: '' },
    tempCode: { type: String, default: '' },
    custom: { type: Object, default: null },
    // isShow: { type: Boolean, default: false },
  },
  data() {
    return {
      id: null,
      code: '',
      receiver: '{{users|email}}',
      cc: '',
      bcc: '',
      subject: '',
      content: '',
      schoolId: null,
      userId: null,
      testReceiver: '',
      orders: null,
      error: null,
      isShow: false,
      target: 'all',
      targetOption: [
        { value: 'all', text: '全部 All Teacher' },
        { value: 'k', text: '幼稚園 Kindergarten' },
        { value: 'p', text: '小學 Primary' },
        { value: 's', text: '中學 Secondary' },
        { value: 'sp', text: '特殊 Special' },
        { value: 'o', text: '其他 Other' },
      ],
      advTarget: 'none',
      advTargetOption: [
        { value: 'none', text: '沒有 None' },
        { value: 'eventId', text: '活動 Event' },
        { value: 'eventSlotId', text: '活動場次 Event Slot' },
        { value: 'district', text: '區域 District' },
      ],
      advTarget2: null,
      advTargetOption2: [],
      advTarget3: null,
      advTargetOption3: [], // event slot only
      tmp_advTargetResult: [],
      advTargetResult: [],
      advTarget2Filter: null,
      advTarget2FilterOption: [],
    }
  },
  async fetch() {
    try {
      if (this.action === 'edit' || this.action === 'send') {
        if (this.action === 'edit') {
          await this.$axios
            .get(
              `${process.env.cloudApiUrl}mailTemplate/${this.$route.params.id}`
            )
            .then((result) => {
              const data = result.data.data[0]
              this.id = data.id
              this.code = data.code
              this.receiver = data.receiver
              this.cc = data.cc
              this.bcc = data.bcc
              this.subject = data.subject
              this.content = data.content
              this.focusTextEditor()
            })
        } else if (this.action === 'send') {
          const template = await this.$axios.get(
            `${process.env.cloudApiUrl}mailTemplate/template/${this.tempCode}`
          )
          const result = await this.$axios.post(
            `${process.env.localApiUrl}mailTemplate/generate`,
            {
              trnId: this.trnId,
              code: this.tempCode,
              template: template.data.data[0],
              custom: this.custom,
            }
          )
          this.userId = result.data.data.userId
          this.schoolId = result.data.data.schoolId
          const data = result.data.data.template
          this.receiver = data.receiver
          this.cc = data.cc
          this.bcc = data.bcc
          this.subject = data.subject
          this.content = data.content
          this.focusTextEditor()
        }
      } else {
        this.isShow = true
      }
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  computed: {},
  watch: {
    target: {
      handler(newVal, oldVal) {
        if (this.advTargetResult) {
          this.updateAdvTargetResult(newVal)
        }
      },
      deep: true,
    },
    tmp_advTargetResult: {
      handler(newVal, oldVal) {
        this.updateAdvTargetResult(this.target)
      },
      deep: true,
    },
    advTarget: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          let tmp = newVal
          if (newVal === 'eventSlotId') {
            tmp = 'eventId'
          }
          this.getAdvTarget1(tmp)
        }
      },
      deep: true,
    },
    advTarget2: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          if (this.advTarget === 'eventSlotId') {
            this.getAdvTarget2('eventSlotId', newVal)
          } else if (this.advTarget === 'district') {
            this.getAdvTargetResult('district', newVal)
          } else if (this.advTarget === 'eventId') {
            this.getAdvTargetResult('eventId', newVal)
          }
        }
      },
      deep: true,
    },
    advTarget3: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          if (this.advTarget === 'eventSlotId') {
            this.getAdvTargetResult('eventSlotId', newVal)
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.action === 'edit' || this.action === 'send') {
      this.isShow = false
      $(document).on('focusin', function (e) {
        if (
          $(e.target).closest(
            '.tox-tinymce-aux, .tox-dialog, .moxman-window, .tam-assetmanager-root'
          ).length
        ) {
          e.stopImmediatePropagation()
        }
      })
    }
  },
  methods: {
    getAdvTargetOption2() {
      this.advTarget2FilterOption = this.advTarget2Filter
        ? this.advTargetOption2.filter((item) => {
            return item.text
              .toLowerCase()
              .includes(this.advTarget2Filter.toLowerCase())
          })
        : this.advTargetOption2
    },
    async getAdvTarget1(target) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters`,
        {
          type: target,
        }
      )
      this.advTargetOption2 = result.data.data
      this.getAdvTargetOption2()
      this.advTarget2 = null
      this.advTarget3 = null
    },
    async getAdvTarget2(target, eventId) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters`,
        {
          type: target,
          eventId,
        }
      )
      this.advTargetOption3 = result.data.data
      this.advTarget3 = null
    },
    async getAdvTargetResult(target, Id) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters/target`,
        {
          type: target,
          id: Id,
        }
      )
      this.tmp_advTargetResult = result.data.data
      // this.advTargetResult = this.tmp_advTargetResult
    },
    updateAdvTargetResult(target) {
      if (target === 'k') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 1
        )
      } else if (target === 'p') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 2
        )
      } else if (target === 's') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 3
        )
      } else if (target === 'sp') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 4
        )
      } else if (target === 'o') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 5
        )
      } else {
        this.advTargetResult = this.tmp_advTargetResult
      }
    },
    focusTextEditor() {
      this.$nextTick(() => {
        this.isShow = true
        /* console.log('emailContent', this.$refs.tinymce.$el)
        this.$refs.tinymce.$el.focus() */
      })
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async submitEdit() {
      console.log('Save template')
      try {
        let result
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.receiver,
          cc: this.cc,
          bcc: this.bcc,
          subject: this.subject,
          content: this.content,
        }
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}mailTemplate/update`,
            obj
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/emailTemplate'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async submit() {
      console.log('this.action: ', this.action)
      const confirm = await this.$swal({
        title: this.$t('alert.sendEmail'),
        // text: this.$t('change_request.reconfirm'),
        type: 'question',
        showCancelButton: true,
        confirmButtonText: `${this.$t('alert.yes')}`,
        confirmButtonColor: '#d9534f', // '#28a745',
        cancelButtonText: `${this.$t('alert.no')}`,
      })
      if (confirm.value) {
        // console.log('Send email')
        try {
          const obj = {
            id: this.id,
            code: this.code,
            receiver: this.receiver,
            cc: this.cc,
            bcc: this.bcc,
            subject: this.subject,
            content: this.content,
          }
          // TODO: change api to generate customized email notification and add to queue system.
          const result = await this.$axios.$post(
            `${process.env.cloudApiUrl}mailTemplate/compose/send`,
            {
              template: obj,
              target: this.target,
              advancedTarget: this.advTargetResult,
            }
          )
          if (result.error !== undefined) throw new Error(result.error)
          const back = await this.$swal({
            title: this.$t('alert.title'),
            text: `${this.$t('alert.content')}： ${this.$t(
              'alert.send'
            )}${this.$t('alert.success')}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.$t('alert.back'),
            confirmButtonColor: '#d9534f',
            cancelButtonText: 'OK',
          })
          if (back.value) {
            this.$router.push(this.localePath('/admin/mailTemplate'))
          }
        } catch (err) {
          // console.log(err)
          this.error = this.$t('error.' + err.response.data.error)
        }
      }
    },
    async sendTestMail() {
      try {
        const obj = {
          id: this.id,
          code: this.code,
          receiver: this.testReceiver,
          cc: '',
          bcc: '',
          subject: this.subject,
          content: this.content,
        }
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}mailTemplate/send`,
          {
            template: obj,
            userId: 0,
            schoolId: 0,
          }
        )
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `test mail has been sent`,
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: 'OK',
        })
        if (back.value) {
          // this.$router.push(this.localePath('/admin/mailTemplate'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
