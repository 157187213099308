<template>
  <b-row align-h="center" class="component">
    <b-col cols="5">
      <Notification v-if="error" :message="error" />
      <div class="back">
        <nuxt-link :to="localePath('/')">
          <img
            :src="`${staticPrefix}img/login_home.png`"
            style="height: 25px"
            alt="home"
          />
        </nuxt-link>
      </div>
      <b-card>
        <div class="login">
          <b-col cols="8">
            <h3 class="title-login">{{ $t('g.title') }}</h3>
            <h4 class="title-login">{{ $t('login.forgotpw') }}</h4>
          </b-col>
          <b-form method="post" @submit.prevent="loginSubmit">
            <b-form-group id="email" label-for="email">
              <p class="label">{{ $t('login.email') }}</p>
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                required
                trim
              ></b-form-input>
            </b-form-group>

            <div>
              <b-button
                id="login"
                type="submit"
                :disabled="api && api.includes('preview')"
              >
                {{ $t('action.submit') }}
              </b-button>
            </div>
            <div>
              <b-row align-h="between">
                <b-col align="left">
                  <nuxt-link :to="localePath('/login')">{{
                    $t('login.login')
                  }}</nuxt-link>
                </b-col>
                <b-col align="right">
                  <a :href="registrationLink" target="_blank">{{
                    $t('login.donthaveAcc')
                  }}</a>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
export default {
  components: {
    Notification,
  },
  data() {
    return {
      api: process.env.mainApiUrl,
      email: '',
      error: null,
      registrationLink: 'https://eform.one.gov.hk/form/lcs105/',
    }
  },
  computed: {
    staticPrefix() {
      return process.env.base
    },
  },
  methods: {
    async loginSubmit() {
      try {
        const result = await this.$axios.$post(`users/forgotPwd`, {
          email: this.email,
        })
        if (result.error !== undefined) throw new Error(result.error)
        await this.$swal({
          text: `如你的帳戶存在,系統會發送一封重設密碼電郵到你的電郵地址！`,
          icon: 'question',
          confirmButtonText: 'OK',
          confirmButtonColor: '#d9534f',
        })
        this.$router.push(this.localePath('/'))
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style scoped>
* {
  color: #fd7136;
}

.component {
  justify-content: center;
  text-align: center;
  padding-top: 10%;
}

.col {
  margin: 0px;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

h3 {
  padding-bottom: 30px;
}

form {
  padding-left: 20px;
  padding-right: 20px;
}

.title-login {
  letter-spacing: 2px;
  text-align: left;
  padding-left: 5px;
}

.links {
  padding-top: 15px;
}

.form-group > label {
  text-align: left;
}

p {
  color: #000;
}

.row {
  margin: 0px;
}

#login {
  width: 100%;
  color: #fff;
  background: #fd7136;
  border: 0px;
  border-radius: 0px;
  margin-top: 20px;
}

#login:hover {
  width: 100%;
  color: #fff;
  background: #fbaf5d;
  border: 0px;
  border-radius: 0px;
}

span {
  color: #000;
}

input {
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid #fd7136;
  background-color: rgba(255, 255, 255, 0);
}

.d-block {
  text-align: left;
}

a {
  font-weight: bold;
}

a:hover {
  color: #fd7136;
}

.label {
  color: #fd7136;
  text-align: left;
}
.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.9);
}

.back {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.9);
  width: 50px;
  z-index: 1;
  background: #fff;
  border: 1px;
  margin-left: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .col-5,
  .login .col-8 {
    max-width: unset;
    flex: none;
  }

  .col-5 {
    top: 75px;
    padding: 0;
  }

  .card {
    border-radius: 0;
  }
}
</style>
