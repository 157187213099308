var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("p", [_vm._v("地點代號")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "code", "label-for": "code" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "code",
                        type: "text",
                        required: "",
                        readonly: _vm.action === "edit" ? true : false,
                        state: _vm.validation(),
                        trim: "",
                      },
                      model: {
                        value: _vm.code,
                        callback: function ($$v) {
                          _vm.code = $$v
                        },
                        expression: "code",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("small", [_vm._v("請使用統一格式")]),
                _c("br"),
                _vm._v(" "),
                _c("small", [_vm._v("e.g. 香港大會堂 地點代號：HKCH")]),
                _c("br"),
                _vm._v(" "),
                _c("small", [_vm._v("e.g. 香港大會堂劇院 地點代號：TH/HKCH")]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("p", [_vm._v("場館代號")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "code", "label-for": "code" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "code",
                        type: "text",
                        required: "",
                        trim: "",
                      },
                      model: {
                        value: _vm.displayCode,
                        callback: function ($$v) {
                          _vm.displayCode = $$v
                        },
                        expression: "displayCode",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("small", [
                  _vm._v("\n          場館代號只顯示於節目表\n          "),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://www.abo.gov.hk/tc/eventCategory/2/2020/09",
                      },
                    },
                    [_vm._v("\n            Example\n          ")]
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", [_vm._v("e.g. 香港大會堂 場館代號：HKCH")]),
                _c("br"),
                _vm._v(" "),
                _c("small", [_vm._v("e.g. 香港大會堂劇院 場館代號：HKCH")]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("p", { staticClass: "mt-3" }, [_vm._v("地點名稱（英）")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "name_en", "label-for": "name_en" } },
                  [
                    _c("b-form-input", {
                      attrs: { id: "name_en", type: "text", trim: "" },
                      model: {
                        value: _vm.name_en,
                        callback: function ($$v) {
                          _vm.name_en = $$v
                        },
                        expression: "name_en",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", [_vm._v("地點名稱（繁）")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "name_tc", "label-for": "name_tc" } },
                  [
                    _c("b-form-input", {
                      attrs: { id: "name_tc", type: "text", trim: "" },
                      model: {
                        value: _vm.name_tc,
                        callback: function ($$v) {
                          _vm.name_tc = $$v
                        },
                        expression: "name_tc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", [_vm._v("地點名稱（簡）")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "name_sc", "label-for": "name_sc" } },
                  [
                    _c("b-form-input", {
                      attrs: { id: "name_sc", type: "text", trim: "" },
                      model: {
                        value: _vm.name_sc,
                        callback: function ($$v) {
                          _vm.name_sc = $$v
                        },
                        expression: "name_sc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }