var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      (_vm.$route.name.includes("apply") ||
        _vm.$route.name.includes("application") ||
        _vm.$route.name.includes("shoppingCart")) &&
      _vm.schoolId
        ? _c("tab-1", {
            attrs: {
              edit: _vm.edit,
              eventlist: _vm.eventlist,
              "choice-array": _vm.choiceArray,
              classes: _vm.classes,
              "event-group": _vm.eventGroup,
              workshop: _vm.workshop,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      (_vm.$route.name.includes("apply") ||
        _vm.$route.name.includes("application") ||
        _vm.$route.name.includes("shoppingCart")) &&
      _vm.schoolId
        ? _c("tab-2", { attrs: { equipments: _vm.equipments, edit: _vm.edit } })
        : _vm._e(),
      _vm._v(" "),
      (_vm.$route.name.includes("apply") ||
        _vm.$route.name.includes("application") ||
        _vm.$route.name.includes("shoppingCart")) &&
      _vm.schoolId
        ? _c("tab-3", { attrs: { sklinfo: _vm.sklinfo, edit: _vm.edit } })
        : _vm._e(),
      _vm._v(" "),
      (_vm.$route.name.includes("apply") ||
        _vm.$route.name.includes("application") ||
        _vm.$route.name.includes("shoppingCart")) &&
      _vm.schoolId
        ? _c("tab-4", {
            attrs: {
              "pass-teachers": _vm.teachers,
              remarks: _vm.remarks,
              edit: _vm.edit,
              sklinfo: _vm.sklinfo,
              "sch-tc": _vm.schTc,
              "sch-en": _vm.schEn,
              "user-name-tc": _vm.userNameTc,
              "user-email": _vm.userEmail,
              "user-tel": _vm.userTel,
              "has-tel": _vm.hasTel,
              "school-id": _vm.schoolId,
              "user-id": _vm.userId,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      (_vm.$route.name.includes("application") &&
        !_vm.$route.name.includes("change_request")) ||
      (_vm.$route.name.includes("apply") && !_vm.schoolId)
        ? _c("tab-6", { attrs: { edit: _vm.edit, scheme: "spaps" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }