<template>
  <svg class="svg-icon">
    <use :xlink:href="`${src}#${name}`" :fill="color" />
  </svg>
</template>
<script>
export default {
  props: {
    src: { type: String, default: '' },
    name: { type: String, required: true },
    color: { type: String, default: '#000000' },
  },
}
</script>
