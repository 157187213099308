var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isList
    ? _c("tr", [
        _vm.event.category_id
          ? _c("td", [_vm._v(_vm._s(_vm.getCategory(_vm.event.category_id)))])
          : _c("td", [_vm._v("-")]),
        _vm._v(" "),
        _c("td", [_vm._v(_vm._s(_vm.event.code))]),
        _vm._v(" "),
        _c(
          "td",
          [
            _c(
              "nuxt-link",
              {
                staticClass: "schemeLink",
                attrs: { to: _vm.getUrl(_vm.event) },
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.i18nTitle(_vm.event)) },
                }),
                _vm._v(" "),
                _vm.event.statusTxt &&
                _vm.i18nContent(JSON.parse(_vm.event.statusTxt)) !== ""
                  ? _c("span", {
                      staticStyle: {
                        "font-size": "80%",
                        "white-space": "nowrap",
                      },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.i18nContent(JSON.parse(_vm.event.statusTxt))
                        ),
                      },
                    })
                  : _vm.getStatus === 1
                  ? _c("span", { staticClass: "status_completed" }, [
                      _vm._v(
                        "\n        (" +
                          _vm._s(_vm.$t("event.statusList.completed")) +
                          ")\n      "
                      ),
                    ])
                  : _vm.getStatus === 2
                  ? _c("span", { staticClass: "status_cancelled" }, [
                      _vm._v(
                        "\n        (" +
                          _vm._s(_vm.$t("event.statusList.cancelled")) +
                          ")\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm.event.isFamily
              ? _c("img", {
                  staticClass: "icon-family",
                  attrs: {
                    src: "https://www.abo.gov.hk/archive/img/SCDS2021/parent.jpg",
                    alt: "icon-family",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("td", [
          !_vm.getSchemesSlug(_vm.event.scheme).includes("spaps")
            ? _c("div", [_vm._v("\n      " + _vm._s(_vm.getDate()) + "\n    ")])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("td", [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.bookmark(_vm.event.id)
                },
              },
            },
            [
              _c("img", {
                staticClass: "icon_bookmark",
                attrs: {
                  src: _vm.isBookmarked
                    ? "/img/icon_bookmark_green.svg"
                    : "/img/icon_bookmark_grey.svg",
                  alt: "unbookmark",
                },
              }),
            ]
          ),
        ]),
      ])
    : _c(
        "b-card",
        { staticClass: "card-size", attrs: { "no-body": "" } },
        [
          _c(
            "nuxt-link",
            { staticClass: "schemeLink", attrs: { to: _vm.getUrl(_vm.event) } },
            [
              _c("b-card-img-lazy", {
                attrs: { src: _vm.getImg(), alt: "card image" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "card-tags" }, [
                _vm.event.category_id
                  ? _c(
                      "div",
                      {
                        staticClass: "card-tag card-category",
                        style: {
                          background: _vm.getColor(
                            _vm.getSchemesSlug(_vm.event.scheme)
                          ),
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.getCategory(_vm.event.category_id)) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.isFamily
                  ? _c("div", { staticClass: "card-tag card-family" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.getScheme(_vm.event.scheme) &&
                            _vm.getScheme(_vm.event.scheme).parentIcon
                              ? JSON.parse(
                                  _vm.getScheme(_vm.event.scheme).parentIcon
                                ).img
                              : "",
                          alt: "icon-family",
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getSchemesSlug(_vm.event.scheme).includes("scds")
                  ? _c("div", { staticClass: "card-tag card-code" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.event.code) + "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-body-header" }, [
              _c(
                "div",
                { staticClass: "card-body-target mb-1" },
                _vm._l(_vm.getEventTarget(_vm.event), function (target, index) {
                  return _c("div", { key: index, staticClass: "card-target" }, [
                    _vm._v("\n          " + _vm._s(target) + "\n        "),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm.getScheme(_vm.event.scheme)
                ? _c(
                    "h6",
                    {
                      class: _vm
                        .getSchemesSlug(_vm.event.scheme)
                        .includes("aoa")
                        ? "card-scheme-title2 mb-2"
                        : "card-scheme-title mb-2",
                      style: {
                        color: _vm.getColor(
                          _vm.getSchemesSlug(_vm.event.scheme)
                        ),
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.i18nTitle(_vm.getScheme(_vm.event.scheme))
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.bookmark(_vm.event.id)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "icon_bookmark",
                    attrs: {
                      src: _vm.isBookmarked
                        ? "/img/icon_bookmark_green.svg"
                        : "/img/icon_bookmark_grey.svg",
                      alt: "icon-bookmark",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mb-5",
                staticStyle: { "margin-bottom": "2rem !important" },
              },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "schemeLink",
                    attrs: { to: _vm.getUrl(_vm.event) },
                  },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.i18nTitle(_vm.event)) },
                    }),
                    _vm._v(" "),
                    _vm.event.statusTxt &&
                    _vm.i18nContent(JSON.parse(_vm.event.statusTxt)) !== ""
                      ? _c("span", {
                          staticStyle: {
                            "font-size": "80%",
                            "white-space": "nowrap",
                          },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.i18nContent(JSON.parse(_vm.event.statusTxt))
                            ),
                          },
                        })
                      : _vm.getStatus === 1
                      ? _c("span", { staticClass: "status_completed" }, [
                          _vm._v(
                            "\n          (" +
                              _vm._s(_vm.$t("event.statusList.completed")) +
                              ")\n        "
                          ),
                        ])
                      : _vm.getStatus === 2
                      ? _c("span", { staticClass: "status_cancelled" }, [
                          _vm._v(
                            "\n          (" +
                              _vm._s(_vm.$t("event.statusList.cancelled")) +
                              ")\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.getSchemesSlug(_vm.event.scheme).includes("spaps")
              ? _c("h6", { staticClass: "card-date" }, [
                  _vm._v("\n      " + _vm._s(_vm.getDate()) + "\n    "),
                ])
              : _vm._e(),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }