<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="10">
      <div class="form">
        <b-form method="post" @submit.prevent="submit">
          <h1>{{ $t('event.otherInfo') }}</h1>
          <div class="shadow">
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="title"
                  class="mt-3"
                  description="「已取消」活動不會開放報名"
                  :label="$t('event.status')"
                >
                  <!-- <p class="label">{{ $t('event.status') }}</p> -->
                  <b-form-select
                    v-model="status"
                    type="number"
                    :options="statusList"
                    required
                    trim
                    :placeholder="$t('event.status')"
                  />
                </b-form-group>
                <b-form-group
                  id="title"
                  class="mt-3"
                  label-for="title"
                  description="填寫後將不會顯示自動產生的「已完成」/「已取消」"
                >
                  <b-tabs>
                    <b-tab title="自訂活動狀態(英)">
                      <medium-editor
                        :text="statusTxt.content_en"
                        :options="options_statusTxt_en"
                        class="medium-editor"
                        @edit="
                          (operation) => {
                            statusTxt.content_en =
                              operation.api.origElements.innerHTML
                          }
                        "
                      >
                      </medium-editor>
                    </b-tab>
                    <b-tab title="自訂活動狀態(繁)">
                      <medium-editor
                        :text="statusTxt.content_tc"
                        :options="options_statusTxt_tc"
                        class="medium-editor"
                        @edit="
                          (operation) => {
                            statusTxt.content_tc =
                              operation.api.origElements.innerHTML
                          }
                        "
                      >
                      </medium-editor>
                    </b-tab>
                    <b-tab title="自訂活動狀態(簡)">
                      <medium-editor
                        :text="statusTxt.content_sc"
                        :options="options_statusTxt_sc"
                        class="medium-editor"
                        @edit="
                          (operation) => {
                            statusTxt.content_sc =
                              operation.api.origElements.innerHTML
                          }
                        "
                      >
                      </medium-editor>
                    </b-tab>
                  </b-tabs>
                </b-form-group>
              </b-col>
              <b-col>
                <p class="label" style="color: red">
                  {{ $t('event.adminRemark') }}
                </p>
                <b-form-textarea
                  id="adminRemark"
                  v-model="adminRemark"
                  rows="6"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group id="scheme" label-for="scheme">
                  <p class="label">{{ $t('event.scheme') }}</p>
                  <b-form-select
                    v-model="scheme"
                    :options="schemeList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="getParentSchemeId(scheme) === 1 || haveCat(scheme)">
                <b-form-group id="category_id" label-for="category_id">
                  <p class="label">{{ $t('event.category_id') }}</p>
                  <b-form-select
                    v-model="category_id"
                    :options="getCategoryList(scheme)"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group id="tag" label-for="tag">
                  <p class="label">{{ $t('event.tag') }}</p>
                  <b-form-select
                    v-model="tag"
                    :options="tags"
                    :placeholder="$t('event.tag')"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="contact" label-for="contact">
                  <p class="label">
                    {{ $t('event.contact') }}
                  </p>
                  <div v-for="(c, i) in contact_id" :key="i">
                    <div style="display: flex">
                      <div style="width: 90%">
                        <b-form-select
                          v-model="contact_id[i]"
                          :options="contactList"
                          :placeholder="$t('event.contact')"
                        ></b-form-select>
                      </div>
                      <div>
                        <b-button
                          class="mx-1"
                          variant="outline-danger"
                          @click="
                            () => {
                              contact_id.splice(i, 1)
                            }
                          "
                        >
                          <b-icon icon="trash-fill"></b-icon>
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <b-button
                    variant="outline-success"
                    @click="
                      () => {
                        contact_id.push(-1)
                      }
                    "
                  >
                    新增聯絡人
                  </b-button>

                  <!-- <v-select
                    :options="contactList"
                    :value="getContact(contact_id)"
                    :placeholder="$t('event.contact')"
                    @input="(c) => updateContact(c)"
                  ></v-select> -->
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group id="parent" label-for="parent">
                  <p class="label">{{ $t('event.parent') }}</p>
                  <b-form-select
                    v-model="parent"
                    :options="eventList"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <p class="label">
                  {{ $t('event.thumbnail') }}<br />
                  {{ $t('event.suggested_size') }}: (720px(W) x 540px(H))
                </p>
                <b-form-input v-model="thumbnail" type="text"></b-form-input>
                <b-form-file
                  id="thumbnail"
                  accept="image/jpeg, image/png, image/gif"
                  @change="(e) => uploadImageResize(e, false, -1, 720)"
                />
                <img
                  :src="thumbnail"
                  style="max-height: 200px"
                  alt="event thumbnail"
                />
              </b-col>
              <b-col>
                <p class="label">
                  {{ $t('event.cal_img') }}<br />
                  {{ $t('event.suggested_size') }}: (1000px(W) x 520px(H))
                </p>
                <b-form-input
                  v-model="imgForCalendar"
                  type="text"
                ></b-form-input>
                <b-form-file
                  id="imgForCalendar"
                  accept="image/jpeg, image/png, image/gif"
                  @change="uploadImage"
                />
                <img
                  :src="imgForCalendar"
                  style="max-height: 200px"
                  alt="event calendar"
                />
              </b-col>
            </b-row>

            <b-row style="margin-top: 16px">
              <b-col>
                <!-- startdate, enddate -->
                <b-form-group id="startdate" label-for="startdate">
                  <p class="label">
                    {{ $t('event.startdate') }}（如沒有活動場次）
                  </p>
                  <b-form-input v-model="startdate" type="date" trim />
                </b-form-group>
                <b-form-group id="enddate" label-for="enddate">
                  <p class="label">
                    {{ $t('event.enddate') }}（如沒有活動場次）
                  </p>
                  <b-form-input v-model="enddate" type="date" trim />
                </b-form-group>
              </b-col>

              <b-col>
                <p class="label">
                  {{ $t('event.target.title') }}
                </p>
                <b-form-checkbox-group
                  v-model="target"
                  :options="target_skl_options"
                >
                </b-form-checkbox-group>
              </b-col>
            </b-row>

            <!-- <b-row>
              <b-col>
                <b-form-group id="regStartDate" label-for="regStartDate">
                  <p class="label">{{ $t('event.regStartDate') }}</p>
                  <b-form-input
                    v-model="regStartDate"
                    type="date"
                    required
                    trim
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group id="regDeadline" label-for="regDeadline">
                  <p class="label">{{ $t('event.regDeadline') }}</p>
                  <b-form-input
                    v-model="regDeadline"
                    type="date"
                    required
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row> -->
          </div>

          <h1>{{ $t('event.basicInfo') }}</h1>
          <div class="row shadow">
            <div class="col-sm-7">
              <b-row>
                <b-col md="2">
                  <p class="label" style="inline-block">
                    {{ $t('event.code') }}
                  </p>
                </b-col>
                <b-col md="5">
                  <b-form-group id="code" label-for="code">
                    <b-form-input
                      v-model="code"
                      type="text"
                      trim
                    ></b-form-input>
                    <!-- :readonly="action === 'edit' ? true : false" -->
                  </b-form-group>
                </b-col>

                <!-- <b-col style="display: flex; justify-content: space-between"> -->
                <b-col style="display: flex">
                  <b-form-group id="isFamily" class="mr-3">
                    <b-form-checkbox
                      v-model="isFamily"
                      value="1"
                      unchecked-value="0"
                    >
                      <img
                        class="icon"
                        src="https://www.abo.gov.hk/archive/img/SCDS2021/parent.jpg"
                        :alt="$t('event.isFamily')"
                      />
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group id="isStar">
                    <b-form-checkbox
                      v-model="isStar"
                      value="1"
                      unchecked-value="0"
                    >
                      <img
                        class="icon"
                        src="https://www.abo.gov.hk/archive/img/SCDS2021/star.jpg"
                        :alt="$t('event.isStar')"
                      />
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group id="title" label-for="title">
                <b-tabs>
                  <b-tab :title="$t('event.eventName.en')">
                    <medium-editor
                      :text="title_en"
                      :options="options_title_en"
                      class="medium-editor"
                      @edit="
                        (operation) => {
                          title_en = operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                    <!-- <b-form-input
                      v-model="title_en"
                      type="text"
                      trim
                    ></b-form-input> -->
                  </b-tab>
                  <b-tab :title="$t('event.eventName.tc')">
                    <medium-editor
                      :text="title_tc"
                      :options="options_title_tc"
                      class="medium-editor"
                      @edit="
                        (operation) => {
                          title_tc = operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                    <!-- <b-form-input
                      v-model="title_tc"
                      type="text"
                      trim
                    ></b-form-input> -->
                  </b-tab>
                  <b-tab :title="$t('event.eventName.sc')">
                    <medium-editor
                      :text="title_sc"
                      :options="options_title_sc"
                      class="medium-editor"
                      @edit="
                        (operation) => {
                          title_sc = operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                    <!-- <b-form-input
                      v-model="title_sc"
                      type="text"
                      trim
                    ></b-form-input> -->
                  </b-tab>
                </b-tabs>
              </b-form-group>

              <b-form-group id="title" label-for="title">
                <b-tabs>
                  <b-tab :title="$t('event.organiser.en')">
                    <b-form-input
                      v-model="organiser.title_en"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-tab>
                  <b-tab :title="$t('event.organiser.tc')">
                    <b-form-input
                      v-model="organiser.title_tc"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-tab>
                  <b-tab :title="$t('event.organiser.sc')">
                    <b-form-input
                      v-model="organiser.title_sc"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-tab>
                </b-tabs>
              </b-form-group>

              <b-form-group label-for="banner" :label="$t('event.banner')">
                {{ $t('scheme.banner_size') }}: 1000px(W) x 200px(H)
                <b-form-input v-model="banner" type="text"></b-form-input>
                <b-form-file
                  id="banner"
                  accept="image/*"
                  @change="uploadImage"
                />
                <img :src="banner" style="max-height: 200px" alt="banner" />
              </b-form-group>

              <b-form-group id="topRemark" label-for="title">
                <b-tabs>
                  <b-tab :title="$t('event.topRemark.en')">
                    <medium-editor
                      class="medium-editor-topRemark"
                      :text="topRemark.content_en"
                      :options="options_disable_return"
                      @edit="
                        (operation) => {
                          topRemark.content_en =
                            operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                  </b-tab>
                  <b-tab :title="$t('event.topRemark.tc')">
                    <medium-editor
                      class="medium-editor-topRemark"
                      :text="topRemark.content_tc"
                      :options="options_disable_return"
                      @edit="
                        (operation) => {
                          topRemark.content_tc =
                            operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                  </b-tab>
                  <b-tab :title="$t('event.topRemark.sc')">
                    <medium-editor
                      class="medium-editor-topRemark"
                      :text="topRemark.content_sc"
                      :options="options_disable_return"
                      @edit="
                        (operation) => {
                          topRemark.content_sc =
                            operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                  </b-tab>
                </b-tabs>
              </b-form-group>

              <b-form-group id="content" label-for="content">
                <b-tabs>
                  <b-tab :title="$t('event.content.en')">
                    <tinymceEditor
                      id="content_en"
                      :value="content_en"
                      @writeContent="getContent"
                    />
                  </b-tab>
                  <b-tab :title="$t('event.content.tc')">
                    <tinymceEditor
                      id="content_tc"
                      :value="content_tc"
                      @writeContent="getContent"
                    />
                  </b-tab>
                  <b-tab :title="$t('event.content.sc')">
                    <tinymceEditor
                      id="content_sc"
                      :value="content_sc"
                      @writeContent="getContent"
                    />
                  </b-tab>
                </b-tabs>
              </b-form-group>

              <b-form-group id="content_remark" label-for="title">
                <b-tabs>
                  <b-tab :title="$t('event.content_remark.en')">
                    <medium-editor
                      class="medium-editor"
                      :text="content_remark.content_en"
                      :options="options"
                      @edit="
                        (operation) => {
                          content_remark.content_en =
                            operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                  </b-tab>
                  <b-tab :title="$t('event.content_remark.tc')">
                    <medium-editor
                      class="medium-editor"
                      :text="content_remark.content_tc"
                      :options="options"
                      @edit="
                        (operation) => {
                          content_remark.content_tc =
                            operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                  </b-tab>
                  <b-tab :title="$t('event.content_remark.sc')">
                    <medium-editor
                      class="medium-editor"
                      :text="content_remark.content_sc"
                      :options="options"
                      @edit="
                        (operation) => {
                          content_remark.content_sc =
                            operation.api.origElements.innerHTML
                        }
                      "
                    >
                    </medium-editor>
                  </b-tab>
                </b-tabs>
              </b-form-group>
            </div>

            <div class="col-sm-5">
              <b-form-group
                v-for="(i, index) in noOfImg"
                :key="index"
                label-for="img"
                :label="$t('event.img').concat(index + 1)"
              >
                <b-form-input
                  v-model="img[index].img"
                  type="text"
                ></b-form-input>
                <b-form-file
                  :id="`img${index}`"
                  accept="image/jpeg, image/png, image/gif"
                  @change="(e) => uploadImage(e, true, index)"
                />
                <b-form-group id="imgCaption" label-for="title">
                  <b-tabs content-class="mt-3">
                    <b-tab :title="$t('event.imgCaption.en')">
                      <medium-editor
                        class="medium-editor"
                        :text="img[index].caption.content_en"
                        :options="options"
                        @edit="
                          (operation) => {
                            img[index].caption.content_en =
                              operation.api.origElements.innerHTML
                          }
                        "
                      >
                      </medium-editor>
                    </b-tab>
                    <b-tab :title="$t('event.imgCaption.tc')">
                      <medium-editor
                        class="medium-editor"
                        :text="img[index].caption.content_tc"
                        :options="options"
                        @edit="
                          (operation) => {
                            img[index].caption.content_tc =
                              operation.api.origElements.innerHTML
                          }
                        "
                      >
                      </medium-editor>
                    </b-tab>
                    <b-tab :title="$t('event.imgCaption.sc')">
                      <medium-editor
                        class="medium-editor"
                        :text="img[index].caption.content_sc"
                        :options="options"
                        @edit="
                          (operation) => {
                            img[index].caption.content_sc =
                              operation.api.origElements.innerHTML
                          }
                        "
                      >
                      </medium-editor>
                    </b-tab>
                  </b-tabs>
                </b-form-group>
                <img
                  :src="img[index].img"
                  style="max-height: 200px"
                  alt="image"
                />
                <b-button
                  variant="outline-danger"
                  class="btn_img_del"
                  @click="delImg(index)"
                >
                  {{ $t('event.del_img') }}{{ index + 1 }}
                </b-button>
              </b-form-group>
              <b-button variant="outline-success" @click="addImg">{{
                $t('event.add_img')
              }}</b-button>
            </div>
          </div>

          <!-------------->
          <div v-if="getParentSchemeId(scheme) !== 1">
            <h1>{{ $t('event.tabContent') }}</h1>
            <div class="shadow mt-3 mb-10" style="padding: 30px">
              <b-row>
                <b-col>
                  <div class="d-flex" style="align-item: middle">
                    <b-button
                      variant="outline-success"
                      class="mr-2 mb-3"
                      @click="addContentTab(0, addTabIndex)"
                    >
                      添加分段內容
                    </b-button>
                    <b-form-input
                      v-model="addTabIndex"
                      type="number"
                      :min="1"
                      :max="tabs.length + 1"
                      style="width: 150px"
                      placeholder="位置(選填)"
                    />
                  </div>
                </b-col>
                <b-col>
                  <div style="float: right">
                    <b-button
                      variant="outline-success"
                      @click="addContentTab(1)"
                    >
                      添加 SPAPS 範本
                    </b-button>

                    <b-button
                      variant="outline-success"
                      @click="addContentTab(2)"
                    >
                      添加 AES 範本
                    </b-button>

                    <b-button
                      variant="outline-success"
                      @click="addContentTab(3)"
                    >
                      添加 AOA 範本
                    </b-button>
                  </div>
                </b-col>
              </b-row>

              <b-tabs content-class="mt-3">
                <b-tab v-for="(t, counter) in tabs" :key="counter" class="tab">
                  <template #title>
                    {{ counter + 1 }}) {{ i18nTitle(t) }}
                    <b-button
                      size="sm"
                      variant="danger"
                      style="border-radius: 500%"
                      @click.prevent="deleteContentTab(counter)"
                    >
                      ×
                    </b-button>
                  </template>
                  <b-form-group id="tabs" label-for="tabs">
                    <b-tabs content-class="tab-column">
                      <b-tab :title="$t('g.en')">
                        <p class="label">{{ $t('tab.title.en') }}</p>
                        <b-form-input
                          v-model="t.title_en"
                          :placeholder="$t('placeholder.title.en')"
                          type="text"
                          trim
                        ></b-form-input>
                        <AoaContent
                          v-if="t.aoaContent"
                          v-model="t.aoaContent"
                          :pass-data="t.aoaContent"
                          :type="t.aoaType"
                          :lang="0"
                          :edit="true"
                        />
                        <SpapsTableDetail
                          v-if="t.table_detail"
                          v-model="t.table_detail"
                          :impl-table-data="t.table_detail"
                          :lang="0"
                          :edit="true"
                        />
                        <p
                          v-if="t.content_en || t.content_en === ''"
                          class="label"
                        >
                          {{ $t('tab.content.en') }}
                        </p>
                        <tinymceEditor
                          v-if="t.content_en || t.content_en === ''"
                          :id="'tab_editor_en_' + counter"
                          :value="t.content_en"
                          @writeContent="getTabContent"
                        />
                        <SpapsTableImpl
                          v-if="t.table_impl"
                          v-model="t.table_impl"
                          :impl-table-data="t.table_impl"
                          :lang="0"
                          :edit="true"
                        />
                      </b-tab>
                      <b-tab :title="$t('g.tc')">
                        <p class="label">{{ $t('tab.title.tc') }}</p>
                        <b-form-input
                          v-model="t.title_tc"
                          :placeholder="$t('placeholder.title.tc')"
                          type="text"
                          trim
                        ></b-form-input>
                        <AoaContent
                          v-if="t.aoaContent"
                          v-model="t.aoaContent"
                          :pass-data="t.aoaContent"
                          :type="t.aoaType"
                          :lang="1"
                          :edit="true"
                        />
                        <SpapsTableDetail
                          v-if="t.table_detail"
                          v-model="t.table_detail"
                          :impl-table-data="t.table_detail"
                          :lang="1"
                          :edit="true"
                        />
                        <p
                          v-if="t.content_tc || t.content_tc === ''"
                          class="label"
                        >
                          {{ $t('tab.content.tc') }}
                        </p>
                        <tinymceEditor
                          v-if="t.content_tc || t.content_tc === ''"
                          :id="'tab_editor_tc_' + counter"
                          :value="t.content_tc"
                          @writeContent="getTabContent"
                        />
                        <SpapsTableImpl
                          v-if="t.table_impl"
                          v-model="t.table_impl"
                          :impl-table-data="t.table_impl"
                          :lang="1"
                          :edit="true"
                        />
                        <!-- <table v-if="t.table" class="table_implementation">
                          <tbody>
                            <tr v-for="(row, index) in t.table" :key="index">
                              <td v-for="(item, i) in row" :key="i">
                                <b-form-input
                                  v-model="item.content_tc"
                                  :placeholder="$t('placeholder.title.tc')"
                                  type="text"
                                  trim
                                ></b-form-input>
                              </td>
                            </tr>
                            <tr></tr>
                          </tbody>
                        </table> -->
                      </b-tab>
                      <b-tab :title="$t('g.sc')">
                        <p class="label">{{ $t('tab.title.sc') }}</p>
                        <b-form-input
                          v-model="t.title_sc"
                          :placeholder="$t('placeholder.title.sc')"
                          type="text"
                          trim
                        ></b-form-input>
                        <AoaContent
                          v-if="t.aoaContent"
                          v-model="t.aoaContent"
                          :pass-data="t.aoaContent"
                          :type="t.aoaType"
                          :lang="2"
                          :edit="true"
                        />
                        <SpapsTableDetail
                          v-if="t.table_detail"
                          v-model="t.table_detail"
                          :impl-table-data="t.table_detail"
                          :lang="2"
                          :edit="true"
                        />
                        <p
                          v-if="t.content_sc || t.content_sc === ''"
                          class="label"
                        >
                          {{ $t('tab.content.sc') }}
                        </p>
                        <tinymceEditor
                          v-if="t.content_sc || t.content_sc === ''"
                          :id="'tab_editor_sc_' + counter"
                          :value="t.content_sc"
                          @writeContent="getTabContent"
                        />
                        <SpapsTableImpl
                          v-if="t.table_impl"
                          v-model="t.table_impl"
                          :impl-table-data="t.table_impl"
                          :lang="2"
                          :edit="true"
                        />
                      </b-tab>
                    </b-tabs>
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <!-------------->
          <h1>{{ $t('event.sessInfo') }}</h1>
          <div class="shadow">
            <event-table
              v-model="additional_field"
              :event="getEvent"
              :remark="remark"
              :additional="additional_field"
              is-edit
              @change="updateRemarks"
            />
            <b-button
              v-if="!eventSlot.length"
              variant="success"
              @click="addTab"
            >
              添加活動場次
            </b-button>
            <b-form-group id="table_remark" label-for="title">
              <b-tabs>
                <b-tab :title="$t('event.table_remark.en')">
                  <tinymceEditor
                    id="table_remark_content_en"
                    :value="table_remark.content_en"
                    :height="200"
                    @writeContent="
                      (evt, id, content) => (table_remark.content_en = content)
                    "
                  />
                </b-tab>
                <b-tab :title="$t('event.table_remark.tc')">
                  <tinymceEditor
                    id="table_remark_content_tc"
                    :value="table_remark.content_tc"
                    :height="200"
                    @writeContent="
                      (evt, id, content) => (table_remark.content_tc = content)
                    "
                  />
                </b-tab>
                <b-tab :title="$t('event.table_remark.sc')">
                  <tinymceEditor
                    id="table_remark_content_sc"
                    :value="table_remark.content_sc"
                    :height="200"
                    @writeContent="
                      (evt, id, content) => (table_remark.content_sc = content)
                    "
                  />
                </b-tab>
              </b-tabs>
            </b-form-group>

            <div style="height: 30px" />

            <b-modal
              id="modal-editslots"
              title="選擇場次"
              size="xl"
              hide-footer
            >
              <!-- <p v-for="(eslot, counter) in eventSlot" :key="counter">
                {{ `${eslot.date} ${eslot.startTime} ${eslot.venue}` }}
              </p> -->
              <b-button size="sm" @click="selectAllRows">Select All</b-button>
              <b-button size="sm" @click="clearSelected">
                Clear selected
              </b-button>

              <span>
                地點：
                <b-form-select
                  v-model="selectedVenue"
                  size="sm"
                  style="width: 300px"
                  :options="venueList"
                ></b-form-select>
                <b-button
                  variant="outline-success"
                  size="sm"
                  @click="editSlotsVenue"
                >
                  Apply Changes
                </b-button>
              </span>

              <b-table
                id="es-table"
                ref="selectableTable"
                class="es-table mt-3"
                striped
                hover
                small
                select-mode="multi"
                selectable
                selected-variant="info"
                :items="eventSlot"
                :fields="eventSlot_fields"
                @row-selected="onRowSelected"
              >
                <template #cell(selected)="row">
                  <template v-if="row.rowSelected">
                    <!-- <div class="checked"></div> -->
                    <b-iconstack>
                      <b-icon
                        stacked
                        variant="primary"
                        icon="square-fill"
                      ></b-icon>
                      <b-icon stacked variant="white" icon="check"></b-icon>
                    </b-iconstack>
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <b-iconstack>
                      <b-icon
                        stacked
                        icon="square-fill"
                        variant="white"
                      ></b-icon>
                    </b-iconstack>
                    <span class="sr-only">Not selected</span>
                  </template>
                </template>
                <template #cell(slot)="row">
                  {{
                    `${
                      row.item.venue && getVenue(row.item.venue)
                        ? getVenue(row.item.venue).text
                        : ''
                    }
                    ${row.item.venue}
                    ${row.item.date}
                    ${row.item.startTime}`
                  }}
                </template>
              </b-table>
            </b-modal>

            <b-tabs v-model="activeTab">
              <b-tab
                v-for="(eslot, counter) in eventSlot"
                :key="eslot.id"
                class="tab"
              >
                <template #title>
                  {{ eslot.venue + ' ' + eslot.date }}
                  <b-button
                    size="sm"
                    variant="warning"
                    style="border-radius: 500%"
                    @click="copyTab(counter)"
                  >
                    <!-- copy icon -->
                    <b-icon icon="clipboard" aria-hidden="true" />
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    style="border-radius: 500%"
                    @click.prevent="deleteTab(counter)"
                  >
                    ×
                  </b-button>
                </template>

                <b-form-group id="slots" label-for="slots">
                  <b-button variant="success" @click="addTab">
                    添加活動場次
                  </b-button>
                  <b-button v-b-modal.modal-editslots variant="outline-primary">
                    修改 全部/部份場次
                  </b-button>
                  <!-- <b-button variant="outline-primary" @click="orderTab">
                    活動場次排序
                  </b-button> -->
                  <b-row>
                    <b-col>
                      <p class="label">{{ $t('event.date') }}</p>
                      <b-form-input
                        v-model="eslot.date"
                        type="date"
                        required
                        trim
                        :formatter="format"
                        @change="reorderTabs"
                      />
                      <div class="ie-hint">yyyy-mm-dd</div>
                    </b-col>
                    <b-col>
                      <p class="label">
                        {{ $t('event.venue') }}
                      </p>
                      <!-- <v-select
                        :options="venueList"
                        :value="getVenue(eslot.venue)"
                        :placeholder="$t('event.venue')"
                        @input="(venue) => updateVenue(eslot, venue)"
                      ></v-select> -->
                      <b-form-select
                        v-model="eslot.venue"
                        :options="venueList"
                      ></b-form-select>
                    </b-col>
                    <b-col>
                      <p class="label">{{ $t('event.start_time') }}</p>
                      <b-form-input
                        v-model="eslot.startTime"
                        type="time"
                        required
                        trim
                        :placeholder="$t('event.start_time')"
                      />
                      <div class="ie-hint">hh:mm:ss</div>
                    </b-col>
                    <b-col>
                      <p class="label">{{ $t('event.end_time') }}</p>
                      <b-form-input
                        v-model="eslot.endTime"
                        type="time"
                        required
                        trim
                        :placeholder="$t('event.end_time')"
                      />
                      <div class="ie-hint">hh:mm:ss</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" md="6">
                      <b-row>
                        <b-col>
                          <p class="label">
                            {{ $t('event.ticket') }}（{{
                              $t('event.student')
                            }}）
                          </p>
                          <b-form-input
                            v-model="eslot.fee.student"
                            type="number"
                            min="1"
                            lazy-formatter
                            :formatter="priceFormatter"
                            :placeholder="$t('event.ticket')"
                            @input="
                              () => {
                                if (eslot.fee.student === '')
                                  eslot.fee.student = null
                              }
                            "
                          />
                        </b-col>
                        <b-col>
                          <p class="label">
                            {{ $t('event.ticket') }}（{{
                              $t('event.teacher')
                            }}）
                          </p>
                          <b-form-input
                            v-model="eslot.fee.teacher"
                            type="number"
                            min="1"
                            lazy-formatter
                            :formatter="priceFormatter"
                            :placeholder="$t('event.ticket')"
                            @input="
                              () => {
                                if (eslot.fee.teacher === '')
                                  eslot.fee.teacher = null
                              }
                            "
                          />
                        </b-col>
                        <b-col>
                          <p class="label">
                            {{ $t('event.ticket') }}（{{
                              $t('event.parents')
                            }}）
                          </p>
                          <b-form-input
                            v-model="eslot.fee.parent"
                            type="number"
                            min="1"
                            lazy-formatter
                            :formatter="priceFormatter"
                            :placeholder="$t('event.ticket')"
                            @input="
                              () => {
                                if (eslot.fee.parent === '')
                                  eslot.fee.parent = null
                              }
                            "
                          />
                        </b-col>
                        <b-col>
                          <p class="label">
                            {{ $t('event.ticket') }}（{{ $t('event.cssa') }}）
                          </p>
                          <b-form-input
                            v-model="eslot.fee.cssa"
                            type="number"
                            min="1"
                            lazy-formatter
                            :formatter="priceFormatter"
                            :placeholder="$t('event.ticket')"
                            @input="
                              () => {
                                if (eslot.fee.cssa === '') eslot.fee.cssa = null
                              }
                            "
                          />
                        </b-col>
                      </b-row>
                      <div style="width: 450px">
                        <p class="label">{{ $t('event.target.title') }}</p>
                        <b-form-checkbox-group
                          v-model="eslot.target"
                          :options="target_options"
                        >
                        </b-form-checkbox-group>
                      </div>
                    </b-col>

                    <b-col>
                      <b-row>
                        <b-col>
                          <p class="label">{{ $t('event.quota') }}</p>
                          <b-form-input
                            v-model="eslot.quota"
                            type="number"
                            required
                            trim
                            :placeholder="$t('event.quota')"
                          />
                        </b-col>

                        <b-col>
                          <p class="label">
                            {{ $t('event.remain_quota') }}
                          </p>
                          <b-form-input
                            v-model="eslot.remain_quota"
                            type="number"
                            trim
                            :placeholder="$t('event.remain_quota')"
                          />
                        </b-col>
                      </b-row>
                      <p class="label">{{ $t('event.status') }}</p>
                      <b-form-select
                        v-model="eslot.status"
                        type="number"
                        :options="statusList"
                        trim
                        :placeholder="$t('event.status')"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-tab>
            </b-tabs>
          </div>

          <!-------------->

          <h1>{{ $t('event.applyInfo') }}</h1>
          <div class="shadow">
            <b-row v-if="getParentSchemeId(scheme) === 5">
              <!-- <b-col md="3">
                <b-form-group label="">
                  <b-form-radio v-model="haveAdditionalEventInt" value="1">
                    有演前/演後活動
                  </b-form-radio>
                  <b-form-radio v-model="haveAdditionalEventInt" value="0">
                    沒有演前/演後活動
                  </b-form-radio>
                </b-form-group>
              </b-col> -->
              <b-col md="6" class="mb-3">
                <p class="label">節目形式</p>
                <b-form-input v-model="haveAdditionalEvent" type="text" trim />
                <small>e.g. 演出暨演後座談會</small>
              </b-col>
            </b-row>

            <!-- AES only: regStartDate, regDeadline -->
            <!-- <b-row v-if="getParentSchemeId(scheme) === 5">
              <b-col md="6">
                <b-form-group id="regStartDate" label-for="regStartDate">
                  <p class="label">{{ $t('event.regStartDate') }}</p>
                  <b-form-input v-model="regStartDate" type="date" trim />
                </b-form-group>
                <b-form-group id="regDeadline" label-for="regDeadline">
                  <p class="label">{{ $t('event.regDeadline') }}</p>
                  <b-form-input v-model="regDeadline" type="date" trim />
                </b-form-group>
              </b-col>
            </b-row> -->

            <b-form-group id="applyRemark" label-for="title">
              <b-tabs>
                <b-tab :title="$t('event.applyRemark.en')">
                  <medium-editor
                    class="medium-editor"
                    :text="applyRemark.content_en"
                    :options="options"
                    @edit="
                      (operation) => {
                        applyRemark.content_en =
                          operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor>
                </b-tab>
                <b-tab :title="$t('event.applyRemark.tc')">
                  <medium-editor
                    class="medium-editor"
                    :text="applyRemark.content_tc"
                    :options="options"
                    @edit="
                      (operation) => {
                        applyRemark.content_tc =
                          operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor>
                </b-tab>
                <b-tab :title="$t('event.applyRemark.sc')">
                  <medium-editor
                    class="medium-editor"
                    :text="applyRemark.content_sc"
                    :options="options"
                    @edit="
                      (operation) => {
                        applyRemark.content_sc =
                          operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor>
                </b-tab>
              </b-tabs>
            </b-form-group>
          </div>

          <h1>{{ $t('event.successRemarkInfo') }}</h1>
          <div class="shadow">
            <b-form-group id="successRemark" label-for="title">
              <b-tabs>
                <b-tab :title="$t('event.successRemark.en')">
                  <medium-editor
                    class="medium-editor"
                    :text="successRemark.content_en"
                    :options="options"
                    @edit="
                      (operation) => {
                        successRemark.content_en =
                          operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor>
                </b-tab>
                <b-tab :title="$t('event.successRemark.tc')">
                  <medium-editor
                    class="medium-editor"
                    :text="successRemark.content_tc"
                    :options="options"
                    @edit="
                      (operation) => {
                        successRemark.content_tc =
                          operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor>
                </b-tab>
                <b-tab :title="$t('event.successRemark.sc')">
                  <medium-editor
                    class="medium-editor"
                    :text="successRemark.content_sc"
                    :options="options"
                    @edit="
                      (operation) => {
                        successRemark.content_sc =
                          operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor>
                </b-tab>
              </b-tabs>
            </b-form-group>
          </div>

          <h1 v-if="getParentSchemeId(scheme) === 5">AES 專用</h1>
          <div v-if="getParentSchemeId(scheme) === 5" class="shadow">
            <b-row>
              <b-col md="6" class="mb-3">
                <p class="label">節目聯絡人</p>
                <b-form-input v-model="aesContact" type="text" trim />
              </b-col>
              <b-col md="6" class="mb-3">
                <p class="label">節目聯絡人電話</p>
                <b-form-input v-model="aesContactTel" type="text" trim />
              </b-col>
              <b-col md="6" class="mb-3">
                <p class="label">支票抬頭(中)</p>
                <b-form-input
                  v-model="aesContactChequePayableTc"
                  type="text"
                  trim
                />
              </b-col>
              <b-col md="6" class="mb-3">
                <p class="label">支票抬頭(En)</p>
                <b-form-input
                  v-model="aesContactChequePayableEn"
                  type="text"
                  trim
                />
              </b-col>
              <b-button variant="outline-success" @click="updateAes()">
                Update AES contact
              </b-button>
            </b-row>
          </div>

          <div v-if="getParentSchemeId(scheme) === 4">
            <h3>{{ getSchemeSlug(scheme).toUpperCase() }} 申請選項</h3>
            <b-row>
              <b-col md="2">
                <div style="line-height: 2.5">預覽申請選項：</div>
              </b-col>
              <b-col>
                <b-form-select
                  v-model="spapsSelected"
                  :options="eventlist_spaps"
                ></b-form-select>
              </b-col>
            </b-row>
            <div class="shadow">
              <!-- {{ spapsGroup }} -->
              <b-form-group
                v-for="(group, index) in spapsGroup"
                :key="index"
                label-for="spapsGroup"
                :label="'選擇'.concat(index + 1)"
              >
                <!-- <div style="display: flex">
                  <div style="width: 100px">計劃代號</div>
                  <div>
                    <b-form-input
                      v-model="group.code"
                      type="text"
                    ></b-form-input>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="width: 100px">名額</div>
                  <div>
                    <b-form-input
                      v-model="group.quota.primary"
                      placeholder="小學組"
                      type="number"
                    ></b-form-input>
                  </div>
                  <div>
                    <b-form-input
                      v-model="group.quota.secondary"
                      placeholder="中學組"
                      type="number"
                    ></b-form-input>
                  </div>
                  <div>
                    <b-form-input
                      v-model="group.quota.special"
                      placeholder="特殊學校組"
                      type="number"
                    ></b-form-input>
                  </div>
                </div> -->
                <b-row class="mb-2">
                  <b-col md="3">
                    <div>計劃代號</div>
                    <div>
                      <b-form-input
                        v-model="group.code"
                        placeholder="eg C / C(延續)"
                        type="text"
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col>
                    <b-form-group id="title" label-for="title">
                      <b-tabs>
                        <b-tab title="計劃名稱(英)">
                          <b-form-input
                            v-model="group.title_en"
                            type="text"
                            trim
                          ></b-form-input>
                        </b-tab>
                        <b-tab title="計劃名稱(繁)">
                          <b-form-input
                            v-model="group.title_tc"
                            type="text"
                            trim
                          ></b-form-input>
                        </b-tab>
                        <b-tab title="計劃名稱(簡)">
                          <b-form-input
                            v-model="group.title_sc"
                            type="text"
                            trim
                          ></b-form-input>
                        </b-tab>
                      </b-tabs>
                      <small> 如適用，e.g. 音樂劇計劃 / 音樂劇歌舞計劃 </small>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col md="3">
                    <div class="quota-txt">名額(小學)</div>
                    <b-form-input
                      v-model="group.grpPrimary.quota"
                      placeholder="小學組"
                      type="number"
                    ></b-form-input>
                    <div class="quota-txt">名額(中學)</div>
                    <b-form-input
                      v-model="group.grpSecondary.quota"
                      placeholder="中學組"
                      type="number"
                    ></b-form-input>
                    <div class="quota-txt">名額(特殊學校)</div>
                    <b-form-input
                      v-model="group.grpSpecial.quota"
                      placeholder="特殊學校組"
                      type="number"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-tabs>
                      <b-tab title="組別名稱(小學)(英)">
                        <b-form-input
                          v-model="group.grpPrimary.title_en"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                      <b-tab title="組別名稱(小學)(繁)">
                        <b-form-input
                          v-model="group.grpPrimary.title_tc"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                      <b-tab title="組別名稱(小學)(簡)">
                        <b-form-input
                          v-model="group.grpPrimary.title_sc"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                    </b-tabs>

                    <b-tabs>
                      <b-tab title="組別名稱(中學)(英)">
                        <b-form-input
                          v-model="group.grpSecondary.title_en"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                      <b-tab title="組別名稱(中學)(繁)">
                        <b-form-input
                          v-model="group.grpSecondary.title_tc"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                      <b-tab title="組別名稱(中學)(簡)">
                        <b-form-input
                          v-model="group.grpSecondary.title_sc"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                    </b-tabs>

                    <b-tabs>
                      <b-tab title="組別名稱(特殊學校)(英)">
                        <b-form-input
                          v-model="group.grpSpecial.title_en"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                      <b-tab title="組別名稱(特殊學校)(繁)">
                        <b-form-input
                          v-model="group.grpSpecial.title_tc"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                      <b-tab title="組別名稱(特殊學校)(簡)">
                        <b-form-input
                          v-model="group.grpSpecial.title_sc"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-tab>
                    </b-tabs>
                    <small> 如適用，e.g. 中學入門組 / 延續組</small>
                    <br />
                    <small>
                      若沒有填寫則會顯示「小學組」/「中學組」/「特殊學校組」
                    </small>
                  </b-col>
                  <!-- <b-col>
                    <div>組別名稱(小學)</div>
                    <b-form-input
                      v-model="group.groupname.primary"
                      placeholder=""
                      type="text"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <div>組別名稱(中學)</div>
                    <b-form-input
                      v-model="group.groupname.secondary"
                      placeholder=""
                      type="text"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <div>組別名稱(特殊學校)</div>
                    <b-form-input
                      v-model="group.groupname.special"
                      placeholder=""
                      type="text"
                    ></b-form-input>
                  </b-col> -->
                </b-row>

                <b-button
                  variant="outline-danger"
                  class="btn_img_del"
                  @click="delSpapsGroup(index)"
                >
                  刪除選擇{{ index + 1 }}
                </b-button>
              </b-form-group>

              <b-button variant="outline-success" @click="addSpapsGroup">
                添加選擇
              </b-button>
            </div>
          </div>

          <b-form-group
            id="customizeLastModify"
            label-for="customizeLastModify"
          >
            <p class="label">最後修改日期</p>
            <b-form-input
              id="customizeLastModify"
              v-model="customizeLastModify"
              type="date"
            />
          </b-form-group>

          <b-form-group id="disabled">
            <b-form-checkbox v-model="disabled" value="1" unchecked-value="0">
              {{ $t('event.disabled') }}
            </b-form-checkbox>
            <b-form-checkbox v-model="disabled" value="2" unchecked-value="0">
              {{ $t('event.web_disabled') }}
            </b-form-checkbox>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" variant="outline-success" type="submit">{{
              $t('action.submit')
            }}</b-button>
            <b-button variant="outline-primary" @click="previewEplatform()">
              {{ $t('action.preview') }} (E-Platform)
            </b-button>
            <b-button variant="outline-warning" @click="previewWebpage()">
              {{ $t('action.preview') }} (Webpage)
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
// import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import TinymceEditor from '~/components/admin/tinymceEditor'
import tools from '~/components/common/tools'
import Notification from '~/components/common/notification'
import eventTable from '~/components/event/eventTable'
import RedBtn from '~/components/event/redBtn'

export default {
  components: {
    Notification,
    TinymceEditor,
    // vSelect,
    eventTable,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    if (this.action === 'edit') {
      try {
        const aesResult = await this.$axios.get(
          `${process.env.cloudApiUrl}contacts/aes/contact/${this.$route.params.id}`
        )
        this.aesContact = aesResult.data.data[0].contact
        this.aesContactTel = aesResult.data.data[0].tel
        this.aesContactChequePayableTc =
          aesResult.data.data[0].cheque_payable_tc
        this.aesContactChequePayableEn =
          aesResult.data.data[0].cheque_payable_en
        console.log(this.aesContact)
      } catch (err) {
        console.log(err)
      }
    }
    try {
      const eventList = await this.$axios.get(
        `${process.env.cloudApiUrl}events/getList`
      )
      this.eventList = this.eventList.concat([
        ...eventList.data.data.map((e) => ({
          text: e.code + ' - ' + e.title_tc,
          value: e.id,
        })),
      ])
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const categoryList = await this.$axios.get(
        `${process.env.cloudApiUrl}events/categories`
      )
      this.categoryList = this.categoryList.concat([
        ...categoryList.data.data.map((c) => ({
          text: c.title_tc,
          value: c.id,
          scheme: c.scheme,
        })),
      ])
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const tags = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/tags`
      )
      this.tags = this.tags.concat([
        ...tags.data.data.map((tag) => ({
          text: tag.name_tc,
          value: tag.id,
        })),
      ])
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const contacts = await this.$axios.get(
        `${process.env.cloudApiUrl}contacts/public/contact`
      )
      this.contactList = [
        ...contacts.data.data.map((contact) => ({
          // label: contact.name_tc + '(' + contact.tel + ')',
          // code: contact.id,
          text: contact.id + '. ' + contact.name_tc + '(' + contact.tel + ')',
          value: contact.id,
        })),
      ]
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const venues = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/venue`
      )
      this.venueList = [
        ...venues.data.data.map((venue) => ({
          text: `${venue.id}. ${venue.name_tc} ${venue.code}`,
          value: venue.code,
          // label: venue.name_tc,
          // code: venue.code,
        })),
      ]
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const schemel = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/getList`
      )
      let schemeListData = schemel.data.data
      schemeListData.sort((a, b) => {
        if (a.slug < b.slug) return -1
        if (a.slug > b.slug) return 1
        return 0
      })

      schemeListData = schemeListData.map((d) => {
        d.value = d.id
        d.text = d.title_tc
        // delete d.id
        delete d.title_tc
        return d
      })

      const scheme = schemeListData.find((d) => {
        return d.id === parseInt(this.$route.query.subscheme)
      })
      const parentId = scheme ? scheme.parent : null
      if (parentId) {
        schemeListData = schemeListData.filter((d) => d.parent === parentId)
      }

      this.schemeList = this.schemeList.concat(schemeListData)
      if (this.action === 'edit' || this.action === 'copy') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.scheme = data.scheme
        this.parent = data.parent
        this.category_id = data.category_id
        this.tag = data.tag
        this.isFamily = data.isFamily
        this.isStar = data.isStar
        this.code = data.code
        this.title_en = data.title_en
        this.title_tc = data.title_tc
        this.title_sc = data.title_sc
        this.organiser = JSON.parse(data.organiser) || {}
        this.content_en = data.content_en || ''
        this.content_tc = data.content_tc || ''
        this.content_sc = data.content_sc || ''
        /* this.regStartDate = this.$moment(data.regStartDate).format('yyyy-MM-DD')
        this.regDeadline = this.$moment(data.regDeadline).format('yyyy-MM-DD') */
        this.regStartDate = data.regStartDate
          ? this.$moment(data.regStartDate).format('yyyy-MM-DD')
          : null
        this.regDeadline = data.regDeadline
          ? this.$moment(data.regDeadline).format('yyyy-MM-DD')
          : null
        this.startdate = data.startdate
          ? this.$moment(data.startdate).format('yyyy-MM-DD')
          : null
        this.enddate = data.enddate
          ? this.$moment(data.enddate).format('yyyy-MM-DD')
          : null
        this.img = JSON.parse(data.img) || []
        this.noOfImg = this.img.length
        this.thumbnail = data.thumbnail
        this.imgForCalendar = data.cal_img
        this.links = data.links
        this.banner = data.banner
        /* if (data.target !== null || data.target !== undefined)
          this.target = JSON.parse(data.target)
        if (!this.target) this.target = [] */
        this.target = data.target ? data.target.split(',') : []

        this.content_remark = JSON.parse(data.content_remark) || {}
        this.content_remark.content_en = this.content_remark.content_en
          ? this.content_remark.content_en
          : ''
        this.content_remark.content_tc = this.content_remark.content_tc
          ? this.content_remark.content_tc
          : ''
        this.content_remark.content_sc = this.content_remark.content_sc
          ? this.content_remark.content_sc
          : ''

        this.table_remark = JSON.parse(data.table_remark) || {}
        this.table_remark.content_en = this.table_remark.content_en
          ? this.table_remark.content_en
          : ''
        this.table_remark.content_tc = this.table_remark.content_tc
          ? this.table_remark.content_tc
          : ''
        this.table_remark.content_sc = this.table_remark.content_sc
          ? this.table_remark.content_sc
          : ''

        this.topRemark = JSON.parse(data.topRemark) || {}
        this.topRemark.content_en = this.topRemark.content_en
          ? this.topRemark.content_en
          : ''
        this.topRemark.content_tc = this.topRemark.content_tc
          ? this.topRemark.content_tc
          : ''
        this.topRemark.content_sc = this.topRemark.content_sc
          ? this.topRemark.content_sc
          : ''

        this.applyRemark = JSON.parse(data.applyRemark) || {}
        this.applyRemark.content_en = this.applyRemark.content_en
          ? this.applyRemark.content_en
          : ''
        this.applyRemark.content_tc = this.applyRemark.content_tc
          ? this.applyRemark.content_tc
          : ''
        this.applyRemark.content_sc = this.applyRemark.content_sc
          ? this.applyRemark.content_sc
          : ''
        this.customizeLastModify =
          data.customizeLastModify != null
            ? new Date(data.customizeLastModify).toLocaleDateString('en-CA', {
                timeZone: 'Asia/Hong_Kong',
              })
            : null

        try {
          this.successRemark = JSON.parse(data.successRemark) || {}
          this.successRemark.content_en = this.successRemark.content_en
            ? this.successRemark.content_en
            : ''
          this.successRemark.content_tc = this.successRemark.content_tc
            ? this.successRemark.content_tc
            : ''
          this.successRemark.content_sc = this.successRemark.content_sc
            ? this.successRemark.content_sc
            : ''
        } catch (error) {
          this.successRemark.content_en = ''
          this.successRemark.content_tc = ''
          this.successRemark.content_sc = ''
        }

        this.adminRemark = data.adminRemark

        this.haveAdditionalEvent =
          !!data.haveAdditionalEvent && data.haveAdditionalEvent !== ''
            ? data.haveAdditionalEvent
            : null
        this.haveAdditionalEventInt =
          !!data.haveAdditionalEvent && data.haveAdditionalEvent !== '' ? 1 : 0

        this.spapsGroup = JSON.parse(data.spapsGroup) || null

        this.additional_field = JSON.parse(data.additional_field) || []
        this.status = data.status ? data.status : this.status

        const remarkTemp = JSON.parse(data.remark) || null
        this.disabled = data.disabled
        this.contact_id = data.contact_id ? data.contact_id.split(',') : []
        this.contact_id = this.contact_id.map((d) => (d = parseInt(d)))

        this.statusTxt = data.statusTxt
          ? JSON.parse(data.statusTxt)
          : {
              content_en: null,
              content_tc: null,
              content_sc: null,
            }

        this.eventSlot = data.EventSlot.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        ).map((d) => {
          d.date = this.$moment(d.date).format('yyyy-MM-DD')
          d.target = d.target.split(',').sort()
          // console.log(d.fee, typeof d.fee)
          d.fee = JSON.parse(d.fee) || {}
          d.fee.student = d.fee.student || null
          d.fee.teacher = d.fee.teacher || null
          d.fee.cssa = d.fee.cssa || null
          d.status = d.status && d.status > -1 ? d.status : 0
          return d
        })

        this.editTicket()

        if (remarkTemp != null) {
          this.remark = remarkTemp
          this.remark.ticket = this.remark.ticket || []
          if (this.remark.ticket.length === 0) {
            this.remark.ticket.push({
              text: { en: '', tc: '', sc: '' },
              color: 'default',
            })
          }
          if (this.remark.date.length === 0) {
            this.remark.date.push({
              text: { en: '', tc: '', sc: '' },
              color: 'default',
            })
          }
          if (this.remark.venue.length === 0) {
            this.remark.venue.push({
              text: { en: '', tc: '', sc: '' },
              color: 'default',
            })
          }
          if (this.remark.dura.length === 0) {
            this.remark.dura.push({
              text: { en: '', tc: '', sc: '' },
              color: 'default',
            })
          }
        }
        if (data.tabs !== null || data.tabs !== undefined)
          this.tabs = JSON.parse(data.tabs)
        if (!this.tabs) this.tabs = []
      }
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },

  data() {
    return {
      activeTab: null,
      aesContact: '',
      aesContactTel: '',
      aesContactChequePayableTc: '',
      aesContactChequePayableEn: '',
      addTabIndex: null,
      audio: '',
      audio2: '',
      audio0: '',
      customizeLastModify: '',
      eventSlot_fields: [
        { key: 'selected', label: '選取' },
        { key: 'slot', label: '場次' },
      ],
      selected: [],
      selectedVenue: '',
      selectedFee: {},
      spapsSelected: -1,
      eventlist_spaps: [],
      eventlist_spaps_original: [],
      options_statusTxt_en: {
        toolbar: {
          buttons: ['red', 'green', 'default'],
        },
        extensions: {
          red: new RedBtn.P1(),
          green: new RedBtn.P2(),
          default: new RedBtn.P3(),
        },
        disableReturn: true,
      },
      options_statusTxt_tc: {
        toolbar: {
          buttons: ['red', 'green', 'default'],
        },
        extensions: {
          red: new RedBtn.P1(),
          green: new RedBtn.P2(),
          default: new RedBtn.P3(),
        },
        disableReturn: true,
      },
      options_statusTxt_sc: {
        toolbar: {
          buttons: ['red', 'green', 'default'],
        },
        extensions: {
          red: new RedBtn.P1(),
          green: new RedBtn.P2(),
          default: new RedBtn.P3(),
        },
        disableReturn: true,
      },
      options_title_en: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      options_title_tc: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      options_title_sc: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      options_disable_return: {
        toolbar: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'unorderedlist',
            'orderedlist',
            'removeFormat',
          ],
        },
        disableReturn: true,
      },
      options: {
        toolbar: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'unorderedlist',
            'orderedlist',
            'removeFormat',
          ],
        },
        disableReturn: false,
      },
      aoaTemplate: [
        {
          title_en: '故事簡介',
          title_tc: '故事簡介',
          title_sc: '故事简介',
          content_en: '',
          content_tc: '',
          content_sc: '',
          aoaType: 'intro',
          aoaContent: [{ main_audio: '' }],
        },
        {
          title_en: '聲音工廠',
          title_tc: '聲音工廠',
          title_sc: '声音工厂',
          aoaType: 'soundFactory',
          aoaContent: [
            {
              img: '',
              title_en: '',
              title_tc: '',
              title_sc: '',
              audio: '',
              order: '',
            },
          ],
        },
        {
          title_en: '廣播歷史及趣事',
          title_tc: '廣播歷史及趣事',
          title_sc: '广播历史及趣事',
          aoaType: 'history',
          aoaContent: [
            {
              content_en: '',
              content_tc: '',
              content_sc: '',
              color: '',
              order: '',
            },
          ],
        },
        {
          title_en: '現聲說法',
          title_tc: '現聲說法',
          title_sc: '现声说法',
          aoaType: 'interview',
          aoaContent: [
            {
              guest_en: '',
              guest_tc: '',
              guest_sc: '',
              topic_en: '',
              topic_tc: '',
              topic_sc: '',
              audio: '',
              order: '',
            },
          ],
        },
        {
          title_en: '導賞輔助資料',
          title_tc: '導賞輔助資料',
          title_sc: '导赏辅助资料',
          aoaType: 'suppInfo',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
        {
          title_en: '同學的感受',
          title_tc: '同學的感受',
          title_sc: '同学的感受',
          aoaType: 'feedback',
          content_en: '',
          content_tc: '',
          content_sc: '',
          aoaContent: [
            {
              student_en: '',
              student_tc: '',
              student_sc: '',
              audio: '',
              color: '',
              order: '',
            },
          ],
        },
        {
          title_en: '延伸活動',
          title_tc: '延伸活動',
          title_sc: '延伸活动',
          aoaType: 'postActivity',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
      ],
      aesTemplate: [
        {
          title_en: '',
          title_tc: '藝團簡介',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
        {
          title_en: '',
          title_tc: '演出內容',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
        {
          title_en: '',
          title_tc: '主要創作人員',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
        {
          title_en: '',
          title_tc: '查詢',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
      ],
      spapsTemplate: [
        {
          title_en: '',
          title_tc: '視像介紹',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
        {
          title_en: '',
          title_tc: '要點及目標',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
        {
          title_en: '',
          title_tc: '計劃內容',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
        {
          title_en: '',
          title_tc: '推行日期',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
          table_impl: [
            [
              { content_en: '', content_tc: '活動', content_sc: '' },
              { content_en: '', content_tc: '日期', content_sc: '' },
              { content_en: '', content_tc: '內容', content_sc: '' },
            ],
            [
              { content_en: '', content_tc: '', content_sc: '' },
              { content_en: '', content_tc: '', content_sc: '' },
              { content_en: '', content_tc: '', content_sc: '' },
            ],
          ],
        },
        {
          title_en: '',
          title_tc: '計劃詳情',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
          table_detail: [
            [
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
            [
              {
                content_en: '',
                content_tc: '參與學校名額',
                content_sc: '',
                colspan: 1,
              },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
            [
              {
                content_en: '',
                content_tc: '目標對象',
                content_sc: '',
                colspan: 1,
              },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
            [
              {
                content_en: '',
                content_tc: '參與學校資歷',
                content_sc: '',
                colspan: 1,
              },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
            [
              {
                content_en: '',
                content_tc: '參加人數',
                content_sc: '',
                colspan: 1,
              },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
            [
              {
                content_en: '',
                content_tc: '上課時間',
                content_sc: '',
                colspan: 1,
              },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
            [
              {
                content_en: '',
                content_tc: '工作坊節數',
                content_sc: '',
                colspan: 1,
              },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
            [
              {
                content_en: '',
                content_tc: '全期課程費用',
                content_sc: '',
                colspan: 1,
              },
              { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
            ],
          ],
        },
        {
          title_en: '',
          title_tc: '聯絡',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
      ],
      target_options: [
        { text: this.$t('event.target.k1'), value: 'k1' },
        { text: this.$t('event.target.k2'), value: 'k2' },
        { text: this.$t('event.target.k3'), value: 'k3' },
        { text: this.$t('event.target.p1'), value: 'p1' },
        { text: this.$t('event.target.p2'), value: 'p2' },
        { text: this.$t('event.target.p3'), value: 'p3' },
        { text: this.$t('event.target.p4'), value: 'p4' },
        { text: this.$t('event.target.p5'), value: 'p5' },
        { text: this.$t('event.target.p6'), value: 'p6' },
        { text: this.$t('event.target.f1'), value: 'f1' },
        { text: this.$t('event.target.f2'), value: 'f2' },
        { text: this.$t('event.target.f3'), value: 'f3' },
        { text: this.$t('event.target.f4'), value: 'f4' },
        { text: this.$t('event.target.f5'), value: 'f5' },
        { text: this.$t('event.target.f6'), value: 'f6' },
        { text: this.$t('event.target.n'), value: 'n' },
        { text: this.$t('event.target.tk'), value: 't1' },
        { text: this.$t('event.target.tp'), value: 't2' },
        { text: this.$t('event.target.ts'), value: 't3' },
      ],
      target_skl_options: [
        { text: this.$t('event.target.k'), value: 'k' },
        { text: this.$t('event.target.p'), value: 'p' },
        { text: this.$t('event.target.f'), value: 'f' },
        { text: this.$t('event.target.special'), value: 'n' },
        { text: this.$t('event.target.tk'), value: 'tk' },
        { text: this.$t('event.target.tp'), value: 'tp' },
        { text: this.$t('event.target.ts'), value: 'ts' },
      ],
      schemeList: [{ value: null, text: 'Not Selected' }],
      eventList: [{ value: null, text: 'No Parent' }],
      categoryList: [{ value: null, text: 'No Category' }],
      tags: [{ value: null, text: 'No Tag' }],
      statusList: [
        { value: 0, text: this.$t('event.statusList.normal') },
        // { value: 1, text: this.$t('event.statusList.completed') },
        { value: 2, text: this.$t('event.statusList.cancelled') },
      ],
      venueList: [],
      contactList: [],
      noOfImg: 1,
      noOfSpapsGroup: 1,
      scheme: '',
      parent: null,
      id: null,
      code: '',
      tag: null,
      category_id: null,
      isFamily: 0,
      isStar: 0,
      title_en: '',
      title_tc: '',
      title_sc: '',
      organiser: { title_en: '', title_tc: '', title_sc: '' },
      content_en: '',
      content_tc: '',
      content_sc: '',
      tabs: [],
      regStartDate: null,
      regDeadline: null,
      img: [
        {
          img: '',
          caption: { content_en: '', content_tc: '', content_sc: '' },
        },
      ],
      thumbnail: '',
      imgForCalendar: '',
      startdate: null,
      enddate: null,
      target: [],
      additional_field: [],
      links: '',
      banner: '',
      eventSlot: [],
      contact_id: [],
      content_remark: { content_en: '', content_tc: '', content_sc: '' },
      table_remark: { content_en: '', content_tc: '', content_sc: '' },
      topRemark: { content_en: '', content_tc: '', content_sc: '' },
      applyRemark: { content_en: '', content_tc: '', content_sc: '' },
      successRemark: { content_en: '', content_tc: '', content_sc: '' },
      adminRemark: '',
      haveAdditionalEventInt: 0,
      haveAdditionalEvent: null,
      status: 0,
      statusTxt: {},
      spapsGroup: null,
      remark: {
        date: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        venue: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        dnv: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        time: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        dura: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        target: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        quota: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        ticket: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
      },
      disabled: 0,
      error: null,
    }
  },
  computed: {
    isSubScheme() {
      return this.parent
    },
    getEvent() {
      return {
        EventSlot: [...this.eventSlot].sort((a, b) => {
          if (a.data > b.date) {
            return 1
          } else if (a.data < b.date) {
            return -1
          } else if (a.startTime > b.startTime) {
            return 1
          } else if (a.startTime < b.startTime) {
            return -1
          } else {
            return 0
          }
        }),
      }
    },
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      tc.push(this.organiser.title_tc)
      tc.push(this.topRemark.content_tc)
      tc.push(this.content_remark.content_tc)
      tc.push(this.table_remark.content_tc)
      tc.push(this.applyRemark.content_tc)
      tc.push(this.successRemark.content_tc)
      if (this.tabs.length > 0) {
        this.tabs.forEach((tab) => {
          tc.push({
            title: tab.title_tc,
            content: tab.content_tc,
          })
          if (tab.table_impl)
            tab.table_impl.forEach((row) =>
              row.forEach((col) =>
                tc.push({
                  content: col.content_tc,
                })
              )
            )
          if (tab.table_detail)
            tab.table_detail.forEach((row) =>
              row.forEach((col) =>
                tc.push({
                  content: col.content_tc,
                })
              )
            )
          if (tab.aoaContent)
            tab.aoaContent.forEach((col) => {
              if (col.title_tc) {
                tc.push({
                  content: col.title_tc,
                })
              }
              if (col.content_tc) {
                tc.push({
                  content: col.content_tc,
                })
              }
              if (col.guest_tc) {
                tc.push({
                  content: col.guest_tc,
                })
              }
              if (col.topic_tc) {
                tc.push({
                  content: col.topic_tc,
                })
              }
              if (col.student_tc) {
                tc.push({
                  content: col.student_tc,
                })
              }
            })
        })
      }
      if (this.additional_field.length > 0) {
        this.additional_field.forEach((row) => {
          row.forEach((col) =>
            tc.push({
              content: col.content_tc,
            })
          )
        })
      }
      if (this.img.length > 0) {
        this.img.forEach((i) => {
          tc.push({
            content: i.caption.content_tc,
          })
        })
      }
      // console.log('test', this.remark)
      this.pushRemark(this.remark.date, tc)
      this.pushRemark(this.remark.venue, tc)
      this.pushRemark(this.remark.dnv, tc)
      this.pushRemark(this.remark.time, tc)
      this.pushRemark(this.remark.ticket, tc)
      this.pushRemark(this.remark.dura, tc)
      this.pushRemark(this.remark.target, tc)
      this.pushRemark(this.remark.quota, tc)
      return tc
    },
  },
  watch: {
    scheme: {
      handler(newVal, oldVal) {
        this.getEventlist(newVal)
      },
      deep: true,
    },
    spapsGroup: {
      handler(newVal, oldVal) {
        this.generateEventlist(this.scheme)
      },
      deep: true,
    },
  },
  methods: {
    reorderTabs() {
      this.eventSlot.sort((a, b) => new Date(a.date) - new Date(b.date))
      // Find the index of the currently active tab by title
      const activeTabIndex = this.eventSlot.findIndex(
        (tab) => tab.id === this.activeTab
      )

      // If the active tab still exists, update the activeTab index
      if (activeTabIndex !== -1) {
        this.activeTab = this.eventSlot[activeTabIndex].id
      }
    },
    priceFormatter(value) {
      if (value > 0) return value
      return null
    },
    editTicket() {
      // set same ticket price for all event slots
      if (this.eventSlot.length) {
        this.selectedFee = JSON.parse(JSON.stringify(this.eventSlot[0].fee))
      }
      this.eventSlot.forEach((d) => {
        d.fee = this.selectedFee
        // const found = this.selected.find((e) => e.id === d.id)
        // if (found) d.fee = JSON.parse(JSON.stringify(this.selectedFee))
      })
    },
    editSlotsVenue() {
      this.eventSlot.forEach((d) => {
        const found = this.selected.find((e) => e.id === d.id)
        if (found) d.venue = this.selectedVenue
      })
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    onRowSelected(items) {
      this.selected = items
    },
    async getEventlist(subscheme) {
      try {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/subScheme/${subscheme}`
        )
        this.eventlist_spaps_original = result.data.data
        this.eventlist_spaps_original.sort((a, b) =>
          a.code.localeCompare(b.code)
        )
        this.generateEventlist()
      } catch (err) {}
    },
    generateEventlist() {
      const arr = []
      arr.push({ value: -1, text: '請選擇...' })

      this.eventlist_spaps_original.forEach((d) => {
        let group = null
        if (d.id === this.id) {
          group = this.spapsGroup
        } else {
          group =
            typeof d.spapsGroup === 'string'
              ? JSON.parse(d.spapsGroup)
              : d.spapsGroup
        }
        if (group) {
          group.forEach((e) => {
            let text = `${
              d.organiser ? this.i18nTitle(JSON.parse(d.organiser)) : ''
            }${this.i18nTitle(d)}`
            // for sub-scheme eg 音樂劇計劃 & 音樂劇歌舞計劃
            if (this.i18nTitle(e)) text = `${text} - 「${this.i18nTitle(e)}」`
            // for groups eg 中學入門組 & 中學延續組
            if (
              e.grpPrimary.quota === 0 &&
              e.grpSecondary.quota === 0 &&
              e.grpSpecial.quota === 0
            ) {
              const item = {}
              item.value = d.id
              item.text = text
              arr.push(item)
            } else {
              if (e.grpPrimary.quota > 0) {
                const textGrp =
                  this.i18nTitle(e.grpPrimary) !== ''
                    ? this.i18nTitle(e.grpPrimary)
                    : this.$t('school.primarysklgrp')
                const text2 = `${text} (${textGrp})`
                /* const text2 = `${text} (${this.i18nTitle(e.grpPrimary)})` */
                const item = {}
                item.value = d.id
                item.text = text2
                arr.push(item)
              }
              if (e.grpSecondary.quota > 0) {
                const textGrp =
                  this.i18nTitle(e.grpSecondary) !== ''
                    ? this.i18nTitle(e.grpSecondary)
                    : this.$t('school.secondarysklgrp')
                const text2 = `${text} (${textGrp})`
                const item = {}
                item.value = d.id
                item.text = text2
                arr.push(item)
              }
              if (e.grpSpecial.quota > 0) {
                const textGrp =
                  this.i18nTitle(e.grpSpecial) !== ''
                    ? this.i18nTitle(e.grpSpecial)
                    : this.$t('school.specsklgrp')
                const text2 = `${text} (${textGrp})`
                const item = {}
                item.value = d.id
                item.text = text2
                arr.push(item)
              }
            }
          })
        } else {
          const item = {}
          item.value = d.id
          item.text = `${
            d.organiser ? this.i18nTitle(JSON.parse(d.organiser)) : ''
          }：${this.i18nTitle(d)}`
          arr.push(item)
        }
        this.eventlist_spaps = [...arr]
      })
    },
    getSchemeSlug(id) {
      const result = this.schemeList.find((s) => s.value === parseInt(id))
      return result ? result.slug : null
    },
    getParentSchemeId(id) {
      const result = this.schemeList.find((s) => s.value === parseInt(id))
      // console.log('result', result)
      return result ? result.parent : null
    },
    haveCat(id) {
      const result2 = this.schemeList.find((s) => s.value === parseInt(id))
      // console.log('result2', result2)
      return result2 ? result2.needCategory : false
    },
    getCategoryList(id) {
      let arr = [...this.categoryList]
      arr = arr.filter((d) => {
        return d.scheme === this.scheme
      })
      return arr
    },
    format(value, event) {
      return this.$moment(value).format('YYYY-MM-DD')
    },

    async prepareToExit() {
      const back = await this.$swal({
        html: `${this.$t('alert.unsaveTitle')}<br />${this.$t('alert.unsave')}`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('alert.yes'),
        confirmButtonColor: '#d9534f',
        cancelButtonText: this.$t('alert.cancel'),
      })
      return back.value
    },
    addEventTableRow() {
      this.additional_field.push([
        {
          content_en: '',
          content_tc: '',
          content_sc: '',
          colspan: 1,
        },
        { content_en: '', content_tc: '', content_sc: '', colspan: 1 },
      ])
    },
    addExtraEvent() {},
    getTabContent(evt, id, content) {
      if (id.startsWith('tab_editor')) {
        const tmp = id.split('_')
        this.tabs[tmp[3]]['content_' + tmp[2]] = content
      } else {
        this[id] = content
      }
    },
    pushRemark(data, tc) {
      for (let i = 0; i < data.length; i++) {
        tc.push(data[i].text.tc)
      }
    },
    shiftRemark(data, sc) {
      for (let i = 0; i < data.length; i++) {
        data[i].text.sc = sc.shift()
      }
    },
    updateRemarks(newVal) {
      this.remark = newVal
    },
    setSc(data) {
      // console.log(data)
      this.title_sc = data.shift()
      this.content_sc = data.shift()
      this.organiser.title_sc = data.shift()
      this.topRemark.content_sc = data.shift()
      this.content_remark.content_sc = data.shift()
      this.table_remark.content_sc = data.shift()
      this.applyRemark.content_sc = data.shift()
      this.successRemark.content_sc = data.shift()
      if (this.tabs.length > 0) {
        for (let i = 0; i < this.tabs.length; i++) {
          const temp = data.shift()
          this.tabs[i].title_sc = temp.title
          this.tabs[i].content_sc = temp.content
          if (this.tabs[i].table_impl)
            this.tabs[i].table_impl.forEach((row) => {
              row.forEach((col) => {
                const temp2 = data.shift()
                col.content_sc = temp2.content
              })
            })

          if (this.tabs[i].table_detail)
            this.tabs[i].table_detail.forEach((row) => {
              row.forEach((col) => {
                const temp2 = data.shift()
                col.content_sc = temp2.content
              })
            })
          if (this.tabs[i].aoaContent)
            this.tabs[i].aoaContent.forEach((col) => {
              if (col.title_tc) {
                const temp2 = data.shift()
                col.title_sc = temp2.content
              }
              if (col.content_tc) {
                const temp2 = data.shift()
                col.content_sc = temp2.content
              }
              if (col.guest_tc) {
                const temp2 = data.shift()
                col.guest_sc = temp2.content
              }
              if (col.topic_tc) {
                const temp2 = data.shift()
                col.topic_sc = temp2.content
              }
              if (col.student_tc) {
                const temp2 = data.shift()
                col.student_sc = temp2.content
              }
            })
        }
      }
      if (this.additional_field.length > 0) {
        this.additional_field.forEach((row) => {
          row.forEach((col) => {
            const temp = data.shift()
            col.content_sc = temp.content
          })
        })
      }
      if (this.img.length > 0) {
        this.img.forEach((i) => {
          const temp = data.shift()
          i.caption.content_sc = temp.content
        })
      }
      this.shiftRemark(this.remark.date, data)
      this.shiftRemark(this.remark.venue, data)
      this.shiftRemark(this.remark.dnv, data)
      this.shiftRemark(this.remark.time, data)
      this.shiftRemark(this.remark.ticket, data)
      this.shiftRemark(this.remark.dura, data)
      this.shiftRemark(this.remark.target, data)
      this.shiftRemark(this.remark.quota, data)
    },
    getTarget(eslot) {
      return eslot.target
    },
    getVenue(code) {
      return this.venueList.find((v) => {
        return v.code === code || v.value === code
      })
    },
    getContact(code) {
      return this.contactList.find((c) => c.code === code)
    },
    updateVenue(eslot, venue) {
      eslot.venue = venue === null ? null : venue.code
    },
    updateContact(contact) {
      this.contact_id = contact === null ? null : contact.code
    },
    getContent(evt, id, content) {
      this[id] = content
      // console.log('getContent', id, this[id])
    },
    async uploadImage(e, isImg, index) {
      try {
        const params = new FormData()
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            this[e.target.id] = result.data.location
            if (isImg) this.img[index].img = result.data.location
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
      // console.log(this.img)
    },
    async uploadImageResize(e, isImg, index, width) {
      try {
        const params = new FormData()
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload/${width}`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            this[e.target.id] = result.data.location
            if (isImg) this.img[index].img = result.data.location
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    async submit() {
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        // await this.callapi(process.env.previewApiUrl)
        // console.log(data, data.previewId)
        if (data && data.previewId)
          await this.callapi(process.env.cloudApiUrl, data.previewId)
        else await this.callapi(process.env.cloudApiUrl)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.go(-1)
          // this.$router.push(this.localePath('/admin/event'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async previewEplatform() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }

      if (previewId) {
        window.open(
          `${process.env.previewUrl}${this.$i18n.locale}/event/${previewId}`
        )
      } else if (this.parent)
        window.open(
          `${process.env.previewUrl}${this.$i18n.locale}/event/${this.parent}`
        )
      else
        window.open(
          `${process.env.previewUrl}${this.$i18n.locale}/event/${this.id}`
        )

      // delete preview db entry after preview
      this.revertPreviewDbEntry(previewId)
    },
    async updateAes() {
      try {
        const obj = {
          event_id: this.id,
          contact: this.aesContact,
          tel: this.aesContactTel,
          cheque_payable_tc: this.aesContactChequePayableTc,
          cheque_payable_en: this.aesContactChequePayableEn,
        }
        await this.$axios.$post(
          `${process.env.cloudApiUrl}contacts/aes/contact`,
          obj
        )
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.go(-1)
          // this.$router.push(this.localePath('/admin/event'))
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async previewWebpage() {
      let previewId = null
      // console.log(this.regStartDate, typeof this.regStartDate)
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
        await this.$axios.$post(
          `${process.env.previewApiUrl}preview/toWebPreview`
        )
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
      if (previewId) {
        window.open(
          `${process.env.previewWebUrl}${this.$i18n.locale}/eventDetail/${previewId}`
        )
      } else if (this.parent)
        window.open(
          `${process.env.previewWebUrl}${this.$i18n.locale}/eventDetail/${this.parent}`
        )
      else
        window.open(
          `${process.env.previewWebUrl}${this.$i18n.locale}/eventDetail/${this.id}`
        )

      // delete preview db entry after preview
      this.revertPreviewDbEntry(previewId)
    },
    async revertPreviewDbEntry(previewId) {
      if (previewId) {
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#d9534f',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.previewApiUrl}events/delete`,
            {
              id: previewId,
            }
          )
          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)
        }
      } else if (this.action === 'edit') {
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#1cbcb4',
        })
        if (back.value) {
          // post original data to preview db
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}events/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)

          const newEventSlot = data.EventSlot.map((d) => {
            d.date = this.$moment(d.date).format('yyyy-MM-DD')
            d.fee = JSON.parse(d.fee) || {}
            d.fee.student = d.fee.student || null
            d.fee.teacher = d.fee.teacher || null
            d.fee.cssa = d.fee.cssa || null
            d.status = d.status && d.status > -1 ? d.status : 0
            return d
          })

          const result2 = await this.$axios.$post(
            `${process.env.previewApiUrl}events/update`,
            {
              id: data.id,
              scheme: data.scheme,
              parent: data.parent,
              category_id: data.category_id,
              tag: data.tag,
              isFamily: data.isFamily,
              isStar: data.isStar,
              code: data.code,
              title_en: data.title_en,
              title_tc: data.title_tc,
              title_sc: data.title_sc,
              organiser: data.organiser,
              content_en: data.content_en || '',
              content_tc: data.content_tc || '',
              content_sc: data.content_sc || '',
              regStartDate: data.regStartDate
                ? this.$moment(data.regStartDate).format('yyyy-MM-DD')
                : null,
              regDeadline: data.regDeadline
                ? this.$moment(data.regDeadline).format('yyyy-MM-DD')
                : null,
              tabs: data.tabs ? JSON.parse(data.tabs) : null,
              img: data.img,
              thumbnail: data.thumbnail,
              cal_img: data.imgForCalendar,
              startdate: data.startdate
                ? this.$moment(data.startdate).format('yyyy-MM-DD')
                : null,
              enddate: data.enddate
                ? this.$moment(data.enddate).format('yyyy-MM-DD')
                : null,
              target: data.target,
              additional_field: data.additional_field,
              status: data.status,
              statusTxt: data.statusTxt ? JSON.parse(data.statusTxt) : null,
              links: data.links,
              banner: data.banner,
              remark: data.remark,
              table_remark: data.table_remark,
              content_remark: data.content_remark,
              topRemark: data.topRemark,
              applyRemark: data.applyRemark,
              successRemark: data.successRemark,
              adminRemark: data.adminRemark,
              haveAdditionalEvent: data.haveAdditionalEvent,
              spapsGroup: data.spapsGroup,
              disabled: data.disabled,
              contact_id: data.contact_id,
              eventSlot: newEventSlot,
              customizeLastModify: data.customizeLastModify,
            }
          )

          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result2.error !== undefined) throw new Error(result2.error)
        }
      }
    },
    async callapi(url, previewId) {
      const newEventSlot = JSON.parse(JSON.stringify(this.eventSlot))
      newEventSlot.map((d) => {
        d.target = d.target.toString()
        return d
      })
      if (this.customizeLastModify === '') {
        this.customizeLastModify = null
      }
      const contact = this.contact_id.filter((d) => d !== -1).join()
      // console.log(this.eventSlot)
      let result
      if (this.action === 'edit') {
        result = await this.$axios.$post(`${url}events/update`, {
          id: this.id,
          scheme: this.scheme,
          parent: this.parent,
          category_id: this.category_id,
          tag: this.tag,
          isFamily: this.isFamily,
          isStar: this.isStar,
          code: this.code,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          organiser: JSON.stringify(this.organiser),
          content_en: this.content_en || '',
          content_tc: this.content_tc || '',
          content_sc: this.content_sc || '',
          regStartDate:
            this.regStartDate !== 'Invalid date' ? this.regStartDate : null,
          regDeadline:
            this.regDeadline !== 'Invalid date' ? this.regDeadline : null,
          tabs: this.tabs.length > 0 ? this.tabs : null,
          img: JSON.stringify(this.img),
          thumbnail: this.thumbnail,
          cal_img: this.imgForCalendar,
          startdate: this.startdate !== '' ? this.startdate : null,
          enddate: this.enddate !== '' ? this.enddate : null,
          target: this.target.length > 0 ? this.target.toString() : null,
          additional_field: JSON.stringify(this.additional_field),
          status: this.status,
          statusTxt: this.statusTxt,
          links: this.links,
          banner: this.banner,
          remark: JSON.stringify(this.remark),
          table_remark: JSON.stringify(this.table_remark),
          content_remark: JSON.stringify(this.content_remark),
          topRemark: JSON.stringify(this.topRemark),
          applyRemark: JSON.stringify(this.applyRemark),
          successRemark: JSON.stringify(this.successRemark),
          adminRemark: this.adminRemark,
          haveAdditionalEvent: this.haveAdditionalEvent,
          spapsGroup: this.spapsGroup,
          customizeLastModify: this.customizeLastModify,
          disabled: this.disabled,
          contact_id: contact,
          eventSlot: newEventSlot,
        })
      } else {
        result = await this.$axios.$post(`${url}events/add`, {
          id: this.id,
          scheme: this.scheme,
          parent: this.parent,
          category_id: this.category_id,
          tag: this.tag,
          isFamily: this.isFamily,
          isStar: this.isStar,
          code: this.code,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          organiser: JSON.stringify(this.organiser),
          content_en: this.content_en || '',
          content_tc: this.content_tc || '',
          content_sc: this.content_sc || '',
          regStartDate:
            this.regStartDate !== 'Invalid date' ? this.regStartDate : null,
          regDeadline:
            this.regDeadline !== 'Invalid date' ? this.regDeadline : null,
          tabs: this.tabs.length > 0 ? this.tabs : null,
          img: JSON.stringify(this.img),
          thumbnail: this.thumbnail,
          cal_img: this.imgForCalendar,
          startdate: this.startdate !== '' ? this.startdate : null,
          enddate: this.enddate !== '' ? this.enddate : null,
          target: this.target.length > 0 ? this.target.toString() : null,
          additional_field: JSON.stringify(this.additional_field),
          topRemark: JSON.stringify(this.topRemark),
          applyRemark: JSON.stringify(this.applyRemark),
          successRemark: JSON.stringify(this.successRemark),
          adminRemark: this.adminRemark,
          haveAdditionalEvent: this.haveAdditionalEvent,
          spapsGroup: this.spapsGroup,
          status: this.status,
          statusTxt: this.statusTxt,
          links: this.links,
          banner: this.banner,
          customizeLastModify: this.customizeLastModify,
          remark: JSON.stringify(this.remark),
          disabled: this.disabled,
          contact_id: contact,
          eventSlot: newEventSlot,
          previewId,
        })
      }
      this.error = ''
      if (result.error !== undefined) throw new Error(result.error)
      // console.log('preview', result)
      return result && result.data ? result.data : null
    },
    addTab() {
      this.eventSlot.push({
        id: 0,
        date: '',
        startTime: '',
        endTime: '',
        fee: { student: null, teacher: null, cssa: null },
        venue: '',
        target: [],
        quota: '',
        remain_quota: null,
        status: 0,
      })
    },
    copyTab(counter) {
      this.eventSlot.push(JSON.parse(JSON.stringify(this.eventSlot[counter])))
      this.eventSlot[this.eventSlot.length - 1].id = 0
    },
    deleteTab(counter) {
      console.log('delete', counter, 'eventSlot', this.eventSlot)
      this.eventSlot.splice(counter, 1)
      console.log('delete', counter, 'eventSlot', this.eventSlot)
    },
    addImg() {
      this.noOfImg += 1
      this.img.push({
        img: '',
        caption: { content_en: '', content_tc: '', content_sc: '' },
      })
    },
    delImg(index) {
      this.img.splice(index, 1)
      this.noOfImg -= 1
    },
    addSpapsGroup() {
      this.noOfSpapsGroup += 1
      if (this.spapsGroup === null) this.spapsGroup = []
      this.spapsGroup.push({
        code: '',
        title_en: '',
        title_tc: '',
        title_sc: '',
        grpPrimary: {
          quota: 0,
          title_en: '',
          title_tc: '',
          title_sc: '',
        },
        grpSecondary: {
          quota: 0,
          title_en: '',
          title_tc: '',
          title_sc: '',
        },
        grpSpecial: {
          quota: 0,
          title_en: '',
          title_tc: '',
          title_sc: '',
        },
      })
      /* this.spapsGroup.push({
        code: '',
        quota: {
          primary: 0,
          secondary: 0,
          special: 0,
        },
        groupname: {
          primary: '',
          secondary: '',
          special: '',
        },
        title_en: '',
        title_tc: '',
        title_sc: '',
      }) */
    },
    delSpapsGroup(index) {
      this.spapsGroup.splice(index, 1)
      this.noOfSpapsGroup -= 1
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    addContentTab(index, tabIndex) {
      // index 0: general; 1: spaps template; 2: aes template; 3: aoa template
      const i =
        tabIndex <= 0
          ? 0
          : tabIndex > this.tabs.length
          ? this.tabs.length
          : tabIndex - 1
      if (index === 0) {
        if (tabIndex) {
          this.tabs.splice(i, 0, {
            title_en: '',
            title_tc: '',
            title_sc: '',
            content_en: '',
            content_tc: '',
            content_sc: '',
          })
        } else {
          this.tabs.push({
            title_en: '',
            title_tc: '',
            title_sc: '',
            content_en: '',
            content_tc: '',
            content_sc: '',
          })
        }
      } else if (index === 1) {
        this.spapsTemplate.forEach((t) => this.tabs.push(t))
      } else if (index === 2) {
        this.aesTemplate.forEach((t) => this.tabs.push(t))
      } else if (index === 3) {
        this.aoaTemplate.forEach((t) => this.tabs.push(t))
      }
    },
    deleteContentTab(counter) {
      this.tabs.splice(counter, 1)
    },
  },
}
</script>
<style lang="scss">
.es-table > tbody > tr:focus,
.es-table > tbody > tr:active {
  outline: none !important;
  box-shadow: none;
}
.tab-column {
  flex-basis: 0 !important;
  margin-top: 1rem !important;
}
.adminRemark > div > * {
  margin-bottom: 0;
}
.green {
  color: green;
}
</style>
<style lang="scss" scoped>
.w-5 {
  width: 100px;
  margin-right: 10px;
}
.quota-txt {
  margin-top: 4px;
}
.label {
  margin: 1em 0;
}
.medium-editor {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
.medium-editor-topRemark {
  color: red;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
.btn_img_del {
  display: block;
  margin-top: 5px;
}
.tab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.component {
  text-align: left;
}
.btn {
  margin-bottom: 5px;
}
.icon {
  width: 30px;
  height: 30px;
}
.shadow {
  padding: 30px;
  margin: 30px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.ie-hint {
  color: red;
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-hint {
    display: block;
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .shadow {
    padding: 12px;
    margin: 30px -15px;
  }
}
</style>
<!--[if IE]>
  <style scoped>
    .ie-hint {
      display: block;
    }
  </style>
<![endif]-->
