var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "10" } }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "slug", "label-for": "slug" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.$t("scheme.slug"))),
                          ]),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: { type: "text", trim: "" },
                            model: {
                              value: _vm.slug,
                              callback: function ($$v) {
                                _vm.slug = $$v
                              },
                              expression: "slug",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-for": "img",
                            label: _vm.$t("scheme.img"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.img,
                              callback: function ($$v) {
                                _vm.img = $$v
                              },
                              expression: "img",
                            },
                          }),
                          _vm._v(" "),
                          _c("b-form-file", {
                            attrs: { id: "img", accept: "image/*" },
                            on: { change: _vm.uploadImage },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: { "max-height": "100px" },
                            attrs: { src: _vm.img, alt: "category" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-7" },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "mt-3" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.en") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_en",
                                    "label-for": "title_en",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.en"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_en,
                                      callback: function ($$v) {
                                        _vm.title_en = $$v
                                      },
                                      expression: "title_en",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.tc") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_tc",
                                    "label-for": "title_tc",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.tc"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_tc,
                                      callback: function ($$v) {
                                        _vm.title_tc = $$v
                                      },
                                      expression: "title_tc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("scheme.title.sc") } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "title_sc",
                                    "label-for": "title_sc",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "placeholder.title.sc"
                                      ),
                                      type: "text",
                                      trim: "",
                                    },
                                    model: {
                                      value: _vm.title_sc,
                                      callback: function ($$v) {
                                        _vm.title_sc = $$v
                                      },
                                      expression: "title_sc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { id: "scheme", "label-for": "scheme" } },
                        [
                          _c("p", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.$t("event.scheme"))),
                          ]),
                          _vm._v(" "),
                          _c("b-form-select", {
                            attrs: { options: _vm.schemeList },
                            model: {
                              value: _vm.scheme,
                              callback: function ($$v) {
                                _vm.scheme = $$v
                              },
                              expression: "scheme",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.scheme
                        ? _c(
                            "b-form-group",
                            {
                              attrs: { id: "parentCat", "label-for": "parent" },
                            },
                            [
                              _c("p", { staticClass: "label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.category.parentCat"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.catList,
                                  disabled: !_vm.scheme ? true : false,
                                },
                                model: {
                                  value: _vm.parent,
                                  callback: function ($$v) {
                                    _vm.parent = $$v
                                  },
                                  expression: "parent",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "shadow my-3 mse-c1" },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { id: "content", "label-for": "content" } },
                        [
                          _c(
                            "b-tabs",
                            { attrs: { "content-class": "mt-3" } },
                            [
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.en") },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: { rows: "10", "max-rows": "20" },
                                    model: {
                                      value: _vm.content_en,
                                      callback: function ($$v) {
                                        _vm.content_en = $$v
                                      },
                                      expression: "content_en",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.tc") },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: { rows: "10", "max-rows": "20" },
                                    model: {
                                      value: _vm.content_tc,
                                      callback: function ($$v) {
                                        _vm.content_tc = $$v
                                      },
                                      expression: "content_tc",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tab",
                                {
                                  attrs: { title: _vm.$t("event.content.sc") },
                                },
                                [
                                  _c("b-form-textarea", {
                                    attrs: { rows: "10", "max-rows": "20" },
                                    model: {
                                      value: _vm.content_sc,
                                      callback: function ($$v) {
                                        _vm.content_sc = $$v
                                      },
                                      expression: "content_sc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { width: "300px" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { id: "slug", "label-for": "slug" } },
                      [
                        _c("p", { staticClass: "label" }, [_vm._v("類別次序")]),
                        _vm._v(" "),
                        _c("b-form-input", {
                          attrs: { type: "number", trim: "" },
                          model: {
                            value: _vm.orders,
                            callback: function ($$v) {
                              _vm.orders = $$v
                            },
                            expression: "orders",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                          disabled: _vm.btn_disabled,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")) + "\n          ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }