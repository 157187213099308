<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="6">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          <p>{{ $t('config.type') }}</p>
          <b-form-group id="type" label-for="type">
            <b-form-input
              id="type"
              v-model="type"
              type="text"
              required
              readonly
              trim
            ></b-form-input>
          </b-form-group>

          <b-form-group v-for="(item, index) in value" :key="index">
            <h3>網站 {{ index + 1 }}</h3>
            <!-- <b-tabs content-class="mt-3"> -->
            <b-tabs>
              <b-tab :title="$t('config.title.en')">
                <b-form-input
                  v-model="item.title_en"
                  type="text"
                  trim
                ></b-form-input>
              </b-tab>
              <b-tab :title="$t('config.title.tc')">
                <b-form-input
                  v-model="item.title_tc"
                  type="text"
                  trim
                ></b-form-input>
              </b-tab>
              <b-tab :title="$t('config.title.sc')">
                <b-form-input
                  v-model="item.title_sc"
                  type="text"
                  trim
                ></b-form-input>
              </b-tab>
            </b-tabs>

            <div class="mt-3">類別簡稱</div>
            <b-form-input v-model="item.code" type="text" trim></b-form-input>
            <b-button variant="danger" class="mt-3" @click="delType(index)">
              刪除類別 {{ index + 1 }}
            </b-button>
            <hr />
          </b-form-group>

          <b-button variant="success" class="mt-3 mb-3" @click="addType()">
            新增類別
          </b-button>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              $t('action.submit')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      // console.log(this.action)
      if (this.action === 'edit') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}systemVariables/public/schemeType`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.type = data.type
        this.value = data.value ? JSON.parse(data.value) : []
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      type: '',
      value: [],
      error: null,
    }
  },
  computed: {
    getTc() {
      const tc = []
      // tc.push(this.content_tc)
      this.value.forEach((d) => {
        tc.push(d.title_tc)
      })
      return tc
    },
  },
  methods: {
    addType() {
      this.value.push({
        code: '',
        title_en: '',
        title_tc: '',
        title_sc: '',
      })
    },
    delType(index) {
      this.value.splice(index, 1)
    },
    setSc(data) {
      if (this.value.length > 0) {
        for (let i = 0; i < this.value.length; i++) {
          this.value[i].title_sc = data[i]
        }
      }
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async submit() {
      try {
        let result
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}systemVariables/update`,
            {
              id: this.id,
              type: this.type,
              value: this.value ? JSON.stringify(this.value) : [],
            }
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}systemVariables/add`,
            {
              type: this.type,
              value: this.value ? JSON.stringify(this.value) : [],
            }
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.push(this.localePath('/admin/config'))
        }
      } catch (err) {
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
