<template>
  <div class="app" :class="fontclass">
    <header class="row header mobile-row">
      <div
        v-if="api && api.includes('preview')"
        pill
        variant="secondary"
        class="preview"
      >
        預覽模式 <b-icon icon="eye" />
      </div>
      <div
        v-if="api && api.includes('abo.lcsd.hksarg')"
        pill
        variant="secondary"
        class="preview"
      >
        內聯網 {{ version }}
      </div>
      <div
        v-if="api && api.includes('abotest.lcsd.hksarg')"
        pill
        variant="secondary"
        class="preview"
      >
        內聯網 (Testing)
      </div>
      <b-col class="logo">
        <nuxt-link :to="localePath('/')">
          <img
            v-show="$i18n.locale === 'en'"
            class="logoimg"
            :src="`${staticPrefix}img/logo_abo_en.png`"
            alt="logo image"
          />
          <img
            v-show="$i18n.locale === 'tc'"
            class="logoimg"
            :src="`${staticPrefix}img/logo_abo_ch.png`"
            alt="封面圖片"
          />
          <img
            v-show="$i18n.locale === 'sc'"
            class="logoimg"
            :src="`${staticPrefix}img/logo_abo_sc.png`"
            alt="封面图片"
          />
        </nuxt-link>
      </b-col>
      <b-col
        class="fontstyle"
        :class="navDisplaying ? 'm-show-flex' : 'm-hide-flex'"
      >
        <div>
          <nuxt-link
            class="lang-text"
            :class="$i18n.locale == 'tc' ? 'selected' : ''"
            :to="switchLocalePath('tc')"
          >
            <!-- <nuxt-link v-if="$i18n.locale !== 'tc'" :to="switchLocalePath('tc')"> -->
            {{ $t('g.tc') }}
          </nuxt-link>
        </div>
        <div>/</div>
        <div>
          <nuxt-link
            class="lang-text"
            :class="$i18n.locale == 'sc' ? 'selected' : ''"
            :to="switchLocalePath('sc')"
          >
            <!-- <nuxt-link v-if="$i18n.locale !== 'sc'" :to="switchLocalePath('sc')"> -->
            {{ $t('g.sc') }}
          </nuxt-link>
        </div>
        <div>/</div>
        <div>
          <nuxt-link
            class="lang-text"
            :class="$i18n.locale == 'en' ? 'selected' : ''"
            :to="switchLocalePath('en')"
          >
            <!-- <nuxt-link v-if="$i18n.locale !== 'en'" :to="switchLocalePath('en')"> -->
            {{ $t('g.en') }}</nuxt-link
          >
        </div>
        <div>|</div>
        <div class="fontsize">
          <a
            onclick="return false"
            :class="selectedFontSize === 1 ? 'a1-selected' : 'a1'"
            @click="changeFont(1)"
          >
            A
          </a>
          <a
            onclick="return false"
            :class="selectedFontSize === 2 ? 'a2-selected' : 'a2'"
            @click="changeFont(2)"
          >
            A
          </a>
          <a
            onclick="return false"
            :class="selectedFontSize === 3 ? 'a3-selected' : 'a3'"
            @click="changeFont(3)"
          >
            A
          </a>
        </div>
        <div class="m-line-hide">|</div>
        <div class="sharing a2a_kit">
          <!-- facebook -->
          <a class="a2a_button_facebook"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-facebook"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
              /></svg
          ></a>
          <!-- weibo -->
          <a class="a2a_button_sina_weibo"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <path
                d="M6.722 14.031c-2.65.262-4.938-.938-5.109-2.675c-.172-1.741 1.837-3.359 4.484-3.622c2.65-.263 4.938.938 5.106 2.675c.175 1.741-1.834 3.362-4.481 3.622zm5.297-5.772c-.225-.069-.381-.113-.262-.409c.256-.644.281-1.197.003-1.594c-.519-.741-1.941-.703-3.569-.019c0 0-.513.222-.381-.181c.25-.806.213-1.478-.178-1.869c-.884-.884-3.234.034-5.25 2.05C.876 7.74.001 9.343.001 10.728c0 2.644 3.394 4.253 6.713 4.253c4.35 0 7.247-2.528 7.247-4.534c0-1.216-1.022-1.903-1.941-2.188z"
                fill="currentColor"
              />
              <path
                d="M14.909 3.416a4.234 4.234 0 0 0-4.031-1.306a.61.61 0 1 0 .256 1.194a3.01 3.01 0 0 1 3.494 3.872a.615.615 0 0 0 .394.772a.615.615 0 0 0 .772-.394v-.003a4.222 4.222 0 0 0-.884-4.134z"
                fill="currentColor"
              />
              <path
                d="M13.294 4.875a2.055 2.055 0 0 0-1.963-.634a.527.527 0 1 0 .219 1.031c.341-.072.709.034.959.309c.25.278.319.656.209.987a.532.532 0 0 0 .341.666a.531.531 0 0 0 .666-.341a2.062 2.062 0 0 0-.431-2.019z"
                fill="currentColor"
              />
              <path
                d="M6.869 10.884c-.094.159-.297.234-.456.169c-.159-.063-.206-.244-.116-.397c.094-.153.291-.228.447-.169c.156.056.213.234.125.397zm-.847 1.082c-.256.409-.806.588-1.219.4c-.406-.184-.528-.659-.272-1.059c.253-.397.784-.575 1.194-.403c.416.178.55.65.297 1.063zm.962-2.894c-1.259-.328-2.684.3-3.231 1.409c-.559 1.131-.019 2.391 1.253 2.803c1.319.425 2.875-.228 3.416-1.447c.534-1.197-.131-2.425-1.438-2.766z"
                fill="currentColor"
              /></svg
          ></a>
          <!-- twitter -->
          <a class="a2a_button_twitter"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-twitter"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
              /></svg
          ></a>
          <!-- email -->
          <a class="a2a_button_email"><b-icon icon="envelope-fill"></b-icon></a>
          <!-- link -->
          <a class="a2a_button_copy_link"
            ><b-icon icon="share-fill"></b-icon
          ></a>

          <script async src="https://static.addtoany.com/menu/page.js"></script>
        </div>
        <div class="m-line-hide">|</div>
        <!-- will display when wide screen-->
        <div v-if="!loggedIn" class="pointer" @click="login">
          {{ $t('login.login') }}
        </div>
        <div v-if="loggedIn" class="logined" style="font-size: 1.3em">
          <div class="usericon" style="color: #fc7137" @click="toggleProfile">
            <span> {{ user.name.substring(0, 1) }}</span>
          </div>
          <div class="noti" @click="toggleNoti">
            <b-icon icon="bell-fill"></b-icon>
            <span v-if="count_noti() > 10" class="noti-count">{{ '10+' }}</span>
            <span v-else-if="count_noti() > 0" class="noti-count">{{
              count_noti()
            }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="m-header-right">
        <!-- Responsive Edit -->
        <a
          :class="navDisplaying ? 'm-header-button-active' : 'm-header-button'"
          @click="m_click_navbar_button()"
        >
          <!--img
            :src="`${staticPrefix}img/icon_menu_on.png`"
            style="height: 25px"
          /-->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65 49.79">
            <title>Asset 12</title>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <line class="cls-1" x1="4" y1="4" x2="61" y2="4" />
                <line class="cls-1" x1="4" y1="24.9" x2="61" y2="24.9" />
                <line class="cls-1" x1="4" y1="45.79" x2="61" y2="45.79" />
              </g>
            </g>
          </svg>
        </a>
      </b-col>
      <UserProfile
        ref="mProfile"
        :show="isShowProfile"
        :user="user"
        @close="closeProfile"
      />
      <NotiBox ref="noti" :show="isShowNoti" :user="user" @close="closeNoti" />
    </header>
    <b-row style="background: #f8f8f8; min-height: calc(100vh - 54px)">
      <div sm="12" md="1" class="nav-container">
        <NavBar ref="mNav" />
      </div>
      <b-col sm="12" class="content d-12" style="padding: 0px">
        <b-container class="main">
          <Nuxt />
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import NavBar from '~/components/navbar.vue'
import UserProfile from '~/components/common/user_profile.vue'
import NotiBox from '~/components/common/notification_box.vue'
export default {
  components: { NavBar, UserProfile, NotiBox },
  async fetch() {
    try {
      if (this.loggedIn) {
        const result = await this.$axios.get(`users/profile`)
        this.$store.commit('localStorage/userProfile', result.data.data[0])
        this.user.name = `${result.data.data[0].name}`
        this.user.schoolId = result.data.data[0].school
        this.user.sid = result.data.data[0].sid
        this.user.role = result.data.data[0].role
        this.user.isAdmin =
          result.data.data[0].admin || result.data.data[0].editor

        if (this.user.sid) {
          const result2 = await this.$axios.get(
            `${process.env.cloudApiUrl}schools/getName/${this.user.sid}`
          )
          if (result2.data.data.length) {
            const tmpSchool = result2.data.data[0]
            tmpSchool.name_sc = tmpSchool.name_tc
            this.user.school = this.i18nName(tmpSchool)
          }
        }
      }
    } catch (err) {}
    // try {
    //   const result = await this.$axios.get(
    //     `${process.env.cloudApiUrl}notification`
    //   )
    //   this.notiArray = result.data.data
    // } catch (err) {}
  },
  data() {
    return {
      api: process.env.mainApiUrl,
      user: {
        name: '',
        schoolId: '',
        school: null,
        isAdmin: false,
        role: '',
      },
      isShowProfile: false,
      isShowNoti: false,
      selectedFontSize: this.$store.state.localStorage.setting.fontsize,
      navDisplaying: false,
      notiArray: [],
      timer: '',
    }
  },
  computed: {
    version() {
      return process.env.version
    },
    fontclass() {
      const v = this.$store.state.localStorage.setting.fontsize
      return v === 3 ? 'fontsize3' : v === 2 ? 'fontsize2' : 'fontsize1'
    },
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
    staticPrefix() {
      return process.env.base
    },
  },
  created() {
    this.fetchNotiList()
    this.timer = setInterval(this.fetchNotiList, 60000)
  },
  mounted() {},
  methods: {
    closeProfile(data) {
      this.isShowProfile = data
    },
    closeNoti(data) {
      this.isShowNoti = data
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    changeFont(data) {
      this.$store.commit('localStorage/changeFont', data)
      this.selectedFontSize = data
    },
    login() {
      const tmp = this.$route.name.split('___')
      this.$store.commit('localStorage/updateRef', tmp[0])
      this.$router.push(this.localePath('login'))
    },
    m_click_notice_button() {
      // Find News Modal to Show
      this.$refs.mNav.showNewsModal()
    },
    m_click_navbar_button() {
      // Open / Close Nav Bar
      this.$refs.mNav.toggleNav()
    },
    set_nav_displaying() {
      this.navDisplaying = this.$refs.mNav.mNavDisplay
    },
    toggleProfile() {
      this.isShowProfile = !this.isShowProfile
      this.isShowNoti = false

      console.log(window)

      if (window.innerWidth < window.innerHeight || window.innerWidth < 768) {
        if (!this.user.isAdmin) {
          this.$refs.mProfile.account()
          this.$refs.mNav.toggleNav()
        }
      }
    },
    toggleNoti() {
      this.isShowNoti = !this.isShowNoti
      this.isShowProfile = false

      // console.log(window)

      // if (window.innerWidth < window.innerHeight || window.innerWidth < 768) {
      //   if (!this.user.isAdmin) {
      //     this.$refs.mProfile.account()
      //     this.$refs.mNav.toggleNav()
      //   }
      // }
    },
    async fetchNotiList() {
      try {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}notification`
        )
        this.notiArray = result.data.data
      } catch (err) {}
    },
    count_noti() {
      if (this.checkRole() === 't3') {
        return this.getSCDSNoti().length
      } else if (this.checkRole() === 't2') {
        return (
          this.getSPAPSNoti().length +
          this.getAESNoti().length +
          this.getAOANoti().length
        )
      } else if (this.checkRole() === 'admin') {
        return (
          this.getSCDSNoti().length +
          this.getSPAPSNoti().length +
          this.getAESNoti().length +
          this.getAOANoti().length
        )
      } else {
        return (
          this.getSCDSNoti().length +
          this.getSPAPSNoti().length +
          this.getAESNoti().length +
          this.getAOANoti().length
        )
      }
    },
    checkRole() {
      const admin = [1, 2, 11, 12]
      const t2 = [4, 7, 13]
      const t3 = [5, 8, 9, 10]
      if (admin.includes(this.user.role)) {
        return 'admin'
      } else if (t2.includes(this.user.role)) {
        return 't2'
      } else if (t3.includes(this.user.role)) {
        return 't3'
      } else {
        return '0'
      }
    },
    getSCDSNoti() {
      // console.log('this.SCDSnotiArray')
      // console.log(this.notiArray)
      let scdsArr = this.notiArray
      scdsArr = scdsArr.filter((a) => {
        return (
          a.admin_team === 'scds' &&
          a.staff_handled === 0 &&
          a.user_handled === 0
        )
      })
      // console.log(scdsArr)
      return scdsArr
    },
    getSPAPSNoti() {
      // console.log('this.SPAPSnotiArray')
      let spapsArr = this.notiArray
      spapsArr = spapsArr.filter((a) => {
        return (
          a.admin_team === 'spaps' &&
          a.staff_handled === 0 &&
          a.user_handled === 0
        )
      })
      // console.log(spapsArr)
      return spapsArr
    },
    getAESNoti() {
      // console.log('this.AESnotiArray')
      let aoaArr = this.notiArray
      aoaArr = aoaArr.filter((a) => {
        return (
          a.admin_team === 'aes' &&
          a.staff_handled === 0 &&
          a.user_handled === 0
        )
      })
      // console.log(aoaArr)
      return aoaArr
    },
    getAOANoti() {
      // console.log('this.AOAnotiArray')
      let aoaArr = this.notiArray
      aoaArr = aoaArr.filter((a) => {
        return (
          a.admin_team === 'aoa' &&
          a.staff_handled === 0 &&
          a.user_handled === 0
        )
      })
      // console.log(aoaArr)
      return aoaArr
    },
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@media print {
  .fontstyle.m-show-flex {
    display: none;
  }
  .nav-container {
    display: none;
  }
}
.btn-outline-light {
  color: #3f3f3f;
  border-color: #dddddd;
}
.btn-outline-light:hover {
  border-color: whitesmoke;
}
.preview {
  position: fixed;
  border-radius: 50rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20px;
  z-index: 4;
  /* -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3)); */
  /* filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3)); */
  width: 150px;
  padding: 0.5em;
  color: #fc7137;
  border: 1px solid #fc7137;
}
.usericon {
  user-select: none;
  background: #fc7137;
  color: #fff !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 5px;
  font-size: 21px;
  cursor: pointer;
}
.usericon:hover {
  opacity: 0.75;
}
.a2a_button_facebook:hover,
.a2a_button_sina_weibo:hover,
.a2a_button_twitter:hover,
.a2a_button_email:hover,
.a2a_button_copy_link:hover {
  opacity: 0.75;
}
.noti {
  color: #3f3f3f;
  cursor: pointer;
}
.noti:hover {
  /* color: rgba(63, 63, 63, 0.3); */
  opacity: 0.75;
}
.noti-count {
  position: absolute;
  padding: 2px 4px;
  background: #ff4f38;
  color: #fff;
  border-radius: 30px;
  top: 10px;
  right: 20px;
  min-width: 20px;
  font-size: 10px;
  z-index: 200;
  text-align: center;
}
.sharing {
  display: block;
}
.sharing a {
  margin: 0px 8px;
  color: #3f3f3f !important;
}
.navBtn {
  color: #3f3f3f !important;
}
.navBtn:hover {
  color: #fc7137 !important;
}
.btn-abo {
  background: #fc7137;
  color: white;
}
a {
  cursor: pointer;
}

.fontstyle {
  position: relative;
  float: right;
  display: flex;
  font-size: 18px;
  vertical-align: center;
  justify-content: flex-end;
}
.fontstyle > * {
  align-self: center;
}
.fontstyle a {
  color: rgba(63, 63, 63, 0.3);
}
.fontstyle > div {
  margin: 8px;
}
.logined {
  display: flex;
  position: static;
  left: auto;
}
.logined > * {
  align-self: center;
  margin: 0 8px;
}
.fontsize {
  display: flex;
}
.fontsize a {
  color: #3f3f3f;
}
.fontsize > * {
  margin: 0 8px;
  align-self: baseline;
}
.selected {
  color: rgba(63, 63, 63, 1) !important;
}

html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

header {
  background: #fff;
  margin: 0px;
  z-index: 10;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  width: 100%;
  top: 0;
  height: 75px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-print-color-adjust: exact;
}
.nav-wrapper {
  padding-left: 0px;
}

.d-block {
  text-align: left;
}

.header {
  margin: 0px;
}
.app {
  margin: 0 auto;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
/* @media (min-width: 1200px) { */
.container {
  max-width: 1920px;
}
/* } */
.container {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.nuxt-link-exact-active p {
  color: #000;
}

.pointer {
  cursor: pointer;
  color: #fc7137;
  font-weight: bold;
  margin-left: 15px;
}

.logo {
  height: 60px;
}
.logo.col-sm-12.col-md-3 {
  text-align: left;
  // padding-left: 50px;
}
.logoimg {
  position: absolute;
  left: 20px;
}
/* @media (min-width: 768px) { */
.d-12 {
  flex: 0 0 calc(100% - 100px);
  max-width: calc(100% - 100px);
  // R-edit
}

.nav-container {
  padding: 0px;
  width: 100px;

  // R-edit
  position: static;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  z-index: 0;
}
/* } */
.fontsize1 {
  // font-size: 12px !important;
  // font-size: 1rem !important;
  font-size: 95% !important;
}
.fontsize2 {
  // font-size: 15px !important;
  // font-size: 1.15rem !important;
  font-size: 107% !important;
}
.fontsize3 {
  // font-size: 18px !important;
  // font-size: 1.3rem !important;
  font-size: 120% !important;
}
.a1 {
  font-size: 14px !important;
}
.a2 {
  font-size: 18px !important;
}
.a3 {
  font-size: 22px !important;
}
.a1-selected {
  font-size: 14px !important;
  text-decoration: underline;
}
.a2-selected {
  font-size: 18px !important;
  text-decoration: underline;
}
.a3-selected {
  font-size: 22px !important;
  text-decoration: underline;
}

/* RESPONSIVE EDIT START */
.m-header-right {
  display: none;
}

.m-hide-flex {
  display: flex;
}

.m-line-hide {
  display: block;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .m-show-flex {
    right: 0;
  }

  .m-line-hide {
    display: none;
  }

  .m-hide-flex {
    right: -100%;
  }

  .sharing {
    display: none;
  }
  .fontstyle.col {
    top: 60px;
    position: fixed;
    padding-left: 0;
    padding-right: 1%;
    padding-top: 20px;
    background: #1cbcb4;
    transition: all 0.5s ease;

    border-left: 12px #085b57 solid; // eye focus
    border-right: 12px #085b57 solid;
  }

  .fontstyle a {
    color: rgba(255, 255, 255, 0.3);
  }

  .fontstyle > div {
    color: white;
    margin: 8px 4px;
  }

  .fontsize > * {
    margin: 0 6px;
  }

  .pointer {
    color: #085b57 !important;
    position: absolute;
    left: 5%;

    border-radius: 5px;
    border: 6px #ffffff solid;
    background: #ffffff;
    box-shadow: 1px 1px 1px;
  }

  .pointer:hover {
    border: 6px #ddd solid;
    background: #ddd;
    box-shadow: 0px 0px 0px;
  }

  .logined {
    position: absolute;
    left: 5%;
  }

  .selected,
  .a1-selected,
  .a2-selected,
  .a3-selected {
    color: white !important;
  }

  header {
    height: 60px;
    padding-top: 5px;
  }

  .m-header-right {
    display: flex;
    float: right;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 0;

    position: absolute;
  }

  .m-header-right .m-header-button,
  .m-header-right .m-header-button-active {
    margin: 8px;
    font-size: 15px !important; // not to shift when font-size change
  }

  .m-header-button svg,
  .m-header-button-active svg {
    height: 36px;
    width: 36px;
  }

  .m-header-button svg .cls-1,
  .m-header-button-active .cls-1 {
    fill: none;
    stroke: #085b57;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 8px;
  }

  .m-header-button:hover svg .cls-1,
  .m-header-button-active svg .cls-1 {
    stroke: #1cbcb4;
  }

  .nav-container {
    position: fixed;
    left: auto;
    right: auto;
    bottom: auto;

    width: 0;
    top: 75px;
    z-index: 4;
  }

  .d-12 {
    max-width: unset;
    flex: none;
  }

  .logoimg {
    left: 2px;
    top: 9px;
    height: 38px;
  }

  .noti-count {
    position: absolute;
    padding: 2px 4px;
    background: #ff4f38;
    color: #fff;
    border-radius: 30px;
    top: 0px;
    right: -5px;
    min-width: 20px;
    font-size: 10px;
    z-index: 200;
    text-align: center;
  }
}

/* RESPONSIVE EDIT END */
</style>

<style lang="scss">
body {
  font-family: 'Noto Sans TC', 'Noto Sans SC', sans-serif;
}

.wrapper {
  text-align: left;
  align-content: left;
}

/* @media (min-width: 768px) { */
.main {
  min-height: calc(100vh - 115px);
  margin-bottom: 75px;
  margin-top: 100px;
  margin: 95px auto 75px auto;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .main {
    margin-top: 65px;
  }

  .app {
    overflow-x: hidden;
  }

  .tabs .nav.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    scrollbar-width: thin;
  }

  .tabs .nav.nav-tabs::-webkit-scrollbar {
    height: 5px;
  }

  .tabs .nav.nav-tabs::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .tabs .nav.nav-tabs::-webkit-scrollbar-thumb {
    background: #bbb;
  }
  .tabs .nav.nav-tabs::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
}
/* } */
/* @media (max-width: 767px) {
  .main {
    margin: 0;
  }
  .container {
    max-width: unset !important;
  }
} */
</style>
