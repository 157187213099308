<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="8">
      <div class="newsForm">
        <b-form method="post" @submit.prevent="submit">
          {{ $t('mailTemplate.target') }}
          <b-form-group id="to" label-for="to">
            <b-form-select
              v-model="target"
              :options="targetOption"
              :state="!!target"
              size="sm"
              class="mt-1"
              required
            >
            </b-form-select>
          </b-form-group>
          {{ $t('smsTemplate.advancedTarget') }}
          <b-form-group id="to" label-for="to">
            <b-form-select
              v-model="advTarget"
              :options="advTargetOption"
              :state="!!advTarget"
              size="sm"
              class="mt-1"
              required
            ></b-form-select>
          </b-form-group>
          <div v-if="advTarget !== 'none'">
            {{ $t('smsTemplate.advancedTarget') }}
            <b-row>
              <b-col sm="3">
                {{ $t('smsTemplate.filter') }}
                <b-form-input
                  v-model="advTarget2Filter"
                  size="sm"
                  class="mt-1"
                  @change="getAdvTargetOption2"
                ></b-form-input>
              </b-col>
              <b-col sm="9">
                {{ $t('smsTemplate.select') }}
                <b-form-select
                  v-model="advTarget2"
                  :options="advTarget2FilterOption"
                  :state="!!advTarget2"
                  size="sm"
                  class="mt-1"
                  required
                ></b-form-select>
              </b-col>
            </b-row>
          </div>

          <div v-if="advTarget === 'eventSlotId' && advTarget2 !== null">
            {{ $t('smsTemplate.advancedTarget') }}
            <b-form-group id="to" label-for="to">
              <b-form-select
                v-model="advTarget3"
                :options="advTargetOption3"
                :state="!!advTarget3"
                size="sm"
                class="mt-1"
                required
              ></b-form-select>
            </b-form-group>
          </div>

          <div v-if="advTarget !== 'none'">
            <div v-if="advTargetResult !== null" style="padding: 10px 0px">
              <b-button v-b-toggle.collapse-1 variant="primary" size="sm"
                >對象名單
                <b-badge variant="light"> {{ advTargetResult.length }} </b-badge
                >：</b-button
              >
              <b-collapse id="collapse-1" class="mt-2">
                <b-card>
                  <div v-for="i in advTargetResult" :key="i">
                    <p>
                      Name: {{ i.name }} | Email: {{ i.email }} | Tel:
                      {{ i.tel }}
                    </p>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </div>
          <b-form-group id="subject" label-for="subject">
            <b-tabs content-class="mt-3">
              <b-tab :title="'標題 (繁)'">
                <b-form-input
                  v-model="subject.subject_tc"
                  type="text"
                ></b-form-input>
              </b-tab>
              <b-tab :title="'標題 (簡)'">
                <b-form-input
                  v-model="subject.subject_sc"
                  type="text"
                ></b-form-input>
              </b-tab>
              <b-tab :title="'標題 (英)'">
                <b-form-input
                  v-model="subject.subject_en"
                  type="text"
                ></b-form-input>
              </b-tab>
            </b-tabs>
          </b-form-group>

          <b-form-group id="content" label-for="content">
            <b-tabs content-class="mt-3">
              <b-tab :title="'內容 (繁)'">
                <b-textarea v-model="content.content_tc" rows="5"></b-textarea>
              </b-tab>
              <b-tab :title="'內容 (簡)'">
                <b-textarea v-model="content.content_sc" rows="5"></b-textarea>
              </b-tab>
              <b-tab :title="'內容 (英)'">
                <b-textarea v-model="content.content_en" rows="5"></b-textarea>
              </b-tab>
            </b-tabs>
          </b-form-group>
          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button id="submit" type="submit" variant="outline-success">{{
              '發送通知'
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>

<script>
// import TinymceEditorFlexible from '~/components/admin/tinymceEditorFlexible'
import Notification from '~/components/common/notification'
/* import tools from '~/components/common/tools' */

export default {
  components: {
    Notification,
    // TinymceEditorFlexible,
    /* tools, */
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    action: { type: String, default: 'add' },
    trnId: { type: String, default: '' },
    tempCode: { type: String, default: '' },
    custom: { type: Object, default: null },
    // isShow: { type: Boolean, default: false },
  },
  async fetch() {},
  data() {
    return {
      id: null,
      code: '',
      receiver: '{{users|email}}',
      cc: '',
      bcc: '',
      subject: {
        subject_tc: '',
        subject_sc: '',
        subject_en: '',
      },
      content: {
        content_tc: '',
        content_sc: '',
        content_en: '',
      },
      schoolId: null,
      userId: null,
      testReceiver: '',
      orders: null,
      error: null,
      isShow: false,
      target: 'all',
      targetOption: [
        { value: 'all', text: '全部 All Teacher' },
        { value: 'k', text: '幼稚園 Kindergarten' },
        { value: 'p', text: '小學 Primary' },
        { value: 's', text: '中學 Secondary' },
        { value: 'sp', text: '特殊 Special' },
        { value: 'o', text: '其他 Other' },
      ],
      advTarget: 'none',
      advTargetOption: [
        { value: 'none', text: '沒有 None' },
        { value: 'eventId', text: '活動 Event' },
        { value: 'eventSlotId', text: '活動場次 Event Slot' },
        { value: 'district', text: '區域 District' },
      ],
      advTarget2: null,
      advTargetOption2: [],
      advTarget3: null,
      advTargetOption3: [], // event slot only
      tmp_advTargetResult: [],
      advTargetResult: [],
      advTarget2Filter: null,
      advTarget2FilterOption: [],
    }
  },
  computed: {},
  watch: {
    target: {
      handler(newVal, oldVal) {
        if (this.advTargetResult) {
          this.updateAdvTargetResult(newVal)
        }
      },
      deep: true,
    },
    tmp_advTargetResult: {
      handler(newVal, oldVal) {
        this.updateAdvTargetResult(this.target)
      },
      deep: true,
    },
    advTarget: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          let tmp = newVal
          if (newVal === 'eventSlotId') {
            tmp = 'eventId'
          }
          this.getAdvTarget1(tmp)
        }
      },
      deep: true,
    },
    advTarget2: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          if (this.advTarget === 'eventSlotId') {
            this.getAdvTarget2('eventSlotId', newVal)
          } else if (this.advTarget === 'district') {
            this.getAdvTargetResult('district', newVal)
          } else if (this.advTarget === 'eventId') {
            this.getAdvTargetResult('eventId', newVal)
          }
        }
      },
      deep: true,
    },
    advTarget3: {
      handler(newVal, oldVal) {
        if (newVal !== 'none') {
          if (this.advTarget === 'eventSlotId') {
            this.getAdvTargetResult('eventSlotId', newVal)
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    // if (this.action === 'edit' || this.action === 'send') {
    //   this.isShow = false
    //   $(document).on('focusin', function (e) {
    //     if (
    //       $(e.target).closest(
    //         '.tox-tinymce-aux, .tox-dialog, .moxman-window, .tam-assetmanager-root'
    //       ).length
    //     ) {
    //       e.stopImmediatePropagation()
    //     }
    //   })
    // }
  },
  methods: {
    getAdvTargetOption2() {
      this.advTarget2FilterOption = this.advTarget2Filter
        ? this.advTargetOption2.filter((item) => {
            return item.text
              .toLowerCase()
              .includes(this.advTarget2Filter.toLowerCase())
          })
        : this.advTargetOption2
    },
    async getAdvTarget1(target) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters`,
        {
          type: target,
        }
      )
      this.advTargetOption2 = result.data.data
      this.getAdvTargetOption2()
      this.advTarget2 = null
      this.advTarget3 = null
    },
    async getAdvTarget2(target, eventId) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters`,
        {
          type: target,
          eventId,
        }
      )
      this.advTargetOption3 = result.data.data
      this.advTarget3 = null
    },
    async getAdvTargetResult(target, Id) {
      const result = await this.$axios.post(
        `${process.env.localApiUrl}filters/target`,
        {
          type: target,
          id: Id,
        }
      )
      this.tmp_advTargetResult = result.data.data
      // this.advTargetResult = this.tmp_advTargetResult
    },
    updateAdvTargetResult(target) {
      if (target === 'k') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 1
        )
      } else if (target === 'p') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 2
        )
      } else if (target === 's') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 3
        )
      } else if (target === 'sp') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 4
        )
      } else if (target === 'o') {
        this.advTargetResult = this.tmp_advTargetResult.filter(
          (s) => s.school_type === 5
        )
      } else {
        this.advTargetResult = this.tmp_advTargetResult
      }
    },
    focusTextEditor() {
      this.$nextTick(() => {
        this.isShow = true
      })
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    async submit() {
      try {
        console.log('SEND NOTICE')
        console.log('this.target: ', this.target)
        console.log('this.subject: ', JSON.stringify(this.subject))
        console.log('this.content: ', JSON.stringify(this.content))
        const obj = {
          title: JSON.stringify(this.subject),
          content: JSON.stringify(this.content),
          target: this.target,
          advancedTarget:
            this.advTarget === 'none'
              ? this.targetResult
              : this.advTargetResult,
        }
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}notification/compose/send`,
          obj
        )
        if (result.status) {
          console.log('sent')
          const back = await this.$swal({
            title: this.$t('alert.success'),
            text: '已成功發送通知',
            type: 'success',
            confirmButtonText: this.$t('alert.back'),
            // confirmButtonColor: '#d9534f',
          })
          if (back.value) {
            this.$router.go(-1)
            // this.$router.push(this.localePath('/'))
          }
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}

h1::first-letter {
  text-transform: capitalize;
}

.content_textarea {
  height: 200px;
}

.component {
  text-align: left;
}

.submit {
  margin-bottom: 20px;
}

.form-group {
  margin-top: 5px;
}
</style>
