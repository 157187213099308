var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-sch",
            size: "xl",
            title: "學校資訊",
            "content-class": "modal-result-content",
            centered: "",
            "hide-footer": "",
            "header-bg-variant": "dark",
            "header-text-variant": "light",
            "body-bg-variant": "light",
            "body-text-variant": "dark",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                !_vm.school.id
                  ? _c("div", { staticClass: "red" }, [
                      _vm._v(
                        "\n          此學校未有註冊E-Platform，系統沒有相關學校資料。\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedApplication
                  ? _c("table", { staticClass: "selected-user" }, [
                      _c("tr", [
                        _c("td", [_vm._v("學校名稱")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.school.name_tc
                                  ? _vm.school.name_tc
                                  : _vm.selectedApplication.schoolName
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("學校ID")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.id))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("千年表ID")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.selectedApplication.schoolId)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("地區")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.address_area))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("街道及號碼")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.address_street))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("建築物名稱、樓層")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.address_building))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("區域")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.school.region
                                  ? _vm.regionList.find(
                                      (d) => d.value === _vm.school.region
                                    ).name
                                  : ""
                              ) +
                              "\n              " +
                              _vm._s(
                                _vm.school.district
                                  ? _vm.districtList.find(
                                      (d) => d.value === _vm.school.district
                                    ).name
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("學校類別")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.school.type
                                  ? _vm.typeList.find(
                                      (d) => d.value === _vm.school.type
                                    ).name
                                  : ""
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("學校電話")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.tel))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("學校傳真")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.fax))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("聯絡電郵")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.email))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("校長")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.school.principal_name))]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("b-col", [
                _vm.selectedApplication &&
                _vm.user &&
                (_vm.user.isEditorAdmin || _vm.user.isSuperAdmin)
                  ? _c("div", [
                      _vm._v(
                        "\n          此為匯入申請，沒有註冊用戶資訊\n        "
                      ),
                    ])
                  : _vm.selectedApplication
                  ? _c("table", { staticClass: "selected-user" }, [
                      _c("tr", [
                        _c("td", [_vm._v("用戶名稱")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.user.name))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("用戶ID")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(_vm.selectedApplication.userId)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("學校")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.school.name_tc
                                  ? _vm.school.name_tc
                                  : _vm.selectedApplication.schoolName
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("電話")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.user.tel))]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("電郵")]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(_vm.user.email))]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-user",
            size: "md",
            title: "用戶資訊",
            "content-class": "modal-result-content",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _vm.selectedApplication
            ? _c("table", { staticClass: "selected-user" }, [
                _c("tr", [
                  _c("td", [_vm._v("用戶名稱")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.user.name))]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("用戶ID")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.selectedApplication.userId))]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("學校")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.school.name_tc
                            ? _vm.school.name_tc
                            : _vm.selectedApplication.schoolName
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("電話")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.user.tel))]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("電郵")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.user.email))]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }