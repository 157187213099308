var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "eventTable" }, [
    _vm.event.EventSlot
      ? _c(
          "div",
          { staticClass: "event_detail" },
          [
            _vm.isEdit
              ? _c("ul", { staticClass: "nav nav-tabs" }, [
                  _vm.event.EventSlot.length
                    ? _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "table-type",
                                  options: _vm.options,
                                  name: "type",
                                },
                                on: { change: _vm.setTypeRemark },
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: _vm.lang === 0 ? "active" : "",
                        staticStyle: { cursor: "pointer" },
                        attrs: { "aria-setsize": "3" },
                        on: {
                          click: function ($event) {
                            return _vm.changeLang(0)
                          },
                        },
                      },
                      [_vm._v("內容(英)")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: _vm.lang === 1 ? "active" : "",
                        staticStyle: { cursor: "pointer" },
                        attrs: { "aria-setsize": "3" },
                        on: {
                          click: function ($event) {
                            return _vm.changeLang(1)
                          },
                        },
                      },
                      [_vm._v("內容(繁)")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        class: _vm.lang === 2 ? "active" : "",
                        staticStyle: { cursor: "pointer" },
                        attrs: { "aria-setsize": "3" },
                        on: {
                          click: function ($event) {
                            return _vm.changeLang(2)
                          },
                        },
                      },
                      [_vm._v("內容(簡)")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.event.EventSlot.length
              ? _c("table", [
                  _c("tbody", [
                    _vm.singleVenue
                      ? _c("tr", [
                          !_vm.isEdit
                            ? _c("th", { ref: "th" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("eventTable.date")) +
                                    "\n          "
                                ),
                              ])
                            : _c("th", [
                                _vm._v(_vm._s(_vm.getTxt(_vm.lang, "date"))),
                              ]),
                          _vm._v(" "),
                          _vm.date
                            ? _c(
                                "td",
                                { staticStyle: { position: "relative" } },
                                [
                                  _c("table", { attrs: { width: "100%" } }, [
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.date,
                                        function (d, key, index) {
                                          return _c(
                                            "tr",
                                            {
                                              key: key,
                                              staticClass: "td-time",
                                            },
                                            [
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  {
                                                    style: _vm.isEdit
                                                      ? "max-width: 530px; display: inline-block"
                                                      : "",
                                                  },
                                                  [
                                                    _vm._l(d, function (a, i) {
                                                      return _c(
                                                        "span",
                                                        {
                                                          key: i,
                                                          staticClass:
                                                            "td-time",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm
                                                                  .$moment(
                                                                    a.date
                                                                  )
                                                                  .format("D.M")
                                                              )
                                                          ),
                                                          i !== d.length - 1
                                                            ? _c("span", [
                                                                _vm
                                                                  .$moment(
                                                                    a.date
                                                                  )
                                                                  .format(
                                                                    "Y"
                                                                  ) !==
                                                                _vm
                                                                  .$moment(
                                                                    d[i + 1]
                                                                      .date
                                                                  )
                                                                  .format("Y")
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        "." +
                                                                          _vm._s(
                                                                            _vm
                                                                              .$moment(
                                                                                a.date
                                                                              )
                                                                              .format(
                                                                                "Y"
                                                                              )
                                                                          )
                                                                      ),
                                                                      _c("br"),
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        " | "
                                                                      ),
                                                                    ]),
                                                              ])
                                                            : _vm._e(),
                                                          !d[i + 1]
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "." +
                                                                    _vm._s(
                                                                      _vm
                                                                        .$moment(
                                                                          a.date
                                                                        )
                                                                        .format(
                                                                          "Y"
                                                                        )
                                                                    )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    }),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getBracket(
                                                          _vm.lang,
                                                          "open"
                                                        )
                                                      ) +
                                                        _vm._s(
                                                          _vm.getWeekly(
                                                            _vm.lang,
                                                            d[0].weekday
                                                          )
                                                        ) +
                                                        _vm._s(
                                                          _vm.getBracket(
                                                            _vm.lang,
                                                            "close"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  [
                                                    _c("remark-block", {
                                                      attrs: {
                                                        id: index,
                                                        lang: _vm.lang,
                                                        value:
                                                          _vm.getDateRemark(
                                                            index
                                                          ),
                                                        edit: _vm.isEdit,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.setDateRemark,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.singleVenue
                      ? _c("tr", [
                          !_vm.isEdit
                            ? _c("th", [
                                _vm._v(_vm._s(_vm.$t("eventTable.venue"))),
                              ])
                            : _c("th", [
                                _vm._v(_vm._s(_vm.getTxt(_vm.lang, "venue"))),
                              ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("table", { attrs: { width: "100%" } }, [
                              _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.getVenue(
                                              _vm.lang,
                                              _vm.event.EventSlot[0].venue
                                            )
                                          )
                                      ),
                                      _c("remark-block", {
                                        attrs: {
                                          id: 0,
                                          value: _vm.getVenueRemark(0),
                                          lang: _vm.lang,
                                          edit: _vm.isEdit,
                                        },
                                        on: { change: _vm.setVenueRemark },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ])
                      : _c("tr", [
                          !_vm.isEdit
                            ? _c("th", [
                                _vm._v(
                                  _vm._s(_vm.$t("eventTable.dateVenuePair"))
                                ),
                              ])
                            : _c("th", [
                                _vm._v(_vm._s(_vm.getTxt(_vm.lang, "dvp"))),
                              ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("table", { attrs: { width: "100%" } }, [
                              _c(
                                "tbody",
                                _vm._l(_vm.venueDates, function (vd, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              vd.date.replaceAll("/", ".")
                                            ) +
                                            _vm._s(
                                              _vm.getDNVWeekday(
                                                _vm.lang,
                                                vd.day
                                              )
                                            ) +
                                            "\n                    " +
                                            _vm._s(
                                              _vm.getVenue(_vm.lang, vd.venue)
                                            )
                                        ),
                                        _c("remark-block", {
                                          attrs: {
                                            id: index,
                                            value: _vm.getDNVRemark(index),
                                            lang: _vm.lang,
                                            edit: _vm.isEdit,
                                          },
                                          on: { change: _vm.setDNVRemark },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ]),
                    _vm._v(" "),
                    _c("tr", [
                      !_vm.isEdit
                        ? _c("th", [_vm._v(_vm._s(_vm.$t("eventTable.time")))])
                        : _c("th", [
                            _vm._v(_vm._s(_vm.getTxt(_vm.lang, "time"))),
                          ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("table", { attrs: { width: "100%" } }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.time, function (t, i) {
                              return _c(
                                "tr",
                                { key: i, staticClass: "td-time" },
                                [
                                  _c(
                                    "td",
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm
                                              .$moment(
                                                `2021-01-01 ${t.startTime}`
                                              )
                                              .format("h:mma") === "12:00pm"
                                              ? "12:00nn"
                                              : _vm
                                                  .$moment(
                                                    `2021-01-01 ${t.startTime}`
                                                  )
                                                  .format("h:mma")
                                          ) +
                                          "\n                    -\n                    " +
                                          _vm._s(
                                            _vm
                                              .$moment(
                                                `2021-01-01 ${t.endTime}`
                                              )
                                              .format("h:mma") === "12:00pm"
                                              ? "12:00nn"
                                              : _vm
                                                  .$moment(
                                                    `2021-01-01 ${t.endTime}`
                                                  )
                                                  .format("h:mma")
                                          )
                                      ),
                                      _c("remark-block", {
                                        attrs: {
                                          id: i,
                                          value: _vm.getTimeRemark(i),
                                          lang: _vm.lang,
                                          edit: _vm.isEdit,
                                        },
                                        on: { change: _vm.setTimeRemark },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      !_vm.isEdit
                        ? _c("th", [
                            _vm._v(_vm._s(_vm.$t("eventTable.target"))),
                          ])
                        : _c("th", [
                            _vm._v(_vm._s(_vm.getTxt(_vm.lang, "target"))),
                          ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("table", { attrs: { width: "100%" } }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.target, function (t, i) {
                              return _c("tr", { key: i }, [
                                _c(
                                  "td",
                                  [
                                    _vm._v(
                                      "\n                    " + _vm._s(t)
                                    ),
                                    _c("remark-block", {
                                      attrs: {
                                        id: i,
                                        value: _vm.getTargetRemark(i),
                                        lang: _vm.lang,
                                        edit: _vm.isEdit,
                                      },
                                      on: { change: _vm.setTargetRemark },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.haveTicket
                      ? _c("tr", [
                          !_vm.isEdit
                            ? _c("th", [
                                _vm._v(_vm._s(_vm.$t("eventTable.ticket"))),
                              ])
                            : _c("th", [
                                _vm._v(_vm._s(_vm.getTxt(_vm.lang, "ticket"))),
                              ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("table", { attrs: { width: "100%" } }, [
                              _c(
                                "tbody",
                                [
                                  _vm._l(_vm.ticket, function (t, i) {
                                    return _c(
                                      "tr",
                                      { key: i, staticClass: "td-time" },
                                      [
                                        _c(
                                          "td",
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.getTickets(_vm.lang, t)
                                                )
                                            ),
                                            _c("remark-block", {
                                              attrs: {
                                                id: i,
                                                value: _vm.getTicketRemark(i),
                                                lang: _vm.lang,
                                                edit: _vm.isEdit,
                                              },
                                              on: {
                                                change: _vm.setTicketRemark,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm.ticket.length && _vm.ticket[0].cssa > 0
                                    ? _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(`${_vm.getTxt(
                                                _vm.lang,
                                                "cssaTicketTxt_a"
                                              )}
                      $${_vm.ticket[0].cssa}
                      ${_vm.getTxt(_vm.lang, "cssaTicketTxt_b")}`) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [
                        _vm._v(_vm._s(_vm.getTxt(_vm.lang, "duration"))),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("table", { attrs: { width: "100%" } }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.duration, function (d, i) {
                              return _c(
                                "tr",
                                { key: i, staticClass: "td-time" },
                                [
                                  _c(
                                    "td",
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.getDurationtxt(_vm.lang, d)
                                          )
                                      ),
                                      _c("remark-block", {
                                        attrs: {
                                          id: i,
                                          value: _vm.getDuraRemark(i),
                                          lang: _vm.lang,
                                          edit: _vm.isEdit,
                                        },
                                        on: { change: _vm.setDuraRemark },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.getTxt(_vm.lang, "quota")))]),
                      _vm._v(" "),
                      _c("td", [
                        _c("table", { attrs: { width: "100%" } }, [
                          _c(
                            "tbody",
                            _vm._l(_vm.quota, function (t, i) {
                              return _c("tr", { key: i }, [
                                _c(
                                  "td",
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.numberFormat(t))
                                    ),
                                    _c("remark-block", {
                                      attrs: {
                                        id: i,
                                        value: _vm.getQuotaRemark(i),
                                        lang: _vm.lang,
                                        edit: _vm.isEdit,
                                      },
                                      on: { change: _vm.setQuotaRemark },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("EventTableAdditionalRow", {
              attrs: {
                "impl-table-data": _vm.additional ? _vm.additional : [],
                lang: _vm.lang,
                edit: _vm.isEdit,
                "have-event-slot": _vm.haveEventSlot,
                "first-td-width":
                  !_vm.isEdit && _vm.isMounted && _vm.$refs.th
                    ? _vm.$refs.th.clientWidth
                    : 110,
              },
              model: {
                value: _vm.additional,
                callback: function ($$v) {
                  _vm.additional = $$v
                },
                expression: "additional",
              },
            }),
            _vm._v(" "),
            _vm.event.table_remark && !_vm.isEdit
              ? _c("div", {
                  attrs: { fluid: "sm" },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.i18nContent(JSON.parse(_vm.event.table_remark))
                    ),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.event.EventSlot.length && !_vm.isEdit && _vm.showApplyBtn
              ? _c("div", { staticClass: "event-btn" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-apply",
                      on: {
                        click: function ($event) {
                          return _vm.apply()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("event.apply")) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }