<template>
  <div>
    <b-modal
      id="modal-sch"
      size="xl"
      title="學校資訊"
      content-class="modal-result-content"
      centered
      hide-footer
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="light"
      body-text-variant="dark"
    >
      <!-- <p>
        {{ selectedApplication.trnId }} | {{ selectedApplication.schoolName }}
      </p> -->
      <b-row>
        <b-col>
          <div v-if="!school.id" class="red">
            此學校未有註冊E-Platform，系統沒有相關學校資料。
          </div>
          <table v-if="selectedApplication" class="selected-user">
            <tr>
              <td>學校名稱</td>
              <td>
                {{
                  school.name_tc
                    ? school.name_tc
                    : selectedApplication.schoolName
                }}
              </td>
            </tr>
            <tr>
              <td>學校ID</td>
              <td>{{ school.id }}</td>
            </tr>
            <tr>
              <td>千年表ID</td>
              <td>{{ selectedApplication.schoolId }}</td>
            </tr>
            <tr>
              <td>地區</td>
              <td>{{ school.address_area }}</td>
            </tr>
            <tr>
              <td>街道及號碼</td>
              <td>{{ school.address_street }}</td>
            </tr>
            <tr>
              <td>建築物名稱、樓層</td>
              <td>{{ school.address_building }}</td>
            </tr>
            <tr>
              <td>區域</td>
              <td>
                {{
                  school.region
                    ? regionList.find((d) => d.value === school.region).name
                    : ''
                }}
                {{
                  school.district
                    ? districtList.find((d) => d.value === school.district).name
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <td>學校類別</td>
              <td>
                {{
                  school.type
                    ? typeList.find((d) => d.value === school.type).name
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <td>學校電話</td>
              <td>{{ school.tel }}</td>
            </tr>
            <tr>
              <td>學校傳真</td>
              <td>{{ school.fax }}</td>
            </tr>
            <tr>
              <td>聯絡電郵</td>
              <td>{{ school.email }}</td>
            </tr>
            <tr>
              <td>校長</td>
              <td>{{ school.principal_name }}</td>
            </tr>
            <!-- <tr>
              <td>SPAPS曾參與計劃</td>
              <td>{{ selectedApplication.joinHistory }}</td>
            </tr> -->
          </table>
        </b-col>
        <b-col>
          <!-- <div
            v-if="
              selectedApplication &&
              user &&
              (user.isEditorAdmin || user.isSuperAdmin)
            "
          >
            Imported by: {{ selectedApplication.user_name }} at
            {{ selectedApplication }}
            <br />{{ selectedApplication.additionTeachers }}
          </div> -->
          <div
            v-if="
              selectedApplication &&
              user &&
              (user.isEditorAdmin || user.isSuperAdmin)
            "
          >
            此為匯入申請，沒有註冊用戶資訊
          </div>
          <table v-else-if="selectedApplication" class="selected-user">
            <tr>
              <td>用戶名稱</td>
              <td>{{ user.name }}</td>
            </tr>
            <tr>
              <td>用戶ID</td>
              <td>{{ selectedApplication.userId }}</td>
            </tr>
            <tr>
              <td>學校</td>
              <td>
                {{
                  school.name_tc
                    ? school.name_tc
                    : selectedApplication.schoolName
                }}
              </td>
            </tr>
            <tr>
              <td>電話</td>
              <td>{{ user.tel }}</td>
            </tr>
            <tr>
              <td>電郵</td>
              <td>{{ user.email }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-user"
      size="md"
      title="用戶資訊"
      content-class="modal-result-content"
      centered
      hide-footer
    >
      <!-- <p>
        {{ selectedApplication.user_name }} @
        {{ selectedApplication.schoolName }}
      </p> -->
      <table v-if="selectedApplication" class="selected-user">
        <tr>
          <td>用戶名稱</td>
          <td>{{ user.name }}</td>
        </tr>
        <tr>
          <td>用戶ID</td>
          <td>{{ selectedApplication.userId }}</td>
        </tr>
        <tr>
          <td>學校</td>
          <td>
            {{
              school.name_tc ? school.name_tc : selectedApplication.schoolName
            }}
          </td>
        </tr>
        <tr>
          <td>電話</td>
          <td>{{ user.tel }}</td>
        </tr>
        <tr>
          <td>電郵</td>
          <td>{{ user.email }}</td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['selectedApplication'],
  async fetch() {
    if (this.selectedApplication && this.selectedApplication.schoolId) {
      try {
        const result = await this.$axios.get(
          `${process.env.localApiUrl}schools/sarid/${this.selectedApplication.schoolId}`
        )
        if (result.data.data.length) this.school = result.data.data[0]
      } catch (err) {}
    }
    if (this.selectedApplication && this.selectedApplication.userId) {
      try {
        // TODO: map user from cloud to local
        const result = await this.$axios.get(
          `${process.env.localApiUrl}users/${this.selectedApplication.userId}`
        )
        if (result.data.data.length) this.user = result.data.data[0]
      } catch (err) {}
    }
  },
  data() {
    return {
      school: {},
      user: {},
      typeList: [
        { value: 1, name: '幼稚園' },
        { value: 2, name: '小學' },
        { value: 3, name: '中學' },
        { value: 4, name: '特殊學校' },
        { value: 5, name: '其他（請註明）' },
      ],
      regionList: [
        { value: 'hk', name: '香港島' },
        { value: 'kln', name: '九龍區' },
        { value: 'nt', name: '新界區' },
      ],
      districtList: [
        {
          value: 0,
          name: this.$t('school.districtList.select'),
          disabled: true,
        },
        { value: 1, name: '中西區 Central & Western District' },
        { value: 2, name: '東區 Eastern District' },
        { value: 3, name: '南區 Southern District' },
        { value: 4, name: '灣仔區 Wanchai District' },
        { value: 5, name: '九龍城區 Kowloon City District' },
        { value: 6, name: '觀塘區 Kuwn Tong District' },
        { value: 7, name: '深水埗區 Sham Shui Po District' },
        { value: 8, name: '黃大仙區 Wong Tai Sin District' },
        { value: 9, name: '油尖旺區 Yau Tsim Mong District' },
        { value: 10, name: '離島區 Islands District' },
        { value: 11, name: '葵青區 Kwai Tsing District' },
        { value: 12, name: '北區 North District' },
        { value: 13, name: '西貢區 Sai Kung District' },
        { value: 14, name: '沙田區 Shatin District' },
        { value: 15, name: '大埔區 Tai Po District' },
        { value: 16, name: '荃灣區 Tsuen Wan District' },
        { value: 17, name: '屯門區 Tuen Mun District' },
        { value: 18, name: '元朗區 Yuen Long District' },
      ],
    }
  },
  computed: {},
  watch: {
    selectedApplication: {
      handler(newVal, oldVal) {
        this.school = {}
        this.user = {}
        this.getSchoolAndUser()
      },
      deep: true,
    },
  },
  methods: {
    async getSchoolAndUser() {
      if (this.selectedApplication && this.selectedApplication.schoolId) {
        try {
          const result = await this.$axios.get(
            `${process.env.localApiUrl}schools/sarid/${this.selectedApplication.schoolId}`
          )
          if (result.data.data.length) this.school = result.data.data[0]
        } catch (err) {}
      }
      if (this.selectedApplication && this.selectedApplication.userId) {
        try {
          // TODO: map user from cloud to local
          const result = await this.$axios.get(
            `${process.env.localApiUrl}users/${this.selectedApplication.userId}`
          )
          if (result.data.data.length) this.user = result.data.data[0]
        } catch (err) {}
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.selected-user {
  width: 100%;
  // border: #3f3f3f 1px solid;
  color: #3f3f3f;
  background-color: white;
}
.selected-user > tr > td {
  line-height: 2;
  padding: 5px 20px;
}
.selected-user > tr > td:nth-child(1) {
  width: 175px;
  min-width: 175px;
}
</style>
