var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-card", {
    staticClass: "mb-2",
    staticStyle: { "max-width": "20rem" },
    attrs: {
      title: _vm.name,
      "img-src": _vm.img,
      "img-alt": _vm.name,
      "img-top": "",
      tag: "article",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }