<template>
  <div ref="eventTable">
    <div v-if="event.EventSlot" class="event_detail">
      <ul v-if="isEdit" class="nav nav-tabs">
        <div v-if="event.EventSlot.length" style="width: 100%">
          <b-form-group>
            <b-form-radio-group
              id="table-type"
              v-model="selected"
              :options="options"
              name="type"
              @change="setTypeRemark"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <li class="nav-item">
          <a
            aria-setsize="3"
            class="nav-link"
            :class="lang === 0 ? 'active' : ''"
            style="cursor: pointer"
            @click="changeLang(0)"
            >內容(英)</a
          >
        </li>
        <li class="nav-item">
          <a
            aria-setsize="3"
            class="nav-link"
            :class="lang === 1 ? 'active' : ''"
            style="cursor: pointer"
            @click="changeLang(1)"
            >內容(繁)</a
          >
        </li>
        <li class="nav-item">
          <a
            aria-setsize="3"
            class="nav-link"
            :class="lang === 2 ? 'active' : ''"
            style="cursor: pointer"
            @click="changeLang(2)"
            >內容(簡)</a
          >
        </li>
        <!---->
      </ul>

      <table v-if="event.EventSlot.length">
        <tbody>
          <tr v-if="singleVenue">
            <th v-if="!isEdit" ref="th">
              {{ $t('eventTable.date') }}
            </th>
            <th v-else>{{ getTxt(lang, 'date') }}</th>
            <td v-if="date" style="position: relative">
              <table width="100%">
                <tbody>
                  <tr
                    v-for="(d, key, index) in date"
                    :key="key"
                    class="td-time"
                  >
                    <td>
                      <div
                        :style="
                          isEdit
                            ? 'max-width: 530px; display: inline-block'
                            : ''
                        "
                      >
                        <span v-for="(a, i) in d" :key="i" class="td-time">
                          {{ $moment(a.date).format('D.M')
                          }}<span v-if="i !== d.length - 1">
                            <!-- show year and add <br /> only if have next date is not on the same year--><span
                              v-if="
                                $moment(a.date).format('Y') !==
                                $moment(d[i + 1].date).format('Y')
                              "
                              >.{{ $moment(a.date).format('Y') }}<br /></span
                            ><span v-else>&ensp;|&ensp;</span></span
                          ><!-- or show year if no next date --><span
                            v-if="!d[i + 1]"
                            >.{{ $moment(a.date).format('Y') }}</span
                          ></span
                        >{{ getBracket(lang, 'open')
                        }}{{ getWeekly(lang, d[0].weekday)
                        }}{{ getBracket(lang, 'close') }}
                      </div>
                      <span>
                        <remark-block
                          :id="index"
                          :lang="lang"
                          :value="getDateRemark(index)"
                          :edit="isEdit"
                          @change="setDateRemark"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr v-if="singleVenue">
            <th v-if="!isEdit">{{ $t('eventTable.venue') }}</th>
            <th v-else>{{ getTxt(lang, 'venue') }}</th>
            <td>
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      {{ getVenue(lang, event.EventSlot[0].venue)
                      }}<remark-block
                        :id="0"
                        :value="getVenueRemark(0)"
                        :lang="lang"
                        :edit="isEdit"
                        @change="setVenueRemark"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr v-else>
            <th v-if="!isEdit">{{ $t('eventTable.dateVenuePair') }}</th>
            <th v-else>{{ getTxt(lang, 'dvp') }}</th>
            <td>
              <table width="100%">
                <tbody>
                  <tr v-for="(vd, index) in venueDates" :key="index">
                    <td>
                      {{ vd.date.replaceAll('/', '.')
                      }}{{ getDNVWeekday(lang, vd.day) }}
                      {{ getVenue(lang, vd.venue)
                      }}<remark-block
                        :id="index"
                        :value="getDNVRemark(index)"
                        :lang="lang"
                        :edit="isEdit"
                        @change="setDNVRemark"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th v-if="!isEdit">{{ $t('eventTable.time') }}</th>
            <th v-else>{{ getTxt(lang, 'time') }}</th>
            <td>
              <table width="100%">
                <tbody>
                  <tr v-for="(t, i) in time" :key="i" class="td-time">
                    <td>
                      {{
                        $moment(`2021-01-01 ${t.startTime}`).format('h:mma') ===
                        '12:00pm'
                          ? '12:00nn'
                          : $moment(`2021-01-01 ${t.startTime}`).format('h:mma')
                      }}
                      -
                      {{
                        $moment(`2021-01-01 ${t.endTime}`).format('h:mma') ===
                        '12:00pm'
                          ? '12:00nn'
                          : $moment(`2021-01-01 ${t.endTime}`).format('h:mma')
                      }}<remark-block
                        :id="i"
                        :value="getTimeRemark(i)"
                        :lang="lang"
                        :edit="isEdit"
                        @change="setTimeRemark"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <th v-if="!isEdit">{{ $t('eventTable.target') }}</th>
            <th v-else>{{ getTxt(lang, 'target') }}</th>
            <td>
              <table width="100%">
                <tbody>
                  <tr v-for="(t, i) in target" :key="i">
                    <td>
                      {{ t
                      }}<remark-block
                        :id="i"
                        :value="getTargetRemark(i)"
                        :lang="lang"
                        :edit="isEdit"
                        @change="setTargetRemark"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr v-if="haveTicket">
            <th v-if="!isEdit">{{ $t('eventTable.ticket') }}</th>
            <th v-else>{{ getTxt(lang, 'ticket') }}</th>
            <td>
              <table width="100%">
                <tbody>
                  <tr v-for="(t, i) in ticket" :key="i" class="td-time">
                    <td>
                      {{ getTickets(lang, t)
                      }}<remark-block
                        :id="i"
                        :value="getTicketRemark(i)"
                        :lang="lang"
                        :edit="isEdit"
                        @change="setTicketRemark"
                      />
                    </td>
                  </tr>
                  <tr v-if="ticket.length && ticket[0].cssa > 0">
                    <td>
                      {{
                        `${getTxt(lang, 'cssaTicketTxt_a')}
                        $${ticket[0].cssa}
                        ${getTxt(lang, 'cssaTicketTxt_b')}`
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <div style="float: right">
                <b-button
                  variant="outline-success"
                  size="sm"
                  @click="addEmptyObjForRemark(ticket)"
                >
                  新增備註
                </b-button>
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="delEmptyObjForRemark(ticket)"
                >
                  刪除備註
                </b-button>
              </div> -->
            </td>
          </tr>

          <tr>
            <th>{{ getTxt(lang, 'duration') }}</th>
            <td>
              <table width="100%">
                <tbody>
                  <tr v-for="(d, i) in duration" :key="i" class="td-time">
                    <td>
                      {{ getDurationtxt(lang, d)
                      }}<remark-block
                        :id="i"
                        :value="getDuraRemark(i)"
                        :lang="lang"
                        :edit="isEdit"
                        @change="setDuraRemark"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th>{{ getTxt(lang, 'quota') }}</th>
            <td>
              <table width="100%">
                <tbody>
                  <tr v-for="(t, i) in quota" :key="i">
                    <td>
                      {{ numberFormat(t)
                      }}<remark-block
                        :id="i"
                        :value="getQuotaRemark(i)"
                        :lang="lang"
                        :edit="isEdit"
                        @change="setQuotaRemark"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <EventTableAdditionalRow
        v-model="additional"
        :impl-table-data="additional ? additional : []"
        :lang="lang"
        :edit="isEdit"
        :have-event-slot="haveEventSlot"
        :first-td-width="
          !isEdit && isMounted && $refs.th ? $refs.th.clientWidth : 110
        "
      />
      <div
        v-if="event.table_remark && !isEdit"
        fluid="sm"
        v-html="i18nContent(JSON.parse(event.table_remark))"
      ></div>
      <!-- <div
        v-if="
          event.EventSlot.length && !isEdit && showApplyBtn && eventStatus === 0
        "
        class="event-btn"
      > -->
      <div
        v-if="event.EventSlot.length && !isEdit && showApplyBtn"
        class="event-btn"
      >
        <div class="btn-apply" @click="apply()">
          {{ $t('event.apply') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import remarkBlock from '~/components/event/remarkBlock'

export default {
  name: 'EventTable',
  components: { remarkBlock },
  props: {
    event: Object,
    additional: Array,
    remark: {
      type: Object,
      default: () => ({
        date: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        venue: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        dnv: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        time: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        dura: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        target: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        quota: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        ticket: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
        type: 'P',
      }),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    showApplyBtn: {
      type: Boolean,
      default: true,
    },
    eventStatus: Number,
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/venue`
      )
      this.venue = result.data.data
    } catch (err) {}
    this.getDate()
    this.getTime()
    this.isSingleVenue()
    this.getDuration()
    this.getTarget()
    this.getQuota()
    this.getTicket()
    this.getTypeRemark()
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/public/getList`
      )
      this.schemeAll = result.data.data
    } catch (err) {}
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/event`
      )
      this.eventAll = result.data.data
    } catch (err) {}
    // console.log('eT', this.remark.venue, this.remark.venue.length)
  },
  data() {
    return {
      isMounted: false,
      lang: this.i18nLang(),
      venue: [],
      duration: [],
      subevents: [],
      quota: '',
      singleVenue: true,
      venueDates: [],
      time: '',
      date: '',
      target: [],
      index: 0,
      ticket: [],

      selected: 'P',
      options: [
        { text: 'Performance', value: 'P' },
        { text: 'Activity', value: 'A' },
      ],

      fields: [
        { key: 'dateVenuePair', label: this.$t('eventTable.dateVenuePair') },
        { key: 'date', label: this.$t('eventTable.date') },
        { key: 'venue', label: this.$t('eventTable.venue') },
        { key: 'time', label: this.$t('eventTable.time') },
        { key: 'duration', label: this.$t('eventTable.duration') },
        { key: 'target', label: this.$t('eventTable.target') },
        { key: 'ticket', label: this.$t('eventTable.ticket') },
        { key: 'quota', label: this.$t('eventTable.quota') },
      ],
      items: [
        {
          round: this.$t('scheme.round.round1'),
          startDate: null,
          endDate: null,
        },
      ],
      schemeAll: [],
      eventAll: [],
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
    haveTicket() {
      let check = false
      // console.log(this.ticket)
      check = this.ticket.some((d) => {
        if (d) {
          if (typeof d === 'string') d = JSON.parse(d)
          // console.log(!!d.student, !!d.teacher, !!d.cssa)
          // some: break if true is return, else continue loop
          return !!d.student || !!d.teacher || !!d.cssa || !!d.parent
        } else return false
      })
      return check
    },
    firstTdWidth() {
      // get td width of upper table
      return this.isMounted && this.$refs.th ? this.$refs.th.clientWidth : 110
    },
    haveEventSlot() {
      return !!this.event.EventSlot.length
    },
  },
  watch: {
    event: {
      handler(newVal, oldVal) {
        this.getDate()
        this.getTime()
        this.isSingleVenue()
        this.getDuration()
        this.getTicket()
        this.getTarget()
        this.getQuota()
        this.$forceUpdate()
        this.getEditableTypeRemark()
      },
      deep: true,
    },
    remark: {
      handler(newVal, oldVal) {},
      deep: true,
    },
    venueDates: {
      handler(newVal, oldVal) {
        this.remarkBalance(this.remark.dnv, newVal)
      },
      deep: true,
    },
    date: {
      handler(newVal, oldVal) {
        this.remarkBalance(this.remark.date, newVal)
      },
      deep: true,
    },
    time: {
      handler(newVal, oldVal) {
        this.remarkBalance(this.remark.time, newVal)
      },
      deep: true,
    },
    ticket: {
      handler(newVal, oldVal) {
        if (this.remark.ticket) this.remarkBalance(this.remark.ticket, newVal)
      },
      deep: true,
    },
    dura: {
      handler(newVal, oldVal) {
        this.remarkBalance(this.remark.dura, newVal)
      },
      deep: true,
    },
    target: {
      handler(newVal, oldVal) {
        this.remarkBalance(this.remark.target, newVal)
      },
      deep: true,
    },
    quota: {
      handler(newVal, oldVal) {
        this.remarkBalance(this.remark.quota, newVal)
      },
      deep: true,
    },
    lang: {
      handler(newVal, oldVal) {
        this.getTarget()
      },
      deep: true,
    },
  },
  mounted() {
    this.matchWidth()
  },
  methods: {
    matchWidth() {
      this.isMounted = true
    },
    getParentSchemeId() {
      const event = this.$route.params.id
        ? this.$route.params.id
        : this.$route.params.code

      if (event) {
        const subscheme = this.eventAll.find((e) => e.id === parseInt(event))
        if (subscheme) {
          const result = this.schemeAll.find(
            (s) => s.id === parseInt(subscheme.scheme)
          )
          if (result) {
            return result.parent
          }
        }
      } else return 1
    },
    getTickets(lang, t) {
      if (t === '') return ''

      const array = Object.keys(t).reduce((arr = [], e) => {
        const price = t[e]
        const found = arr.find((a) => a.price === price)
        if (!!price && e !== 'cssa') {
          if (!found) arr.push({ price, type: [`${this.getTxt(lang, e)}`] })
          else found.type.push(`${this.getTxt(lang, e)}`)
        }
        return arr
      }, [])
      // let b = Object.keys(a).map(e => a[e])

      const openBracket = `${this.getTxt(lang, 'openBracket')}`
      const closeBracket = `${this.getTxt(lang, 'closeBracket')}`
      const comma = `${this.getTxt(lang, 'comma')}`
      const commaC = `${this.getTxt(lang, 'commachi')}`

      const result = array.map(
        (d) =>
          `$${d.price}${openBracket}${d.type.join(`${commaC}`)}${closeBracket}`
      )
      if (result.length) return result.join(`${comma}`)
      return 'null'
    },
    getTxt(lang, field) {
      switch (field) {
        case 'date':
          return lang === 0 ? 'Date' : '日期'
        case 'venue':
          return lang === 0 ? 'Venue' : lang === 1 ? '地點' : '地点'
        case 'dvp':
          return lang === 0
            ? 'Date and Venue'
            : lang === 1
            ? '日期及地點'
            : '日期及地点'
        case 'time':
          return lang === 0 ? 'Time' : lang === 1 ? '時間' : '时间'
        // case 'duration':
        //   return lang === 0 ? 'Duration' : lang === 1 ? '節目需時' : '节目需时'
        case 'duration':
          return lang === 0 ? 'Duration' : lang === 1 ? '節目長度' : '节目长度'

        case 'target':
          return lang === 0 ? 'Target' : lang === 1 ? '對象' : '对象'
        case 'quota':
          return lang === 0
            ? this.selected === 'A'
              ? 'Capacity per activity'
              : 'Capacity per performance'
            : lang === 1
            ? '每場名額'
            : '每场名额'
        case 'ticket':
          return lang === 0 ? 'Ticket price' : lang === 1 ? '票價' : '票价'
        case 'student':
          return lang === 0 ? 'Students' : lang === 1 ? '學生' : '学生'
        case 'teacher':
          return lang === 0 ? 'Teachers' : lang === 1 ? '老師' : '老师'
        case 'parent':
          return lang === 0 ? 'Parents' : lang === 1 ? '家長' : '家长'
        case 'openBracket':
          return lang === 0 ? ' (' : '（'
        case 'closeBracket':
          return lang === 0 ? ')' : '）'
        case 'comma':
          return lang === 0 ? ', ' : '，'
        case 'commachi':
          return lang === 0 ? ', ' : '、'
        case 'cssaTicketTxt_a':
          return lang === 0
            ? '* Tickets at'
            : lang === 1
            ? '* 綜合社會保障援助受惠人士可享優惠票價'
            : '* 综合社会保障援助受惠人士可享优惠票价'
        case 'cssaTicketTxt_b':
          return lang === 0
            ? 'are available for Comprehensive Social Security Assistance (CSSA) recipients on a first-come-first-served basis.'
            : lang === 1
            ? '，數量有限，先到先得， 額滿即止。'
            : '，数量有限，先到先得， 额满即止。'
        case 'hour':
          return lang === 0 ? ' hour ' : lang === 1 ? '小時' : '小时'
        case 'hours':
          return lang === 0 ? ' hours ' : lang === 1 ? '小時' : '小时'
        case 'min':
          return lang === 0 ? ' minutes ' : lang === 1 ? '分鐘' : '分钟'
        case 'sec':
          return lang === 0 ? ' seconds ' : '秒'
        case 'to':
          return lang === 0 ? ' - ' : '至'
        default:
          return ''
      }
    },
    numberFormat(data) {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    apply() {
      if (this.loggedIn) {
        this.$emit('input', this.event)
        this.$bvModal.show('modal-1')
      } else {
        this.$router.push(this.localePath('/login'))
      }
    },
    remarkBalance(type, result) {
      const resultLength = Array.isArray(result)
        ? result.length
        : Object.keys(result).length

      while (resultLength > type.length) {
        type.push({
          text: { en: '', tc: '', sc: '' },
          color: 'default',
        })
      }
      while (type.length > resultLength) {
        type.pop()
      }
    },
    changeLang(lang) {
      this.lang = lang
    },
    setRemark() {
      if (!this.remark) {
        this.remark = {
          date: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          venue: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          dnv: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          time: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          dura: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          target: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          quota: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          ticket: [{ text: { en: '', tc: '', sc: '' }, color: 'default' }],
          type: 'P',
        }
      }
    },
    setPropCont(props, index) {
      if (props == null) {
        props = []
      }
      if (props.length < index) {
        props.push({
          text: { en: '', tc: '', sc: '' },
          color: 'default',
        })
      }
      return props[index]
    },
    getTypeRemark() {
      this.setRemark()
      this.selected = JSON.parse(this.event.remark).type ?? 'P'
    },
    getEditableTypeRemark() {
      this.setRemark()
      this.selected = this.remark.type ?? 'P'
    },
    getDateRemark(index) {
      this.setRemark()
      return this.setPropCont(this.remark.date, index)
    },
    getVenueRemark(index) {
      this.setRemark()
      return this.setPropCont(this.remark.venue, index)
    },
    getDNVRemark(index) {
      this.setRemark()
      return this.setPropCont(this.remark.dnv, index)
    },
    getTimeRemark(index) {
      this.setRemark()
      return this.setPropCont(this.remark.time, index)
    },
    getDuraRemark(index) {
      this.setRemark()
      return this.setPropCont(this.remark.dura, index)
    },
    getTargetRemark(index) {
      this.setRemark()
      return this.setPropCont(this.remark.target, index)
    },
    getQuotaRemark(index) {
      return this.setPropCont(this.remark.quota, index)
    },
    getTicketRemark(index) {
      return this.setPropCont(this.remark.ticket, index)
    },
    setTypeRemark() {
      this.remark.type = this.selected
      this.$emit('change', this.remark)
    },
    setDateRemark(id, newVal) {
      this.remark.date[id] = newVal
      this.$emit('change', this.remark)
    },
    setVenueRemark(id, newVal) {
      this.remark.venue[id] = newVal
      this.$emit('change', this.remark)
    },
    setDNVRemark(id, newVal) {
      this.remark.dnv[id] = newVal
      this.$emit('change', this.remark)
    },
    setTimeRemark(id, newVal) {
      this.remark.time[id] = newVal
      this.$emit('change', this.remark)
    },
    setDuraRemark(id, newVal) {
      this.remark.dura[id] = newVal
      this.$emit('change', this.remark)
    },
    setTargetRemark(id, newVal) {
      this.remark.target[id] = newVal
      this.$emit('change', this.remark)
    },
    setQuotaRemark(id, newVal) {
      this.remark.quota[id] = newVal
      this.$emit('change', this.remark)
    },
    setTicketRemark(id, newVal) {
      this.remark.ticket[id] = newVal
      this.$emit('change', this.remark)
    },
    // i18n
    i18nTarget(lang) {
      switch (lang) {
        case 0:
          return ' Students'
        case 1:
          return '學生'
        case 2:
          return '学生'
        default:
          return ''
      }
    },
    i18nTargetTK(lang) {
      switch (lang) {
        case 0:
          return 'Kindergarten Teachers'
        case 1:
          return '幼稚園老師'
        case 2:
          return '幼稚园老师'
        default:
          return ''
      }
    },
    i18nTargetTP(lang) {
      switch (lang) {
        case 0:
          return 'Primary Teachers'
        case 1:
          return '小學老師'
        case 2:
          return '小学老师'
        default:
          return ''
      }
    },
    i18nTargetTS(lang) {
      switch (lang) {
        case 0:
          return 'Secondary Teachers'
        case 1:
          return '中學老師'
        case 2:
          return '中学老师'
        default:
          return ''
      }
    },
    i18nLang() {
      switch (this.$i18n.locale) {
        case 'en':
          return 0
        case 'tc':
          return 1
        case 'sc':
          return 2
        default:
          return 1
      }
    },
    i18nLangTxt(lang) {
      switch (lang) {
        case 0:
          return 'en'
        case 1:
          return 'tc'
        case 2:
          return 'sc'
        default:
          return ''
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    // others
    dateNextLine(date, nextDate) {
      return date && nextDate
        ? date.slice(0, 4) !== nextDate.slice(0, 4)
        : false
    },
    changeSubevent(i) {
      this.indexSubEvent = i
    },
    getDate() {
      const result = this.event.EventSlot.reduce((acc = [], d) => {
        const found = acc.find((a) => a.date === d.date)
        if (!found) {
          acc.push({
            date: d.date,
            weekday: new Date(d.date).toString().slice(0, 3), // this.$moment(d.date).isoWeekday(), // new Date(d.date).getDay(),
            year_weekday: `${new Date(d.date)
              .toString()
              .slice(11, 15)}_${new Date(d.date).toString().slice(0, 3)}`,
          }) // not found, so need to add data property
        } else if (!found.date.includes(d.date)) {
          found.date.push(d.date)
        }
        return acc
      }, [])
      // console.log('1', result)
      this.date = result.sort((a, b) =>
        a.date > b.date ? 1 : b.date > a.date ? -1 : 0
      )

      // console.log('2: sort', this.date)

      const datearr = this.date

      const result2 = datearr.reduce(function (r, a) {
        r[a.year_weekday] = r[a.year_weekday] || []
        r[a.year_weekday].push(a)
        return r
      }, Object.create(null))

      // console.log('3:', result2)

      this.date = result2

      console.log('date', this.date)
    },
    getTime() {
      const result = this.event.EventSlot.reduce((acc = [], d) => {
        const found = acc.find(
          (a) =>
            a.startTime.slice(0, 5) === d.startTime.slice(0, 5) &&
            a.endTime.slice(0, 5) === d.endTime.slice(0, 5)
          // .slice(0, 5) as may appear 14:30 or 14:30:00
        )
        if (!found) {
          acc.push({ startTime: d.startTime, endTime: d.endTime }) // not found, so need to add data property
        }
        return acc
      }, [])
      this.time = result

      if (result.length > 0) {
        this.time = result.sort(
          // (a, b) => (a.endTime > b.endTime ? 1 : b.endTime > a.endTime ? -1 : 0)
          (a, b) =>
            a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
        )
      }
      this.getDuration()
    },

    addEmptyObjForRemark(target) {
      // cssa price: leave a blank line for abo to type remark
      this.ticket.push('')
    },
    delEmptyObjForRemark(target) {
      const lastObj = target[target.length - 1]
      if (lastObj === '') this.ticket.pop()
    },
    // --- Generate ticket price ---
    getTicket() {
      const result = this.event.EventSlot.reduce((acc = [], d) => {
        const string = typeof d.fee !== 'string' ? JSON.stringify(d.fee) : d.fee
        const found = acc.find((d) => {
          // console.log(JSON.stringify(d), string, JSON.stringify(d) === string)
          return JSON.stringify(d) === string
        })
        if (!found) acc.push(JSON.parse(string))
        return acc
      }, [])

      // cssa price: leave a blank line for abo to type remark
      /* this.ticket.some((d) => {
        // console.log(d, d && d.cssa)
        if (d && d.cssa) {
          result.push('')
          return true
        } else return false
      }) */
      this.ticket = result
    },

    getTarget() {
      // k1: -2, k2: -1, k3: 0,
      // p1: 1, p2: 2, p3: 3, p4: 4, p5: 5, p6: 6
      // f1: 7, f2: 8, f3: 9, f4: 10, f5: 11, f6: 12
      const eventslot = [...this.event.EventSlot].sort((a, b) => {
        return a.id - b.id
      })
      // const eventslot = this.event.EventSlot
      const result = eventslot.reduce((acc = [], d) => {
        const string = this.formatTarget(d.target.toString())
        /* const string = this.gradeToInt(d.target.toString()) */

        const found = acc.find(
          // compare 2 array
          (a) => JSON.stringify(a) === JSON.stringify(string)
        )
        if (!found) acc.push(string)
        return acc
      }, [])

      /* result = result.sort((a, b) => a[0] - b[0])
      const resultArrInTxt = []
      result.forEach((d) => {
        resultArrInTxt.push(this.formatTarget(d))
      })
      this.target = resultArrInTxt */
      this.target = result
      return null
    },
    gradeToInt(t) {
      const targetArray = t.split(',')
      let targetArrayInInt = []
      targetArray.map((t) => {
        if (t[0] === 'k') targetArrayInInt.push(parseInt(t[1]) - 3)
        if (t[0] === 'p') targetArrayInInt.push(parseInt(t[1]))
        if (t[0] === 'f') targetArrayInInt.push(parseInt(t[1]) + 6)
      })
      // console.log('targetArrayInInt ' + targetArrayInInt)

      targetArrayInInt = targetArrayInInt.sort((a, b) =>
        a > b ? 1 : b > a ? -1 : 0
      )
      return targetArrayInInt
    },
    formatTarget(t) {
      // console.log('tar: ', t)
      let result = ''
      const targetArray = t.split(',')
      let targetArrayInInt = []
      targetArray.map((t) => {
        if (t[0] === 'k') targetArrayInInt.push(parseInt(t[1]) - 3) // e.g. k1 = -2
        if (t[0] === 'p') targetArrayInInt.push(parseInt(t[1]))
        if (t[0] === 'f') targetArrayInInt.push(parseInt(t[1]) + 6) // e.g. f1 = 7
      })
      // console.log('targetArrayInInt ' + targetArrayInInt)

      targetArrayInInt = targetArrayInInt.sort((a, b) =>
        a > b ? 1 : b > a ? -1 : 0
      )
      const check =
        targetArrayInInt[0] + targetArrayInInt.length - 1 ===
        targetArrayInInt[targetArrayInInt.length - 1]

      if (check) {
        // if only 1 target / continous targets
        if (targetArrayInInt.length === 1) {
          // if only 1 item
          result = this.intToGrade(targetArrayInInt[0])
        } else {
          // if continous
          result = this.intToGrade(targetArrayInInt[0])
            .concat(`${this.getTxt(this.lang, 'to')}`) // .concat(' - ')
            .concat(
              this.intToGrade(targetArrayInInt[targetArrayInInt.length - 1])
            )
        }
      } else {
        // if not continous, eg. p1-p2, p5-p6
        result = this.group(targetArrayInInt)
      }
      // console.log('result', result.length)
      // console.log('result: ', result)
      if (result.length > 0) {
        // with student
        // result += this.$t('event.target.s')
        result += this.i18nTarget(this.lang)
        if (t.includes('t1')) {
          result += ', ' + this.i18nTargetTK(this.lang)
        }
        if (t.includes('t2')) {
          result += ', ' + this.i18nTargetTP(this.lang)
        }
        if (t.includes('t3')) {
          result += ', ' + this.i18nTargetTS(this.lang)
        }
      } else if (result.length === 0) {
        // without student
        if (t.includes('t1')) {
          result = this.i18nTargetTK(this.lang)
        }
        if (t.includes('t2')) {
          result += result.length > 0 ? ', ' : ''
          result += this.i18nTargetTP(this.lang)
        }
        if (t.includes('t3')) {
          result += result.length > 0 ? ', ' : ''
          result += this.i18nTargetTS(this.lang)
        }
      }

      return result
    },
    intToGrade(i) {
      // console.log(i, typeof i)
      let result = ''
      const lang = this.isEdit ? this.lang : this.i18nLang()
      switch (lang) {
        case 0:
          result =
            i < 1
              ? 'K.'.concat((i + 3).toString())
              : i < 7
              ? 'P.'.concat(i.toString())
              : 'S.'.concat((i - 6).toString())
          break
        case 1:
          result =
            i === -2
              ? '幼兒班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        case 2:
          result =
            i === -2
              ? '幼儿班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
    group(numbers) {
      const result = numbers
        .reduce((result, value, index, array) => {
          if (!index || array[index - 1] + 1 !== value) {
            result.push([this.intToGrade(parseInt(value))])
          } else {
            result[result.length - 1][1] = this.intToGrade(parseInt(value))
          }
          return result
        }, [])
        .map((array) => array.join(this.getTxt(this.lang, 'to')))
      return result.join(', ')
    },

    async getQuota() {
      const result = await this.event.EventSlot.reduce((acc = [], d) => {
        const found = acc.find((a) => a === d.quota)
        if (!found) {
          acc.push(d.quota) // not found, so need to add data property
        } /* else if (!found.target.includes(d.startTime)) {
          found.target.push(d.target)
        } */
        return acc
      }, [])
      // console.log(result + ' ' + result[0] + ' ' + result.length)
      this.quota = result
      // this.remarkBalance(this.remark.quota, result)

      return null
    },

    // --- Generate duration ---
    getDurationtxt(lang, d) {
      let result = ''
      result =
        d.hour === 1
          ? d.hour.toString().concat(this.getTxt(lang, 'hour'))
          : result
      result =
        d.hour > 1
          ? d.hour.toString().concat(this.getTxt(lang, 'hours'))
          : result
      result =
        d.min > 0
          ? result.concat(d.min.toString()).concat(this.getTxt(lang, 'min'))
          : result
      result =
        d.sec > 0
          ? result.concat(d.sec).concat(this.getTxt(lang, 'sec'))
          : result
      return result
    },
    getDuration() {
      let durationArr = []
      if (this.time.length) {
        // console.log(this.time)
        durationArr = this.time.reduce((arr = [], t) => {
          const a = t.startTime
          const b = t.endTime

          const difference = Math.abs(this.toSeconds(a) - this.toSeconds(b))

          // format time differnece
          const hour = Math.floor(difference / 3600) // an hour has 3600 seconds
          const min = Math.floor((difference % 3600) / 60) // a minute has 60 seconds
          const sec = difference % 60

          const result = {
            hour,
            min,
            sec,
          }

          const found = arr.find(
            (a) => JSON.stringify(a) === JSON.stringify(result)
          )
          if (!found) arr.push(result)
          return arr
        }, [])
      }
      this.duration = durationArr
    },
    toSeconds(timeStr) {
      // Extract hours, minutes and seconds
      const parts = timeStr.split(':')
      // compute  and return total seconds
      return (
        parts[0] * 3600 + // an hour has 3600 seconds
        parts[1] * 60 // a minute has 60 seconds
      ) // seconds
    },

    // --- Generate venue-date pairs ---
    async isSingleVenue() {
      if (this.event.EventSlot) {
        const result = await this.event.EventSlot.reduce((acc = [], d) => {
          const found = acc.find((a) => a.venue === d.venue)
          // const datehk = new Date(d.date).toString().slice(4, 15) // .toLocaleString()
          if (!found) {
            acc.push({ venue: d.venue, date: [d.date] }) // not found, so need to add data property
          } else if (!found.date.includes(d.date)) {
            found.date.push(d.date)
          }
          return acc
        }, [])
        this.venueDates = result
        // this.remarkBalance(this.remark.dnv, result)

        this.formatVenueDatePair()
      }
      this.singleVenue = this.venueDates.length === 1
    },
    formatVenueDatePair() {
      const newVenueDates = []
      this.venueDates.forEach((vdate) => {
        vdate.date.sort()
        // console.log(' vdate ' + JSON.stringify(vdate))
        const datearr = vdate.date.map((d) => new Date(d))
        let i = -1
        const result = datearr.reduce(function (stack, b) {
          const cur = stack[i]
          const a = cur ? cur[cur.length - 1] : 0 // the last item of the last array

          // create and push to new array if the date is not consecutive
          if (b - a > 1000 * 60 * 60 * 24) {
            i++
          }
          if (!stack[i]) stack[i] = []
          stack[i].push(b)

          return stack
        }, [])

        // console.log(result)
        // result: arr of array of dates
        // r: each array contains either 1 date or a group of consecutive dates

        let dateString = '' // eg. 22, 24-26/2/2021
        const dayString = [] // eg. (二至五)

        result.map((r, index) => {
          // nextDate: the first date of the next r
          const nextDate = result[index + 1] ? result[index + 1][0] : null

          dateString = dateString !== '' ? `${dateString}, ` : dateString
          // dayString = dayString !== '' ? `${dayString}, ` : dayString

          /* ------------------if the array r only contains 1 date------------------ */
          if (r.length === 1) {
            // if has the next r

            /* dateString */
            dateString = nextDate
              ? this.isSameMonth(nextDate, r[0])
                ? `${dateString}${this.$moment(r[0]).format('D')}`
                : this.isSameYear(nextDate, r[0])
                ? `${dateString}${this.$moment(r[0]).format('D.M')}`
                : `${dateString}${this.$moment(r[0]).format('D.M.Y')}`
              : `${dateString}${this.$moment(r[0]).format('D.M.Y')}`

            /* dayString */
            // dayString.push(this.getWeekly(1, this.$moment(r[0]).isoWeekday()))
            dayString.push(this.$moment(r[0]).isoWeekday().toString())
          } else {
            /* ------------------if the array r contains a group of consecutive dates------------------ */

            /* dateString */
            const dateFirst = new Date(r[0])
            const yearFirst = dateFirst.getFullYear()
            const monthFirst = dateFirst.getMonth() + 1
            const dtFirst = dateFirst.getDate()

            const dateLast = new Date(r[r.length - 1])
            const yearLast = dateLast.getFullYear()
            const monthLast = dateLast.getMonth() + 1
            const dtLast = dateLast.getDate()

            if (this.isSameMonth(r[0], r[r.length - 1])) {
              dateString = `${dateString}${dtFirst}-${dtLast}`

              // compare with 1st item in next r (next date array)
              dateString = this.isSameMonth(r[0], nextDate)
                ? dateString
                : `${dateString}/${monthFirst}`

              dateString = this.isSameYear(r[0], nextDate)
                ? dateString
                : `${dateString}/${yearFirst}`
            } else if (this.isSameYear(r[0], r[r.length - 1])) {
              dateString = `${dateString}${dtFirst}/${monthFirst}-${dtLast}/${monthLast}`

              // compare with 1st item in next r (next date array)
              dateString = this.isSameYear(r[0], nextDate)
                ? dateString
                : `${dateString}/${yearFirst}`
            } else {
              dateString = `${dateString}${dtFirst}/${monthFirst}/${yearFirst}-${dtLast}/${monthLast}/${yearLast}`
            }

            /* dayString */
            /* dayString.push(
              this.getWeekly(1, this.$moment(r[0]).isoWeekday())
                .concat('至')
                .concat(
                  this.getWeekly(1, this.$moment(r[r.length - 1]).isoWeekday())
                )
            ) */
            dayString.push(
              this.$moment(r[0])
                .isoWeekday()
                .toString()
                // .concat(',')
                .concat(
                  this.$moment(r[r.length - 1])
                    .isoWeekday()
                    .toString()
                )
            )
          }
        })
        // console.log(dayString[0])
        // console.log(dayString[1])
        const dayStringUnique = dayString.reduce(function (dates, d) {
          if (!dates.includes(d)) dates.push(d)
          return dates
        }, [])

        /* dayString =
          dayStringUnique.length === 1 ? `${dayStringUnique}` : `${dayString}`
        // console.log(dayString, dayStringUnique) */

        newVenueDates.push({
          venue: vdate.venue,
          date: dateString,
          day: dayStringUnique,
        })
        this.venueDates = newVenueDates
        // console.log('newVenueDates ' + newVenueDates)
      })
    },
    getDNVWeekday(lang, dayString) {
      let result = ''
      dayString.forEach((d, i) => {
        if (d.length === 1) result = `${result}${this.getWeekly(lang, d[0])}`
        if (d.length > 1)
          result = `${result}${this.getWeekly(lang, d[0])}${this.getTxt(
            lang,
            'to'
          )}${this.getWeekly(lang, d[1])}`
        if (i !== dayString.length - 1)
          result = `${result}${this.getTxt(lang, 'comma')}`
      })
      return `${this.getBracket(lang, 'open')}${result}${this.getBracket(
        lang,
        'close'
      )}`
    },
    isSameMonth(date1, date2) {
      return this.$moment(date1).format('M') === this.$moment(date2).format('M')
    },
    isSameYear(date1, date2) {
      return this.$moment(date1).format('Y') === this.$moment(date2).format('Y')
    },
    getBracket(lang, type) {
      if (type === 'open') return lang === 0 ? ' (' : '（'
      else if (type === 'close') return lang === 0 ? ')' : '）'
    },
    getWeekly(lang, day) {
      switch (day) {
        case 1:
        case '1':
        case 'Mon':
        case 'Monday':
          return lang === 0 ? 'Mon' : '一'
        case 2:
        case '2':
        case 'Tue':
        case 'Tuesday':
          return lang === 0 ? 'Tue' : '二'
        case 3:
        case '3':
        case 'Wed':
        case 'Wednesday':
          return lang === 0 ? 'Wed' : '三'
        case 4:
        case '4':
        case 'Thu':
        case 'Thursday':
          return lang === 0 ? 'Thu' : '四'
        case 5:
        case '5':
        case 'Fri':
        case 'Friday':
          return lang === 0 ? 'Fri' : '五'
        case 6:
        case '6':
        case 'Sat':
        case 'Saturday':
          return lang === 0 ? 'Sat' : '六'
        case 7:
        case '7':
        case 'Sun':
        case 'Sunday':
          return lang === 0 ? 'Sun' : '日'
      }
    },

    findSubevent(subevents) {
      return false
    },
    getVenue(lang, code) {
      let venueText = ''
      if (this.venue) {
        this.venue.forEach((v) => {
          if (v.code === code) {
            venueText =
              lang === 0 ? v.name_en : lang === 2 ? v.name_sc : v.name_tc
          }
        })
      }
      return venueText
    },
    getWeekDay(obj, target) {
      if (obj.Mon === target) {
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.event-btn {
  display: flex;
}
.btn-add {
  width: 31px;
  height: 31px;
}
.btn-apply {
  display: inline-block;
  min-width: 180px;
  border-radius: 20px;
  padding: 7px 60px;
  margin-right: auto;
  margin-left: auto;
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: white;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-apply:hover {
  background-color: #17a79f;
}

.td-right {
  float: right;
}
.td-time {
  margin-bottom: 0;
}
.event_detail > table {
  border: 1px solid lightgrey;
  width: 100%;
  // margin-bottom: 25px;
}
.event_detail > table > tbody > tr > th {
  background-color: #ffe086; // #f5ddd4;
  // text-align: center;
  height: 40px;
  border: 1px solid lightgrey;
  width: 180px;
  padding: 8px;
  text-align: center;
}
.event_detail > table > tbody > tr > td {
  background-color: #ffffde; // #fffaf5;
  text-align: left;
  height: 40px;
  border: 1px solid lightgrey;
  padding: 8px;
  // font-weight: 500;
}
.event_apply_links {
  border: 1px solid #1fbcb4;
  border-radius: 20px;
  padding: 7px 50px;
  background: #1fbcb4;
  color: white;
  text-decoration: none;
  // box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: fit-content;
  width: 180px;
  cursor: pointer;
  /* position: fixed;
  right: 145px;
  bottom: 50px;
  z-index: 4; */
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.3));
}
.event_apply_links:hover {
  background: #62d0ca;
  border: 1px solid #62d0ca;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .event_detail > table > tbody > tr > th {
    width: auto;
  }
}
@media print {
  .event_detail > table > tbody > tr > th {
    background-color: #ffe086; // #f5ddd4;
    // text-align: center;
    height: 40px;
    border: 1px solid lightgrey;
    width: 30% !important;
    padding: 8px;
    text-align: center;
  }
}
</style>
