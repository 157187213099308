var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-cont" }, [
    _c(
      "div",
      { staticClass: "spaps-div" },
      [
        _c("h3", [
          _vm._v("❺ " + _vm._s(_vm.$t("apply.spaps.tab_txt.tab3.title"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "questions mt-4" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("apply.spaps.tab_txt.tab3.fund")) +
              "\n      "
          ),
          _c("span", { staticClass: "red" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _vm.edit
          ? _c("b-form-radio-group", {
              attrs: {
                id: "radio-group-fund",
                options: _vm.fund,
                state:
                  _vm.validate || _vm.validateOnclick[0]
                    ? !!_vm.sklinfo.fund
                    : null,
              },
              on: {
                input: function ($event) {
                  return _vm.inputChanged(0)
                },
              },
              model: {
                value: _vm.sklinfo.fund,
                callback: function ($$v) {
                  _vm.$set(_vm.sklinfo, "fund", $$v)
                },
                expression: "sklinfo.fund",
              },
            })
          : _c("div", { staticClass: "choice" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.sklinfo.fund
                      ? _vm.$t(
                          `apply.spaps.tab_txt.tab3.fund_${_vm.sklinfo.fund}`
                        )
                      : "/"
                  ) +
                  "\n    "
              ),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "spaps-div" },
      [
        _c("h3", [
          _vm._v("❻ " + _vm._s(_vm.$t("apply.spaps.tab_txt.tab3.title2"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "questions mt-3" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("apply.spaps.tab_txt.tab3.otherGroup")) +
              "\n      "
          ),
          _c("span", { staticClass: "red" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _vm.edit
          ? _c("b-form-radio-group", {
              attrs: {
                id: "radio-group-otherGroup",
                options: _vm.otherGroup,
                state:
                  _vm.validate || _vm.validateOnclick[1]
                    ? !!_vm.sklinfo.otherGroup
                    : null,
              },
              on: {
                input: function ($event) {
                  return _vm.inputChanged(1)
                },
              },
              model: {
                value: _vm.sklinfo.otherGroup,
                callback: function ($$v) {
                  _vm.$set(_vm.sklinfo, "otherGroup", $$v)
                },
                expression: "sklinfo.otherGroup",
              },
            })
          : _c("div", { staticClass: "choice" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.sklinfo.otherGroup
                      ? _vm.$t(
                          `apply.spaps.tab_txt.tab3.otherGroup_${_vm.sklinfo.otherGroup}`
                        )
                      : "/"
                  ) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _vm.sklinfo.otherGroup === 1
          ? _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "6" } },
                  [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("apply.spaps.tab_txt.tab3.otherGroupSpecify")
                          ) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state:
                              _vm.validate || _vm.validateOnclick[5]
                                ? _vm.checkExist(_vm.sklinfo.otherGroupSpecify)
                                : null,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChanged(5)
                            },
                          },
                          model: {
                            value: _vm.sklinfo.otherGroupSpecify,
                            callback: function ($$v) {
                              _vm.$set(_vm.sklinfo, "otherGroupSpecify", $$v)
                            },
                            expression: "sklinfo.otherGroupSpecify",
                          },
                        })
                      : _c("div", { staticClass: "choice" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.checkExist(_vm.sklinfo.otherGroupSpecify)
                                  ? _vm.sklinfo.otherGroupSpecify
                                  : "/"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "questions mt-5" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("apply.spaps.tab_txt.tab3.otherClass")) +
              "\n      "
          ),
          _c("span", { staticClass: "red" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _vm.edit
          ? _c("b-form-radio-group", {
              attrs: {
                id: "radio-group-otherClass",
                options: _vm.otherClass,
                state:
                  _vm.validate || _vm.validateOnclick[2]
                    ? !!_vm.sklinfo.otherClass
                    : null,
              },
              on: {
                input: function ($event) {
                  return _vm.inputChanged(2)
                },
              },
              model: {
                value: _vm.sklinfo.otherClass,
                callback: function ($$v) {
                  _vm.$set(_vm.sklinfo, "otherClass", $$v)
                },
                expression: "sklinfo.otherClass",
              },
            })
          : _c("div", { staticClass: "choice" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.sklinfo.otherClass
                      ? _vm.$t(
                          `apply.spaps.tab_txt.tab3.otherClass_${_vm.sklinfo.otherClass}`
                        )
                      : "/"
                  ) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _vm.sklinfo.otherClass === 1
          ? _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("apply.spaps.tab_txt.tab3.otherClassContent")
                          ) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state:
                              _vm.validate || _vm.validateOnclick[6]
                                ? _vm.checkExist(_vm.sklinfo.otherClassContent)
                                : null,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChanged(6)
                            },
                          },
                          model: {
                            value: _vm.sklinfo.otherClassContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.sklinfo, "otherClassContent", $$v)
                            },
                            expression: "sklinfo.otherClassContent",
                          },
                        })
                      : _c("div", { staticClass: "choice" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.checkExist(_vm.sklinfo.otherClassContent)
                                  ? _vm.sklinfo.otherClassContent
                                  : "/"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("apply.spaps.tab_txt.tab3.otherClassYear")
                          ) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state:
                              _vm.validate || _vm.validateOnclick[7]
                                ? _vm.checkYear(_vm.sklinfo.otherClassYear)
                                : null,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChanged(7)
                            },
                          },
                          model: {
                            value: _vm.sklinfo.otherClassYear,
                            callback: function ($$v) {
                              _vm.$set(_vm.sklinfo, "otherClassYear", $$v)
                            },
                            expression: "sklinfo.otherClassYear",
                          },
                        })
                      : _c("div", { staticClass: "choice" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.checkExist(_vm.sklinfo.otherClassYear)
                                  ? _vm.sklinfo.otherClassYear
                                  : "/"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "questions mt-5" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("apply.spaps.tab_txt.tab3.otherTraining")) +
              "\n      "
          ),
          _c("span", { staticClass: "red" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _vm.edit
          ? _c("b-form-radio-group", {
              attrs: {
                id: "radio-group-otherTraining",
                options: _vm.otherTraining,
                state:
                  _vm.validate || _vm.validateOnclick[3]
                    ? !!_vm.sklinfo.otherTraining
                    : null,
              },
              on: {
                input: function ($event) {
                  return _vm.inputChanged(3)
                },
              },
              model: {
                value: _vm.sklinfo.otherTraining,
                callback: function ($$v) {
                  _vm.$set(_vm.sklinfo, "otherTraining", $$v)
                },
                expression: "sklinfo.otherTraining",
              },
            })
          : _c("div", { staticClass: "choice" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.sklinfo.otherTraining
                      ? _vm.$t(
                          `apply.spaps.tab_txt.tab3.otherTraining_${_vm.sklinfo.otherTraining}`
                        )
                      : "/"
                  ) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _vm.sklinfo.otherTraining === 1
          ? _c(
              "div",
              [
                _c("div", { staticClass: "event-title mt-3" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("apply.spaps.tab_txt.tab3.otherTrainingType")
                      ) +
                      "\n        "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        type: "text",
                        state:
                          _vm.validate || _vm.validateOnclick[8]
                            ? _vm.checkExist(_vm.sklinfo.otherTrainingType)
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(8)
                        },
                      },
                      model: {
                        value: _vm.sklinfo.otherTrainingType,
                        callback: function ($$v) {
                          _vm.$set(_vm.sklinfo, "otherTrainingType", $$v)
                        },
                        expression: "sklinfo.otherTrainingType",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.checkExist(_vm.sklinfo.otherTrainingType)
                              ? _vm.sklinfo.otherTrainingType
                              : "/"
                          ) +
                          "\n      "
                      ),
                    ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.sklinfo.otherTraining === 1
          ? _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("apply.spaps.tab_txt.tab3.otherTrainingName")
                          ) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state:
                              _vm.validate || _vm.validateOnclick[9]
                                ? _vm.checkExist(_vm.sklinfo.otherTrainingName)
                                : null,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChanged(9)
                            },
                          },
                          model: {
                            value: _vm.sklinfo.otherTrainingName,
                            callback: function ($$v) {
                              _vm.$set(_vm.sklinfo, "otherTrainingName", $$v)
                            },
                            expression: "sklinfo.otherTrainingName",
                          },
                        })
                      : _c("div", { staticClass: "choice" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.checkExist(_vm.sklinfo.otherTrainingName)
                                  ? _vm.sklinfo.otherTrainingName
                                  : "/"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("apply.spaps.tab_txt.tab3.otherTrainingYear")
                          ) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state:
                              _vm.validate || _vm.validateOnclick[10]
                                ? _vm.checkYear(_vm.sklinfo.otherTrainingYear)
                                : null,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChanged(10)
                            },
                          },
                          model: {
                            value: _vm.sklinfo.otherTrainingYear,
                            callback: function ($$v) {
                              _vm.$set(_vm.sklinfo, "otherTrainingYear", $$v)
                            },
                            expression: "sklinfo.otherTrainingYear",
                          },
                        })
                      : _c("div", { staticClass: "choice" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.checkExist(_vm.sklinfo.otherTrainingYear)
                                  ? _vm.sklinfo.otherTrainingYear
                                  : "/"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "questions mt-5" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("apply.spaps.tab_txt.tab3.havePerformance")) +
              "\n      "
          ),
          _c("span", { staticClass: "red" }, [_vm._v("*")]),
        ]),
        _vm._v(" "),
        _vm.edit
          ? _c("b-form-radio-group", {
              attrs: {
                id: "radio-group-havePerformance",
                options: _vm.havePerformance,
                state:
                  _vm.validate || _vm.validateOnclick[4]
                    ? !!_vm.sklinfo.havePerformance
                    : null,
              },
              on: {
                input: function ($event) {
                  return _vm.inputChanged(4)
                },
              },
              model: {
                value: _vm.sklinfo.havePerformance,
                callback: function ($$v) {
                  _vm.$set(_vm.sklinfo, "havePerformance", $$v)
                },
                expression: "sklinfo.havePerformance",
              },
            })
          : _c("div", { staticClass: "choice" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.sklinfo.havePerformance
                      ? _vm.$t(
                          `apply.spaps.tab_txt.tab3.havePerformance_${_vm.sklinfo.havePerformance}`
                        )
                      : "/"
                  ) +
                  "\n    "
              ),
            ]),
        _vm._v(" "),
        _vm.sklinfo.havePerformance === 1
          ? _c(
              "div",
              [
                _c("div", { staticClass: "event-title mt-3" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("apply.spaps.tab_txt.tab3.havePerformanceType")
                      ) +
                      "\n        "
                  ),
                  _c("span", { staticClass: "red" }, [_vm._v("*")]),
                ]),
                _vm._v(" "),
                _vm.edit
                  ? _c("b-form-input", {
                      attrs: {
                        type: "text",
                        state:
                          _vm.validate || _vm.validateOnclick[11]
                            ? _vm.checkExist(_vm.sklinfo.havePerformanceType)
                            : null,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.inputChanged(11)
                        },
                      },
                      model: {
                        value: _vm.sklinfo.havePerformanceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.sklinfo, "havePerformanceType", $$v)
                        },
                        expression: "sklinfo.havePerformanceType",
                      },
                    })
                  : _c("div", { staticClass: "choice" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.checkExist(_vm.sklinfo.havePerformanceType)
                              ? _vm.sklinfo.havePerformanceType
                              : "/"
                          ) +
                          "\n      "
                      ),
                    ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.sklinfo.havePerformance === 1
          ? _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "apply.spaps.tab_txt.tab3.havePerformanceName"
                            )
                          ) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state:
                              _vm.validate || _vm.validateOnclick[12]
                                ? _vm.checkExist(
                                    _vm.sklinfo.havePerformanceName
                                  )
                                : null,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChanged(12)
                            },
                          },
                          model: {
                            value: _vm.sklinfo.havePerformanceName,
                            callback: function ($$v) {
                              _vm.$set(_vm.sklinfo, "havePerformanceName", $$v)
                            },
                            expression: "sklinfo.havePerformanceName",
                          },
                        })
                      : _c("div", { staticClass: "choice" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.checkExist(_vm.sklinfo.havePerformanceName)
                                  ? _vm.sklinfo.havePerformanceName
                                  : "/"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  [
                    _c("div", { staticClass: "event-title mt-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "apply.spaps.tab_txt.tab3.havePerformanceYear"
                            )
                          ) +
                          "\n          "
                      ),
                      _c("span", { staticClass: "red" }, [_vm._v("*")]),
                    ]),
                    _vm._v(" "),
                    _vm.edit
                      ? _c("b-form-input", {
                          attrs: {
                            type: "text",
                            state:
                              _vm.validate || _vm.validateOnclick[13]
                                ? _vm.checkYear(_vm.sklinfo.havePerformanceYear)
                                : null,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.inputChanged(13)
                            },
                          },
                          model: {
                            value: _vm.sklinfo.havePerformanceYear,
                            callback: function ($$v) {
                              _vm.$set(_vm.sklinfo, "havePerformanceYear", $$v)
                            },
                            expression: "sklinfo.havePerformanceYear",
                          },
                        })
                      : _c("div", { staticClass: "choice" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.checkExist(_vm.sklinfo.havePerformanceYear)
                                  ? _vm.sklinfo.havePerformanceYear
                                  : "/"
                              ) +
                              "\n        "
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }