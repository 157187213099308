var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("div", { staticClass: "cont-search-results" }, [
          _vm.currSelected.length > 0
            ? _c("div", { staticClass: "search-results" }, [
                _c("div", { staticClass: "search-filter-cont" }, [
                  _c("div", { staticClass: "search-filter-list" }, [
                    _c("div", { staticClass: "filter-title" }, [
                      _vm._v(_vm._s(_vm.$t("event.search_result"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "filter-tags" },
                      _vm._l(_vm.currSelected, function (tag, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "filter-tag" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(tag.name ? tag.name : tag.text) +
                                "\n                "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "filter-tag-x",
                                attrs: { href: "javascript:void(0)" },
                                on: {
                                  click: function ($event) {
                                    return _vm.rmTag(tag)
                                  },
                                },
                              },
                              [_vm._v("\n                  ×")]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.currSelected.length > 0
                      ? _c(
                          "a",
                          {
                            staticClass: "btn-refresh btn-link",
                            on: { click: _vm.refresh },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("event.clear_all")) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "cards-box" }, [
            _vm.showSortBtn
              ? _c("div", { staticClass: "card-box-header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-box-sort",
                      on: {
                        click: function ($event) {
                          _vm.isShowSortMenu = !_vm.isShowSortMenu
                        },
                      },
                    },
                    [
                      _vm.showSortBtn
                        ? _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("event.sort_by")) +
                                _vm._s(_vm.getSortItem) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isShowSortMenu
                    ? _c("div", { staticClass: "card-box-sort-menu" }, [
                        _c("ul", { staticClass: "card-box-sort-menu-list" }, [
                          _c(
                            "li",
                            {
                              staticClass: "card-box-sort-menu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.sortEvent("date")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.startdate")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              staticClass: "card-box-sort-menu-item",
                              on: {
                                click: function ($event) {
                                  return _vm.sortEvent("code")
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("event.code")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "mt-5" },
                  [_c("b-spinner", { attrs: { variant: "secondary" } })],
                  1
                )
              : _vm.events.length > 0
              ? _c("div", { staticClass: "cards-box-body" }, [
                  _vm.isGrid
                    ? _c(
                        "div",
                        { staticClass: "cards-box-content-grid row p-0" },
                        _vm._l(_vm.events, function (event, index) {
                          return _c("EventCard", {
                            key: index,
                            attrs: {
                              event: event,
                              index: index,
                              bookmarks: _vm.bookmarks,
                              schemes: _vm.schemes,
                              categories: _vm.categories,
                              "aoa-pass": _vm.aoaPass,
                              "aoa-join": _vm.aoaJoin,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  false
                    ? _c(
                        "div",
                        { staticClass: "cards-box-content-grid row" },
                        _vm._l(_vm.events, function (event, index) {
                          return _c("EventCard", {
                            key: index,
                            attrs: {
                              event: event,
                              index: index,
                              bookmarks: _vm.bookmarks,
                              schemes: _vm.schemes,
                              categories: _vm.categories,
                              "aoa-pass": _vm.aoaPass,
                              "aoa-join": _vm.aoaJoin,
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isGrid
                    ? _c("div", { staticClass: "cards-box-content-list" }, [
                        _vm.events
                          ? _c(
                              "table",
                              { staticStyle: { "margin-top": "30px" } },
                              [
                                _c(
                                  "tbody",
                                  [
                                    _c("tr", [
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("event.category_id"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("event.id"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("event.name"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v(_vm._s(_vm.$t("event.date2"))),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v(
                                          _vm._s(_vm.$t("navbar.bookmark"))
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.events, function (event, index) {
                                      return _c("EventCard", {
                                        key: index,
                                        attrs: {
                                          event: event,
                                          index: index,
                                          bookmarks: _vm.bookmarks,
                                          "is-list": !_vm.isGrid,
                                          schemes: _vm.schemes,
                                          categories: _vm.categories,
                                          "aoa-pass": _vm.aoaPass,
                                          "aoa-join": _vm.aoaJoin,
                                        },
                                      })
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "cards-box-body" }, [
                  _c(
                    "h1",
                    { staticClass: "mt-5" },
                    [_c("b-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("h6", { staticClass: "mt-3" }, [
                    _vm._v(_vm._s(_vm.$t("event.not_found"))),
                  ]),
                ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }