export default async function (e) {
  const { app, store } = e
  if (store.state.localStorage.auth.loggedIn) {
    app.$axios.setToken(store.state.localStorage.auth.token, 'Bearer')
    try {
      const now = new Date()
      const nextTime = new Date(store.state.localStorage.auth.nextToken)
      if (now >= nextTime) {
        await store.dispatch('localStorage/refreshToken')
      }
    } catch (err) {
      try {
        await store.dispatch('localStorage/refreshToken')
      } catch (e) {
        await store.dispatch('localStorage/logout')
      }
    }
  }
}
