<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="6">
      <div class="newsForm">
        <b-form>
          <b-form-group
            id="created_at"
            label-for="created_at"
            :label="$t('feedback.date')"
          >
            <b-form-input
              id="created_at"
              v-model="created_at"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="userId"
            label-for="userId"
            :label="$t('feedback.username')"
          >
            <b-form-input
              id="userId"
              v-model="userId"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="school"
            label-for="school"
            :label="$t('feedback.schoolname')"
          >
            <b-form-input
              id="school"
              v-model="school"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="type"
            label-for="type"
            :label="$t('feedback.scheme')"
          >
            <b-form-input
              id="type"
              v-model="type"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group id="content" label-for="content">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('feedback.detail')">
                <b-textarea
                  v-model="content"
                  no-resize
                  rows="10"
                  readonly
                  style="background-color: white"
                ></b-textarea>
              </b-tab>
            </b-tabs>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-button variant="outline-success" @click="$router.go(-1)">{{
              $t('apply.back')
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'

export default {
  components: {
    Notification,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      // TODO: map user from cloud to local
      const result = await this.$axios.get(`${process.env.localApiUrl}users`)
      this.users = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      const result = await this.$axios.get(`${process.env.localApiUrl}schools`)
      this.schools = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      // console.log(this.action)
      if (this.action === 'view') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}feedbacks/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.userId = this.getUser(data.userId)
        this.school = this.getSchool(data.school)
        this.type = data.type
        this.content = data.content
        this.created_at = this.$moment(data.created_at).format(
          'yyyy.MM.DD HH:mm'
        )
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      userId: null,
      school: null,
      type: '',
      content: '',
      created_at: '',
      error: null,
    }
  },
  computed: {},
  methods: {
    getContent(evt, id, content) {
      this[id] = content
    },
    getUser(userid) {
      // console.log(userid)
      const id = parseInt(userid)
      if (this.users) {
        return this.users.find((c) => c.id === id)
          ? this.users.find((c) => c.id === id).name
          : id
      }
    },
    getSchool(schoolid) {
      const id = parseInt(schoolid)
      if (this.schools) {
        if (!id) {
          return 'NA'
        }

        const school = this.schools.find((c) => c.id === id)
        if (school) {
          return school.name_tc
        } else {
          return id
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
