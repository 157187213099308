var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "noti-card", class: _vm.handled === 0 ? "not-read" : "" },
    [
      _c(
        "div",
        {
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              return _vm.readNotification(_vm.id)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "noti-icon-container", attrs: { cols: "2" } },
                [
                  _c(
                    "div",
                    { staticClass: "noti-heading" },
                    [
                      _c("b-icon", {
                        staticClass: "noti-icon rounded-circle",
                        attrs: { icon: _vm.icon },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "main-content", attrs: { cols: "9" } },
                [
                  _c("div", { staticClass: "noti-card-title" }, [
                    _vm._v(_vm._s(_vm.cardTitle)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "noti-content" },
                    [
                      _vm._t("default", function () {
                        return [_vm._v("Notification Card Content")]
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "noti-datetime" }, [
                        _vm._v(_vm._s(_vm.datetime)),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.handled === 0
                ? _c(
                    "b-col",
                    {
                      staticClass: "noti-not-read-container",
                      attrs: { cols: "1" },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "noti-not-read",
                        attrs: {
                          icon: "circle-fill",
                          variant: "primary",
                          "font-scale": "0.5",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }