<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="10">
      <div class="form">
        <b-form method="post" @submit.prevent="submit">
          <div class="row">
            <div class="col-sm-5">
              <b-form-group id="slug" label-for="slug">
                <p class="label">
                  {{ $t('scheme.slug') }}（e.g. 'scds1920', 'spaps2021'）
                </p>
                <b-form-input
                  v-model="slug"
                  type="text"
                  :disabled="action === 'edit' ? true : false"
                  lazy-formatter
                  :formatter="slugformatter"
                  :state="slugState"
                  required
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                  Cannot contain special characters
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label-for="banner" :label="$t('scheme.banner')">
                {{ $t('scheme.banner_size') }}: 1200px(W) x 313px(H)
                <b-form-input v-model="banner" type="text"></b-form-input>
                <b-form-file
                  id="banner"
                  accept="image/*"
                  @change="uploadImage"
                />
                <img :src="banner" style="max-height: 100px" alt="banner" />
              </b-form-group>

              <b-form-group label-for="img" :label="$t('scheme.img')">
                {{ $t('scheme.banner_size') }}: Square - 350px(W) x 350px(H);
                Rectangle - 535px(W) x 350px(H);
                <b-form-input v-model="img" type="text"></b-form-input>
                <b-form-file id="img" accept="image/*" @change="uploadImage" />
                <img :src="img" style="max-height: 100px" alt="image" />
              </b-form-group>

              <div v-if="parent && parent === 1">
                <b-form-group
                  label-for="img"
                  label="圖像：'家長參與'（文化日適用）"
                >
                  {{ $t('scheme.banner_size') }}: 50px(W) x 50px(H)
                  <b-form-input
                    v-model="parentIcon.img"
                    type="text"
                  ></b-form-input>
                  <b-form-file
                    id="parentIcon"
                    accept="image/*"
                    @change="(e) => uploadImage(e, 'parentIcon')"
                  />
                  <b-tabs content-class="mt-3">
                    <b-tab :title="$t('scheme.popover.en')">
                      <b-form-group id="popover_en" label-for="popover_en">
                        <b-form-input
                          v-model="parentIcon.popover.content_en"
                          :placeholder="$t('placeholder.popover.en')"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                    <b-tab :title="$t('scheme.popover.tc')">
                      <b-form-group id="popover_tc" label-for="popover_tc">
                        <b-form-input
                          v-model="parentIcon.popover.content_tc"
                          :placeholder="$t('placeholder.popover.tc')"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                    <b-tab :title="$t('scheme.popover.sc')">
                      <b-form-group id="popover_sc" label-for="popover_sc">
                        <b-form-input
                          v-model="parentIcon.popover.content_sc"
                          :placeholder="$t('placeholder.popover.sc')"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>
                  <img
                    :src="parentIcon.img"
                    style="max-height: 30px"
                    alt="parent icon"
                  />
                </b-form-group>

                <b-form-group
                  label-for="img"
                  label="圖像：'適合較少經驗參加者'（文化日適用）"
                >
                  {{ $t('scheme.banner_size') }}: 50px(W) x 50px(H)
                  <b-form-input
                    v-model="starIcon.img"
                    type="text"
                  ></b-form-input>
                  <b-form-file
                    id="starIcon"
                    accept="image/*"
                    @change="(e) => uploadImage(e, 'starIcon')"
                  />
                  <b-tabs content-class="mt-3">
                    <b-tab :title="$t('scheme.popover.en')">
                      <b-form-group id="popover_en" label-for="popover_en">
                        <b-form-input
                          v-model="starIcon.popover.content_en"
                          :placeholder="$t('placeholder.popover.en')"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                    <b-tab :title="$t('scheme.popover.tc')">
                      <b-form-group id="popover_tc" label-for="popover_tc">
                        <b-form-input
                          v-model="starIcon.popover.content_tc"
                          :placeholder="$t('placeholder.popover.tc')"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                    <b-tab :title="$t('scheme.popover.sc')">
                      <b-form-group id="popover_sc" label-for="popover_sc">
                        <b-form-input
                          v-model="starIcon.popover.content_sc"
                          :placeholder="$t('placeholder.popover.sc')"
                          type="text"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>
                  <img
                    :src="starIcon.img"
                    style="max-height: 30px"
                    alt="star icon"
                  />
                </b-form-group>
              </div>
            </div>
            <div class="col-sm-7">
              <b-tabs content-class="my-3">
                <b-tab :title="$t('scheme.title.en')">
                  <!-- <medium-editor
                    :text="title_en"
                    :options="options_title_en"
                    class="medium-editor"
                    @edit="
                      (operation) => {
                        title_en = operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor> -->
                  <b-form-group id="title_en" label-for="title_en">
                    <b-form-input
                      v-model="title_en"
                      :placeholder="$t('placeholder.title.en')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
                <b-tab :title="$t('scheme.title.tc')">
                  <!-- <medium-editor
                    :text="title_tc"
                    :options="options_title_tc"
                    class="medium-editor"
                    @edit="
                      (operation) => {
                        title_tc = operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor> -->
                  <b-form-group id="title_tc" label-for="title_tc">
                    <b-form-input
                      v-model="title_tc"
                      :placeholder="$t('placeholder.title.tc')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
                <b-tab :title="$t('scheme.title.sc')">
                  <!-- <medium-editor
                    :text="title_sc"
                    :options="options_title_sc"
                    class="medium-editor"
                    @edit="
                      (operation) => {
                        title_sc = operation.api.origElements.innerHTML
                      }
                    "
                  >
                  </medium-editor> -->
                  <b-form-group id="title_sc" label-for="title_sc">
                    <b-form-input
                      v-model="title_sc"
                      :placeholder="$t('placeholder.title.sc')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
              </b-tabs>

              <p class="label">
                計劃年份 (請輸入開始年份 e.g.計劃年份為2021-2022, 輸入'2021')
              </p>
              <b-form-input v-model="year" type="number" trim></b-form-input>

              <b-form-group id="parent" label-for="parent" class="mt-3">
                <p class="label">{{ $t('scheme.schemeType') }}</p>
                <b-form-select
                  v-model="schemeType"
                  :options="schemeTypeList"
                ></b-form-select>
              </b-form-group>

              <b-form-group id="parent" label-for="parent">
                <p class="label">{{ $t('scheme.parent') }}</p>
                <!-- <b-form-select
                  v-model="parent"
                  :options="schemeList"
                  :disabled="action === 'edit' ? true : false"
                ></b-form-select> -->
                <b-form-select
                  v-model="parent"
                  :options="schemeList"
                ></b-form-select>
              </b-form-group>

              <!-- checkbox for adding event cat -->
              <b-form-group id="needCategory" label-for="needCategory">
                <b-form-checkbox
                  id="cat_checkbox"
                  v-model="needCategory"
                  name="cat_checkbox"
                  value="1"
                  unchecked-value="0"
                  :disabled="parent"
                >
                  需要活動分類
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label-for="yt_link" :label="$t('scheme.yt_link')">
                <b-form-input v-model="yt_link" type="text" trim></b-form-input>
              </b-form-group>

              <!-- v-if="parent && slug.includes('scds')" -->
              <div class="m-period-table-roller">
                <b-table
                  v-if="
                    parent && (parent === 1 || parent === 4 || parent === 5)
                  "
                  :fields="getRegRound"
                  :items="filterRegFields(reg_fields)"
                  style="border: 2px #ff739d solid; margin-bottom: 5px"
                  :style="{ borderColor: getColor(slug) }"
                >
                  <template #cell(round)="row">
                    {{ row.item.round }}
                  </template>
                  <template #cell(startRegDate)="row">
                    <b-form-input
                      v-model="row.item.startDate"
                      type="date"
                      placeholder="yyyy-mm-dd"
                    ></b-form-input>
                    <div class="ie-hint">yyyy-mm-dd</div>
                  </template>
                  <template #cell(endRegDate)="row">
                    <b-form-input
                      v-model="row.item.endDate"
                      type="date"
                      placeholder="yyyy-mm-dd"
                    ></b-form-input>
                    <div class="ie-hint">yyyy-mm-dd</div>
                  </template>
                </b-table>
                <small v-if="parent && parent === 4" class="red">
                  "Late Application" 截止報名日期後，將不能再報名參加此計劃
                </small>
              </div>
            </div>
          </div>

          <div v-if="parent">
            <div class="shadow my-3 mse-c1">
              <b-form-group id="content" label-for="content">
                <b-tabs content-class="mt-3">
                  <b-tab :title="$t('event.content.en')">
                    <tinymceEditor
                      id="content_en"
                      :value="content_en"
                      @writeContent="getContent"
                    />
                  </b-tab>
                  <b-tab :title="$t('event.content.tc')">
                    <tinymceEditor
                      id="content_tc"
                      :value="content_tc"
                      @writeContent="getContent"
                    />
                  </b-tab>
                  <b-tab :title="$t('event.content.sc')">
                    <tinymceEditor
                      id="content_sc"
                      :value="content_sc"
                      @writeContent="getContent"
                    />
                  </b-tab>
                </b-tabs>
              </b-form-group>
            </div>
          </div>

          <!-- <div v-if="(parent && !isPortalScheme()) || isPortalScheme()">
            <h1 v-if="isPortalScheme() && !parent">
              以下內容只顯示於E-Platform
            </h1>
            <h1 v-else-if="isPortalScheme()">以下內容只顯示於網站</h1> -->
          <div>
            <div class="shadow my-3 mse-c1">
              <div class="d-flex mb-3">
                <b-button variant="outline-success" @click="addTab(addTabIndex)"
                  >添加分段內容</b-button
                >
                <b-form-input
                  v-model="addTabIndex"
                  type="number"
                  :min="1"
                  :max="tabs.length + 1"
                  style="width: 150px"
                  class="ml-2"
                  placeholder="位置(選填)"
                />
              </div>

              <b-tabs content-class="mt-3">
                <b-tab
                  v-for="(tab, counter) in tabs"
                  :key="counter"
                  class="tab"
                >
                  <template #title>
                    {{ counter + 1 }}) {{ i18nTitle(tab) }}
                    <b-button
                      size="sm"
                      variant="danger"
                      style="border-radius: 500%"
                      @click.prevent="deleteTab(counter)"
                    >
                      ×
                    </b-button>
                  </template>
                  <b-form-group id="tabs" label-for="tabs">
                    <b-tabs content-class="mt-3">
                      <b-tab :title="$t('g.en')">
                        <p class="label">{{ $t('tab.title.en') }}</p>
                        <b-form-input
                          v-model="tab.title_en"
                          :placeholder="$t('placeholder.title.en')"
                          type="text"
                          trim
                        ></b-form-input>
                        <p class="label">{{ $t('tab.content.en') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_en_' + counter"
                          :value="tab.content_en"
                          @writeContent="getTabContent"
                        />
                      </b-tab>
                      <b-tab :title="$t('g.tc')">
                        <p class="label">{{ $t('tab.title.tc') }}</p>
                        <b-form-input
                          v-model="tab.title_tc"
                          :placeholder="$t('placeholder.title.tc')"
                          type="text"
                          trim
                        ></b-form-input>
                        <p class="label">{{ $t('tab.content.tc') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_tc_' + counter"
                          :value="tab.content_tc"
                          @writeContent="getTabContent"
                        />
                      </b-tab>
                      <b-tab :title="$t('g.sc')">
                        <p class="label">{{ $t('tab.title.sc') }}</p>
                        <b-form-input
                          v-model="tab.title_sc"
                          :placeholder="$t('placeholder.title.sc')"
                          type="text"
                          trim
                        ></b-form-input>
                        <p class="label">{{ $t('tab.content.sc') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_sc_' + counter"
                          :value="tab.content_sc"
                          @writeContent="getTabContent"
                      /></b-tab>
                    </b-tabs>
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </div>
          </div>

          <b-row v-if="parent">
            <b-col>
              <b-form-group id="isFinish">
                <b-form-checkbox
                  v-model="isFinish"
                  value="1"
                  unchecked-value="0"
                  >{{ $t('scheme.isFinish') }}
                  <!-- <span v-if="web_portal === 1">{{
                    $t('scheme.isFinishRemark')
                  }}</span> -->
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="customizeLastModify"
                label-for="customizeLastModify"
              >
                <p class="label">最後修改日期</p>
                <b-form-input
                  id="customizeLastModify"
                  v-model="customizeLastModify"
                  type="date"
                />
              </b-form-group>

              <b-form-group id="web_portal">
                <!-- able to show new added scheme to eplatform -->
                <b-form-checkbox
                  v-model="web_portal"
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled === '1'"
                  >{{ $t('scheme.web_portal') }}
                </b-form-checkbox>
                <!-- <b-form-checkbox
                  v-model="web_portal"
                  value="1"
                  unchecked-value="0"
                  :disabled="disabled === '1' || !isEPlatformSubscheme()"
                  >{{ $t('scheme.web_portal') }}
                </b-form-checkbox> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="disabled">
                <b-form-checkbox
                  v-model="disabled"
                  value="3"
                  unchecked-value="0"
                  >{{ $t('scheme.disabled') }} (Website only)
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="disabled"
                  value="1"
                  unchecked-value="0"
                  >{{ $t('scheme.disabled') }} (Website & Eplatform)
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="disabled"
                  value="2"
                  unchecked-value="0"
                  >{{ $t('scheme.web_disabled') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <Notification v-if="error" :message="error" />
          <div>
            <b-button
              id="submit"
              type="submit"
              variant="outline-success"
              :disabled="btn_disabled"
              >{{ $t('action.submit') }}
            </b-button>
            <b-button
              :variant="isEPlatform() ? 'outline-primary' : 'outline-secondary'"
              :disabled="!isEPlatform()"
              @click="previewEplatform()"
            >
              {{ $t('action.preview') }} (E-Platform)
            </b-button>
            <b-button variant="outline-warning" @click="previewWebpage()">
              {{ $t('action.preview') }} (Webpage)
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import TinymceEditor from '~/components/admin/tinymceEditor'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    TinymceEditor,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}systemVariables/public/schemeType`
      )
      const schemeTypeListData = result.data.data
      // console.log(schemeTypeListData)
      // console.log(JSON.parse(schemeTypeListData[0].value))
      this.schemeTypeList = JSON.parse(schemeTypeListData[0].value).map((d) => {
        d.value = d.code
        d.text = d.title_tc
        return d
      })
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
    try {
      const schemel = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/name`
      )
      let schemeListData = schemel.data.data
      // console.log('schemeListData:', schemeListData)
      schemeListData = schemeListData.map((d) => {
        d.value = d.id
        d.text = d.title_tc
        // delete d.id
        // delete d.title_tc
        return d
      })
      this.schemeList = this.schemeList.concat(schemeListData)
      if (this.action === 'edit' || this.action === 'copy') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}schemes/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.slug = data.slug
        this.schemeType = data.schemeType
        this.parent = data.parent
        this.title_en = data.title_en
        this.title_tc = data.title_tc
        this.title_sc = data.title_sc
        this.content_en = data.content_en
        this.content_tc = data.content_tc
        this.content_sc = data.content_sc
        this.customizeLastModify =
          data.customizeLastModify != null
            ? new Date(data.customizeLastModify).toLocaleDateString('en-CA', {
                timeZone: 'Asia/Hong_Kong',
              })
            : null
        this.img = data.img
        this.yt_link = data.yt_link
        this.reg_fields[0].startDate = this.$moment(
          data.reg_round1_start
        ).format('yyyy-MM-DD')
        this.reg_fields[0].endDate = this.$moment(data.reg_round1_end).format(
          'yyyy-MM-DD'
        )
        this.reg_fields[1].startDate = this.$moment(
          data.reg_round2_start
        ).format('yyyy-MM-DD')
        this.reg_fields[1].endDate = this.$moment(data.reg_round2_end).format(
          'yyyy-MM-DD'
        )
        this.reg_fields[2].startDate = this.$moment(
          data.reg_round3_start
        ).format('yyyy-MM-DD')
        this.reg_fields[2].endDate = this.$moment(data.reg_round3_end).format(
          'yyyy-MM-DD'
        )
        this.reg_fields[3].startDate = this.$moment(
          data.reg_round4_start
        ).format('yyyy-MM-DD')
        this.reg_fields[3].endDate = this.$moment(data.reg_round4_end).format(
          'yyyy-MM-DD'
        )
        this.banner = data.banner
        this.parentIcon = data.parentIcon
          ? JSON.parse(data.parentIcon)
          : this.parentIcon
        this.starIcon = data.starIcon
          ? JSON.parse(data.starIcon)
          : this.starIcon
        this.web_portal = data.web_portal
        this.disabled = data.disabled
        this.isFinish = data.isFinish
        if (data.tabs !== null || data.tabs !== undefined)
          this.tabs = JSON.parse(data.tabs)
        if (!this.tabs) this.tabs = []
        this.year = data.year
        if (data.applyNotes !== null || data.applyNotes !== undefined)
          this.applyNotes = JSON.parse(data.applyNotes)
        if (!this.applyNotes) this.applyNotes = []
        this.needCategory = data.parent
          ? this.haveCat(data.parent)
          : data.needCategory
        this.customizeLastModify = data.customizeLastModify
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      addTabIndex: null,
      options_title_en: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      options_title_tc: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      options_title_sc: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      btn_disabled: false,
      schemeType: '',
      schemeTypeList: [],
      schemeList: [{ value: null, text: 'No Parent' }],
      // schemeList: [{ value: '1', text: '學校文化日計劃' }],
      reg_round_scds: [
        { key: 'roundScds', label: this.$t('scheme.round.title') },
        { key: 'startRegDate', label: this.$t('scheme.regStartDate') },
        { key: 'endRegDate', label: this.$t('scheme.regDeadline') },
      ],
      reg_round_spaps: [
        { key: 'roundSpaps', label: this.$t('scheme.round.title') },
        { key: 'startRegDate', label: this.$t('scheme.regStartDate') },
        { key: 'endRegDate', label: this.$t('scheme.regDeadline') },
      ],
      reg_round_aes: [
        { key: 'roundAes', label: this.$t('scheme.round.title') },
        { key: 'startRegDate', label: this.$t('scheme.regStartDate') },
        { key: 'endRegDate', label: this.$t('scheme.regDeadline') },
      ],
      reg_fields: [
        {
          roundScds: this.$t('scheme.round.round1'),
          roundSpaps: '正式報名時段',
          roundAes: '正式報名時段',
          startDate: null,
          endDate: null,
        },
        {
          roundScds: this.$t('scheme.round.round2'),
          roundSpaps: '逾期報名時段',
          startDate: null,
          endDate: null,
        },
        {
          roundScds: this.$t('scheme.round.round3'),
          startDate: null,
          endDate: null,
        },
        {
          roundScds: this.$t('scheme.round.round4'),
          startDate: null,
          endDate: null,
        },
      ],
      parentIcon: {
        img: '',
        popover: {
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
      },
      starIcon: {
        img: '',
        popover: {
          content_en: '',
          content_tc: '',
          content_sc: '',
        },
      },
      parent: null,
      id: null,
      slug: '',
      title_en: '',
      title_tc: '',
      title_sc: '',
      content_en: '',
      content_tc: '',
      content_sc: '',
      img: '',
      yt_link: '',
      banner: '',
      customizeLastModify: '',
      tabs: [],
      web_portal: 0,
      disabled: 0,
      isFinish: 0,
      year: null,
      applyNotes: [],
      error: null,
      needCategory: 0,
    }
  },
  computed: {
    getRegRound() {
      if (this.parent) {
        if (this.parent === 1) return this.reg_round_scds
        if (this.parent === 4) return this.reg_round_spaps
        if (this.parent === 5) return this.reg_round_aes
      }
      return null
    },
    slugState() {
      const format = /^[a-z0-9]+$/
      // console.log(format.test(this.slug), this.slug)
      return format.test(this.slug)
    },
    // hard code: scds/spaps/aes/aoa
    /* web_portal() {
      if (
        this.id === 1 ||
        this.id === 9 ||
        this.id === 4 ||
        this.id === 5 ||
        this.parent === 1 ||
        this.parent === 9 ||
        this.parent === 4 ||
        this.parent === 5
      )
        return 1
      else return 0
    }, */
    isSubScheme() {
      return this.parent
    },
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      tc.push(this.parentIcon.popover.content_tc)
      tc.push(this.starIcon.popover.content_tc)
      if (this.tabs.length > 0) {
        for (let i = 0; i < this.tabs.length; i++) {
          tc.push({
            title: this.tabs[i].title_tc,
            content: this.tabs[i].content_tc,
          })
        }
      }
      return tc
    },
  },
  watch: {
    /* disabled: {
      handler(newVal, oldVal) {
        if (newVal === '1') this.web_portal = 0
      },
      deep: true,
    }, */
  },
  methods: {
    isEPlatformSubscheme() {
      if (
        this.parent === 1 ||
        this.parent === 4 ||
        this.parent === 5 ||
        this.parent === 9
      )
        return true
    },
    isEPlatform() {
      if (
        this.id === 1 ||
        this.id === 4 ||
        this.id === 5 ||
        this.id === 9 ||
        this.id === 112 ||
        this.parent === 1 ||
        this.parent === 4 ||
        this.parent === 5 ||
        this.parent === 9 ||
        this.parent === 112
      )
        return true
    },
    haveCat(id) {
      const result = this.schemeList.find((s) => s.value === parseInt(id))
      // console.log('result:', result)
      return result ? result.needCategory : false
    },
    async previewEplatform() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }

      if (this.id === 1 || this.parent === 1)
        window.open(`${process.env.previewUrl}${this.$i18n.locale}/scheme/scds`)
      else if (this.id === 4 || this.parent === 4)
        window.open(
          `${process.env.previewUrl}${this.$i18n.locale}/scheme/spaps`
        )
      else if (this.id === 5 || this.parent === 5)
        window.open(`${process.env.previewUrl}${this.$i18n.locale}/scheme/aes`)
      else if (this.id === 9 || this.parent === 9)
        window.open(`${process.env.previewUrl}${this.$i18n.locale}/scheme/aoa`)
      else if (this.id === 112 || this.parent === 112)
        window.open(`${process.env.previewUrl}${this.$i18n.locale}`)
      else alert('E-Platform URL not found.')

      this.revertPreviewDbEntry(previewId)
      /* if (previewId) {
        // delete preview db entry after preview
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#d9534f',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.previewApiUrl}schemes/delete`,
            {
              id: previewId,
            }
          )
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)
        }
      } */
    },
    async previewWebpage() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
        await this.$axios.$post(
          `${process.env.previewApiUrl}preview/toWebPreview`
        )
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }

      if (this.parent) {
        window.open(
          `${process.env.previewWebUrl}${
            this.$i18n.locale
          }/subScheme/${this.slug.toLowerCase()}`
        )
      } else {
        window.open(
          `${process.env.previewWebUrl}${
            this.$i18n.locale
          }/scheme/${this.slug.toLowerCase()}`
        )
      }
      this.revertPreviewDbEntry(previewId)
      /* if (previewId) {
        // delete preview db entry after preview
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#d9534f',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.previewApiUrl}schemes/delete`,
            {
              id: previewId,
            }
          )
          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)
        }
      } */
    },
    async revertPreviewDbEntry(previewId) {
      if (previewId) {
        // delete preview db entry after preview
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#d9534f',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.previewApiUrl}schemes/delete`,
            {
              id: previewId,
            }
          )
          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)
        }
      } else if (this.action === 'edit') {
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#1cbcb4',
        })
        if (back.value) {
          // post original data to preview db
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}schemes/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)

          const result2 = await this.$axios.$post(
            `${process.env.previewApiUrl}schemes/update`,
            {
              // data,
              id: data.id,
              slug: data.slug,
              schemeType: data.schemeType,
              parent: data.parent,
              title_en: data.title_en,
              title_tc: data.title_tc,
              title_sc: data.title_sc,
              content_en: data.content_en,
              content_tc: data.content_tc,
              content_sc: data.content_sc,
              img: data.img,
              banner: data.banner,
              yt_link: data.yt_link,
              reg_round1_start: data.reg_round1_start
                ? this.$moment(data.reg_round1_start).format('yyyy-MM-DD')
                : null,
              reg_round1_end: data.reg_round1_end
                ? this.$moment(data.reg_round1_end).format('yyyy-MM-DD')
                : null,
              reg_round2_start: data.reg_round2_start
                ? this.$moment(data.reg_round2_start).format('yyyy-MM-DD')
                : null,
              reg_round2_end: data.reg_round2_end
                ? this.$moment(data.reg_round2_end).format('yyyy-MM-DD')
                : null,
              reg_round3_start: data.reg_round3_start
                ? this.$moment(data.reg_round3_start).format('yyyy-MM-DD')
                : null,
              reg_round3_end: data.reg_round3_end
                ? this.$moment(data.reg_round3_end).format('yyyy-MM-DD')
                : null,
              reg_round4_start: data.reg_round4_start
                ? this.$moment(data.reg_round4_start).format('yyyy-MM-DD')
                : null,
              reg_round4_end: data.reg_round4_end
                ? this.$moment(data.reg_round4_end).format('yyyy-MM-DD')
                : null,
              tabs: data.tabs ? JSON.parse(data.tabs) : null,
              parentIcon: data.parentIcon,
              starIcon: data.starIcon,
              web_portal: data.web_portal,
              disabled: data.disabled,
              isFinish: data.isFinish,
              year: data.year,
              applyNotes: data.applyNotes,
              // needCategory: data.needCategory,
              needCategory: data.parent
                ? this.haveCat(data.parent)
                : data.needCategory,
              customizeLastModify: data.customizeLastModify,
            }
          )

          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result2.error !== undefined) throw new Error(result2.error)
        }
      }
    },
    async callapi(url, previewId) {
      const regDate = this.reg_fields
      const today = this.$moment(new Date()).format('yyyy-MM-DD')
      if (!this.title_en || !this.title_tc || !this.title_sc) {
        this.error = 'error.title cannot be empty'
        return
      }
      if (this.customizeLastModify === '') {
        this.customizeLastModify = null
      }
      if (
        !regDate[0] &&
        (regDate[0].startDate <= today ||
          regDate[0].endDate <= regDate[0].startDate ||
          regDate[1].startDate <= regDate[0].endDate ||
          regDate[1].endDate <= regDate[1].startDate ||
          regDate[2].startDate <= regDate[1].endDate ||
          regDate[2].endDate <= regDate[2].startDate ||
          regDate[3].startDate <= regDate[2].endDate ||
          regDate[3].endDate <= regDate[3].startDate)
      ) {
        this.error = 'error.Registration Date Error'
        return
      }
      let result
      if (this.action === 'edit') {
        result = await this.$axios.$post(`${url}schemes/update`, {
          id: this.id,
          slug: this.slug.toLowerCase(),
          parent: this.parent,
          schemeType: this.schemeType,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          img: this.img,
          banner: this.banner,
          yt_link: this.yt_link,
          reg_round1_start:
            this.reg_fields[0].startDate !== 'Invalid date'
              ? this.reg_fields[0].startDate
              : null,
          reg_round1_end:
            this.reg_fields[0].endDate !== 'Invalid date'
              ? this.reg_fields[0].endDate
              : null,
          reg_round2_start:
            this.reg_fields[1].startDate !== 'Invalid date'
              ? this.reg_fields[1].startDate
              : null,
          reg_round2_end:
            this.reg_fields[1].endDate !== 'Invalid date'
              ? this.reg_fields[1].endDate
              : null,
          reg_round3_start:
            this.reg_fields[2].startDate !== 'Invalid date'
              ? this.reg_fields[2].startDate
              : null,
          reg_round3_end:
            this.reg_fields[2].endDate !== 'Invalid date'
              ? this.reg_fields[2].endDate
              : null,
          reg_round4_start:
            this.reg_fields[3].startDate !== 'Invalid date'
              ? this.reg_fields[3].startDate
              : null,
          reg_round4_end:
            this.reg_fields[3].endDate !== 'Invalid date'
              ? this.reg_fields[3].endDate
              : null,
          tabs: this.tabs.length > 0 ? this.tabs : null,
          parentIcon: JSON.stringify(this.parentIcon),
          starIcon: JSON.stringify(this.starIcon),
          web_portal: this.web_portal,
          disabled: this.disabled,
          isFinish: this.isFinish,
          year: this.year,
          applyNotes: this.applyNotes.length > 0 ? this.applyNotes : null,
          // needCategory: this.needCategory,
          needCategory: this.parent
            ? this.haveCat(this.parent)
            : this.needCategory,
          customizeLastModify: this.customizeLastModify,
        })
      } else {
        result = await this.$axios.$post(`${url}schemes/add`, {
          slug: this.slug.toLowerCase(),
          parent: this.parent,
          schemeType: this.schemeType,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          img: this.img,
          banner: this.banner,
          yt_link: this.yt_link,
          reg_round1_start:
            this.reg_fields[0].startDate !== 'Invalid date'
              ? this.reg_fields[0].startDate
              : null,
          reg_round1_end:
            this.reg_fields[0].endDate !== 'Invalid date'
              ? this.reg_fields[0].endDate
              : null,
          reg_round2_start:
            this.reg_fields[1].startDate !== 'Invalid date'
              ? this.reg_fields[1].startDate
              : null,
          reg_round2_end:
            this.reg_fields[1].endDate !== 'Invalid date'
              ? this.reg_fields[1].endDate
              : null,
          reg_round3_start:
            this.reg_fields[2].startDate !== 'Invalid date'
              ? this.reg_fields[2].startDate
              : null,
          reg_round3_end:
            this.reg_fields[2].endDate !== 'Invalid date'
              ? this.reg_fields[2].endDate
              : null,
          reg_round4_start:
            this.reg_fields[3].startDate !== 'Invalid date'
              ? this.reg_fields[3].startDate
              : null,
          reg_round4_end:
            this.reg_fields[3].endDate !== 'Invalid date'
              ? this.reg_fields[3].endDate
              : null,
          tabs: this.tabs.length > 0 ? this.tabs : null,
          parentIcon: JSON.stringify(this.parentIcon),
          starIcon: JSON.stringify(this.starIcon),
          web_portal: this.web_portal,
          disabled: this.disabled,
          isFinish: this.isFinish,
          year: this.year,
          applyNotes: this.applyNotes.length > 0 ? this.applyNotes : null,
          previewId,
          // needCategory: this.needCategory,
          needCategory: this.parent
            ? this.haveCat(this.parent)
            : this.needCategory,
          customizeLastModify: this.customizeLastModify,
        })
      }
      this.error = ''
      if (result.error !== undefined) throw new Error(result.error)
      // console.log('preview', result)
      return result && result.data ? result.data : null
    },
    getColor(slug) {
      switch (true) {
        case slug.includes('scds'):
          return '#FF739D'
        case slug.includes('aoa'):
          return '#4FB1E8'
        case slug.includes('spaps'):
          return '#9772EA'
        case slug.includes('aes'):
          return '#739e00'
        default:
          return ''
      }
    },
    filterRegFields(arr) {
      if (this.parent === 4) return [...arr].slice(0, 2)
      if (this.parent === 5) return [...arr].slice(0, 1)
      else return arr
    },
    slugformatter(value) {
      return value.toLowerCase()
    },
    isPortalScheme() {
      return (
        this.slug.includes('scds') ||
        this.slug.includes('aoa') ||
        this.slug.includes('spaps') ||
        this.slug.includes('aes')
      )
    },
    setSc(data) {
      // console.log(data)
      this.title_sc = data[0]
      this.content_sc = data[1]
      this.parentIcon.popover.content_sc = data[2]
      this.starIcon.popover.content_sc = data[3]
      if (this.tabs.length > 0) {
        for (let i = 0; i <= this.tabs.length; i++) {
          if (data[i + 4]) {
            this.tabs[i].title_sc = data[i + 4].title
            this.tabs[i].content_sc = data[i + 4].content
          }
        }
      }
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    getTabContent(evt, id, content) {
      if (id.startsWith('tab_editor')) {
        const tmp = id.split('_')
        this.tabs[tmp[3]]['content_' + tmp[2]] = content
      } else {
        this[id] = content
      }
    },
    async uploadImage(e, target) {
      try {
        const params = new FormData()
        await // console.log(e.target)
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            if (target && target === 'parentIcon')
              this.parentIcon.img = result.data.location
            else if (target && target === 'starIcon')
              this.starIcon.img = result.data.location
            else this[e.target.id] = result.data.location
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    async submit(e) {
      try {
        this.callapi(process.env.previewApiUrl)
        this.callapi(process.env.cloudApiUrl)
        /* const regDate = this.reg_fields
        const today = this.$moment(new Date()).format('yyyy-MM-DD')
        if (!this.title_en || !this.title_tc || !this.title_sc) {
          this.error = 'error.title cannot be empty'
          return
        }
        if (
          !regDate[0] &&
          (regDate[0].startDate <= today ||
            regDate[0].endDate <= regDate[0].startDate ||
            regDate[1].startDate <= regDate[0].endDate ||
            regDate[1].endDate <= regDate[1].startDate ||
            regDate[2].startDate <= regDate[1].endDate ||
            regDate[2].endDate <= regDate[2].startDate ||
            regDate[3].startDate <= regDate[2].endDate ||
            regDate[3].endDate <= regDate[3].startDate)
        ) {
          this.error = 'error.Registration Date Error'
          return
        }
        let result
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}schemes/update`,
            {
              id: this.id,
              slug: this.slug.toLowerCase(),
              parent: this.parent,
              schemeType: this.schemeType,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              banner: this.banner,
              yt_link: this.yt_link,
              reg_round1_start:
                this.reg_fields[0].startDate !== 'Invalid date'
                  ? this.reg_fields[0].startDate
                  : null,
              reg_round1_end:
                this.reg_fields[0].endDate !== 'Invalid date'
                  ? this.reg_fields[0].endDate
                  : null,
              reg_round2_start:
                this.reg_fields[1].startDate !== 'Invalid date'
                  ? this.reg_fields[1].startDate
                  : null,
              reg_round2_end:
                this.reg_fields[1].endDate !== 'Invalid date'
                  ? this.reg_fields[1].endDate
                  : null,
              reg_round3_start:
                this.reg_fields[2].startDate !== 'Invalid date'
                  ? this.reg_fields[2].startDate
                  : null,
              reg_round3_end:
                this.reg_fields[2].endDate !== 'Invalid date'
                  ? this.reg_fields[2].endDate
                  : null,
              reg_round4_start:
                this.reg_fields[3].startDate !== 'Invalid date'
                  ? this.reg_fields[3].startDate
                  : null,
              reg_round4_end:
                this.reg_fields[3].endDate !== 'Invalid date'
                  ? this.reg_fields[3].endDate
                  : null,
              tabs: this.tabs.length > 0 ? this.tabs : null,
              parentIcon: JSON.stringify(this.parentIcon),
              starIcon: JSON.stringify(this.starIcon),
              web_portal: this.web_portal,
              disabled: this.disabled,
              isFinish: this.isFinish,
              year: this.year,
              applyNotes: this.applyNotes.length > 0 ? this.applyNotes : null,
            }
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}schemes/add`,
            {
              slug: this.slug.toLowerCase(),
              parent: this.parent,
              schemeType: this.schemeType,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              banner: this.banner,
              yt_link: this.yt_link,
              reg_round1_start:
                this.reg_fields[0].startDate !== 'Invalid date'
                  ? this.reg_fields[0].startDate
                  : null,
              reg_round1_end:
                this.reg_fields[0].endDate !== 'Invalid date'
                  ? this.reg_fields[0].endDate
                  : null,
              reg_round2_start:
                this.reg_fields[1].startDate !== 'Invalid date'
                  ? this.reg_fields[1].startDate
                  : null,
              reg_round2_end:
                this.reg_fields[1].endDate !== 'Invalid date'
                  ? this.reg_fields[1].endDate
                  : null,
              reg_round3_start:
                this.reg_fields[2].startDate !== 'Invalid date'
                  ? this.reg_fields[2].startDate
                  : null,
              reg_round3_end:
                this.reg_fields[2].endDate !== 'Invalid date'
                  ? this.reg_fields[2].endDate
                  : null,
              reg_round4_start:
                this.reg_fields[3].startDate !== 'Invalid date'
                  ? this.reg_fields[3].startDate
                  : null,
              reg_round4_end:
                this.reg_fields[3].endDate !== 'Invalid date'
                  ? this.reg_fields[3].endDate
                  : null,
              tabs: this.tabs.length > 0 ? this.tabs : null,
              parentIcon: JSON.stringify(this.parentIcon),
              starIcon: JSON.stringify(this.starIcon),
              web_portal: this.web_portal,
              disabled: this.disabled,
              isFinish: this.isFinish,
              year: this.year,
              applyNotes: this.applyNotes.length > 0 ? this.applyNotes : null,
            }
          )
        }
        if (result.error !== undefined) throw new Error(result.error) */
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.go(-1)
          // this.$router.push(this.localePath('/admin/scheme'))
        } else if (this.action === 'add' || this.action === 'copy') {
          this.btn_disabled = true
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    addTab(tabIndex) {
      const i =
        tabIndex <= 0
          ? 0
          : tabIndex > this.tabs.length
          ? this.tabs.length
          : tabIndex - 1
      if (tabIndex) {
        this.tabs.splice(i, 0, {
          title_en: '',
          title_tc: '',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
          link_en: '',
          link_tc: '',
          link_sc: '',
        })
      } else {
        this.tabs.push({
          title_en: '',
          title_tc: '',
          title_sc: '',
          content_en: '',
          content_tc: '',
          content_sc: '',
          link_en: '',
          link_tc: '',
          link_sc: '',
        })
      }
    },
    deleteTab(counter) {
      this.tabs.splice(counter, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
.medium-editor {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
.tab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.component {
  text-align: left;
}
.btn {
  margin-bottom: 5px;
}
.ie-hint {
  color: red;
  display: none;
}

.mse-c1 {
  padding: 30px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-hint {
    display: block;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .mse-c1 {
    padding: 10px;
  }

  .m-period-table-roller {
    overflow: auto;
    scrollbar-width: thin;
  }

  .shadow {
    padding: 12px;
    margin: 30px -15px;
  }
}
</style>
<!--[if IE]>
  <style scoped>
    .ie-hint {
      display: block;
    }
  </style>
<![endif]-->
