<template>
  <!-- <div
    style="
      position: fixed;
      background-color: white;
      height: 100vh;
      width: 305px;
    "
  > -->
  <div>
    <div class="calendar_title border-bottom">
      {{ $t('home.calendar') }}
      <div v-b-modal.modal-calendar class="more btn">
        {{ $t('home.view_calendar') }}
        <b-icon icon="calendar2-check" style="vertical-align: sub" />
      </div>
    </div>
    <v-calendar
      title-position="left"
      :attributes="attrs"
      :locale="{
        id: i18nCalendar(),
        firstDayOfWeek: 0,
        // masks: { title: $moment(datePicked.date).format('yyyy年M月D日') },
        // masks: { title: 'YYYY年MMMD日' },
      }"
      :first-day-of-week="0"
      :min-date="new Date()"
      is-expanded
      class="calendar"
      :is-inline="true"
      :update-on-input="true"
      @dayclick="(day) => test(day)"
    />
    <!-- <p style="color: #1cbcb4; text-align: center">已獲接納的活動</p> -->
    <div style="min-height: 300px">
      <b-button-group class="cus-btn-grp">
        <b-button
          class="cus-btn"
          style="font-size: 96%"
          variant="outline-light"
          :pressed="showAppliedEvent"
          @click="
            () => {
              if (loggedIn) showAppliedEvent = true
              else $router.push(localePath('/login'))
            }
          "
          >{{ $t('home.applied_event') }}</b-button
        >
        <b-button
          class="cus-btn"
          style="font-size: 96%"
          variant="outline-light"
          :pressed="!showAppliedEvent"
          @click="() => (showAppliedEvent = false)"
          >{{ $t('home.upcoming_event') }}</b-button
        >
      </b-button-group>

      <div v-if="showAppliedEvent">
        <div v-if="applicationsSuccess">
          <div v-for="(application, index) in applicationsSuccess" :key="index">
            <a
              v-if="application.result_choice"
              v-b-modal.modal-calendar
              @click="
                () => {
                  eventSelected = application.event
                  $store.commit('tempStorage/storeEventSelected', eventSelected)
                }
              "
            >
              <EventCardForCalendar
                :event="application.event"
                :index="index"
                :bookmarks="bookmarks"
                :schemes="schemes"
                :result-slot="application.result_event_slot"
              />
            </a>
            <div
              v-if="index !== applicationsSuccess.length - 1"
              style="width=100%; border: 1px solid whitesmoke"
            ></div>
          </div>
        </div>
        <div v-if="!applicationsSuccess.length" class="result-txt">
          {{ $t('home.no_applied_event') }}
        </div>
      </div>
      <div v-else>
        <div v-if="filteredEvents.length < 1" class="result-txt">
          {{ $t('home.no_upcoming_event') }}
        </div>
        <div v-else>
          <div v-if="events" class="card-list">
            <div v-for="(event, index) in filteredEvents" :key="index">
              <!-- v-if="isOnSelectedDate(event.EventSlot)" -->
              <!-- :class="isAppliedEvent(event.code) ? 'green-border' : ''" -->
              <div
                v-b-modal.modal-calendar
                @click="
                  () => {
                    // eventSelected = event
                    $store.commit('tempStorage/storeEventSelected', event)
                  }
                "
              >
                <EventCardForCalendar
                  :event="event"
                  :index="index"
                  :bookmarks="bookmarks"
                  :schemes="schemes"
                  :result-slot="getSelectedDateEventSlotId(event)"
                  :is-applied="isAppliedEvent(event.code)"
                />
              </div>
              <div
                v-if="index !== filteredEvents.length - 1"
                style="width=100%; border: 1px solid whitesmoke"
              ></div>
            </div>
          </div>
        </div>
        <!-- <div v-if="!filteredEvents.length">{{ filteredEvents.length }}沒有相關活動</div> -->
      </div>
    </div>
    <full-calendar></full-calendar>
  </div>
</template>
<script>
// import Calendar from 'v-calendar/lib/components/calendar.umd'
// import DatePicker from 'v-calendar-ie11/src/components/DatePicker.vue'
// import Calendar from 'v-calendar-ie11/lib/v-calendar.umd'
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import FullCalendar from '../home/fullCalendar.vue'
import EventCardForCalendar from '~/components/common/event_card_for_calendar.vue'

export default {
  components: {
    FullCalendar,
    EventCardForCalendar,
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/venue`
      )
      this.venue = result.data.data
    } catch (err) {}
    try {
      if (this.eventsPortalStored.length) {
        this.events = this.eventsPortalStored.map((a) => ({ ...a }))
      } else {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/public/calevent`
        )
        this.events = result.data.data
      }
      this.filteredEvents = this.events.filter((e) => {
        if (this.isDisabledScheme(e.scheme)) return false
        if (e.disabled === 1) return false
        if (e.status === 2) return false
        return !!e.EventSlot.find((s) => {
          return (
            this.$moment(s.date).format('yyyy-MM-DD') ===
            this.$moment(new Date()).format('yyyy-MM-DD')
          )
        })
      })
    } catch (err) {}
    try {
      if (this.loggedIn) {
        const result = await this.$axios.get(`applications`)
        this.allApplications = result.data.data
        this.applications = this.getApplications
        // console.log(this.applications)
        this.resultText = this.applications.length ? '' : this.errorText

        this.applicationsSuccess = this.applications.filter((a) => {
          let isFutureEvent = false
          if (a.status === 1) {
            if (a.result_choice === 1) {
              if (
                this.$moment(a.firstChoiceDate).isAfter(
                  this.$moment().subtract(1, 'day'),
                  'day'
                )
              )
                isFutureEvent = true
            }
            if (a.result_choice === 2) {
              if (
                this.$moment(a.secondChoiceDate).isAfter(
                  this.$moment().subtract(1, 'day'),
                  'day'
                )
              )
                isFutureEvent = true
            }
          }
          return !!a.result_choice && isFutureEvent
        })
        this.showAppliedEvent = !!this.applicationsSuccess.length

        this.eventSelected = this.eventSelectedStored
          ? this.eventSelectedStored
          : this.applications
          ? this.applications[0].event
          : {}
        this.$store.commit('tempStorage/storeEventSelected', this.eventSelected)
      } else this.showAppliedEvent = false
    } catch (err) {}
    /* try {
      const result = await this.$axios.get(`${process.env.cloudApiUrl}bookmarks`)
      this.bookmarks = result.data.data
      // console.log(this.bookmarks)
      // this.syncBookmark()
    } catch (err) {} */
    try {
      if (this.schemeStored.length) {
        this.schemes = this.schemeStored.map((a) => ({ ...a }))
      } else {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}schemes/public/all`
        )
        this.schemes = result.data.data
        this.$store.commit(
          'tempStorage/importSchemes',
          result.data.data.map((a) => ({ ...a }))
        )
      }
    } catch (err) {}
    // default calendar
    try {
      this.test2()
    } catch (err) {}
  },
  data() {
    const todos = [
      {
        description: '古典合唱劇場—彼高利斯《聖母悼歌》',
        isComplete: false,
        dates: new Date(2020, 11, 30),
        color: 'pink',
      },
      {
        description: '音樂事務處「起舞人間」管樂音樂會',
        isComplete: true,
        dates: new Date(2020, 9, 27),
        color: 'pink',
      },
    ]
    return {
      showAppliedEvent: false,
      incId: todos.length,
      todos,
      applications: [],
      applicationsSuccess: [],
      schemes: [],
      events: [],
      eventSelected: {},
      filteredEvents: [],
      bookmarks: [],
      datePicked: new Date().toISOString(),
    }
  },

  computed: {
    eventsPortalStored() {
      return this.$store.state.tempStorage.eventsPortal
    },
    /* eventsApplied() {
      return this.events.filter((e) =>
        this.applications.find((a) => a.activityCode === e.code)
      )
    }, */
    attrs() {
      const arr = []
      /* arr = [
        ...this.todos.map((todo) => ({
          dates: todo.dates,
          dot: {
            color: todo.color,
            class: todo.isComplete ? 'opacity-75' : '',
          }, */
      /* popover: {
            label: todo.description,
          }, */
      /* customData: todo,
          highlight: {
            color: 'pink',
            contentStyle: { backgroundColor: '#e9f9f8', color: '#1fbcb4' },
          },
        })),
      ] */

      const arrEventsGrpbyDate = this.events.reduce((array = [], event) => {
        if (event.EventSlot.length) {
          /* event.EventSlot.map((a) => {
            arr[a.date] = arr[a.date] || []
            if (!arr[a.date].includes(event.title_tc))
              arr[a.date].push(event.title_tc)
          }) */
          /* event.EventSlot.map((a) => {
            const item = {
              date: a.date,
              title: [event.title_tc],
            }
            const find = arr.findIndex((f) => a.date === f.date)
            // console.log('find ' + find)
            if (find < 0) arr.push(item)
            else if (!arr[find].title.includes(event.title_tc))
              arr[find].title.push(event.title_tc)
          }) */
          event.EventSlot.map((a) => {
            const item = {
              date: a.date,
              title: event.title_tc, // event.parent ?
            }
            const find = array.findIndex((f) => {
              return a.date === f.date && event.title_tc === f.title
            })
            // if no event with the same name and date, push (eventslots of the same event may have same name and date)
            if (find < 0) array.push(item)
          })
        }
        return array
      }, [])
      // console.log(arrEventsGrpbyDate)
      // console.log(arrEventsGrpbyDate[0])

      arrEventsGrpbyDate.map((obj) => {
        /* const titles = obj.title.reduce((string, t) => {
          return string.concat(`<br/>${t}`)
        }, '') */
        if (new Date() <= new Date(obj.date)) {
          arr.push({
            dates: new Date(obj.date),
            highlight: {
              // dot: {
              color: 'teal',
              fillMode: 'light',
              contentStyle: { backgroundColor: '#e9f9f8', color: '#1fbcb4' },
            },
            // dayclick will cause popover show a wrong date ...
            /*  popover: {
              label: obj.title,
            }, */
          })
        }
      })
      arr.push({
        dates: new Date(),
        highlight: {
          color: 'teal',
          contentStyle: { backgroundColor: '#1fbcb4', color: '#fff' },
        },
      })
      return arr
    },
    eventSelectedStored() {
      return this.$store.state.tempStorage.eventSelected
    },
    eventsStored() {
      return this.$store.state.tempStorage.events
    },
    schemeStored() {
      return this.$store.state.tempStorage.schemes
    },
    getApplications() {
      let applications = this.allApplications
      applications.forEach((a) => {
        a.event = this.events.find((e) => e.id === a.event_id)
      })
      if (this.status >= 0)
        applications = applications.filter((a) => a.status === this.status)
      if (this.plan >= 0)
        applications = applications.filter((a) => a.event.scheme === this.plan)

      if (this.sortKey === 'code') {
        applications.sort((a, b) => {
          // console.log(a.code + ' ' + b.code)
          if (a.activityCode < b.activityCode) return -1
          if (a.activityCode > b.activityCode) return 1
          return 0
        })
      }

      if (this.sortKey === 'date') {
        applications.sort((a, b) => {
          // console.log(a.code + ' ' + b.code)
          if (a.submitTime < b.submitTime) return -1
          if (a.submitTime > b.submitTime) return 1
          return 0
        })
      }
      return applications
    },
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
  },
  methods: {
    isDisabledScheme(schemeId) {
      const found = this.schemes.find((d) => d.id === schemeId)
      return found.disabled === 1
    },
    i18nCalendar() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'en'
        case 'tc':
          return 'zh'
        case 'sc':
          return 'zh'
        default:
          return ''
      }
    },
    getSelectedDateEventSlotId(event) {
      let id = null
      // if no date is selected on the calendar, choose today
      const date = this.datePicked
        ? this.$moment(this.datePicked).format('yyyy-MM-DD')
        : this.$moment(new Date()).format('yyyy-MM-DD')

      // console.log('event.EventSlot' + JSON.stringify(event.EventSlot))

      // return id of eventslot on 'date'
      if (event.EventSlot) {
        const eventslot = event.EventSlot.find(
          (s) => this.$moment(s.date).format('yyyy-MM-DD') === date
        )
        id = eventslot ? eventslot.id : null
        // console.log(id, date, eventslot)
      }
      return id
    },
    test(day) {
      // manual calendar
      // console.log(day)
      this.datePicked = day.id
      this.filteredEvents = this.events.filter(
        (e) =>
          !!e.EventSlot.find((s) => {
            return this.$moment(s.date).format('yyyy-MM-DD') === day.id
          })
      )
      // console.log(this.filteredEvents)
    },
    test2() {
      // default calendar
      const d = new Date()
      this.filteredEvents = this.events.filter(
        (e) =>
          !!e.EventSlot.find((s) => {
            return (
              this.$moment(s.date).format('yyyy-MM-DD') ===
              d.toISOString().substring(0, 10)
            )
          })
      )
      // console.log(d)
    },
    isAppliedEvent(code) {
      return !!this.applications.find((a) => a.activityCode === code)
    },
  },
}
</script>
<style lang="scss">
.vc-w-full .vc-relative {
  .vc-grid-container {
    width: 100%;
    display: -ms-grid;
    &.vc-weeks {
      padding: 5px 6px 7px 6px;
    }
    .vc-day-content {
      min-height: 1.8rem;
      width: 2.58rem;
      height: 1.8rem;
      margin: 0.1rem auto;
    }
    .vc-day-content:hover {
      width: 1.8rem;
      background-color: whitesmoke;
    }
    .vc-day-content:focus {
      background-color: #3182ce;
      color: white;
      width: 1.8rem;
    }
  }
}
.vc-arrows-container {
  padding: 8px 10px;
}

.vc-opacity-0 {
  position: relative;
}

.vc-popover-content.direction-bottom {
  margin-top: 10px;
}
.vc-popover-caret {
  &.align-left {
    left: 18px;
  }
}
.vc-header {
  padding: 10px 10px 0 10px;
}

.vc-highlight {
  width: 28px;
  height: 28px;
}

.vc-bg-teal-600 {
  background-color: #1fbcb4 !important;
}

.vc-bg-teal-200 {
  background-color: #e9f9f8 !important;
  color: #1fbcb4 !important;
}
.vc-text-teal-900 {
  color: #1fbcb4 !important;
}

.vc-day-content:focus {
  background-color: #3182ce !important;
  color: white !important;
  width: 1.8rem;
}

.calendar .vc-container {
  border: none;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal-cal-content {
  padding: 0px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .calendar_title {
    font-size: 160%;
    color: #1cbcb4;
    margin-top: 0px;
    margin-left: 0.6em;
    font-weight: bold;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .calendar_title {
    font-size: 160%;
    color: #1cbcb4;
    margin-top: 0px;
    margin-left: 15px;
    font-weight: bold;
  }
}
</style>
<style lang="scss" scoped>
.result-txt {
  text-align: center;
}
.cus-btn {
  width: 50%;
  background-color: whitesmoke !important;
  color: #6a6a6a !important;
  border: 1.5px solid #f2f2f2 !important;
}
.cus-btn:hover {
  color: #1cbcb4 !important;
}
.cus-btn.active {
  background-color: white !important;
  color: #1cbcb4 !important;
  // font-weight: 500;
}
.cus-btn-grp {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
}
.more {
  border-color: #1fbcb4;
  border-radius: 500px;
  color: #1fbcb4;
  // width: fit-content;
  position: absolute;
  right: 5px;
  font-size: 57%;
}
.more:hover {
  background: #1fbcb4;
  color: white;
}
@media (min-width: 768px) {
  .card-list {
    // overflow: scroll;
    // height: calc(100vh - 465px);
    width: 100%;
  }
}
@media (max-width: 767px) {
  .card-list {
    // overflow: scroll;
    width: 100%;
    padding-bottom: 150px;
  }
}

@media only screen and (min-width: 540px) and (max-width: 768px),
  (min-width: 768px) and (orientation: portrait) {
  .more {
    right: 10%;
  }
}
</style>
