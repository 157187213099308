<template>
  <div>
    <a v-b-modal.modal-selectskl href="javascript:void(0)">
      {{ !!sarId ? '尋找其他學校' : '選擇學校' }}
    </a>
    <b-modal id="modal-selectskl" title="選擇學校" size="xl" hide-footer>
      <div class="my-3">
        已選擇：
        <span
          :class="getSklName(sarId) === '未選擇' ? 'not_selected' : 'resultskl'"
        >
          {{ getSklName(sarId) }}
        </span>
      </div>
      選擇學校：
      {{ `${selected && selected.length ? selected[0].schoolName : ''}` }}
      <b-button
        class="mx-2"
        variant="outline-success"
        size="sm"
        :disabled="!(selected && selected.length)"
        @click="() => (sarId = selected[0].id)"
      >
        確定
      </b-button>
      <b-button size="sm" @click="clearSelected"> Clear selected </b-button>

      <b-input-group class="my-3">
        <b-input v-model="keyword" type="text" placeholder="Search" />
        <b-input-group-append>
          <b-button @click="removeKeyword">×</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-table
        id="table"
        ref="selectableTable"
        striped
        hover
        small
        selectable
        selected-variant="info"
        select-mode="single"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        @row-selected="onRowSelected"
      >
        <template #cell(district)="row">
          {{ getDistrict(row.item.district) }}
        </template>
      </b-table>
      <p class="mt-3">Total: {{ total }} | Search : {{ rows }}</p>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table"
        align="center"
      ></b-pagination>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    schId: {
      type: Number,
      default: null,
    },
    school: {
      type: Array,
      default: () => [],
    },
    sklList: {
      type: Array,
      default: () => [{ value: null, text: '匯入申請後將顯示建議學校選項' }],
    },
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.localApiUrl}schools/sar/school`
      )
      this.allSkls = result.data.data
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      suggestSklList: this.sklList,
      sarId: this.schId,
      selected: this.school,
      perPage: 15,
      currentPage: 1,
      allSkls: [],
      keyword: null,
      districtList: [
        {
          value: 0,
          text: this.$t('school.districtList.select'),
        },
        { value: 1, text: '中西區 Central & Western District' },
        { value: 2, text: '東區 Eastern District' },
        { value: 3, text: '南區 Southern District' },
        { value: 4, text: '灣仔區 Wanchai District' },
        { value: 5, text: '九龍城區 Kowloon City District' },
        { value: 6, text: '觀塘區 Kuwn Tong District' },
        { value: 7, text: '深水埗區 Sham Shui Po District' },
        { value: 8, text: '黃大仙區 Wong Tai Sin District' },
        { value: 9, text: '油尖旺區 Yau Tsim Mong District' },
        { value: 10, text: '離島區 Islands District' },
        { value: 11, text: '葵青區 Kwai Tsing District' },
        { value: 12, text: '北區 North District' },
        { value: 13, text: '西貢區 Sai Kung District' },
        { value: 14, text: '沙田區 Shatin District' },
        { value: 15, text: '大埔區 Tai Po District' },
        { value: 16, text: '荃灣區 Tsuen Wan District' },
        { value: 17, text: '屯門區 Tuen Mun District' },
        { value: 18, text: '元朗區 Yuen Long District' },
      ],
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
    total() {
      return this.allSkls.length
    },
    items() {
      return this.keyword
        ? this.allSkls.filter((item) => {
            const id = item.id || ''
            const district = item.district || ''
            const schoolName = item.schoolName || ''
            const intSchoolNameChi = item.intSchoolNameChi || ''
            const edbCat = item.edbCat || ''
            return (
              id.toString().includes(this.getKeyword()) ||
              district.toString().includes(this.getKeyword()) ||
              schoolName.toLowerCase().includes(this.getKeyword()) ||
              intSchoolNameChi.toLowerCase().includes(this.getKeyword()) ||
              edbCat.toLowerCase().includes(this.getKeyword())
            )
          })
        : this.allSkls
    },
  },
  watch: {
    schId: {
      handler(newVal, oldVal) {
        this.sarId = newVal
      },
      deep: true,
    },
    school: {
      handler(newVal, oldVal) {
        this.selected = newVal
      },
      deep: true,
    },
    sarId: {
      handler(newVal, oldVal) {
        this.$emit('change', newVal, this.selected)
      },
      deep: true,
    },
    selected: {
      handler(newVal, oldVal) {
        // this.$emit('change', this.sarId, newVal)
      },
      deep: true,
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    getKeyword() {
      return this.keyword.toLowerCase().trim()
    },
    getSklName(sarId) {
      if (this.sarId && this.allSkls.length) {
        const found = this.allSkls.find((d) => {
          return d.id === this.sarId
        })
        return found
          ? `${sarId}. ${this.getDistrict(found.district)}: ${found.schoolName}`
          : '未選擇'
      }
      if (this.sarId && this.suggestSklList.length) {
        const found = this.suggestSklList.find((d) => {
          return d.id === this.sarId
        })
        return found
          ? `${sarId}. ${this.getDistrict(found.district)}: ${found.schoolName}`
          : '未選擇'
      }
      return '未選擇'
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    removeKeyword() {
      this.keyword = ''
    },

    getDistrict(id) {
      const found = this.districtList.find((d) => d.value === id)
      return found ? found.text : ''
    },
  },
}
</script>
<style lang="scss" scoped>
.resultskl {
  color: grey;
  background-color: white;
  padding: 5px 20px;
  line-height: 2.5;
}
.not_selected {
  color: #f76576;
  background-color: white;
  padding: 5px 20px;
}
</style>
