var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navmenu",
      class: _vm.adminNavDisplay ? "m-show" : "m-hide",
    },
    [
      _c(
        "b-nav",
        { staticClass: "navbar-nav", attrs: { vertical: "" } },
        [
          _c(
            "b-nav-item",
            {
              class: _vm.togglenav,
              staticStyle: { "padding-top": "15px" },
              on: { click: _vm.toggleNav },
            },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/admin") } }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.home")))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/news") } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.news")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/school") } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.school")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                {
                  attrs: {
                    to: _vm.localePath("/admin/schoolApplicationRecord"),
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("navbar_admin.schoolApplicationRecord"))
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            {
              class: _vm.togglenav,
              staticStyle: { "padding-bottom": "10px" },
              on: { click: _vm.toggleNav },
            },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/user") } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.user")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/change_request") } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("navbar_admin.change_request"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/cancelEvent") } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("navbar_admin.cancelEvent"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/page") } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.page")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/config") } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.config")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/emailPreview") } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("navbar_admin.emailPreview"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/emailTemplate") } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("navbar_admin.emailTemplate"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/smsTemplate") } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("navbar_admin.smsTemplate"))),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            {
              class: _vm.togglenav,
              staticStyle: { "padding-bottom": "10px" },
              on: { click: _vm.toggleNav },
            },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/role") } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.role")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/admin/feedback") } },
                [_c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.feedback")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-nav-item",
            { class: _vm.togglenav, on: { click: _vm.toggleNav } },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("navbar_admin.backhome")))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.loggedIn
            ? _c(
                "b-nav-item",
                { class: _vm.togglenav, on: { click: _vm.logout } },
                [_c("a", [_vm._v(_vm._s(_vm.$t("navbar.logout")))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "navicon-container align-center",
              on: { click: _vm.toggleNav },
            },
            [
              _c("div", { staticClass: "navicon" }),
              _vm._v(" "),
              _c("div", { staticClass: "navicon" }),
              _vm._v(" "),
              _c("div", { staticClass: "navicon" }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }