<template>
  <b-modal id="modal-2" ref="newsmodal2" scrollable size="lg">
    <template #modal-header="{ close }">
      <h5 center>{{ $t('home.news') }}</h5>
      <b-button size="sm" class="modal-close-button" @click="close()"
        >×</b-button
      >
    </template>
    <div v-if="getNews.length">
      <div v-for="n in getNews" :key="n.id" class="b-col-12 news-item">
        <div class="news-header">
          <div>
            <pre class="news-title">{{ i18nTitle(n) }}</pre>
          </div>
          <div v-if="n.date" class="news-date">
            <b-icon icon="calendar2-check" style="margin-right: 5px" />{{
              $moment(n.date).format('D.M.Y')
            }}
          </div>
        </div>
        <div class="news-content">
          <div v-html="i18nContent(n)"></div>
        </div>
      </div>
    </div>
    <h3 v-else>{{ $t('news.error') }}</h3>
    <template #modal-footer="" class="row" style="justify-content: flex-end">
      <b-col cols="8" style="margin: 0; padding: 0; height: 100%">
        <div style="margin: 0; padding: 0" v-html="i18nContent(config)"></div>
        <b-form-checkbox
          id="showNewsPopup"
          v-model="notShowNewsPopup"
          :value="true"
          :unchecked-value="false"
          @change="
            (checked) => {
              $store.commit('tempStorage/storeShowNewsPopup', checked)
            }
          "
        >
          {{ $t('home.showNews') }}
        </b-form-checkbox>
      </b-col>
      <b-col style="margin: 0; padding: 0; height: 100%">
        <img
          style="
            width: 160px;
            height: 76px;
            position: absolute;
            right: 0;
            bottom: 0;
          "
          :src="`${base}/img/logo_lcsd.svg`"
          alt="logo_lcsd"
        />
      </b-col>
    </template>
  </b-modal>
</template>

<script>
export default {
  // props: ['img', 'name'],
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}news/public/${this.$i18n.locale}`
      )
      this.news = result.data.data
      // console.log(this.news)
    } catch (err) {
      // console.log(err)
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}config/public/news_footer`
      )
      this.config = result.data.data[0]
    } catch (err) {
      // console.log(err)
    }
  },
  data() {
    return {
      // base: process.env.base,
      base: '',
      location: this.$route.path,
      notShowNewsPopup: this.$store.state.tempStorage.notShowNewsPopup,
      news: [],
      config: {},
    }
  },
  computed: {
    notShowNewsPopupStored() {
      return this.$store.state.tempStorage.notShowNewsPopup
    },
    getNews() {
      return this.news.filter((d) => {
        if (
          d.startDate &&
          this.$moment(d.startDate).isAfter(this.$moment(), 'day')
        )
          return false
        if (
          d.endDate &&
          this.$moment(d.endDate).isBefore(this.$moment(), 'day')
        )
          return false
        return true
      })
    },
  },
  /* mounted() {
    this.$nextTick(() => {})
    if (
      !this.notShowNewsPopupStored &&
      (this.location === '/en' ||
        this.location === '/tc' ||
        this.location === '/sc')
    )
      this.$refs.newsmodal2.show()
  }, */
  methods: {
    showModal() {
      this.notShowNewsPopup = this.$store.state.tempStorage.notShowNewsPopup
      this.$refs.newsmodal2.show()
    },
    // i18n
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.news-item {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  background: #f8f8f8;
  border-radius: 12px;
  margin-bottom: 10px;
}
.news-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.news-title {
  color: #1cbcb4;
  display: contents;
  font-weight: 500;
  font-size: 100%;
  margin-bottom: 15px;
  font-family: 'Noto Sans TC';
}
.news-content {
  display: table-cell;
  // padding-bottom: 20px;
  padding-top: 0px;
  text-align: left;
}
.news-date {
  @extend .news-content;
  white-space: nowrap;
  padding-right: 0px;
  color: #999999;
}
.modal-close-button {
  position: absolute;
  right: 45px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

:deep .img-responsive {
  width: 100% !important;
  height: auto;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .modal-close-button {
    right: 15px;
  }

  #modal-2 {
    padding-right: 0px !important;
  }

  .news-header {
    flex-direction: column;
  }

  .news-title {
    white-space: break-spaces;
  }

  .news-content {
    overflow-wrap: break-word;
  }
}
</style>
