<template>
  <div class="tab-cont">
    <div class="spaps-div">
      <b-row class="mb-3">
        <b-col md="11">
          <h3>❶ {{ $t('apply.spaps.tab_txt.choice.title') }}</h3>
          <div v-for="ch in localChoiceHint" :key="ch.id">
            {{ ch.id }}: {{ ch.value }}
          </div>
          <div v-for="(c, index) in localChoiceArray" :key="index" class="mb-3">
            <div v-if="edit">
              <div class="subtitle">
                {{ $t('apply.spaps.tab_txt.choice.title_a') }}{{ index + 1
                }}{{ $t('apply.spaps.tab_txt.choice.title_b') }}
                <span v-if="index === 0" class="red">*</span>
              </div>
              <b-form-select
                v-model="localChoiceArray[index]"
                :options="filterChoices(index)"
                :state="
                  validate || validateOnclick[0]
                    ? localChoiceArray.length === 1
                      ? localChoiceArray[index] !== -1
                      : index === localChoiceArray.length - 1 // the new selection box
                      ? null
                      : localChoiceArray.length > 1
                    : null
                "
              ></b-form-select>
            </div>
            <div v-else-if="localChoiceArray[index] !== -1">
              <div class="subtitle">
                {{ $t('apply.spaps.tab_txt.choice.title_a') }}{{ index + 1
                }}{{ $t('apply.spaps.tab_txt.choice.title_b') }}
              </div>
              <div v-if="c.event" class="choice">
                {{ getEventTitle(c) }}
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.classes') }}
            {{ localClassHints }}
          </div>
          <b-form-checkbox-group
            v-if="edit"
            id="checkbox-group-class"
            v-model="localClasses"
            :options="createOption1"
            name="flavour-1"
            @input="inputChanged"
          ></b-form-checkbox-group>
          <div v-else class="choice">
            {{ localClasses ? formatTargetInArray(localClasses) : '/' }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.eventGroup') }}
          </div>
          <b-form-input
            v-if="edit"
            v-model="localEventGroup"
            :placeholder="$t('apply.spaps.tab_txt.placeholder')"
            type="text"
            @input="inputChanged"
          />
          <div v-else class="choice">
            {{ localEventGroup !== null ? localEventGroup : '/' }}
          </div>
        </b-col>
      </b-row>
    </div>

    <hr />

    <div class="spaps-div">
      <h3>❷ {{ $t('apply.spaps.tab_txt.div2.title') }}</h3>

      <div id="event-title-1" class="event-title">
        {{ $t('apply.spaps.tab_txt.div2.title1') }}
      </div>
      <b-row>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title1a') }}
            <span class="red">*</span>
          </div>
          <b-form-select
            v-if="edit"
            v-model="localWorkshop.workshopFirstweekday"
            :options="weekdays"
            :state="
              validate || validateOnclick[1]
                ? !!localWorkshop.workshopFirstweekday
                : null
            "
            @input="inputChanged(1)"
          ></b-form-select>
          <div v-else class="choice">
            {{
              localWorkshop.workshopFirstweekday !== null
                ? localWorkshop.workshopFirstweekday
                : '/'
            }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title1b') }} (hh:mm - hh:mm)
            <span class="red">*</span>
          </div>
          <!-- <b-form-timepicker
            v-if="edit"
            v-model="workshop.workshopFirsttime"
            :locale="$i18n.locale"
          ></b-form-timepicker> -->
          <b-form-input
            v-if="edit"
            v-model="localWorkshop.workshopFirsttime"
            type="text"
            :state="
              validate || validateOnclick[2]
                ? checkTime(localWorkshop.workshopFirsttime)
                : null
            "
            @input="inputChanged(2)"
          />
          <div v-else class="choice">
            {{
              localWorkshop.workshopFirsttime !== null
                ? localWorkshop.workshopFirsttime
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <div id="event-title-2" class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.div2.title2') }}
      </div>
      <b-row>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title1a') }}
            <span class="red">*</span>
          </div>
          <b-form-select
            v-if="edit"
            v-model="localWorkshop.workshopSecondweekday"
            :options="weekdays"
            :state="
              validate || validateOnclick[3]
                ? !!localWorkshop.workshopSecondweekday
                : null
            "
            @input="inputChanged(3)"
          ></b-form-select>
          <div v-else class="choice">
            {{
              localWorkshop.workshopSecondweekday !== null
                ? localWorkshop.workshopSecondweekday
                : '/'
            }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title1b') }} (hh:mm - hh:mm)
            <span class="red">*</span>
          </div>
          <!-- <b-form-timepicker
            v-if="edit"
            v-model="workshop.workshopSecondtime"
            :locale="$i18n.locale"
          ></b-form-timepicker> -->
          <b-form-input
            v-if="edit"
            v-model="localWorkshop.workshopSecondtime"
            type="text"
            :state="
              validate || validateOnclick[4]
                ? checkTime(localWorkshop.workshopSecondtime)
                : null
            "
            @input="inputChanged(4)"
          />
          <div v-else class="choice">
            {{
              localWorkshop.workshopSecondtime !== null
                ? localWorkshop.workshopSecondtime
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <div id="event-title-3" class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.div2.title3') }}
      </div>
      <b-row>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title3a') }}
            <span class="red">*</span>
          </div>
          <!-- {{ localWorkshop.performFirstdate }} -->
          <!-- <v-date-picker
            v-if="edit"
            v-model="localWorkshop.performFirstdate"
            class="inline-block h-full"
            :mask="{
              input: 'YYYY-MM-DD',
            }"
          >
          </v-date-picker> -->
          <!-- <v-calendar
            v-if="edit"
            @dayclick="
              () => {
                alert('test hj5iklt,ghfm cvx')
              }
            "
          /> -->

          <b-form-datepicker
            v-if="edit"
            v-model="localWorkshop.performFirstdate"
            :min="today"
            :locale="$i18n.locale"
            :state="
              validate || validateOnclick[5]
                ? !!localWorkshop.performFirstdate
                : null
            "
            @input="inputChanged(5)"
          ></b-form-datepicker>
          <div v-else class="choice">
            {{
              localWorkshop.performFirstdate !== null
                ? localWorkshop.performFirstdate
                : '/'
            }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title1b') }} (hh:mm)
            <span class="red">*</span>
          </div>
          <!-- <b-form-timepicker
            v-if="edit"
            v-model="localWorkshop.performFirsttime"
            :locale="$i18n.locale"
            :state="validate ? !!localWorkshop.performFirsttime : null"
          ></b-form-timepicker> -->
          <b-form-input
            v-if="edit"
            v-model="localWorkshop.performFirsttime"
            type="text"
            :state="
              validate || validateOnclick[6]
                ? checkTime(localWorkshop.performFirsttime)
                : null
            "
            @input="inputChanged(6)"
          />
          <div v-else class="choice">
            {{
              localWorkshop.performFirsttime !== null
                ? localWorkshop.performFirsttime
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <div id="event-title-4" class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.div2.title4') }}
      </div>
      <b-row>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title3a') }}
            <span class="red">*</span>
          </div>
          <!-- {{ localWorkshop.performSeconddate }} -->
          <b-form-datepicker
            v-if="edit"
            v-model="localWorkshop.performSeconddate"
            :min="today"
            :locale="$i18n.locale"
            :state="
              validate || validateOnclick[7]
                ? !!localWorkshop.performSeconddate
                : null
            "
            @input="inputChanged(7)"
          ></b-form-datepicker>
          <div v-else class="choice">
            {{
              localWorkshop.performSeconddate !== null
                ? localWorkshop.performSeconddate
                : '/'
            }}
          </div>
        </b-col>
        <b-col>
          <div class="event-title">
            {{ $t('apply.spaps.tab_txt.div2.title1b') }} (hh:mm)
            <span class="red">*</span>
          </div>
          <!-- <b-form-timepicker
            v-if="edit"
            v-model="localWorkshop.performSecondtime"
            :locale="$i18n.locale"
            :state="validate ? !!localWorkshop.performSecondtime : null"
          ></b-form-timepicker> -->
          <b-form-input
            v-if="edit"
            v-model="localWorkshop.performSecondtime"
            type="text"
            :state="
              validate || validateOnclick[8]
                ? checkTime(localWorkshop.performSecondtime)
                : null
            "
            @input="inputChanged(8)"
          />
          <div v-else class="choice">
            {{
              localWorkshop.performSecondtime !== null
                ? localWorkshop.performSecondtime
                : '/'
            }}
          </div>
        </b-col>
      </b-row>

      <div id="event-title-5" class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.div2.title5') }}
        <span class="red">*</span>
      </div>
      <b-form-input
        v-if="edit"
        v-model="localWorkshop.anticipatedNo"
        type="number"
        :state="
          validate || validateOnclick[9]
            ? checkNumber(localWorkshop.anticipatedNo)
            : null
        "
        @input="inputChanged(9)"
      />
      <div v-else class="choice">
        {{
          localWorkshop.anticipatedNo !== null
            ? localWorkshop.anticipatedNo
            : '/'
        }}
      </div>

      <div id="event-title-6" class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.div2.title6') }}
        <span class="red">*{{ localAnticipatedClassHints }}</span>
      </div>
      <b-row>
        <b-col md="5">
          <b-form-checkbox-group
            v-if="edit"
            id="checkbox-group-anticipatedClass"
            v-model="selectAnticipatedClass"
            :options="createOption1"
            :state="
              validate || validateOnclick[10] ? checkAnticipatedClass() : null
            "
            @input="inputChanged(10)"
          ></b-form-checkbox-group>
          <div v-else class="choice">
            {{
              localWorkshop.anticipatedClass
                ? formatTargetInArray(localWorkshop.anticipatedClass)
                : '/'
            }}
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />
  </div>
</template>
<script>
export default {
  props: {
    validate: { type: Boolean, default: false },
    eventlist: {
      type: Array,
      default() {
        return []
      },
    },
    edit: { type: Boolean, default: false },
    choiceHint: {
      type: Array,
      default() {
        return []
      },
    },
    choiceArray: {
      type: Array,
      default() {
        return [-1]
      },
    },
    classHints: {
      type: String,
      default: null,
    },
    anticipatedClassHints: {
      type: String,
      default: null,
    },
    classes: {
      type: Array,
      default() {
        return []
      },
    },
    eventGroup: { type: String, default: '' },
    workshop: {
      type: Object,
      default() {
        return {
          workshopFirstweekday: '',
          workshopFirsttime: '',
          workshopSecondweekday: '',
          workshopSecondtime: '',
          performFirstdate: '',
          performFirsttime: '',
          performSeconddate: '',
          performSecondtime: '',
          anticipatedClass: [],
          anticipatedNo: '',
        }
      },
    },
  },
  data() {
    return {
      localChoiceHint: this.choiceHint,
      localClassHints: this.classHints,
      localAnticipatedClassHints: this.anticipatedClassHints,
      localChoiceArray: this.choiceArray,
      localClasses: this.classes,
      localEventGroup: this.eventGroup,
      localWorkshop: this.workshop,
      selectAnticipatedClass: [],
      //
      noOfFieldsToValidate: 10,
      validateOnclick: [],
      loading1: true,
      something: 'something',
      choice: [],

      weekdays: [
        { value: null, text: this.$t('apply.select') },
        { value: 1, text: this.$t('numbers.n1') },
        { value: 2, text: this.$t('numbers.n2') },
        { value: 3, text: this.$t('numbers.n3') },
        { value: 4, text: this.$t('numbers.n4') },
        { value: 5, text: this.$t('numbers.n5') },
        { value: 6, text: this.$t('numbers.n6') },
        { value: 7, text: this.$t('numbers.w7') },
      ],
      today: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
    }
  },
  computed: {
    createOption1() {
      const targetstring = 'p1,p2,p3,p4,p5,p6,f1,f2,f3,f4,f5,f6'
      const targetArray = targetstring.split(',')
      const options = []
      targetArray.map((t) => {
        if (t[0] === 'k') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) - 3),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 'p') {
          const item = {
            text: this.intToGrade(parseInt(t[1])),
            value: t,
          }
          options.push(item)
        }
        if (t[0] === 'f') {
          const item = {
            text: this.intToGrade(parseInt(t[1]) + 6),
            value: t,
          }
          options.push(item)
        }
      })
      return options
    },
  },
  watch: {
    classHints: {
      handler(newVal, oldVal) {
        this.localClassHints = newVal
      },
      deep: true,
    },
    anticipatedClassHints: {
      handler(newVal, oldVal) {
        this.localAnticipatedClassHints = newVal
      },
      deep: true,
    },
    localChoiceArray: {
      handler(newVal, oldVal) {
        const count = newVal.filter((d) => d === -1).length
        const reset = newVal.findIndex((d) => d === -1)
        const last = newVal.length - 1

        if (count === 0) {
          newVal.push(-1)
        } else if (reset !== last) {
          this.localChoiceArray = newVal.slice(0, reset)
        }

        this.inputChanged(0)
      },
      deep: true,
    },
    choiceHint: {
      handler(newVal, oldVal) {
        this.localChoiceHint = newVal
      },
      deep: true,
    },
    choiceArray: {
      handler(newVal, oldVal) {
        this.localChoiceArray = newVal
      },
      deep: true,
    },
    classes: {
      handler(newVal, oldVal) {
        this.localClasses = newVal
      },
      deep: true,
    },
    eventGroup: {
      handler(newVal, oldVal) {
        this.localEventGroup = newVal
      },
      deep: true,
    },
    workshop: {
      handler(newVal, oldVal) {
        this.localWorkshop = newVal
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => (this.loading1 = false), 500)
    })
    for (let i = 0; i < this.noOfFieldsToValidate; i++) {
      this.validateOnclick.push(false)
    }
  },
  methods: {
    filterChoices(index) {
      return this.eventlist.filter(
        (d) => !this.eventIsChosen(d.value.event, d.value.groupid, index)
      )
    },
    eventIsChosen(event, groupid, index) {
      // only consider choices with i < provided index
      const check = this.localChoiceArray.find((d, i) => {
        if (d && d.event && i < index) {
          return d.event === event && d.groupid === groupid
        }
        return false
      })
      return !!check
    },
    removeEle(arr, reset) {
      return arr.slice(0, reset)
    },
    inputChanged(fieldIndex) {
      this.localWorkshop.anticipatedClass = this.selectAnticipatedClass
      this.$emit(
        'input',
        this.localChoiceArray,
        this.localClasses,
        this.localEventGroup,
        this.localWorkshop,
        this.checkPassValidationTab1()
      )
      this.validateOnclick[fieldIndex] = true
    },
    checkTime(input) {
      const regex = new RegExp('[0-2][0-9]:[0-5][0-9]')
      return regex.test(input)
    },
    checkNumber(input) {
      return input > 0 && !input.toString().includes('.')
    },
    checkExist(input) {
      return !!input && input !== ''
    },
    checkAnticipatedClass() {
      return (
        this.localWorkshop.anticipatedClass !== null &&
        this.localWorkshop.anticipatedClass.length > 0
      )
    },
    checkPassValidationTab1() {
      return (
        this.localChoiceArray.length > 1 &&
        this.checkTime(this.localWorkshop.workshopFirsttime) &&
        this.checkTime(this.localWorkshop.workshopSecondtime) &&
        this.checkTime(this.localWorkshop.performFirsttime) &&
        this.checkTime(this.localWorkshop.performSecondtime) &&
        this.checkNumber(this.localWorkshop.anticipatedNo) &&
        this.checkAnticipatedClass() &&
        this.localWorkshop &&
        !!this.localWorkshop.workshopSecondweekday &&
        !!this.localWorkshop.workshopFirstweekday &&
        !!this.localWorkshop.performFirstdate &&
        !!this.localWorkshop.performSeconddate
      )
    },
    getEventTitle(choice) {
      const event = this.eventlist.find((d) => d.id === choice.event)
      let text = event
        ? `${
            event.organiser ? this.i18nTitle(JSON.parse(event.organiser)) : ''
          }${this.i18nTitle(event)}`
        : ''

      if (!this.edit && typeof event.spapsGroup === 'string')
        event.spapsGroup = JSON.parse(event.spapsGroup)

      /* if (event && event.spapsGroup && event.spapsGroup.length > 1) { */
      if (event && event.spapsGroup) {
        if (
          choice.groupid >= 0 &&
          this.i18nTitle(event.spapsGroup[choice.groupid]) !== ''
        )
          text = `${text} - 「${this.i18nTitle(
            event.spapsGroup[choice.groupid]
          )}」`
        if (choice.groupid >= 0 && choice.group !== 'default') {
          if (choice.group === 'primary') {
            text = `${text} (${
              this.i18nTitle(event.spapsGroup[choice.groupid].grpPrimary) !== ''
                ? this.i18nTitle(event.spapsGroup[choice.groupid].grpPrimary)
                : this.$t('school.primarysklgrp')
            })`
          } else if (choice.group === 'secondary') {
            text = `${text} (${
              this.i18nTitle(event.spapsGroup[choice.groupid].grpSecondary) !==
              ''
                ? this.i18nTitle(event.spapsGroup[choice.groupid].grpSecondary)
                : this.$t('school.secondarysklgrp')
            })`
          } else if (choice.group === 'special') {
            text = `${text} (${
              this.i18nTitle(event.spapsGroup[choice.groupid].grpSpecial) !== ''
                ? this.i18nTitle(event.spapsGroup[choice.groupid].grpSpecial)
                : this.$t('school.specsklgrp')
            })`
          }
        }
        /* event.spapsGroup.forEach((e, index) => {
          text = `${
            event.organiser ? this.i18nTitle(JSON.parse(event.organiser)) : ''
          }：${this.i18nTitle(event)}`
          if (this.i18nTitle(e)) text = `${text} - ${this.i18nTitle(e)}`
          if (
            e.groupname.primary === '' &&
            e.groupname.secondary === '' &&
            e.groupname.special === ''
          ) {
            // console.log(text)
          } else {
            if (e.groupname.primary !== '') {
              text = `${text} (${e.groupname.primary})`
            }
            if (e.groupname.secondary !== '') {
              text = `${text} (${e.groupname.secondary})`
            }
            if (e.groupname.special !== '') {
              text = `${text} (${e.groupname.special})`
            }
          }
        }) */
      }
      text = text.replace(/&nbsp;|<[^>]+>/g, '')
      text = text.replace(/&amp;/g, '&')
      return text
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },

    formatTarget(t) {
      // k1: -2, k2: -1, k3: 0,
      // p1: 1, p2: 2, p3: 3, p4: 4, p5: 5, p6: 6
      // f1: 7, f2: 8, f3: 9, f4: 10, f5: 11, f6: 12
      let result = ''
      const targetArray = t.split(',')
      result = this.formatTargetInArray(targetArray)
      return result
    },
    formatTargetInArray(targetArray) {
      if (targetArray.length === 0) {
        return '/'
      }
      let result = ''
      let targetArrayInInt = []
      targetArray =
        typeof targetArray === 'string' ? JSON.parse(targetArray) : targetArray

      targetArray.map((t) => {
        if (t[0] === 'k') targetArrayInInt.push(parseInt(t[1]) - 3)
        if (t[0] === 'p') targetArrayInInt.push(parseInt(t[1]))
        if (t[0] === 'f') targetArrayInInt.push(parseInt(t[1]) + 6)
      })
      targetArrayInInt = targetArrayInInt.sort((a, b) => a - b)
      // console.log('targetArrayInInt ' + targetArrayInInt)

      if (targetArrayInInt.length === 1)
        return this.intToGrade(targetArrayInInt[0])

      const check =
        targetArrayInInt[0] + targetArrayInInt.length - 1 ===
        targetArrayInInt[targetArrayInInt.length - 1]
      // console.log(check)
      if (check) {
        result = this.intToGrade(targetArrayInInt[0])
          .concat(this.$t('event.to')) // .concat(' - ')
          .concat(
            this.intToGrade(targetArrayInInt[targetArrayInInt.length - 1])
          )
      } else {
        targetArrayInInt.forEach((t, index) => {
          result = result.concat(this.intToGrade(t))
          result =
            index === targetArrayInInt.length - 1 ? result : result.concat(', ')
        })
      }
      return result
    },
    intToGrade(i) {
      let result = ''
      switch (this.$i18n.locale) {
        case 'en':
          result =
            i < 1
              ? 'K.'.concat((i + 3).toString())
              : i < 7
              ? 'P.'.concat(i.toString())
              : 'S.'.concat((i - 6).toString())
          break
        case 'tc':
          result =
            i === -2
              ? '幼兒班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        case 'sc':
          result =
            i === -2
              ? '幼儿班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
  },
}
</script>
<style lang="scss" scoped>
h3 {
  color: #fe3855;
}
.event-title {
  // color: #1fbcb4;
}
.choice {
  background-color: whitesmoke;
  padding: 5px;
  overflow-wrap: break-word;
}
.subtitle {
  color: #ffa500;
}
.spaps-div {
  // border: 2px solid #1fbcb4;
  padding: 10px 0;
  text-align: left;
}
.applyRemark {
  margin-top: 30px;
  text-align: left;
  padding: 15px;
  background-color: whitesmoke;
}
.is-valid {
  border-color: #28a745;
}
.is-invalid {
  border-color: #dc3545;
}
</style>
