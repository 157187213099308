<template>
  <div>
    <h1>參加者心聲</h1>
    <div v-for="(comment, counter) in data" :key="counter" class="comment-box">
      <p class="label">心聲{{ counter + 1 }}</p>
      <p v-if="lang === 0" class="label">{{ $t('tab.title.en') }}</p>
      <p v-if="lang === 1" class="label">{{ $t('tab.title.tc') }}</p>
      <p v-if="lang === 2" class="label">{{ $t('tab.title.sc') }}</p>
      <b-form-input
        v-model="comment.school"
        :placeholder="
          lang === 0
            ? $t('placeholder.school.en')
            : lang === 1
            ? $t('placeholder.school.tc')
            : $t('placeholder.school.sc')
        "
        type="text"
        trim
        @input="inputChanged"
      ></b-form-input>
      <b-form-input
        v-model="comment.author"
        :placeholder="
          lang === 0
            ? $t('placeholder.author.en')
            : lang === 1
            ? $t('placeholder.author.tc')
            : $t('placeholder.author.sc')
        "
        type="text"
        trim
        @input="inputChanged"
      ></b-form-input>
      <b-row>
        <b-col md="7">
          <p v-if="lang === 0" class="label">{{ $t('tab.content.en') }}</p>
          <p v-if="lang === 1" class="label">{{ $t('tab.content.tc') }}</p>
          <p v-if="lang === 2" class="label">{{ $t('tab.content.sc') }}</p>
          <medium-editor
            :text="comment.content"
            :options="options"
            class="content"
            @edit="
              (operation) => {
                comment.content = operation.api.origElements.innerHTML
                inputChanged()
              }
            "
          >
          </medium-editor>
        </b-col>
        <b-col md="5">
          <b-form-group label-for="img" :label="$t('scheme.img')">
            <b-form-input
              v-model="comment.img"
              type="text"
              @input="inputChanged"
            ></b-form-input>
            <b-form-file
              id="img"
              accept="image/*"
              @change="
                (e) => {
                  uploadImage(e, counter)
                  inputChanged()
                }
              "
            />
            <img :src="comment.img" style="max-height: 100px" alt="comment" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="outline-danger"
        style="margin-top: 15px"
        @click="delRow(counter)"
      >
        刪除心聲{{ counter + 1 }}
      </b-button>
    </div>
    <div v-if="edit">
      <b-button variant="outline-success" @click="addRow">{{
        $t('scheme.add_comment')
      }}</b-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    commentData: {
      type: Array,
      default: () => [],
    },
    lang: { type: Number, default: 0 },
    edit: { type: Boolean, default: false },
  },
  data() {
    return {
      img: [],
      uploadImageIndex: null,
      myText: 'default',
      options: {
        toolbar: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'unorderedlist',
            'orderedlist',
          ],
        },
      },
      data: this.commentData.map((a) => ({ ...a })),
      // noOfCol: this.commentData.length ? this.commentData[0].length - 1 : 1, // number of column minus header(1st col)
    }
  },
  computed: {
    /* data() {
      return this.commentData.map((a) => [...a])
    }, */
  },

  watch: {
    uploadImageIndex: {
      handler(newVal, oldVal) {
        this.data[newVal].img = this.img[newVal]
        this.inputChanged()
      },
      deep: true,
    },
    commentData: {
      handler(newVal, oldVal) {
        this.data = newVal.map((a) => ({ ...a }))
      },
      deep: true,
    },
  },
  methods: {
    async uploadImage(e, index) {
      try {
        const params = new FormData()
        await // console.log(e.target)
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            this.img[index] = result.data.location
            this.uploadImageIndex = index
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    inputChanged() {
      this.$emit('input', this.data)
    },
    addRow() {
      this.data.push({ school: '', author: '', content: '', img: '' })
      this.inputChanged()
    },
    delRow(index) {
      // this.data.pop()
      this.data.splice(index, 1)
      this.inputChanged()
    },
    i18nText(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.en
        case 'tc':
          return item.tc
        case 'sc':
          return item.sc
        default:
          return ''
      }
    },
  },
}
</script>
<style scoped lang="scss">
.content {
  background-color: white;
  border: 1px solid lightgrey;
  min-height: 200px;
}
.comment-box {
  border: 1px solid #ff9300;
  padding: 15px;
  margin: 15px 0;
}
</style>
