<template>
  <div>
    <div v-for="(item, index) in data" :key="index">
      <hr />
      <h3 v-if="type === 'soundFactory'">音頻 {{ index + 1 }}</h3>
      <h3
        v-if="type === 'history' || type === 'interview' || type === 'feedback'"
      >
        分段內容 {{ index + 1 }}
      </h3>

      <div v-if="!!item.title_tc || item.title_tc === ''">
        名稱({{ lang === 0 ? '英' : lang === 1 ? '繁' : '簡' }})
        <b-form-input
          v-if="lang === 0"
          v-model="item.title_en"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 1"
          v-model="item.title_tc"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 2"
          v-model="item.title_sc"
          type="text"
          trim
        ></b-form-input>
      </div>

      <div v-if="!!item.guest_tc || item.guest_tc === ''">
        嘉賓({{ lang === 0 ? '英' : lang === 1 ? '繁' : '簡' }})
        <b-form-input
          v-if="lang === 0"
          v-model="item.guest_en"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 1"
          v-model="item.guest_tc"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 2"
          v-model="item.guest_sc"
          type="text"
          trim
        ></b-form-input>
      </div>

      <div v-if="!!item.topic_tc || item.topic_tc === ''" class="mt-3">
        題目({{ lang === 0 ? '英' : lang === 1 ? '繁' : '簡' }})
        <b-form-input
          v-if="lang === 0"
          v-model="item.topic_en"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 1"
          v-model="item.topic_tc"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 2"
          v-model="item.topic_sc"
          type="text"
          trim
        ></b-form-input>
      </div>

      <div v-if="!!item.student_tc || item.student_tc === ''" class="mt-3">
        學生({{ lang === 0 ? '英' : lang === 1 ? '繁' : '簡' }})
        <b-form-input
          v-if="lang === 0"
          v-model="item.student_en"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 1"
          v-model="item.student_tc"
          type="text"
          trim
        ></b-form-input>
        <b-form-input
          v-if="lang === 2"
          v-model="item.student_sc"
          type="text"
          trim
        ></b-form-input>
      </div>

      <div v-if="!!item.content_tc || item.content_tc === ''" class="mt-3">
        內容({{ lang === 0 ? '英' : lang === 1 ? '繁' : '簡' }})
        <b-form-textarea
          v-if="lang === 0"
          v-model="item.content_en"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-form-textarea
          v-if="lang === 1"
          v-model="item.content_tc"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-form-textarea
          v-if="lang === 2"
          v-model="item.content_sc"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>

      <div v-if="!!item.audio || item.audio === ''" class="mt-3">
        Audio
        <b-form-input v-model="item.audio" type="text"></b-form-input>
        <b-form-file accept="audio/*" @change="(e) => uploadAudio(e, index)" />
      </div>

      <div v-if="!!item.main_audio || item.main_audio === ''" class="mt-3">
        Main Audio
        <b-form-input v-model="item.main_audio" type="text"></b-form-input>
        <b-form-file accept="audio/*" @change="(e) => uploadAudio(e)" />
      </div>

      <div v-if="!!item.img || item.img === ''" class="mt-3">
        圖片
        <b-form-input v-model="item.img" type="text"></b-form-input>
        <b-form-file
          accept="image/jpeg, image/png, image/gif"
          @change="(e) => uploadImage(e, index)"
        />
        <img :src="item.img" style="max-height: 200px" alt="icon item" />
      </div>

      <div v-if="!!item.color || item.color === ''" class="mt-3">
        背景顏色
        <b-form-input v-model="item.color" type="text" trim></b-form-input>
      </div>

      <div v-if="!!item.order || item.order === ''" class="mt-3">
        次序
        <b-form-input v-model="item.order" type="text" trim></b-form-input>
      </div>

      <b-button
        v-if="type === 'soundFactory'"
        class="mt-3"
        variant="outline-danger"
        @click="delItem(index)"
      >
        刪除 音頻 {{ index + 1 }}
      </b-button>
      <b-button
        v-if="type === 'history' || type === 'interview' || type === 'feedback'"
        class="mt-3"
        variant="outline-danger"
        @click="delItem(index)"
      >
        刪除 分段內容 {{ index + 1 }}
      </b-button>
    </div>

    <hr />
    <b-button
      v-if="type === 'soundFactory'"
      variant="outline-success"
      @click="addItem()"
    >
      添加 音頻
    </b-button>
    <b-button
      v-if="type === 'history' || type === 'interview' || type === 'feedback'"
      variant="outline-success"
      @click="addItem()"
    >
      添加 分段內容
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    passData: {
      type: Array,
      default: () => [],
    },
    type: { type: String, default: '' },
    lang: { type: Number, default: 0 },
    edit: { type: Boolean, default: false },
  },
  data() {
    return {
      uploadImageIndex: null,
      uploadAudioIndex: null,
      main_audio: '',
      img: [],
      audio: [],
      myText: 'default',
      options: {
        toolbar: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'unorderedlist',
            'orderedlist',
          ],
        },
      },
      data: [...this.passData],
      // data: this.passData.map((a) => [...a]),
      soundFactory: {
        img: '',
        title_en: '',
        title_tc: '',
        title_sc: '',
        audio: '',
        order: '',
      },
      history: {
        content_en: '',
        content_tc: '',
        content_sc: '',
        color: '',
        order: '',
      },
      interview: {
        guest_en: '',
        guest_tc: '',
        guest_sc: '',
        topic_en: '',
        topic_tc: '',
        topic_sc: '',
        audio: '',
        order: '',
      },
      feedback: {
        student_en: '',
        student_tc: '',
        student_sc: '',
        audio: '',
        color: '',
        order: '',
      },
    }
  },
  computed: {},

  watch: {
    main_audio: {
      handler(newVal, oldVal) {
        this.data[0].main_audio = newVal
        this.inputChanged()
      },
      deep: true,
    },
    uploadAudioIndex: {
      handler(newVal, oldVal) {
        this.data[newVal].audio = this.audio[newVal]
        this.inputChanged()
      },
      deep: true,
    },
    uploadImageIndex: {
      handler(newVal, oldVal) {
        this.data[newVal].img = this.img[newVal]
        this.inputChanged()
      },
      deep: true,
    },
    passData: {
      handler(newVal, oldVal) {
        this.data = [...newVal]
      },
      deep: true,
    },
  },
  methods: {
    addItem() {
      switch (this.type) {
        case 'soundFactory':
          this.data.push({
            img: '',
            title_en: '',
            title_tc: '',
            title_sc: '',
            audio: '',
            order: '',
          })
          this.inputChanged()
          return ''
        case 'history':
          this.data.push({
            content_en: '',
            content_tc: '',
            content_sc: '',
            color: '',
            order: '',
          })
          this.inputChanged()
          return ''
        case 'interview':
          this.data.push({
            guest_en: '',
            guest_tc: '',
            guest_sc: '',
            topic_en: '',
            topic_tc: '',
            topic_sc: '',
            audio: '',
            order: '',
          })
          this.inputChanged()
          return ''
        case 'feedback':
          this.data.push({
            student_en: '',
            student_tc: '',
            student_sc: '',
            audio: '',
            color: '',
            order: '',
          })
          this.inputChanged()
          return ''
        default:
          return ''
      }
    },
    delItem(index) {
      this.data.splice(index, 1)
      this.inputChanged()
    },
    async uploadImage(e, index) {
      try {
        const params = new FormData()
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            // this[e.target.id] = result.data.location
            this.img[index] = result.data.location
            this.uploadImageIndex = index
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    async uploadAudio(e, index) {
      try {
        const params = new FormData()
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            if (index >= 0) {
              this.audio[index] = result.data.location
              this.uploadAudioIndex = index
            } else this.main_audio = result.data.location
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    /* processEditOperation(operation) {
      this.myText = operation.api.origElements.innerHTML
      // console.log(this.myText)
    }, */
    inputChanged() {
      this.$emit('input', this.data)
    },
    addRow() {
      this.data.push([
        { content_en: '', content_tc: '', content_sc: '' },
        { content_en: '', content_tc: '', content_sc: '' },
        { content_en: '', content_tc: '', content_sc: '' },
      ])
      this.inputChanged()
    },
    delRow() {
      this.data.pop()
      this.inputChanged()
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nText(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.en
        case 'tc':
          return item.tc
        case 'sc':
          return item.sc
        default:
          return ''
      }
    },
  },
}
</script>
<style scoped lang="scss">
.first_td {
  background-color: #ffe086;
  border-radius: 0;
  border: none;
  color: black;
  font-weight: 500;
}
.other_td {
  background-color: #ffffde;
  border-radius: 0;
  border: none;
  color: black;
}
.table_impl {
  // border: 1px solid lightgrey;
  width: 100%;
  margin: 4px auto 25px auto;
}
.fontsize-85 {
  // font-size: 0.85em;
}
.td-cont > p {
  // margin-bottom: 0.1em;
}
.table_impl > tbody > tr > td:nth-child(1) {
  background-color: #ffe086; // #f5ddd4;
  width: 30%;
}
.table_impl > tbody > tr > td:nth-child(2) {
  width: 20%;
}
.table_impl > tbody > tr > td:nth-child(3) {
  width: 50%;
}
.table_impl > tbody > tr:nth-child(1) > td {
  background-color: #ffe086; // #f5ddd4;
}
.table_impl > tbody > tr > td {
  background-color: #ffffde; // #f5ddd4;
  // text-align: center;
  height: 2.3em;
  border: 1px solid lightgrey;
  padding: 10px;
}
</style>
