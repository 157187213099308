var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c(
        "header",
        { staticClass: "row header mobile-row" },
        [
          _vm.api && _vm.api.includes("preview")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [
                  _vm._v("\n      預覽模式 "),
                  _c("b-icon", { attrs: { icon: "eye" } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.api && _vm.api.includes("abo.lcsd.hksarg")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [_vm._v("\n      內聯網 " + _vm._s(_vm.version) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.api && _vm.api.includes("abotest.lcsd.hksarg")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [_vm._v("\n      內聯網 (Testing)\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("b-col", [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$i18n.locale === "en",
                  expression: "$i18n.locale === 'en'",
                },
              ],
              staticClass: "logoimg",
              attrs: {
                src: `${_vm.staticPrefix}img/logo_abo_en.png`,
                alt: "logo image",
              },
            }),
            _vm._v(" "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$i18n.locale === "tc",
                  expression: "$i18n.locale === 'tc'",
                },
              ],
              staticClass: "logoimg",
              attrs: {
                src: `${_vm.staticPrefix}img/logo_abo_ch.png`,
                alt: "封面圖片",
              },
            }),
            _vm._v(" "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$i18n.locale === "sc",
                  expression: "$i18n.locale === 'sc'",
                },
              ],
              staticClass: "logoimg",
              attrs: {
                src: `${_vm.staticPrefix}img/logo_abo_sc.png`,
                alt: "封面图片",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "fontstyle mobile-col-12",
              attrs: { sm: "7", md: "9", xl: "9" },
            },
            [
              _vm.loggedIn
                ? _c(
                    "div",
                    {
                      staticClass: "logined",
                      staticStyle: { color: "#fc7137", "font-size": "1.3em" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "usericon",
                          on: { click: _vm.toggleProfile },
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.user.name.substring(0, 1))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "noti", on: { click: _vm.toggleNoti } },
                        [
                          _c("b-icon", { attrs: { icon: "bell-fill" } }),
                          _vm._v(" "),
                          _vm.count_noti() > 10
                            ? _c("span", { staticClass: "noti-count" }, [
                                _vm._v(_vm._s("10+")),
                              ])
                            : _vm.count_noti() > 0
                            ? _c("span", { staticClass: "noti-count" }, [
                                _vm._v(_vm._s(_vm.count_noti())),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "m-nav-button",
                          on: { click: _vm.toggleAdminNav },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 65 49.79",
                              },
                            },
                            [
                              _c("title", [_vm._v("Asset 12")]),
                              _vm._v(" "),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Layer_2",
                                    "data-name": "Layer 2",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "Layer_1-2",
                                        "data-name": "Layer 1",
                                      },
                                    },
                                    [
                                      _c("line", {
                                        staticClass: "cls-1",
                                        attrs: {
                                          x1: "4",
                                          y1: "4",
                                          x2: "61",
                                          y2: "4",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("line", {
                                        staticClass: "cls-1",
                                        attrs: {
                                          x1: "4",
                                          y1: "24.9",
                                          x2: "61",
                                          y2: "24.9",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("line", {
                                        staticClass: "cls-1",
                                        attrs: {
                                          x1: "4",
                                          y1: "45.79",
                                          x2: "61",
                                          y2: "45.79",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("UserProfile", {
            attrs: { show: _vm.isShowProfile, user: _vm.user },
            on: { close: _vm.closeProfile },
          }),
          _vm._v(" "),
          _c("NotiBox", {
            ref: "noti",
            attrs: { show: _vm.isShowNoti, user: _vm.user },
            on: { close: _vm.closeNoti },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          staticStyle: {
            background: "#f8f8f8",
            "min-height": "calc(100vh - 54px)",
          },
        },
        [
          _c(
            "div",
            { staticClass: "nav-container", attrs: { md: "1" } },
            [_c("NavBar", { ref: "adminNav" })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "content d-12",
              staticStyle: { padding: "0px", "padding-top": "75px" },
              attrs: { sm: "12" },
            },
            [
              _c(
                "b-container",
                {
                  staticStyle: {
                    "min-height": "calc(100vh - 100px - 95px)",
                    "margin-bottom": "100px",
                  },
                },
                [_c("b-col", { attrs: { md: "12" } }, [_c("Nuxt")], 1)],
                1
              ),
              _vm._v(" "),
              _c("Footer"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }