export const state = () => ({
  profile: {},
  auth: {
    token: undefined,
    refreshToken: undefined,
    loggedIn: false,
    user: undefined,
    admin: false,
    nextToken: undefined,
    exp: undefined,
    name: undefined,
    permissionList: undefined,
    role: undefined,
    school: undefined,
    schoolAdmin: false,
  },
  unbookmark: {
    events: [],
  },
  setting: {
    fontsize: 1,
  },
  ref: undefined,
})

export const mutations = {
  token(state, data) {
    state.auth.token = data.token
    state.auth.refreshToken = data.refreshToken
    const t = new Date()
    t.setSeconds(t.getSeconds() + 600)
    state.auth.nextToken = t
  },
  userInfo(state, data) {
    state.auth.loggedIn = true
    state.auth.user = data.user
    state.auth.admin = data.admin || data.editor
    state.auth.exp = data.exp
    state.auth.name = data.name
    state.auth.permissionList = data.permissionList
    state.auth.role = data.role
    state.auth.school = data.school
    state.auth.schoolAdmin = data.schoolAdmin
  },
  userProfile(state, data) {
    state.profile = data
  },
  logout(state) {
    state.auth = {
      token: '',
      refreshToken: '',
      loggedIn: false,
      user: undefined,
      schoolAdmin: false,
      admin: false,
      nextToken: undefined,
      exp: undefined,
    }
  },
  changeFont(state, data) {
    state.setting.fontsize = data
  },
  updateRef(state, data) {
    state.ref = data
  },
  unbookmark(state, data) {
    state.unbookmark.events.push(data)
  },
  getunbookmark(state, data) {
    return state.unbookmark.events
  },
}

export const actions = {
  async nuxtServerInit({ state, commit, dispatch }, { req }) {},
  async userInit({ app, state, commit }, data) {
    try {
      this.$axios.setToken(data.token, 'Bearer')
      const userInfo = await this.$axios.get(`users/me`)
      // console.log(userInfo)
      commit('token', data)
      commit('userInfo', userInfo.data.data)
    } catch (error) {
      // console.log(error)
    }
  },
  async logout({ app, state, commit, redirect }) {
    try {
      await this.$axios.post(`users/logout`)
    } catch (err) {
      await this.$axios.post(`users/revoke_token`, {
        refreshToken: state.auth.refreshToken,
      })
    } finally {
      commit('logout')
    }
  },
  async refreshToken({ state, commit, dispatch }) {
    try {
      const result = await this.$axios.post(`users/refresh_token`, {
        refreshToken: state.auth.refreshToken,
      })
      commit('token', result.data.data)
    } catch (err) {
      dispatch('logout')
    }
  },
}
