<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="10">
      <div class="form">
        <b-form method="post" @submit.prevent="submit">
          <div class="row">
            <div class="col-sm-5">
              <b-form-group id="slug" label-for="slug">
                <p class="label">{{ $t('scheme.slug') }}</p>
                <b-form-input
                  v-model="slug"
                  type="text"
                  required
                  trim
                ></b-form-input>
              </b-form-group>

              <b-form-group label-for="img" :label="$t('scheme.img')">
                <b-form-input v-model="img" type="text"></b-form-input>
                <b-form-file id="img" accept="image/*" @change="uploadImage" />
                <img :src="img" style="max-height: 100px" alt="other page" />
              </b-form-group>
            </div>

            <div class="col-sm-7">
              <b-tabs content-class="mt-3">
                <b-tab :title="$t('scheme.title.en')">
                  <b-form-group id="title_en" label-for="title_en">
                    <b-form-input
                      v-model="title_en"
                      :placeholder="$t('placeholder.title.en')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
                <b-tab :title="$t('scheme.title.tc')">
                  <b-form-group id="title_tc" label-for="title_tc">
                    <b-form-input
                      v-model="title_tc"
                      :placeholder="$t('placeholder.title.tc')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
                <b-tab :title="$t('scheme.title.sc')">
                  <b-form-group id="title_sc" label-for="title_sc">
                    <b-form-input
                      v-model="title_sc"
                      :placeholder="$t('placeholder.title.sc')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
              </b-tabs>

              <b-form-group id="parent" label-for="parent">
                <p class="label">{{ $t('event.scheme') }}</p>
                <b-form-select
                  v-model="scheme"
                  :options="schemeList"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div>
            <div class="shadow my-3 mse-c1">
              <b-form-group id="content" label-for="content">
                <b-tabs content-class="mt-3">
                  <b-tab :title="$t('event.content.en')">
                    <tinymceEditor
                      id="content_en"
                      :value="content_en"
                      @writeContent="getContent"
                    />
                  </b-tab>
                  <b-tab :title="$t('event.content.tc')">
                    <tinymceEditor
                      id="content_tc"
                      :value="content_tc"
                      @writeContent="getContent"
                    />
                  </b-tab>
                  <b-tab :title="$t('event.content.sc')">
                    <tinymceEditor
                      id="content_sc"
                      :value="content_sc"
                      @writeContent="getContent"
                    />
                  </b-tab>
                </b-tabs>
              </b-form-group>
            </div>
          </div>

          <!-- <div v-if="(parent && !isPortalScheme()) || isPortalScheme()">
            <h1 v-if="isPortalScheme() && !parent">
              以下內容只顯示於E-Platform
            </h1>
            <h1 v-else-if="isPortalScheme()">以下內容只顯示於網站</h1> -->
          <div>
            <div class="shadow my-3 mse-c1">
              <b-button variant="outline-success" @click="addTab">{{
                $t('scheme.add_tab')
              }}</b-button>

              <b-tabs content-class="mt-3">
                <b-tab
                  v-for="(tab, counter) in tabs"
                  :key="counter"
                  class="tab"
                >
                  <template #title>
                    {{ i18nTitle(tab) }}
                    <b-button
                      size="sm"
                      variant="danger"
                      style="border-radius: 500%"
                      @click.prevent="deleteTab(counter)"
                    >
                      ×
                    </b-button>
                  </template>
                  <b-form-group id="tabs" label-for="tabs">
                    <b-tabs content-class="mt-3">
                      <b-tab :title="$t('g.en')">
                        <p class="label">{{ $t('tab.title.en') }}</p>
                        <medium-editor
                          :text="tab.title_en"
                          :options="options_title_en"
                          class="medium-editor"
                          @edit="
                            (operation) => {
                              tab.title_en =
                                operation.api.origElements.innerHTML
                            }
                          "
                        ></medium-editor>
                        <!-- <b-form-input
                          v-model="tab.title_en"
                          :placeholder="$t('placeholder.title.en')"
                          type="text"
                          trim
                        ></b-form-input> -->
                        <p class="label">{{ $t('tab.content.en') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_en_' + counter"
                          :value="tab.content_en"
                          @writeContent="getTabContent"
                        />
                        <CommentForm
                          v-if="tab.commentlist_en"
                          v-model="tab.commentlist_en"
                          :comment-data="tab.commentlist_en"
                          :lang="0"
                          :edit="true"
                        />
                      </b-tab>
                      <b-tab :title="$t('g.tc')">
                        <p class="label">{{ $t('tab.title.tc') }}</p>
                        <medium-editor
                          :text="tab.title_tc"
                          :options="options_title_tc"
                          class="medium-editor"
                          @edit="
                            (operation) => {
                              tab.title_tc =
                                operation.api.origElements.innerHTML
                            }
                          "
                        ></medium-editor>
                        <!-- <b-form-input
                          v-model="tab.title_tc"
                          :placeholder="$t('placeholder.title.tc')"
                          type="text"
                          trim
                        ></b-form-input> -->
                        <p class="label">{{ $t('tab.content.tc') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_tc_' + counter"
                          :value="tab.content_tc"
                          @writeContent="getTabContent"
                        />
                        <CommentForm
                          v-if="tab.commentlist_tc"
                          v-model="tab.commentlist_tc"
                          :comment-data="tab.commentlist_tc"
                          :lang="1"
                          :edit="true"
                        />
                      </b-tab>
                      <b-tab :title="$t('g.sc')">
                        <p class="label">{{ $t('tab.title.sc') }}</p>
                        <medium-editor
                          :text="tab.title_sc"
                          :options="options_title_sc"
                          class="medium-editor"
                          @edit="
                            (operation) => {
                              tab.title_sc =
                                operation.api.origElements.innerHTML
                            }
                          "
                        ></medium-editor>
                        <!-- <b-form-input
                          v-model="tab.title_sc"
                          :placeholder="$t('placeholder.title.sc')"
                          type="text"
                          trim
                        ></b-form-input> -->
                        <p class="label">{{ $t('tab.content.sc') }}</p>
                        <tinymceEditor
                          :id="'tab_editor_sc_' + counter"
                          :value="tab.content_sc"
                          @writeContent="getTabContent"
                        />
                        <CommentForm
                          v-if="tab.commentlist_sc"
                          v-model="tab.commentlist_sc"
                          :comment-data="tab.commentlist_sc"
                          :lang="2"
                          :edit="true"
                        />
                      </b-tab>
                    </b-tabs>
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </div>
          </div>

          <p class="label">{{ $t('news.order') }}</p>
          <b-form-input
            id="orders"
            v-model="orders"
            type="number"
            trim
          ></b-form-input>

          <b-form-group id="disabled">
            <b-form-checkbox v-model="disabled" value="1" unchecked-value="0">{{
              $t('news.disabled')
            }}</b-form-checkbox>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div>
            <b-button
              id="submit"
              type="submit"
              variant="outline-success"
              :disabled="btn_disabled"
              >{{ $t('action.submit') }}
            </b-button>
            <b-button variant="outline-warning" @click="previewWebpage()">
              {{ $t('action.preview') }} (Webpage)
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
import TinymceEditor from '~/components/admin/tinymceEditor'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    TinymceEditor,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      const schemel = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/getList`
      )
      let schemeListData = schemel.data.data
      schemeListData.sort((a, b) => {
        if (a.slug < b.slug) return -1
        if (a.slug > b.slug) return 1
        return 0
      })

      schemeListData = schemeListData.map((d) => {
        d.value = d.id
        d.text = d.title_tc
        // delete d.id
        delete d.title_tc
        return d
      })

      const scheme = schemeListData.find((d) => {
        return d.id === parseInt(this.$route.query.subscheme)
      })
      const parentId = scheme ? scheme.parent : null
      if (parentId) {
        schemeListData = schemeListData.filter(
          (d) => d.parent === parentId || d.id === parentId
        )
      }

      this.schemeList = this.schemeList.concat(schemeListData)

      if (this.action === 'edit' || this.action === 'copy') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}schemeCommentPages/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.slug = data.slug
        this.scheme = data.scheme
        this.parent = data.parent
        this.title_en = data.title_en
        this.title_tc = data.title_tc
        this.title_sc = data.title_sc
        this.content_en = data.content_en
        this.content_tc = data.content_tc
        this.content_sc = data.content_sc
        this.img = data.img
        this.order = data.order
        this.disabled = data.disabled
        if (data.tabs !== null || data.tabs !== undefined)
          this.tabs = JSON.parse(data.tabs)
        if (!this.tabs) this.tabs = []
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      options_title_en: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      options_title_tc: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      options_title_sc: {
        toolbar: {
          buttons: ['bold', 'italic'],
        },
        disableReturn: true,
      },
      orders: null,
      btn_disabled: false,
      schemeList: [{ value: null, text: 'No Parent' }],
      scheme: '',
      parent: null,
      id: null,
      slug: '',
      title_en: '',
      title_tc: '',
      title_sc: '',
      content_en: '',
      content_tc: '',
      content_sc: '',
      img: null,
      tabs: [],
      order: null,
      disabled: 0,
      error: null,
    }
  },
  computed: {
    isSubScheme() {
      return this.parent
    },
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      if (this.tabs.length > 0) {
        this.tabs.forEach((tab) => {
          tc.push({
            title: tab.title_tc,
            content: tab.content_tc,
          })
          if (tab.commentlist_tc)
            tab.commentlist_tc.forEach((entry) =>
              tc.push({
                school: entry.school,
                author: entry.author,
                content: entry.content,
              })
            )
        })
      }
      return tc
    },
  },
  methods: {
    isPortalScheme() {
      return (
        this.slug.includes('scds') ||
        this.slug.includes('aoa') ||
        this.slug.includes('spaps') ||
        this.slug.includes('aes')
      )
    },
    setSc(data) {
      this.title_sc = data.shift()
      this.content_sc = data.shift()
      if (this.tabs.length > 0) {
        for (let i = 0; i < this.tabs.length; i++) {
          const temp = data.shift()
          this.tabs[i].title_sc = temp.title
          this.tabs[i].content_sc = temp.content
          if (this.tabs[i].commentlist_tc) {
            this.tabs[i].commentlist_sc = this.tabs[i].commentlist_tc.map(
              (a) => ({ ...a })
            )
            this.tabs[i].commentlist_sc.forEach((entry) => {
              const temp2 = data.shift()
              entry.school = temp2.school
              entry.author = temp2.author
              entry.content = temp2.content
            })
          }
        }
      }
      /* this.title_sc = data[0]
      this.content_sc = data[1]
      if (this.tabs.length > 0) {
        for (let i = 1; i <= this.tabs.length; i++) {
          this.tabs[i - 1].title_sc = data[i + 1].title
          this.tabs[i - 1].content_sc = data[i + 1].content
        }
      } */
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    getTabContent(evt, id, content) {
      if (id.startsWith('tab_editor')) {
        const tmp = id.split('_')
        this.tabs[tmp[3]]['content_' + tmp[2]] = content
      } else {
        this[id] = content
      }
    },
    async uploadImage(e) {
      try {
        const params = new FormData()
        await // console.log(e.target)
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            this[e.target.id] = result.data.location
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    async submit(e) {
      try {
        this.callapi(process.env.previewApiUrl)
        this.callapi(process.env.cloudApiUrl)

        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.go(-1)
          // this.$router.push(this.localePath('/admin/scheme'))
        } else if (this.action === 'add') {
          this.btn_disabled = true
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async previewWebpage() {
      let previewId = null
      try {
        const data = await this.callapi(process.env.previewApiUrl)
        previewId = data && data.previewId ? data.previewId : null
        await this.$axios.$post(
          `${process.env.previewApiUrl}preview/toWebPreview`
        )
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }

      if (this.parent) {
        window.open(
          `${process.env.previewWebUrl}${
            this.$i18n.locale
          }/commentPage/${this.slug.toLowerCase()}`
        )
      } else if (this.tabs.length > 0) {
        console.log(this.tabs)
        if (
          this.tabs.commentlist_en ||
          this.tabs.commentlist_tc ||
          this.tabs.commentlist_sc
        ) {
          window.open(
            `${process.env.previewWebUrl}${
              this.$i18n.locale
            }/commentList/${this.slug.toLowerCase()}`
          )
        } else {
          window.open(
            `${process.env.previewWebUrl}${
              this.$i18n.locale
            }/commentPage/${this.slug.toLowerCase()}`
          )
        }
      } else {
        window.open(
          `${process.env.previewWebUrl}${
            this.$i18n.locale
          }/commentList/${this.slug.toLowerCase()}`
        )
      }
      this.revertPreviewDbEntry(previewId)
    },
    async callapi(url, previewId) {
      if (!this.title_en || !this.title_tc || !this.title_sc) {
        this.error = 'error.title cannot be empty'
        return
      }
      let result
      if (this.action === 'edit') {
        result = await this.$axios.$post(`${url}schemeCommentPages/update`, {
          id: this.id,
          slug: this.slug,
          parent: this.parent,
          scheme: this.scheme,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          img: this.img,
          orders: this.orders,
          disabled: this.disabled,
          tabs: this.tabs.length > 0 ? JSON.stringify(this.tabs) : null,
          previewId,
        })
      } else {
        result = await this.$axios.$post(`${url}schemeCommentPages/add`, {
          slug: this.slug,
          parent: this.parent,
          scheme: this.scheme,
          title_en: this.title_en,
          title_tc: this.title_tc,
          title_sc: this.title_sc,
          content_en: this.content_en,
          content_tc: this.content_tc,
          content_sc: this.content_sc,
          img: this.img,
          orders: this.orders,
          disabled: this.disabled,
          tabs: this.tabs.length > 0 ? JSON.stringify(this.tabs) : null,
        })
      }
      if (result.error !== undefined) throw new Error(result.error)
    },
    async revertPreviewDbEntry(previewId) {
      if (previewId) {
        // delete preview db entry after preview
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#d9534f',
        })
        if (back.value) {
          const result = await this.$axios.$post(
            `${process.env.previewApiUrl}schemeCommentPages/delete`,
            {
              id: previewId,
            }
          )
          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)
        }
      } else if (this.action === 'edit') {
        const back = await this.$swal({
          text: `已完成預覽？`,
          icon: 'question',
          confirmButtonText: '是',
          confirmButtonColor: '#1cbcb4',
        })
        if (back.value) {
          // post original data to preview db
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}schemeCommentPages/${this.$route.params.id}`
          )
          const data = result.data.data[0]
          this.error = ''
          if (result.error !== undefined) throw new Error(result.error)

          const result2 = await this.$axios.$post(
            `${process.env.previewApiUrl}schemeCommentPages/update`,
            {
              // data,
              id: data.id,
              slug: data.slug,
              parent: data.parent,
              scheme: data.scheme,
              title_en: data.title_en,
              title_tc: data.title_tc,
              title_sc: data.title_sc,
              content_en: data.content_en,
              content_tc: data.content_tc,
              content_sc: data.content_sc,
              img: data.img,
              orders: data.orders,
              disabled: data.disabled,
              tabs: data.tabs.length > 0 ? JSON.stringify(data.tabs) : null,
            }
          )

          // update webpage json data
          await this.$axios.$post(
            `${process.env.previewApiUrl}preview/toWebPreview`
          )
          this.error = ''
          if (result2.error !== undefined) throw new Error(result2.error)
        }
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    /* addCommentInTab(tab) {
      tab.commentList = [123, 456]
    }, */
    addTab() {
      this.tabs.push({
        title_en: '',
        title_tc: '',
        title_sc: '',
        content_en: '',
        content_tc: '',
        content_sc: '',
        commentlist_en: [],
        commentlist_tc: [],
        commentlist_sc: [],
      })
    },
    deleteTab(counter) {
      this.tabs.splice(counter, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
.medium-editor {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
.tab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.component {
  text-align: left;
}
.btn {
  margin-bottom: 5px;
}
.ie-hint {
  color: red;
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-hint {
    display: block;
    padding-bottom: 10px;
  }
}

.mse-c1 {
  padding: 30px;
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .mse-c1 {
    padding: 10px;
  }

  .shadow {
    padding: 12px;
    margin: 30px -15px;
  }
}
</style>
<!--[if IE]>
  <style scoped>
    .ie-hint {
      display: block;
    }
  </style>
<![endif]-->
