import { render, staticRenderFns } from "./tinymceEditorFlexible.vue?vue&type=template&id=0330ba9c&"
import script from "./tinymceEditorFlexible.vue?vue&type=script&lang=js&"
export * from "./tinymceEditorFlexible.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/sengital/abo-preview-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0330ba9c')) {
      api.createRecord('0330ba9c', component.options)
    } else {
      api.reload('0330ba9c', component.options)
    }
    module.hot.accept("./tinymceEditorFlexible.vue?vue&type=template&id=0330ba9c&", function () {
      api.rerender('0330ba9c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/admin/tinymceEditorFlexible.vue"
export default component.exports