<template>
  <div>
    <div v-if="event.EventSlot" class="event_detail">
      <ul v-if="isEdit" class="nav nav-tabs">
        <li class="nav-item">
          <a
            aria-setsize="3"
            class="nav-link"
            :class="lang === 0 ? 'active' : ''"
            style="cursor: pointer"
            @click="changeLang(0)"
            >內容(英)</a
          >
        </li>
        <li class="nav-item">
          <a
            aria-setsize="3"
            class="nav-link"
            :class="lang === 1 ? 'active' : ''"
            style="cursor: pointer"
            @click="changeLang(1)"
            >內容(繁)</a
          >
        </li>
        <li class="nav-item">
          <a
            aria-setsize="3"
            class="nav-link"
            :class="lang === 2 ? 'active' : ''"
            style="cursor: pointer"
            @click="changeLang(2)"
            >內容(簡)</a
          >
        </li>
        <!---->
      </ul>
      <table v-if="event.EventSlot.length" style="font-size: 0.9em">
        <tbody>
          <tr v-if="singleVenue">
            <th>日期：</th>

            <td v-if="date" style="position: relative">
              <table>
                <tbody>
                  <tr
                    v-for="(d, key, index) in date"
                    :key="key"
                    class="td-time"
                  >
                    <td>
                      <span v-for="(a, i) in d" :key="i" class="td-time">
                        {{ $moment(a.date).format('D.M')
                        }}<span v-if="i !== d.length - 1">
                          <!-- show year and add <br /> only if have next date is not on the same year--><span
                            v-if="
                              $moment(a.date).format('Y') !==
                              $moment(d[i + 1].date).format('Y')
                            "
                            >.{{ $moment(a.date).format('Y') }}<br /></span
                          ><span v-else>&ensp;|&ensp;</span></span
                        ><!-- or show year if no next date --><span
                          v-if="!d[i + 1]"
                          >.{{ $moment(a.date).format('Y') }}</span
                        >
                      </span>
                      ({{ getWeekly(d[0].weekday) }})&ensp;

                      <span v-if="JSON.parse(event.remark)">
                        <!-- Add <br /> for remarks if the number of dates of the current line or next line > 6 -->
                        <span
                          v-if="
                            d.length > 6 && JSON.parse(event.remark).date[index]
                            // Object.values(date)[index + 1].length > 6)
                          "
                          ><br />
                        </span>
                        <span
                          v-if="
                            JSON.parse(event.remark).date[index] &&
                            JSON.parse(event.remark).date[index].text.tc !== ''
                          "
                          :class="
                            JSON.parse(event.remark).date[index].color == 'red'
                              ? 'red'
                              : ''
                          "
                          v-html="JSON.parse(event.remark).date[index].text.tc"
                        ></span>
                      </span>

                      <!-- Add <br /> if the number of dates of the current line or next line > 6 -->
                      <span
                        v-if="
                          index + 1 !== Object.keys(date).length &&
                          (d.length > 6 ||
                            Object.keys(date).map((e) => date[e])[index + 1]
                              .length > 6)
                          // Object.values(date)[index + 1].length > 6)
                        "
                        ><br />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr v-if="singleVenue">
            <th>地點：</th>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td>{{ getVenue(event.EventSlot[0].venue) }}&ensp;</td>
                    <td v-if="JSON.parse(event.remark)">
                      <span
                        v-if="JSON.parse(event.remark).venue[0]"
                        :class="
                          JSON.parse(event.remark).venue[0].color == 'red'
                            ? 'red'
                            : ''
                        "
                        v-html="JSON.parse(event.remark).venue[0].text.tc"
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr v-else>
            <th>日期及地點：</th>
            <td>
              <table>
                <tbody>
                  <tr v-for="(vd, i) in venueDates" :key="i">
                    <td>{{ vd.date }}&ensp;</td>
                    <td>{{ vd.day }}&ensp;</td>
                    <td style="width: 110px">{{ getVenue(vd.venue) }}&ensp;</td>
                    <td v-if="JSON.parse(event.remark)">
                      <span
                        v-if="JSON.parse(event.remark).dnv[i]"
                        :class="
                          JSON.parse(event.remark).dnv[i].color == 'red'
                            ? 'red'
                            : ''
                        "
                        v-html="JSON.parse(event.remark).dnv[i].text.tc"
                      >
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th>時間：</th>
            <td>
              <table>
                <tbody>
                  <tr v-for="(t, i) in time" :key="i" class="td-time">
                    <td>
                      {{ t.startTime.slice(0, 5) }} -
                      {{ t.endTime.slice(0, 5) }}&ensp;
                    </td>
                    <td v-if="JSON.parse(event.remark)">
                      <span
                        v-if="JSON.parse(event.remark).time[i]"
                        :class="
                          JSON.parse(event.remark).time[i].color == 'red'
                            ? 'red'
                            : ''
                        "
                        v-html="JSON.parse(event.remark).time[i].text.tc"
                      >
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th>需時：</th>
            <td>
              <table>
                <tbody>
                  <tr v-for="(d, i) in getDuration()" :key="i" class="td-time">
                    <td>
                      {{ d }}&ensp;<span
                        v-if="
                          JSON.parse(event.remark) &&
                          JSON.parse(event.remark).dura[i]
                        "
                        :class="
                          JSON.parse(event.remark).dura[i].color == 'red'
                            ? 'red'
                            : ''
                        "
                        v-html="JSON.parse(event.remark).dura[i].text.tc"
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th>對象：</th>
            <td>
              <table>
                <tbody>
                  <tr v-for="(t, i) in target" :key="i">
                    <td>{{ t }}&ensp;</td>
                    <td v-if="JSON.parse(event.remark)">
                      <span
                        v-if="JSON.parse(event.remark).target[i]"
                        :class="
                          JSON.parse(event.remark).target[i].color == 'red'
                            ? 'red'
                            : ''
                        "
                        v-html="JSON.parse(event.remark).target[i].text.tc"
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <th>名額：</th>
            <td>
              <table>
                <tbody>
                  <tr v-for="(t, i) in quota" :key="i">
                    <td>{{ t }}&ensp;</td>
                    <td v-if="JSON.parse(event.remark)">
                      <span
                        v-if="JSON.parse(event.remark).quota[i]"
                        :class="
                          JSON.parse(event.remark).quota[i].color == 'red'
                            ? 'red'
                            : ''
                        "
                        v-html="JSON.parse(event.remark).quota[i].text.tc"
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div style="display: flex; justify-content: center">
        <div v-if="event.EventSlot.length && !isEdit" class="event-btn">
          <nuxt-link
            v-if="!event.parent"
            :to="localePath(`/event/${event.id}`)"
            class="event_apply_links"
            >查看更多</nuxt-link
          >
          <nuxt-link
            v-else
            :to="localePath(`/event/${parentEvent.code}`)"
            class="event_apply_links"
            >查看更多</nuxt-link
          >
        </div>
        <div v-if="event.EventSlot.length && !isEdit" class="event-btn">
          <a
            v-if="!isBookmarked"
            class="event_apply_links"
            @click="bookmark(event.id)"
          >
            加入書籤
          </a>
          <a
            v-else
            class="event_apply_links"
            style="
              background: whitesmoke;
              color: #999999;
              border: 1px solid #999999;
            "
            @click="bookmark(event.id)"
          >
            已加入書籤
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
// import remarkBlock from '~/components/event/remarkBlock'

export default {
  name: 'EventTable',
  components: {
    // remarkBlock
  },
  props: {
    event: Object,
    /* remark: {
      type: Object,
      default: () => ({
        date: [],
        venue: [],
        dnv: [],
        time: [],
        dura: [],
        target: [],
        quota: [],
      }),
    }, */
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    /* try {
      // console.log(this.event.id, this.event.parent)
      if (this.event.parent) {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/public/parentCode/${this.event.parent}`
        )
        this.parentEvent = result.data.data
      }
    } catch (err) {} */
    /* try {
      const result = await this.$axios.get(`${process.env.cloudApiUrl}bookmarks`)
      this.bookmarks = result.data.data
      // console.log(this.bookmarks)
      // this.syncBookmark()
    } catch (err) {} */
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/venue`
      )
      this.venue = result.data.data
    } catch (err) {}
    this.getDate()
    this.getTime()
    this.isSingleVenue()
    this.getTarget()
    this.getQuota()
  },
  data() {
    return {
      i: null,
      lang: 0,
      venue: [],
      subevents: [],
      quota: '',
      singleVenue: true,
      venueDates: [],
      time: '',
      date: '',
      target: [],
      index: 0,
      // bookmarks: [],
      // isBookmarked: false,
      parentEvent: {},
    }
  },
  watch: {
    event: {
      handler(newVal, oldVal) {
        this.getDate()
        this.getTime()
        this.isSingleVenue()
        this.getTarget()
        this.getQuota()
        this.$forceUpdate()
        // this.isBookmarked = this.getIsBookmarked(newVal)
        // this.parentEvent = this.getParentEvent(newVal)
      },
      deep: true,
    },
    remark: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    /* async getParentEvent(event) {
      try {
        // console.log(event.id, event.parent)
        if (event.parent !== null) {
          const result = await this.$axios.get(
            `${process.env.cloudApiUrl}events/public/parentCode/${event.parent}`
          )
          // console.log(event.id, event.parent, result.data.data[0].code)
          this.parentEvent = result.data.data[0]
        }
      } catch (err) {}
      return {}
    },
    getIsBookmarked(event) {
      if (this.bookmarks && event) {
        if (this.bookmarks.find((bk) => bk.event_id === event.code)) {
          // this.isBookmarked = true
          // console.log('syncBookmark', event.code)
          return true
        }
      }
      return false
    }, */
    changeLang(lang) {
      this.lang = lang
    },
    // i18n
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    // bookmarks
    /* bookmark(code) {
      // this.event.isBookmarked = !this.event.isBookmarked
      // console.log(this.isBookmarked)
      // console.log('Before', this.isBookmarked)
      // console.log(this.isBookmarked)

      if (this.isBookmarked) {
        this.addbookmark(code)
        this.isBookmarked = false
        // console.log('After', this.isBookmarked)
      } else {
        this.delbookmark(code)
        this.isBookmarked = true
        // console.log('After', this.isBookmarked)
      }
      // console.log(this.isBookmarked)
    },
    async addbookmark(code) {
      try {
        const result = await this.$axios.post(`${process.env.cloudApiUrl}bookmarks/add`, {
          id: code,
        })
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    async delbookmark(code) {
      try {
        const result = await this.$axios.post(`${process.env.cloudApiUrl}bookmarks/delete`, {
          id: code,
        })
        if (result.error !== undefined) throw new Error(result.error)
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    }, */
    // others
    dateNextLine(date, nextDate) {
      return date && nextDate
        ? date.slice(0, 4) !== nextDate.slice(0, 4)
        : false
    },
    changeSubevent(i) {
      this.indexSubEvent = i
    },
    async getDate() {
      const result = await this.event.EventSlot.reduce((acc = [], d) => {
        const found = acc.find((a) => a.date === d.date)
        if (!found) {
          acc.push({
            date: d.date,
            weekday: new Date(d.date).toString().slice(0, 3), // this.$moment(d.date).isoWeekday(), // new Date(d.date).getDay(),
            year_weekday: `${new Date(d.date)
              .toString()
              .slice(11, 15)}_${new Date(d.date).toString().slice(0, 3)}`,
          }) // not found, so need to add data property
        } else if (!found.date.includes(d.date)) {
          found.date.push(d.date)
        }
        return acc
      }, [])
      this.date = result.sort((a, b) =>
        a.date > b.date ? 1 : b.date > a.date ? -1 : 0
      )
      // console.log(this.date)

      const datearr = this.date
      // let i = -1
      const result1 = datearr.reduce(function (r, a) {
        r[a.year_weekday] = r[a.year_weekday] || []
        r[a.year_weekday].push(a)
        return r
      }, Object.create(null))

      this.date = result1
    },
    async getTime() {
      const result = await this.event.EventSlot.reduce((acc = [], d) => {
        const found = acc.find(
          (a) =>
            a.startTime.slice(0, 5) === d.startTime.slice(0, 5) &&
            a.endTime.slice(0, 5) === d.endTime.slice(0, 5)
          // .slice(0, 5) as may appear 14:30 or 14:30:00
        )
        if (!found) {
          acc.push({ startTime: d.startTime, endTime: d.endTime }) // not found, so need to add data property
        }
        return acc
      }, [])
      // console.log(result + ' ' + result[0] + ' ' + result.length)
      this.time = result
      if (result.length > 0) {
        this.time = result.sort((a, b) =>
          a.endTime - b.endTime ? 1 : b.endTime > a.endTime ? -1 : 0
        )
      }
      /* if (result.length > 0) {
        this.time = result.sort((a, b) =>
          a.startTime > b.startTime ? 1 : b.startTime > a.startTime ? -1 : 0
        )
      } */
      return null
    },
    async getTarget() {
      // k1: -2, k2: -1, k3: 0,
      // p1: 1, p2: 2, p3: 3, p4: 4, p5: 5, p6: 6
      // f1: 7, f2: 8, f3: 9, f4: 10, f5: 11, f6: 12
      const result = await this.event.EventSlot.reduce((acc = [], d) => {
        const string = this.formatTarget(d.target.toString())
        const found = acc.find(
          // compare 2 array
          (a) => JSON.stringify(a) === JSON.stringify(string)
        )
        if (!found) acc.push(string) // not found, so need to add data property
        return acc
      }, [])
      this.target = result
      return null
    },
    formatTarget(t) {
      let result = ''
      const targetArray = t.split(',')
      let targetArrayInInt = []
      targetArray.map((t) => {
        if (t[0] === 'k') targetArrayInInt.push(parseInt(t[1]) - 3)
        if (t[0] === 'p') targetArrayInInt.push(parseInt(t[1]))
        if (t[0] === 'f') targetArrayInInt.push(parseInt(t[1]) + 6)
      })
      // console.log('targetArrayInInt ' + targetArrayInInt)

      targetArrayInInt = targetArrayInInt.sort((a, b) =>
        a > b ? 1 : b > a ? -1 : 0
      )
      const check =
        targetArrayInInt[0] + targetArrayInInt.length - 1 ===
        targetArrayInInt[targetArrayInInt.length - 1]

      if (check) {
        // if only 1 item
        if (targetArrayInInt.length === 1)
          result = this.intToGrade(targetArrayInInt[0])
        // if continous
        else {
          result = this.intToGrade(targetArrayInInt[0])
            .concat('至') // .concat(' - ')
            .concat(
              this.intToGrade(targetArrayInInt[targetArrayInInt.length - 1])
            )
        }
      } else {
        result = this.group(targetArrayInInt)
      }
      return result
    },
    intToGrade(i) {
      // console.log(i, typeof i)
      let result = ''
      switch (this.$i18n.locale) {
        case 'en':
          result =
            i < 1
              ? 'K.'.concat((i + 3).toString())
              : i < 7
              ? 'P.'.concat(i.toString())
              : 'S.'.concat((i - 6).toString())
          break
        case 'tc':
          result =
            i === -2
              ? '幼兒班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        case 'sc':
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
    group(numbers) {
      const result = numbers
        .reduce((result, value, index, array) => {
          if (!index || array[index - 1] + 1 !== value) {
            result.push([this.intToGrade(parseInt(value))])
          } else {
            result[result.length - 1][1] = this.intToGrade(parseInt(value))
          }
          return result
        }, [])
        .map((array) => array.join('至'))
      return result.join(', ')
    },

    async getQuota() {
      const result = await this.event.EventSlot.reduce((acc = [], d) => {
        const found = acc.find((a) => a === d.quota)
        if (!found) {
          acc.push(d.quota) // not found, so need to add data property
        } /* else if (!found.target.includes(d.startTime)) {
          found.target.push(d.target)
        } */
        return acc
      }, [])
      // console.log(result + ' ' + result[0] + ' ' + result.length)
      this.quota = result
      return null
    },

    // --- Generate duration ---
    getDuration() {
      let durationArr = []
      if (this.time.length) {
        // console.log(this.time)
        durationArr = this.time.reduce((arr = [], t) => {
          const a = t.startTime
          const b = t.endTime

          const difference = Math.abs(this.toSeconds(a) - this.toSeconds(b))

          // format time differnece
          const hour = Math.floor(difference / 3600) // an hour has 3600 seconds
          const min = Math.floor((difference % 3600) / 60) // a minute has 60 seconds
          const sec = difference % 60

          // console.log('result ' + hour + ' ' + min + ' ' + sec)
          let result = ''
          result = hour > 0 ? hour.toString().concat('小時') : result
          result =
            min > 0 ? result.concat(min.toString()).concat('分鐘') : result
          result = sec > 0 ? result.concat(sec).concat('秒') : result

          const found = arr.find((a) => a === result)
          // const datehk = new Date(d.date).toString().slice(4, 15) // .toLocaleString()
          if (!found) arr.push(result)

          // console.log(arr)
          return arr
        }, [])
      }
      return durationArr
    },
    toSeconds(timeStr) {
      // Extract hours, minutes and seconds
      const parts = timeStr.split(':')
      // compute  and return total seconds
      return (
        parts[0] * 3600 + // an hour has 3600 seconds
        parts[1] * 60 // a minute has 60 seconds
      ) // seconds
    },

    // --- Generate venue-date pairs ---
    async isSingleVenue() {
      if (this.event.EventSlot) {
        const result = await this.event.EventSlot.reduce((acc = [], d) => {
          const found = acc.find((a) => a.venue === d.venue)
          // const datehk = new Date(d.date).toString().slice(4, 15) // .toLocaleString()
          if (!found) {
            acc.push({ venue: d.venue, date: [d.date] }) // not found, so need to add data property
          } else if (!found.date.includes(d.date)) {
            found.date.push(d.date)
          }
          return acc
        }, [])
        this.venueDates = result
        this.formatVenueDatePair()
      }
      this.singleVenue = this.venueDates.length === 1
    },
    formatVenueDatePair() {
      const newVenueDates = []
      this.venueDates.forEach((vdate) => {
        vdate.date.sort()
        // console.log(' vdate ' + JSON.stringify(vdate))
        const datearr = vdate.date.map((d) => new Date(d))
        let i = -1
        const result = datearr.reduce(function (stack, b) {
          const cur = stack[i]
          const a = cur ? cur[cur.length - 1] : 0 // the last item of the last array

          // create and push to new array if the date is not consecutive
          if (b - a > 1000 * 60 * 60 * 24) {
            i++
          }
          if (!stack[i]) stack[i] = []
          stack[i].push(b)

          return stack
        }, [])

        // console.log(result)
        // result: arr of array of dates
        // r: each array contains either 1 date or a group of consecutive dates

        let dateString = '' // eg. 22, 24-26/2/2021
        let dayString = [] // eg. (二至五)

        result.map((r, index) => {
          // nextDate: the first date of the next r
          const nextDate = result[index + 1] ? result[index + 1][0] : null

          dateString = dateString !== '' ? `${dateString}, ` : dateString
          // dayString = dayString !== '' ? `${dayString}, ` : dayString

          /* ------------------if the array r only contains 1 date------------------ */
          if (r.length === 1) {
            // if has the next r

            /* dateString */
            dateString = nextDate
              ? this.isSameMonth(nextDate, r[0])
                ? `${dateString}${this.$moment(r[0]).format('D')}`
                : this.isSameYear(nextDate, r[0])
                ? `${dateString}${this.$moment(r[0]).format('D.M')}`
                : `${dateString}${this.$moment(r[0]).format('D.M.Y')}`
              : `${dateString}${this.$moment(r[0]).format('D.M.Y')}`

            /* dayString */
            dayString.push(this.getWeekly(this.$moment(r[0]).isoWeekday()))
          } else {
            /* ------------------if the array r contains a group of consecutive dates------------------ */

            /* dateString */
            const dateFirst = new Date(r[0])
            const yearFirst = dateFirst.getFullYear()
            const monthFirst = dateFirst.getMonth() + 1
            const dtFirst = dateFirst.getDate()

            const dateLast = new Date(r[r.length - 1])
            const yearLast = dateLast.getFullYear()
            const monthLast = dateLast.getMonth() + 1
            const dtLast = dateLast.getDate()

            if (this.isSameMonth(r[0], r[r.length - 1])) {
              dateString = `${dateString}${dtFirst}-${dtLast}`

              // compare with 1st item in next r (next date array)
              dateString = this.isSameMonth(r[0], nextDate)
                ? dateString
                : `${dateString}/${monthFirst}`

              dateString = this.isSameYear(r[0], nextDate)
                ? dateString
                : `${dateString}/${yearFirst}`
            } else if (this.isSameYear(r[0], r[r.length - 1])) {
              dateString = `${dateString}${dtFirst}/${monthFirst}-${dtLast}/${monthLast}`

              // compare with 1st item in next r (next date array)
              dateString = this.isSameYear(r[0], nextDate)
                ? dateString
                : `${dateString}/${yearFirst}`
            } else {
              dateString = `${dateString}${dtFirst}/${monthFirst}/${yearFirst}-${dtLast}/${monthLast}/${yearLast}`
            }

            /* dayString */
            dayString.push(
              this.getWeekly(this.$moment(r[0]).isoWeekday())
                .concat('至')
                .concat(
                  this.getWeekly(this.$moment(r[r.length - 1]).isoWeekday())
                )
            )
          }
        })
        // console.log(dayString[0])
        // console.log(dayString[1])
        const dayStringUnique = dayString.reduce(function (dates, d) {
          if (!dates.includes(d)) dates.push(d)
          return dates
        }, [])

        dayString =
          dayStringUnique.length === 1
            ? `(${dayStringUnique})`
            : `(${dayString})`

        newVenueDates.push({
          venue: vdate.venue,
          date: dateString,
          day: dayString,
        })
        this.venueDates = newVenueDates
        // console.log('newVenueDates ' + newVenueDates)
      })
    },
    isSameMonth(date1, date2) {
      return this.$moment(date1).format('M') === this.$moment(date2).format('M')
    },
    isSameYear(date1, date2) {
      return this.$moment(date1).format('Y') === this.$moment(date2).format('Y')
    },
    getWeekly(day) {
      switch (day) {
        case 1:
        case 'Mon':
        case 'Monday':
          return '一'
        case 2:
        case 'Tue':
        case 'Tuesday':
          return '二'
        case 3:
        case 'Wed':
        case 'Wednesday':
          return '三'
        case 4:
        case 'Thu':
        case 'Thursday':
          return '四'
        case 5:
        case 'Fri':
        case 'Friday':
          return '五'
        case 6:
        case 'Sat':
        case 'Saturday':
          return '六'
        case 7:
        case 'Sun':
        case 'Sunday':
          return '日'
      }
    },

    findSubevent(subevents) {
      return false
    },
    getVenue(code) {
      let venueText = ''
      if (this.venue) {
        this.venue.find((v) => {
          // venueText = v.code === code ? this.i18nName(v) : ''
          if (v.code === code) {
            venueText = this.i18nName(v)
          }
        })
      }
      // console.log(venueText)
      return venueText
    },
    getWeekDay(obj, target) {
      if (obj.Mon === target) {
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.red {
  color: red;
}
.event-btn {
  margin: 15px;
  margin-right: 15px;
  width: 100%;
}
.td-right {
  float: right;
}
.td-time {
  margin-bottom: 0;
}
.event_detail > table {
  // border: 1px solid lightgrey;
  width: 100%;
  // margin-top: 50px;
  // margin-bottom: 25px;
  color: #3f3f3f;
}
.event_detail > table > tbody > tr > th {
  // background-color: #ffe086; // #f5ddd4;
  text-align: left;
  height: 40px;
  // border: 1px solid lightgrey;
  width: 90px;
  font-weight: normal;
  // padding-bottom: 0;
  vertical-align: top;
  padding-top: 8px;
}
.event_detail > table > tbody > tr > td {
  // background-color: #ffffde; // #fffaf5;
  text-align: left;
  height: 40px;
  // border: 1px solid lightgrey;
  padding: 5px;
  // padding-bottom: 0;
  // font-weight: 500;
}
.event_apply_links {
  border: 1px solid #1fbcb4;
  border-radius: 20px;
  padding: 7px;
  text-decoration: none;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  background: #fff;
  color: #1fbcb4;
}
.event_apply_links:hover {
  background: #1fbcb4;
  color: white;
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .event_detail > table > tbody > tr > th {
    width: auto;
  }
}
</style>
