<template>
  <editor
    :initial-value="content"
    :options="editorOptions"
    height="500px"
    initial-edit-type="wysiwyg"
    preview-style="vertical"
  />
</template>
<script>
import 'codemirror/lib/codemirror.css'
import '@toast-ui/editor/dist/toastui-editor.css'

import { Editor } from '@toast-ui/vue-editor'

export default {
  components: {
    Editor,
  },
  props: {
    content: { type: String, default: '' },
  },
  data() {
    return {
      editorText: '',
      editorOptions: {
        hideModeSwitch: true,
        usageStatistics: false,
      },
    }
  },
}
</script>
