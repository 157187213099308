import Vue from 'vue'
import editor from 'vue2-medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
import 'vuejs-medium-editor/src/themes/default.css'

/* export default {
  components: {
    'medium-editor': editor,
  },
} */

Vue.component('medium-editor', editor)
