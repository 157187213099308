<template>
  <div class="pw-cont">
    <h2 class="title">
      <img
        :src="`${staticPrefix}img/icon_information.svg`"
        alt="icon information"
      />
      {{ $t('account.information') }}
    </h2>
    <div class="pw-cont-inner">
      <b-row>
        <b-col cols="3">
          <label for="textarea-small">{{ $t('account.name') }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="user.name" type="text" readonly />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3">
          <label for="textarea-small">{{ $t('account.email') }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="user.email" type="text" readonly />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3">
          <label for="textarea-small">{{ $t('account.school') }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="schoolName" type="text" readonly />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3">
          <label for="textarea-small">{{ $t('account.schoolId') }}</label>
        </b-col>
        <b-col>
          <span>{{ user.school }}</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3">
          <label for="textarea-small">{{ $t('account.account') }}</label>
        </b-col>
        <b-col>
          <span v-if="user.schoolAdmin">
            {{ $t('user.profileisSchoolAdmin') }}
          </span>
          <span v-else-if="user.editor">
            {{ $t('user.isEditorAdmin') }}
          </span>
          <span v-else-if="user.admin">
            {{ $t('user.isSuperAdmin') }}
          </span>
          <span v-else>
            {{ $t('user.schoolTeacher') }}
          </span>
        </b-col>
      </b-row>

      <div class="submit">
        <b-button pill class="btn-apply" :href="$t('account.modifyInfo')">
          {{ $t('account.submit') }}
        </b-button>
      </div>
    </div>

    <Notification v-if="error" :message="error" />
    <b-alert v-if="success" variant="success" show>
      <b-icon icon="check-circle"></b-icon>
      {{ $t('account.pwSuccess') }}
    </b-alert>
  </div>
</template>
<script>
export default {
  layout: 'default',
  middleware: ['RequireLogin', 'jwtCheck'],
  fetch() {
    this.loading = false
  },
  data() {
    return {
      success: false,
      error: '',
      schoolName: '',
    }
  },
  computed: {
    user() {
      return this.$store.state.localStorage.profile
    },
    staticPrefix() {
      return process.env.base
    },
  },
  mounted() {
    console.log('User', this.user)
    this.setup()
  },
  methods: {
    async setup() {
      if (this.user.sid) {
        const result2 = await this.$axios.get(
          `${process.env.cloudApiUrl}schools/getName/${this.user.sid}`
        )
        if (result2.data.data.length) {
          const tmpSchool = result2.data.data[0]
          tmpSchool.name_sc = tmpSchool.name_tc
          this.schoolName = this.i18nName(tmpSchool)
        }
      }
    },
    async submit() {},
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.rules {
  background-color: whitesmoke;
  padding: 15px;
  color: #3f3f3f;
  text-align: left;
}
.contcontaier {
  background: white;
  padding-bottom: 100px;
}
.cont-wrap {
  display: block;
  flex-wrap: wrap;
}
.cont {
  width: calc(85% + 20px);
  margin-left: auto;
  margin-right: auto;
}
.scheme-cont {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.pw-cont {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}
.pw-cont-inner {
  padding: 0 -15px;
  border-color: #ddd;
  border-top-style: solid;
  border-width: 1px;
  text-align: left;
}
.pw-cont > *,
.pw-cont-inner > * {
  margin: 16px 0;
}
.btn-apply {
  background-color: #1cbcb4;
  border-color: #1cbcb4;
  color: white;
}
.btn-apply:hover {
  background-color: #17a79f;
}
.title {
  text-align: left;
  color: #393939;
  margin-bottom: 5px;
  font-weight: 100;
  font-size: 24px;
}

.submit {
  width: 100%;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .col-3 {
    flex: none;
    max-width: unset;
  }

  .submit {
    margin: auto;
    width: fit-content;
  }
}
</style>
