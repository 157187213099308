var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cont-tab" }, [
    _c(
      "div",
      { staticClass: "scheme-title" },
      _vm._l(_vm.getPaths(), function (n, index) {
        return _c(
          "div",
          { key: n.id, staticClass: "path" },
          [
            _c(
              "nuxt-link",
              {
                class:
                  index === _vm.getPaths().length - 1
                    ? "scheme-link-last"
                    : "scheme-link",
                attrs: { to: _vm.localePath(`/${n.slug}`) },
              },
              [_vm._v(_vm._s(n.title))]
            ),
            index != _vm.getPaths().length - 1
              ? _c("span", [_vm._v(" > ")])
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }