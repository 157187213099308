<template>
  <div class="newsForm">
    <b-form-group id="to" label-for="to">
      {{ $t('mailTemplate.to') }}
      <b-form-input v-model="receiver" type="text"></b-form-input>
    </b-form-group>

    <b-form-group id="cc" label-for="cc">
      {{ $t('mailTemplate.cc') }}
      <b-form-input v-model="cc" type="text"></b-form-input>
    </b-form-group>

    <b-form-group id="bcc" label-for="bcc">
      {{ $t('mailTemplate.bcc') }}
      <b-form-input v-model="bcc" type="text"></b-form-input>
    </b-form-group>

    <b-form-group id="subject" label-for="subject">
      {{ $t('mailTemplate.subject') }}
      <b-form-input v-model="subject" type="text"></b-form-input>
    </b-form-group>

    {{ $t('mailTemplate.content') }}
    <b-form-group id="content" label-for="content">
      <div>
        <tinymceEditorFlexible
          id="content"
          ref="tinymce"
          :value="content"
          @writeContent="getContent"
          @editorInit="(e) => e.setContent(content)"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import TinymceEditorFlexible from '~/components/admin/tinymceEditorFlexible'
/* import tools from '~/components/common/tools' */

export default {
  components: {
    TinymceEditorFlexible,
    /* tools, */
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    action: { type: String, default: 'add' },
    emailContent: {
      type: Object,
      default: () => ({
        receiver: '',
        cc: '',
        bcc: '',
        subject: '',
        content: '',
      }),
    },
    tempCode: { type: String, default: '' },
    custom: { type: Object, default: null },
  },
  data() {
    return {
      id: null,
      code: '',
      receiver: '',
      cc: '',
      bcc: '',
      subject: '',
      content: '',
      schoolId: null,
      userId: null,
      testReceiver: '',
      orders: null,
      isShow: false,
    }
  },
  computed: {},
  watch: {
    // on props change, update data
    emailContent() {
      const { receiver, cc, bcc, subject, content } = this.emailContent
      this.receiver = receiver
      this.cc = cc
      this.bcc = bcc
      this.subject = subject
      this.content = content
      this.$emit('UpdateData', this.emailContent)
    },
    // on data change, update props
    receiver() {
      this.emailContent.receiver = this.receiver
      this.$emit('UpdateData', this.emailContent)
    },
    cc() {
      this.emailContent.cc = this.cc
      this.$emit('UpdateData', this.emailContent)
    },
    bcc() {
      this.emailContent.bcc = this.bcc
      this.$emit('UpdateData', this.emailContent)
    },
    subject() {
      this.emailContent.subject = this.subject
      this.$emit('UpdateData', this.emailContent)
    },
    content() {
      this.emailContent.content = this.content
      this.$emit('UpdateData', this.emailContent)
    },
  },
  mounted() {},
  methods: {
    focusTextEditor() {
      this.$nextTick(() => {
        this.isShow = true
        /* console.log('emailContent', this.$refs.tinymce.$el)
        this.$refs.tinymce.$el.focus() */
      })
    },
    getContent(evt, id, content) {
      this[id] = content
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
