var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _vm.action != "send" && _vm.code
                  ? _c(
                      "b-form-group",
                      { attrs: { id: "code", "label-for": "code" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("mailTemplate.code")) +
                            "\n          "
                        ),
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            readonly: _vm.action !== "add",
                          },
                          model: {
                            value: _vm.code,
                            callback: function ($$v) {
                              _vm.code = $$v
                            },
                            expression: "code",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("mailTemplate.target")) +
                    "\n        "
                ),
                _c(
                  "b-form-group",
                  { attrs: { id: "to", "label-for": "to" } },
                  [
                    _c("b-form-select", {
                      staticClass: "mt-1",
                      attrs: {
                        options: _vm.targetOption,
                        state: !!_vm.target,
                        size: "sm",
                        required: "",
                      },
                      model: {
                        value: _vm.target,
                        callback: function ($$v) {
                          _vm.target = $$v
                        },
                        expression: "target",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("smsTemplate.advancedTarget")) +
                    "\n        "
                ),
                _c(
                  "b-form-group",
                  { attrs: { id: "to", "label-for": "to" } },
                  [
                    _c("b-form-select", {
                      staticClass: "mt-1",
                      attrs: {
                        options: _vm.advTargetOption,
                        state: !!_vm.advTarget,
                        size: "sm",
                        required: "",
                      },
                      model: {
                        value: _vm.advTarget,
                        callback: function ($$v) {
                          _vm.advTarget = $$v
                        },
                        expression: "advTarget",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.advTarget !== "none"
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("smsTemplate.advancedTarget")) +
                            "\n          "
                        ),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { sm: "3" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("smsTemplate.filter")) +
                                    "\n              "
                                ),
                                _c("b-form-input", {
                                  staticClass: "mt-1",
                                  attrs: { size: "sm" },
                                  on: { change: _vm.getAdvTargetOption2 },
                                  model: {
                                    value: _vm.advTarget2Filter,
                                    callback: function ($$v) {
                                      _vm.advTarget2Filter = $$v
                                    },
                                    expression: "advTarget2Filter",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { sm: "9" } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("smsTemplate.select")) +
                                    "\n              "
                                ),
                                _c("b-form-select", {
                                  staticClass: "mt-1",
                                  attrs: {
                                    options: _vm.advTarget2FilterOption,
                                    state: !!_vm.advTarget2,
                                    size: "sm",
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.advTarget2,
                                    callback: function ($$v) {
                                      _vm.advTarget2 = $$v
                                    },
                                    expression: "advTarget2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.advTarget === "eventSlotId" && _vm.advTarget2 !== null
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("smsTemplate.advancedTarget")) +
                            "\n          "
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { id: "to", "label-for": "to" } },
                          [
                            _c("b-form-select", {
                              staticClass: "mt-1",
                              attrs: {
                                options: _vm.advTargetOption3,
                                state: !!_vm.advTarget3,
                                size: "sm",
                                required: "",
                              },
                              model: {
                                value: _vm.advTarget3,
                                callback: function ($$v) {
                                  _vm.advTarget3 = $$v
                                },
                                expression: "advTarget3",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.advTarget !== "none"
                  ? _c("div", [
                      _vm.advTargetResult !== null
                        ? _c(
                            "div",
                            { staticStyle: { padding: "10px 0px" } },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle.collapse-1",
                                      modifiers: { "collapse-1": true },
                                    },
                                  ],
                                  attrs: { variant: "primary", size: "sm" },
                                },
                                [
                                  _vm._v("對象名單\n              "),
                                  _c(
                                    "b-badge",
                                    { attrs: { variant: "light" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.advTargetResult.length) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v("："),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-collapse",
                                {
                                  staticClass: "mt-2",
                                  attrs: { id: "collapse-1" },
                                },
                                [
                                  _c(
                                    "b-card",
                                    _vm._l(_vm.advTargetResult, function (i) {
                                      return _c("div", { key: i }, [
                                        _c("p", [
                                          _vm._v(
                                            "\n                    Name: " +
                                              _vm._s(i.name) +
                                              " | Email: " +
                                              _vm._s(i.email) +
                                              " | Tel:\n                    " +
                                              _vm._s(i.tel) +
                                              "\n                  "
                                          ),
                                        ]),
                                      ])
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "to", "label-for": "to" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.to")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.receiver,
                        callback: function ($$v) {
                          _vm.receiver = $$v
                        },
                        expression: "receiver",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "cc", "label-for": "cc" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.cc")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.cc,
                        callback: function ($$v) {
                          _vm.cc = $$v
                        },
                        expression: "cc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "bcc", "label-for": "bcc" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.bcc")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.bcc,
                        callback: function ($$v) {
                          _vm.bcc = $$v
                        },
                        expression: "bcc",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "subject", "label-for": "subject" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.subject")) +
                        "\n          "
                    ),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.subject,
                        callback: function ($$v) {
                          _vm.subject = $$v
                        },
                        expression: "subject",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("mailTemplate.content")) +
                    "\n        "
                ),
                _c(
                  "b-form-group",
                  { attrs: { id: "content", "label-for": "content" } },
                  [
                    _c(
                      "div",
                      [
                        _vm.isShow
                          ? _c("tinymceEditorFlexible", {
                              ref: "tinymce",
                              attrs: { id: "content", value: _vm.content },
                              on: {
                                writeContent: _vm.getContent,
                                editorInit: (e) => e.setContent(_vm.content),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "testto", "label-for": "testto" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("mailTemplate.testto")) +
                        "\n          "
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.testReceiver,
                                callback: function ($$v) {
                                  _vm.testReceiver = $$v
                                },
                                expression: "testReceiver",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { cols: "2" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-info" },
                                on: { click: _vm.sendTestMail },
                              },
                              [_vm._v(_vm._s(_vm.$t("mailTemplate.testmail")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _vm.code
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-success" },
                            on: { click: _vm.submitEdit },
                          },
                          [_vm._v(_vm._s(_vm.$t("action.saveTemplate")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-danger",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submitToMailQueue")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }