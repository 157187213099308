<script>
import VueMediumEditor from 'vue2-medium-editor'
import rangy from 'rangy/lib/rangy-core.js'
import 'rangy/lib/rangy-classapplier'
import 'rangy/lib/rangy-highlighter'
import 'rangy/lib/rangy-selectionsaverestore'
import 'rangy/lib/rangy-textrange'
import 'rangy/lib/rangy-serializer'
const pHash = {
  p1: { name: 'reset', class: 'reset' },
}
function pButtonCreator(p) {
  return VueMediumEditor.MediumEditor.Extension.extend({
    name: p.name,
    init() {
      this.classApplier = rangy.createClassApplier(p.class, {
        elementTagName: 'span',
        normalize: false,
      })
      this.button = this.document.createElement('button')
      this.button.classList.add('medium-editor-action')
      this.button.innerHTML = p.name
      this.button.title = p.class
      this.on(this.button, 'click', this.handleClick.bind(this))
    },
    getButton() {
      return this.button
    },
    clearFontSize() {
      VueMediumEditor.MediumEditor.selection
        .getSelectedElements(this.document)
        .forEach(function (el) {
          if (
            el.nodeName.toLowerCase() === 'span' &&
            el.hasAttribute('class')
          ) {
            el.removeAttribute('class')
          }
        })
    },
    handleClick(event) {
      this.clearFontSize()
      // this.classApplier.toggleSelection()

      // Ensure the editor knows about an html change so watchers are notified
      // ie: <textarea> elements depend on the editableInput event to stay synchronized
      this.base.checkContentChanged()
    },
  })
}
export default {
  P1: pButtonCreator(pHash.p1),
}
</script>
