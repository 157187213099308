var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pw-cont" },
    [
      _c("h2", { staticClass: "title" }, [
        _c("img", {
          attrs: {
            src: `${_vm.staticPrefix}img/icon_password.svg`,
            alt: "icon password",
          },
        }),
        _vm._v("\n    " + _vm._s(_vm.$t("account.password")) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "rules",
        domProps: { innerHTML: _vm._s(_vm.$t("account.password_rules")) },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "pw-cont-inner" }, [
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("account.oldPassword")) },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("b-form-input", {
              attrs: {
                type: _vm.viewOldPassword ? "text" : "password",
                size: "lg",
              },
              model: {
                value: _vm.oldPassword,
                callback: function ($$v) {
                  _vm.oldPassword = $$v
                },
                expression: "oldPassword",
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "outline-dark" },
                on: {
                  click: function ($event) {
                    _vm.viewOldPassword = !_vm.viewOldPassword
                  },
                },
              },
              [
                _c("b-icon", {
                  attrs: { icon: _vm.viewOldPassword ? "eye" : "eye-slash" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("account.newPassword")) },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("b-form-input", {
              attrs: {
                type: _vm.viewNewPassword ? "text" : "password",
                state:
                  _vm.validatePw(_vm.newPassword) && _vm.newPassword !== "",
                size: "lg",
              },
              model: {
                value: _vm.newPassword,
                callback: function ($$v) {
                  _vm.newPassword = $$v
                },
                expression: "newPassword",
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "outline-dark" },
                on: {
                  click: function ($event) {
                    _vm.viewNewPassword = !_vm.viewNewPassword
                  },
                },
              },
              [
                _c("b-icon", {
                  attrs: { icon: _vm.viewNewPassword ? "eye" : "eye-slash" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("account.repeatPassword")) },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("b-form-input", {
              attrs: {
                type: _vm.viewRepeatPassword ? "text" : "password",
                state: _vm.validateRepeatPw,
                size: "lg",
              },
              model: {
                value: _vm.repeatPassword,
                callback: function ($$v) {
                  _vm.repeatPassword = $$v
                },
                expression: "repeatPassword",
              },
            }),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "outline-dark" },
                on: {
                  click: function ($event) {
                    _vm.viewRepeatPassword = !_vm.viewRepeatPassword
                  },
                },
              },
              [
                _c("b-icon", {
                  attrs: { icon: _vm.viewRepeatPassword ? "eye" : "eye-slash" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "submit-pw" }, [
          _c(
            "div",
            {
              staticClass: "btn btn-apply",
              on: {
                click: function ($event) {
                  return _vm.submitPw()
                },
              },
            },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("action.submit")) + "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.error
        ? _c("Notification", { attrs: { message: _vm.error } })
        : _vm._e(),
      _vm._v(" "),
      _vm.success
        ? _c(
            "b-alert",
            { attrs: { variant: "success", show: "" } },
            [
              _c("b-icon", { attrs: { icon: "check-circle" } }),
              _vm._v("\n    " + _vm._s(_vm.$t("account.pwSuccess")) + "\n  "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }