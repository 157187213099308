var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { staticClass: "svg-icon" }, [
    _c("use", {
      attrs: { "xlink:href": `${_vm.src}#${_vm.name}`, fill: _vm.color },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }