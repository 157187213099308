<template>
  <div class="navmenu" :class="adminNavDisplay ? 'm-show' : 'm-hide'">
    <b-nav vertical class="navbar-nav">
      <b-nav-item
        :class="togglenav"
        style="padding-top: 15px"
        @click="toggleNav"
      >
        <!-- @click="toggleNav" -->
        <nuxt-link :to="localePath('/admin')">
          <span>{{ $t('navbar_admin.home') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/news')">
          <span>{{ $t('navbar_admin.news') }}</span>
        </nuxt-link>
      </b-nav-item>
      <!-- <b-nav-item :class="togglenav">
        <nuxt-link :to="localePath('/admin/event')">
          <span>{{ $t('navbar_admin.event') }}</span>
        </nuxt-link>
      </b-nav-item> -->
      <!-- <b-nav-item :class="togglenav">
        <nuxt-link :to="localePath('/admin/contact')">
          <span>{{ $t('navbar_admin.contact') }}</span>
        </nuxt-link>
      </b-nav-item> -->
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/school')">
          <span>{{ $t('navbar_admin.school') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/schoolApplicationRecord')">
          <span>{{ $t('navbar_admin.schoolApplicationRecord') }}</span>
        </nuxt-link>
      </b-nav-item>
      <!-- <b-nav-item :class="togglenav">
        <nuxt-link :to="localePath('/admin/application')">
          <span>{{ $t('navbar_admin.application') }}</span>
        </nuxt-link>
      </b-nav-item> -->
      <b-nav-item
        :class="togglenav"
        style="padding-bottom: 10px"
        @click="toggleNav"
      >
        <nuxt-link :to="localePath('/admin/user')">
          <span>{{ $t('navbar_admin.user') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav">
        <nuxt-link :to="localePath('/admin/change_request')">
          <span>{{ $t('navbar_admin.change_request') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/cancelEvent')">
          <span>{{ $t('navbar_admin.cancelEvent') }}</span>
        </nuxt-link>
      </b-nav-item>
      <!-- <b-nav-item :class="togglenav">
        <nuxt-link :to="localePath('/admin/scheme')">
          <span>{{ $t('navbar_admin.scheme') }}</span>
        </nuxt-link>
      </b-nav-item> -->
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/page')">
          <span>{{ $t('navbar_admin.page') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/config')">
          <span>{{ $t('navbar_admin.config') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/emailPreview')">
          <span>{{ $t('navbar_admin.emailPreview') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/emailTemplate')">
          <span>{{ $t('navbar_admin.emailTemplate') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/admin/smsTemplate')">
          <span>{{ $t('navbar_admin.smsTemplate') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item
        :class="togglenav"
        style="padding-bottom: 10px"
        @click="toggleNav"
      >
        <nuxt-link :to="localePath('/admin/role')">
          <span>{{ $t('navbar_admin.role') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item :class="togglenav">
        <nuxt-link :to="localePath('/admin/feedback')">
          <span>{{ $t('navbar_admin.feedback') }}</span>
        </nuxt-link>
      </b-nav-item>
      <!-- <b-nav-item
        v-if="$route.path !== localePath('/admin')"
        :class="togglenav"
      >
        <nuxt-link :to="localePath('/admin')">
          <span>{{ $t('navbar_admin.backadminhome') }}</span>
        </nuxt-link>
      </b-nav-item> -->
      <b-nav-item :class="togglenav" @click="toggleNav">
        <nuxt-link :to="localePath('/')">
          <span>{{ $t('navbar_admin.backhome') }}</span>
        </nuxt-link>
      </b-nav-item>
      <b-nav-item v-if="loggedIn" :class="togglenav" @click="logout">
        <a>{{ $t('navbar.logout') }}</a>
      </b-nav-item>
      <div class="navicon-container align-center" @click="toggleNav">
        <div class="navicon"></div>
        <div class="navicon"></div>
        <div class="navicon"></div>
      </div>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      togglenav: null,
      adminNavDisplay: false,
    }
  },
  computed: {
    username() {
      return this.$store.state.localStorage.auth.user
    },
    loggedIn() {
      return this.$store.state.localStorage.auth.loggedIn
    },
    isAdmin() {
      return this.$store.state.localStorage.auth.admin
    },
  },
  methods: {
    logout() {
      try {
        this.$store.dispatch('localStorage/logout')
      } catch (e) {
        // console.log(e)
      }
      this.$router.push(this.localePath('/'))
    },
    toggleNav() {
      /* if (window.innerWidth <= 768) {
        this.togglenav = this.togglenav != null ? null : 'mobile-nav'
        // console.log(this.togglenav)
      } */
      this.adminNavDisplay = !this.adminNavDisplay
    },
  },
}
</script>

<style lang="scss" scoped>
.user-wrapper {
  background-color: #fff;
  padding-top: 60px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding: 10px;
  align-self: center;
  margin: auto;
}

.nav-item {
  align-self: center;
  margin: 0;
  padding: 0;
}
.navbar-nav {
  padding-top: 45px;
}

.nav {
  display: block;
}
.navicon {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 15px;
}

.navmenu {
  width: 100%;
  display: inline-grid;
}

a {
  color: #fff;
}

img {
  height: 25px;
}
.nav-item-obj {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 5px;
}
svg {
  height: 35px;
  width: 25px;
}
.nuxt-link-exact-active {
  // .nav-item-obj {
  //   background: #fff;
  // }
  span {
    background: #5f5f5f;
    width: 100%;
    display: block;
    height: 40px;
    line-height: 40px;
  }
  svg {
    fill: white;
  }
  .cls-1 {
    fill: white;
    stroke: white;
  }
  .st0 {
    stroke: white;
  }
}
.navbar-nav .nav-link {
  padding: 10px 0;
  color: white;
  &:hover {
    svg {
      fill: white;
    }
    .cls-1 {
      fill: white;
      stroke: white;
    }
    .st0 {
      stroke: white;
    }
  }
}
li {
  padding-left: 2px;
  padding-right: 2px;
}

p {
  font-size: 1rem;
}

/* @media (min-width: 768px) { */
.navmenu {
  background-color: #333;
  min-height: 100vh;
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  top: 0px;
  overflow-x: hidden;
  overflow-y: visible;
  padding-top: 20px;
  width: 110px;
}

.navicon-container {
  display: none;
}
/* } */

/* @media (max-width: 768px) {
  .navmenu {
    background-color: #1cbcb4;
    height: auto;
    width: 100%;
  }
  .nav-item {
    display: none;
  }

  .mobile-nav {
    display: block;
  }

  .navicon-container {
    display: block;
  }
} */
.m-show,
.m-hide {
  display: block;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .m-show {
    display: block;
  }

  .m-hide {
    display: none;
  }

  .navmenu {
    width: 100%;
    z-index: 4;
  }

  .navbar-nav {
    padding-top: 25px;
  }
}
</style>
