var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "notibox" }, [
        _c("div", { staticClass: "notibox-heading" }, [
          _c("div", { staticClass: "noti_title" }, [
            _vm._v(_vm._s(_vm.$t("noti.title"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "close_noti", on: { click: _vm.closeNoti } },
            [_c("b-icon", { attrs: { icon: "x" } })],
            1
          ),
          _vm._v(" "),
          _vm.user.isAdmin
            ? _c(
                "div",
                { staticClass: "noti_btn_grp" },
                [
                  _vm.checkRole() === "t3" || _vm.checkRole() === "admin"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "noti_btn",
                          attrs: {
                            variant: "outline-light",
                            pressed: _vm.showingScheme === "scds",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("scds")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s("SCDS") +
                              " (" +
                              _vm._s(
                                _vm.scdsArray.filter((a) => {
                                  return a.staff_handled === 0
                                }).length
                              ) +
                              ")\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkRole() === "t2" || _vm.checkRole() === "admin"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "noti_btn",
                          attrs: {
                            variant: "outline-light",
                            pressed: _vm.showingScheme === "spaps",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("spaps")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s("SPAPS") +
                              " (" +
                              _vm._s(
                                _vm.spapsArray.filter((a) => {
                                  return a.staff_handled === 0
                                }).length
                              ) +
                              ")\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkRole() === "t2" || _vm.checkRole() === "admin"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "noti_btn",
                          attrs: {
                            variant: "outline-light",
                            pressed: _vm.showingScheme === "aes",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("aes")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s("AES") +
                              " (" +
                              _vm._s(
                                _vm.aesArray.filter((a) => {
                                  return a.staff_handled === 0
                                }).length
                              ) +
                              ")\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkRole() === "t2" || _vm.checkRole() === "admin"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "noti_btn",
                          attrs: {
                            variant: "outline-light",
                            pressed: _vm.showingScheme === "aoa",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("aoa")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s("AOA") +
                              " (" +
                              _vm._s(
                                _vm.aoaArray.filter((a) => {
                                  return a.staff_handled === 0
                                }).length
                              ) +
                              ")\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkRole() === "t2" || _vm.checkRole() === "admin"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "noti_btn",
                          attrs: {
                            variant: "outline-light",
                            pressed: _vm.showingScheme === "other",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("other")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s("Other") +
                              " (" +
                              _vm._s(
                                _vm.otherArray.filter((a) => {
                                  return a.staff_handled === 0
                                }).length
                              ) +
                              ")\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c("div", [
                _c("hr", { staticStyle: { margin: "10px 0px 0px 0px" } }),
              ]),
        ]),
        _vm._v(" "),
        _vm.showingScheme === "scds"
          ? _c("div", { staticClass: "noti-main" }, [
              _vm.scdsArray.length !== 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.scdsArray.slice().reverse(),
                      function (noti, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            noti.type === "NEW_APPLICATION"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "file-earmark-text",
                                      color: "rgb(131, 160, 52)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.new_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(noti.title) + " – "),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEventName(noti.event_id)
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            1,
                                            "new"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(noti.trnId))]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CHANGE_REQUEST"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "pencil-square",
                                      color: "#dea95f",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.change_request"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(noti.title) + " – "),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEventName(noti.event_id)
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      ":\n          " +
                                        _vm._s(noti.schoolname) +
                                        "\n          "
                                    ),
                                    _vm.isCancel(noti.content)
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getContent(
                                                noti.admin_team,
                                                noti.trnId,
                                                "request_cancel"
                                              )
                                            ),
                                          },
                                        })
                                      : _c("span", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getContent(
                                                  noti.admin_team,
                                                  noti.trnId,
                                                  "request_edit"
                                                )
                                              ),
                                            },
                                          }),
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.editSection(
                                                  noti.content,
                                                  noti.admin_team
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("(" + _vm._s(noti.trnId) + ")"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "FEEDBACK"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "chat-left-text",
                                      color: "rgb(123, 200, 245)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t("noti.feedback"),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(noti.username) +
                                          _vm._s(
                                            _vm.$t("noti.feedback_content")
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { color: "#6a6a6a" } },
                                      [_vm._v('"' + _vm._s(noti.content) + '"')]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CANCEL_APPLICATION_BEFORE_DEADLINE"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "exclamation-square",
                                      color: "#e86767",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.cancel_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(noti.title) + " – "),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEventName(noti.event_id)
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      ":\n          " +
                                        _vm._s(noti.schoolname) +
                                        "\n          "
                                    ),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            1,
                                            "cancel"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("(" + _vm._s(noti.trnId) + ")"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _c("div", [
                    _c("div", { staticClass: "no_noti" }, [
                      _vm._v(_vm._s(_vm.$t("noti.no_noti"))),
                    ]),
                  ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showingScheme === "spaps"
          ? _c("div", { staticClass: "noti-main" }, [
              _vm.spapsArray.length !== 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.spapsArray.slice().reverse(),
                      function (noti, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            noti.type === "NEW_APPLICATION"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "file-earmark-text",
                                      color: "rgb(131, 160, 52)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.new_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    noti.title
                                      ? _c(
                                          "div",
                                          { staticClass: "spaps-warn" },
                                          [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "noti.submission_count"
                                                  )
                                                ) +
                                                _vm._s(noti.title) +
                                                "\n          "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            2,
                                            "new"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(noti.trnId))]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CHANGE_REQUEST"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "pencil-square",
                                      color: "#dea95f",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.change_request"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(noti.schoolname) +
                                        "\n          "
                                    ),
                                    _vm.isCancel(noti.content)
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getContent(
                                                noti.admin_team,
                                                noti.trnId,
                                                "request_cancel"
                                              )
                                            ),
                                          },
                                        })
                                      : _c("span", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getContent(
                                                  noti.admin_team,
                                                  noti.trnId,
                                                  "request_edit"
                                                )
                                              ),
                                            },
                                          }),
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.editSection(
                                                  noti.content,
                                                  noti.admin_team
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("(" + _vm._s(noti.trnId) + ")"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "FEEDBACK"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "chat-left-text",
                                      color: "rgb(123, 200, 245)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t("noti.feedback"),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(noti.username) +
                                          _vm._s(
                                            _vm.$t("noti.feedback_content")
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { color: "#6a6a6a" } },
                                      [_vm._v('"' + _vm._s(noti.content) + '"')]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CANCEL_APPLICATION_BEFORE_DEADLINE"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "exclamation-square",
                                      color: "#e86767",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.cancel_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(noti.schoolname) +
                                        "\n          "
                                    ),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            2,
                                            "cancel"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("(" + _vm._s(noti.trnId) + ")"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _c("div", [
                    _c("div", { staticClass: "no_noti" }, [
                      _vm._v(_vm._s(_vm.$t("noti.no_noti"))),
                    ]),
                  ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showingScheme === "aes"
          ? _c("div", { staticClass: "noti-main" }, [
              _vm.aesArray.length !== 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.aesArray.slice().reverse(),
                      function (noti, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            noti.type === "NEW_APPLICATION"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "file-earmark-text",
                                      color: "rgb(131, 160, 52)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.new_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEventName(noti.event_id)
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            3,
                                            "new"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(noti.trnId))]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CHANGE_REQUEST"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "pencil-square",
                                      color: "#dea95f",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.change_request"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEventName(noti.event_id)
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      ":\n          " +
                                        _vm._s(noti.schoolname) +
                                        "\n          "
                                    ),
                                    _vm.isCancel(noti.content)
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getContent(
                                                noti.admin_team,
                                                noti.trnId,
                                                "request_cancel"
                                              )
                                            ),
                                          },
                                        })
                                      : _c("span", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getContent(
                                                  noti.admin_team,
                                                  noti.trnId,
                                                  "request_edit"
                                                )
                                              ),
                                            },
                                          }),
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.editSection(
                                                  noti.content,
                                                  noti.admin_team
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                        ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("(" + _vm._s(noti.trnId) + ")"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "FEEDBACK"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "chat-left-text",
                                      color: "rgb(123, 200, 245)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t("noti.feedback"),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(noti.username) +
                                          _vm._s(
                                            _vm.$t("noti.feedback_content")
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { color: "#6a6a6a" } },
                                      [_vm._v('"' + _vm._s(noti.content) + '"')]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CANCEL_APPLICATION_BEFORE_DEADLINE"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "exclamation-square",
                                      color: "#e86767",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.cancel_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getEventName(noti.event_id)
                                        ),
                                      },
                                    }),
                                    _vm._v(
                                      ":\n          " +
                                        _vm._s(noti.schoolname) +
                                        "\n          "
                                    ),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            3,
                                            "cancel"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("(" + _vm._s(noti.trnId) + ")"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _c("div", [
                    _c("div", { staticClass: "no_noti" }, [
                      _vm._v(_vm._s(_vm.$t("noti.no_noti"))),
                    ]),
                  ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showingScheme === "aoa"
          ? _c("div", { staticClass: "noti-main" }, [
              _vm.aoaArray.length !== 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.aoaArray.slice().reverse(),
                      function (noti, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            noti.type === "NEW_APPLICATION"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "file-earmark-text",
                                      color: "rgb(131, 160, 52)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.new_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            4,
                                            "new"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(noti.trnId))]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "FEEDBACK"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "chat-left-text",
                                      color: "rgb(123, 200, 245)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t("noti.feedback"),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(noti.username) +
                                          _vm._s(
                                            _vm.$t("noti.feedback_content")
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { color: "#6a6a6a" } },
                                      [_vm._v('"' + _vm._s(noti.content) + '"')]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showingScheme === "other"
          ? _c("div", { staticClass: "noti-main" }, [
              _vm.otherArray.length !== 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.otherArray.slice().reverse(),
                      function (noti, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            noti.type === "NEW_APPLICATION"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "file-earmark-text",
                                      color: "rgb(131, 160, 52)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t(
                                        "noti.new_application"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getLink(
                                            noti.event_id,
                                            noti.schemeId,
                                            4,
                                            "new"
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(noti.trnId))]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "FEEDBACK"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "chat-left-text",
                                      color: "rgb(123, 200, 245)",
                                      handled: noti.staff_handled,
                                      "card-title": _vm.$t("noti.feedback"),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(noti.username) +
                                          _vm._s(
                                            _vm.$t("noti.feedback_content")
                                          )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { color: "#6a6a6a" } },
                                      [_vm._v('"' + _vm._s(noti.content) + '"')]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.checkRole()
          ? _c("div", { staticClass: "noti-main-teacher" }, [
              _vm.notiArray.length !== 0
                ? _c(
                    "div",
                    _vm._l(
                      _vm.notiArray.slice().reverse(),
                      function (noti, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            noti.type === "UPDATE_APPLICATION_RESULT"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "file-earmark-text",
                                      color: "rgb(131, 160, 52)",
                                      handled: noti.user_handled,
                                      "card-title": _vm.$t(
                                        "noti.update_application_result"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "noti.update_application_result_content1"
                                            )
                                          ) +
                                          "(" +
                                          _vm._s(noti.trnId) +
                                          ")\n          "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getContent(
                                            noti.admin_team,
                                            noti.trnId,
                                            "update_result"
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "EVENT_CANCELLATION"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "fexclamation-square",
                                      color: "#e86767",
                                      handled: noti.user_handled,
                                      "card-title": _vm.$t(
                                        "noti.event_cancellation"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(noti.content))])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CHANGE_APPLICATION_DETAIL"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "pencil-square",
                                      color: "#dea95f",
                                      handled: noti.user_handled,
                                      "card-title": _vm.$t(
                                        "noti.change_application_detail"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [_c("span", [_vm._v(_vm._s(noti.content))])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "PROMOTION"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "volume-up",
                                      color: "#e2a9f2",
                                      handled: noti.user_handled,
                                      "card-title": _vm.changeLang(
                                        "subject",
                                        noti.title
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    noti.content
                                      ? _c("div", [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.changeLang(
                                                  "content",
                                                  noti.content
                                                )
                                              ) +
                                              "\n          "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CHANGE_REQUEST_APPROVED"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "volume-up",
                                      color: "#dea95f",
                                      handled: noti.user_handled,
                                      "card-title": _vm.$t(
                                        "noti.change_request"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    noti.admin_team === "spaps"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_vm.$t("sar.spaps")) +
                                              "：\n          "
                                          ),
                                        ])
                                      : noti.admin_team === "scds"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(noti.title) +
                                              "\n            "
                                          ),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getEventName(noti.event_id)
                                              ),
                                            },
                                          }),
                                          _vm._v("：\n          "),
                                        ])
                                      : noti.admin_team === "aes"
                                      ? _c("div", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getEventName(noti.event_id)
                                              ),
                                            },
                                          }),
                                          _vm._v("：\n          "),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    noti.content.includes("cancel")
                                      ? _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.getContent(
                                                noti.admin_team,
                                                noti.trnId,
                                                "cancel_approved"
                                              )
                                            ),
                                          },
                                        })
                                      : _c("div", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getContent(
                                                  noti.admin_team,
                                                  noti.trnId,
                                                  "change_approved"
                                                )
                                              ),
                                            },
                                          }),
                                        ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CHANGE_REQUEST_REJECTED"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "pencil-square",
                                      color: "#dea95f",
                                      handled: noti.user_handled,
                                      "card-title": _vm.$t(
                                        "noti.change_request"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    noti.admin_team === "spaps"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_vm.$t("sar.spaps")) +
                                              "：\n          "
                                          ),
                                        ])
                                      : noti.admin_team === "scds"
                                      ? _c("div", [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(noti.title) +
                                              "\n            "
                                          ),
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getEventName(noti.event_id)
                                              ),
                                            },
                                          }),
                                          _vm._v("：\n          "),
                                        ])
                                      : noti.admin_team === "aes"
                                      ? _c("div", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getEventName(noti.event_id)
                                              ),
                                            },
                                          }),
                                          _vm._v("：\n          "),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getContent(
                                            noti.admin_team,
                                            noti.trnId,
                                            "change_rejected"
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            noti.type === "CHANGE_EVENT_DETAIL"
                              ? _c(
                                  "NotificationCard",
                                  {
                                    attrs: {
                                      id: noti.id,
                                      icon: "pencil-square",
                                      color: "#dea95f",
                                      handled: noti.user_handled,
                                      "card-title": _vm.$t(
                                        "noti.change_event_detail"
                                      ),
                                      datetime: noti.created_at,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.getEventLink(
                                              noti.content
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getEventTitle(noti.content)
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "noti.change_event_detail_content"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _c("div", [
                    _c("div", { staticClass: "no_noti" }, [
                      _vm._v(_vm._s(_vm.$t("noti.no_noti"))),
                    ]),
                  ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }