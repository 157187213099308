var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { attrs: { sm: "12", md: "10" } },
        [
          _vm.error
            ? _c("Notification", { attrs: { message: _vm.error } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "b-form",
                {
                  attrs: { method: "post" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-form-group",
                    { attrs: { id: "title", "label-for": "title" } },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { "content-class": "mt-3" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("contact.name.en") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "name_en",
                                  type: "text",
                                  required: "",
                                  trim: "",
                                },
                                model: {
                                  value: _vm.name_en,
                                  callback: function ($$v) {
                                    _vm.name_en = $$v
                                  },
                                  expression: "name_en",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("contact.name.tc") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "name_tc",
                                  type: "text",
                                  required: "",
                                  trim: "",
                                },
                                model: {
                                  value: _vm.name_tc,
                                  callback: function ($$v) {
                                    _vm.name_tc = $$v
                                  },
                                  expression: "name_tc",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("contact.name.sc") } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "name_sc",
                                  type: "text",
                                  required: "",
                                  trim: "",
                                },
                                model: {
                                  value: _vm.name_sc,
                                  callback: function ($$v) {
                                    _vm.name_sc = $$v
                                  },
                                  expression: "name_sc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "label" }, [
                    _vm._v(_vm._s(_vm.$t("contact.tel"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { id: "tel", "label-for": "tel" } },
                    [
                      _c("b-form-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.tel,
                          callback: function ($$v) {
                            _vm.tel = $$v
                          },
                          expression: "tel",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "submit" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            id: "submit",
                            type: "submit",
                            variant: "outline-success",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("action.submit")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }