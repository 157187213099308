var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.event
        ? _c("tab-0", {
            attrs: {
              event: _vm.event,
              venues: _vm.venues,
              "first-slot": _vm.firstSlot,
              "second-slot": _vm.secondSlot,
              "pass-choice": _vm.choice,
              status: _vm.status,
              "cancelled-choice": _vm.cancelledChoice,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.event
        ? _c("tab-1", {
            attrs: {
              edit: false,
              "pass-teachers": _vm.teachers,
              remarks: _vm.remarks,
              admin: _vm.admin,
              "school-id": _vm.schoolId,
              "user-name-tc": _vm.userNameTc,
              "user-email": _vm.userEmail,
              "has-tel": _vm.hasTel,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      (_vm.$route.name.includes("application") &&
        !_vm.$route.name.includes("change_request")) ||
      (_vm.$route.name.includes("apply") && !_vm.event)
        ? _c("tab-2", { attrs: { scheme: "scds" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }