var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pw-cont" },
    [
      _c("h2", { staticClass: "title" }, [
        _c("img", {
          attrs: {
            src: `${_vm.staticPrefix}img/icon_information.svg`,
            alt: "icon information",
          },
        }),
        _vm._v("\n    " + _vm._s(_vm.$t("account.information")) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pw-cont-inner" },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _c("label", { attrs: { for: "textarea-small" } }, [
                  _vm._v(_vm._s(_vm.$t("account.name"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: { type: "text", readonly: "" },
                    model: {
                      value: _vm.user.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "name", $$v)
                      },
                      expression: "user.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _c("label", { attrs: { for: "textarea-small" } }, [
                  _vm._v(_vm._s(_vm.$t("account.email"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: { type: "text", readonly: "" },
                    model: {
                      value: _vm.user.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _c("label", { attrs: { for: "textarea-small" } }, [
                  _vm._v(_vm._s(_vm.$t("account.school"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c("b-form-input", {
                    attrs: { type: "text", readonly: "" },
                    model: {
                      value: _vm.schoolName,
                      callback: function ($$v) {
                        _vm.schoolName = $$v
                      },
                      expression: "schoolName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _c("label", { attrs: { for: "textarea-small" } }, [
                  _vm._v(_vm._s(_vm.$t("account.schoolId"))),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", [_c("span", [_vm._v(_vm._s(_vm.user.school))])]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _c("label", { attrs: { for: "textarea-small" } }, [
                  _vm._v(_vm._s(_vm.$t("account.account"))),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", [
                _vm.user.schoolAdmin
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("user.profileisSchoolAdmin")) +
                          "\n        "
                      ),
                    ])
                  : _vm.user.editor
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("user.isEditorAdmin")) +
                          "\n        "
                      ),
                    ])
                  : _vm.user.admin
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("user.isSuperAdmin")) +
                          "\n        "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("user.schoolTeacher")) +
                          "\n        "
                      ),
                    ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "submit" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-apply",
                  attrs: { pill: "", href: _vm.$t("account.modifyInfo") },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("account.submit")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.error
        ? _c("Notification", { attrs: { message: _vm.error } })
        : _vm._e(),
      _vm._v(" "),
      _vm.success
        ? _c(
            "b-alert",
            { attrs: { variant: "success", show: "" } },
            [
              _c("b-icon", { attrs: { icon: "check-circle" } }),
              _vm._v("\n    " + _vm._s(_vm.$t("account.pwSuccess")) + "\n  "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }