var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "client-only",
    [
      _c("editor", {
        attrs: { id: _vm.id, init: _vm.init },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }