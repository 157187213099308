var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "created_at",
                      "label-for": "created_at",
                      label: _vm.$t("change_request.date"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "created_at", type: "text", readonly: "" },
                      model: {
                        value: _vm.created_at,
                        callback: function ($$v) {
                          _vm.created_at = $$v
                        },
                        expression: "created_at",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "school",
                      "label-for": "school",
                      label: _vm.$t("change_request.school"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "school", type: "text", readonly: "" },
                      model: {
                        value: _vm.school,
                        callback: function ($$v) {
                          _vm.school = $$v
                        },
                        expression: "school",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "userId",
                      "label-for": "userId",
                      label: _vm.$t("change_request.username"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "userId", type: "text", readonly: "" },
                      model: {
                        value: _vm.userId,
                        callback: function ($$v) {
                          _vm.userId = $$v
                        },
                        expression: "userId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "trnId",
                      "label-for": "trnId",
                      label: _vm.$t("change_request.trnId"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "trnId", type: "text", readonly: "" },
                      model: {
                        value: _vm.trnId,
                        callback: function ($$v) {
                          _vm.trnId = $$v
                        },
                        expression: "trnId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "formId",
                      "label-for": "formId",
                      label: _vm.$t("feedback.scheme"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "formId", type: "text", readonly: "" },
                      model: {
                        value: _vm.formId,
                        callback: function ($$v) {
                          _vm.formId = $$v
                        },
                        expression: "formId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "eventCode",
                      "label-for": "eventCode",
                      label: _vm.$t("change_request.eventCode"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "eventCode", type: "text", readonly: "" },
                      model: {
                        value: _vm.eventCode,
                        callback: function ($$v) {
                          _vm.eventCode = $$v
                        },
                        expression: "eventCode",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "activityName",
                      "label-for": "activityName",
                      label: _vm.$t("event.name"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "activityName", type: "text", readonly: "" },
                      model: {
                        value: _vm.activityName,
                        callback: function ($$v) {
                          _vm.activityName = $$v
                        },
                        expression: "activityName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "eventSlot",
                      "label-for": "eventSlot",
                      label: _vm.$t("change_request.eventSlot"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "eventSlot", type: "text", readonly: "" },
                      model: {
                        value: _vm.eventSlot,
                        callback: function ($$v) {
                          _vm.eventSlot = $$v
                        },
                        expression: "eventSlot",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "changeSection",
                      "label-for": "changeSection",
                      label: _vm.$t("change_request.changeSection"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "changeSection",
                        type: "text",
                        readonly: "",
                      },
                      model: {
                        value: _vm.changeSection,
                        callback: function ($$v) {
                          _vm.changeSection = $$v
                        },
                        expression: "changeSection",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "changeContent",
                      "label-for": "changeContent",
                    },
                  },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("change_request.detail") } },
                          [
                            _c("b-textarea", {
                              staticStyle: { "background-color": "white" },
                              attrs: {
                                "no-resize": "",
                                rows: "10",
                                readonly: "",
                              },
                              model: {
                                value: _vm.changeContent,
                                callback: function ($$v) {
                                  _vm.changeContent = $$v
                                },
                                expression: "changeContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "ABO備註" } },
                  [
                    _c("b-textarea", {
                      staticStyle: { "background-color": "white" },
                      attrs: { rows: "2", "max-rows": "3" },
                      model: {
                        value: _vm.remarks,
                        callback: function ($$v) {
                          _vm.remarks = $$v
                        },
                        expression: "remarks",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "request_checked",
                          name: "request_checked",
                          value: "1",
                          "unchecked-value": "0",
                          disabled: _vm.checkValid,
                        },
                        model: {
                          value: _vm.status,
                          callback: function ($$v) {
                            _vm.status = $$v
                          },
                          expression: "status",
                        },
                      },
                      [_vm._v("\n            已處理 (獲接納)\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          id: "request_checked2",
                          name: "request_checked2",
                          value: "2",
                          "unchecked-value": "0",
                          disabled: _vm.checkValid2,
                        },
                        model: {
                          value: _vm.status,
                          callback: function ($$v) {
                            _vm.status = $$v
                          },
                          expression: "status",
                        },
                      },
                      [_vm._v("\n            已處理 (不獲接納)\n          ")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-success" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("apply.back")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { variant: "outline-primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submit_update()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("apply.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }