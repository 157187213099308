var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "calendar_title border-bottom" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("home.calendar")) + "\n    "),
        _c(
          "div",
          {
            directives: [
              {
                name: "b-modal",
                rawName: "v-b-modal.modal-calendar",
                modifiers: { "modal-calendar": true },
              },
            ],
            staticClass: "more btn",
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("home.view_calendar")) + "\n      "
            ),
            _c("b-icon", {
              staticStyle: { "vertical-align": "sub" },
              attrs: { icon: "calendar2-check" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("v-calendar", {
        staticClass: "calendar",
        attrs: {
          "title-position": "left",
          attributes: _vm.attrs,
          locale: {
            id: _vm.i18nCalendar(),
            firstDayOfWeek: 0,
            // masks: { title: $moment(datePicked.date).format('yyyy年M月D日') },
            // masks: { title: 'YYYY年MMMD日' },
          },
          "first-day-of-week": 0,
          "min-date": new Date(),
          "is-expanded": "",
          "is-inline": true,
          "update-on-input": true,
        },
        on: { dayclick: (day) => _vm.test(day) },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "min-height": "300px" } },
        [
          _c(
            "b-button-group",
            { staticClass: "cus-btn-grp" },
            [
              _c(
                "b-button",
                {
                  staticClass: "cus-btn",
                  staticStyle: { "font-size": "96%" },
                  attrs: {
                    variant: "outline-light",
                    pressed: _vm.showAppliedEvent,
                  },
                  on: {
                    click: () => {
                      if (_vm.loggedIn) _vm.showAppliedEvent = true
                      else _vm.$router.push(_vm.localePath("/login"))
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("home.applied_event")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "cus-btn",
                  staticStyle: { "font-size": "96%" },
                  attrs: {
                    variant: "outline-light",
                    pressed: !_vm.showAppliedEvent,
                  },
                  on: { click: () => (_vm.showAppliedEvent = false) },
                },
                [_vm._v(_vm._s(_vm.$t("home.upcoming_event")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showAppliedEvent
            ? _c("div", [
                _vm.applicationsSuccess
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.applicationsSuccess,
                        function (application, index) {
                          return _c("div", { key: index }, [
                            application.result_choice
                              ? _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-calendar",
                                        modifiers: { "modal-calendar": true },
                                      },
                                    ],
                                    on: {
                                      click: () => {
                                        _vm.eventSelected = application.event
                                        _vm.$store.commit(
                                          "tempStorage/storeEventSelected",
                                          _vm.eventSelected
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("EventCardForCalendar", {
                                      attrs: {
                                        event: application.event,
                                        index: index,
                                        bookmarks: _vm.bookmarks,
                                        schemes: _vm.schemes,
                                        "result-slot":
                                          application.result_event_slot,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            index !== _vm.applicationsSuccess.length - 1
                              ? _c("div", {
                                  staticStyle: {
                                    border: "1px solid whitesmoke",
                                  },
                                })
                              : _vm._e(),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.applicationsSuccess.length
                  ? _c("div", { staticClass: "result-txt" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("home.no_applied_event")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("div", [
                _vm.filteredEvents.length < 1
                  ? _c("div", { staticClass: "result-txt" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("home.no_upcoming_event")) +
                          "\n      "
                      ),
                    ])
                  : _c("div", [
                      _vm.events
                        ? _c(
                            "div",
                            { staticClass: "card-list" },
                            _vm._l(_vm.filteredEvents, function (event, index) {
                              return _c("div", { key: index }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-calendar",
                                        modifiers: { "modal-calendar": true },
                                      },
                                    ],
                                    on: {
                                      click: () => {
                                        // eventSelected = event
                                        _vm.$store.commit(
                                          "tempStorage/storeEventSelected",
                                          event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("EventCardForCalendar", {
                                      attrs: {
                                        event: event,
                                        index: index,
                                        bookmarks: _vm.bookmarks,
                                        schemes: _vm.schemes,
                                        "result-slot":
                                          _vm.getSelectedDateEventSlotId(event),
                                        "is-applied": _vm.isAppliedEvent(
                                          event.code
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                index !== _vm.filteredEvents.length - 1
                                  ? _c("div", {
                                      staticStyle: {
                                        border: "1px solid whitesmoke",
                                      },
                                    })
                                  : _vm._e(),
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("full-calendar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }