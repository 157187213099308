<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="9" style="font-size: 0.9em">
      <div>
        <div>
          <div>報名階段</div>
          <b-form-radio-group
            v-model="spapsRoundOfToday"
            :options="spapsRoundOption"
            :state="!!spapsRoundOfToday"
            class="mb-3"
            required
          ></b-form-radio-group>
        </div>
        <div>報名方法</div>
        <div style="display: flex" class="mb-3">
          <b-form-radio-group
            v-model="applyMethod"
            :options="applyMethodOption"
            :state="!!applyMethod"
            required
          ></b-form-radio-group>
          <b-form-input
            v-model="applyMethodOther"
            type="text"
            maxlength="1000"
            style="width: 200px"
          />
        </div>
        <div class="mb-1">報名遞交時間（YYYY-MM-DD HH:mm:ss）</div>
        <b-form-input
          v-model="submitTime"
          :state="submitTimeState(submitTime)"
          type="text"
          style="width: 300px"
          class="mb-3"
        ></b-form-input>

        <div class="mb-1">申請編號（自動產生）</div>
        <b-form-input
          v-model="trnId"
          class="mb-3"
          type="text"
          readonly
        ></b-form-input>

        <b-form-group label-for="form" label="匯入申請（OGCIO .zip）">
          <b-form-file
            v-if="action === 'add'"
            id="form"
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
            @change="uploadForm"
          />
        </b-form-group>
        <!-- {{ data }} -->
        <div>
          <div class="mb-1">附件</div>
          <p v-for="(a, index) in attr" :key="index">
            <a :href="`${attachmentUrl}${a}`" target="_blank">
              <b-icon icon="paperclip"></b-icon>{{ a }}
            </a>
          </p>
        </div>
      </div>

      <b-form @submit="onSubmit">
        <div class="shadow">
          <matching-school
            :data="data"
            :sch-id="sarId"
            :name="schoolData ? schoolData.name_tc : ''"
            @input="onChangeSkl"
          />
        </div>
        <div class="shadow">
          <applySchoolInfo
            :promo="disagreePromo"
            :teacher-data="teachers"
            :school-data="schoolData"
            @change="onSchoolInfoChange"
          />
        </div>

        <div class="shadow">
          <tab-1
            v-if="eventlist"
            :choice-hint="choiceHint"
            :class-hints="classHints"
            :anticipated-class-hints="anticipatedClassHints"
            :eventlist="eventlist"
            :choice-array="choiceArray"
            :classes="classes"
            :event-group="eventGroup"
            :workshop="workshop"
            :edit="true"
            @input="getTab1Input"
          ></tab-1>
        </div>
        <div class="shadow">
          <tab-2
            :edit="true"
            :equipments="equipments"
            @input="getTab2Input"
          ></tab-2>
        </div>
        <div class="shadow">
          <tab-3 :edit="true" :sklinfo="sklinfo" @input="getTab3Input"></tab-3>
        </div>
        <!-- <div class="shadow">
          <tab-5></tab-5>
        </div> -->

        <b-button type="submit" variant="success"> 提交 </b-button>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
// import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import tab1 from '~/components/apply/spaps/tab1.vue'
import tab2 from '~/components/apply/spaps/tab2.vue'
import tab3 from '~/components/apply/spaps/tab3.vue'
/* import tab5 from '~/components/apply/spaps/tab5.vue' */
import applySchoolInfo from '~/components/admin/application/applySchoolInfo'
export default {
  layout: 'admin',
  middleware: ['Admin', 'jwtCheck'],
  components: {
    applySchoolInfo,
    tab1,
    tab2,
    tab3,
    // tab5, */
  },
  props: ['action'],
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/public/getId/${this.$route.params.slug}`
      )
      this.schemeId = result.data.data ? result.data.data[0].id : null

      const result2 = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/subScheme/${this.schemeId}`
      )
      this.eventlist = result2.data.data
      this.eventlist.map((d) => {
        d.value = d.id
        d.text = `${
          d.organiser ? this.i18nTitle(JSON.parse(d.organiser)) : ''
        }：${this.i18nTitle(d)}`
      })
      this.eventlist.sort((a, b) => a.code.localeCompare(b.code))
      this.generateEventlist()
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      classHints: null,
      anticipatedClassHints: null,
      sklinfo: {
        ticName1: {
          surname: null,
          givenName: null,
        },
        ticName2: {
          surname: null,
          givenName: null,
        },
        schoolMobile1: null,
        schoolMobile2: null,
        email1: null,
        email2: null,

        fund: null,
        otherGroup: null,
        otherGroupSpecify: null,

        otherClass: null,
        otherClassContent: null,
        otherClassYear: null,

        otherTraining: null,
        otherTrainingType: null,
        otherTrainingName: null,
        otherTrainingYear: null,

        havePerformance: null,
        havePerformanceType: null,
        havePerformanceName: null,
        havePerformanceYear: null,
      },
      schoolData: {
        name_tc: null,
        name_en: null,
        category: null,
        region: null,
        district: 0,
        address_area: null,
        address_street: null,
        address_building: null,
        tel: null,
        fax: null,
        email: null,
        principal_name: null,
      },
      choiceHint: [],
      choiceArray: [-1],
      classes: [],
      eventGroup: null,
      workshop: {
        workshopFirstweekday: null,
        workshopFirsttime: null,
        workshopSecondweekday: null,
        workshopSecondtime: null,
        performFirstdate: null,
        performFirsttime: null,
        performSeconddate: null,
        performSecondtime: null,
        anticipatedClass: null,
        anticipatedNo: null,
      },
      eventlist: [],
      data: null,
      trnId: '',
      submitTime: '',
      teachers: [{}],
      declare: true,
      disagreePromo: false,
      attr: [],
      sarId: null,
      keyword: '',
      perPage: 15,
      currentPage: 1,
      suggestEventList: [],
      selectedEvent: [],
      suggestEventSlotList: [],
      selectedEventIndex: 0,
      selectedEventId: null,
      targetMap: [
        'k1',
        'k2',
        'k3',
        'p1',
        'p2',
        'p3',
        'p4',
        'p5',
        'p6',
        'f1',
        'f2',
        'f3',
        'f4',
        'f5',
        'f6',
      ],
      targetOption: [
        { value: 'k1', text: this.intToGrade(-2) },
        { value: 'k2', text: this.intToGrade(-1) },
        { value: 'k3', text: this.intToGrade(0) },
        { value: 'p1', text: this.intToGrade(1) },
        { value: 'p2', text: this.intToGrade(2) },
        { value: 'p3', text: this.intToGrade(3) },
        { value: 'p4', text: this.intToGrade(4) },
        { value: 'p5', text: this.intToGrade(5) },
        { value: 'p6', text: this.intToGrade(6) },
        { value: 'f1', text: this.intToGrade(7) },
        { value: 'f2', text: this.intToGrade(8) },
        { value: 'f3', text: this.intToGrade(9) },
        { value: 'f4', text: this.intToGrade(10) },
        { value: 'f5', text: this.intToGrade(11) },
        { value: 'f6', text: this.intToGrade(12) },
      ],
      applyMethodOption: [
        { value: 'E', text: 'OGCIO E-Form' },
        { value: 'F', text: 'Fax' },
        { value: 'M', text: 'Mail' },
        { value: 'O', text: '其他報名方法（請註明）：' },
      ],
      spapsRoundOption: [
        { value: 1, text: 'Regular' },
        { value: 2, text: 'Late Submission' },
      ],
      applyMethod: null,
      applyMethodOther: '',
      schemeId: null,
      spapsRoundOfToday: null,
      equipments: {
        venue: null,
        plan: null,
        size: null,
        floor: null,
        equipment: null,
        venue2: null,
        speaker: null,
        mic: null,
        micAmount: null,
      },
    }
  },
  computed: {
    getData() {
      const data = {
        choice: this.choiceArray,
        classes: this.classes,
        eventGroup: this.eventGroup,
        workshop: this.workshop,
        equipments: this.equipments,
        sklinfo: this.sklinfo,
      }
      return JSON.stringify(Object.assign(data, this.schoolData))
    },
    attachmentUrl() {
      return process.env.attachmentUrl
    },
  },
  watch: {
    applyMethod: {
      handler(newVal, oldVal) {
        if (newVal !== 'E') {
          this.getTrnId()
        }
      },
      deep: true,
    },
  },
  methods: {
    mappingDistrict(data) {
      const id = parseInt(data)
      switch (id) {
        case 2:
          return 4
        case 3:
          return 2
        case 4:
          return 3
        case 6:
          return 8
        case 7:
          return 6
        case 8:
          return 9
        case 9:
          return 7
        case 10:
          return 16
        case 12:
          return 13
        case 13:
          return 14
        case 14:
          return 15
        case 15:
          return 12
        case 16:
          return 17
        case 17:
          return 18
        case 18:
          return 10
        default:
          return id
      }
    },
    async onChangeSkl(sarId) {
      this.sarId = sarId
      await this.$axios
        .get(`${process.env.localApiUrl}schools/sarid/getSchoolList/${sarId}`)
        .then((result) => {
          if (result.data.data.length > 0) {
            const tmp = result.data.data[0]
            this.schoolData = {
              name_tc: tmp.name_tc,
              name_en: tmp.name_en,
              category: tmp.type,
              region: tmp.region,
              district: tmp.district,
              address_area: tmp.address_area,
              address_street: tmp.address_street,
              address_building: tmp.address_building,
              tel: tmp.tel,
              fax: tmp.fax,
              email: tmp.email,
              principal_name: tmp.principal_name,
            }
          }
        })
    },
    onSchoolInfoChange(schoolData, teachers, promo) {
      this.schoolData = schoolData
      this.teachers = teachers
      this.disagreePromo = promo
    },
    async getTrnId() {
      const result = await this.$axios.get(
        `${process.env.localApiUrl}applications/getTrnId/spaps`
      )
      if (result) {
        this.trnId = result.data.data.trnId
      }
    },
    async onSubmit(event) {
      event.preventDefault()
      const submit = await this.$swal({
        title: this.$t('alert.submit'),
        text: `確定新增 ${this.schoolData.name_tc} 申請紀錄?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: `${this.$t('alert.yes')}`,
        confirmButtonColor: '#28a745', // '#d9534f',
        cancelButtonText: `${this.$t('alert.no')}`,
      })

      if (submit.value) {
        try {
          if (this.data) this.data.applyMethod = this.applyMethod
          const tmp = {
            formId: 'ABOSPAPSFORM',
            schoolId: this.sarId,
            trnId: `${this.trnId}`,
            submitTime: this.$moment(this.submitTime)
              .add(8, 'hours')
              .format('YYYY-MM-DD HH:mm:ss'),
            data: this.getData,
            remarks: '',
            schemeId: this.schemeId,
            additionTeachers: this.teachers
              ? JSON.stringify(this.teachers)
              : [],
            ogcio: this.data ? JSON.stringify(this.data) : '',
          }
          const result = await this.$axios.$post(
            `${process.env.localApiUrl}applications/import/spaps`,
            tmp
          )
          if (result.data) {
            const result2 = await this.$swal({
              title: this.$t('apply.alert_success'),
              html: '成功新增申請',
              icon: 'success',
              customClass: 'swal-wide',
              showCancelButton: false,
              confirmButtonText: this.$t('alert.confirm'),
              confirmButtonColor: '#1cbbb4',
            })
            if (result2.value) {
              const path = this.$router.currentRoute.path
                .replace('add', 'all')
                .replace('/tc', '')
              this.$router.push(this.localePath(path))
            }
          }
          if (result.error !== undefined) throw new Error(result.error)
        } catch (err) {
          // console.log(err)
        }
      }
    },
    async uploadForm(e) {
      try {
        const params = new FormData()
        params.append('form', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.localApiUrl}files/form`,
          params
        )
        if (await result) {
          const data = result.data
          if (data.status === 'ok') {
            this.applyMethod = 'E'
            this.data = data.data
            this.attr = data.data.attr ? data.data.attr : []
            this.trnId = this.trimData(data.data.submission.submit.trnId)
            this.submitTime = this.trimData(
              data.data.submission.submit.submitTime
            )
            const subData = data.data.submission.data
            // console.log(subData)

            this.schoolData.name_tc = this.trimData(subData.schoolNameChi)
            this.schoolData.name_en = this.trimData(subData.schoolNameEng)
            this.schoolData.address_area = this.trimData(subData.address)
            this.schoolData.region = this.trimData(subData.region)
            this.schoolData.district = this.mappingDistrict(
              this.trimData(subData.district)
            )
            this.schoolData.category =
              parseInt(this.trimData(subData.schoolType)) + 1
            this.schoolData.tel = this.trimData(subData.schoolTel)
            this.schoolData.fax = this.trimData(subData.schoolFax)
            this.schoolData.email = this.trimData(subData.schoolEmail)
            this.schoolData.principal_name = `${this.trimData(
              subData.principalName.surname
            )}${this.trimData(subData.principalName.givenName)}`
            this.teachers = []
            if (subData.ticName1) {
              this.teachers.push({
                name: `${this.trimData(
                  subData.ticName1.surname
                )}${this.trimData(subData.ticName1.givenName)}`,
                email: this.trimData(subData.email1),
                tel: this.trimData(subData.schoolMobile1),
              })
            }

            if (subData.ticName2) {
              this.teachers.push({
                name: `${this.trimData(
                  subData.ticName2.surname
                )}${this.trimData(subData.ticName2.givenName)}`,
                email: this.trimData(subData.email2),
                tel: this.trimData(subData.schoolMobile2),
              })
            }

            this.choices = []
            if (subData.choice1)
              this.choices.push({
                id: 1,
                value: this.trimData(subData.choice1),
              })
            if (subData.choice2)
              this.choices.push({
                id: 2,
                value: this.trimData(subData.choice2),
              })
            if (subData.choice3)
              this.choices.push({
                id: 3,
                value: this.trimData(subData.choice3),
              })
            if (subData.choice4)
              this.choices.push({
                id: 4,
                value: this.trimData(subData.choice4),
              })
            if (subData.choice5)
              this.choices.push({
                id: 5,
                value: this.trimData(subData.choice5),
              })
            if (subData.choice6)
              this.choices.push({
                id: 6,
                value: this.trimData(subData.choice6),
              })
            if (subData.choice7)
              this.choices.push({
                id: 7,
                value: this.trimData(subData.choice7),
              })
            if (subData.choice8)
              this.choices.push({
                id: 8,
                value: this.trimData(subData.choice8),
              })
            if (subData.choice9)
              this.choices.push({
                id: 9,
                value: this.trimData(subData.choice9),
              })
            if (subData.choice10)
              this.choices.push({
                id: 10,
                value: this.trimData(subData.choice10),
              })
            if (subData.choice11)
              this.choices.push({
                id: 11,
                value: this.trimData(subData.choice11),
              })
            if (subData.choice12)
              this.choices.push({
                id: 12,
                value: this.trimData(subData.choice12),
              })
            if (subData.choice13)
              this.choices.push({
                id: 13,
                value: this.trimData(subData.choice13),
              })

            this.choiceHint = this.choices
            try {
              this.classes = this.getClass(subData.class)
            } catch (error) {}
            this.classHints = this.trimData(subData.class)
            // this.eventGroup = this.trimData(subData.eventGroup)
            this.workshop.workshopFirstweekday = this.trimData(
              subData.workshopFirstweekday
            )
            this.workshop.workshopFirsttime = this.trimData(
              subData.workshopFirsttime
            )
            this.workshop.workshopSecondweekday = this.trimData(
              subData.workshopSecondweekday
            )
            this.workshop.workshopSecondtime = this.trimData(
              subData.workshopSecondtime
            )
            this.workshop.performFirstdate = this.trimData(
              subData.performFirstdate
            )
            this.workshop.performFirsttime = this.trimData(
              subData.performFirsttime
            )
            this.workshop.performSeconddate = this.trimData(
              subData.performSeconddate
            )
            this.workshop.performSecondtime = this.trimData(
              subData.performSecondtime
            )
            this.workshop.anticipatedClass = this.getClass(
              subData.anticipatedGrade
            )
            this.anticipatedClassHints = subData.anticipatedGrade
            this.workshop.anticipatedNo = parseInt(
              this.trimData(subData.anticipatedNo)
            )
            this.equipments = {
              venue: this._.flatten(subData.venue),
              plan: this.trimData(subData.plan),
              size: this.trimData(subData.size),
              floor: this._.flatten([subData.floor]),
              equipment: this._.flatten(subData.equipment),
              venue2: parseInt(subData.venue2),
              speaker: this.trimData(subData.speaker),
              mic: this.trimData(subData.mic),
              micAmount: this.trimData(subData.micAmount),
            }
            this.sklinfo = {
              ticName1: subData.ticName1,
              ticName2: subData.ticName2,
              schoolMobile1: subData.schoolMobile1,
              schoolMobile2: subData.schoolMobile1,
              email1: subData.email1,
              email2: subData.email2,

              fund: subData.fund,
              otherGroup: subData.otherGroup,
              otherGroupSpecify: subData.otherGroupSpecify,

              otherClass: subData.otherClass,
              otherClassContent: subData.otherGroup,
              otherClassYear: subData.otherClassYear,

              otherTraining: subData.otherTraining,
              otherTrainingType: subData.otherTrainingType,
              otherTrainingName: subData.otherTrainingName,
              otherTrainingYear: subData.otherTrainingYear,

              havePerformance: subData.havePerformance,
              havePerformanceType: subData.havePerformanceType,
              havePerformanceName: subData.havePerformanceName,
              havePerformanceYear: subData.havePerformanceYear,
            }

            if (this.trimData(subData.declare) === 'true') this.declare = true
            if (this.trimData(subData.disagreePromo) === 'true')
              this.disagreePromo = true
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        console.log(err)
        alert(err)
      }
    },
    generateEventlist() {
      const arr = []
      const item = {}
      item.value = -1
      item.text = `${this.$t('apply.select')}`
      arr.push(item)

      this.eventlist.forEach((d) => {
        /* 
        item.value = {
          event: d.id, // eventid
          groupid: index, // if only 1 group: -1. else group index in spapsGroup Array
          group: 'default', // if group name(eg中學入門組) is not specified: 'default', else 'primary' / 'secondary' / 'special'
        }
         */
        const group =
          typeof d.spapsGroup === 'string'
            ? JSON.parse(d.spapsGroup)
            : d.spapsGroup
        if (group) {
          group.forEach((e, index) => {
            let text = `${
              d.organiser ? this.i18nTitle(JSON.parse(d.organiser)) : ''
            }：${this.i18nTitle(d)}`
            // for sub-scheme eg 音樂劇計劃 & 音樂劇歌舞計劃
            if (this.i18nTitle(e)) text = `${text} - ${this.i18nTitle(e)}`
            // for groups eg 中學入門組 & 中學延續組
            if (
              e.grpPrimary.quota === 0 &&
              e.grpSecondary.quota === 0 &&
              e.grpSpecial.quota === 0
            ) {
              // console.log('please input spaps quota')
            } else {
              if (e.grpPrimary.quota > 0) {
                const textGrp =
                  this.i18nTitle(e.grpPrimary) !== ''
                    ? this.i18nTitle(e.grpPrimary)
                    : this.$t('school.primarysklgrp')
                const text2 = `${text} (${textGrp})`
                let item = {}
                item.value = {
                  event: d.id,
                  groupid: index,
                  group: 'primary',
                }
                item.text = text2
                item.quota = e.grpPrimary.quota
                item = this.passField(item, d)
                arr.push(item)
              }
              if (e.grpSecondary.quota > 0) {
                const textGrp =
                  this.i18nTitle(e.grpSecondary) !== ''
                    ? this.i18nTitle(e.grpSecondary)
                    : this.$t('school.secondarysklgrp')
                const text2 = `${text} (${textGrp})`
                let item = {}
                item.value = {
                  event: d.id,
                  groupid: index,
                  group: 'secondary',
                }
                item.text = text2
                item.quota = e.grpSecondary.quota
                item = this.passField(item, d)
                arr.push(item)
              }
              if (e.grpSpecial.quota > 0) {
                const textGrp =
                  this.i18nTitle(e.grpSpecial) !== ''
                    ? this.i18nTitle(e.grpSpecial)
                    : this.$t('school.specsklgrp')
                const text2 = `${text} (${textGrp})`
                let item = {}
                item.value = {
                  event: d.id,
                  groupid: index,
                  group: 'special',
                }
                item.text = text2
                item.quota = e.grpSpecial.quota
                item = this.passField(item, d)
                arr.push(item)
              }
            }
          })
        } else {
          let item = {}
          item.value = {
            event: d.id,
            groupid: -1,
            group: 'default',
          }
          item.text = `${
            d.organiser ? this.i18nTitle(JSON.parse(d.organiser)) : ''
          }：${this.i18nTitle(d)}`
          item = this.passField(item, d)
          arr.push(item)
        }
        this.eventlist = [...arr]
      })
    },
    passField(item, d) {
      item.id = d.id
      item.title_en = d.title_en
      item.title_tc = d.title_tc
      item.title_sc = d.title_sc
      item.organiser = d.organiser
      item.spapsGroup = d.spapsGroup
      return item
    },
    getTab1Input(
      choiceArray,
      classes,
      eventGroup,
      workshop,
      passValidationTab1
    ) {
      this.choiceArray = choiceArray
      this.classes = classes
      this.eventGroup = eventGroup
      this.workshop = workshop
      // this.passValidationTab1 = passValidationTab1
    },
    getTab2Input(equipments, passValidationTab2) {
      this.equipments = equipments
      // this.passValidationTab2 = passValidationTab2
    },
    getTab3Input(sklinfo, passValidationTab3) {
      this.sklinfo = sklinfo
      // this.passValidationTab3 = passValidationTab3
    },
    submitTimeState(input) {
      const regex = new RegExp(
        '^([0-9]{4})-[0-2][0-9]-[0-3][0-9] [0-2][0-9]:[0-5][0-9]:[0-5][0-9]$'
      )
      return regex.test(input)
    },
    addTeacher() {
      this.teachers.push({})
    },
    delTeacher(index) {
      this.teachers.splice(index, 1)
    },
    trimData(field) {
      return field ? field.toString().trim().replaceAll('：', ':') : ''
    },
    getClass(inclass) {
      const classString = inclass
        .toString()
        .toLowerCase()
        .trim()
        .replaceAll('.', '')
      if (classString.length < 1) return
      const classNum = []
      let headStr = classString[0]
      classNum.push(this.targetMap[parseInt(headStr) + 2])
      for (let i = 1; i < classString.length; i += 2) {
        if (classString[i] === '-') {
          for (
            let j = parseInt(headStr) + 1;
            j <= parseInt(classString[i + 1]);
            j++
          ) {
            classNum.push(this.targetMap[j + 2])
          }
        } else {
          classNum.push(this.targetMap[parseInt(headStr) + 2])
          headStr = classString[i + 1]
          if (i + 1 === classString.length - 1) {
            classNum.push(this.targetMap[parseInt(headStr) + 2])
          }
        }
      }
      return this._.filter(classNum)
    },
    intToGrade(i) {
      let result = ''
      switch (this.$i18n.locale) {
        case 'en':
          result =
            i < 1
              ? 'K.'.concat((i + 3).toString())
              : i < 7
              ? 'P.'.concat(i.toString())
              : 'S.'.concat((i - 6).toString())
          break
        case 'tc':
          result =
            i === -2
              ? '幼兒班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        case 'sc':
          result =
            i === -2
              ? '幼儿班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.match-db {
  color: grey;
}
.resultskl {
  color: grey;
  background-color: white;
  padding: 5px 20px;
  line-height: 2.5;
}
.not_selected {
  color: #f76576;
  background-color: white;
  padding: 5px 20px;
}
fieldset.topLeftInsideLegend legend {
  float: left;
  width: auto;
  display: block;
  margin: 0 100% 10px 0;
  padding: 0 10px;
  background: #f5f5f5;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 5px 0;
  font-weight: 700;
  font-size: 12px;
  color: #1cbcb4;
  white-space: normal;
}
fieldset.topLeftInsideLegend {
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 0;
  margin-bottom: 10px;
}
fieldset.topLeftInsideLegend > div {
  padding: 0 20px;
}
.layout-inline {
  display: inline-block;
  margin-right: 50px;
}
.shadow-important {
  padding: 10px 30px;
  margin: 30px 0;
  border: 2px solid #fc7137;
}
.shadow {
  padding: 10px 30px;
  margin: 30px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.component {
  text-align: left;
}
</style>
