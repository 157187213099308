var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.data, function (item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("hr"),
            _vm._v(" "),
            _vm.type === "soundFactory"
              ? _c("h3", [_vm._v("音頻 " + _vm._s(index + 1))])
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "history" ||
            _vm.type === "interview" ||
            _vm.type === "feedback"
              ? _c("h3", [
                  _vm._v("\n      分段內容 " + _vm._s(index + 1) + "\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            !!item.title_tc || item.title_tc === ""
              ? _c(
                  "div",
                  [
                    _vm._v(
                      "\n      名稱(" +
                        _vm._s(
                          _vm.lang === 0 ? "英" : _vm.lang === 1 ? "繁" : "簡"
                        ) +
                        ")\n      "
                    ),
                    _vm.lang === 0
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.title_en,
                            callback: function ($$v) {
                              _vm.$set(item, "title_en", $$v)
                            },
                            expression: "item.title_en",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 1
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.title_tc,
                            callback: function ($$v) {
                              _vm.$set(item, "title_tc", $$v)
                            },
                            expression: "item.title_tc",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 2
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.title_sc,
                            callback: function ($$v) {
                              _vm.$set(item, "title_sc", $$v)
                            },
                            expression: "item.title_sc",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.guest_tc || item.guest_tc === ""
              ? _c(
                  "div",
                  [
                    _vm._v(
                      "\n      嘉賓(" +
                        _vm._s(
                          _vm.lang === 0 ? "英" : _vm.lang === 1 ? "繁" : "簡"
                        ) +
                        ")\n      "
                    ),
                    _vm.lang === 0
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.guest_en,
                            callback: function ($$v) {
                              _vm.$set(item, "guest_en", $$v)
                            },
                            expression: "item.guest_en",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 1
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.guest_tc,
                            callback: function ($$v) {
                              _vm.$set(item, "guest_tc", $$v)
                            },
                            expression: "item.guest_tc",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 2
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.guest_sc,
                            callback: function ($$v) {
                              _vm.$set(item, "guest_sc", $$v)
                            },
                            expression: "item.guest_sc",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.topic_tc || item.topic_tc === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v(
                      "\n      題目(" +
                        _vm._s(
                          _vm.lang === 0 ? "英" : _vm.lang === 1 ? "繁" : "簡"
                        ) +
                        ")\n      "
                    ),
                    _vm.lang === 0
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.topic_en,
                            callback: function ($$v) {
                              _vm.$set(item, "topic_en", $$v)
                            },
                            expression: "item.topic_en",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 1
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.topic_tc,
                            callback: function ($$v) {
                              _vm.$set(item, "topic_tc", $$v)
                            },
                            expression: "item.topic_tc",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 2
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.topic_sc,
                            callback: function ($$v) {
                              _vm.$set(item, "topic_sc", $$v)
                            },
                            expression: "item.topic_sc",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.student_tc || item.student_tc === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v(
                      "\n      學生(" +
                        _vm._s(
                          _vm.lang === 0 ? "英" : _vm.lang === 1 ? "繁" : "簡"
                        ) +
                        ")\n      "
                    ),
                    _vm.lang === 0
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.student_en,
                            callback: function ($$v) {
                              _vm.$set(item, "student_en", $$v)
                            },
                            expression: "item.student_en",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 1
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.student_tc,
                            callback: function ($$v) {
                              _vm.$set(item, "student_tc", $$v)
                            },
                            expression: "item.student_tc",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 2
                      ? _c("b-form-input", {
                          attrs: { type: "text", trim: "" },
                          model: {
                            value: item.student_sc,
                            callback: function ($$v) {
                              _vm.$set(item, "student_sc", $$v)
                            },
                            expression: "item.student_sc",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.content_tc || item.content_tc === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v(
                      "\n      內容(" +
                        _vm._s(
                          _vm.lang === 0 ? "英" : _vm.lang === 1 ? "繁" : "簡"
                        ) +
                        ")\n      "
                    ),
                    _vm.lang === 0
                      ? _c("b-form-textarea", {
                          attrs: { rows: "3", "max-rows": "6" },
                          model: {
                            value: item.content_en,
                            callback: function ($$v) {
                              _vm.$set(item, "content_en", $$v)
                            },
                            expression: "item.content_en",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 1
                      ? _c("b-form-textarea", {
                          attrs: { rows: "3", "max-rows": "6" },
                          model: {
                            value: item.content_tc,
                            callback: function ($$v) {
                              _vm.$set(item, "content_tc", $$v)
                            },
                            expression: "item.content_tc",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 2
                      ? _c("b-form-textarea", {
                          attrs: { rows: "3", "max-rows": "6" },
                          model: {
                            value: item.content_sc,
                            callback: function ($$v) {
                              _vm.$set(item, "content_sc", $$v)
                            },
                            expression: "item.content_sc",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.audio || item.audio === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v("\n      Audio\n      "),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: item.audio,
                        callback: function ($$v) {
                          _vm.$set(item, "audio", $$v)
                        },
                        expression: "item.audio",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-form-file", {
                      attrs: { accept: "audio/*" },
                      on: { change: (e) => _vm.uploadAudio(e, index) },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.main_audio || item.main_audio === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v("\n      Main Audio\n      "),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: item.main_audio,
                        callback: function ($$v) {
                          _vm.$set(item, "main_audio", $$v)
                        },
                        expression: "item.main_audio",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-form-file", {
                      attrs: { accept: "audio/*" },
                      on: { change: (e) => _vm.uploadAudio(e) },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.img || item.img === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v("\n      圖片\n      "),
                    _c("b-form-input", {
                      attrs: { type: "text" },
                      model: {
                        value: item.img,
                        callback: function ($$v) {
                          _vm.$set(item, "img", $$v)
                        },
                        expression: "item.img",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-form-file", {
                      attrs: { accept: "image/jpeg, image/png, image/gif" },
                      on: { change: (e) => _vm.uploadImage(e, index) },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: { "max-height": "200px" },
                      attrs: { src: item.img, alt: "icon item" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.color || item.color === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v("\n      背景顏色\n      "),
                    _c("b-form-input", {
                      attrs: { type: "text", trim: "" },
                      model: {
                        value: item.color,
                        callback: function ($$v) {
                          _vm.$set(item, "color", $$v)
                        },
                        expression: "item.color",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !!item.order || item.order === ""
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _vm._v("\n      次序\n      "),
                    _c("b-form-input", {
                      attrs: { type: "text", trim: "" },
                      model: {
                        value: item.order,
                        callback: function ($$v) {
                          _vm.$set(item, "order", $$v)
                        },
                        expression: "item.order",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "soundFactory"
              ? _c(
                  "b-button",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "outline-danger" },
                    on: {
                      click: function ($event) {
                        return _vm.delItem(index)
                      },
                    },
                  },
                  [_vm._v("\n      刪除 音頻 " + _vm._s(index + 1) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "history" ||
            _vm.type === "interview" ||
            _vm.type === "feedback"
              ? _c(
                  "b-button",
                  {
                    staticClass: "mt-3",
                    attrs: { variant: "outline-danger" },
                    on: {
                      click: function ($event) {
                        return _vm.delItem(index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n      刪除 分段內容 " + _vm._s(index + 1) + "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.type === "soundFactory"
        ? _c(
            "b-button",
            {
              attrs: { variant: "outline-success" },
              on: {
                click: function ($event) {
                  return _vm.addItem()
                },
              },
            },
            [_vm._v("\n    添加 音頻\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "history" ||
      _vm.type === "interview" ||
      _vm.type === "feedback"
        ? _c(
            "b-button",
            {
              attrs: { variant: "outline-success" },
              on: {
                click: function ($event) {
                  return _vm.addItem()
                },
              },
            },
            [_vm._v("\n    添加 分段內容\n  ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }