<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="6">
      <div class="newsForm">
        <b-form>
          <b-form-group
            id="created_at"
            label-for="created_at"
            :label="$t('change_request.date')"
          >
            <b-form-input
              id="created_at"
              v-model="created_at"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="school"
            label-for="school"
            :label="$t('change_request.school')"
          >
            <b-form-input
              id="school"
              v-model="school"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="userId"
            label-for="userId"
            :label="$t('change_request.username')"
          >
            <b-form-input
              id="userId"
              v-model="userId"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="trnId"
            label-for="trnId"
            :label="$t('change_request.trnId')"
          >
            <b-form-input
              id="trnId"
              v-model="trnId"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="formId"
            label-for="formId"
            :label="$t('feedback.scheme')"
          >
            <b-form-input
              id="formId"
              v-model="formId"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="eventCode"
            label-for="eventCode"
            :label="$t('change_request.eventCode')"
          >
            <b-form-input
              id="eventCode"
              v-model="eventCode"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="activityName"
            label-for="activityName"
            :label="$t('event.name')"
          >
            <b-form-input
              id="activityName"
              v-model="activityName"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="eventSlot"
            label-for="eventSlot"
            :label="$t('change_request.eventSlot')"
          >
            <b-form-input
              id="eventSlot"
              v-model="eventSlot"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="changeSection"
            label-for="changeSection"
            :label="$t('change_request.changeSection')"
          >
            <b-form-input
              id="changeSection"
              v-model="changeSection"
              type="text"
              readonly
            ></b-form-input>
          </b-form-group>

          <b-form-group id="changeContent" label-for="changeContent">
            <b-tabs content-class="mt-3">
              <b-tab :title="$t('change_request.detail')">
                <b-textarea
                  v-model="changeContent"
                  no-resize
                  rows="10"
                  readonly
                  style="background-color: white"
                ></b-textarea>
              </b-tab>
            </b-tabs>
          </b-form-group>

          <b-form-group label="ABO備註">
            <b-textarea
              v-model="remarks"
              rows="2"
              max-rows="3"
              style="background-color: white"
            ></b-textarea>
          </b-form-group>

          <Notification v-if="error" :message="error" />
          <div class="submit">
            <b-form-checkbox
              id="request_checked"
              v-model="status"
              name="request_checked"
              value="1"
              unchecked-value="0"
              :disabled="checkValid"
            >
              已處理 (獲接納)
              <!-- 已處理（已處理項目將在列表隱藏） -->
            </b-form-checkbox>
            <b-form-checkbox
              id="request_checked2"
              v-model="status"
              name="request_checked2"
              value="2"
              unchecked-value="0"
              :disabled="checkValid2"
            >
              已處理 (不獲接納)
            </b-form-checkbox>
            <br />
            <b-button variant="outline-success" @click="$router.go(-1)">{{
              $t('apply.back')
            }}</b-button>
            <b-button
              variant="outline-primary"
              style="margin-left: 10px"
              @click="submit_update()"
              >{{ $t('apply.submit') }}</b-button
            >
            <!-- <b-button
              style="margin: 0px 10px"
              variant="outline-secondary"
              disabled
              >{{ '發送已處理電郵' }}</b-button
            > -->
          </div>
        </b-form>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'

export default {
  components: {
    Notification,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      // TODO: map user from cloud to local
      const result = await this.$axios.get(`${process.env.localApiUrl}users`)
      this.users = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      const result = await this.$axios.get(`${process.env.localApiUrl}schools`)
      this.schools = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/public/scheme/name`
      )
      this.schemeAll = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}applications/admin/${this.$route.query.trnid}`
      )
      this.application = result.data.data
    } catch (err) {
      console.log(err)
    }
    try {
      // console.log(this.action)
      if (this.action === 'view') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}changeRequest/${this.$route.query.id}`
        )
        const data = result.data.data[0]
        console.log('testing', data)
        this.id = data.id
        this.trnId = data.trnId + '(' + this.id + ')'
        this.formId = this.getScheme(data)
        this.userId = this.getUser(data.userId)
        this.school = this.getSchool(data.userId)
        this.eventCode = data.eventCode ? data.eventCode : ' – '
        this.activityName = this.getActivityName()
        this.eventSlot = this.getSlot()
        this.changeSection = this.getSection(data.changeSection, data.formId)
        // this.changeContent = data.changeContent
        this.changeContent = this.contentDisplay(
          data.changeSection,
          data.changeContent,
          data.formId
        )
        this.status = data.status
        this.remarks = data.remarks
        this.created_at = this.$moment(data.created_at).format(
          'yyyy.MM.DD HH:mm'
        )
      }
    } catch (err) {
      console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      id: null,
      userId: null,
      school: '',
      formId: null,
      trnId: null,
      eventCode: '',
      activityName: '',
      eventSlot: '',
      changeContent: '',
      changeSection: '',
      created_at: '',
      error: null,
      status: 0,
      remarks: '',
    }
  },
  computed: {
    checkValid() {
      if (this.status === 2) {
        return true
      } else return false
    },
    checkValid2() {
      if (this.status === 1) {
        return true
      } else return false
    },
  },
  methods: {
    getUser(userid) {
      // console.log(userid)
      const id = parseInt(userid)
      if (this.users) {
        return this.users.find((c) => c.id === id)
          ? this.users.find((c) => c.id === id).name
          : id
      }
    },
    getScheme() {
      const schemeId = this.application[0].schemeId
      if (this.schemeAll) {
        return this.schemeAll.find((s) => s.id === schemeId)
          ? this.schemeAll.find((s) => s.id === schemeId).title_tc
          : schemeId
      }
    },
    getSchool(userid) {
      const id = parseInt(userid)
      let schoolid = ''
      let schoolnameTc = ''
      let schoolnameEn = ''
      if (this.users) {
        schoolid = this.users.find((c) => c.id === id)
          ? this.users.find((c) => c.id === id).school
          : ''
      }
      if (this.schools) {
        const found = this.schools.find((s) => s.id === schoolid)
        schoolnameTc = found.name_tc ? found.name_tc : null
        schoolnameEn = found.name_en ? found.name_en : null
      }
      if (schoolnameTc) {
        return schoolnameTc + ' (' + schoolid + ')'
      } else {
        return schoolnameEn + ' (' + schoolid + ')'
      }
    },
    getActivityName() {
      const activityName = this.application[0].activityName
      if (activityName) {
        console.log('activityName', activityName)
        return activityName
      }
      console.error('No activity name.')
      return null
    },
    getSlot() {
      const app = this.application[0]
      // console.log(app)
      let eventSlot = '獲選：場次'
      if (app.result_event_slot) {
        // result choice === 1
        if (app.result_choice === 1) {
          eventSlot +=
            app.event_slot_1 +
            '｜' +
            this.$moment(app.firstChoiceDate).format('YYYY.MM.DD')
          if (app.firstChoiceTime) {
            eventSlot += '｜' + app.firstChoiceTime
          }
        }
        // result choice === 2
        if (app.result_choice === 2) {
          eventSlot +=
            app.event_slot_2 +
            '｜' +
            this.$moment(app.secondChoiceDate).format('YYYY.MM.DD')
          if (app.secondChoiceTime) {
            eventSlot += '｜' + app.secondChoiceTime
          }
        }
      } else {
        if (app.formId.includes('SCDS')) {
          eventSlot = '沒有獲選場次'
        } else {
          eventSlot = '沒有場次'
        }
        console.log('no result event slot')
      }
      return eventSlot
    },
    // let eventslot = ' – '
    // let slot1 = '首選：'
    // let slot2 = '次選：'
    // if (app.event_slot_1) {
    //   slot1 += '場次' + app.event_slot_1
    //   if (app.firstChoiceDate) {
    //     slot1 += '｜' + this.$moment(app.firstChoiceDate).format('YYYY.MM.DD')
    //   }
    //   if (app.firstChoiceTime) {
    //     slot1 += '｜' + app.firstChoiceTime
    //   }
    //   eventslot = slot1
    // }
    // if (app.event_slot_2) {
    //   slot2 += '場次' + app.event_slot_2
    //   if (app.secondChoiceDate) {
    //     slot2 +=
    //       '｜' + this.$moment(app.secondChoiceDate).format('YYYY.MM.DD')
    //   }
    //   if (app.secondChoiceTime) {
    //     slot2 += '｜' + app.secondChoiceTime
    //   }
    //   eventslot += '\n' + slot2
    // }
    // return eventslot

    getSection(section, formid) {
      // console.log(section)
      let secArr = ''
      // if (section.includes('eventslot')) {
      //   secArr += this.$t('change_request.section.eventslot') + ', '
      // }
      if (section.includes('class')) {
        secArr += this.$t('change_request.section.class') + ', '
      }
      if (formid.includes('SPAPS') && section.includes('number')) {
        secArr += this.$t('change_request.section.anti_number') + ', '
      }
      if (section.includes('number')) {
        if (section.includes('studentNo')) {
          secArr += this.$t('change_request.section.studentNo') + ', '
        }
        if (formid.includes('SCDS') && section.includes('parentNo')) {
          secArr += this.$t('change_request.section.parentNo_short') + ', '
        } else if (formid.includes('AES') && section.includes('parentNo')) {
          secArr += this.$t('change_request.section.cssano_short') + ', '
        }
        if (section.includes('teacherNo')) {
          secArr += this.$t('change_request.section.teacherNo') + ', '
        }
      }
      if (section.includes('add_teacher')) {
        secArr += this.$t('change_request.section.add_teacher') + ', '
      }
      if (section.includes('add_lead_teacher')) {
        secArr += this.$t('change_request.section.add_lead_teacher') + ', '
      }
      if (section.includes('other')) {
        secArr += this.$t('change_request.section.other_short') + ', '
      }
      if (section?.includes('cancel') && !section?.includes('cancel_section')) {
        secArr += this.$t('change_request.section.cancel') + ', '
      }
      if (section?.includes('cancel') && section?.includes('cancel_section')) {
        secArr += this.$t('change_request.section.cancel_section') + ', '
      }
      if (section.includes('noEdit')) {
        secArr += this.$t('change_request.section.noEdit') + ', '
      }
      return secArr.slice(0, -2)
    },
    contentDisplay(section, content, formid) {
      let display = ''
      if (formid.includes('SCDS') && section.includes('class')) {
        display += '更改學生班級為：'
        // if (JSON.parse(content).class.resultslot.length > 0) {
        //   display += '獲選場次：'
        //   display += JSON.parse(content).class.resultslot
        //   display += '\n'
        // }
        if (JSON.parse(content).class.firstslot.length > 0) {
          // display += '首選場次：'
          display += JSON.parse(content).class.firstslot
          display += '\n'
        }
        // if (JSON.parse(content).class.secondslot.length > 0) {
        //   display += '次選場次：'
        //   display += JSON.parse(content).class.secondslot
        //   display += '\n'
        // }
        display += '\n'
      }
      if (formid.includes('SCDS') && section.includes('class')) {
        display += '更改學生班級為：'
        display += JSON.parse(content).class.firstslot
        display += '\n\n'
      }
      if (formid.includes('SPAPS') && section.includes('number')) {
        display += '更改預計出席人數為：'
        display += JSON.parse(content).studentNo
        display += '\n'
      }
      if (section.includes('number')) {
        console.log('aes parent', content)
        console.log(formid, section, section.includes('parentNo'))
        if (section.includes('studentNo')) {
          display += '更改學生人數為：'
          display += JSON.parse(content).studentNo
          display += '\n'
        }
        if (formid.includes('SCDS') && section.includes('parentNo')) {
          display += '更改家長人數為：'
          display += JSON.parse(content).parentNo
          display += '\n'
        } else if (formid.includes('AES') && section.includes('parentNo')) {
          display += '更改學生人數（綜合社會保障援助受惠人士）為：'
          display += JSON.parse(content).parentNo
          display += '\n'
        }
        if (section.includes('teacherNo')) {
          display += '更改老師人數為：'
          display += JSON.parse(content).teacherNo
          display += '\n'
        }
        display += '\n'
      }
      if (section.includes('add_teacher')) {
        display += this.$t('change_request.section.add_teacher') + '：\n'
        display +=
          this.$t('change_request.add_teacher.name') +
          JSON.parse(content).add_teacher.name +
          '\n'
        display +=
          this.$t('change_request.add_teacher.email') +
          JSON.parse(content).add_teacher.email +
          '\n'
        display +=
          this.$t('change_request.add_teacher.tel') +
          JSON.parse(content).add_teacher.tel +
          '\n'
        display += '\n'
      }
      if (section.includes('add_lead_teacher')) {
        display += this.$t('change_request.section.add_lead_teacher') + '：\n'
        display +=
          this.$t('change_request.add_teacher.name') +
          JSON.parse(content).add_lead_teacher.name +
          '\n'
        display +=
          this.$t('change_request.add_teacher.tel') +
          JSON.parse(content).add_lead_teacher.tel +
          '\n'
        display += '\n'
      }
      if (section.includes('other')) {
        display += '其他：'
        display += JSON.parse(content).other
        display += '\n\n'
      }
      if (section.includes('cancel') && section.includes('cancel_section')) {
        display += this.$t('change_request.section.cancel_section')
        display += ':'
        if (JSON.parse(content).cancel_section.includes('first')) {
          display += this.$t('change_request.section.cancel_section_first')
        }
        if (JSON.parse(content).cancel_section.length > 1) {
          display += ','
        }
        if (JSON.parse(content).cancel_section.includes('second')) {
          display += this.$t('change_request.section.cancel_section_second')
        }
      }
      if (section.includes('cancel') && !section.includes('cancel_section')) {
        display += '取消原因：'
        display += this.getReason(JSON.parse(content).cancel.reason)
        if (JSON.parse(content).cancel.reason === '4') {
          display += ' – '
          display += JSON.parse(content).cancel.specify
        }
        display += '\n\n'
      }
      if (section.includes('noEdit')) {
        display += '沒有任何修改。'
      }
      return display
    },
    getReason(value) {
      switch (value) {
        case '1':
          return this.$t('change_request.aes.cancel_option1')
        case '2':
          return this.$t('change_request.aes.cancel_option2')
        case '3':
          return this.$t('change_request.aes.cancel_option3')
        case '4':
          return this.$t('change_request.section.other_short')
      }
    },
    async submit_update() {
      //   console.log('status: ', this.status)
      //   console.log('id: ', this.id)
      try {
        const obj = {
          remarks: this.remarks,
          status: this.status,
          id: this.id,
        }
        // console.log('remarks: ', this.remarks)
        // console.log('status: ', this.status)
        // console.log('id: ', this.id)
        const result = await this.$axios.$post(
          `${process.env.cloudApiUrl}changeRequest/update`,
          obj
        )
        if (result) {
          // console.log(result)
          const back = await this.$swal({
            title: this.$t('alert.title'),
            text: '已處理此申請',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.$t('alert.back'),
            confirmButtonColor: '#d9534f',
            cancelButtonText: 'OK',
          })
          // console.log('back: ', back.value)
          if (back.value) {
            this.$router.go(-1)
          } else {
            this.$router.go()
          }
        }
      } catch (err) {
        this.error = this.$t('error.' + err.response.data.error)
      }
      console.log('submitted')
    },
  },
}
</script>
<style lang="scss" scoped>
p.label {
  margin-top: 16px;
}
h1::first-letter {
  text-transform: capitalize;
}
.content_textarea {
  height: 200px;
}
.component {
  text-align: left;
}
.submit {
  margin-bottom: 20px;
}
.form-group {
  margin-top: 5px;
}
</style>
