var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    [
      _c(
        "b-row",
        { staticClass: "align-middle" },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("b-row", [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c(
                        "nuxt-link",
                        { attrs: { to: _vm.localePath("/notice") } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.i18nName(_vm.noticeData)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", [_vm._v("|")]),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.lcsd.gov.hk/tc/info_pdo.html",
                          target: "_blank",
                          title: "私隱政策",
                        },
                      },
                      [_vm._v(_vm._s(_vm.getTxt("privacy")))]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("b-row", [
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      _vm._s(_vm.getTxt("lastmodified")) +
                        " " +
                        _vm._s(_vm.lastmodified)
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "6" } }),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "2" } }, [
            _c(
              "div",
              {
                staticStyle: { "text-align": "right", "padding-right": "18px" },
                attrs: { id: "wcag2aa" },
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      id: "footerLogoAA",
                      href: "https://www.w3.org/WAI/WCAG2AA-Conformance",
                      target: "_blank",
                      title:
                        "符合萬維網聯盟有關無障礙網頁設計指引中2A級別的要求",
                      rel: "external",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { "max-width": "100px" },
                      attrs: {
                        height: "24",
                        src: "https://www.w3.org/WAI/wcag21/wcag2.1AA-v.png",
                        alt: "符合萬維網聯盟有關無障礙網頁設計指引中2A級別的要求",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }