<template>
  <!-- {{ data }} -->
  <div style="margin-bottom: 25px">
    <table class="additional_row">
      <tbody>
        <tr v-for="(row, index) in data" :key="index">
          <td
            v-for="(item, i) in row"
            :key="i"
            :colspan="item.colspan"
            :style="edit ? 'padding: 5px 0 0 8px' : i === 0 ? tdStyles : ''"
          >
            <div v-if="!edit & (lang === 0)" v-html="item.content_en"></div>
            <div v-if="!edit & (lang === 1)" v-html="item.content_tc"></div>
            <div v-if="!edit & (lang === 2)" v-html="item.content_sc"></div>

            <!-- <b-form-input
              v-if="edit & (lang === 0)"
              v-model="item.content_en"
              type="text"
              trim
              :class="i === 0 ? 'first_td' : 'other_td'"
              @input="inputChanged"
            ></b-form-input>
            <b-form-input
              v-if="edit & (lang === 1)"
              v-model="item.content_tc"
              type="text"
              trim
              :class="i === 0 ? 'first_td' : 'other_td'"
              @input="inputChanged"
            ></b-form-input>
            <b-form-input
              v-if="edit & (lang === 2)"
              v-model="item.content_sc"
              type="text"
              trim
              :class="i === 0 ? 'first_td' : 'other_td'"
              @input="inputChanged"
            ></b-form-input> -->
            <medium-editor
              v-if="edit & (lang === 0)"
              :text="item.content_en"
              :options="options"
              @edit="
                (operation) => {
                  item.content_en = operation.api.origElements.innerHTML
                  inputChanged()
                }
              "
            >
            </medium-editor>
            <medium-editor
              v-if="edit & (lang === 1)"
              :text="item.content_tc"
              :options="options"
              @edit="
                (operation) => {
                  item.content_tc = operation.api.origElements.innerHTML
                  inputChanged()
                }
              "
            >
            </medium-editor>
            <medium-editor
              v-if="edit & (lang === 2)"
              :text="item.content_sc"
              :options="options"
              @edit="
                (operation) => {
                  item.content_sc = operation.api.origElements.innerHTML
                  inputChanged()
                }
              "
            >
            </medium-editor>
          </td>
        </tr>
        <tr></tr>
      </tbody>
    </table>
    <div v-if="edit">
      <b-button variant="outline-success" @click="addAdditionalEvent">
        添加演前/演後活動
      </b-button>
      <b-button variant="outline-success" @click="addRow">添加列</b-button>
      <b-button variant="outline-danger" @click="delRow">
        刪除列（只限新增列）
      </b-button>
      <p style="color: red; font-size: 80%">
        <br />*系統根據活動場次資料自動產生：「日期」、「地點」、「時間」、「節目需時」、「對象」、「名額」
        <br />*若沒有場次包含票價，則不會顯示「票價」列
      </p>
    </div>
  </div>
</template>
<script>
import RedBtn from '~/components/event/redBtn'
export default {
  props: {
    implTableData: {
      type: Array,
      default: () => [],
    },
    lang: { type: Number, default: 0 },
    edit: { type: Boolean, default: false },
    haveEventSlot: { type: Boolean, default: false },
    firstTdWidth: { type: Number, default: 110 },
  },
  data() {
    return {
      options: {
        toolbar: {
          buttons: [
            'red',
            'bold',
            'italic',
            'strikethrough',
            'unorderedlist',
            'orderedlist',
          ],
        },
        extensions: {
          /* red: new RedBtn({
            buttonText: '<hr>',
            htmlToInsert: "<hr class='someclass'>",
          }), */
          red: new RedBtn.P1(),
        },
        placeholder: {
          text: 'Text',
        },
      },
      tdStyles: {},
      data: this.implTableData.map((a) => [...a]),
      noOfCol: 2, // number of column minus header(1st col)
    }
  },
  computed: {},
  /* watch: {
    data: {
      handler(newVal, oldVal) {
        this.$emit('change', newVal)
      },
      deep: true,
    },
    lang: {
      handler(newVal, oldVal) {},
      deep: true,
    },
  }, */
  watch: {
    implTableData: {
      handler(newVal, oldVal) {
        this.data = newVal.map((a) => [...a])
        // this.noOfCol = newVal.length ? newVal[0].length - 1 : 1
      },
      deep: true,
    },
    firstTdWidth: {
      handler(newVal, oldVal) {
        this.setWidth()
      },
      deep: true,
    },
  },
  mounted() {
    this.setWidth()
  },
  methods: {
    setWidth() {
      // match width of eventTable th
      this.$set(this.tdStyles, 'width', `${this.firstTdWidth}px`)
      // console.log(this.tdStyles)
    },
    inputChanged() {
      this.$parent.$emit('input', this.data)
    },
    getContent(item) {
      switch (this.lang) {
        case 0:
          return item.content_en
        case 1:
          return item.content_tc
        case 2:
          return item.content_sc
        default:
          return ''
      }
    },
    span(value, index) {
      this.data[index][1].colspan = this.noOfCol
      // only keep the 1st & 2nd cell
      if ((value === '1') & (this.data[index].length > 2)) {
        // value = 1 : span checked
        this.data[index] = this.data[index].slice(0, 2)
      } else if ((value === '0') & (this.data[index].length === 2)) {
        // value = 0 : span unchecked
        this.data[index][1].colspan = 1
        for (let i = 0; i < this.noOfCol - 1; i++) {
          this.data[index].push({
            content_en: '',
            content_tc: '',
            content_sc: '',
            colspan: 1,
          })
        }
      }
      this.inputChanged()
    },
    addCol() {
      this.data.forEach((d) => {
        if (d.length > 1) {
          if (d[1].colspan === 1)
            // normal row: add new cell
            d.push({
              content_en: '',
              content_tc: '',
              content_sc: '',
              colspan: 1,
            })
          else d[1].colspan += 1
          // if original cell has span checked (colspan > 1), colspan + 1
        }
      })
      this.noOfCol += 1
      /* // console.log(typeof this.data, this.data)
      // console.log(typeof this.data[0], this.data[0]) */
      this.inputChanged()
    },
    delCol() {
      this.data.forEach((d) => {
        if (d.length > 2) d.pop()
        else d[1].colspan -= 1
      })
      // console.log(this.data)
      this.noOfCol -= 1
      this.inputChanged()
    },
    addRow() {
      const col = { content_en: '', content_tc: '', content_sc: '', colspan: 1 }
      const row = []
      for (let i = 0; i < this.noOfCol; i++) {
        // need to deep copy col
        row.push(JSON.parse(JSON.stringify(col)))
      }
      this.data.push(row)
      this.inputChanged()
    },
    delRow() {
      this.data.pop()
      this.inputChanged()
    },
    addAdditionalEvent() {
      this.data.push([
        { content_en: '', content_tc: '', content_sc: '', colspan: 2 },
      ])
      const row = [
        {
          content_en: '',
          content_tc: '日期',
          content_sc: '',
          colspan: 1,
        },
        {
          content_en: '',
          content_tc: '',
          content_sc: '',
          colspan: 1,
        },
      ]
      this.data.push(row)
      this.inputChanged()
    },
    i18nText(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.en
        case 'tc':
          return item.tc
        case 'sc':
          return item.sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.first_td {
  background-color: #ffe086;
  border-radius: 0;
  border: none;
  color: black;
  font-weight: bold;
  // text-align: center;
  padding: 0;
}
.other_td {
  background-color: #ffffde;
  border-radius: 0;
  border: none;
  color: black;
  padding: 0;
}
.additional_row {
  // border: 1px solid lightgrey;
  width: 100%;
  margin-bottom: 25px;
  margin-top: -1px;
  // margin: 4px auto 25px auto;
  // font-size: 0.8em;
}
.additional_row > tbody > tr:nth-child(1) > td {
  // border-top: none;
}
.additional_row > tbody > tr > td:nth-child(1) {
  background-color: #ffe086; // #f5ddd4;
  text-align: center;
  width: 180px;
  font-weight: bold;
  padding: 8px;
}
.additional_row > tbody > tr > td {
  background-color: #ffffde; // #f5ddd4;
  height: 2.3em;
  border: 1px solid lightgrey;
  min-width: 180px;
  padding: 8px;
  text-align: left;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .additional_row > tbody > tr > td:nth-child(1) {
    width: auto;
  }

  .additional_row > tbody > tr > td {
    min-width: unset;
  }
}

@media print {
  .additional_row > tbody > tr > td:nth-child(1) {
    background-color: #ffe086; // #f5ddd4;
    text-align: center;
    width: 30% !important;
    font-weight: bold;
    padding: 8px;
  }
  .additional_row > tbody > tr > td {
    background-color: #ffffde; // #f5ddd4;
    height: 2.3em;
    border: 1px solid lightgrey;
    padding: 8px;
    text-align: left;
  }
}
</style>
<style lang="scss">
.additional_row > tbody > tr > td > div > * {
  margin-bottom: 0;
}
.red,
.txt-red {
  color: #fe3855;
}
</style>
