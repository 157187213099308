var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "created_at",
                      "label-for": "created_at",
                      label: _vm.$t("feedback.date"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "created_at", type: "text", readonly: "" },
                      model: {
                        value: _vm.created_at,
                        callback: function ($$v) {
                          _vm.created_at = $$v
                        },
                        expression: "created_at",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "userId",
                      "label-for": "userId",
                      label: _vm.$t("feedback.username"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "userId", type: "text", readonly: "" },
                      model: {
                        value: _vm.userId,
                        callback: function ($$v) {
                          _vm.userId = $$v
                        },
                        expression: "userId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "school",
                      "label-for": "school",
                      label: _vm.$t("feedback.schoolname"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "school", type: "text", readonly: "" },
                      model: {
                        value: _vm.school,
                        callback: function ($$v) {
                          _vm.school = $$v
                        },
                        expression: "school",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "type",
                      "label-for": "type",
                      label: _vm.$t("feedback.scheme"),
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { id: "type", type: "text", readonly: "" },
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "content", "label-for": "content" } },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("feedback.detail") } },
                          [
                            _c("b-textarea", {
                              staticStyle: { "background-color": "white" },
                              attrs: {
                                "no-resize": "",
                                rows: "10",
                                readonly: "",
                              },
                              model: {
                                value: _vm.content,
                                callback: function ($$v) {
                                  _vm.content = $$v
                                },
                                expression: "content",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-success" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("apply.back")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }