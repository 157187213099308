var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "7" } }, [
        _c(
          "div",
          { staticClass: "schoolForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-for": "form",
                          label: _vm.$t("school.form"),
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text", readonly: "" },
                          model: {
                            value: _vm.form_id,
                            callback: function ($$v) {
                              _vm.form_id = $$v
                            },
                            expression: "form_id",
                          },
                        }),
                        _vm._v(" "),
                        _vm.action === "add"
                          ? _c("b-form-file", {
                              attrs: {
                                id: "form",
                                accept:
                                  "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
                              },
                              on: { change: _vm.uploadForm },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-5" },
                      [
                        _c("h3", [_vm._v("1. 配對千年表紀錄")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-1" }, [
                          _vm._v("請從千年表選擇相應學校："),
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            options: _vm.suggestSklList,
                            value: _vm.sarId,
                            placeholder: "Suggested Schools",
                          },
                          on: { input: (c) => _vm.updateSarId(c) },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-2 mb-5" }, [
                          _c("table", [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [_vm._v("找不到學校？")]),
                                _vm._v(" "),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName: "v-b-modal.modal-selectskl",
                                          modifiers: {
                                            "modal-selectskl": true,
                                          },
                                        },
                                      ],
                                      attrs: { href: "javascript:void(0)" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        尋找其他學校\n                      "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td"),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-addskl",
                                        modifiers: { "modal-addskl": true },
                                      },
                                    ],
                                    attrs: { href: "javascript:void(0)" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      新增學校至千年表\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "my-3" }, [
                          _vm._v("\n              已選擇：\n              "),
                          _c(
                            "span",
                            {
                              class:
                                _vm.getSklName(_vm.sarId) === "未選擇"
                                  ? "not_selected"
                                  : "resultskl",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.getSklName(_vm.sarId)) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.matchedStr) },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        attrs: {
                          id: "modal-addskl",
                          title: "新增學校至千年表",
                          size: "lg",
                          "hide-footer": "",
                        },
                      },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { "label-for": "form", label: "學校名稱" } },
                          [
                            _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.newskl_schoolName,
                                callback: function ($$v) {
                                  _vm.newskl_schoolName = $$v
                                },
                                expression: "newskl_schoolName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { "label-for": "form", label: "學校類別" } },
                          [
                            _c("b-form-radio-group", {
                              staticClass: "mb-3",
                              attrs: {
                                options: _vm.typeList,
                                "value-field": "value",
                                "text-field": "name",
                                "disabled-field": "notEnabled",
                              },
                              model: {
                                value: _vm.newskl_category,
                                callback: function ($$v) {
                                  _vm.newskl_category = $$v
                                },
                                expression: "newskl_category",
                              },
                            }),
                            _vm._v(" "),
                            _vm.newskl_category === 5
                              ? _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.newskl_categoryTxt,
                                    callback: function ($$v) {
                                      _vm.newskl_categoryTxt = $$v
                                    },
                                    expression: "newskl_categoryTxt",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-group",
                          { attrs: { "label-for": "form", label: "學校區域" } },
                          [
                            _c("b-form-select", {
                              staticClass: "mb-3",
                              attrs: {
                                options: _vm.districtList,
                                "value-field": "value",
                                "text-field": "name",
                                "disabled-field": "disabled",
                              },
                              model: {
                                value: _vm.newskl_district,
                                callback: function ($$v) {
                                  _vm.newskl_district = $$v
                                },
                                expression: "newskl_district",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-success" },
                            on: {
                              click: function ($event) {
                                return _vm.addSkl()
                              },
                            },
                          },
                          [_vm._v("\n              新增學校\n            ")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("school-modal", {
                      attrs: {
                        "sch-id": _vm.sarId,
                        school: _vm.selected,
                        "skl-list": _vm.suggestSklList,
                      },
                      on: { change: _vm.schoolModalChange },
                    }),
                    _vm._v(" "),
                    _c("h3", [_vm._v("2. 檢查學校資料")]),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      { attrs: { id: "title", "label-for": "title" } },
                      [
                        _c(
                          "b-tabs",
                          { attrs: { "content-class": "mt-3" } },
                          [
                            _c(
                              "b-tab",
                              { attrs: { title: _vm.$t("school.name.en") } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.name.en"
                                    ),
                                    type: "text",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.name_en,
                                    callback: function ($$v) {
                                      _vm.name_en = $$v
                                    },
                                    expression: "name_en",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              { attrs: { title: _vm.$t("school.name.tc") } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.name.tc"
                                    ),
                                    type: "text",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.name_tc,
                                    callback: function ($$v) {
                                      _vm.name_tc = $$v
                                    },
                                    expression: "name_tc",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("school.address")))]),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "address_area",
                                  "label-for": "address_area",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.address.area"
                                    ),
                                    type: "text",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.address_area,
                                    callback: function ($$v) {
                                      _vm.address_area = $$v
                                    },
                                    expression: "address_area",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "address_street",
                                  "label-for": "address_street",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.address.street"
                                    ),
                                    type: "text",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.address_street,
                                    callback: function ($$v) {
                                      _vm.address_street = $$v
                                    },
                                    expression: "address_street",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "address_building",
                                  "label-for": "address_building",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.address.building"
                                    ),
                                    type: "text",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.address_building,
                                    callback: function ($$v) {
                                      _vm.address_building = $$v
                                    },
                                    expression: "address_building",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("school.district")))]),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "school_region",
                          "label-for": "school_region",
                        },
                      },
                      [
                        _c("b-form-radio-group", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.regionList,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "notEnabled",
                          },
                          model: {
                            value: _vm.region,
                            callback: function ($$v) {
                              _vm.region = $$v
                            },
                            expression: "region",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      { attrs: { id: "district", "label-for": "district" } },
                      [
                        _c("b-form-select", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.districtList,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "disabled",
                          },
                          model: {
                            value: _vm.district,
                            callback: function ($$v) {
                              _vm.district = $$v
                            },
                            expression: "district",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.$t("school.level")))]),
                    _vm._v(" "),
                    _c(
                      "b-form-group",
                      { attrs: { id: "type", "label-for": "type" } },
                      [
                        _c("b-form-radio-group", {
                          staticClass: "mb-3",
                          attrs: {
                            options: _vm.typeList,
                            "value-field": "value",
                            "text-field": "name",
                            "disabled-field": "notEnabled",
                          },
                          model: {
                            value: _vm.type,
                            callback: function ($$v) {
                              _vm.type = $$v
                            },
                            expression: "type",
                          },
                        }),
                        _vm._v(" "),
                        _vm.type === 5
                          ? _c("b-form-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.typeOther,
                                callback: function ($$v) {
                                  _vm.typeOther = $$v
                                },
                                expression: "typeOther",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("p", [_vm._v(_vm._s(_vm.$t("school.tel")))]),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              { attrs: { id: "tel", "label-for": "tel" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.tel"
                                    ),
                                    type: "number",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.tel,
                                    callback: function ($$v) {
                                      _vm.tel = $$v
                                    },
                                    expression: "tel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c("p", [_vm._v(_vm._s(_vm.$t("school.fax")))]),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              { attrs: { id: "fax", "label-for": "fax" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.fax"
                                    ),
                                    type: "number",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.fax,
                                    callback: function ($$v) {
                                      _vm.fax = $$v
                                    },
                                    expression: "fax",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c("p", [_vm._v(_vm._s(_vm.$t("school.email")))]),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              { attrs: { id: "email", "label-for": "email" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.email"
                                    ),
                                    type: "email",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function ($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("school.principal_name"))),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "principal_name",
                                  "label-for": "principal_name",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.$t(
                                      "school.placeholder.principal_name"
                                    ),
                                    type: "text",
                                    required: "",
                                    trim: "",
                                  },
                                  model: {
                                    value: _vm.principal_name,
                                    callback: function ($$v) {
                                      _vm.principal_name = $$v
                                    },
                                    expression: "principal_name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("p", [_vm._v("附件")]),
                        _vm._v(" "),
                        _vm._l(_vm.attr, function (a, index) {
                          return _c("p", { key: index }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: `${_vm.attachmentUrl}${a}`,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("b-icon", { attrs: { icon: "paperclip" } }),
                                _vm._v(_vm._s(a) + "\n              "),
                              ],
                              1
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.$t("user.isSchoolAdmin")))]),
                    _vm._v(" "),
                    _c("user-Form", {
                      attrs: {
                        action:
                          _vm.action == "edit" ? "schoolEdit" : "schoolAdd",
                        detail: _vm.admin,
                      },
                      on: { change: _vm.getUser },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.$t("user.schoolTeacher")))]),
                    _vm._v(" "),
                    _vm.action === "add"
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-success" },
                            on: { click: _vm.addTab },
                          },
                          [_vm._v(_vm._s(_vm.$t("school.add_teacherSlot")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-tabs",
                      _vm._l(_vm.teacherList, function (teacher, index) {
                        return _c(
                          "b-tab",
                          {
                            key: index,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(teacher.name) +
                                          "\n                "
                                      ),
                                      _vm.action === "add"
                                        ? _c(
                                            "b-button",
                                            {
                                              staticStyle: {
                                                "border-radius": "500%",
                                              },
                                              attrs: {
                                                size: "sm",
                                                variant: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.deleteTab(index)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  ×\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("user-Form", {
                              attrs: {
                                action:
                                  _vm.action == "edit"
                                    ? "schoolEdit"
                                    : "schoolAdd",
                                detail: teacher,
                              },
                              on: { change: _vm.getUser },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.$t("school.remarks")))]),
                    _vm._v(" "),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "remarks",
                                  "label-for": "remarks",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", trim: "" },
                                  model: {
                                    value: _vm.remarks,
                                    callback: function ($$v) {
                                      _vm.remarks = $$v
                                    },
                                    expression: "remarks",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                          disabled: _vm.systemMode === "cloud",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }