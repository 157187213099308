<template>
  <b-row align-h="center" class="component">
    <b-col sm="12" md="10">
      <div class="form">
        <b-form method="post" @submit.prevent="submit">
          <div class="row">
            <div class="col-sm-5">
              <b-form-group id="slug" label-for="slug">
                <p class="label">{{ $t('scheme.slug') }}</p>
                <b-form-input v-model="slug" type="text" trim></b-form-input>
              </b-form-group>

              <b-form-group label-for="img" :label="$t('scheme.img')">
                <b-form-input v-model="img" type="text"></b-form-input>
                <b-form-file id="img" accept="image/*" @change="uploadImage" />
                <img :src="img" style="max-height: 100px" alt="category" />
              </b-form-group>
            </div>

            <div class="col-sm-7">
              <b-tabs content-class="mt-3">
                <b-tab :title="$t('scheme.title.en')">
                  <b-form-group id="title_en" label-for="title_en">
                    <b-form-input
                      v-model="title_en"
                      :placeholder="$t('placeholder.title.en')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
                <b-tab :title="$t('scheme.title.tc')">
                  <b-form-group id="title_tc" label-for="title_tc">
                    <b-form-input
                      v-model="title_tc"
                      :placeholder="$t('placeholder.title.tc')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
                <b-tab :title="$t('scheme.title.sc')">
                  <b-form-group id="title_sc" label-for="title_sc">
                    <b-form-input
                      v-model="title_sc"
                      :placeholder="$t('placeholder.title.sc')"
                      type="text"
                      trim
                    ></b-form-input>
                  </b-form-group>
                </b-tab>
              </b-tabs>

              <b-form-group id="scheme" label-for="scheme">
                <p class="label">{{ $t('event.scheme') }}</p>
                <b-form-select
                  v-model="scheme"
                  :options="schemeList"
                ></b-form-select>
              </b-form-group>

              <b-form-group v-if="scheme" id="parentCat" label-for="parent">
                <p class="label">{{ $t('admin.category.parentCat') }}</p>
                <b-form-select
                  v-model="parent"
                  :options="catList"
                  :disabled="!scheme ? true : false"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div>
            <div class="shadow my-3 mse-c1">
              <b-form-group id="content" label-for="content">
                <b-tabs content-class="mt-3">
                  <b-tab :title="$t('event.content.en')">
                    <!-- <tinymceEditor
                      id="content_en"
                      :value="content_en"
                      @writeContent="getContent"
                    /> -->
                    <b-form-textarea
                      v-model="content_en"
                      rows="10"
                      max-rows="20"
                    ></b-form-textarea>
                  </b-tab>
                  <b-tab :title="$t('event.content.tc')">
                    <b-form-textarea
                      v-model="content_tc"
                      rows="10"
                      max-rows="20"
                    ></b-form-textarea>
                  </b-tab>
                  <b-tab :title="$t('event.content.sc')">
                    <b-form-textarea
                      v-model="content_sc"
                      rows="10"
                      max-rows="20"
                    ></b-form-textarea>
                  </b-tab>
                </b-tabs>
              </b-form-group>
            </div>
          </div>

          <div style="width: 300px">
            <b-form-group id="slug" label-for="slug">
              <p class="label">類別次序</p>
              <b-form-input v-model="orders" type="number" trim></b-form-input>
            </b-form-group>
          </div>

          <Notification v-if="error" :message="error" />
          <div>
            <b-button
              id="submit"
              type="submit"
              variant="outline-success"
              :disabled="btn_disabled"
              >{{ $t('action.submit') }}
            </b-button>
          </div>
        </b-form>
      </div>
    </b-col>
    <tools :tc="getTc" @writeContent="setSc"></tools>
  </b-row>
</template>

<script>
// import TinymceEditor from '~/components/admin/tinymceEditor'
import Notification from '~/components/common/notification'
import tools from '~/components/common/tools'

export default {
  components: {
    Notification,
    // TinymceEditor,
    tools,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['action'],
  async fetch() {
    try {
      const schemel = await this.$axios.get(
        `${process.env.cloudApiUrl}schemes/getList`
      )
      let schemeListData = schemel.data.data
      schemeListData.sort((a, b) => {
        if (a.slug < b.slug) return -1
        if (a.slug > b.slug) return 1
        return 0
      })

      schemeListData = schemeListData.map((d) => {
        d.value = d.id
        d.text = d.title_tc
        // delete d.id
        delete d.title_tc
        return d
      })

      const scheme = schemeListData.find((d) => {
        return d.id === parseInt(this.$route.query.subscheme)
      })
      const parentId = scheme ? scheme.parent : null
      if (parentId) {
        schemeListData = schemeListData.filter(
          (d) => d.parent === parentId || d.id === parentId
        )
      }

      this.schemeList = this.schemeList.concat(schemeListData)

      const result2 = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/category`
      )
      let catlist = result2.data.data
      catlist = catlist.filter((c) => c.parent === null)
      catlist = catlist.map((d) => {
        d.value = d.id
        d.text = d.title_tc
        delete d.id
        delete d.title_tc
        return d
      })
      this.catList = this.catList.concat(catlist)
      this.catListOriginal = this.catListOriginal.concat(catlist)

      if (this.action === 'edit' || this.action === 'copy') {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}categories/${this.$route.params.id}`
        )
        const data = result.data.data[0]
        this.id = data.id
        this.slug = data.slug
        this.scheme = data.scheme
        this.parent = data.parent
        this.title_en = data.title_en
        this.title_tc = data.title_tc
        this.title_sc = data.title_sc
        this.content_en = data.content_en
        this.content_tc = data.content_tc
        this.content_sc = data.content_sc
        this.img = data.img
        this.orders = data.orders

        this.catList = this.catList.filter((c) => c.scheme === this.scheme)
      }
    } catch (err) {
      // console.log(err)
      this.$nuxt.error({
        message: 'Not Found',
        statusCode: 404,
      })
    }
  },
  data() {
    return {
      btn_disabled: false,
      catList: [{ value: null, text: 'No Parent' }],
      catListOriginal: [{ value: null, text: 'No Parent' }],
      schemeList: [{ value: null, text: 'No Parent' }],
      schemeAll: [],
      scheme: '',
      parent: null,
      id: null,
      slug: '',
      title_en: '',
      title_tc: '',
      title_sc: '',
      content_en: '',
      content_tc: '',
      content_sc: '',
      img: null,
      tabs: [],
      orders: null,
      error: null,
    }
  },
  computed: {
    isSubScheme() {
      return this.parent
    },
    getTc() {
      const tc = []
      tc.push(this.title_tc)
      tc.push(this.content_tc)
      if (this.tabs.length > 0) {
        for (let i = 0; i < this.tabs.length; i++) {
          tc.push({
            title: this.tabs[i].title_tc,
            content: this.tabs[i].content_tc,
          })
        }
      }
      return tc
    },
  },
  watch: {
    scheme: {
      handler(newVal, oldVal) {
        this.catList = this.catListOriginal.filter((c) => c.scheme === newVal)
        this.catList.concat([{ value: null, text: 'No Parent' }])
      },
      deep: true,
    },
  },
  methods: {
    getParentSchemeId(id) {
      const result = this.schemeAll.find((s) => s.value === parseInt(id))
      // console.log(this.schemeAll, result)
      return result ? result.parent : null
    },
    isPortalScheme() {
      return (
        this.slug.includes('scds') ||
        this.slug.includes('aoa') ||
        this.slug.includes('spaps') ||
        this.slug.includes('aes')
      )
    },
    setSc(data) {
      this.title_sc = data[0]
      this.content_sc = data[1]
      if (this.tabs.length > 0) {
        for (let i = 1; i <= this.tabs.length; i++) {
          this.tabs[i - 1].title_sc = data[i + 1].title
          this.tabs[i - 1].content_sc = data[i + 1].content
        }
      }
    },
    getContent(evt, id, content) {
      this[id] = content
    },
    getTabContent(evt, id, content) {
      if (id.startsWith('tab_editor')) {
        const tmp = id.split('_')
        this.tabs[tmp[3]]['content_' + tmp[2]] = content
      } else {
        this[id] = content
      }
    },
    async uploadImage(e) {
      try {
        const params = new FormData()
        await // console.log(e.target)
        params.append('image', e.target.files[0])
        const result = await this.$axios.post(
          `${process.env.cloudApiUrl}files/upload`,
          params
        )
        if (result) {
          if (result.data.status === 'ok') {
            this[e.target.id] = result.data.location
          }
        } else {
          throw new Error('upload failed')
        }
      } catch (err) {
        alert(err)
      }
    },
    async submit(e) {
      try {
        if (!this.title_en || !this.title_tc || !this.title_sc) {
          this.error = 'error.title cannot be empty'
          return
        }
        let result
        if (this.action === 'edit') {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}categories/update`,
            {
              id: this.id,
              slug: this.slug,
              parent: this.parent,
              scheme: this.scheme,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              orders: this.orders,
            }
          )
          result = await this.$axios.$post(
            `${process.env.previewApiUrl}categories/update`,
            {
              id: this.id,
              slug: this.slug,
              parent: this.parent,
              scheme: this.scheme,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              orders: this.orders,
            }
          )
        } else {
          result = await this.$axios.$post(
            `${process.env.cloudApiUrl}categories/add`,
            {
              slug: this.slug,
              parent: this.parent,
              scheme: this.scheme,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              orders: this.orders,
            }
          )
          result = await this.$axios.$post(
            `${process.env.previewApiUrl}categories/add`,
            {
              slug: this.slug,
              parent: this.parent,
              scheme: this.scheme,
              title_en: this.title_en,
              title_tc: this.title_tc,
              title_sc: this.title_sc,
              content_en: this.content_en,
              content_tc: this.content_tc,
              content_sc: this.content_sc,
              img: this.img,
              orders: this.orders,
            }
          )
        }
        if (result.error !== undefined) throw new Error(result.error)
        const back = await this.$swal({
          title: this.$t('alert.title'),
          text: `${this.$t('alert.content')}： ${
            this.action === 'edit'
              ? this.$t('alert.edit')
              : this.$t('alert.add')
          }${this.$t('alert.success')}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.$t('alert.back'),
          confirmButtonColor: '#d9534f',
          cancelButtonText: 'OK',
        })
        if (back.value) {
          this.$router.go(-1)
          // this.$router.push(this.localePath('/admin/scheme'))
        } else if (this.action === 'add') {
          this.btn_disabled = true
        }
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
    addTab() {
      this.tabs.push({
        title_en: '',
        title_tc: '',
        title_sc: '',
        content_en: '',
        content_tc: '',
        content_sc: '',
        link_en: '',
        link_tc: '',
        link_sc: '',
      })
    },
    deleteTab(counter) {
      this.tabs.splice(counter, 1)
    },
  },
}
</script>
<style lang="scss" scoped>
.tab {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.component {
  text-align: left;
}
.btn {
  margin-bottom: 5px;
}
.ie-hint {
  color: red;
  display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-hint {
    display: block;
    padding-bottom: 10px;
  }
}

.mse-c1 {
  padding: 30px;
}
@media only screen and (max-width: 768px), (orientation: portrait) {
  .mse-c1 {
    padding: 10px;
  }

  .shadow {
    padding: 12px;
    margin: 30px -15px;
  }
}
</style>
<!--[if IE]>
  <style scoped>
    .ie-hint {
      display: block;
    }
  </style>
<![endif]-->
