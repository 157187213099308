var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("參加者心聲")]),
      _vm._v(" "),
      _vm._l(_vm.data, function (comment, counter) {
        return _c(
          "div",
          { key: counter, staticClass: "comment-box" },
          [
            _c("p", { staticClass: "label" }, [
              _vm._v("心聲" + _vm._s(counter + 1)),
            ]),
            _vm._v(" "),
            _vm.lang === 0
              ? _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("tab.title.en"))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lang === 1
              ? _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("tab.title.tc"))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.lang === 2
              ? _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("tab.title.sc"))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("b-form-input", {
              attrs: {
                placeholder:
                  _vm.lang === 0
                    ? _vm.$t("placeholder.school.en")
                    : _vm.lang === 1
                    ? _vm.$t("placeholder.school.tc")
                    : _vm.$t("placeholder.school.sc"),
                type: "text",
                trim: "",
              },
              on: { input: _vm.inputChanged },
              model: {
                value: comment.school,
                callback: function ($$v) {
                  _vm.$set(comment, "school", $$v)
                },
                expression: "comment.school",
              },
            }),
            _vm._v(" "),
            _c("b-form-input", {
              attrs: {
                placeholder:
                  _vm.lang === 0
                    ? _vm.$t("placeholder.author.en")
                    : _vm.lang === 1
                    ? _vm.$t("placeholder.author.tc")
                    : _vm.$t("placeholder.author.sc"),
                type: "text",
                trim: "",
              },
              on: { input: _vm.inputChanged },
              model: {
                value: comment.author,
                callback: function ($$v) {
                  _vm.$set(comment, "author", $$v)
                },
                expression: "comment.author",
              },
            }),
            _vm._v(" "),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { md: "7" } },
                  [
                    _vm.lang === 0
                      ? _c("p", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("tab.content.en"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 1
                      ? _c("p", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("tab.content.tc"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lang === 2
                      ? _c("p", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm.$t("tab.content.sc"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("medium-editor", {
                      staticClass: "content",
                      attrs: { text: comment.content, options: _vm.options },
                      on: {
                        edit: (operation) => {
                          comment.content = operation.api.origElements.innerHTML
                          _vm.inputChanged()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          "label-for": "img",
                          label: _vm.$t("scheme.img"),
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { type: "text" },
                          on: { input: _vm.inputChanged },
                          model: {
                            value: comment.img,
                            callback: function ($$v) {
                              _vm.$set(comment, "img", $$v)
                            },
                            expression: "comment.img",
                          },
                        }),
                        _vm._v(" "),
                        _c("b-form-file", {
                          attrs: { id: "img", accept: "image/*" },
                          on: {
                            change: (e) => {
                              _vm.uploadImage(e, counter)
                              _vm.inputChanged()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: { "max-height": "100px" },
                          attrs: { src: comment.img, alt: "comment" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticStyle: { "margin-top": "15px" },
                attrs: { variant: "outline-danger" },
                on: {
                  click: function ($event) {
                    return _vm.delRow(counter)
                  },
                },
              },
              [_vm._v("\n      刪除心聲" + _vm._s(counter + 1) + "\n    ")]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.edit
        ? _c(
            "div",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-success" },
                  on: { click: _vm.addRow },
                },
                [_vm._v(_vm._s(_vm.$t("scheme.add_comment")))]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }