var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app", class: _vm.fontclass },
    [
      _c(
        "header",
        { staticClass: "row header mobile-row" },
        [
          _vm.api && _vm.api.includes("preview")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [
                  _vm._v("\n      預覽模式 "),
                  _c("b-icon", { attrs: { icon: "eye" } }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.api && _vm.api.includes("abo.lcsd.hksarg")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [_vm._v("\n      內聯網 " + _vm._s(_vm.version) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.api && _vm.api.includes("abotest.lcsd.hksarg")
            ? _c(
                "div",
                {
                  staticClass: "preview",
                  attrs: { pill: "", variant: "secondary" },
                },
                [_vm._v("\n      內聯網 (Testing)\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "logo" },
            [
              _c("nuxt-link", { attrs: { to: _vm.localePath("/") } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$i18n.locale === "en",
                      expression: "$i18n.locale === 'en'",
                    },
                  ],
                  staticClass: "logoimg",
                  attrs: {
                    src: `${_vm.staticPrefix}img/logo_abo_en.png`,
                    alt: "logo image",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$i18n.locale === "tc",
                      expression: "$i18n.locale === 'tc'",
                    },
                  ],
                  staticClass: "logoimg",
                  attrs: {
                    src: `${_vm.staticPrefix}img/logo_abo_ch.png`,
                    alt: "封面圖片",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.$i18n.locale === "sc",
                      expression: "$i18n.locale === 'sc'",
                    },
                  ],
                  staticClass: "logoimg",
                  attrs: {
                    src: `${_vm.staticPrefix}img/logo_abo_sc.png`,
                    alt: "封面图片",
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "fontstyle",
              class: _vm.navDisplaying ? "m-show-flex" : "m-hide-flex",
            },
            [
              _c(
                "div",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "lang-text",
                      class: _vm.$i18n.locale == "tc" ? "selected" : "",
                      attrs: { to: _vm.switchLocalePath("tc") },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("g.tc")) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("/")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "lang-text",
                      class: _vm.$i18n.locale == "sc" ? "selected" : "",
                      attrs: { to: _vm.switchLocalePath("sc") },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("g.sc")) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("/")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "nuxt-link",
                    {
                      staticClass: "lang-text",
                      class: _vm.$i18n.locale == "en" ? "selected" : "",
                      attrs: { to: _vm.switchLocalePath("en") },
                    },
                    [_vm._v("\n          " + _vm._s(_vm.$t("g.en")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("|")]),
              _vm._v(" "),
              _c("div", { staticClass: "fontsize" }, [
                _c(
                  "a",
                  {
                    class: _vm.selectedFontSize === 1 ? "a1-selected" : "a1",
                    attrs: { onclick: "return false" },
                    on: {
                      click: function ($event) {
                        return _vm.changeFont(1)
                      },
                    },
                  },
                  [_vm._v("\n          A\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: _vm.selectedFontSize === 2 ? "a2-selected" : "a2",
                    attrs: { onclick: "return false" },
                    on: {
                      click: function ($event) {
                        return _vm.changeFont(2)
                      },
                    },
                  },
                  [_vm._v("\n          A\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    class: _vm.selectedFontSize === 3 ? "a3-selected" : "a3",
                    attrs: { onclick: "return false" },
                    on: {
                      click: function ($event) {
                        return _vm.changeFont(3)
                      },
                    },
                  },
                  [_vm._v("\n          A\n        ")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m-line-hide" }, [_vm._v("|")]),
              _vm._v(" "),
              _c("div", { staticClass: "sharing a2a_kit" }, [
                _c("a", { staticClass: "a2a_button_facebook" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-facebook",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "16",
                        height: "16",
                        fill: "currentColor",
                        viewBox: "0 0 16 16",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("a", { staticClass: "a2a_button_sina_weibo" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:xlink": "http://www.w3.org/1999/xlink",
                        "aria-hidden": "true",
                        role: "img",
                        width: "1em",
                        height: "1em",
                        preserveAspectRatio: "xMidYMid meet",
                        viewBox: "0 0 16 16",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M6.722 14.031c-2.65.262-4.938-.938-5.109-2.675c-.172-1.741 1.837-3.359 4.484-3.622c2.65-.263 4.938.938 5.106 2.675c.175 1.741-1.834 3.362-4.481 3.622zm5.297-5.772c-.225-.069-.381-.113-.262-.409c.256-.644.281-1.197.003-1.594c-.519-.741-1.941-.703-3.569-.019c0 0-.513.222-.381-.181c.25-.806.213-1.478-.178-1.869c-.884-.884-3.234.034-5.25 2.05C.876 7.74.001 9.343.001 10.728c0 2.644 3.394 4.253 6.713 4.253c4.35 0 7.247-2.528 7.247-4.534c0-1.216-1.022-1.903-1.941-2.188z",
                          fill: "currentColor",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M14.909 3.416a4.234 4.234 0 0 0-4.031-1.306a.61.61 0 1 0 .256 1.194a3.01 3.01 0 0 1 3.494 3.872a.615.615 0 0 0 .394.772a.615.615 0 0 0 .772-.394v-.003a4.222 4.222 0 0 0-.884-4.134z",
                          fill: "currentColor",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M13.294 4.875a2.055 2.055 0 0 0-1.963-.634a.527.527 0 1 0 .219 1.031c.341-.072.709.034.959.309c.25.278.319.656.209.987a.532.532 0 0 0 .341.666a.531.531 0 0 0 .666-.341a2.062 2.062 0 0 0-.431-2.019z",
                          fill: "currentColor",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M6.869 10.884c-.094.159-.297.234-.456.169c-.159-.063-.206-.244-.116-.397c.094-.153.291-.228.447-.169c.156.056.213.234.125.397zm-.847 1.082c-.256.409-.806.588-1.219.4c-.406-.184-.528-.659-.272-1.059c.253-.397.784-.575 1.194-.403c.416.178.55.65.297 1.063zm.962-2.894c-1.259-.328-2.684.3-3.231 1.409c-.559 1.131-.019 2.391 1.253 2.803c1.319.425 2.875-.228 3.416-1.447c.534-1.197-.131-2.425-1.438-2.766z",
                          fill: "currentColor",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("a", { staticClass: "a2a_button_twitter" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "bi bi-twitter",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "16",
                        height: "16",
                        fill: "currentColor",
                        viewBox: "0 0 16 16",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "a2a_button_email" },
                  [_c("b-icon", { attrs: { icon: "envelope-fill" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "a2a_button_copy_link" },
                  [_c("b-icon", { attrs: { icon: "share-fill" } })],
                  1
                ),
                _vm._v(" "),
                _c("script", {
                  attrs: {
                    async: "",
                    src: "https://static.addtoany.com/menu/page.js",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m-line-hide" }, [_vm._v("|")]),
              _vm._v(" "),
              !_vm.loggedIn
                ? _c(
                    "div",
                    { staticClass: "pointer", on: { click: _vm.login } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("login.login")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn
                ? _c(
                    "div",
                    {
                      staticClass: "logined",
                      staticStyle: { "font-size": "1.3em" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "usericon",
                          staticStyle: { color: "#fc7137" },
                          on: { click: _vm.toggleProfile },
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.user.name.substring(0, 1))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "noti", on: { click: _vm.toggleNoti } },
                        [
                          _c("b-icon", { attrs: { icon: "bell-fill" } }),
                          _vm._v(" "),
                          _vm.count_noti() > 10
                            ? _c("span", { staticClass: "noti-count" }, [
                                _vm._v(_vm._s("10+")),
                              ])
                            : _vm.count_noti() > 0
                            ? _c("span", { staticClass: "noti-count" }, [
                                _vm._v(_vm._s(_vm.count_noti())),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c("b-col", { staticClass: "m-header-right" }, [
            _c(
              "a",
              {
                class: _vm.navDisplaying
                  ? "m-header-button-active"
                  : "m-header-button",
                on: {
                  click: function ($event) {
                    return _vm.m_click_navbar_button()
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 65 49.79",
                    },
                  },
                  [
                    _c("title", [_vm._v("Asset 12")]),
                    _vm._v(" "),
                    _c(
                      "g",
                      { attrs: { id: "Layer_2", "data-name": "Layer 2" } },
                      [
                        _c(
                          "g",
                          {
                            attrs: { id: "Layer_1-2", "data-name": "Layer 1" },
                          },
                          [
                            _c("line", {
                              staticClass: "cls-1",
                              attrs: { x1: "4", y1: "4", x2: "61", y2: "4" },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              staticClass: "cls-1",
                              attrs: {
                                x1: "4",
                                y1: "24.9",
                                x2: "61",
                                y2: "24.9",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              staticClass: "cls-1",
                              attrs: {
                                x1: "4",
                                y1: "45.79",
                                x2: "61",
                                y2: "45.79",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("UserProfile", {
            ref: "mProfile",
            attrs: { show: _vm.isShowProfile, user: _vm.user },
            on: { close: _vm.closeProfile },
          }),
          _vm._v(" "),
          _c("NotiBox", {
            ref: "noti",
            attrs: { show: _vm.isShowNoti, user: _vm.user },
            on: { close: _vm.closeNoti },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          staticStyle: {
            background: "#f8f8f8",
            "min-height": "calc(100vh - 54px)",
          },
        },
        [
          _c(
            "div",
            { staticClass: "nav-container", attrs: { sm: "12", md: "1" } },
            [_c("NavBar", { ref: "mNav" })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "content d-12",
              staticStyle: { padding: "0px" },
              attrs: { sm: "12" },
            },
            [_c("b-container", { staticClass: "main" }, [_c("Nuxt")], 1)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }