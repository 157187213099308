const cookieparser = process.server ? require('cookieparser') : undefined

export default async function ({ app, route, req, store, redirect }) {
  if (process.server) {
    try {
      const allCookies = cookieparser.parse(req.headers.cookie)
      const token = allCookies.jwt
      this.$axios.setToken(token, 'Bearer')
      const result = await this.$axios.get(`users/me`)
      if (!result.data.user) throw new Error('NOT_EXIST')
    } catch (err) {
      const tmp = route.name.split('___')
      store.commit('localStorage/updateRef', tmp[0])
      return redirect(app.localePath('/login'))
    }
  }
  if (process.client) {
    if (!store.state.localStorage.auth.loggedIn) {
      const tmp = route.name.split('___')
      store.commit('localStorage/updateRef', tmp[0])
      return redirect(app.localePath('/login'))
    }
  }
}
