<template>
  <div>
    <a v-b-modal.modal-selectUser href="javascript:void(0)">
      {{ !!userId ? '尋找其他用戶' : '選擇用戶' }}
    </a>
    <b-modal id="modal-selectUser" title="選擇學校" size="xl" hide-footer>
      <div class="my-3">
        已選擇：
        <span
          :class="
            getUserName(userId) === '未選擇' ? 'not_selected' : 'resultskl'
          "
        >
          {{ getUserName(userId) }}
        </span>
      </div>
      選擇用戶：
      {{ `${selected && selected.length ? selected[0].name : ''}` }}
      <b-button
        class="mx-2"
        variant="outline-success"
        size="sm"
        :disabled="!(selected && selected.length)"
        @click="() => (userId = selected[0].id)"
      >
        確定
      </b-button>
      <b-button size="sm" @click="clearSelected"> Clear selected </b-button>

      <b-input-group class="my-3">
        <b-input v-model="keyword" type="text" placeholder="Search" />
        <b-input-group-append>
          <b-button @click="removeKeyword">×</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-table
        id="table"
        ref="selectableTable"
        striped
        hover
        small
        selectable
        selected-variant="info"
        select-mode="single"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        @row-selected="onRowSelected"
      >
      </b-table>
      <p class="mt-3">Total: {{ total }} | Search : {{ rows }}</p>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table"
        align="center"
      ></b-pagination>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    admin: {
      type: Array,
      default: () => [],
    },
    teachers: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Array,
      default: () => [],
    },
    uid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      perPage: 15,
      userId: null,
      currentPage: 1,
      selected: null,
      keyword: null,
      userList: this._.concat(this.admin, this.teachers),
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
    total() {
      return this.userList.length
    },
    allUsers() {
      return this.userList.map((u) => {
        return {
          id: u.id,
          name: u.name,
          email: u.email,
          tel: u.tel,
          role: u.isSchoolAdmin ? 'Admin' : 'Teacher',
        }
      })
    },
    items() {
      return this.keyword
        ? this.allUsers.filter((item) => {
            return (
              item.id.toString().includes(this.getKeyword()) ||
              item.name.toString().includes(this.getKeyword()) ||
              item.email.toLowerCase().includes(this.getKeyword()) ||
              item.tel.toLowerCase().includes(this.getKeyword()) ||
              item.role.toLowerCase().includes(this.getKeyword())
            )
          })
        : this.allUsers
    },
  },
  watch: {
    selected: {
      handler(newVal, oldVal) {
        // this.$emit('change', newVal, this.userId)
      },
      deep: true,
    },
    userId: {
      handler(newVal, oldVal) {
        this.$emit('change', this.selected, newVal)
      },
      deep: true,
    },
    user: {
      handler(newVal, oldVal) {
        this.selected = newVal
      },
      deep: true,
    },
    teachers: {
      handler(newVal, oldVal) {
        this.userList = this._.concat(this.admin, this.teachers)
      },
      deep: true,
    },
    admin: {
      handler(newVal, oldVal) {
        this.userList = this._.concat(this.admin, this.teachers)
      },
      deep: true,
    },
    uid: {
      handler(newVal, oldVal) {
        this.userId = newVal
      },
      deep: true,
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    removeKeyword() {
      this.keyword = ''
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    getKeyword() {
      return this.keyword.toLowerCase().trim()
    },
    getUserName(id) {
      const find = this._.find(this.userList, { id })
      return find ? find.name : '未選擇'
    },
  },
}
</script>

<style lang="scss" scoped>
.resultskl {
  color: grey;
  background-color: white;
  padding: 5px 20px;
  line-height: 2.5;
}
.not_selected {
  color: #f76576;
  background-color: white;
  padding: 5px 20px;
}
</style>
