var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "8" } }, [
        _c(
          "div",
          { staticClass: "pageForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-form-group",
                  { attrs: { id: "slug", "label-for": "slug" } },
                  [
                    _c("p", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("page.slug"))),
                    ]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      attrs: {
                        id: "slug",
                        type: "text",
                        required: "",
                        trim: "",
                        readonly: "",
                      },
                      model: {
                        value: _vm.slug,
                        callback: function ($$v) {
                          _vm.slug = $$v
                        },
                        expression: "slug",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "title", "label-for": "title" } },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("page.title.en") } },
                          [
                            _c("b-form-input", {
                              attrs: { id: "title_en", type: "text", trim: "" },
                              model: {
                                value: _vm.title_en,
                                callback: function ($$v) {
                                  _vm.title_en = $$v
                                },
                                expression: "title_en",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("page.title.tc") } },
                          [
                            _c("b-form-input", {
                              attrs: { id: "title_tc", type: "text", trim: "" },
                              model: {
                                value: _vm.title_tc,
                                callback: function ($$v) {
                                  _vm.title_tc = $$v
                                },
                                expression: "title_tc",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("page.title.sc") } },
                          [
                            _c("b-form-input", {
                              attrs: { id: "title_sc", type: "text", trim: "" },
                              model: {
                                value: _vm.title_sc,
                                callback: function ($$v) {
                                  _vm.title_sc = $$v
                                },
                                expression: "title_sc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "content", "label-for": "content" } },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("page.content.en") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_en",
                                value: _vm.content_en,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("page.content.tc") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_tc",
                                value: _vm.content_tc,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("page.content.sc") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_sc",
                                value: _vm.content_sc,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "shadow my-3 mse-c1" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-success" },
                        on: { click: _vm.addTab },
                      },
                      [_vm._v(_vm._s(_vm.$t("scheme.add_tab")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      _vm._l(_vm.tabs, function (tab, counter) {
                        return _c(
                          "b-tab",
                          {
                            key: counter,
                            staticClass: "tab",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.i18nTitle(tab)) +
                                          "\n                "
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticStyle: {
                                            "border-radius": "500%",
                                          },
                                          attrs: {
                                            size: "sm",
                                            variant: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteTab(counter)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  ×\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              { attrs: { id: "tabs", "label-for": "tabs" } },
                              [
                                _c(
                                  "b-tabs",
                                  { attrs: { "content-class": "mt-3" } },
                                  [
                                    _c(
                                      "b-tab",
                                      { attrs: { title: _vm.$t("g.en") } },
                                      [
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("tab.title.en"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeholder.title.en"
                                            ),
                                            type: "text",
                                            trim: "",
                                          },
                                          model: {
                                            value: tab.title_en,
                                            callback: function ($$v) {
                                              _vm.$set(tab, "title_en", $$v)
                                            },
                                            expression: "tab.title_en",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("tab.content.en"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("tinymceEditor", {
                                          attrs: {
                                            id: "tab_editor_en_" + counter,
                                            value: tab.content_sc,
                                          },
                                          on: {
                                            writeContent: _vm.getTabContent,
                                          },
                                          model: {
                                            value: tab.content_en,
                                            callback: function ($$v) {
                                              _vm.$set(tab, "content_en", $$v)
                                            },
                                            expression: "tab.content_en",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-tab",
                                      { attrs: { title: _vm.$t("g.tc") } },
                                      [
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("tab.title.tc"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeholder.title.tc"
                                            ),
                                            type: "text",
                                            trim: "",
                                          },
                                          model: {
                                            value: tab.title_tc,
                                            callback: function ($$v) {
                                              _vm.$set(tab, "title_tc", $$v)
                                            },
                                            expression: "tab.title_tc",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("tab.content.tc"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("tinymceEditor", {
                                          attrs: {
                                            id: "tab_editor_tc_" + counter,
                                            value: tab.content_tc,
                                          },
                                          on: {
                                            writeContent: _vm.getTabContent,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-tab",
                                      { attrs: { title: _vm.$t("g.sc") } },
                                      [
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("tab.title.sc"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "placeholder.title.sc"
                                            ),
                                            type: "text",
                                            trim: "",
                                          },
                                          model: {
                                            value: tab.title_sc,
                                            callback: function ($$v) {
                                              _vm.$set(tab, "title_sc", $$v)
                                            },
                                            expression: "tab.title_sc",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "label" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("tab.content.sc"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("tinymceEditor", {
                                          attrs: {
                                            id: "tab_editor_sc_" + counter,
                                            value: tab.content_sc,
                                          },
                                          on: {
                                            writeContent: _vm.getTabContent,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "customizeLastModify",
                      "label-for": "customizeLastModify",
                    },
                  },
                  [
                    _c("p", { staticClass: "label" }, [_vm._v("最後修改日期")]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      attrs: { id: "customizeLastModify", type: "date" },
                      model: {
                        value: _vm.customizeLastModify,
                        callback: function ($$v) {
                          _vm.customizeLastModify = $$v
                        },
                        expression: "customizeLastModify",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-form-radio",
                      {
                        attrs: { value: 0 },
                        model: {
                          value: _vm.deployTarget,
                          callback: function ($$v) {
                            _vm.deployTarget = $$v
                          },
                          expression: "deployTarget",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("news.deployTarget0")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-radio",
                      {
                        attrs: { value: 1 },
                        model: {
                          value: _vm.deployTarget,
                          callback: function ($$v) {
                            _vm.deployTarget = $$v
                          },
                          expression: "deployTarget",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("news.deployTarget1")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-radio",
                      {
                        attrs: { value: 2 },
                        model: {
                          value: _vm.deployTarget,
                          callback: function ($$v) {
                            _vm.deployTarget = $$v
                          },
                          expression: "deployTarget",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("news.deployTarget2")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          variant: "outline-success",
                          type: "submit",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                    _vm._v(" "),
                    _vm.deployTarget === 0 || _vm.deployTarget === 2
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: {
                              click: function ($event) {
                                return _vm.previewEplatform()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("action.preview")) +
                                " (E-Platform)\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.deployTarget === 1 || _vm.deployTarget === 2
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-warning" },
                            on: {
                              click: function ($event) {
                                return _vm.previewWebpage()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("action.preview")) +
                                " (Webpage)\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }