<template>
  <div>
    <b-modal
      id="modal-eventslot"
      centered
      scrollable
      size="xl"
      @ok="handleOk"
      @show="resetModal"
    >
      <template #modal-header="{ close }">
        <h5 center>{{ $t('event.select_event_slot') }}</h5>
        <b-button size="sm" class="modal-close" @click="close()">×</b-button>
      </template>
      <div class="news-item">
        <div v-if="eventSlots">
          <div class="wrapper">
            <div class="event_slot">
              <div class="row">
                <div v-if="parentSchemeId !== 5" class="col-11 ordering">
                  {{ $t('event.filter_by_target') }}
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="filterItem"
                    name="radio-target"
                  >
                    <b-form-radio value="all">
                      {{ $t('event.all') }}
                    </b-form-radio>
                    <b-form-radio value="k">
                      {{ $t('event.target.k') }}
                    </b-form-radio>
                    <b-form-radio value="p">
                      {{ $t('event.target.p') }}
                    </b-form-radio>
                    <b-form-radio value="f">
                      {{ $t('event.target.f') }}
                    </b-form-radio>
                    <!-- <b-form-radio
                      :disabled="!isSpecialSkl"
                      value="n"
                      >特殊學校優先場次</b-form-radio
                    > -->
                  </b-form-radio-group>
                </div>
                <div v-else class="col-11"></div>
                <div class="col-1">
                  <span class="note-right">*{{ $t('event.required') }}</span>
                </div>
              </div>
              <!-- -------------------------------------------- -->
              <b-table
                id="table"
                class="event_table text-center"
                striped
                thead-class="eventslot-table-td"
                :items="filteredEventSlot"
                :fields="eventslotFields"
              >
                <template #head(firstSlot)="">
                  {{ $t('event.first_choice') }}<span class="note">*</span>
                </template>
                <template #cell(date)="row">
                  {{ $moment(row.item.date).format('DD.MM.YYYY') }}
                </template>
                <template #cell(time)="row">
                  {{
                    $moment(`2021-01-01 ${row.item.startTime}`).format('h:mm a')
                  }}
                  -
                  {{
                    $moment(`2021-01-01 ${row.item.endTime}`).format('h:mm a')
                  }}
                </template>
                <template #cell(venue)="row">
                  {{ getVenue(row.item.venue) }}
                </template>
                <template #cell(target)="row">
                  {{ row.item.target ? formatTarget(row.item.target) : '' }}
                </template>
                <template
                  v-if="scdsAdditionalRound && !isAdmin"
                  #cell(remain_quota)="row"
                >
                  <div v-if="getRemainQuota(row.item.id) > 0">
                    {{ getRemainQuota(row.item.id) }}
                  </div>
                  <div v-else style="color: indianred">
                    {{ $t('event.statusList.fulled') }}
                  </div>
                </template>
                <template #cell(slot)="row">
                  <div v-if="row.item.status === 2" style="color: indianred">
                    {{ $t('event.statusList.cancelled') }}
                  </div>
                  <div
                    v-else-if="
                      $moment(row.item.date).isBefore($moment(), 'day')
                    "
                    style="color: forestgreen"
                  >
                    {{ $t('event.statusList.completed') }}
                  </div>
                  <div
                    v-else-if="
                      getRemainQuota(row.item.id) <= 0 &&
                      (scdsAdditionalRound || isAdmin)
                    "
                    style="color: indianred"
                  >
                    {{ $t('event.statusList.fulled') }}
                  </div>
                  <b-form-radio
                    v-model="firstChoice"
                    :value="row.item.id"
                    name="first"
                    :state="state"
                    @change="checkRadio('1', row.item.id)"
                  ></b-form-radio>
                </template>
                <template #cell(firstSlot)="row">
                  <div v-if="row.item.status === 2" style="color: indianred">
                    {{ $t('event.statusList.cancelled') }}
                    <b-form-radio
                      v-if="isAdmin"
                      v-model="firstChoice"
                      :value="row.item.id"
                      name="first"
                      :state="state"
                      @change="checkRadio('1', row.item.id)"
                    ></b-form-radio>
                  </div>
                  <div
                    v-else-if="
                      $moment(row.item.date).isBefore($moment(), 'day')
                    "
                    style="color: forestgreen"
                  >
                    {{ $t('event.statusList.completed') }}
                    <b-form-radio
                      v-if="isAdmin"
                      v-model="firstChoice"
                      :value="row.item.id"
                      name="first"
                      :state="state"
                      @change="checkRadio('1', row.item.id)"
                    ></b-form-radio>
                  </div>
                  <div
                    v-else-if="
                      getRemainQuota(row.item.id) <= 0 && scdsAdditionalRound
                    "
                    style="color: indianred"
                  >
                    {{ $t('event.statusList.fulled') }}
                    <b-form-radio
                      v-if="isAdmin"
                      v-model="firstChoice"
                      :value="row.item.id"
                      name="first"
                      :state="state"
                      @change="checkRadio('1', row.item.id)"
                    ></b-form-radio>
                  </div>
                  <b-form-radio
                    v-else
                    v-model="firstChoice"
                    :value="row.item.id"
                    name="first"
                    :state="state"
                    @change="checkRadio('1', row.item.id)"
                  ></b-form-radio>
                </template>
                <template #cell(secondSlot)="row">
                  <div v-if="row.item.status === 2" style="color: indianred">
                    {{ $t('event.statusList.cancelled') }}
                    <b-form-radio
                      v-if="isAdmin"
                      v-model="secondChoice"
                      :value="row.item.id"
                      name="second"
                      @change="checkRadio('2', row.item.id)"
                    ></b-form-radio>
                  </div>
                  <div
                    v-else-if="
                      $moment(row.item.date).isBefore($moment(), 'day')
                    "
                    style="color: forestgreen"
                  >
                    {{ $t('event.statusList.completed') }}
                    <b-form-radio
                      v-if="isAdmin"
                      v-model="secondChoice"
                      :value="row.item.id"
                      name="second"
                      @change="checkRadio('2', row.item.id)"
                    ></b-form-radio>
                  </div>
                  <div
                    v-else-if="
                      getRemainQuota(row.item.id) <= 0 && scdsAdditionalRound
                    "
                    style="color: indianred"
                  >
                    {{ $t('event.statusList.fulled') }}
                    <b-form-radio
                      v-if="isAdmin"
                      v-model="firstChoice"
                      :value="row.item.id"
                      name="first"
                      :state="state"
                      @change="checkRadio('1', row.item.id)"
                    ></b-form-radio>
                  </div>
                  <b-form-radio
                    v-else
                    v-model="secondChoice"
                    :value="row.item.id"
                    name="second"
                    @change="checkRadio('2', row.item.id)"
                  ></b-form-radio>
                </template>
              </b-table>
              <!-- -------------------------------------------- -->

              <p v-if="!eventSlots.length" style="text-align: center">
                {{ $t('event.no_slot_found') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="">
        <!-- <div class="btn-apply" @click="apply()">
          {{ $t('event.apply') }}
        </div> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: {
    eventSlots: { type: Array, default: () => [] },
    isAdmin: { type: Boolean, default: false },
    parentSchemeId: { type: Number, default: 0 },
    passFirstChoice: { type: Number, default: 0 },
    passSecondChoice: { type: Number, default: 0 },
  },
  async fetch() {
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/public/venue`
      )
      this.venue = result.data.data
    } catch (err) {}
    try {
      if (this.isAdmin) {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/public/event/` +
            this.$route.query.eventid
        )
        this.event = result.data.data
      } else {
        const result = await this.$axios.get(
          `${process.env.cloudApiUrl}events/public/event/` +
            this.$route.params.id
        )
        this.event = result.data.data
      }
    } catch (err) {}
    if (this.parentSchemeId === 1) {
      try {
        const temp = await this.$axios.get(
          `${process.env.cloudApiUrl}schemes/public/id/${this.event[0].scheme}`
        )
        const temp2 = temp.data.data
        if (temp2.length && temp2[0].reg_round2_start) {
          this.scdsAdditionalRound = this.$moment().isSameOrAfter(
            this.$moment(temp2[0].reg_round2_start),
            'day'
          )
        }
      } catch (err) {}
    }
    try {
      const result = await this.$axios.get(
        `${process.env.cloudApiUrl}events/remainQuota/${this.event[0].id}`
      )
      this.remain_quota = result.data.data
    } catch (err) {}
  },
  data() {
    return {
      scdsAdditionalRound: false,
      firstChoice: this.passFirstChoice,
      secondChoice: this.passSecondChoice,
      filterItem: 'all',
    }
  },
  computed: {
    filteredEventSlot() {
      if (this.filterItem !== 'all') {
        return this.eventSlots.filter(
          (s) => s.target.includes(this.filterItem)
          // console.log(s.target)
        )
      } else {
        return this.eventSlots
      }
    },
    state() {
      return Boolean(this.firstChoice)
    },
    eventslotFields() {
      if (this.parentSchemeId === 1)
        return [
          this.isAdmin ? { key: 'id', label: '場次ID' } : '',
          { key: 'date', label: this.$t('event.date2'), sortable: true },
          { key: 'time', label: this.$t('eventTable.time') },
          { key: 'venue', label: this.$t('event.venue'), sortable: true },
          { key: 'target', label: this.$t('event.target.title') },
          { key: 'quota', label: this.$t('event.quota') },
          this.scdsAdditionalRound && !this.isAdmin
            ? { key: 'remain_quota', label: this.$t('event.remain_quota') }
            : '',
          { key: 'firstSlot', label: this.$t('event.first_choice') },
          { key: 'secondSlot', label: this.$t('event.sec_choice') },
        ]
      else
        return [
          { key: 'id', label: '場次ID' },
          { key: 'date', label: this.$t('event.date2'), sortable: true },
          { key: 'time', label: this.$t('eventTable.time') },
          { key: 'venue', label: this.$t('event.venue'), sortable: true },
          { key: 'target', label: this.$t('event.target.title') },
          { key: 'quota', label: this.$t('event.quota') },
          { key: 'slot', label: this.$t('event.select_slot') },
        ]
    },
  },
  watch: {
    passFirstChoice: {
      handler(newVal, oldVal) {
        this.firstChoice = newVal
      },
      deep: true,
    },
    passSecondChoice: {
      handler(newVal, oldVal) {
        this.secondChoice = newVal
      },
      deep: true,
    },
  },
  methods: {
    resetModal() {
      this.firstChoice = this.passFirstChoice
      this.secondChoice = this.passSecondChoice
    },
    handleOk() {
      this.$emit('input', this.firstChoice, this.secondChoice)
    },
    checkRadio(selection, selectedId) {
      if (selection === '1') {
        if (this.secondChoice === selectedId) this.secondChoice = null
      } else if (selection === '2') {
        if (this.firstChoice === selectedId) this.firstChoice = null
      }
    },
    getVenue(code) {
      // console.log('this.event ' + JSON.stringify(this.event))
      let venueText = ''
      if (this.venue) {
        this.venue.find((v) => {
          if (v.code === code) {
            venueText = this.i18nName(v)
          }
        })
      }
      // console.log(venueText)
      return venueText
    },
    getRemainQuota(eventslot) {
      // console.log('eventslot: ', eventslot)
      let remainingquota = '0'
      if (this.remain_quota) {
        this.remain_quota.find((q) => {
          if (q.eventSlotId === eventslot) {
            remainingquota = q.remainQuota
          }
        })
      }
      // console.log('remainingquota: ', remainingquota)
      return remainingquota
    },
    formatTarget(t) {
      let result = ''
      const targetArray = t.split(',')
      let targetArrayInInt = []
      targetArray.map((t) => {
        if (t[0] === 'k') targetArrayInInt.push(parseInt(t[1]) - 3)
        if (t[0] === 'p') targetArrayInInt.push(parseInt(t[1]))
        if (t[0] === 'f') targetArrayInInt.push(parseInt(t[1]) + 6)
      })
      // console.log('targetArrayInInt ' + targetArrayInInt)

      targetArrayInInt = targetArrayInInt.sort((a, b) =>
        a > b ? 1 : b > a ? -1 : 0
      )
      const check =
        targetArrayInInt[0] + targetArrayInInt.length - 1 ===
        targetArrayInInt[targetArrayInInt.length - 1]

      if (check) {
        // if only 1 item
        if (targetArrayInInt.length === 1)
          result = this.intToGrade(targetArrayInInt[0])
        // if continous
        else {
          result = this.intToGrade(targetArrayInInt[0])
            .concat(this.$t('event.to')) // .concat(' - ')
            .concat(
              this.intToGrade(targetArrayInInt[targetArrayInInt.length - 1])
            )
        }
      } else {
        result = this.group(targetArrayInInt)
      }
      return result
    },
    intToGrade(i) {
      // console.log(i, typeof i)
      let result = ''
      switch (this.$i18n.locale) {
        case 'en':
          result =
            i < 1
              ? 'K.'.concat((i + 3).toString())
              : i < 7
              ? 'P.'.concat(i.toString())
              : 'S.'.concat((i - 6).toString())
          break
        case 'tc':
          result =
            i === -2
              ? '幼兒班'
              : i === -1
              ? '低班'
              : i === 0
              ? '高班'
              : i < 7
              ? '小'.concat(this.intToChi(i))
              : '中'.concat(this.intToChi(i - 6))
          break
        case 'sc':
          break
        default:
          break
      }
      return result
    },
    intToChi(i) {
      switch (i) {
        case 1:
          return '一'
        case 2:
          return '二'
        case 3:
          return '三'
        case 4:
          return '四'
        case 5:
          return '五'
        case 6:
          return '六'
        default:
          break
      }
    },
    group(numbers) {
      const result = numbers
        .reduce((result, value, index, array) => {
          if (!index || array[index - 1] + 1 !== value) {
            result.push([this.intToGrade(parseInt(value))])
          } else {
            result[result.length - 1][1] = this.intToGrade(parseInt(value))
          }
          return result
        }, [])
        .map((array) => array.join('至'))
      return result.join(', ')
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss">
.event_slot > table > thead > tr > th {
  background-color: #ffe086; // #f5ddd4;
  text-align: center;
  height: 40px;
  border: 1px solid grey;
}
</style>
<style lang="scss" scoped>
.news-item {
  margin-top: -20px;
}
.modal-close {
  position: absolute;
  right: 45px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.note,
.note-right {
  color: #d14814;
}
.event_slot {
  margin-top: 5px;
}
.event_slot > table {
  border: none;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 25px;
  color: #3f3f3f;
}
</style>
