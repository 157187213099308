<template>
  <b-modal :id="code" size="xl" :hide-footer="true" @shown="shown" @hide="hide">
    <email-template-form
      action="send"
      :is-show="isShow"
      :trn-id="trnId"
      :temp-code="code"
      :custom="custom"
    ></email-template-form>
  </b-modal>
</template>
<script>
import EmailTemplateForm from '~/components/admin/emailTemplate/emailTemplateForm'
export default {
  components: { EmailTemplateForm },
  props: {
    trnId: { type: String, default: '' },
    code: { type: String, default: '' },
    customApi: { type: String, default: '' },
    customData: { type: Object, default: null },
  },
  async fetch() {
    try {
      const result = await this.$axios.get(this.customApi)
      this.custom = result.data.data[0]

      this.custom = Object.assign({}, this.custom, this.customData)
      console.log(this.custom)
      console.log(this.customData)
    } catch (error) {
      this.custom = this.customData
      console.log(error)
    }
  },
  data() {
    return {
      isShow: false,
      custom: null,
    }
  },
  methods: {
    shown() {
      this.isShow = true
    },
    hide() {
      this.isShow = true
    },
  },
}
</script>
