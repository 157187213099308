var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-cont" }, [
    _c("div", { staticClass: "slot-title" }, [
      !_vm.$route.name.includes("apply")
        ? _c("hr", { staticStyle: { width: "95%" } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.$route.name.includes("apply")
        ? _c("div", { staticClass: "event-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("apply.scds.tab1.declare")) + " "
            ),
            _c("span", [_vm._v("*")]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "slot-form" },
      [
        _vm.$i18n.locale === "en"
          ? _c("div", [_vm._v("I hereby acknowledge that:")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-field declare-cont" },
          [
            _c("b-form-checkbox", {
              attrs: { disabled: _vm.statement, state: _vm.state },
              model: {
                value: _vm.statement2,
                callback: function ($$v) {
                  _vm.statement2 = $$v
                },
                expression: "statement2",
              },
            }),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("apply.scds.tab1.declare1a")) +
                  "\n        "
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: "modal-a",
                      expression: "'modal-a'",
                    },
                  ],
                  attrs: { href: "javascript:void(0)" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.scds.tab1.declare1")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("apply.scds.tab1.declare1b")) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-field declare-cont" },
          [
            _c("b-form-checkbox", {
              attrs: { disabled: _vm.statement, state: _vm.state },
              model: {
                value: _vm.statement1,
                callback: function ($$v) {
                  _vm.statement1 = $$v
                },
                expression: "statement1",
              },
            }),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("apply.scds.tab1.declare2")) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "event-field declare-cont" },
          [
            _c("b-form-checkbox", {
              attrs: { disabled: _vm.statement, state: _vm.state },
              model: {
                value: _vm.statement3,
                callback: function ($$v) {
                  _vm.statement3 = $$v
                },
                expression: "statement3",
              },
            }),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("apply.scds.tab1.declare3a")) +
                  "\n        "
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal",
                      value: "modal-b",
                      expression: "'modal-b'",
                    },
                  ],
                  attrs: { href: "javascript:void(0)" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("apply.scds.tab1.declare3")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("apply.scds.tab1.declare3b")) +
                  "\n      "
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm.scheme === "spaps"
          ? _c(
              "div",
              { staticClass: "event-field declare-cont" },
              [
                _c("b-form-checkbox", {
                  attrs: { disabled: _vm.statement, state: _vm.state },
                  model: {
                    value: _vm.statement4,
                    callback: function ($$v) {
                      _vm.statement4 = $$v
                    },
                    expression: "statement4",
                  },
                }),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("apply.scds.tab1.declare4")) +
                      "\n      "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("b-form-invalid-feedback", { attrs: { state: _vm.state } }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("apply.scds.tab1.declare_note")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            ref: "newsmodal2",
            attrs: { id: "modal-a", scrollable: "", size: "lg" },
            scopedSlots: _vm._u([
              {
                key: "modal-header",
                fn: function ({ close }) {
                  return [
                    _c("h5", { attrs: { center: "" } }, [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.declare1"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "45px",
                          "border-radius": "50%",
                          width: "30px",
                          height: "30px",
                        },
                        attrs: { size: "sm" },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      },
                      [_vm._v("×")]
                    ),
                  ]
                },
              },
              {
                key: "modal-footer",
                fn: function () {
                  return [
                    _c("img", {
                      staticStyle: { width: "160px", height: "76px" },
                      attrs: {
                        src: `${_vm.staticPrefix}img/logo_lcsd.svg`,
                        alt: "logo_lcsd",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.i18nContent(_vm.application_personal_info_statement)
                ),
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            ref: "newsmodal2",
            attrs: { id: "modal-b", scrollable: "", size: "lg" },
            scopedSlots: _vm._u([
              {
                key: "modal-header",
                fn: function ({ close }) {
                  return [
                    _c("h5", { attrs: { center: "" } }, [
                      _vm._v(_vm._s(_vm.$t("apply.scds.tab1.declare3"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticStyle: {
                          position: "absolute",
                          right: "45px",
                          "border-radius": "50%",
                          width: "30px",
                          height: "30px",
                        },
                        attrs: { size: "sm" },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      },
                      [_vm._v("×")]
                    ),
                  ]
                },
              },
              {
                key: "modal-footer",
                fn: function () {
                  return [
                    _c("img", {
                      staticStyle: { width: "160px", height: "76px" },
                      attrs: {
                        src: `${_vm.staticPrefix}img/logo_lcsd.svg`,
                        alt: "logo_lcsd",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(_vm.i18nContent(_vm.application_notes)),
              },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }