<template>
  <client-only>
    <editor :id="id" v-model="content" :init="init"></editor>
  </client-only>
</template>

<script>
import editor from '@tinymce/tinymce-vue'

let tinymce

if (process.client) {
  // eslint-disable-next-line no-unused-vars
  tinymce = require('tinymce/tinymce')
  require('tinymce/themes/silver')
  require('tinymce/icons/default')
  require('tinymce/plugins/image')
  require('tinymce/plugins/media')
  require('tinymce/plugins/table')
  require('tinymce/plugins/lists')
  require('tinymce/plugins/link')
  require('tinymce/plugins/wordcount')
  require('tinymce/plugins/fullscreen')
  require('tinymce/plugins/code')
  require('tinymce/plugins/paste')
  require('tinymce/plugins/advlist')
  require('tinymce/plugins/autolink')
  require('tinymce/plugins/autosave')
  require('tinymce/plugins/codesample')
  require('tinymce/plugins/colorpicker')
  require('tinymce/plugins/contextmenu')
  require('tinymce/plugins/directionality')
  require('tinymce/plugins/nonbreaking')
  require('tinymce/plugins/insertdatetime')
  require('tinymce/plugins/preview')
  require('tinymce/plugins/print')
  require('tinymce/plugins/save')
  require('tinymce/plugins/searchreplace')
  require('tinymce/plugins/spellchecker')
  require('tinymce/plugins/tabfocus')
  require('tinymce/plugins/template')
  require('tinymce/plugins/textcolor')
  require('tinymce/plugins/visualblocks')
  require('tinymce/plugins/textpattern')
  require('tinymce/plugins/visualchars')
  require('tinymce/plugins/wordcount')
  require('tinymce/plugins/anchor')
  require('tinymce/plugins/hr')
  require('tinymce/plugins/link')
  require('tinymce/plugins/noneditable')
  require('tinymce/plugins/pagebreak')
  require('tinymce/plugins/charmap')
  // require('tinymce/plugins/formatpainter')
}
export default {
  components: { editor },
  props: {
    el: { type: String, default: '' },
    value: { type: String, default: '' },
    id: { type: String, default: '' },
    height: { type: Number, default: 400 },
  },
  data() {
    return {
      content: this.value,
      ele: this.id,
      init: {
        selector: '#' + this.id,
        skin_url: '/tinymce/skins/ui/oxide',
        height: this.height,
        branding: false,
        menubar: true,
        menu: {
          format: {
            title: 'Format',
            items:
              'blockformats | bold italic underline strikethrough superscript subscript codeformat | forecolor backcolor | removeformat',
          },
        },
        // object_resizing: false,
        autosave_ask_before_unload: false,
        plugins: [
          'advlist autolink lists link image print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code wordcount',
          'charmap',
          // 'formatpainter',
        ],
        font_formats:
          'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; BiauKai=標楷體,DFKai-SB,BiauKai; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; MicrosoftJhengHei=Microsoft JhengHei, sans-serif; MingLiU=mingliu; Noto Sans TC, Noto Sans SC=noto-sans tc, noto-sans sc, sans-serif;',
        fontsize_formats:
          '8px 9px 10px 10.5px 11px 12px 14px 16px 18px 20px 22px 24px 26px 28px 36px 48px 72px',
        toolbar:
          'undo redo | selectall removeformat forecolor fontsizeselect fontselect | bold italic underline | bullist numlist outdent indent',
        custom_colors: false,
        formats: {
          removeformat: [
            {
              selector:
                'b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins',
              remove: 'all',
              split: true,
              expand: false,
              block_expand: true,
              deep: true,
            },
            {
              selector: 'table,tr,th,td',
              attributes: ['style', 'class', 'width'],
              remove: 'none',
              split: false,
              expand: false,
              deep: true,
            },
            {
              selector: 'span',
              attributes: ['style', 'class'],
              remove: 'empty',
              split: true,
              expand: false,
              deep: true,
            },
            {
              selector: '*',
              attributes: ['style', 'class'],
              split: false,
              expand: false,
              deep: true,
            },
          ],
        },
        body_class: 'panel-body ',
        color_map: [
          'FFE086',
          'color1',
          'FFFFDE',
          'color2',
          '1FBCB4',
          'color3',
          'FC7137',
          'color4',
          'D3D3D3',
          'color5',
          'FFFFFF',
          'color6',
          '337AB7',
          'color7',
          'FFA500',
          'color8',
          'F5F5F5',
          'color9',
          '65AA33',
          'color10',
          'FE3855',
          'color11',
          'EAEAEA',
          'color12',
          'C00000',
          'Standard1',
          'FF0000',
          'Standard2',
          'FFC000',
          'Standard3',
          'FFFF00',
          'Standard4',
          '92D050',
          'Standard5',
          '00B050',
          'Standard6',
          '00B0F0',
          'Standard7',
          '0070C0',
          'Standard8',
          '002060',
          'Standard9',
          '7030A0',
          'Standard10',
          'ff0e0e',
          'Standard1',
          'ff4d4d',
          'Standard2',
          'ffd34d',
          'Standard3',
          'ffff4d',
          'Standard4',
          'b8e08c',
          'Standard5',
          '00fd73',
          'Standard6',
          '3ecbff',
          'Standard7',
          '0e9aff',
          'Standard8',
          '003aad',
          'Standard9',
          '9752cb',
          'Standard10',
        ],
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        content_css: '/tinymce/skins/content/default/content.css',
        paste_data_images: true,
        content_style: `
            *                         { padding:0; margin:0; }
            html, body                { height:100%; }
            img                       { max-width:100%; display:block;height:auto; }
            a                         { text-decoration: none; }
            iframe                    { width: 100%; }
            p                         { line-height:1.6; margin: 0px; font-size: 16px; font-family:'Noto Sans TC', 'Noto Sans SC', sans-serif; }
            h1                        { font-size: 23.5px; }
            h2                        { font-size: 21.5px; }
            h3                        { font-size: 19px; }
            table                     { word-wrap:break-word; word-break:break-all; max-width:100%; border:none; border-color:#999; }
            .mce-object-iframe        { width:100%; box-sizing:border-box; margin:0; padding:0; }
            ul,ol                     { list-style-position:inside; }
            body, td, pre {
            font-family: 'Noto Sans TC', 'Noto Sans SC', sans-serif;
            font-size: 16px;
}
          `,
        default_link_target: '_blank',
        link_title: false,
        // convert_urls: false, // 图片上传路径为绝对路径
        // remove_script_host: false,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        paste_word_valid_elements: '*[*]',
        paste_convert_word_fake_lists: false,
        paste_webkit_styles: 'none',
        paste_merge_formats: true,
        nonbreaking_force_tab: false,
        paste_auto_cleanup_on_paste: false,
        forced_root_block: 'p',
        force_p_newlines: true,
        importcss_append: true,
        tabfocus_elements: ':prev,:next',
        image_class_list: [{ title: 'Responsive', value: 'img-responsive' }],
        // custom image upload handler
        images_upload_handler: (blobInfo, success, failure) => {
          const params = new FormData()
          params.append('image', blobInfo.blob())
          this.$axios
            .post(`${process.env.cloudApiUrl}files/upload`, params)
            .then((res) => {
              // console.log(res.data)
              if (res.data.status === 'ok') {
                success(res.data.location)
              } else {
                failure('Upload Failed')
              }
            })
            .catch(() => {
              failure('Upload Failed')
            })
        },
        setup: (editor) => {
          // on out focus
          const _this = this
          editor.on('blur', function () {
            _this.$emit('saveDraft')
          })
        },
      },
    }
  },
  watch: {
    value(newValue) {
      this.content = newValue
    },
    el(newValue) {
      this.ele = newValue
    },
    content(newValue) {
      this.$emit('writeContent', this.$event, this.ele, newValue)
    },
  },
}
</script>
