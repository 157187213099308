var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _vm.edit
        ? _c(
            "b-col",
            { attrs: { cols: "1" } },
            [
              _c(
                "b-button",
                {
                  staticStyle: { margin: "40px 0 4px 0" },
                  attrs: { variant: "outline-success" },
                  on: { click: _vm.addRow },
                },
                [_vm._v("添加列")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-danger" },
                  on: { click: _vm.delRow },
                },
                [_vm._v("刪除列")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-col", { attrs: { cols: _vm.edit ? 11 : 12 } }, [
        _vm.edit
          ? _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-success" },
                    on: { click: _vm.addCol },
                  },
                  [_vm._v("添加欄")]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-danger" },
                    on: { click: _vm.delCol },
                  },
                  [_vm._v("刪除欄")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticStyle: { "overflow-x": "auto" } }, [
          _c("table", { staticClass: "table_impl" }, [
            _c(
              "tbody",
              [
                _vm._l(_vm.data, function (row, index) {
                  return _c(
                    "tr",
                    { key: index },
                    [
                      _vm._l(row, function (item, i) {
                        return _c(
                          "td",
                          { key: i, attrs: { colspan: item.colspan } },
                          [
                            !_vm.edit & (_vm.lang === 0)
                              ? _c(
                                  "span",
                                  { style: i === 0 ? "font-weight: 500" : "" },
                                  [_vm._v(_vm._s(item.content_en))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.edit & (_vm.lang === 1)
                              ? _c(
                                  "span",
                                  { style: i === 0 ? "font-weight: 500" : "" },
                                  [_vm._v(_vm._s(item.content_tc))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.edit & (_vm.lang === 2)
                              ? _c(
                                  "span",
                                  { style: i === 0 ? "font-weight: 500" : "" },
                                  [_vm._v(_vm._s(item.content_sc))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.edit & (_vm.lang === 0)
                              ? _c("b-form-input", {
                                  class: i === 0 ? "first_td" : "other_td",
                                  attrs: { type: "text", trim: "" },
                                  on: { input: _vm.inputChanged },
                                  model: {
                                    value: item.content_en,
                                    callback: function ($$v) {
                                      _vm.$set(item, "content_en", $$v)
                                    },
                                    expression: "item.content_en",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.edit & (_vm.lang === 1)
                              ? _c("b-form-input", {
                                  class: i === 0 ? "first_td" : "other_td",
                                  attrs: { type: "text", trim: "" },
                                  on: { input: _vm.inputChanged },
                                  model: {
                                    value: item.content_tc,
                                    callback: function ($$v) {
                                      _vm.$set(item, "content_tc", $$v)
                                    },
                                    expression: "item.content_tc",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.edit & (_vm.lang === 2)
                              ? _c("b-form-input", {
                                  class: i === 0 ? "first_td" : "other_td",
                                  attrs: { type: "text", trim: "" },
                                  on: { input: _vm.inputChanged },
                                  model: {
                                    value: item.content_sc,
                                    callback: function ($$v) {
                                      _vm.$set(item, "content_sc", $$v)
                                    },
                                    expression: "item.content_sc",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "td",
                        { style: _vm.edit === false ? "display: none" : "" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { value: "1", "unchecked-value": "0" },
                              on: {
                                change: (checked) => _vm.span(checked, index),
                              },
                            },
                            [_vm._v("\n                Span\n              ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("tr"),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }