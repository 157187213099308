var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c("b-col", { attrs: { sm: "12", md: "6" } }, [
        _c(
          "div",
          { staticClass: "newsForm" },
          [
            _c(
              "b-form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c("p", [_vm._v(_vm._s(_vm.$t("config.type")))]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "type", "label-for": "type" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "type",
                        type: "text",
                        required: "",
                        readonly: "",
                        trim: "",
                      },
                      model: {
                        value: _vm.type,
                        callback: function ($$v) {
                          _vm.type = $$v
                        },
                        expression: "type",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { id: "content", "label-for": "content" } },
                  [
                    _c(
                      "b-tabs",
                      { attrs: { "content-class": "mt-3" } },
                      [
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("config.content.en") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_en",
                                value: _vm.content_en,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("config.content.tc") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_tc",
                                value: _vm.content_tc,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          { attrs: { title: _vm.$t("config.content.sc") } },
                          [
                            _c("tinymceEditor", {
                              attrs: {
                                id: "content_sc",
                                value: _vm.content_sc,
                              },
                              on: { writeContent: _vm.getContent },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.error
                  ? _c("Notification", { attrs: { message: _vm.error } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "submit" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          id: "submit",
                          type: "submit",
                          variant: "outline-success",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("action.submit")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tools", {
        attrs: { tc: _vm.getTc },
        on: { writeContent: _vm.setSc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }