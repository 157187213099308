var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-alert", { attrs: { variant: "danger", show: "" } }, [
    _vm._v(_vm._s(_vm.message)),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }