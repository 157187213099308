import { render, staticRenderFns } from "./aoaContent.vue?vue&type=template&id=7782c156&scoped=true&"
import script from "./aoaContent.vue?vue&type=script&lang=js&"
export * from "./aoaContent.vue?vue&type=script&lang=js&"
import style0 from "./aoaContent.vue?vue&type=style&index=0&id=7782c156&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7782c156",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/sengital/abo-preview-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7782c156')) {
      api.createRecord('7782c156', component.options)
    } else {
      api.reload('7782c156', component.options)
    }
    module.hot.accept("./aoaContent.vue?vue&type=template&id=7782c156&scoped=true&", function () {
      api.rerender('7782c156', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/event/aoaContent.vue"
export default component.exports