var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.edit
      ? _c(
          "div",
          [
            _c(
              "b-button",
              {
                attrs: { variant: "outline-success" },
                on: { click: _vm.addRow },
              },
              [_vm._v("添加列")]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { variant: "outline-danger" },
                on: { click: _vm.delRow },
              },
              [_vm._v("刪除列")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { ref: "tableImpl", staticStyle: { "overflow-x": "auto" } }, [
      _c(
        "table",
        { class: !!_vm.edit ? "table_impl" : "table_impl fontsize-85" },
        [
          _c(
            "tbody",
            [
              _vm._l(_vm.data, function (row, index) {
                return _c(
                  "tr",
                  { key: index },
                  _vm._l(row, function (item, i) {
                    return _c(
                      "td",
                      { key: i },
                      [
                        !_vm.edit && _vm.i18nContent(item)
                          ? _c("div", {
                              staticClass: "td-cont",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm
                                    .i18nContent(item)
                                    .replace(/(?:\r\n|\r|\n)/g, "<br />")
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && index === 0 && _vm.lang === 0
                          ? _c("b-form-input", {
                              class: "first_td",
                              attrs: { type: "text", trim: "" },
                              on: { input: _vm.inputChanged },
                              model: {
                                value: item.content_en,
                                callback: function ($$v) {
                                  _vm.$set(item, "content_en", $$v)
                                },
                                expression: "item.content_en",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && index !== 0 && _vm.lang === 0
                          ? _c("medium-editor", {
                              attrs: {
                                text: item.content_en,
                                options: _vm.options,
                              },
                              on: {
                                edit: (operation) => {
                                  item.content_en =
                                    operation.api.origElements.innerHTML
                                  _vm.inputChanged()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && index === 0 && _vm.lang === 1
                          ? _c("b-form-input", {
                              class: "first_td",
                              attrs: { type: "text", trim: "" },
                              on: { input: _vm.inputChanged },
                              model: {
                                value: item.content_tc,
                                callback: function ($$v) {
                                  _vm.$set(item, "content_tc", $$v)
                                },
                                expression: "item.content_tc",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && index !== 0 && _vm.lang === 1
                          ? _c("medium-editor", {
                              attrs: {
                                text: item.content_tc,
                                options: _vm.options,
                              },
                              on: {
                                edit: (operation) => {
                                  item.content_tc =
                                    operation.api.origElements.innerHTML
                                  _vm.inputChanged()
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && index === 0 && _vm.lang === 2
                          ? _c("b-form-input", {
                              class: "first_td",
                              attrs: { type: "text", trim: "" },
                              on: { input: _vm.inputChanged },
                              model: {
                                value: item.content_sc,
                                callback: function ($$v) {
                                  _vm.$set(item, "content_sc", $$v)
                                },
                                expression: "item.content_sc",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && index !== 0 && _vm.lang === 2
                          ? _c("medium-editor", {
                              attrs: {
                                text: item.content_sc,
                                options: _vm.options,
                              },
                              on: {
                                edit: (operation) => {
                                  item.content_sc =
                                    operation.api.origElements.innerHTML
                                  _vm.inputChanged()
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              }),
              _vm._v(" "),
              _c("tr"),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }