<template>
  <div class="tab-cont">
    <div class="spaps-div">
      <h3>❸ {{ $t('apply.spaps.tab_txt.tab2.title') }}</h3>
      <p>{{ $t('apply.spaps.tab_txt.tab2.content') }}</p>

      <b-row>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab2.venue') }}
            <span class="red">*</span>
          </div>
          <b-form-checkbox-group
            v-if="edit"
            id="checkbox-group-venue"
            v-model="equipments.venue"
            :options="venues"
            :state="
              validate || validateOnclick[0]
                ? !!equipments.venue && equipments.venue.length > 0
                : null
            "
            @input="inputChanged(0)"
          ></b-form-checkbox-group>
          <div v-else class="choice">
            {{ equipments.venue && equipments.venue.length ? '' : '/' }}
            <span v-for="(d, index) in equipments.venue" :key="index">
              {{ $t(`apply.spaps.tab_txt.tab2.venue_${d}`) }}
            </span>
          </div>
        </b-col>

        <b-col md="6">
          <div v-if="equipments.venue && equipments.venue.find((d) => d === 5)">
            <div id="5" class="subtitle mt-3">
              {{ $t('apply.spaps.tab_txt.tab2.otherVenue') }}
              <span class="red">*</span>
            </div>
            <b-form-input
              v-if="edit"
              v-model="equipments.otherVenue"
              type="text"
              :state="
                equipments.venue &&
                equipments.venue.find((d) => d === 5) &&
                (validate || validateOnclick[3])
                  ? !!equipments.otherVenue
                  : null
              "
              @input="inputChanged(3)"
            />
            <div v-else class="choice">
              {{ equipments.otherVenue !== null ? equipments.otherVenue : '/' }}
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab2.plan') }}
            <span class="red">*</span>
          </div>
          <b-form-radio-group
            v-if="edit"
            id="radio-group-plan"
            v-model="equipments.plan"
            :options="plans"
            :state="validate || validateOnclick[1] ? !!equipments.plan : null"
            @input="inputChanged(1)"
          ></b-form-radio-group>
          <div v-else class="choice">
            {{
              equipments.plan
                ? $t(`apply.spaps.tab_txt.tab2.plan_${equipments.plan}`)
                : '/'
            }}
          </div>
        </b-col>

        <b-col>
          <div id="5" class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab2.size') }}
            <span class="red">*</span>
          </div>
          <b-form-input
            v-if="edit"
            v-model="equipments.size"
            type="number"
            :state="
              validate || validateOnclick[2]
                ? checkNumber(equipments.size)
                : null
            "
            @input="inputChanged(2)"
          />
          <div v-else class="choice">
            {{ equipments.size !== null ? equipments.size : '/' }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="event-title mt-3">
            {{ $t('apply.spaps.tab_txt.tab2.floor') }}
          </div>
          <b-form-checkbox-group
            v-if="edit"
            id="checkbox-group-floor"
            v-model="equipments.floor"
            :options="floors"
            @input="inputChanged"
          ></b-form-checkbox-group>
          <div v-else class="choice">
            {{ equipments.floor && equipments.floor.length ? '' : '/' }}
            <span v-for="(d, index) in equipments.floor" :key="index">
              {{ $t(`apply.spaps.tab_txt.tab2.floor_${d}`) }}
            </span>
          </div>
        </b-col>

        <b-col md="6">
          <div v-if="equipments.floor && equipments.floor.find((d) => d === 3)">
            <div id="5" class="subtitle mt-3">
              {{ $t('apply.spaps.tab_txt.tab2.floorSpecify') }}
              <span class="red">*</span>
            </div>
            <b-form-input
              v-if="edit"
              v-model="equipments.floorSpecify"
              type="text"
              :state="
                equipments.floor &&
                equipments.floor.find((d) => d === 3) &&
                (validate || validateOnclick[4])
                  ? !!equipments.floorSpecify
                  : null
              "
              @input="inputChanged(4)"
            />
            <div v-else class="choice">
              {{
                equipments.floorSpecify !== null ? equipments.floorSpecify : '/'
              }}
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="event-title mt-3">
        {{ $t('apply.spaps.tab_txt.tab2.equipment') }}
      </div>
      <b-form-checkbox-group
        v-if="edit"
        id="checkbox-group-equipment"
        v-model="equipments.equipment"
        :options="equip"
        @input="inputChanged"
      ></b-form-checkbox-group>
      <div v-else class="choice">
        {{ equipments.equipment && equipments.equipment.length ? '' : '/' }}
        <span v-for="(d, index) in equipments.equipment" :key="index">
          {{ $t(`apply.spaps.tab_txt.tab2.equipment_${d}`) }}
        </span>
      </div>
    </div>

    <hr />

    <!-- updated for 22/23新增活動 -->
    <div class="spaps-div">
      <div :class="exceptionalScheme ? 'spaps-div2' : ''">
        <h3>
          ❹ {{ $t('apply.spaps.tab_txt.tab2.title2') }}
          <span v-if="exceptionalScheme">
            {{ $t('apply.spaps.not_applicable') }}
          </span>
        </h3>
        <p v-html="$t('apply.spaps.tab_txt.tab2.content2')"></p>

        <b-row>
          <b-col>
            <div class="event-title mt-3">
              {{ $t('apply.spaps.tab_txt.tab2.venue') }}
            </div>
            <b-form-radio-group
              v-if="exceptionalScheme"
              id="checkbox-radio-2"
              v-model="equipments.venue2"
              :options="venue2"
              disabled
              @input="inputChanged"
            ></b-form-radio-group>
            <b-form-radio-group
              v-else-if="edit"
              id="checkbox-radio-2"
              v-model="equipments.venue2"
              :options="venue2"
              @input="inputChanged"
            ></b-form-radio-group>
            <div v-else class="choice">
              {{
                equipments.venue2
                  ? $t(`apply.spaps.tab_txt.tab2.venue2_${equipments.venue2}`)
                  : '/'
              }}
            </div>
          </b-col>

          <b-col md="6">
            <div v-if="equipments.venue2 === 2">
              <div id="5" class="subtitle mt-3">
                {{ $t('apply.spaps.tab_txt.tab2.otherVenue') }}
              </div>
              <b-form-input
                v-if="edit"
                v-model="equipments.otherVenue2"
                type="text"
                @input="inputChanged"
              />
              <div v-else class="choice">
                {{
                  equipments.otherVenue2 !== null ? equipments.otherVenue2 : '/'
                }}
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <div class="event-title mt-3">
              {{ $t('apply.spaps.tab_txt.tab2.speaker') }}
            </div>
            <b-form-radio-group
              v-if="exceptionalScheme"
              id="checkbox-radio-3"
              v-model="equipments.speaker"
              :options="speaker"
              disabled
              @input="inputChanged"
            ></b-form-radio-group>
            <b-form-radio-group
              v-else-if="edit"
              id="checkbox-radio-3"
              v-model="equipments.speaker"
              :options="speaker"
              @input="inputChanged"
            ></b-form-radio-group>
            <div v-else class="choice">
              {{
                equipments.speaker
                  ? $t(`apply.spaps.tab_txt.tab2.speaker_${equipments.speaker}`)
                  : '/'
              }}
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="event-title mt-3">
              {{ $t('apply.spaps.tab_txt.tab2.mic') }}
            </div>
            <b-form-radio-group
              v-if="exceptionalScheme"
              id="checkbox-radio-4"
              v-model="equipments.mic"
              :options="mic"
              disabled
              @input="inputChanged"
            ></b-form-radio-group>
            <b-form-radio-group
              v-else-if="edit"
              id="checkbox-radio-4"
              v-model="equipments.mic"
              :options="mic"
              @input="inputChanged"
            ></b-form-radio-group>
            <div v-else class="choice">
              {{
                equipments.mic
                  ? $t(`apply.spaps.tab_txt.tab2.mic_${equipments.mic}`)
                  : '/'
              }}
            </div>
          </b-col>

          <b-col md="6">
            <div v-if="equipments.mic === 1">
              <div id="5" class="subtitle mt-3">
                {{ $t('apply.spaps.tab_txt.tab2.micAmount') }}
              </div>
              <b-form-input
                v-if="edit"
                v-model="equipments.micAmount"
                type="number"
                @input="inputChanged"
              />
              <div v-else class="choice">
                {{ equipments.micAmount !== null ? equipments.micAmount : '/' }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
export default {
  props: {
    validate: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    equipments: {
      type: Object,
      default() {
        return {
          venue: null,
          plan: null,
          size: null,
          floor: null,
          equipment: null,
          venue2: null,
          speaker: null,
          mic: null,
          micAmount: null,
        }
      },
    },
  },
  data() {
    return {
      exceptionalScheme: this.$route.query.subscheme === '104',
      noOfFieldsToValidate: 6,
      validateOnclick: [],
      venues: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab2.venue_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab2.venue_2') },
        { value: 3, text: this.$t('apply.spaps.tab_txt.tab2.venue_3') },
        { value: 4, text: this.$t('apply.spaps.tab_txt.tab2.venue_4') },
        { value: 5, text: this.$t('apply.spaps.tab_txt.tab2.venue_5') },
      ],
      plans: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab2.plan_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab2.plan_2') },
      ],
      floors: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab2.floor_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab2.floor_2') },
        { value: 3, text: this.$t('apply.spaps.tab_txt.tab2.floor_3') },
      ],
      equip: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab2.equipment_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab2.equipment_2') },
        { value: 3, text: this.$t('apply.spaps.tab_txt.tab2.equipment_3') },
        // { value: 4, text: this.$t('apply.spaps.tab_txt.tab2.equipment_4') },
        // { value: 5, text: this.$t('apply.spaps.tab_txt.tab2.equipment_5') }, // remove this option as requested by ABO
        { value: 6, text: this.$t('apply.spaps.tab_txt.tab2.equipment_6') },
        { value: 7, text: this.$t('apply.spaps.tab_txt.tab2.equipment_7') },
        { value: 9, text: this.$t('apply.spaps.tab_txt.tab2.equipment_9') }, // new added option
        { value: 10, text: this.$t('apply.spaps.tab_txt.tab2.equipment_10') }, // new added option
        { value: 8, text: this.$t('apply.spaps.tab_txt.tab2.equipment_8') },
      ],
      venue2: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab2.venue2_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab2.venue2_2') },
      ],
      speaker: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab2.speaker_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab2.speaker_2') },
      ],
      mic: [
        { value: 1, text: this.$t('apply.spaps.tab_txt.tab2.mic_1') },
        { value: 2, text: this.$t('apply.spaps.tab_txt.tab2.mic_2') },
      ],
    }
  },
  mounted() {
    for (let i = 0; i < this.noOfFieldsToValidate; i++) {
      this.validateOnclick.push(false)
    }
  },
  methods: {
    checkNumber(input) {
      return input > 0 && !input.toString().includes('.')
    },
    checkExist(input) {
      return !!input && input !== ''
    },
    inputChanged(fieldIndex) {
      this.$emit('input', this.equipments, this.checkPassValidationTab2())
      this.validateOnclick[fieldIndex] = true
    },
    checkOtherVenue() {
      if (this.equipments.venue && this.equipments.venue.find((d) => d === 5))
        return this.checkExist(this.equipments.otherVenue)
      else return true
    },
    checkFloorSpecify() {
      if (this.equipments.floor && this.equipments.floor.find((d) => d === 3))
        return this.checkExist(this.equipments.floorSpecify)
      else return true
    },
    checkMicAmount() {
      if (this.equipments.mic === 1)
        return this.checkNumber(this.equipments.micAmount)
      else return true
    },
    checkPassValidationTab2() {
      return (
        this.equipments.venue &&
        this.equipments.venue.length > 0 &&
        !!this.equipments.plan &&
        this.checkExist(this.equipments.size) &&
        this.checkOtherVenue() &&
        this.checkFloorSpecify() &&
        this.checkMicAmount()
      )
    },
    i18nContent(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.content_en
        case 'tc':
          return item.content_tc
        case 'sc':
          return item.content_sc
        default:
          return ''
      }
    },
    i18nName(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.name_en
        case 'tc':
          return item.name_tc
        case 'sc':
          return item.name_sc
        default:
          return ''
      }
    },
    i18nTitle(item) {
      switch (this.$i18n.locale) {
        case 'en':
          return item.title_en
        case 'tc':
          return item.title_tc
        case 'sc':
          return item.title_sc
        default:
          return ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.event-title {
  // color: #1fbcb4;
}
.choice {
  background-color: whitesmoke;
  padding: 5px;
  overflow-wrap: break-word;
}
.subtitle {
  color: #ffa500;
}
h3 {
  color: #ffa500;
}
.spaps-div {
  // border: 2px solid #1fbcb4;
  padding: 10px 0;
  text-align: left;
}
.spaps-div2 > * {
  color: grey !important;
}
</style>
