<template>
  <b-row align-h="center" class="component">
    <b-col cols="5">
      <Notification v-if="error" :message="error" />
      <b-card>
        <div class="login">
          <b-col cols="8">
            <h3 class="title-login">{{ $t('g.title') }}</h3>
            <h4 class="title-login">{{ $t('login.resetpw') }}</h4>
          </b-col>
          <b-form method="post" @submit.prevent="loginSubmit">
            <b-form-group id="email" label-for="email">
              <p class="label">{{ $t('login.email') }}</p>
              <b-form-input
                id="email"
                v-model="email"
                type="email"
                required
                trim
                disabled
              ></b-form-input>
            </b-form-group>

            <b-form-group id="newPassword" label-for="newPassword">
              <p class="label">{{ $t('login.newpwd') }}</p>
              <b-form-input
                id="newPassword"
                v-model="newPassword"
                type="password"
                required
                trim
              ></b-form-input>
            </b-form-group>
            <b-form-group id="repeatPassword" label-for="repeatPassword">
              <p class="label">{{ $t('login.repeatpwd') }}</p>
              <b-form-input
                id="repeatPassword"
                v-model="repeatPassword"
                type="password"
                required
                trim
              ></b-form-input>
            </b-form-group>

            <div class="rules" v-html="$t('account.password_rules')"></div>

            <div>
              <b-button
                id="login"
                type="submit"
                :disabled="api && api.includes('preview')"
              >
                {{ $t('login.resetpw') }}
              </b-button>
            </div>
          </b-form>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Notification from '~/components/common/notification'
export default {
  components: {
    Notification,
  },
  props: {
    tmp: { type: String, default: '' },
  },
  fetch() {
    try {
      this.turl = this.tmp.replace('.', '+').replace('_', '/').replace('-', '=')
      const obj = JSON.parse(atob(this.turl))
      this.email = obj.email
      this.token = obj.token
    } catch (err) {
      this.error = this.$t('error.INVALID_TOKEN')
      this.$router.push(this.localePath('/'))
    }
  },
  data() {
    return {
      api: process.env.mainApiUrl,
      email: '',
      newPassword: '',
      repeatPassword: '',
      token: '',
      turl: '',
      error: null,
    }
  },
  computed: {
    staticPrefix() {
      return process.env.base
    },
  },
  methods: {
    async loginSubmit() {
      try {
        const result = await this.$axios.$post(`users/resetPwd`, {
          email: this.email,
          token: this.token,
          newPassword: this.newPassword,
          repeatPassword: this.repeatPassword,
        })
        if (result.error !== undefined) throw new Error(result.error)
        await this.$swal({
          text: `你的帳戶密碼已重設！`,
          icon: 'question',
          confirmButtonText: 'OK',
          confirmButtonColor: '#d9534f',
        })
        this.$router.push(this.localePath('/'))
      } catch (err) {
        // console.log(err)
        this.error = this.$t('error.' + err.response.data.error)
      }
    },
  },
}
</script>
<style scoped>
* {
  color: #fd7136;
}

.component {
  justify-content: center;
  text-align: center;
  padding-top: 10%;
}

.col {
  margin: 0px;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

h3 {
  padding-bottom: 30px;
}

form {
  padding-left: 20px;
  padding-right: 20px;
}

.title-login {
  letter-spacing: 2px;
  text-align: left;
  padding-left: 5px;
}

.links {
  padding-top: 15px;
}

.form-group > label {
  text-align: left;
}

p {
  color: #000;
}

.row {
  margin: 0px;
}

#login {
  width: 100%;
  color: #fff;
  background: #fd7136;
  border: 0px;
  border-radius: 0px;
  margin-top: 20px;
}

#login:hover {
  width: 100%;
  color: #fff;
  background: #fbaf5d;
  border: 0px;
  border-radius: 0px;
}

span {
  color: #000;
}

input {
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid #fd7136;
  background-color: rgba(255, 255, 255, 0);
}

.d-block {
  text-align: left;
}

a {
  font-weight: bold;
}

a:hover {
  color: #fd7136;
}

.label {
  color: #fd7136;
  text-align: left;
}
.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.9);
}

.back {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(255, 255, 255, 0.9);
  width: 50px;
  z-index: 1;
  background: #fff;
  border: 1px;
  margin-left: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px;
}

@media only screen and (max-width: 768px), (orientation: portrait) {
  .col-5,
  .login .col-8 {
    max-width: unset;
    flex: none;
  }

  .col-5 {
    top: 75px;
    padding: 0;
  }

  .card {
    border-radius: 0;
  }
}
</style>
