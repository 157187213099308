var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "component", attrs: { "align-h": "center" } },
    [
      _c(
        "b-col",
        { staticStyle: { "font-size": "0.9em" }, attrs: { sm: "12", md: "9" } },
        [
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _c("div", [_vm._v("報名階段")]),
                  _vm._v(" "),
                  _c("b-form-radio-group", {
                    staticClass: "mb-3",
                    attrs: {
                      options: _vm.scdsRoundOption,
                      state: !!_vm.scdsRoundOfToday,
                      required: "",
                    },
                    model: {
                      value: _vm.scdsRoundOfToday,
                      callback: function ($$v) {
                        _vm.scdsRoundOfToday = $$v
                      },
                      expression: "scdsRoundOfToday",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("報名方法")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3", staticStyle: { display: "flex" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      options: _vm.applyMethodOption,
                      state: !!_vm.applyMethod,
                      required: "",
                    },
                    model: {
                      value: _vm.applyMethod,
                      callback: function ($$v) {
                        _vm.applyMethod = $$v
                      },
                      expression: "applyMethod",
                    },
                  }),
                  _vm._v(" "),
                  _c("b-form-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "text", maxlength: "1000" },
                    model: {
                      value: _vm.applyMethodOther,
                      callback: function ($$v) {
                        _vm.applyMethodOther = $$v
                      },
                      expression: "applyMethodOther",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _vm._v("報名遞交時間（YYYY-MM-DD HH:mm:ss）"),
              ]),
              _vm._v(" "),
              _c("b-form-input", {
                staticClass: "mb-3",
                staticStyle: { width: "300px" },
                attrs: {
                  state: _vm.submitTimeState(_vm.submitTime),
                  type: "text",
                },
                model: {
                  value: _vm.submitTime,
                  callback: function ($$v) {
                    _vm.submitTime = $$v
                  },
                  expression: "submitTime",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _vm._v("申請編號（自動產生）"),
              ]),
              _vm._v(" "),
              _c("b-form-input", {
                staticClass: "mb-3",
                attrs: { type: "text", readonly: "" },
                model: {
                  value: _vm.trnId,
                  callback: function ($$v) {
                    _vm.trnId = $$v
                  },
                  expression: "trnId",
                },
              }),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "form",
                    label: "匯入申請（OGCIO .zip）",
                  },
                },
                [
                  _vm.action === "add"
                    ? _c("b-form-file", {
                        attrs: {
                          id: "form",
                          accept:
                            "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed",
                        },
                        on: { change: _vm.uploadForm },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("div", { staticClass: "mb-1" }, [_vm._v("附件")]),
                  _vm._v(" "),
                  _vm._l(_vm.attr, function (a, index) {
                    return _c("p", { key: index }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `${_vm.attachmentUrl}${a}`,
                            target: "_blank",
                          },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "paperclip" } }),
                          _vm._v(_vm._s(a) + "\n          "),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("matching-school", {
                    attrs: {
                      data: _vm.data,
                      "sch-id": _vm.sarId,
                      name: _vm.schoolData ? _vm.schoolData.name_tc : "",
                    },
                    on: { input: _vm.onChangeSkl },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "shadow" },
                [
                  _c("applySchoolInfo", {
                    attrs: {
                      promo: _vm.disagreePromo,
                      "teacher-data": _vm.teachers,
                      "school-data": _vm.schoolData,
                    },
                    on: { change: _vm.onSchoolInfoChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("event-slot-modal", {
                attrs: {
                  "event-slots": _vm.getEventSlot,
                  "parent-scheme-id": 1,
                  "is-admin": true,
                  "pass-first-choice": _vm.passFirstChoice,
                  "pass-second-choice": _vm.passSecondChoice,
                },
                on: { input: _vm.getSelectedSlots },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "shadow" }, [
                _c(
                  "div",
                  { staticClass: "form-section" },
                  [
                    _c("h3", [_vm._v("3. 擬參加活動"), _c("br")]),
                    _vm._v(" "),
                    _vm._l(_vm.activityList, function (activity, index) {
                      return _c("div", { key: index }, [
                        _c("fieldset", { staticClass: "topLeftInsideLegend" }, [
                          _c("legend", [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("行"),
                            ]),
                            _vm._v(
                              "# " + _vm._s(index + 1) + "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "layout-default layout-group layout-inline-group",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.activityCode_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.activityCode",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "required",
                                            attrs: {
                                              id: "activityList.0.activityCode_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "control-label required-label",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          活動編號\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [_vm._v("必須提供")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "194px" },
                                          attrs: {
                                            type: "text",
                                            name: "activityList.0.activityCode",
                                            maxlength: "1000",
                                            value: "",
                                            readonly: "",
                                          },
                                          model: {
                                            value: activity.activityCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "activityCode",
                                                $$v
                                              )
                                            },
                                            expression: "activity.activityCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.activityName_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.activityName",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "required",
                                            attrs: {
                                              id: "activityList.0.activityName_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "control-label required-label",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          活動名稱\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [_vm._v("必須提供")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "364px" },
                                          attrs: {
                                            type: "text",
                                            name: "activityList.0.activityName",
                                            maxlength: "1000",
                                            value: "",
                                            readonly: "",
                                          },
                                          model: {
                                            value: activity.activityName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "activityName",
                                                $$v
                                              )
                                            },
                                            expression: "activity.activityName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("div", [_vm._v("配對活動")]),
                                _vm._v(" "),
                                _c("b-form-select", {
                                  staticClass: "mt-1",
                                  attrs: {
                                    options: _vm.suggestEventList,
                                    state: !!activity.event_id,
                                    size: "sm",
                                    required: "",
                                  },
                                  on: {
                                    change: () => {
                                      activity.event_slot_1 = null
                                      activity.event_slot_2 = null
                                      _vm.$forceUpdate()
                                    },
                                  },
                                  model: {
                                    value: activity.event_id,
                                    callback: function ($$v) {
                                      _vm.$set(activity, "event_id", $$v)
                                    },
                                    expression: "activity.event_id",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "layout-inline mt-3 mb-3" },
                                  [
                                    _vm._v(
                                      "\n                    已選擇：\n                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: !activity.event_id
                                          ? "not_selected"
                                          : "resultskl",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              activity.event_id
                                                ? _vm.suggestEventList.find(
                                                    (d) =>
                                                      d.id === activity.event_id
                                                  ).label
                                                : "未選擇"
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr", { staticStyle: { width: "95%" } }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c("div", [_vm._v("配對活動場次")]),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.modal-eventslot",
                                        modifiers: { "modal-eventslot": true },
                                      },
                                    ],
                                    staticClass: "mt-1",
                                    staticStyle: { display: "block" },
                                    attrs: { size: "sm", variant: "primary" },
                                    on: {
                                      click: () => {
                                        _vm.selectedEventIndex = index
                                        _vm.selectedEventId = activity.event_id
                                          ? activity.event_id
                                          : null
                                        _vm.passFirstChoice =
                                          activity.event_slot_1
                                        _vm.passSecondChoice =
                                          activity.event_slot_2
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("\n                    選擇場次 "),
                                    _c("b-icon", {
                                      attrs: { icon: "hand-index-thumb" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "layout-inline mt-3 mb-5" },
                                  [
                                    _vm._v(
                                      "\n                    已選擇首選場次：\n                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: !activity.event_slot_1
                                          ? "not_selected"
                                          : "resultskl",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              activity.event_slot_1
                                                ? activity.event_slot_1
                                                : "未選擇"
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "layout-inline my-3" },
                                  [
                                    _vm._v(
                                      "\n                    已選擇次選場次：\n                    "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: !activity.event_slot_2
                                          ? "not_selected"
                                          : "resultskl",
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              activity.event_slot_2
                                                ? activity.event_slot_2
                                                : "未選擇"
                                            ) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("h5", [_vm._v("首選場次")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "layout-default layout-group layout-inline-group",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.firstChoiceDate_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.firstChoiceDate",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id: "activityList.0.firstChoiceDate_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "control-label" },
                                              [_vm._v("日期")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "input-date-container",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-group" },
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "form-control",
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    width: "125px",
                                                    "margin-right": "0px",
                                                  },
                                                  attrs: {
                                                    name: "activityList.0.firstChoiceDate",
                                                    type: "text",
                                                    placeholder: "YYYY-MM-DD",
                                                    maxlength: "10",
                                                    value: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      activity.firstChoiceDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        activity,
                                                        "firstChoiceDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "activity.firstChoiceDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.firstChoiceTime_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.firstChoiceTime",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "required",
                                            attrs: {
                                              id: "activityList.0.firstChoiceTime_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "control-label required-label",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          時間（例如：2pm-3:30pm）\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [_vm._v("必須提供")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "279px" },
                                          attrs: {
                                            type: "text",
                                            name: "activityList.0.firstChoiceTime",
                                            maxlength: "1000",
                                            value: "",
                                            readonly: "",
                                          },
                                          model: {
                                            value: activity.firstChoiceTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "firstChoiceTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.firstChoiceTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "field form-group layout-default",
                                attrs: {
                                  id: "activityList.0.firstChoiceClass_panel",
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "activityList.0.firstChoiceClass",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "required",
                                        attrs: {
                                          id: "activityList.0.firstChoiceClass_label",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "control-label required-label",
                                          },
                                          [
                                            _vm._v(
                                              "\n                        學生班級（例如：P3, P5-P6）\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v("必須提供"),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      staticStyle: { width: "279px" },
                                      attrs: {
                                        type: "text",
                                        name: "activityList.0.firstChoiceClass",
                                        maxlength: "1000",
                                        value: "",
                                        readonly: "",
                                      },
                                      model: {
                                        value: activity.firstChoiceClass,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            activity,
                                            "firstChoiceClass",
                                            $$v
                                          )
                                        },
                                        expression: "activity.firstChoiceClass",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "control-label required-label",
                                  },
                                  [_vm._v("配對學生班級")]
                                ),
                                _vm._v(" "),
                                _c("b-form-checkbox-group", {
                                  attrs: {
                                    options: _vm.targetOption,
                                    state:
                                      !!activity.firstChoiceClassVerify &&
                                      activity.firstChoiceClassVerify.length >
                                        0,
                                  },
                                  model: {
                                    value: activity.firstChoiceClassVerify,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        activity,
                                        "firstChoiceClassVerify",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "activity.firstChoiceClassVerify",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "layout-default layout-group layout-inline-group",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.firstChoiceStudentNo_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.firstChoiceStudentNo",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "required",
                                            attrs: {
                                              id: "activityList.0.firstChoiceStudentNo_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "control-label required-label",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          學生人數\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [_vm._v("必須提供")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "104px" },
                                          attrs: {
                                            type: "tel",
                                            name: "activityList.0.firstChoiceStudentNo",
                                            maxlength: "15",
                                            pattern: "[0-9]*",
                                            inputmode: "numeric",
                                            value: "",
                                          },
                                          model: {
                                            value:
                                              activity.firstChoiceStudentNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "firstChoiceStudentNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.firstChoiceStudentNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.firstChoiceTeacherNo_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.firstChoiceTeacherNo",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "required",
                                            attrs: {
                                              id: "activityList.0.firstChoiceTeacherNo_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "control-label required-label",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          老師人數\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "sr-only" },
                                              [_vm._v("必須提供")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "104px" },
                                          attrs: {
                                            type: "tel",
                                            name: "activityList.0.firstChoiceTeacherNo",
                                            maxlength: "15",
                                            pattern: "[0-9]*",
                                            inputmode: "numeric",
                                            value: "",
                                          },
                                          model: {
                                            value:
                                              activity.firstChoiceTeacherNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "firstChoiceTeacherNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.firstChoiceTeacherNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.firstChoiceParentNo_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.firstChoiceParentNo",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id: "activityList.0.firstChoiceParentNo_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "control-label" },
                                              [
                                                _vm._v(
                                                  "\n                          家長人數（適用於附用歡迎家長參與標示之活動）\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "104px" },
                                          attrs: {
                                            type: "tel",
                                            name: "activityList.0.firstChoiceParentNo",
                                            maxlength: "15",
                                            pattern: "[0-9]*",
                                            inputmode: "numeric",
                                            value: "",
                                          },
                                          model: {
                                            value: activity.firstChoiceParentNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "firstChoiceParentNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.firstChoiceParentNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("h5", [_vm._v("次選場次")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "layout-default layout-group layout-inline-group",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.secondChoiceDate_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.secondChoiceDate",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id: "activityList.0.secondChoiceDate_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "control-label" },
                                              [_vm._v("日期")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "input-date-container",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "input-group" },
                                              [
                                                _c("b-form-input", {
                                                  staticClass: "form-control",
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    width: "125px",
                                                    "margin-right": "0px",
                                                  },
                                                  attrs: {
                                                    name: "activityList.0.secondChoiceDate",
                                                    type: "text",
                                                    placeholder: "YYYY.MM.DD",
                                                    maxlength: "10",
                                                    value: "",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value:
                                                      activity.secondChoiceDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        activity,
                                                        "secondChoiceDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "activity.secondChoiceDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.secondChoiceTime_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.secondChoiceTime",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id: "activityList.0.secondChoiceTime_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "control-label" },
                                              [
                                                _vm._v(
                                                  "\n                          時間（例如：2pm-3:30pm）\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "279px" },
                                          attrs: {
                                            type: "text",
                                            name: "activityList.0.secondChoiceTime",
                                            maxlength: "1000",
                                            value: "",
                                            readonly: "",
                                          },
                                          model: {
                                            value: activity.secondChoiceTime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "secondChoiceTime",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.secondChoiceTime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "field form-group layout-default",
                                attrs: {
                                  id: "activityList.0.secondChoiceClass_panel",
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    attrs: {
                                      for: "activityList.0.secondChoiceClass",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: {
                                          id: "activityList.0.secondChoiceClass_label",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "control-label" },
                                          [
                                            _vm._v(
                                              "\n                        學生班級（例如：P3, P5-P6）\n                      "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      staticStyle: { width: "279px" },
                                      attrs: {
                                        type: "text",
                                        name: "activityList.0.secondChoiceClass",
                                        maxlength: "1000",
                                        value: "",
                                        readonly: "",
                                      },
                                      model: {
                                        value: activity.secondChoiceClass,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            activity,
                                            "secondChoiceClass",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "activity.secondChoiceClass",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "control-label required-label",
                                  },
                                  [_vm._v("配對學生班級")]
                                ),
                                _vm._v(" "),
                                _c("b-form-checkbox-group", {
                                  attrs: {
                                    options: _vm.targetOption,
                                    state:
                                      !!activity.secondChoiceClassVerify &&
                                      activity.secondChoiceClassVerify.length >
                                        0,
                                  },
                                  model: {
                                    value: activity.secondChoiceClassVerify,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        activity,
                                        "secondChoiceClassVerify",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "activity.secondChoiceClassVerify",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "layout-default layout-group layout-inline-group",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.secondChoiceStudentNo_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.secondChoiceStudentNo",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id: "activityList.0.secondChoiceStudentNo_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "control-label" },
                                              [_vm._v("學生人數")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "104px" },
                                          attrs: {
                                            type: "tel",
                                            name: "activityList.0.secondChoiceStudentNo",
                                            maxlength: "15",
                                            pattern: "[0-9]*",
                                            inputmode: "numeric",
                                            value: "",
                                          },
                                          model: {
                                            value:
                                              activity.secondChoiceStudentNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "secondChoiceStudentNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.secondChoiceStudentNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.secondChoiceTeacherNo_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.secondChoiceTeacherNo",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id: "activityList.0.secondChoiceTeacherNo_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "control-label" },
                                              [_vm._v("老師人數")]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "104px" },
                                          attrs: {
                                            type: "tel",
                                            name: "activityList.0.secondChoiceTeacherNo",
                                            maxlength: "15",
                                            pattern: "[0-9]*",
                                            inputmode: "numeric",
                                            value: "",
                                          },
                                          model: {
                                            value:
                                              activity.secondChoiceTeacherNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "secondChoiceTeacherNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.secondChoiceTeacherNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "field form-group layout-inline",
                                    attrs: {
                                      id: "activityList.0.secondChoiceParentNo_panel",
                                    },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "activityList.0.secondChoiceParentNo",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              id: "activityList.0.secondChoiceParentNo_label",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "control-label" },
                                              [
                                                _vm._v(
                                                  "\n                          家長人數（適用於附用歡迎家長參與標示之活動）\n                        "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          staticStyle: { width: "104px" },
                                          attrs: {
                                            type: "tel",
                                            name: "activityList.0.secondChoiceParentNo",
                                            maxlength: "15",
                                            pattern: "[0-9]*",
                                            inputmode: "numeric",
                                            value: "",
                                          },
                                          model: {
                                            value:
                                              activity.secondChoiceParentNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                activity,
                                                "secondChoiceParentNo",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activity.secondChoiceParentNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "field form-group layout-default",
                                attrs: { id: "activityList.0.remarks_panel" },
                              },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "activityList.0.remarks" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: {
                                          id: "activityList.0.remarks_label",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "control-label" },
                                          [
                                            _vm._v(
                                              "\n                        備註（如有輪椅位或其他需要，請在此列明）\n                      "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      staticStyle: { width: "704px" },
                                      attrs: {
                                        type: "text",
                                        name: "activityList.0.remarks",
                                        maxlength: "1000",
                                        value: "",
                                      },
                                      model: {
                                        value: activity.remarks,
                                        callback: function ($$v) {
                                          _vm.$set(activity, "remarks", $$v)
                                        },
                                        expression: "activity.remarks",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fieldListButtons" }, [
                          _vm.activityList.length > 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-danger mb-3",
                                  attrs: {
                                    "aria-label": "刪除活動",
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delActivity(index)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "icon-plus-circled",
                                  }),
                                  _vm._v(
                                    "\n                刪除活動 " +
                                      _vm._s(index + 1) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "fieldListButtons my-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-success",
                          attrs: { "aria-label": "新增活動", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.addActivity()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "icon-plus-circled" }),
                          _vm._v(" 新增活動\n            "),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-section" }, [
                      _c("div", [_vm._v("聲明"), _c("br")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "field form-group layout-default",
                          attrs: { id: "declare_panel" },
                        },
                        [
                          _c("label", { attrs: { for: "declare" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "required sr-only",
                                attrs: { id: "declare_label" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "control-label required-label",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    本人為上述學校之授權代表；本校校長亦已得悉並同意報名參與上述活動。\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("必須提供"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "checkbox checkbox-single" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "declare" } },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          type: "checkbox",
                                          name: "declare",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.declare,
                                          callback: function ($$v) {
                                            _vm.declare = $$v
                                          },
                                          expression: "declare",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      本人為上述學校之授權代表；本校校長亦已得悉並同意報名參與上述活動。\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "required",
                                        attrs: { id: "declare_label" },
                                      },
                                      [
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v("必須提供"),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                { attrs: { type: "submit", variant: "success" } },
                [_vm._v(" 提交 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }